import * as React from 'react';

interface MyProps {
  toggle(): void;
}

export const AsideToggler = (props: MyProps) => {
  return (
    <button id="asideToggleBtn" onClick={props.toggle} type="button" className="d-md-down-none navbar-toggler">
      <span className="navbar-toggler-icon" />
    </button>
  );
};
