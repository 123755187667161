import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isLoadedShelves, getLoadShelvesError } from '../../../selectors';
import { loadShelves } from '../../../actions';

export interface ChildrenParams {
  isLoaded: boolean;
  loadError: string;
}
export interface Props {
  children: (params: ChildrenParams) => React.ReactNode;
}

const HTTPRequestShelves: React.FC<Props> = ({ children }: Props): JSX.Element => {
  const isLoaded = useSelector(isLoadedShelves);
  const loadError = useSelector(getLoadShelvesError);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadShelves());
  }, [dispatch]);
  return (
    <>
      {children({
        isLoaded,
        loadError,
      })}
    </>
  );
};

export default HTTPRequestShelves;
