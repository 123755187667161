import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getInitialValuesCreatePlanogram } from '../../../selectors';
import { State } from '../../../../../state';

import { InitialValuesCreatePlanogramModal } from '../../../types/CreatePlanogram';
import { createPlanogramFromShelf } from '../../../actions';
import Form from './Form';
import UserGroupModel from '../../../types/UserGroupModel';

export interface Props {
  shelfReferenceId: string;
}
const FormContainer: React.FC<Props> = ({ shelfReferenceId }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const initialValues = useSelector((state: State) => {
    return getInitialValuesCreatePlanogram(state, shelfReferenceId);
  });

  const handleSubmit = (values: InitialValuesCreatePlanogramModal): void => {
    const { userGroups, planogramName, goalKpis, goal } = values;
    dispatch(
      createPlanogramFromShelf({
        shelfReferenceId,
        planogramDetails: {
          planogramName,
          goal,
          goalKpis,
        },
        userGroups: (userGroups as unknown) as UserGroupModel[],
      })
    );
  };
  return <Form initialValues={initialValues} handleSubmit={handleSubmit} />;
};

export default FormContainer;
