import { isLoaded, isLoading } from 'infrastructure/utils/RemoteObjectStatus';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { loadStandardProductComparisonReportRequestAction } from '../../actions';
import * as selectors from '../../selectors';
import { ProductComparisonReportResult } from '../../types';
interface LoadStatusParams {
  isLoaded: boolean;
  isLoading: boolean;
  loadError: string;
  isDataEmpty: boolean;
  data: ProductComparisonReportResult | undefined;
}
export interface StandardProductComparisonReportLoaderProps {
  children: (params: LoadStatusParams) => React.ReactNode;
}

const StandardProductComparisonReportLoader: React.FC<StandardProductComparisonReportLoaderProps> = ({ children }: StandardProductComparisonReportLoaderProps) => {
  const { shelfPhaseId1, shelfPhaseId2, gtin1, gtin2 } = useParams();
  const dispatch = useDispatch();
  const loadState = useSelector(selectors.getProductComparisonReportLoadState);
  const isDataEmpty = useSelector(selectors.isProductComparisonReportEmpty);
  const data = useSelector(selectors.getProductComparisonReportData);
  useEffect(() => {
    if (isDataEmpty && shelfPhaseId1 && shelfPhaseId2 && gtin1 && gtin2) {
      dispatch(loadStandardProductComparisonReportRequestAction(shelfPhaseId1, shelfPhaseId2, parseInt(gtin1), parseInt(gtin2)));
    }
  }, [dispatch, isDataEmpty, shelfPhaseId1, shelfPhaseId2, gtin1, gtin2]);
  return (
    <>
      {children({
        isLoaded: isLoaded(loadState),
        isLoading: isLoading(loadState),
        loadError: loadState?.errorDetails?.errorMessage ?? '',
        isDataEmpty,
        data,
      })}
    </>
  );
};

export { StandardProductComparisonReportLoader };
