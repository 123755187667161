import * as React from 'react';
import PlanogramState from '../../state';
import { State } from 'state';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import * as actions from '../../actions';
import { Button, Typography } from '@material-ui/core';
import { BarChart, Print } from '@material-ui/icons';
import trackComponent from 'infrastructure/tracking/withTracking';
import TestInfoModal from '../modals/TestInfoModal';
import { isLoaded } from 'infrastructure/utils/RemoteObjectStatus';

type MyProps = PlanogramState & DispatchProps;

class PlanogramPageFooter extends React.Component<MyProps, any> {
  render() {
    if (!isLoaded(this.props.loadPlanogramStatus)) {
      return null;
    }
    return (
      <React.Fragment>
        <div className="no-print">
          <Button size="small" variant="outlined" disabled={this.props.testInfo === undefined} color="primary" onClick={this.props.openTestInfoModal}>
            <BarChart />
            &nbsp; Test Info
          </Button>{' '}
          &nbsp;&nbsp;
          <Button className="left-buffer" size="small" variant="outlined" disabled={this.props.testInfo === undefined} color="primary" onClick={() => window.print()}>
            <Print />
            &nbsp; Print
          </Button>
          {this.renderPlanogramGoal()}
          {this.props.showTestInfoModal ? <TestInfoModal testInfo={this.props.testInfo} onClose={this.props.hideTestInfoModal} /> : null}
        </div>
      </React.Fragment>
    );
  }

  renderPlanogramGoal() {
    return (
      <Typography style={{ margin: '8px' }} className="col-xs-4 col-xs-offset-2" variant="overline" color="textPrimary">
        {this.props.planogram?.goal}
      </Typography>
    );
  }
}

function mapStateToProps(state: State, ownProps: any) {
  return {
    ...state.planogram,
    id: ownProps.match.params.id,
  };
}

interface DispatchProps {
  shareModalToggle(): void;

  openTestWithFlowModal(): void;

  openTestInfoModal(): void;

  hideTestInfoModal(): void;

  publicSharingToggleRequest(planogramId: string, enabled: boolean): void;
}

const componentToExport = connect<PlanogramState, DispatchProps, RouteComponentProps<any>>(mapStateToProps, actions)(PlanogramPageFooter);

export default trackComponent(componentToExport, 'PlanogramPageFooter');
