import apiClient from 'utils/apiClient';
import { EulaAgreement } from './types';

export const getCurrentEulaApiRequest = (): Promise<EulaAgreement> => {
  return apiClient.get(`/users/eula/current`);
};

export const agreeToTermsAndConditionsApiRequest = (version: number) => {
  return apiClient.post(`/users/eula/accept`, { version });
};
