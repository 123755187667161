import * as React from 'react';
import PlanogramModel from 'modules/planogram/domain/PlanogramModel';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, LinearProgress, TextField as MuiTextField } from '@material-ui/core';
import { Close, Add } from '@material-ui/icons';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { RemoteObjectStatusInterface } from 'infrastructure/utils/RemoteObjectStatus';
import ShelfLocation from 'modules/planogram/domain/ShelfLocation';
import { NewProductModel } from 'modules/planogram/types/NewProductModel';
import { Formik, Field, Form, FormikProps } from 'formik';
import { TextField as FormikMuiTextField } from 'formik-material-ui';
import * as Yup from 'yup';
import ShelfLocationGeometryPoint from 'modules/planogram/domain/ShelfLocationGeometryPoint';
import { isLoading, isLoaded } from 'infrastructure/utils/RemoteObjectStatus';
interface NewProductComponentProps {
  planogram: PlanogramModel;
  imageUploadStatus: RemoteObjectStatusInterface<string>;
  onClose(): void;
  onUploadImage: (base64Data: string, cropData: { x: number; y: number; width: number; height: number }) => void;
  onNewShelfLocation: (sl: ShelfLocation, product: NewProductModel) => void;
}

interface NewProductFormFields {
  gtin: string;
  productName: string;
  productWidthCm: string;
  imageData: string;
  numberOfFacings: string;
}

interface NewProductComponentState {
  crop: ReactCrop.Crop;
  formData: NewProductFormFields;
  imageWidth: number;
  imageHeight: number;
}

const NewProductSchema = Yup.object<NewProductFormFields>().shape({
  gtin: Yup.number().typeError('GTIN must be a number with max of 14 digits.').required('GTIN is required.').max(99999999999999),
  productName: Yup.string().required('Product name is required').max(500, 'Product name must be less than 500 characters.'),
  productWidthCm: Yup.number().typeError('Width in centimeters must be a number.').min(3, 'Minimum width can be 3cm'),
  imageData: Yup.string().required('Please choose an image.'),
});

const defaultFormData: NewProductFormFields = {
  gtin: '',
  imageData: '',
  numberOfFacings: '',
  productName: '',
  productWidthCm: '',
};

class NewProductComponent extends React.Component<NewProductComponentProps, NewProductComponentState> {
  constructor(props: NewProductComponentProps) {
    super(props);
    this.state = {
      crop: {
        unit: '%',
        width: 100,
        height: 100,
      },
      imageHeight: 0,
      imageWidth: 0,
      formData: {
        ...defaultFormData,
      },
    };
  }

  componentDidUpdate(prevProps: NewProductComponentProps): void {
    if (this.props.imageUploadStatus.data && isLoading(prevProps.imageUploadStatus) && isLoaded(this.props.imageUploadStatus)) {
      let aspect = 0;
      if (this.state.crop.width && this.state.crop.height) {
        aspect = this.state.crop.width / this.state.crop.height;
      } else {
        aspect = this.state.imageWidth / this.state.imageHeight;
      }

      const formData = this.state.formData;
      const planogram = this.props.planogram;
      const pixelsInCm = planogram.shelfImageUrlHeight / planogram.shelfHeightCm;
      let originalWidth = 0;
      let originalHeight = 0;
      if (formData.productWidthCm) {
        originalWidth = pixelsInCm * parseFloat(formData.productWidthCm);
        originalHeight = pixelsInCm * (parseFloat(formData.productWidthCm) / aspect);
      } else {
        originalWidth = 100;
        originalHeight = originalWidth / aspect;
      }

      const shelfLocation = new ShelfLocation();
      shelfLocation.gtin = parseFloat(formData.gtin);
      shelfLocation.imageUrl = this.props.imageUploadStatus.data;
      shelfLocation.shelfFacings = parseFloat(formData.numberOfFacings) || 1;
      shelfLocation.geometry.currentWidth = originalWidth;
      shelfLocation.geometry.currentHeight = originalHeight;
      shelfLocation.geometry.originalHeight = originalHeight;
      shelfLocation.geometry.originalWidth = originalWidth;
      shelfLocation.geometry.hasFixedImage = formData.productWidthCm ? false : true;
      shelfLocation.geometry.points = [
        new ShelfLocationGeometryPoint(-originalWidth, 0),
        new ShelfLocationGeometryPoint(-originalWidth, originalHeight),
        new ShelfLocationGeometryPoint(0, originalHeight),
        new ShelfLocationGeometryPoint(0, 0),
      ];
      shelfLocation.geometry.points.forEach(p => {
        p.isTransformed = true;
      });
      shelfLocation.geometry.calculatePointsPosition();
      const newProduct = new NewProductModel();
      newProduct.gtin = shelfLocation.gtin;
      newProduct.name = formData.productName;
      newProduct.imageUrl = shelfLocation.imageUrl;
      this.props.onNewShelfLocation(shelfLocation, newProduct);
    }
  }

  render(): JSX.Element {
    return (
      <Dialog open={true} onClose={this.props.onClose}>
        <DialogTitle>Add new product</DialogTitle>
        <Formik
          initialValues={{ ...defaultFormData }}
          validationSchema={NewProductSchema}
          onSubmit={(values: NewProductFormFields): void => {
            this.setState({
              formData: {
                ...values,
              },
            });
            this.props.onUploadImage(values.imageData, {
              x: this.state.crop.x || 0,
              y: this.state.crop.y || 0,
              height: this.state.crop.height || 0,
              width: this.state.crop.width || 0,
            });
          }}
          render={(props: FormikProps<NewProductFormFields>): JSX.Element => {
            const isAllValid = Object.values(props.errors).length === 0 || props.submitCount === 0;
            return (
              <Form>
                <DialogContent>
                  <Button fullWidth color="primary" variant="contained" component="label">
                    Upload Product Image
                    <input
                      type="file"
                      style={{ display: 'none' }}
                      name="imageData"
                      onChange={(evt): void => {
                        if (!evt.target || !evt.target.files || evt.target.files.length === 0) {
                          props.setFieldValue('imageData', '');
                          return;
                        }
                        const reader = new FileReader();
                        reader.onload = (e: ProgressEvent<FileReader>): void => {
                          if (e.target && e.target.result) {
                            const img = new Image();
                            const imageData = e.target.result.toString();
                            img.onload = (): void => {
                              this.setState({ imageWidth: img.width, imageHeight: img.height });
                              props.setFieldValue('imageData', imageData);
                              props.setFieldTouched('imageData', true);
                            };
                            img.src = e.target.result.toString();
                          }
                        };
                        reader.readAsDataURL(evt.target.files[0]);
                      }}
                    />
                  </Button>
                  {props.errors.imageData && props.touched.imageData && (
                    <div className="alert alert-danger top-buffer">
                      <strong>{props.errors.imageData}</strong>
                    </div>
                  )}
                  <div className="top-buffer" />
                  {this.renderImageCrop(props.values.imageData)}
                  <Field component={FormikMuiTextField} label="GTIN: " type="text" name="gtin" fullWidth variant="outlined" margin="normal" />
                  <Field component={FormikMuiTextField} label="Product name: " fullWidth variant="outlined" margin="normal" name="productName" type="text" />
                  <MuiTextField
                    label="Width cm: "
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    name="productWidthCm"
                    type="text"
                    helperText={props.touched.productWidthCm ? props.errors.productWidthCm : ''}
                    error={props.touched.productWidthCm && Boolean(props.errors.productWidthCm)}
                    value={props.values.productWidthCm}
                    onBlur={props.handleBlur}
                    disabled={props.isSubmitting}
                    onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => {
                      evt.persist();
                      props.handleChange(evt);
                      props.setFieldTouched('productWidthCm', true, false);
                    }}
                  />
                  {!isAllValid && (
                    <div className="alert alert-danger top-buffer">
                      <strong>Please enter correct information in the fields.</strong>
                    </div>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button disabled={isLoading(this.props.imageUploadStatus) || !isAllValid} onClick={props.submitForm} color="primary" variant="contained">
                    <Add />
                    &nbsp;
                    {isLoading(this.props.imageUploadStatus) ? 'Uploading image' : 'Add New Product'}
                  </Button>
                  <Button onClick={this.props.onClose} color="secondary" variant="contained">
                    <Close />
                    &nbsp;Close
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        />
        {isLoading(this.props.imageUploadStatus) ? <LinearProgress color="primary" /> : null}
      </Dialog>
    );
  }

  renderImageCrop(imageData?: string): JSX.Element | null {
    if (!imageData) {
      return null;
    }

    return (
      <div className="row">
        <div className="col-sm-12">
          <ReactCrop
            crop={this.state.crop}
            src={imageData}
            onChange={(crop: ReactCrop.Crop): void => {
              this.setState({ crop });
            }}
          />
        </div>
      </div>
    );
  }
}

export default NewProductComponent;
