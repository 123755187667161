import { Type } from 'class-transformer';

export default class CompetitorShelf {
  referenceId = '';
  projectId = 0;
  projectName = '';
  storeId = 0;
  storeName = '';
  clientId = 0;
  clientName = '';
  phaseNum = 0;
  shelfNum = 0;
  shelfImageUrl = '';
  isMappingQualityAssured = false;
  @Type(() => Date)
  startdate: Date = new Date();
  @Type(() => Date)
  endDate: Date = new Date();
}
