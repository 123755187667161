import apiClient, { ApiResponse } from 'utils/apiClient';
import {
  BrandComparisonReportResult,
  BrandReportResult,
  ClientBenchmarkReportResult,
  GpcBrickComparisonReportResult,
  GpcBrickReportResult,
  ProductComparisonReportResult,
  ProductReportResult,
  ProjectBenchmarkReportResult,
  ProjectComparisonReportResult,
  ProjectReportResult,
  ShelfComparisonReportResult,
  ShopperComparisonReportResult,
  ShopperReportResult,
  StandardReportPhaseShelf,
  BrandPotentialReport,
  ProductBenchmarkReport,
} from './types';

export const getPhaseShelvesRequest = (): Promise<ApiResponse<StandardReportPhaseShelf[]>> => {
  return apiClient.get(`shelves/standardReportPhaseShelves`);
};

export const getProjectReport = (shelfPhaseId: string): Promise<ApiResponse<ProjectReportResult>> => {
  return apiClient.get(`reports/standardReports/projectReports/${shelfPhaseId}`);
};

export const getShopperReport = (shelfPhaseId: string): Promise<ApiResponse<ShopperReportResult>> => {
  return apiClient.get(`reports/standardReports/shopperReports/projects/${shelfPhaseId}/shoppers`);
};

export const getBrandReport = (shelfPhaseId: string, brandId: number): Promise<ApiResponse<BrandReportResult>> => {
  return apiClient.get(`reports/standardReports/brandReports/projects/${shelfPhaseId}/brands/${brandId}`);
};

export const getProductReport = (shelfPhaseId: string, gtin: number): Promise<ApiResponse<ProductReportResult>> => {
  return apiClient.get(`reports/standardReports/productReports/projects/${shelfPhaseId}/products/${gtin}`);
};
export const getGpcBrickReport = (shelfPhaseId: string, gpcBrickCode: number): Promise<ApiResponse<GpcBrickReportResult>> => {
  return apiClient.get(`reports/standardReports/gpcBrickReports/projects/${shelfPhaseId}/gpcBricks/${gpcBrickCode}`);
};
export const getShelfComparisonReport = (shelfPhaseId1: string, shelfPhaseId2: string): Promise<ApiResponse<ShelfComparisonReportResult>> => {
  return apiClient.get(`reports/standardReports/compareReports/shelfComparison/${shelfPhaseId1}/${shelfPhaseId2}`);
};

export const getProjectComparisonReport = (shelfPhaseId1: string, shelfPhaseId2: string): Promise<ApiResponse<ProjectComparisonReportResult>> => {
  return apiClient.get(`reports/standardReports/compareReports/projects/${shelfPhaseId1}/${shelfPhaseId2}`);
};

export const getShopperComparisonReport = (shelfPhaseId1: string, shelfPhaseId2: string): Promise<ApiResponse<ShopperComparisonReportResult>> => {
  return apiClient.get(`reports/standardReports/compareReports/projects/${shelfPhaseId1}/${shelfPhaseId2}/shoppers`);
};

export const getProductComparisonReport = (shelfPhaseId1: string, shelfPhaseId2: string, gtin1: number, gtin2: number): Promise<ApiResponse<ProductComparisonReportResult>> => {
  return apiClient.get(`reports/standardReports/compareReports/projects/${shelfPhaseId1}/${shelfPhaseId2}/products/${gtin1}/${gtin2}`);
};

export const getBrandComparisonReport = (shelfPhaseId1: string, shelfPhaseId2: string, brandId1: number, brandId2: number): Promise<ApiResponse<BrandComparisonReportResult>> => {
  return apiClient.get(`reports/standardReports/compareReports/projects/${shelfPhaseId1}/${shelfPhaseId2}/brands/${brandId1}/${brandId2}`);
};
export const getGpcBrickComparisonReport = (shelfPhaseId1: string, shelfPhaseId2: string, gpcBrickCode1: number, gpcBrickCode2: number): Promise<ApiResponse<GpcBrickComparisonReportResult>> => {
  return apiClient.get(`reports/standardReports/compareReports/projects/${shelfPhaseId1}/${shelfPhaseId2}/gpcBricks/${gpcBrickCode1}/${gpcBrickCode2}`);
};

export const getProjectBenchmarkReport = (projectId: string): Promise<ApiResponse<ProjectBenchmarkReportResult>> => {
  return apiClient.get(`reports/standardReports/projectReports/projects/${projectId}/benchmark`);
};

export const getClientBenchmarkReport = (clientId: string): Promise<ApiResponse<ClientBenchmarkReportResult>> => {
  return apiClient.get(`reports/standardReports/clientReports/clients/${clientId}/benchmark`);
};

export const getBrandPotentialReport = (shelfPhaseId: string, brandId: number): Promise<ApiResponse<BrandPotentialReport>> => {
  return apiClient.get(`reports/potentialReports/brandReports/projects/${shelfPhaseId}/brands/${brandId}`);
};

export const getProductBenchmarkReport = (targetShelfPhaseId: string, comparisonShelfPhaseId: string, targetGtin: number): Promise<ApiResponse<ProductBenchmarkReport>> => {
  return apiClient.get(`reports/standardreports/productreports/projects/${targetShelfPhaseId}/${comparisonShelfPhaseId}/benchmark/${targetGtin}`);
};

export const getProjectCauseEffectReport = (targetShelfPhaseId: string, comparisonShelfPhaseId: string): Promise<any> => {
  return apiClient.get(`reports/standardReports/projectReports/projects/${targetShelfPhaseId}/${comparisonShelfPhaseId}/cause-effect`, {
    responseType: 'blob',
  });
};

export const getGpcBrickCauseEffectReport = (targetShelfPhaseId: string, comparisonShelfPhaseId: string): Promise<any> => {
  return apiClient.get(`reports/standardReports/gpcBrickReports/projects/${targetShelfPhaseId}/${comparisonShelfPhaseId}/cause-effect`, {
    responseType: 'blob',
  });
};

export const getBrandCauseEffectReport = (targetShelfPhaseId: string, comparisonShelfPhaseId: string): Promise<any> => {
  return apiClient.get(`reports/standardReports/brandReports/projects/${targetShelfPhaseId}/${comparisonShelfPhaseId}/cause-effect`, {
    responseType: 'blob',
  });
};

export const getProductCauseEffectReport = (targetShelfPhaseId: string, comparisonShelfPhaseId: string): Promise<any> => {
  return apiClient.get(`reports/standardReports/productReports/projects/${targetShelfPhaseId}/${comparisonShelfPhaseId}/cause-effect`, {
    responseType: 'blob',
  });
};
