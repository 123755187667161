import EmojiObjectsTwoToneIcon from '@material-ui/icons/EmojiObjectsTwoTone';
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';
import { toMaxPrecisionPercent, toMaxPrecision } from 'utils/Helpers';
import moment from 'moment';
import React from 'react';
import flowLogo from '../../../../../assets/img/brand/flow.jpg';
import '../styles/StandardReportStyle.css';
import '../styles/StandardReportProductGridStyle.css';
import { BrandPotentialReport, BrandPotentialBricks, BrandPotentialSpaceSuggestion, BrandPotentialLocationSuggestion } from '../../types';

export interface BrandPotentialReportPageProps {
  report: BrandPotentialReport;
}

function RenderBrickItem(item: BrandPotentialBricks): React.ReactNode {
  const ignoreBrand = item.brandNumberOfProducts === 0;
  const increaseProduct = item.increaseProducts === 1;
  const decreaseProduct = item.decreaseProducts === 1;
  return (
    <tr>
      <td className="text-left metricNameCell" colSpan={2}>
        {item.gpcBrickDescription}
      </td>
      <td className="text-center ">{item.gpcNumberOfProducts}</td>
      <td className="text-center">{toMaxPrecisionPercent(item.gpcPerformace, 2) ?? '-'}</td>

      <td className="text-center">
        {item.brandNumberOfProducts} {increaseProduct && <EmojiObjectsTwoToneIcon />}
        {decreaseProduct && <RemoveShoppingCartIcon />}
      </td>
      <td className="text-center">{ignoreBrand ? '-' : toMaxPrecisionPercent(item.brandPerformance, 2)}</td>
    </tr>
  );
}

function RenderBricksTable(report: BrandPotentialReport): React.ReactNode {
  const bricksOnShelf = report.bricks
    .filter(function (item) {
      return item.bricksOnShelf === 1;
    })
    .sort(function (a, b) {
      return a.gpcPerformace < b.gpcPerformace ? 1 : -1;
    });
  const bricksToConsider = report.bricks
    .filter(function (item) {
      return item.bricksToConsider === 1;
    })
    .sort(function (a, b) {
      return a.gpcPerformace < b.gpcPerformace ? 1 : -1;
    });
  return (
    <table style={{ marginTop: '10px' }} className="table table-bordered table-striped">
      <tbody>
        <tr>
          <td style={{ width: '20%' }} className="text-left metricNameCell" rowSpan={2} colSpan={2}>
            Bricks on shelf
          </td>
          <td className="text-center metricNameCell" colSpan={2}>
            All brands
          </td>
          <td className="text-center metricNameCell" colSpan={2}>
            {report.overview.brandName}
          </td>
        </tr>
        <tr>
          <td className="text-center metricNameCell text-small">Number of products</td>
          <td className="text-center metricNameCell text-small">Performance</td>
          <td className="text-center metricNameCell text-small">Number of products</td>
          <td className="text-center metricNameCell text-small">Performance</td>
        </tr>

        {bricksOnShelf.map(brick => {
          return RenderBrickItem(brick);
        })}
        {bricksToConsider.length > 0 && (
          <tr>
            <td className="text-left metricNameCell" colSpan={2}>
              Bricks to consider
            </td>
            <td className="text-center metricNameCell text-small">Number of products</td>
            <td className="text-center metricNameCell text-small">Performance</td>
            <td className="text-center metricNameCell text-small">Number of products</td>
            <td className="text-center metricNameCell text-small">Performance</td>
          </tr>
        )}
        {bricksToConsider.map(brick => {
          return RenderBrickItem(brick);
        })}
      </tbody>
    </table>
  );
}

function GetColoredSpan(change: number | undefined): React.ReactNode {
  if (typeof change === 'undefined') {
    return <span> - </span>;
  }
  const operator = change < 0 ? '-' : '+';
  const color = change >= 0 ? 'green' : 'red';
  return (
    <span style={{ color: color }}>
      {operator} {Math.abs(change)}
    </span>
  );
}

function RenderSpaceChangeRow(name: string, space: number, facings: number, change: number, turnover: number): React.ReactNode {
  return (
    <tr>
      <td className="text-left">{name}</td>
      <td className="text-center">{toMaxPrecisionPercent(space, 2)}</td>
      <td className="text-center">
        {facings} {GetColoredSpan(change)}
      </td>
      <td className="text-center">{GetColoredSpan(toMaxPrecision(turnover, 2))}</td>
    </tr>
  );
}

function RenderDelisted(report: BrandPotentialReport): React.ReactNode {
  const delists = report.delists.sort(function (a, b) {
    return a.delistScore > b.delistScore ? 1 : -1;
  });
  return (
    <table style={{ marginTop: '10px' }} className="table table-bordered table-striped">
      <tbody>
        <tr>
          <td style={{ width: '50%' }} className="text-left metricNameCell">
            Could be de-listed
          </td>
          <td className="text-center metricNameCell text-small">Share of space</td>
          <td className="text-center metricNameCell text-small">Number of facings</td>
          <td className="text-center metricNameCell text-small">Incremental daily turnover</td>
        </tr>
        {delists.map(item => {
          return RenderSpaceChangeRow(item.tradeItemDescription, item.shareOfSpace, item.numberOfFacings, item.numberOfFacings * -1, item.incrementalDailyTurnover);
        })}
      </tbody>
    </table>
  );
}

function RenderSpacePotentialTable(name: string, items: BrandPotentialSpaceSuggestion[], invertChange = false): React.ReactNode {
  return (
    <table className="table table-bordered table-striped">
      <tbody>
        <tr>
          <td style={{ width: '50%' }} className="text-left metricNameCell">
            {name}
          </td>
          <td className="text-center metricNameCell text-small">Share of space</td>
          <td className="text-center metricNameCell text-small">Number of facings</td>
          <td className="text-center metricNameCell text-small">Incremental daily turnover</td>
        </tr>
        {items.map(item => {
          const space = item.shareOfSpace;
          const change = invertChange ? item.change * -1 : item.change;
          return RenderSpaceChangeRow(item.tradeItemDescription, space, item.facings, change, item.incrementalDailyTurnover);
        })}
      </tbody>
    </table>
  );
}

function PositionNumberToString(position: number): string {
  return `${position}`.split('').join(',');
}

function RenderLocationPotentialItem(item: BrandPotentialLocationSuggestion): React.ReactNode {
  return (
    <tr>
      <td className="text-left">{item.tradeItemDescription ?? '-'}</td>
      <td className="text-center">{PositionNumberToString(item.currentPosition)}</td>
      <td className="text-center">{PositionNumberToString(item.suggestedPosition)}</td>
      <td className="text-center">{GetColoredSpan(toMaxPrecision(item.incrementalDailyTurnover, 2))}</td>
    </tr>
  );
}
function RenderLocationPotentialTable(items: BrandPotentialLocationSuggestion[]): React.ReactNode {
  return (
    <table className="table table-bordered table-striped">
      <tbody>
        <tr>
          <td style={{ width: '50%' }} className="text-left metricNameCell">
            Benefit from changing location
          </td>
          <td className="text-center metricNameCell text-small">Current position</td>
          <td className="text-center metricNameCell text-small">Suggested position</td>
          <td className="text-center metricNameCell text-small">Incremental daily turnover</td>
        </tr>
        {items.map(item => {
          return RenderLocationPotentialItem(item);
        })}
      </tbody>
    </table>
  );
}

function RenderOosItem(name: string, oos: number, sales: number, turnover: number): React.ReactNode {
  return (
    <tr>
      <td className="text-left text-small">{name ?? '-'}</td>
      <td className="text-center text-small">{toMaxPrecision(oos, 2) ?? '-'}</td>
      <td className="text-center text-small">{toMaxPrecision(sales, 2) ?? '-'}</td>
      <td className="text-center text-small">
        <span style={{ color: 'red' }}>{toMaxPrecision(turnover, 2) ?? '-'}</span>
      </td>
    </tr>
  );
}

function RenderOos(report: BrandPotentialReport): React.ReactNode {
  const shelf = report.lostOosShelf;
  const brand = report.lostOosBrand;
  const products = report.lostOosProducts ?? [];
  return (
    <table style={{ marginTop: '10px' }} className="table table-bordered table-striped">
      <tbody>
        <tr>
          <td style={{ width: '30%' }} className="text-center metricNameCell text-small">
            Lost sales (Out-of-stocks)
          </td>
          <td className="text-center metricNameCell text-small">OOS daily hours</td>
          <td className="text-center metricNameCell text-small">Lost daily sales</td>
          <td className="text-center metricNameCell text-small">Lost daily turnover</td>
        </tr>
        {RenderOosItem('All brands', shelf.hoursOutOfStockPerDay, shelf.lostDailySalesOutOfStock, shelf.lostDailyTurnoverOutOfStock)}
        {RenderOosItem(report.overview.brandName, brand?.hoursOutOfStockPerDay, brand?.lostDailySalesOutOfStock, brand?.lostDailyTurnoverOutOfStock)}
        {products
          .sort(function (a, b) {
            return a.lostDailyTurnoverOutOfStock < b.lostDailyTurnoverOutOfStock ? 1 : -1;
          })
          .slice(0, 3)
          .map(item => {
            return RenderOosItem(item.tradeItemDescription, item.hoursOutOfStockPerDay, item.lostDailySalesOutOfStock, item.lostDailyTurnoverOutOfStock);
          })}
      </tbody>
    </table>
  );
}
function RenderDemographicItem(
  name: string,
  lost: number,
  group: string,
  groupCount: number,
  age: number,
  ageCount: number,
  shoppingWith: string,
  shoppingWithCount: number,
  time: string,
  timeCount: number,
  turnover: number
): React.ReactNode {
  return (
    <tr>
      <td className="text-left text-small">{name}</td>
      <td className="text-center text-small">{lost}</td>
      <td className="text-center text-small">{group ? `${group} (${groupCount})` : '-'}</td>
      <td className="text-center text-small">{age ? `${age} (${ageCount})` : '-'}</td>
      <td className="text-center text-small">{shoppingWith ? `${shoppingWith} (${shoppingWithCount})` : '-'}</td>
      <td className="text-center text-small">{time ? `${time} (${timeCount})` : '-'}</td>
      <td className="text-center text-small">
        <span style={{ color: 'red' }}>{toMaxPrecision(turnover, 2)}</span>
      </td>
    </tr>
  );
}
function RenderDemographic(report: BrandPotentialReport): React.ReactNode {
  const brand = report.lostInteractedBrand;
  const shelf = report.lostInteractedShelf;
  return (
    <table style={{ marginTop: '10px' }} className="table table-bordered table-striped">
      <tbody>
        <tr>
          <td className="text-center metricNameCell text-small">Lost sales (Interacted)</td>
          <td className="text-center metricNameCell text-small">Lost shoppers</td>
          <td className="text-center metricNameCell text-small">Demographic group</td>
          <td className="text-center metricNameCell text-small">Age</td>
          <td className="text-center metricNameCell text-small">Shopping With</td>
          <td className="text-center metricNameCell text-small">Shopping time</td>
          <td className="text-center metricNameCell text-small">Lost daily turnover</td>
        </tr>
        {RenderDemographicItem(
          'All brands',
          shelf.lostShoppersCount,
          shelf.demographicGroup,
          shelf.demographicGroupCount,
          shelf.age,
          shelf.ageCount,
          shelf.shoppingTime,
          shelf.shoppingTimeCount,
          shelf.shoppingWith,
          shelf.shoppingWithCount,
          shelf.lostDailyTurnover
        )}

        {RenderDemographicItem(
          report.overview.brandName,
          brand.lostShoppersCount,
          brand.demographicGroup,
          brand.demographicGroupCount,
          brand.age,
          brand.ageCount,
          brand.shoppingTime,
          brand.shoppingTimeCount,
          brand.shoppingWith,
          brand.shoppingWithCount,
          brand.lostDailyTurnover
        )}
        {report.lostInteractedProducts
          .filter(function (item) {
            return item.lostDailyTurnover > 0;
          })
          .sort(function (a, b) {
            return a.lostDailyTurnover < b.lostDailyTurnover ? 1 : -1;
          })
          .slice(0, 3)
          .map(item => {
            return RenderDemographicItem(
              item.tradeItemDescription + '',
              item.lostShoppersCount,
              item.demographicGroup,
              item.demographicGroupCount,
              item.age,
              item.ageCount,
              item.shoppingTime,
              item.shoppingTimeCount,
              item.shoppingWith,
              item.shoppingWithCount,
              item.lostDailyTurnover
            );
          })}
      </tbody>
    </table>
  );
}

const BrandPotentialReportPage: React.FC<BrandPotentialReportPageProps> = ({ report }: BrandPotentialReportPageProps) => {
  return (
    <div className="document">
      <div className="page">
        <div id="flowLogo">
          <img alt="flow logo" className="img-responsive" src={flowLogo} />
        </div>
        <div style={{ width: '100%' }}>
          <h2 className="text-center">{report.overview.brandName} potential report</h2>
          <p className="text-center" style={{ fontSize: '.8em', padding: 0, margin: 0 }}>
            {report.overview.projectName} - {report.overview.storeName} - {report.overview.shelfNum} - {report.overview.phaseNum}, {moment(report.overview.dateFrom).format('MMMM Do YYYY')} -{' '}
            {moment(report.overview.dateTo).format('MMMM Do YYYY')}
          </p>
          <p className="text-center" style={{ fontSize: '.8em', padding: 0, margin: 0 }}>
            {report.overview.totalBrandInteractors} shoppers interacted with {report.overview.brandName} of {report.overview.totalVisitors} total visitors. {report.overview.brandName} daily turnover:
            {toMaxPrecision(report.overview.brandDailyTurnover, 2)}
          </p>
        </div>
        <div className="row">{RenderBricksTable(report)}</div>
        {report.delists.length > 0 && <div className="row">{RenderDelisted(report)}</div>}
        {report.spaceSuggestionDecrease.length > 0 && <div className="row">{RenderSpacePotentialTable('Space could be reduced', report.spaceSuggestionDecrease, true)}</div>}
        {report.spaceSuggestionIncrease.length > 0 && <div className="row">{RenderSpacePotentialTable('Benefit from more space', report.spaceSuggestionIncrease)}</div>}
        {report.locationSuggestions.length > 0 && <div className="row">{RenderLocationPotentialTable(report.locationSuggestions)}</div>}
        <div className="row">
          {<div style={{ width: '33%' }}>{RenderOos(report)}</div>}
          <div style={{ width: '66%', marginLeft: '5px' }}>{RenderDemographic(report)}</div>
        </div>

        <footer className="footer top-buffer">
          <strong>Copyright &copy; {new Date().getFullYear()} Flow Insights</strong>. Strictly confidential. No distribution allowed. All rights reserved.
        </footer>
      </div>
    </div>
  );
};

export { BrandPotentialReportPage };
