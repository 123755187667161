import { isLoaded, isLoading } from 'infrastructure/utils/RemoteObjectStatus';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { loadStandardProductReportRequestAction } from '../../actions';
import * as selectors from '../../selectors';
import { ProductReportResult } from '../../types';
interface LoadStatusParams {
  isLoaded: boolean;
  isLoading: boolean;
  loadError: string;
  isDataEmpty: boolean;
  data: ProductReportResult | undefined;
}
export interface StandardProductReportLoaderProps {
  children: (params: LoadStatusParams) => React.ReactNode;
}

const StandardProductReportLoader: React.FC<StandardProductReportLoaderProps> = ({ children }: StandardProductReportLoaderProps) => {
  const { shelfPhaseId, gtin } = useParams();
  const dispatch = useDispatch();
  const loadState = useSelector(selectors.getProductReportLoadState);
  const isDataEmpty = useSelector(selectors.isProductReportEmpty);
  const data = useSelector(selectors.getProductReportData);
  useEffect(() => {
    if (isDataEmpty && shelfPhaseId && gtin) {
      dispatch(loadStandardProductReportRequestAction(shelfPhaseId, parseInt(gtin)));
    }
  }, [dispatch, isDataEmpty, shelfPhaseId, gtin]);
  return (
    <>
      {children({
        isLoaded: isLoaded(loadState),
        isLoading: isLoading(loadState),
        loadError: loadState?.errorDetails?.errorMessage ?? '',
        isDataEmpty,
        data,
      })}
    </>
  );
};

export { StandardProductReportLoader };
