import { plainToClass } from 'class-transformer';
import { toastr } from 'react-redux-toastr';
import { all, put, takeLatest } from 'redux-saga/effects';
import apiClient from 'utils/apiClient';
import apiSaga from 'utils/apiSaga';
import ErrorDetails from '../../../infrastructure/exception/ErrorDetails';
import * as actions from './actions';
import * as constants from './constants';
import UserForAdminViewModel from './types/UserForAdminViewModel';
import UserPlanogramForAdminViewModel from './types/UserPlanogramForAdminViewModel';

function getUsersApi() {
  return apiClient.get('/admin/users');
}

function getUserPlanogramsApi(userId: number) {
  return apiClient.get(`/admin/users/${userId}/planograms`);
}

function* getUsersApiRequest() {
  try {
    const response = yield apiSaga(getUsersApi);
    const data = plainToClass(UserForAdminViewModel, response.data as UserForAdminViewModel[]);
    yield put(actions.loadAdminUsersSuccess(data));
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    console.log('Error loading users', errorDetails);
    toastr.error('Error loading users', errorDetails.errorMessage);
    yield put(actions.loadAdminUsersError());
  }
}

function* getUserPlanogramsApiRequest(action: any) {
  try {
    const response = yield apiSaga(getUserPlanogramsApi, action.userId);
    const data = plainToClass(UserPlanogramForAdminViewModel, response.data as UserPlanogramForAdminViewModel[]);
    yield put(actions.loadAdminUserPlanogramsSuccess(data));
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    console.log('Error loading user planograms', errorDetails);
    toastr.error('Error loading user planograms', errorDetails.errorMessage);
    yield put(actions.loadAdminUserPlanogramsError());
  }
}

export default function* root() {
  yield all([takeLatest(constants.ADMIN_USERS_LOAD_REQUEST, getUsersApiRequest), takeLatest(constants.ADMIN_USER_PLANOGRAMS_LOAD_REQUEST, getUserPlanogramsApiRequest)]);
}
