import ErrorDetails from 'infrastructure/exception/ErrorDetails';
import {
  BrandComparisonReportResult,
  BrandReportResult,
  ClientBenchmarkReportResult,
  GpcBrickComparisonReportResult,
  GpcBrickReportResult,
  ProductComparisonReportResult,
  ProductReportResult,
  ProjectBenchmarkReportResult,
  ProjectComparisonReportResult,
  ProjectReportResult,
  ShelfComparisonReportResult,
  ShopperComparisonReportResult,
  ShopperReportResult,
  StandardReportPhaseShelf,
  BrandPotentialReport,
  ProductBenchmarkReport,
} from './types';

// shelf selector action constants
export const STANDARD_REPORTS_LOAD_PHASE_SHELVES_REQUEST = 'STANDARD_REPORTS_LOAD_PHASE_SHELVES_REQUEST';
export type STANDARD_REPORTS_LOAD_PHASE_SHELVES_REQUEST = typeof STANDARD_REPORTS_LOAD_PHASE_SHELVES_REQUEST;

export const STANDARD_REPORTS_LOAD_PHASE_SHELVES_SUCCESS = 'STANDARD_REPORTS_LOAD_PHASE_SHELVES_SUCCESS';
export type STANDARD_REPORTS_LOAD_PHASE_SHELVES_SUCCESS = typeof STANDARD_REPORTS_LOAD_PHASE_SHELVES_SUCCESS;

export const STANDARD_REPORTS_LOAD_PHASE_SHELVES_FAILURE = 'STANDARD_REPORTS_LOAD_PHASE_SHELVES_FAILURE';
export type STANDARD_REPORTS_LOAD_PHASE_SHELVES_FAILURE = typeof STANDARD_REPORTS_LOAD_PHASE_SHELVES_FAILURE;

// standard project report action constants
export const STANDARD_REPORTS_LOAD_PROJECT_REPORT_REQUEST = 'STANDARD_REPORTS_LOAD_PROJECT_REPORT_REQUEST';
export type STANDARD_REPORTS_LOAD_PROJECT_REPORT_REQUEST = typeof STANDARD_REPORTS_LOAD_PROJECT_REPORT_REQUEST;

export const STANDARD_REPORTS_LOAD_PROJECT_REPORT_SUCCESS = 'STANDARD_REPORTS_LOAD_PROJECT_REPORT_SUCCESS';
export type STANDARD_REPORTS_LOAD_PROJECT_REPORT_SUCCESS = typeof STANDARD_REPORTS_LOAD_PROJECT_REPORT_SUCCESS;

export const STANDARD_REPORTS_LOAD_PROJECT_REPORT_FAILURE = 'STANDARD_REPORTS_LOAD_PROJECT_REPORT_FAILURE';
export type STANDARD_REPORTS_LOAD_PROJECT_REPORT_FAILURE = typeof STANDARD_REPORTS_LOAD_PROJECT_REPORT_FAILURE;

// standard shoppers report action constants
export const STANDARD_REPORTS_LOAD_SHOPPER_REPORT_REQUEST = 'STANDARD_REPORTS_LOAD_SHOPPER_REPORT_REQUEST';
export type STANDARD_REPORTS_LOAD_SHOPPER_REPORT_REQUEST = typeof STANDARD_REPORTS_LOAD_SHOPPER_REPORT_REQUEST;

export const STANDARD_REPORTS_LOAD_SHOPPER_REPORT_SUCCESS = 'STANDARD_REPORTS_LOAD_SHOPPER_REPORT_SUCCESS';
export type STANDARD_REPORTS_LOAD_SHOPPER_REPORT_SUCCESS = typeof STANDARD_REPORTS_LOAD_SHOPPER_REPORT_SUCCESS;

export const STANDARD_REPORTS_LOAD_SHOPPER_REPORT_FAILURE = 'STANDARD_REPORTS_LOAD_SHOPPER_REPORT_FAILURE';
export type STANDARD_REPORTS_LOAD_SHOPPER_REPORT_FAILURE = typeof STANDARD_REPORTS_LOAD_SHOPPER_REPORT_FAILURE;

// standard brand report action constants
export const STANDARD_REPORTS_LOAD_BRAND_REPORT_REQUEST = 'STANDARD_REPORTS_LOAD_BRAND_REPORT_REQUEST';
export type STANDARD_REPORTS_LOAD_BRAND_REPORT_REQUEST = typeof STANDARD_REPORTS_LOAD_BRAND_REPORT_REQUEST;

export const STANDARD_REPORTS_LOAD_BRAND_REPORT_SUCCESS = 'STANDARD_REPORTS_LOAD_BRAND_REPORT_SUCCESS';
export type STANDARD_REPORTS_LOAD_BRAND_REPORT_SUCCESS = typeof STANDARD_REPORTS_LOAD_BRAND_REPORT_SUCCESS;

export const STANDARD_REPORTS_LOAD_BRAND_REPORT_FAILURE = 'STANDARD_REPORTS_LOAD_BRAND_REPORT_FAILURE';
export type STANDARD_REPORTS_LOAD_BRAND_REPORT_FAILURE = typeof STANDARD_REPORTS_LOAD_BRAND_REPORT_FAILURE;

// standard product report action constants
export const STANDARD_REPORTS_LOAD_PRODUCT_REPORT_REQUEST = 'STANDARD_REPORTS_LOAD_PRODUCT_REPORT_REQUEST';
export type STANDARD_REPORTS_LOAD_PRODUCT_REPORT_REQUEST = typeof STANDARD_REPORTS_LOAD_PRODUCT_REPORT_REQUEST;

export const STANDARD_REPORTS_LOAD_PRODUCT_REPORT_SUCCESS = 'STANDARD_REPORTS_LOAD_PRODUCT_REPORT_SUCCESS';
export type STANDARD_REPORTS_LOAD_PRODUCT_REPORT_SUCCESS = typeof STANDARD_REPORTS_LOAD_PRODUCT_REPORT_SUCCESS;

export const STANDARD_REPORTS_LOAD_PRODUCT_REPORT_FAILURE = 'STANDARD_REPORTS_LOAD_PRODUCT_REPORT_FAILURE';
export type STANDARD_REPORTS_LOAD_PRODUCT_REPORT_FAILURE = typeof STANDARD_REPORTS_LOAD_PRODUCT_REPORT_FAILURE;

// standard gpc brick report action constants
export const STANDARD_REPORTS_LOAD_GPC_BRICK_REPORT_REQUEST = 'STANDARD_REPORTS_LOAD_GPC_BRICK_REPORT_REQUEST';
export type STANDARD_REPORTS_LOAD_GPC_BRICK_REPORT_REQUEST = typeof STANDARD_REPORTS_LOAD_GPC_BRICK_REPORT_REQUEST;

export const STANDARD_REPORTS_LOAD_GPC_BRICK_REPORT_SUCCESS = 'STANDARD_REPORTS_LOAD_GPC_BRICK_REPORT_SUCCESS';
export type STANDARD_REPORTS_LOAD_GPC_BRICK_REPORT_SUCCESS = typeof STANDARD_REPORTS_LOAD_GPC_BRICK_REPORT_SUCCESS;

export const STANDARD_REPORTS_LOAD_GPC_BRICK_REPORT_FAILURE = 'STANDARD_REPORTS_LOAD_GPC_BRICK_REPORT_FAILURE';
export type STANDARD_REPORTS_LOAD_GPC_BRICK_REPORT_FAILURE = typeof STANDARD_REPORTS_LOAD_GPC_BRICK_REPORT_FAILURE;

// standard shelf changes report action constants
export const STANDARD_REPORTS_LOAD_SHELF_COMPARISON_REPORT_REQUEST = 'STANDARD_REPORTS_LOAD_SHELF_COMPARISON_REPORT_REQUEST';
export type STANDARD_REPORTS_LOAD_SHELF_COMPARISON_REPORT_REQUEST = typeof STANDARD_REPORTS_LOAD_SHELF_COMPARISON_REPORT_REQUEST;

export const STANDARD_REPORTS_LOAD_SHELF_COMPARISON_REPORT_SUCCESS = 'STANDARD_REPORTS_LOAD_SHELF_COMPARISON_REPORT_SUCCESS';
export type STANDARD_REPORTS_LOAD_SHELF_COMPARISON_REPORT_SUCCESS = typeof STANDARD_REPORTS_LOAD_SHELF_COMPARISON_REPORT_SUCCESS;

export const STANDARD_REPORTS_LOAD_SHELF_COMPARISON_REPORT_FAILURE = 'STANDARD_REPORTS_LOAD_SHELF_COMPARISON_REPORT_FAILURE';
export type STANDARD_REPORTS_LOAD_SHELF_COMPARISON_REPORT_FAILURE = typeof STANDARD_REPORTS_LOAD_SHELF_COMPARISON_REPORT_FAILURE;

// standard comparison project report action constants
export const STANDARD_REPORTS_LOAD_PROJECT_COMPARISON_REPORT_REQUEST = 'STANDARD_REPORTS_LOAD_PROJECT_COMPARISON_REPORT_REQUEST';
export type STANDARD_REPORTS_LOAD_PROJECT_COMPARISON_REPORT_REQUEST = typeof STANDARD_REPORTS_LOAD_PROJECT_COMPARISON_REPORT_REQUEST;

export const STANDARD_REPORTS_LOAD_PROJECT_COMPARISON_REPORT_SUCCESS = 'STANDARD_REPORTS_LOAD_PROJECT_COMPARISON_REPORT_SUCCESS';
export type STANDARD_REPORTS_LOAD_PROJECT_COMPARISON_REPORT_SUCCESS = typeof STANDARD_REPORTS_LOAD_PROJECT_COMPARISON_REPORT_SUCCESS;

export const STANDARD_REPORTS_LOAD_PROJECT_COMPARISON_REPORT_FAILURE = 'STANDARD_REPORTS_LOAD_PROJECT_COMPARISON_REPORT_FAILURE';
export type STANDARD_REPORTS_LOAD_PROJECT_COMPARISON_REPORT_FAILURE = typeof STANDARD_REPORTS_LOAD_PROJECT_COMPARISON_REPORT_FAILURE;

// standard comparison shopper report action constants
export const STANDARD_REPORTS_LOAD_SHOPPER_COMPARISON_REPORT_REQUEST = 'STANDARD_REPORTS_LOAD_SHOPPER_COMPARISON_REPORT_REQUEST';
export type STANDARD_REPORTS_LOAD_SHOPPER_COMPARISON_REPORT_REQUEST = typeof STANDARD_REPORTS_LOAD_SHOPPER_COMPARISON_REPORT_REQUEST;

export const STANDARD_REPORTS_LOAD_SHOPPER_COMPARISON_REPORT_SUCCESS = 'STANDARD_REPORTS_LOAD_SHOPPER_COMPARISON_REPORT_SUCCESS';
export type STANDARD_REPORTS_LOAD_SHOPPER_COMPARISON_REPORT_SUCCESS = typeof STANDARD_REPORTS_LOAD_SHOPPER_COMPARISON_REPORT_SUCCESS;

export const STANDARD_REPORTS_LOAD_SHOPPER_COMPARISON_REPORT_FAILURE = 'STANDARD_REPORTS_LOAD_SHOPPER_COMPARISON_REPORT_FAILURE';
export type STANDARD_REPORTS_LOAD_SHOPPER_COMPARISON_REPORT_FAILURE = typeof STANDARD_REPORTS_LOAD_SHOPPER_COMPARISON_REPORT_FAILURE;

// standard comparison product report action constants
export const STANDARD_REPORTS_LOAD_PRODUCT_COMPARISON_REPORT_REQUEST = 'STANDARD_REPORTS_LOAD_PRODUCT_COMPARISON_REPORT_REQUEST';
export type STANDARD_REPORTS_LOAD_PRODUCT_COMPARISON_REPORT_REQUEST = typeof STANDARD_REPORTS_LOAD_PRODUCT_COMPARISON_REPORT_REQUEST;

export const STANDARD_REPORTS_LOAD_PRODUCT_COMPARISON_REPORT_SUCCESS = 'STANDARD_REPORTS_LOAD_PRODUCT_COMPARISON_REPORT_SUCCESS';
export type STANDARD_REPORTS_LOAD_PRODUCT_COMPARISON_REPORT_SUCCESS = typeof STANDARD_REPORTS_LOAD_PRODUCT_COMPARISON_REPORT_SUCCESS;

export const STANDARD_REPORTS_LOAD_PRODUCT_COMPARISON_REPORT_FAILURE = 'STANDARD_REPORTS_LOAD_PRODUCT_COMPARISON_REPORT_FAILURE';
export type STANDARD_REPORTS_LOAD_PRODUCT_COMPARISON_REPORT_FAILURE = typeof STANDARD_REPORTS_LOAD_PRODUCT_COMPARISON_REPORT_FAILURE;

// standard comparison brand report action constants
export const STANDARD_REPORTS_LOAD_BRAND_COMPARISON_REPORT_REQUEST = 'STANDARD_REPORTS_LOAD_BRAND_COMPARISON_REPORT_REQUEST';
export type STANDARD_REPORTS_LOAD_BRAND_COMPARISON_REPORT_REQUEST = typeof STANDARD_REPORTS_LOAD_BRAND_COMPARISON_REPORT_REQUEST;

export const STANDARD_REPORTS_LOAD_BRAND_COMPARISON_REPORT_SUCCESS = 'STANDARD_REPORTS_LOAD_BRAND_COMPARISON_REPORT_SUCCESS';
export type STANDARD_REPORTS_LOAD_BRAND_COMPARISON_REPORT_SUCCESS = typeof STANDARD_REPORTS_LOAD_BRAND_COMPARISON_REPORT_SUCCESS;

export const STANDARD_REPORTS_LOAD_BRAND_COMPARISON_REPORT_FAILURE = 'STANDARD_REPORTS_LOAD_BRAND_COMPARISON_REPORT_FAILURE';
export type STANDARD_REPORTS_LOAD_BRAND_COMPARISON_REPORT_FAILURE = typeof STANDARD_REPORTS_LOAD_BRAND_COMPARISON_REPORT_FAILURE;

// standard comparison gpc brick report action constants
export const STANDARD_REPORTS_LOAD_GPC_BRICK_COMPARISON_REPORT_REQUEST = 'STANDARD_REPORTS_LOAD_GPC_BRICK_COMPARISON_REPORT_REQUEST';
export type STANDARD_REPORTS_LOAD_GPC_BRICK_COMPARISON_REPORT_REQUEST = typeof STANDARD_REPORTS_LOAD_GPC_BRICK_COMPARISON_REPORT_REQUEST;

export const STANDARD_REPORTS_LOAD_GPC_BRICK_COMPARISON_REPORT_SUCCESS = 'STANDARD_REPORTS_LOAD_GPC_BRICK_COMPARISON_REPORT_SUCCESS';
export type STANDARD_REPORTS_LOAD_GPC_BRICK_COMPARISON_REPORT_SUCCESS = typeof STANDARD_REPORTS_LOAD_GPC_BRICK_COMPARISON_REPORT_SUCCESS;

export const STANDARD_REPORTS_LOAD_GPC_BRICK_COMPARISON_REPORT_FAILURE = 'STANDARD_REPORTS_LOAD_GPC_BRICK_COMPARISON_REPORT_FAILURE';
export type STANDARD_REPORTS_LOAD_GPC_BRICK_COMPARISON_REPORT_FAILURE = typeof STANDARD_REPORTS_LOAD_GPC_BRICK_COMPARISON_REPORT_FAILURE;

// standard project benchmark report action constants
export const STANDARD_REPORTS_LOAD_PROJECT_BENCHMARK_REPORT_REQUEST = 'STANDARD_REPORTS_LOAD_PROJECT_BENCHMARK_REPORT_REQUEST';
export type STANDARD_REPORTS_LOAD_PROJECT_BENCHMARK_REPORT_REQUEST = typeof STANDARD_REPORTS_LOAD_PROJECT_BENCHMARK_REPORT_REQUEST;

export const STANDARD_REPORTS_LOAD_PROJECT_BENCHMARK_REPORT_SUCCESS = 'STANDARD_REPORTS_LOAD_PROJECT_BENCHMARK_REPORT_SUCCESS';
export type STANDARD_REPORTS_LOAD_PROJECT_BENCHMARK_REPORT_SUCCESS = typeof STANDARD_REPORTS_LOAD_PROJECT_BENCHMARK_REPORT_SUCCESS;

export const STANDARD_REPORTS_LOAD_PROJECT_BENCHMARK_REPORT_FAILURE = 'STANDARD_REPORTS_LOAD_PROJECT_BENCHMARK_REPORT_FAILURE';
export type STANDARD_REPORTS_LOAD_PROJECT_BENCHMARK_REPORT_FAILURE = typeof STANDARD_REPORTS_LOAD_PROJECT_BENCHMARK_REPORT_FAILURE;

// standard client benchmark report action constants
export const STANDARD_REPORTS_LOAD_CLIENT_BENCHMARK_REPORT_REQUEST = 'STANDARD_REPORTS_LOAD_CLIENT_BENCHMARK_REPORT_REQUEST';
export type STANDARD_REPORTS_LOAD_CLIENT_BENCHMARK_REPORT_REQUEST = typeof STANDARD_REPORTS_LOAD_CLIENT_BENCHMARK_REPORT_REQUEST;

export const STANDARD_REPORTS_LOAD_CLIENT_BENCHMARK_REPORT_SUCCESS = 'STANDARD_REPORTS_LOAD_CLIENT_BENCHMARK_REPORT_SUCCESS';
export type STANDARD_REPORTS_LOAD_CLIENT_BENCHMARK_REPORT_SUCCESS = typeof STANDARD_REPORTS_LOAD_CLIENT_BENCHMARK_REPORT_SUCCESS;

export const STANDARD_REPORTS_LOAD_CLIENT_BENCHMARK_REPORT_FAILURE = 'STANDARD_REPORTS_LOAD_CLIENT_BENCHMARK_REPORT_FAILURE';
export type STANDARD_REPORTS_LOAD_CLIENT_BENCHMARK_REPORT_FAILURE = typeof STANDARD_REPORTS_LOAD_CLIENT_BENCHMARK_REPORT_FAILURE;

// potential brand reports actions
export const POTENTIAL_REPORTS_LOAD_BRAND_REPORT_REQUEST = 'POTENTIAL_REPORTS_LOAD_BRAND_REPORT_REQUEST';
export type POTENTIAL_REPORTS_LOAD_BRAND_REPORT_REQUEST = typeof POTENTIAL_REPORTS_LOAD_BRAND_REPORT_REQUEST;

export const POTENTIAL_REPORTS_LOAD_BRAND_REPORT_SUCCESS = 'POTENTIAL_REPORTS_LOAD_BRAND_REPORT_SUCCESS';
export type POTENTIAL_REPORTS_LOAD_BRAND_REPORT_SUCCESS = typeof POTENTIAL_REPORTS_LOAD_BRAND_REPORT_SUCCESS;

export const POTENTIAL_REPORTS_LOAD_BRAND_REPORT_FAILURE = 'POTENTIAL_REPORTS_LOAD_BRAND_REPORT_FAILURE';
export type POTENTIAL_REPORTS_LOAD_BRAND_REPORT_FAILURE = typeof POTENTIAL_REPORTS_LOAD_BRAND_REPORT_FAILURE;

// Product Benchmark report actions
// standard client benchmark report action constants
export const STANDARD_REPORTS_LOAD_PRODUCT_BENCHMARK_REPORT_REQUEST = 'STANDARD_REPORTS_LOAD_PRODUCT_BENCHMARK_REPORT_REQUEST';
export type STANDARD_REPORTS_LOAD_PRODUCT_BENCHMARK_REPORT_REQUEST = typeof STANDARD_REPORTS_LOAD_PRODUCT_BENCHMARK_REPORT_REQUEST;

export const STANDARD_REPORTS_LOAD_PRODUCT_BENCHMARK_REPORT_SUCCESS = 'STANDARD_REPORTS_LOAD_PRODUCT_BENCHMARK_REPORT_SUCCESS';
export type STANDARD_REPORTS_LOAD_PRODUCT_BENCHMARK_REPORT_SUCCESS = typeof STANDARD_REPORTS_LOAD_PRODUCT_BENCHMARK_REPORT_SUCCESS;

export const STANDARD_REPORTS_LOAD_PRODUCT_BENCHMARK_REPORT_FAILURE = 'STANDARD_REPORTS_LOAD_PRODUCT_BENCHMARK_REPORT_FAILURE';
export type STANDARD_REPORTS_LOAD_PRODUCT_BENCHMARK_REPORT_FAILURE = typeof STANDARD_REPORTS_LOAD_PRODUCT_BENCHMARK_REPORT_FAILURE;

// shelf selector action types
export interface LoadPhaseShelvesRequestActionType {
  type: STANDARD_REPORTS_LOAD_PHASE_SHELVES_REQUEST;
}
export interface LoadPhaseShelvesSuccessActionType {
  type: STANDARD_REPORTS_LOAD_PHASE_SHELVES_SUCCESS;
  data: StandardReportPhaseShelf[];
}
export interface LoadPhaseShelvesFailureActionType {
  type: STANDARD_REPORTS_LOAD_PHASE_SHELVES_FAILURE;
  error: ErrorDetails;
}

// load project report action types
export interface LoadStandardProjectReportRequestActionType {
  type: STANDARD_REPORTS_LOAD_PROJECT_REPORT_REQUEST;
  shelfPhaseId: string;
}
export interface LoadStandardProjectReportSuccessActionType {
  type: STANDARD_REPORTS_LOAD_PROJECT_REPORT_SUCCESS;
  shelfPhaseId: string;
  data: ProjectReportResult;
}
export interface LoadStandardProjectReportFailureActionType {
  type: STANDARD_REPORTS_LOAD_PROJECT_REPORT_FAILURE;
  shelfPhaseId: string;
  error: ErrorDetails;
}

// load shopper report action types
export interface LoadStandardShopperReportRequestActionType {
  type: STANDARD_REPORTS_LOAD_SHOPPER_REPORT_REQUEST;
  shelfPhaseId: string;
}
export interface LoadStandardShopperReportSuccessActionType {
  type: STANDARD_REPORTS_LOAD_SHOPPER_REPORT_SUCCESS;
  shelfPhaseId: string;
  data: ShopperReportResult;
}
export interface LoadStandardShopperReportFailureActionType {
  type: STANDARD_REPORTS_LOAD_SHOPPER_REPORT_FAILURE;
  shelfPhaseId: string;
  error: ErrorDetails;
}
// load brand report actiontypes
export interface LoadStandardBrandReportRequestActionType {
  type: STANDARD_REPORTS_LOAD_BRAND_REPORT_REQUEST;
  shelfPhaseId: string;
  brandId: number;
}
export interface LoadStandardBrandReportSuccessActionType {
  type: STANDARD_REPORTS_LOAD_BRAND_REPORT_SUCCESS;
  shelfPhaseId: string;
  brandId: number;
  data: BrandReportResult;
}
export interface LoadStandardBrandReportFailureActionType {
  type: STANDARD_REPORTS_LOAD_BRAND_REPORT_FAILURE;
  shelfPhaseId: string;
  brandId: number;
  error: ErrorDetails;
}
// load product report actiontypes
export interface LoadStandardProductReportRequestActionType {
  type: STANDARD_REPORTS_LOAD_PRODUCT_REPORT_REQUEST;
  shelfPhaseId: string;
  gtin: number;
}
export interface LoadStandardProductReportSuccessActionType {
  type: STANDARD_REPORTS_LOAD_PRODUCT_REPORT_SUCCESS;
  shelfPhaseId: string;
  gtin: number;
  data: ProductReportResult;
}
export interface LoadStandardProductReportFailureActionType {
  type: STANDARD_REPORTS_LOAD_PRODUCT_REPORT_FAILURE;
  shelfPhaseId: string;
  gtin: number;
  error: ErrorDetails;
}

// load gpc brick report action types
export interface LoadStandardGpcBrickReportRequestActionType {
  type: STANDARD_REPORTS_LOAD_GPC_BRICK_REPORT_REQUEST;
  shelfPhaseId: string;
  gpcBrickCode: number;
}
export interface LoadStandardGpcBrickReportSuccessActionType {
  type: STANDARD_REPORTS_LOAD_GPC_BRICK_REPORT_SUCCESS;
  shelfPhaseId: string;
  gpcBrickCode: number;
  data: GpcBrickReportResult;
}
export interface LoadStandardGpcBrickReportFailureActionType {
  type: STANDARD_REPORTS_LOAD_GPC_BRICK_REPORT_FAILURE;
  shelfPhaseId: string;
  gpcBrickCode: number;
  error: ErrorDetails;
}

// load shelf changes report action types
export interface LoadStandardShelfComparisonReportRequestActionType {
  type: STANDARD_REPORTS_LOAD_SHELF_COMPARISON_REPORT_REQUEST;
  shelfPhaseId1: string;
  shelfPhaseId2: string;
}
export interface LoadStandardShelfComparisonReportSuccessActionType {
  type: STANDARD_REPORTS_LOAD_SHELF_COMPARISON_REPORT_SUCCESS;
  data: ShelfComparisonReportResult;
}
export interface LoadStandardShelfComparisonReportFailureActionType {
  type: STANDARD_REPORTS_LOAD_SHELF_COMPARISON_REPORT_FAILURE;
  error: ErrorDetails;
}

// load comparison project report action types
export interface LoadStandardProjectComparisonReportRequestActionType {
  type: STANDARD_REPORTS_LOAD_PROJECT_COMPARISON_REPORT_REQUEST;
  shelfPhaseId1: string;
  shelfPhaseId2: string;
}
export interface LoadStandardProjectComparisonReportSuccessActionType {
  type: STANDARD_REPORTS_LOAD_PROJECT_COMPARISON_REPORT_SUCCESS;
  data: ProjectComparisonReportResult;
}
export interface LoadStandardProjectComparisonReportFailureActionType {
  type: STANDARD_REPORTS_LOAD_PROJECT_COMPARISON_REPORT_FAILURE;
  error: ErrorDetails;
}

// load comparison shopper report action types
export interface LoadStandardShopperComparisonReportRequestActionType {
  type: STANDARD_REPORTS_LOAD_SHOPPER_COMPARISON_REPORT_REQUEST;
  shelfPhaseId1: string;
  shelfPhaseId2: string;
}
export interface LoadStandardShopperComparisonReportSuccessActionType {
  type: STANDARD_REPORTS_LOAD_SHOPPER_COMPARISON_REPORT_SUCCESS;
  data: ShopperComparisonReportResult;
}
export interface LoadStandardShopperComparisonReportFailureActionType {
  type: STANDARD_REPORTS_LOAD_SHOPPER_COMPARISON_REPORT_FAILURE;
  error: ErrorDetails;
}

// load comparison product report action types
export interface LoadStandardProductComparisonReportRequestActionType {
  type: STANDARD_REPORTS_LOAD_PRODUCT_COMPARISON_REPORT_REQUEST;
  shelfPhaseId1: string;
  shelfPhaseId2: string;
  gtin1: number;
  gtin2: number;
}
export interface LoadStandardProductComparisonReportSuccessActionType {
  type: STANDARD_REPORTS_LOAD_PRODUCT_COMPARISON_REPORT_SUCCESS;
  data: ProductComparisonReportResult;
}
export interface LoadStandardProductComparisonReportFailureActionType {
  type: STANDARD_REPORTS_LOAD_PRODUCT_COMPARISON_REPORT_FAILURE;
  error: ErrorDetails;
}

// load comparison brand report action types
export interface LoadStandardBrandComparisonReportRequestActionType {
  type: STANDARD_REPORTS_LOAD_BRAND_COMPARISON_REPORT_REQUEST;
  shelfPhaseId1: string;
  shelfPhaseId2: string;
  brandId1: number;
  brandId2: number;
}
export interface LoadStandardBrandComparisonReportSuccessActionType {
  type: STANDARD_REPORTS_LOAD_BRAND_COMPARISON_REPORT_SUCCESS;
  data: BrandComparisonReportResult;
}
export interface LoadStandardBrandComparisonReportFailureActionType {
  type: STANDARD_REPORTS_LOAD_BRAND_COMPARISON_REPORT_FAILURE;
  error: ErrorDetails;
}

// load comparison gpc brick report action types
export interface LoadStandardGpcBrickComparisonReportRequestActionType {
  type: STANDARD_REPORTS_LOAD_GPC_BRICK_COMPARISON_REPORT_REQUEST;
  shelfPhaseId1: string;
  shelfPhaseId2: string;
  gpcBrickCode1: number;
  gpcBrickCode2: number;
}
export interface LoadStandardGpcBrickComparisonReportSuccessActionType {
  type: STANDARD_REPORTS_LOAD_GPC_BRICK_COMPARISON_REPORT_SUCCESS;
  data: GpcBrickComparisonReportResult;
}
export interface LoadStandardGpcBrickComparisonReportFailureActionType {
  type: STANDARD_REPORTS_LOAD_GPC_BRICK_COMPARISON_REPORT_FAILURE;
  error: ErrorDetails;
}

// load project benchmark report action types
export interface LoadStandardProjectBenchmarkReportRequestActionType {
  type: STANDARD_REPORTS_LOAD_PROJECT_BENCHMARK_REPORT_REQUEST;
  projectId: number;
}
export interface LoadStandardProjectBenchmarkReportSuccessActionType {
  type: STANDARD_REPORTS_LOAD_PROJECT_BENCHMARK_REPORT_SUCCESS;
  projectId: number;
  data: ProjectBenchmarkReportResult;
}
export interface LoadStandardProjectBenchmarkReportFailureActionType {
  type: STANDARD_REPORTS_LOAD_PROJECT_BENCHMARK_REPORT_FAILURE;
  projectId: number;
  error: ErrorDetails;
}

// load client benchmark report action types
export interface LoadStandardClientBenchmarkReportRequestActionType {
  type: STANDARD_REPORTS_LOAD_CLIENT_BENCHMARK_REPORT_REQUEST;
  clientId: number;
}
export interface LoadStandardClientBenchmarkReportSuccessActionType {
  type: STANDARD_REPORTS_LOAD_CLIENT_BENCHMARK_REPORT_SUCCESS;
  clientId: number;
  data: ClientBenchmarkReportResult;
}
export interface LoadStandardClientBenchmarkReportFailureActionType {
  type: STANDARD_REPORTS_LOAD_CLIENT_BENCHMARK_REPORT_FAILURE;
  clientId: number;
  error: ErrorDetails;
}

/// This can not be a good pattern. Having to write 42 interfaces for loading data from cloud?
// TODO: FIX THIS
export interface LoadPotentialBrandReportRequestActionType {
  type: POTENTIAL_REPORTS_LOAD_BRAND_REPORT_REQUEST;
  shelfPhaseId: string;
  brandId: number;
}
export interface LoadPotentialBrandReportSuccessActionType {
  type: POTENTIAL_REPORTS_LOAD_BRAND_REPORT_SUCCESS;
  shelfPhaseId: string;
  brandId: number;
  data: BrandPotentialReport;
}
export interface LoadPotentialBrandReportFailureActionType {
  type: POTENTIAL_REPORTS_LOAD_BRAND_REPORT_FAILURE;
  shelfPhaseId: string;
  brandId: number;
  error: ErrorDetails;
}

// Load Product Benchmark report actions
export interface LoadStandardProductBenchmarkReportRequestActionType {
  type: STANDARD_REPORTS_LOAD_PRODUCT_BENCHMARK_REPORT_REQUEST;
  targetShelfPhaseId: string;
  comparisonShelfPhaseId: string;
  targetGtin: number;
}
export interface LoadStandardProductBenchmarkReportSuccessActionType {
  type: STANDARD_REPORTS_LOAD_PRODUCT_BENCHMARK_REPORT_SUCCESS;
  targetShelfPhaseId: string;
  comparisonShelfPhaseId: string;
  targetGtin: number;
  data: ProductBenchmarkReport;
}
export interface LoadStandardProductBenchmarkReportFailureActionType {
  type: STANDARD_REPORTS_LOAD_PRODUCT_BENCHMARK_REPORT_FAILURE;
  targetShelfPhaseId: string;
  comparisonShelfPhaseId: string;
  targetGtin: number;
  error: ErrorDetails;
}
