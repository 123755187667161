import React from 'react';
import { useSelector } from 'react-redux';
import { Create } from '@material-ui/icons';
import { createStyles, Fab, makeStyles, Theme, Typography } from '@material-ui/core';

import { isCreatingPlanogramFromShelf } from '../../../selectors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  })
);

export interface Props {
  onClick: () => void;
  isFormValid: boolean;
}

const SubmitBtn: React.FC<Props> = ({ onClick, isFormValid }: Props): JSX.Element => {
  const classes = useStyles();
  const isCreating = useSelector(isCreatingPlanogramFromShelf);
  return (
    <Fab data-testid="create-planogram-submit-btn" color="primary" variant="extended" className={classes.fab} aria-label="create" onClick={onClick} disabled={!isFormValid || isCreating}>
      <Create />
      &nbsp;
      <Typography variant="button">{isCreating ? 'Creating...' : 'Create Planogram'}</Typography>
    </Fab>
  );
};

export default SubmitBtn;
