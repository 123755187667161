import React, { useState, useEffect } from 'react';
import { Button, Card, CircularProgress, Divider, Grid, Typography } from '@material-ui/core';
import { Assessment, ListAlt } from '@material-ui/icons';
import { GpcSelector } from './GpcSelector';
import { BrandAndProductSelector } from './BrandAndProductSelector';
import { AggregatedReportButtons } from './AggregatedReportButtons';
import { MetricSelector } from './MetricSelector';
import { downloadAggregatedReportRequest } from '../apiRequests';
import { AvailableBrandBrickReport, AvailableProductReport, SelectorOption, AggregatedMetrics } from '../types';
import _ from 'lodash';
import { getData } from '../selectors';
import { useSelector } from 'react-redux';

type SetLoading = (state: boolean) => void;

const AggregatedReportComponent: React.FC = () => {
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [selectedFamily, setSelectedFamily] = useState<SelectorOption | undefined>();
  const [selectedClass, setSelectedClass] = useState<SelectorOption | undefined>();
  const [selectedBrick, setSelectedBrick] = useState<SelectorOption | undefined>();
  const [selectedBrandOwner, setSelectedBrandOwner] = useState<SelectorOption | undefined>();
  const [selectedBrand, setSelectedBrand] = useState<SelectorOption | undefined>();
  const [selectedProduct, setSelectedProduct] = useState<SelectorOption | undefined>();
  const [selectedGtin, setSelectedGtin] = useState<SelectorOption | undefined>();
  const [selectedMetrics, setSelectedMetrics] = useState<AggregatedMetrics[]>([]);

  const data = useSelector(getData);

  const downloadAsExcel = (apiRequest: Promise<any>, name: string, setLoading: SetLoading): void => {
    setLoading(true);
    apiRequest
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${name}.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      })
      .finally(() => setLoading(false));
  };

  const families = _.orderBy(
    _.uniqBy(
      (selectedBrand || selectedBrandOwner
        ? data?.brandBricks.filter(i => (!selectedBrandOwner || i.brandOwnerId === selectedBrandOwner.value) && (!selectedBrand || i.brandId === selectedBrand.value))
        : data?.bricks
      ).map((i: AvailableBrandBrickReport) => {
        return { value: i.gpcFamilyCode, label: i.family } as SelectorOption;
      }) ?? [],
      'value'
    ),
    ['label']
  );

  const classes = _.orderBy(
    _.uniqBy(
      (selectedBrand || selectedBrandOwner
        ? data?.brandBricks.filter(
            i =>
              (!selectedFamily || i.gpcFamilyCode === selectedFamily.value) &&
              (!selectedBrandOwner || i.brandOwnerId === selectedBrandOwner.value) &&
              (!selectedBrand || i.brandId === selectedBrand.value)
          )
        : data.bricks?.filter(i => (!selectedFamily || i.gpcFamilyCode === selectedFamily.value) && (!selectedClass || i.gpcClassCode === selectedClass.value))
      ).map((i: AvailableBrandBrickReport) => {
        return { value: i.gpcClassCode, label: i.class } as SelectorOption;
      }) ?? [],
      'value'
    ),
    ['label']
  );

  const bricks = _.orderBy(
    _.uniqBy(
      (selectedBrand || selectedBrandOwner
        ? data?.brandBricks.filter(
            i =>
              (!selectedFamily || i.gpcFamilyCode === selectedFamily.value) &&
              (!selectedClass || i.gpcClassCode === selectedClass.value) &&
              (!selectedBrandOwner || i.brandOwnerId === selectedBrandOwner.value) &&
              (!selectedBrand || i.brandId === selectedBrand.value)
          )
        : data.bricks?.filter(i => (!selectedFamily || i.gpcFamilyCode === selectedFamily.value) && (!selectedClass || i.gpcClassCode === selectedClass.value))
      ).map((i: AvailableBrandBrickReport) => {
        return { value: i.gpcBrickCode, label: i.brick } as SelectorOption;
      }) ?? [],
      'value'
    ),
    ['label']
  );

  const brandOwners = _.orderBy(
    _.uniqBy(
      data?.products
        ?.filter(
          i => (!selectedBrick || i.gpcBrickCode === selectedBrick.value) && (!selectedFamily || i.gpcFamilyCode === selectedFamily.value) && (!selectedClass || i.gpcClassCode === selectedClass.value)
        )
        .map((i: AvailableProductReport) => {
          return { value: i.brandOwnerId, label: i.brandOwner } as SelectorOption;
        }) ?? [],
      'value'
    ),
    ['label']
  );

  const brands = _.orderBy(
    _.uniqBy(
      data?.products
        ?.filter(
          i =>
            (!selectedBrick || i.gpcBrickCode === selectedBrick.value) &&
            (!selectedFamily || i.gpcFamilyCode === selectedFamily.value) &&
            (!selectedClass || i.gpcClassCode === selectedClass.value) &&
            (!selectedBrandOwner || i.brandOwnerId === selectedBrandOwner.value)
        )
        .map((i: AvailableProductReport) => {
          return { value: i.brandId, label: i.brand } as SelectorOption;
        }) ?? [],
      'value'
    ),
    ['label']
  );
  const products = _.orderBy(
    _.uniqBy(
      data?.products
        ?.filter(
          i =>
            (!selectedBrick || i.gpcBrickCode === selectedBrick.value) &&
            (!selectedFamily || i.gpcFamilyCode === selectedFamily.value) &&
            (!selectedClass || i.gpcClassCode === selectedClass.value) &&
            (!selectedBrandOwner || i.brandOwnerId === selectedBrandOwner.value) &&
            (!selectedBrand || i.brandId === selectedBrand.value)
        )
        .map((i: AvailableProductReport) => {
          return { value: i.gtin, label: i.product } as SelectorOption;
        }) ?? [],
      'value'
    ),
    ['label']
  );
  const gtins = _.orderBy(
    _.uniqBy(
      data?.products
        ?.filter(
          i =>
            (!selectedBrick || i.gpcBrickCode === selectedBrick.value) &&
            (!selectedFamily || i.gpcFamilyCode === selectedFamily.value) &&
            (!selectedClass || i.gpcClassCode === selectedClass.value) &&
            (!selectedBrandOwner || i.brandOwnerId === selectedBrandOwner.value) &&
            (!selectedBrand || i.brandId === selectedBrand.value)
        )
        .map((i: AvailableProductReport) => {
          return { value: i.gtin, label: `${i.gtin}` } as SelectorOption;
        }) ?? [],
      'value'
    ),
    ['label']
  );

  const handleOnFamilySelected = (selected: SelectorOption): void => {
    setSelectedFamily(selected);
    setSelectedClass(undefined);
    setSelectedBrick(undefined);
  };

  const handleOnClassSelected = (selected: SelectorOption): void => {
    setSelectedClass(selected);
    setSelectedBrick(undefined);
  };
  const handleOnBrickSelected = (selected: SelectorOption): void => {
    setSelectedBrick(selected);
  };

  const handleOnBrandOwnerSelected = (selected: SelectorOption): void => {
    setSelectedBrandOwner(selected);
    setSelectedBrand(undefined);
    setSelectedProduct(undefined);
    setSelectedGtin(undefined);
  };
  const handleOnBrandSelected = (selected: SelectorOption): void => {
    setSelectedBrand(selected);
    setSelectedProduct(undefined);
    setSelectedGtin(undefined);
  };
  const handleOnProductSelected = (selected: SelectorOption): void => {
    setSelectedProduct(selected);
    if (selected) {
      setSelectedGtin({ value: selected.value, label: `${selected.value}` });
    }
  };

  const handleOnGtinSelected = (selected: SelectorOption): void => {
    setSelectedGtin(selected);
  };

  const brand = _.find(data?.brands, p => p.brandId === selectedBrand?.value);
  const brick = _.find(data?.bricks, p => p.gpcBrickCode === selectedBrick?.value);
  const brandBrick = _.find(data?.brandBricks, p => p.brandId === selectedBrand?.value && p.gpcBrickCode === selectedBrick?.value);
  const product = _.find(data?.products, p => p.gtin === selectedGtin?.value);

  useEffect(() => {
    if (brand) {
      setSelectedBrandOwner({ value: brand.brandOwnerId, label: brand.brandOwner });
    }
  }, [selectedBrand, brand, product]);

  useEffect(() => {
    if (brick) {
      setSelectedFamily({ value: brick.gpcFamilyCode, label: brick.family });
      setSelectedClass({ value: brick.gpcClassCode, label: brick.class });
    }
  }, [brick]);

  useEffect(() => {
    if (selectedGtin && product) {
      setSelectedBrand({ value: product.brandId, label: product.brand });
      setSelectedProduct({ value: product.gtin, label: product.product });
      setSelectedBrick({ value: product.gpcBrickCode, label: product.brick });
    }
  }, [selectedGtin, product]);

  const downloadAvailableReports = (evt: any): void => {
    evt.preventDefault();
    try {
      downloadAsExcel(downloadAggregatedReportRequest(), `AvailableAggregatedReports`, setIsLoadingData);
    } catch (e) {
      setIsLoadingData(false);
      alert(`Could not load AvailableAggregatedReports`);
    }
  };

  return (
    <>
      <Card style={{ margin: '8px', padding: '16px' }}>
        <div style={{ margin: '16px' }}>
          <Typography variant="h5" align="center">
            <Assessment fontSize="large" color="primary" />
            &nbsp;Aggregated Report Generator
          </Typography>
          <Divider variant="inset" />
        </div>
        <Grid container direction="column" justify="flex-start" alignItems="center" spacing={2}>
          <Card style={{ margin: '8px', padding: '16px', backgroundColor: '#E0FFFF', width: '100%' }}>
            <Typography variant="h5" color="textPrimary" align="left">
              <ListAlt fontSize="large" color="primary" />
              &nbsp;Aggregated Reports
            </Typography>
            <Grid item container direction="row" justify="space-around" xs={12} spacing={2}>
              <Grid item container direction="column" justify="flex-start" xs={12}>
                <Card style={{ margin: '8px', padding: '16px', backgroundColor: '#e5e4e2', width: '100%' }}>
                  <Card style={{ margin: '8px', padding: '16px' }}>
                    <Grid item container direction="row" justify="flex-start" xs={12}>
                      <Grid item container direction="column" justify="flex-start" xs={3}>
                        <GpcSelector
                          selectedFamily={selectedFamily}
                          selectedClass={selectedClass}
                          selectedBrick={selectedBrick}
                          familyOptions={families}
                          classOptions={classes}
                          brickOptions={bricks}
                          onFamilySelected={handleOnFamilySelected}
                          onClassSelected={handleOnClassSelected}
                          onBrickSelected={handleOnBrickSelected}
                        />
                      </Grid>
                      <Grid item container direction="column" justify="flex-start" xs={3}>
                        <BrandAndProductSelector
                          selectedBrandOwner={selectedBrandOwner}
                          brandOwners={brandOwners}
                          onBrandOwnerSelected={handleOnBrandOwnerSelected}
                          selectedBrand={selectedBrand}
                          brands={brands}
                          onBrandSelected={handleOnBrandSelected}
                          selectedProduct={selectedProduct}
                          products={products}
                          onProductSelected={handleOnProductSelected}
                          selectedGtin={selectedGtin}
                          gtins={gtins}
                          onGtinSelected={handleOnGtinSelected}
                        />
                      </Grid>
                      <Grid item container direction="column" justify="flex-start" xs={3}>
                        <AggregatedReportButtons
                          brand={selectedBrand}
                          brick={selectedBrick}
                          gtin={selectedGtin}
                          isBrickLessThan={brick !== undefined && brick.interactorsCount < 20}
                          isBrandLessThan={brand !== undefined && brand.interactorsCount < 20}
                          isBrandBrickLessThan={brandBrick !== undefined && brandBrick.interactorsCount < 20}
                          isProductLessThan={product !== undefined && product.interactorsCount < 20}
                          requestBrickReport={() => {
                            window.open(`/reports/aggregatedReports/brick/${selectedBrick?.value}/${selectedMetrics.join(',')}`, '_blank');
                          }}
                          requestBrandBrickReport={() => {
                            window.open(`/reports/aggregatedReports/brand/${selectedBrand?.value}/brick/${selectedBrick?.value}/${selectedMetrics.join(',')}`, '_blank');
                          }}
                          requestBrandReport={() => {
                            window.open(`/reports/aggregatedReports/brand/${selectedBrand?.value}/${selectedMetrics.join(',')}`, '_blank');
                          }}
                          requestProductReport={() => {
                            window.open(`/reports/aggregatedReports/product/${selectedGtin?.value}/${selectedMetrics.join(',')}`, '_blank');
                          }}
                          metricSelected={selectedMetrics.length > 0}
                        />
                      </Grid>
                      <Grid item container direction="column" justify="flex-start" xs={3}>
                        <MetricSelector selectedMetrics={selectedMetrics} onSelectedMetricChanged={setSelectedMetrics} />
                      </Grid>
                    </Grid>
                    <Grid item container direction="row" justify="flex-start" xs={12}>
                      <Grid item xs={12}>
                        <Button fullWidth className="text-center" size="large" variant="contained" color="primary" disabled={isLoadingData} onClick={downloadAvailableReports}>
                          Download list of available aggregated reports
                          {isLoadingData && <CircularProgress />}
                        </Button>
                      </Grid>
                    </Grid>
                  </Card>
                </Card>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Card>
    </>
  );
};

export { AggregatedReportComponent };
