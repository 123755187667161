import { Avatar, Badge, Chip, IconButton, Table, TableBody, TableHead, Tooltip, Typography, TableRow, TableCell } from '@material-ui/core';
import { formatDistanceToNow } from 'date-fns';
import { ArrowDropDown, ArrowDropUp, DeleteForever, Edit, Flag, Person, SupervisorAccount } from '@material-ui/icons';
import { orderBy } from 'lodash';
import * as React from 'react';
import { Link } from 'react-router-dom';
import trackComponent from 'infrastructure/tracking/withTracking';
import { isMasterPlanogram, isPlanogramBuilder, isPlanogramOwner } from '../../../planogram/helpers/permissionsHelper';
import PlanogramView from '../../types/PlanogramView';

interface ShelfPlanogramsModalState {
  orderByProperty: string;
  orderByDescending: boolean;
}

class ShelfPlanogramsModal extends React.Component<ShelfPlanogramsModalProps, ShelfPlanogramsModalState> {
  constructor(props: ShelfPlanogramsModalProps) {
    super(props);
    this.state = {
      orderByProperty: 'name',
      orderByDescending: false,
    };
  }

  changeOrderBy(propertyName: string): void {
    const descending = this.state.orderByProperty === propertyName ? !this.state.orderByDescending : false;
    this.setState({
      orderByProperty: propertyName,
      orderByDescending: descending,
    });
  }

  getOrderByIcon(propertyName: string): null | JSX.Element {
    if (this.state.orderByProperty !== propertyName) {
      return null;
    }

    if (this.state.orderByDescending) {
      return <ArrowDropDown />;
    } else {
      return <ArrowDropUp />;
    }
  }

  render(): JSX.Element {
    return this.renderPlanogramsTable();
    /*
    const planogram = this.props.planograms[0];
    return (
      <Dialog maxWidth="xl" open={true} onClose={this.props.onClose}>
        <DialogTitle>
          <Typography variant="overline" gutterBottom>
            <PhotoFilter fontSize="large" />
            &nbsp; Planograms for {planogram.projectName}, Store: {planogram.storeName}, Phase #{planogram.phaseNum},
            Shelf #{planogram.shelfNum}
          </Typography>
          <Divider light variant="middle" />
        </DialogTitle>
        <DialogContent>{this.renderPlanogramsTable()}</DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={this.props.onClose} className="pull-right">
            <Close />
            &nbsp; Close
          </Button>
        </DialogActions>
      </Dialog>
    );
    */
  }

  renderPlanogramsTable(): JSX.Element {
    return (
      <Table size="small">
        <TableHead>
          <TableRow style={{ backgroundColor: '#fafafa' }}>
            <TableCell align="left" style={{ cursor: 'pointer' }} onClick={(): void => this.changeOrderBy('name')}>
              <Typography variant="subtitle1">
                Name
                {this.getOrderByIcon('name')}
              </Typography>
            </TableCell>
            <TableCell align="left" onClick={(): void => this.changeOrderBy('createdOn')} style={{ cursor: 'pointer' }}>
              <Typography variant="subtitle1">
                Created
                {this.getOrderByIcon('createdOn')}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="subtitle1">Role</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="subtitle1">Edit</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="subtitle1">Builders</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="subtitle1">Tests</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="subtitle1">Delete</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{this.renderPlanograms()}</TableBody>
      </Table>
    );
  }

  renderPlanograms(): JSX.Element[] {
    const planograms = orderBy(this.props.planograms, [this.state.orderByProperty], [this.state.orderByDescending ? 'desc' : 'asc']);
    return planograms.map((p: PlanogramView) => {
      return (
        <TableRow key={p.id} style={{ backgroundColor: '#fafafa' }}>
          <TableCell align="left">{p.name}</TableCell>
          <TableCell align="left">{formatDistanceToNow(new Date(p.createdOn), { addSuffix: true })}</TableCell>
          <TableCell align="center">
            {isPlanogramOwner(p.ownerId, p.builderId, this.props.userId) ? (
              <Chip
                color="secondary"
                label="Owner"
                size="small"
                avatar={
                  <Avatar>
                    <Person />
                  </Avatar>
                }
              />
            ) : (
              <React.Fragment>
                {isPlanogramBuilder(p.ownerId, p.builderId, this.props.userId) ? (
                  <Chip
                    color="secondary"
                    label="Builder"
                    size="small"
                    avatar={
                      <Avatar>
                        <Person />
                      </Avatar>
                    }
                  />
                ) : null}
              </React.Fragment>
            )}
          </TableCell>
          <TableCell align="center">
            <Link to={'/planograms/' + p.id}>
              <Tooltip title="Edit Planogram">
                <IconButton>
                  <Edit color="secondary" />
                </IconButton>
              </Tooltip>
            </Link>
          </TableCell>
          {isMasterPlanogram(p.ownerId, p.builderId) ? (
            <TableCell align="center">
              <Tooltip title="View Builder Planograms">
                <IconButton onClick={(): void => this.props.onViewBuilders(p.id)}>
                  <SupervisorAccount color="secondary" />
                </IconButton>
              </Tooltip>
            </TableCell>
          ) : null}
          <TableCell align="center">
            <Tooltip title="View Shelf Tests">
              <IconButton onClick={(): void => this.props.openTestListModal(p.id)}>
                <Badge color="primary" badgeContent={p.testInfoList.length} showZero={true}>
                  <Flag color="secondary" />
                </Badge>
              </IconButton>
            </Tooltip>
          </TableCell>
          <TableCell align="center" padding={'none'} colSpan={12}>
            {isMasterPlanogram(p.ownerId, p.builderId) ? (
              <Tooltip title="Delete Planogram">
                <IconButton onClick={(): void => this.props.onDeletePlanogram(p.id)}>
                  <DeleteForever color="secondary" />
                </IconButton>
              </Tooltip>
            ) : null}
          </TableCell>
        </TableRow>
      );
    });
  }
}

interface ShelfPlanogramsModalProps {
  planograms: PlanogramView[];
  userId: number;

  onDeletePlanogram(planogramId: string): void;

  onViewBuilders(planogramId: string): void;

  openTestListModal(planogramId: string): void;
}

export default trackComponent(ShelfPlanogramsModal, 'PlanogramsListPage_ShelfPlanogramsModal');
