import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRequest } from './state/actions';
import { getFetchError, getIsLoading as isLoadingSelector, getCarouselData, CarouselData, getShelfConditionsData } from './state/selectors';
import { ShelfConditionsTarget, ShelfConditionsResult } from 'modules/shelfTimelapse/state/types';
import * as salesActions from './aside/SalesDataManagement/actions';

export interface HttpRequestChildrenFnArgs {
  isLoading: boolean;
  errorMessage: string;
  shelfConditionsData: ShelfConditionsResult | undefined;
  carouselData: CarouselData | undefined;
}

export interface HttpRequestProps {
  children: (data: HttpRequestChildrenFnArgs) => JSX.Element;
  targetId: string;
  targetType: ShelfConditionsTarget;
}

const HttpRequest: React.FC<HttpRequestProps> = ({ children, targetId, targetType }: HttpRequestProps): JSX.Element => {
  const isLoading = useSelector(isLoadingSelector);
  const errorMessage = useSelector(getFetchError);

  const shelfConditionsData = useSelector(getShelfConditionsData);
  const carouselData = useSelector(getCarouselData);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRequest({ targetId, targetType }));
    dispatch(salesActions.getSalesDataRequest(targetId));
  }, [dispatch, targetId, targetType]);

  return <>{children({ isLoading, errorMessage, shelfConditionsData, carouselData: carouselData })}</>;
};

export default HttpRequest;
