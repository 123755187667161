import React from 'react';
import { Formik, FieldArray } from 'formik';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Tooltip from '@material-ui/core/Tooltip';

import DeleteIcon from '@material-ui/icons/Delete';
import ClearAll from '@material-ui/icons/ClearAll';

import SelectImagesBtn from './SelectImagesBtn';
import SubmitBtn from './SubmitBtn';

import { useStyles } from '../styles';
import { InitialValues, SubmittedValues, FormActions } from './types';
import { uploadInspirationImageForm } from './validation';

interface UploadImagesProps {
  onSubmit: (values: SubmittedValues, formActions: FormActions) => void;
  initialValues: InitialValues;
}

const UploadInspirationImagesForm: React.FC<UploadImagesProps> = ({ onSubmit, initialValues }: UploadImagesProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Formik
      validationSchema={uploadInspirationImageForm}
      initialValues={initialValues}
      onSubmit={(values, { resetForm, setSubmitting }): void => {
        onSubmit(
          {
            inspirationImages: values.inspirationImages.map(item => ({
              imageBase64: item.imageBase64,
              description: item.description,
            })),
          },
          {
            resetForm,
            setSubmitting,
          }
        );
      }}
    >
      {({ handleSubmit, values, handleBlur, handleChange, setFieldValue, isSubmitting, isValid, errors, touched, validateForm }): JSX.Element => {
        const isFilesSelected = values.inspirationImages.length > 0;
        const isAFilesChecked = values.inspirationImages.some(i => i.isSelected === true);
        return (
          <FieldArray name="inspirationImages">
            {({ push, remove }): JSX.Element => {
              return (
                <Grid container direction="column" justify="center" alignItems="center">
                  <Grid style={{ margin: 15 }} container direction="row" justify="space-around" alignItems="center">
                    <SelectImagesBtn
                      onChange={(e): void => {
                        e.target.files &&
                          e.target.files.length > 0 &&
                          Array.from(e.target.files).forEach(file => {
                            const reader = new FileReader();
                            reader.readAsDataURL(file);
                            reader.onload = (): void => {
                              push({
                                imageBase64: reader.result,
                                file: file,
                                description: '',
                                isSelected: false,
                              });
                              validateForm();
                            };
                          });
                      }}
                    />
                    {isFilesSelected && (
                      <Button
                        data-testid="remove-all-selected-images-btn"
                        onClick={(): void => {
                          const nonSelected = values.inspirationImages.filter(i => i.isSelected === false);
                          setFieldValue('inspirationImages', nonSelected);
                        }}
                        disabled={!isAFilesChecked}
                        startIcon={<ClearAll />}
                        variant="contained"
                        color="primary"
                      >
                        Remove selected
                      </Button>
                    )}
                    {isFilesSelected && <SubmitBtn onClick={handleSubmit} isFormValid={isValid} isSubmitting={isSubmitting} />}
                  </Grid>

                  <Grid container direction="row" justify="space-around" wrap="wrap">
                    {values.inspirationImages.map((item, index: number) => {
                      const err = ((errors.inspirationImages || {})[index] || {}).description;
                      const isTouched = ((touched.inspirationImages || {})[index] || {}).description;
                      return (
                        <Card key={index} className={classes.card}>
                          <CardActionArea>
                            <CardMedia className={classes.img} image={URL.createObjectURL(item.file)} title={item.description} component="img" />
                          </CardActionArea>
                          <CardActions>
                            <Checkbox
                              data-testid="upload-inspiration-images-checkbox"
                              checked={values.inspirationImages[index].isSelected}
                              name={`inspirationImages[${index}].isSelected`}
                              onChange={handleChange}
                              color="primary"
                              inputProps={{
                                'aria-label': 'primary checkbox',
                              }}
                            />
                            <TextField
                              onChange={handleChange}
                              error={isTouched && !!err}
                              helperText={isTouched && !!err ? err : ''}
                              onBlur={handleBlur}
                              name={`inspirationImages[${index}].description`}
                              fullWidth
                              placeholder="Store Details"
                            />
                            <Tooltip title="Remove image">
                              <IconButton
                                data-testid="remove-inspiration-image-btn"
                                onClick={(): void => {
                                  remove(index);
                                }}
                              >
                                <DeleteIcon color="secondary" />
                              </IconButton>
                            </Tooltip>
                          </CardActions>
                        </Card>
                      );
                    })}
                  </Grid>
                </Grid>
              );
            }}
          </FieldArray>
        );
      }}
    </Formik>
  );
};

export default UploadInspirationImagesForm;
