import React from 'react';
import { Alert } from 'reactstrap';
import { Typography } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';

export interface AlertWarningIndicatorProps {
  warningText: string;
}

export const AlertWarningIndicator: React.FC<AlertWarningIndicatorProps> = ({ warningText }: AlertWarningIndicatorProps) => {
  return (
    <Alert className="top-buffer" color="warning">
      <Typography variant="caption" color="textPrimary">
        <InfoOutlined color="primary" fontSize="small" />
        &nbsp; {warningText}
      </Typography>
    </Alert>
  );
};
