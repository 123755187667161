import { Badge, Button, ClickAwayListener, Divider, Grid, IconButton, Paper, Tooltip, Typography } from '@material-ui/core';
import { AddPhotoAlternate, Assessment, Chat, FlipToBack, Highlight, Photo, Style, Videocam } from '@material-ui/icons';
import { ConversationPopperIndex } from 'modules/realogram/components/conversation/ConversationPopperIndex';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
interface TimelineItemMenuContentProps extends RouteComponentProps {
  isOpen: boolean;
  projectId: number;
  storeId: number;
  phaseNum: number;
  shelfNum: number;
  projectName: string;
  storeName: string;
  shelfImageUrl: string;
  shelfReferenceId: string;
  isReadyForReporting: boolean;
  projectType: string;
  isShelfConditionsOpen: boolean;
  isConversationPopperOpen: boolean;
  conversationPopperAnchorEl?: Element;
  conversationMessagesLength: number;
  handleOpenShelfConditionsModal(): void;
  handleCloseShelfConditionsModal(): void;
  handleOpenConversationPopper(anchorEl: Element): void;
  handleOnSubmitMessage(message: string): void;
  handleOnEditMessage(shelfReferenceId: string, messageId: string, userId: number, editedMessage: string): void;
  handleOnSubmitDeleteMessage(messageId: string): void;
  handleOnCloseTimelinesMenu(): void;
  onClose(): void;
}
class TimelineItemMenuContent extends React.Component<TimelineItemMenuContentProps> {
  render(): JSX.Element {
    const isReportingDisabled = !this.props.isReadyForReporting;
    return (
      <>
        <ClickAwayListener mouseEvent="onMouseDown" onClickAway={this.props.handleOnCloseTimelinesMenu}>
          <Paper style={{ backgroundColor: 'white' }}>
            <Grid container direction="column" alignContent="center" justify="center" alignItems="center" style={{ maxWidth: '550px' }}>
              <Typography color="textPrimary" variant="overline" align="center">
                {this.props.projectName} - {this.props.storeName} - Shelf {this.props.shelfNum}
              </Typography>
              <a href={this.props.shelfImageUrl} target="_blank" rel="noopener noreferrer">
                <img key={this.props.shelfImageUrl} src={this.props.shelfImageUrl} className="img-responsive" alt="" style={{ height: 'auto', width: 'auto', maxWidth: '100%', maxHeight: '300px' }} />
              </a>
              <Divider />
              <Grid container direction="row" justify="space-evenly">
                <Tooltip title="Realogram">
                  <IconButton size="medium" href={window.location.origin + '/realograms/' + this.props.shelfReferenceId} target="_blank">
                    <FlipToBack color="primary" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Standard Reports">
                  <span>
                    <IconButton
                      size="medium"
                      disabled={isReportingDisabled}
                      href={`${window.location.origin}/reports/standardReports/${this.props.projectId}/${this.props.storeId}/${this.props.shelfNum}/${this.props.phaseNum}`}
                      target="_blank"
                    >
                      <Assessment color={!isReportingDisabled ? 'primary' : 'disabled'} />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title="Shelf Timelapse">
                  <Button size="medium" href={`/shelves/${this.props.shelfReferenceId}/timelapse`} target="_blank">
                    <Videocam color="primary" />
                  </Button>
                </Tooltip>
                {/* TODO: Competitor shelves feature should be re-architectured to live outside of the Planogram context in order to be displayed over a Timeline of shelves. */}
                <Tooltip title="Competitors">
                  <IconButton size="medium">
                    <Style color="primary" />
                  </IconButton>
                </Tooltip>
                {/* TODO: Inspiration shelves feature should be re-architectured to live outside of the Planogram context in order to be displayed over a Timeline of shelves. */}
                <Tooltip title="Inspiration">
                  <IconButton size="medium">
                    <Highlight color="primary" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Planograms">
                  <IconButton size="medium">
                    <Photo color="primary" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Create Planogram">
                  <IconButton size="medium" href={window.location.origin + '/planograms/create/' + this.props.shelfReferenceId} target="_blank">
                    <AddPhotoAlternate color="primary" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Conversation">
                  <IconButton size="medium" onClick={(evt: React.MouseEvent<HTMLElement>): void => this.props.handleOpenConversationPopper(evt.currentTarget)}>
                    <Badge color="secondary" badgeContent={this.props.conversationMessagesLength}>
                      <Chat color="primary" />
                    </Badge>
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Paper>
        </ClickAwayListener>

        {this.props.isConversationPopperOpen && this.props.conversationPopperAnchorEl && (
          <ConversationPopperIndex
            shelfReferenceId={this.props.shelfReferenceId}
            conversationAnchorEl={this.props.conversationPopperAnchorEl}
            isOpen={this.props.isConversationPopperOpen}
            handleOnSubmitMessage={(message: string): void => this.props.handleOnSubmitMessage(message)}
            handleOnSubmitEdit={(messageId: string, userId: number, editedMessage: string): void => this.props.handleOnEditMessage(this.props.shelfReferenceId, messageId, userId, editedMessage)}
            handleOnSubmitDelete={(messageId: string): void => this.props.handleOnSubmitDeleteMessage(messageId)}
          />
        )}
      </>
    );
  }
}
export default withRouter(TimelineItemMenuContent);
