import { Grid } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { toMaxPrecision, toMaxPrecisionPercent } from 'utils/Helpers';
import flowLogo from '../../../../../assets/img/brand/flow.jpg';
import { GpcBrickComparisonReportResult } from '../../types';
import '../styles/StandardReportStyle.css';
interface StandardGpcBrickComparisonReportPageProps {
  gpcBrickComparisonReport: GpcBrickComparisonReportResult;
}
const StandardGpcBrickComparisonReportPage: React.FC<StandardGpcBrickComparisonReportPageProps> = ({ gpcBrickComparisonReport }: StandardGpcBrickComparisonReportPageProps) => {
  const overviewTitleReport = gpcBrickComparisonReport.overviewTitleReport;
  const overviewRatesReport = gpcBrickComparisonReport.overviewRatesReport;
  const overviewDailyAveragesReport = gpcBrickComparisonReport.overviewDailyAveragesReport;
  const overviewShopperAveragesReport = gpcBrickComparisonReport.overviewShopperAveragesReport;
  const internalMetricsReport = gpcBrickComparisonReport.internalMetricsReport;
  const demographicsReport = gpcBrickComparisonReport.demographicsReport;
  const gpcBrick1ExternalBoughtTogetherReport = _.orderBy(gpcBrickComparisonReport.gpcBrick1ExternalBoughtTogetherReport, 'numberOfShoppers', 'desc');
  const gpcBrick1ExternalWinningOverReport = _.orderBy(gpcBrickComparisonReport.gpcBrick1ExternalWinningOverReport, 'numberOfShoppers', 'desc');
  const gpcBrick1ExternalLosingToReport = _.orderBy(gpcBrickComparisonReport.gpcBrick1ExternalLosingToReport, 'numberOfShoppers', 'desc');
  const gpcBrick2ExternalBoughtTogetherReport = _.orderBy(gpcBrickComparisonReport.gpcBrick2ExternalBoughtTogetherReport, 'numberOfShoppers', 'desc');
  const gpcBrick2ExternalWinningOverReport = _.orderBy(gpcBrickComparisonReport.gpcBrick2ExternalWinningOverReport, 'numberOfShoppers', 'desc');
  const gpcBrick2ExternalLosingToReport = _.orderBy(gpcBrickComparisonReport.gpcBrick2ExternalLosingToReport, 'numberOfShoppers', 'desc');

  return (
    <div className="page">
      <div id="flowLogo">
        <img alt="flow-logo" className="img-responsive" src={flowLogo} />
      </div>
      <div style={{ width: '100%' }}>
        <h3 className="text-center">
          Gpc Brick Comparison Report
          <br />
        </h3>
        <h5 className="text-center">
          <p style={{ margin: '0px' }}>
            <b>Shelf A: </b> {overviewTitleReport.projectNameA}, {overviewTitleReport.storeNameA}, Phase {overviewTitleReport.phaseNumA}, Shelf {overviewTitleReport.shelfNumA} (
            <i>
              {moment(overviewTitleReport.dateFromA).format('DD/MM/YYYY')} - {moment(overviewTitleReport.dateToA).format('DD/MM/YYYY')}
            </i>
            )
          </p>
          <p style={{ margin: '0px' }}>
            <b>Shelf B: </b> {overviewTitleReport.projectNameB}, {overviewTitleReport.storeNameB}, Phase {overviewTitleReport.phaseNumB}, Shelf {overviewTitleReport.shelfNumB} (
            <i>
              {moment(overviewTitleReport.dateFromB).format('DD/MM/YYYY')} - {moment(overviewTitleReport.dateToB).format('DD/MM/YYYY')}
            </i>
            )
          </p>
          <br />
        </h5>
        <h5 className="text-left">
          <Grid container direction="row" justify="space-between" spacing={2}>
            <Grid item>
              <p style={{ margin: '0px' }}>
                <b>Shelf A Visitors:</b> {overviewTitleReport.totalVisitorsA}
              </p>
              <p style={{ margin: '0px' }}>
                <b>Shelf B Visitors:</b> {overviewTitleReport.totalVisitorsB}
              </p>
              <br />
            </Grid>
            <Grid item>
              <p style={{ margin: '0px' }}>
                <b>Gpc Brick A</b>
              </p>
              <p style={{ margin: '0px' }}>{overviewTitleReport.gpcBrickDescriptionA}</p>
              <p style={{ margin: '0px' }}>Interactors: {overviewTitleReport.totalGpcInteractorsA}</p>
            </Grid>
            <Grid item>
              <p style={{ margin: '0px' }}>
                <b>Gpc Brick B</b>
              </p>
              <p style={{ margin: '0px' }}>{overviewTitleReport.gpcBrickDescriptionB}</p>
              <p style={{ margin: '0px' }}>Interactors: {overviewTitleReport.totalGpcInteractorsB}</p>
            </Grid>
          </Grid>
        </h5>
        <div className="row">
          <table style={{ marginTop: '10px' }} className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-center metricNameCell">Gpc Brick Metrics</td>
                <td className="text-center metricNameCell">Conversion rate(percent)</td>
                <td className="text-center metricNameCell">Turnover per visitor(amount)</td>
                <td className="text-center metricNameCell">Daily turnover(average amount)</td>
                <td className="text-center metricNameCell">Destination buyers(percent)</td>
                <td className="text-center metricNameCell">Return ratio(percent)</td>
                <td className="text-center metricNameCell">Gpc Brick lost shoppers(percent)</td>
                <td className="text-center metricNameCell">Gpc Brick out of shelf(daily hours)</td>
                <td className="text-center metricNameCell">Gpc Brick out of stock(percent)</td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">% change</td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewRatesReport.gpcConversionRatePercChange, 2)} {overviewRatesReport.gpcConversionRateSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewShopperAveragesReport.gpcTurnoverPerVisitorPercChange, 2)} {overviewShopperAveragesReport.gpcTurnoverPerVisitorSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewDailyAveragesReport.gpcDailyTurnoverPercChange, 2)} {overviewDailyAveragesReport.gpcDailyTurnoverSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewRatesReport.gpcDestinationBuyersPercChange, 2)} {overviewRatesReport.gpcDestinationBuyersSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewRatesReport.gpcReturnRatioPercChange, 2)} {overviewRatesReport.gpcReturnRatioSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewRatesReport.gpcLostShoppersPercChange, 2)} {overviewRatesReport.gpcLostShoppersSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewDailyAveragesReport.gpcOutOfShelfHoursPercChange, 2)} {overviewDailyAveragesReport.gpcOutOfShelfHoursSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewDailyAveragesReport.gpcOutOfStockPercentPercChange, 2)} {overviewDailyAveragesReport.gpcOutOfStockPercentSignificance}
                </td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">Gpc Brick A</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.gpcConversionRateA, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewShopperAveragesReport.gpcTurnoverPerVisitorA, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewDailyAveragesReport.gpcDailyTurnoverA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.gpcDestinationBuyersA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.gpcReturnRatioA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.gpcLostShoppersA, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewDailyAveragesReport.gpcOutOfShelfHoursA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewDailyAveragesReport.gpcOutOfStockPercentA, 2)}</td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">Gpc Brick B</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.gpcConversionRateB, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewShopperAveragesReport.gpcTurnoverPerVisitorB, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewDailyAveragesReport.gpcDailyTurnoverB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.gpcDestinationBuyersB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.gpcReturnRatioB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.gpcLostShoppersB, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewDailyAveragesReport.gpcOutOfShelfHoursB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewDailyAveragesReport.gpcOutOfStockPercentB, 2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row">
          <table style={{ marginTop: '10px' }} className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-center metricNameCell">Gpc Brick Metrics</td>
                <td className="text-center metricNameCell">Share of space</td>
                <td className="text-center metricNameCell">Share of unit sales</td>
                <td className="text-center metricNameCell">Share of turnover</td>
                <td className="text-center metricNameCell">Share of first pickups</td>
                <td className="text-center metricNameCell">Discovery time(all shoppers)</td>
                <td className="text-center metricNameCell">Seconds to buy and leave(buyers)</td>
                <td className="text-center metricNameCell">Seconds to touch and reject(non-buyers)</td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">% change</td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewRatesReport.shareOfSpacePercChange, 2)} {overviewRatesReport.shareOfSpaceSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewRatesReport.shareOfUnitSalesPercChange, 2)} {overviewRatesReport.shareOfUnitSalesSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewRatesReport.shareOfTurnoverPercChange, 2)} {overviewRatesReport.shareOfTurnoverSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewRatesReport.shareOfFirstPickupsPercChange, 2)} {overviewRatesReport.shareOfFirstPickupsSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewShopperAveragesReport.discoveryTimePercChange, 2)} {overviewShopperAveragesReport.discoveryTimeSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewShopperAveragesReport.secondsToBuyAndLeavePercChange, 2)} {overviewShopperAveragesReport.secondsToBuyAndLeaveSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewShopperAveragesReport.secondsToRejectPercChange, 2)} {overviewShopperAveragesReport.secondsToRejectSignificance}
                </td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">Gpc Brick A</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.shareOfSpaceA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.shareOfUnitSalesA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.shareOfTurnoverA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.shareOfFirstPickupsA, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewShopperAveragesReport.discoveryTimeA, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewShopperAveragesReport.secondsToBuyAndLeaveA, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewShopperAveragesReport.secondsToRejectA, 2)}</td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">Gpc Brick B</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.shareOfSpaceB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.shareOfUnitSalesB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.shareOfTurnoverB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.shareOfFirstPickupsB, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewShopperAveragesReport.discoveryTimeB, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewShopperAveragesReport.secondsToBuyAndLeaveB, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewShopperAveragesReport.secondsToRejectB, 2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row">
          <table className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-center metricNameCell">Gpc Brick metrics</td>
                <td className="text-center metricNameCell">External substitutors</td>
                <td className="text-center metricNameCell">Internal substitutors</td>
                <td className="text-center metricNameCell">Internal multiple purchasers</td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">% change</td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewShopperAveragesReport?.substitutionsPercChange, 2)} {overviewShopperAveragesReport?.substitutionsSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(internalMetricsReport?.internalSubstitutorsPercChange, 2)} {internalMetricsReport?.internalSubstitutorsSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(internalMetricsReport?.internalBuysMoreThanOneProductsPercChange, 2)} {internalMetricsReport?.internalBuysMoreThanOneProductsSignificance}
                </td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">Gpc Brick A</td>
                <td className="text-center">{toMaxPrecision(overviewShopperAveragesReport?.substitutionsA, 2)}</td>
                <td className="text-center">{toMaxPrecision(internalMetricsReport?.internalSubstitutorsA, 2)}</td>
                <td className="text-center">{toMaxPrecision(internalMetricsReport?.internalBuysMoreThanOneProductsA, 2)}</td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">Gpc Brick B</td>
                <td className="text-center">{toMaxPrecision(overviewShopperAveragesReport?.substitutionsB, 2)}</td>
                <td className="text-center">{toMaxPrecision(internalMetricsReport?.internalSubstitutorsB, 2)}</td>
                <td className="text-center">{toMaxPrecision(internalMetricsReport?.internalBuysMoreThanOneProductsB, 2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row">
          <table className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-center metricNameCell" colSpan={3}>
                  Gpc Brick A - {overviewTitleReport.gpcBrickDescriptionA}
                </td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">Mostly bought with</td>
                <td className="text-center metricNameCell">Mostly losing to</td>
                <td className="text-center metricNameCell">Mostly winning over</td>
              </tr>
              <tr>
                <td className="text-center">
                  {gpcBrick1ExternalBoughtTogetherReport.length >= 1
                    ? `
                    ${gpcBrick1ExternalBoughtTogetherReport[0].comparisonGpc} (
                    ${gpcBrick1ExternalBoughtTogetherReport[0].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {gpcBrick1ExternalLosingToReport.length >= 1
                    ? `
                    ${gpcBrick1ExternalLosingToReport[0].losingToGpc} (
                    ${gpcBrick1ExternalLosingToReport[0].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {gpcBrick1ExternalWinningOverReport.length >= 1
                    ? `
                    ${gpcBrick1ExternalWinningOverReport[0].winningOverGpc} (
                    ${gpcBrick1ExternalWinningOverReport[0].numberOfShoppers})`
                    : ''}
                </td>
              </tr>
              <tr>
                <td className="text-center">
                  {gpcBrick1ExternalBoughtTogetherReport.length >= 2
                    ? `
                    ${gpcBrick1ExternalBoughtTogetherReport[1].comparisonGpc} (
                    ${gpcBrick1ExternalBoughtTogetherReport[1].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {gpcBrick1ExternalLosingToReport.length >= 2
                    ? `
                    ${gpcBrick1ExternalLosingToReport[1].losingToGpc} (
                    ${gpcBrick1ExternalLosingToReport[1].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {gpcBrick1ExternalWinningOverReport.length >= 2
                    ? `
                    ${gpcBrick1ExternalWinningOverReport[1].winningOverGpc} (
                    ${gpcBrick1ExternalWinningOverReport[1].numberOfShoppers})`
                    : ''}
                </td>
              </tr>
              <tr>
                <td className="text-center">
                  {gpcBrick1ExternalBoughtTogetherReport.length >= 3
                    ? `
                    ${gpcBrick1ExternalBoughtTogetherReport[2].comparisonGpc} (
                    ${gpcBrick1ExternalBoughtTogetherReport[2].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {gpcBrick1ExternalLosingToReport.length >= 3
                    ? `
                    ${gpcBrick1ExternalLosingToReport[2].losingToGpc} (
                    ${gpcBrick1ExternalLosingToReport[2].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {gpcBrick1ExternalWinningOverReport.length >= 3
                    ? `
                    ${gpcBrick1ExternalWinningOverReport[2].winningOverGpc} (
                    ${gpcBrick1ExternalWinningOverReport[2].numberOfShoppers})`
                    : ''}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row">
          <table className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-center metricNameCell" colSpan={3}>
                  Gpc Brick B - {overviewTitleReport.gpcBrickDescriptionB}
                </td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">Mostly bought with</td>
                <td className="text-center metricNameCell">Mostly losing to</td>
                <td className="text-center metricNameCell">Mostly winning over</td>
              </tr>
              <tr>
                <td className="text-center">
                  {gpcBrick2ExternalBoughtTogetherReport.length >= 1
                    ? `
                    ${gpcBrick2ExternalBoughtTogetherReport[0].comparisonGpc} (
                    ${gpcBrick2ExternalBoughtTogetherReport[0].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {gpcBrick2ExternalLosingToReport.length >= 1
                    ? `
                    ${gpcBrick2ExternalLosingToReport[0].losingToGpc} (
                    ${gpcBrick2ExternalLosingToReport[0].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {gpcBrick2ExternalWinningOverReport.length >= 1
                    ? `
                    ${gpcBrick2ExternalWinningOverReport[0].winningOverGpc} (
                    ${gpcBrick2ExternalWinningOverReport[0].numberOfShoppers})`
                    : ''}
                </td>
              </tr>
              <tr>
                <td className="text-center">
                  {gpcBrick2ExternalBoughtTogetherReport.length >= 2
                    ? `
                    ${gpcBrick2ExternalBoughtTogetherReport[1].comparisonGpc} (
                    ${gpcBrick2ExternalBoughtTogetherReport[1].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {gpcBrick2ExternalLosingToReport.length >= 2
                    ? `
                    ${gpcBrick2ExternalLosingToReport[1].losingToGpc} (
                    ${gpcBrick2ExternalLosingToReport[1].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {gpcBrick2ExternalWinningOverReport.length >= 2
                    ? `
                    ${gpcBrick2ExternalWinningOverReport[1].winningOverGpc} (
                    ${gpcBrick2ExternalWinningOverReport[1].numberOfShoppers})`
                    : ''}
                </td>
              </tr>
              <tr>
                <td className="text-center">
                  {gpcBrick2ExternalBoughtTogetherReport.length >= 3
                    ? `
                    ${gpcBrick2ExternalBoughtTogetherReport[2].comparisonGpc} (
                    ${gpcBrick2ExternalBoughtTogetherReport[2].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {gpcBrick2ExternalLosingToReport.length >= 3
                    ? `
                    ${gpcBrick2ExternalLosingToReport[2].losingToGpc} (
                    ${gpcBrick2ExternalLosingToReport[2].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {gpcBrick2ExternalWinningOverReport.length >= 3
                    ? `
                    ${gpcBrick2ExternalWinningOverReport[2].winningOverGpc} (
                    ${gpcBrick2ExternalWinningOverReport[2].numberOfShoppers})`
                    : ''}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row">
          <table style={{ marginTop: '10px' }} className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-center metricNameCell">Demographics</td>
                <td className="text-center metricNameCell" colSpan={2}>
                  Interactors
                </td>
                <td className="text-center metricNameCell" colSpan={2}>
                  Buyers
                </td>
                <td className="text-center metricNameCell" colSpan={2}>
                  Lost shoppers
                </td>
              </tr>

              <tr>
                <td className="text-center metricNameCell" rowSpan={2}>
                  All
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.allInteractorsPercChange, 2)} {demographicsReport.allInteractorsSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.allBuyersPercChange, 2)} {demographicsReport.allBuyersSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.allLostShoppersPercChange, 2)} {demographicsReport.allLostShoppersSignificance}
                </td>
              </tr>
              <tr>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.allInteractorsA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.allInteractorsB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.allBuyersA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.allBuyersB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.allLostShoppersA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.allLostShoppersB, 2)}</td>
              </tr>
              <tr>
                <td className="text-center metricNameCell" rowSpan={2}>
                  Female
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.femaleInteractorsPercChange, 2)} {demographicsReport.femaleInteractorsSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.femaleBuyersPercChange, 2)} {demographicsReport.femaleBuyersSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.femaleLostShoppersPercChange, 2)} {demographicsReport.femaleLostShoppersSignificance}
                </td>
              </tr>
              <tr>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.femaleInteractorsA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.femaleInteractorsB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.femaleBuyersA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.femaleBuyersB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.femaleLostShoppersA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.femaleLostShoppersB, 2)}</td>
              </tr>

              <tr>
                <td className="text-center metricNameCell" rowSpan={2}>
                  Male
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.maleInteractorsPercChange, 2)} {demographicsReport.maleInteractorsSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.maleBuyersPercChange, 2)} {demographicsReport.maleBuyersSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.maleLostShoppersPercChange, 2)} {demographicsReport.maleLostShoppersSignificance}
                </td>
              </tr>
              <tr>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.maleInteractorsA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.maleInteractorsB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.maleBuyersA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.maleBuyersB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.maleLostShoppersA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.maleLostShoppersB, 2)}</td>
              </tr>

              <tr>
                <td className="text-center metricNameCell" rowSpan={2}>
                  WithKids
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.withKidsInteractorsPercChange, 2)} {demographicsReport.withKidsInteractorsSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.withKidsBuyersPercChange, 2)} {demographicsReport.withKidsBuyersSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.withKidsLostShoppersPercChange, 2)} {demographicsReport.withKidsLostShoppersSignificance}
                </td>
              </tr>
              <tr>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.withKidsInteractorsA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.withKidsInteractorsB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.withKidsBuyersA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.withKidsBuyersB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.withKidsLostShoppersA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.withKidsLostShoppersB, 2)}</td>
              </tr>

              <tr>
                <td className="text-center metricNameCell" rowSpan={2}>
                  Group
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.groupInteractorsPercChange, 2)} {demographicsReport.groupInteractorsSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.groupBuyersPercChange, 2)} {demographicsReport.groupBuyersSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.groupLostShoppersPercChange, 2)} {demographicsReport.groupLostShoppersSignificance}
                </td>
              </tr>
              <tr>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.groupInteractorsA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.groupInteractorsB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.groupBuyersA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.groupBuyersB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.groupLostShoppersA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.groupLostShoppersB, 2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <footer>
        <strong>Copyright &copy; {new Date().getFullYear()} Flow Insights</strong>. Strictly confidential. No distribution allowed. All rights reserved.
      </footer>
    </div>
  );
};

export { StandardGpcBrickComparisonReportPage };
