import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MUISwitch from '@material-ui/core/Switch';
import CircularProgress from '@material-ui/core/CircularProgress';

interface InspirationImagesSwitchProps {
  checked: boolean;
  disabled: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InspirationImagesSwitch: React.FC<InspirationImagesSwitchProps> = ({ checked, disabled, onChange }: InspirationImagesSwitchProps): JSX.Element => {
  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Typography>&nbsp; {checked ? 'Disable' : 'Enable'} Inspiration Images</Typography>
      <MUISwitch
        title={`${checked ? 'Disable' : 'Enable'} Inspiration Images`}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        value="checkedB"
        color="primary"
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
      {disabled && <CircularProgress color="primary" size={20} />}
    </Grid>
  );
};

export default InspirationImagesSwitch;
