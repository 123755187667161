import Command from './Command';
import UndoableCommand from './UndoableCommand';
import CommandManager from './CommandManager';

export default class StackedCommandManager<TEvent> implements CommandManager<TEvent> {
  private commandStack: Command<TEvent>[] = [];

  executeCommand(cmd: Command<TEvent>): TEvent | undefined {
    try {
      const event = cmd.execute();
      if (cmd instanceof UndoableCommand) {
        this.commandStack.push(cmd);
      }
      return event;
    } catch (error) {
      console.log(`Failed to execute a command: ${error}`);
      return undefined;
    }
  }

  undo(): TEvent | undefined {
    if (this.commandStack.length > 0) {
      const cmd: UndoableCommand<TEvent> = this.commandStack.pop() as UndoableCommand<TEvent>;
      const event = cmd.undo();
      return event;
    }
    return undefined;
  }
}
