import { Type } from 'class-transformer';
import TestInfoDto from '../../planogram/types/TestInfoDto';

export default class PlanogramView {
  id = '';
  shelfReferenceId = '';
  name = '';
  email = '';
  createdOn: Date = new Date();

  @Type(() => Date)
  lastOpenedByBuilder?: Date;

  @Type(() => Date)
  lastEditedByBuilder?: Date;
  ownerId = 0;
  builderId = 0;
  imageUrl = '';
  projectName = '';
  storeName = '';
  phaseNum = 0;
  shelfNum = 0;

  testInfoList: TestInfoDto[] = [];
  goal = '';
}
