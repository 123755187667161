import { isError, isLoaded, isLoading } from 'infrastructure/utils/RemoteObjectStatus';
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRequestAction } from '../actions';
import * as selectors from '../selectors';

export interface Props {
  realogramId: string;
  children: (params: ChildrenParams) => React.ReactNode;
}

export interface ChildrenParams {
  isLoaded: boolean;
  isError: boolean;
  errorMessage: string;
  isLoading: boolean;
}

const LoadRealogramComponent: React.FC<Props> = ({ children, realogramId }: Props): JSX.Element => {
  const loadState = useSelector(selectors.realogramLoadingState);

  const dispatch = useDispatch();
  useEffect(() => {
    if (realogramId) {
      dispatch(fetchRequestAction(realogramId));
    }
  }, [dispatch, realogramId]);
  return (
    <>
      {children({
        // TODO maybe just pass the RemoteObjectInterface?
        isLoaded: isLoaded(loadState),
        isError: isError(loadState),
        errorMessage: loadState.errorDetails?.errorMessage ?? '',
        isLoading: isLoading(loadState),
      })}
    </>
  );
};

export default LoadRealogramComponent;
