import { isLoaded, isLoading } from 'infrastructure/utils/RemoteObjectStatus';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { loadStandardShopperReportRequestAction } from '../../actions';
import * as selectors from '../../selectors';
import { ShopperReportResult } from '../../types';
interface LoadStatusParams {
  isLoaded: boolean;
  isLoading: boolean;
  loadError: string;
  isDataEmpty: boolean;
  data: ShopperReportResult | undefined;
}
export interface StandardShopperReportLoaderProps {
  children: (params: LoadStatusParams) => React.ReactNode;
}

const StandardShopperReportLoader: React.FC<StandardShopperReportLoaderProps> = ({ children }: StandardShopperReportLoaderProps) => {
  const { shelfPhaseId } = useParams();
  const dispatch = useDispatch();
  const loadState = useSelector(selectors.getShopperReportLoadState);
  const isDataEmpty = useSelector(selectors.isShopperReportEmpty);
  const data = useSelector(selectors.getShopperReportData);
  useEffect(() => {
    if (isDataEmpty && shelfPhaseId) {
      dispatch(loadStandardShopperReportRequestAction(shelfPhaseId));
    }
  }, [dispatch, isDataEmpty, shelfPhaseId]);
  return (
    <>
      {children({
        isLoaded: isLoaded(loadState),
        isLoading: isLoading(loadState),
        loadError: loadState?.errorDetails?.errorMessage ?? '',
        isDataEmpty,
        data,
      })}
    </>
  );
};

export { StandardShopperReportLoader };
