import moment from 'moment';
import React from 'react';
import flowLogo from '../../../../../assets/img/brand/flow.jpg';
import { ShopperReportResult } from '../../types';
import './StandardShopperReportPage.css';
import '../styles/StandardReportStyle.css';
export interface StandardShopperReportPageProps {
  shopperReport: ShopperReportResult;
}

const StandardShopperReportPage: React.FC<StandardShopperReportPageProps> = ({ shopperReport }: StandardShopperReportPageProps) => {
  const shoppersOverviewReport = shopperReport.shoppersOverviewReport;
  const shoppersDemographicsReport = shopperReport.shoppersDemographicsReport;
  const shoppingWithReport = shopperReport.shoppersShoppingWithReport;
  const shoppingTimeReport = shopperReport.shoppingTimeReport;
  return (
    <div className="page">
      <div id="flowLogo">
        <img alt="flow-logo" className="img-responsive" src={flowLogo} />
      </div>
      <div style={{ width: '100%' }}>
        <h3 className="text-center">Shopper report</h3>
        <h5 className="text-center">
          {shoppersOverviewReport.projectName} - {shoppersOverviewReport.storeName} - Phase {shoppersOverviewReport.phaseNum}
          <br />
          {moment(shoppersOverviewReport.dateFrom).format('DD/MM/YYYY')} - {moment(shoppersOverviewReport.dateTo).format('DD/MM/YYYY')}
        </h5>
        <h5 className="text-left">
          <b>Visitors:</b> {shoppersOverviewReport.totalVisitors}
        </h5>
        <div className="row">
          <table style={{ marginTop: '10px' }} className="table table-bordered table-striped">
            <tbody>
              <tr>
                <th style={{ color: '#001f5c' }} className="text-center" colSpan={2} scope="colgroup">
                  Conversion funnel
                </th>
                <th style={{ color: '#001f5c' }} className="text-center" colSpan={3} scope="colgroup">
                  Basket/cart/hands distribution
                </th>
              </tr>
              <tr>
                <td className="text-left metricNameCell"></td>
                <td className="text-center metricNameCell">Total</td>
                <td className="text-center metricNameCell">Shopping with basket</td>
                <td className="text-center metricNameCell">Shopping with cart</td>
                <td className="text-center metricNameCell">Shopping with hands</td>
              </tr>
              <tr>
                <td className="text-left metricNameCell">Visitors(at shelf)</td>
                <td className="text-center bold bordered">
                  {shoppersOverviewReport.visitorsShare.toLocaleString('en', {
                    style: 'percent',
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td className="text-center">
                  {shoppingWithReport.find(x => x.shoppingWith === 'Shopping with basket')?.visitorsShare.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppingWithReport.find(x => x.shoppingWith === 'Shopping with cart')?.visitorsShare.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppingWithReport.find(x => x.shoppingWith === 'Shopping with hands')?.visitorsShare.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
              </tr>
              <tr>
                <td className="text-left metricNameCell">Browsers(interest)</td>
                <td className="text-center bold bordered">
                  {shoppersOverviewReport.shownInterestShare?.toLocaleString('en', {
                    style: 'percent',
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td className="text-center">
                  {shoppingWithReport.find(x => x.shoppingWith === 'Shopping with basket')?.shownInterestShare.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppingWithReport.find(x => x.shoppingWith === 'Shopping with cart')?.shownInterestShare.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppingWithReport.find(x => x.shoppingWith === 'Shopping with hands')?.shownInterestShare.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
              </tr>
              <tr>
                <td className="text-left metricNameCell">Shoppers(interact)</td>
                <td className="text-center bold bordered">
                  {shoppersOverviewReport.interactedShare?.toLocaleString('en', {
                    style: 'percent',
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td className="text-center">
                  {shoppingWithReport.find(x => x.shoppingWith === 'Shopping with basket')?.interactedShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppingWithReport.find(x => x.shoppingWith === 'Shopping with cart')?.interactedShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppingWithReport.find(x => x.shoppingWith === 'Shopping with hands')?.interactedShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
              </tr>
              <tr>
                <td className="text-left metricNameCell">Buyers(purchase)</td>
                <td className="text-center bold bordered">
                  {shoppersOverviewReport.purchasedShare?.toLocaleString('en', {
                    style: 'percent',
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td className="text-center">
                  {shoppingWithReport.find(x => x.shoppingWith === 'Shopping with basket')?.purchasedShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppingWithReport.find(x => x.shoppingWith === 'Shopping with cart')?.purchasedShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppingWithReport.find(x => x.shoppingWith === 'Shopping with hands')?.purchasedShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
              </tr>
              <tr style={{ height: '8px' }} />
              <tr>
                <td className="text-left metricNameCell">Lost shoppers</td>
                <td className="text-center">
                  {shoppersOverviewReport.lostShoppersShare?.toLocaleString('en', {
                    style: 'percent',
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td className="text-center">
                  {shoppingWithReport.find(x => x.shoppingWith === 'Shopping with basket')?.lostShoppersShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppingWithReport.find(x => x.shoppingWith === 'Shopping with cart')?.lostShoppersShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppingWithReport.find(x => x.shoppingWith === 'Shopping with hands')?.lostShoppersShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
              </tr>
              <tr>
                <td className="text-left metricNameCell">Assisted browsers</td>
                <td className="text-center">
                  {shoppersOverviewReport.assistedBrowsersShare?.toLocaleString('en', {
                    style: 'percent',
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td className="text-center">
                  {shoppingWithReport.find(x => x.shoppingWith === 'Shopping with basket')?.assistedBrowsersShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppingWithReport.find(x => x.shoppingWith === 'Shopping with cart')?.assistedBrowsersShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppingWithReport.find(x => x.shoppingWith === 'Shopping with hands')?.assistedBrowsersShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
              </tr>
              <tr>
                <td className="text-left metricNameCell">Conversion from assistance</td>
                <td className="text-center">
                  {shoppersOverviewReport.conversionFromAssistance?.toLocaleString('en', {
                    style: 'percent',
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td className="text-center">
                  {shoppingWithReport.find(x => x.shoppingWith === 'Shopping with basket')?.conversionFromAssistance?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppingWithReport.find(x => x.shoppingWith === 'Shopping with cart')?.conversionFromAssistance?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppingWithReport.find(x => x.shoppingWith === 'Shopping with hands')?.conversionFromAssistance?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row">
          <table style={{ marginTop: '10px' }} className="table table-bordered table-striped">
            <tbody>
              <tr>
                <th style={{ color: '#001f5c' }} className="text-center" colSpan={2} scope="colgroup">
                  Conversion funnel
                </th>
                <th style={{ color: '#001f5c' }} className="text-center" colSpan={4} scope="colgroup">
                  Demographics distribution
                </th>
              </tr>
              <tr>
                <td className="text-left metricNameCell">Demographics</td>
                <td className="text-center metricNameCell">Total</td>
                <td className="text-center metricNameCell">Female</td>
                <td className="text-center metricNameCell">Male</td>
                <td className="text-center metricNameCell">With Kids</td>
                <td className="text-center metricNameCell">Group</td>
              </tr>
              <tr>
                <td className="text-left metricNameCell">Visitors(at shelf)</td>
                <td className="text-center bold bordered">
                  {shoppersOverviewReport.visitorsShare.toLocaleString('en', {
                    style: 'percent',
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td className="text-center">
                  {shoppersDemographicsReport.find(x => x.demographicGroup === 'Female')?.visitorsShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppersDemographicsReport.find(x => x.demographicGroup === 'Male')?.visitorsShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppersDemographicsReport.find(x => x.demographicGroup === 'With Kids')?.visitorsShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppersDemographicsReport.find(x => x.demographicGroup === 'Group')?.visitorsShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
              </tr>
              <tr>
                <td className="text-left metricNameCell">Browsers(interest)</td>
                <td className="text-center bold bordered">
                  {shoppersOverviewReport.shownInterestShare?.toLocaleString('en', {
                    style: 'percent',
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td className="text-center">
                  {shoppersDemographicsReport.find(x => x.demographicGroup === 'Female')?.shownInterestShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppersDemographicsReport.find(x => x.demographicGroup === 'Male')?.shownInterestShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppersDemographicsReport.find(x => x.demographicGroup === 'With Kids')?.shownInterestShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppersDemographicsReport.find(x => x.demographicGroup === 'Group')?.shownInterestShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
              </tr>
              <tr>
                <td className="text-left metricNameCell">Shoppers(interact)</td>
                <td className="text-center bold bordered">
                  {shoppersOverviewReport.interactedShare?.toLocaleString('en', {
                    style: 'percent',
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td className="text-center">
                  {shoppersDemographicsReport.find(x => x.demographicGroup === 'Female')?.interactedShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppersDemographicsReport.find(x => x.demographicGroup === 'Male')?.interactedShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppersDemographicsReport.find(x => x.demographicGroup === 'With Kids')?.interactedShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppersDemographicsReport.find(x => x.demographicGroup === 'Group')?.interactedShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
              </tr>
              <tr>
                <td className="text-left metricNameCell">Buyers(purchase)</td>
                <td className="text-center bold bordered">
                  {shoppersOverviewReport.purchasedShare?.toLocaleString('en', {
                    style: 'percent',
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td className="text-center">
                  {shoppersDemographicsReport.find(x => x.demographicGroup === 'Female')?.purchasedShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppersDemographicsReport.find(x => x.demographicGroup === 'Male')?.purchasedShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppersDemographicsReport.find(x => x.demographicGroup === 'With Kids')?.purchasedShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppersDemographicsReport.find(x => x.demographicGroup === 'Group')?.purchasedShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
              </tr>
              <tr style={{ height: '8px' }} />
              <tr>
                <td className="text-left metricNameCell">Lost shoppers</td>
                <td className="text-center">
                  {shoppersOverviewReport.lostShoppersShare?.toLocaleString('en', {
                    style: 'percent',
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td className="text-center">
                  {shoppersDemographicsReport.find(x => x.demographicGroup === 'Female')?.lostShoppersShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppersDemographicsReport.find(x => x.demographicGroup === 'Male')?.lostShoppersShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppersDemographicsReport.find(x => x.demographicGroup === 'With Kids')?.lostShoppersShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppersDemographicsReport.find(x => x.demographicGroup === 'Group')?.lostShoppersShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
              </tr>
              <tr>
                <td className="text-left metricNameCell">Assisted browsers</td>
                <td className="text-center">
                  {shoppersOverviewReport.assistedBrowsersShare?.toLocaleString('en', {
                    style: 'percent',
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td className="text-center">
                  {shoppersDemographicsReport.find(x => x.demographicGroup === 'Female')?.assistedBrowsersShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppersDemographicsReport.find(x => x.demographicGroup === 'Male')?.assistedBrowsersShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppersDemographicsReport.find(x => x.demographicGroup === 'With Kids')?.assistedBrowsersShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppersDemographicsReport.find(x => x.demographicGroup === 'Group')?.assistedBrowsersShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
              </tr>
              <tr>
                <td className="text-left metricNameCell">Conversion from assistance</td>
                <td className="text-center">
                  {shoppersOverviewReport.conversionFromAssistance?.toLocaleString('en', {
                    style: 'percent',
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td className="text-center">
                  {shoppersDemographicsReport.find(x => x.demographicGroup === 'Female')?.conversionFromAssistance?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppersDemographicsReport.find(x => x.demographicGroup === 'Male')?.conversionFromAssistance?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppersDemographicsReport.find(x => x.demographicGroup === 'With Kids')?.conversionFromAssistance?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppersDemographicsReport.find(x => x.demographicGroup === 'Group')?.conversionFromAssistance?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="row">
          <table style={{ marginTop: '10px' }} className="table table-bordered table-striped">
            <tbody>
              <tr>
                <th style={{ color: '#001f5c' }} className="text-center" colSpan={2} scope="colgroup">
                  Conversion funnel
                </th>
                <th style={{ color: '#001f5c' }} className="text-center" colSpan={4} scope="colgroup">
                  Shopping Time distribution
                </th>
              </tr>
              <tr>
                <td className="text-left metricNameCell">Shopping Time</td>
                <td className="text-center metricNameCell">Total</td>
                <td className="text-center metricNameCell">Weekday before 4pm</td>
                <td className="text-center metricNameCell">Weekday after 4pm</td>
                <td className="text-center metricNameCell">Weekend (inc. friday) before 4pm</td>
                <td className="text-center metricNameCell">Weekend (inc. friday) after 4pm</td>
              </tr>
              <tr>
                <td className="text-left metricNameCell">Visitors(at shelf)</td>
                <td className="text-center bold bordered">
                  {shoppersOverviewReport.visitorsShare.toLocaleString('en', {
                    style: 'percent',
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td className="text-center">
                  {shoppingTimeReport.find(x => x.shoppingTime === 'Weekday before 4pm')?.visitorsShare.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppingTimeReport.find(x => x.shoppingTime === 'Weekday after 4pm')?.visitorsShare.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppingTimeReport.find(x => x.shoppingTime === 'Weekend inc. friday before 4pm')?.visitorsShare.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppingTimeReport.find(x => x.shoppingTime === 'Weekend inc. friday after 4pm')?.visitorsShare.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
              </tr>
              <tr>
                <td className="text-left metricNameCell">Browsers(interest)</td>
                <td className="text-center bold bordered">
                  {shoppersOverviewReport.shownInterestShare?.toLocaleString('en', {
                    style: 'percent',
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td className="text-center">
                  {shoppingTimeReport.find(x => x.shoppingTime === 'Weekday before 4pm')?.shownInterestShare.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppingTimeReport.find(x => x.shoppingTime === 'Weekday after 4pm')?.shownInterestShare.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppingTimeReport.find(x => x.shoppingTime === 'Weekend inc. friday before 4pm')?.shownInterestShare.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppingTimeReport.find(x => x.shoppingTime === 'Weekend inc. friday after 4pm')?.shownInterestShare.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
              </tr>
              <tr>
                <td className="text-left metricNameCell">Shoppers(interact)</td>
                <td className="text-center bold bordered">
                  {shoppersOverviewReport.interactedShare?.toLocaleString('en', {
                    style: 'percent',
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td className="text-center">
                  {shoppingTimeReport.find(x => x.shoppingTime === 'Weekday before 4pm')?.interactedShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppingTimeReport.find(x => x.shoppingTime === 'Weekday after 4pm')?.interactedShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppingTimeReport.find(x => x.shoppingTime === 'Weekend inc. friday before 4pm')?.interactedShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppingTimeReport.find(x => x.shoppingTime === 'Weekend inc. friday after 4pm')?.interactedShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
              </tr>
              <tr>
                <td className="text-left metricNameCell">Buyers(purchase)</td>
                <td className="text-center bold bordered">
                  {shoppersOverviewReport.purchasedShare?.toLocaleString('en', {
                    style: 'percent',
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td className="text-center">
                  {shoppingTimeReport.find(x => x.shoppingTime === 'Weekday before 4pm')?.purchasedShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppingTimeReport.find(x => x.shoppingTime === 'Weekday after 4pm')?.purchasedShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppingTimeReport.find(x => x.shoppingTime === 'Weekend inc. friday before 4pm')?.purchasedShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppingTimeReport.find(x => x.shoppingTime === 'Weekend inc. friday after 4pm')?.purchasedShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
              </tr>
              <tr style={{ height: '8px' }} />
              <tr>
                <td className="text-left metricNameCell">Lost shoppers</td>
                <td className="text-center">
                  {shoppersOverviewReport.lostShoppersShare?.toLocaleString('en', {
                    style: 'percent',
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td className="text-center">
                  {shoppingTimeReport.find(x => x.shoppingTime === 'Weekday before 4pm')?.lostShoppersShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppingTimeReport.find(x => x.shoppingTime === 'Weekday after 4pm')?.lostShoppersShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppingTimeReport.find(x => x.shoppingTime === 'Weekend inc. friday before 4pm')?.lostShoppersShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppingTimeReport.find(x => x.shoppingTime === 'Weekend inc. friday after 4pm')?.lostShoppersShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
              </tr>
              <tr>
                <td className="text-left metricNameCell">Assisted browsers</td>
                <td className="text-center">
                  {shoppersOverviewReport.assistedBrowsersShare?.toLocaleString('en', {
                    style: 'percent',
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td className="text-center">
                  {shoppingTimeReport.find(x => x.shoppingTime === 'Weekday before 4pm')?.assistedBrowsersShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppingTimeReport.find(x => x.shoppingTime === 'Weekday after 4pm')?.assistedBrowsersShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppingTimeReport.find(x => x.shoppingTime === 'Weekend inc. friday before 4pm')?.assistedBrowsersShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppingTimeReport.find(x => x.shoppingTime === 'Weekend inc. friday after 4pm')?.assistedBrowsersShare?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
              </tr>
              <tr>
                <td className="text-left metricNameCell">Conversion from assistance</td>
                <td className="text-center">
                  {shoppersOverviewReport.conversionFromAssistance?.toLocaleString('en', {
                    style: 'percent',
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td className="text-center">
                  {shoppingTimeReport.find(x => x.shoppingTime === 'Weekday before 4pm')?.conversionFromAssistance?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppingTimeReport.find(x => x.shoppingTime === 'Weekday after 4pm')?.conversionFromAssistance?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppingTimeReport.find(x => x.shoppingTime === 'Weekend inc. friday before 4pm')?.conversionFromAssistance?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
                <td className="text-center">
                  {shoppingTimeReport.find(x => x.shoppingTime === 'Weekend inc. friday after 4pm')?.conversionFromAssistance?.toLocaleString('en', { style: 'percent', maximumFractionDigits: 2 })}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <footer>
        <strong>Copyright &copy; {new Date().getFullYear()} Flow Insights</strong>. Strictly confidential. No distribution allowed. All rights reserved.
      </footer>
    </div>
  );
};

export { StandardShopperReportPage };
