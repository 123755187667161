import { makeStyles, Theme } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';
import { MappedShelfLocation } from '../../types';

export interface Props {
  svgWidth: number;
  svgHeight: number;
  svgBgImageUrl: string;
  shelfLocations: MappedShelfLocation[];
  maxHeight: string;
  width: string;
  onShelfLocationClick?(shelfLocationId: string): void;
  onEmptyClick?(): void;
}

interface MyState {
  isSvgMounted: boolean;
}

const textFontSize = 9;
const textFontSizeMargin = 2;

class ReadonlyPlanogram extends React.Component<Props, MyState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isSvgMounted: false,
    };
  }

  private svgElement: SVGElement | null = null;

  private onSvgReady = (el: SVGElement | null): void => {
    if (el === null) {
      return;
    }
    this.svgElement = el;
    this.setState({ isSvgMounted: true });
  };

  render(): JSX.Element {
    const { svgBgImageUrl, svgHeight, svgWidth, maxHeight, width } = this.props;
    return (
      <svg
        style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', maxHeight }}
        width={width}
        viewBox={`0 0 ${svgWidth} ${svgHeight}`}
        ref={this.onSvgReady}
        onClick={this.props.onEmptyClick}
      >
        <image x="0" y="0" href={svgBgImageUrl} />
        {this.state.isSvgMounted && this.renderShelfLocations()}
      </svg>
    );
  }

  onShelfLocationClicked = (shelfLocationId: string, event: React.MouseEvent<SVGSVGElement, MouseEvent>): void => {
    if (this.props.onShelfLocationClick) {
      event.preventDefault();
      event.stopPropagation();
      this.props.onShelfLocationClick(shelfLocationId);
    }
  };

  renderShelfLocations(): JSX.Element[] | null {
    if (this.svgElement === null) {
      return null;
    }
    const { svgHeight, svgWidth, shelfLocations } = this.props;
    const crect = this.svgElement.getBoundingClientRect();
    const hRatio = crect.width / svgWidth;
    const vRatio = crect.height / svgHeight;
    const ratio = Math.min(1, Math.min(hRatio, vRatio));
    return shelfLocations.map(sl => <ShelfLocationSvg key={sl.id} ratio={ratio} sl={sl} svgWidth={svgWidth} onShelfLocationClicked={this.onShelfLocationClicked} />);
  }
}

interface ShelfLocationSvgProps {
  sl: MappedShelfLocation;
  svgWidth: number;
  ratio: number;
  onShelfLocationClicked?(id: string, event: React.MouseEvent<SVGSVGElement, MouseEvent>): void;
}

const useStylesBootstrap = makeStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

const ShelfLocationSvg: React.FC<ShelfLocationSvgProps> = (props: ShelfLocationSvgProps): JSX.Element => {
  const { id, width, height, x, y, imageUrl, fill, fillOpacity, strokeOpacity, stroke, text } = props.sl;
  const svgWidth = props.svgWidth;
  const ratio = props.ratio;
  let shelfLocationWidth = 0;
  const classes = useStylesBootstrap();
  if (width.endsWith('%')) {
    shelfLocationWidth = parseFloat(width.replace('%', '')) / 100;
  } else {
    shelfLocationWidth = parseFloat(width) / 100;
  }
  shelfLocationWidth = shelfLocationWidth * svgWidth;

  return (
    <Tooltip classes={classes} arrow={true} title={props.sl.tooltip ?? ''} placement="top" key={id}>
      <svg
        width={width}
        height={height}
        x={x}
        y={y}
        onClick={(evt: React.MouseEvent<SVGSVGElement, MouseEvent>): void => {
          if (props.onShelfLocationClicked) {
            props.onShelfLocationClicked(id, evt);
          }
        }}
      >
        <image width="100%" height="100%" xlinkHref={imageUrl} />
        <rect width="100%" height="100%" fill={fill} fillOpacity={fillOpacity} strokeOpacity={strokeOpacity} stroke={stroke} strokeWidth="3%" />
        <text
          ref={(el): void => {
            if (!el) {
              return;
            }
            const fontSize = textFontSize / ratio;
            const newFontSize = Math.min(fontSize, (shelfLocationWidth * fontSize) / el.getComputedTextLength() - textFontSizeMargin / ratio);
            if (newFontSize < fontSize) {
              el.style.fontSize = `${newFontSize}px`;
            }
          }}
          textAnchor="middle"
          dominantBaseline="middle"
          fontWeight="bold"
          fontSize={textFontSize / ratio + 'px'}
          fill="black"
          x="50%"
          y="50%"
        >
          {text}
        </text>
      </svg>
    </Tooltip>
  );
};

export default ReadonlyPlanogram;
