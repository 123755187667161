import 'reflect-metadata';
import 'es6-shim';
import * as ReactDOM from 'react-dom';
import './App.scss';
import * as React from 'react';

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));
