import PlanogramModel from '../domain/PlanogramModel';
import ShelfLocation from '../domain/ShelfLocation';
import GeometryPoint from '../../shared/geometry/GeometryPoint';
import { PlanogramCommand } from './_planogram_commands';
import PlanogramEventBase from '../events/PlanogramEventBase';
import ShelfLocationGeometryPoint from '../domain/ShelfLocationGeometryPoint';

export default class TransientDragPointCommand extends PlanogramCommand {
  private planogram: PlanogramModel;
  private shelfLocation: ShelfLocation;
  private draggingPoint: ShelfLocationGeometryPoint;
  private offsetPoint: GeometryPoint;

  constructor($planogram: PlanogramModel, $shelfLocation: ShelfLocation, $draggingPoint: ShelfLocationGeometryPoint, $offsetPoint: GeometryPoint) {
    super();
    this.planogram = $planogram;
    this.shelfLocation = $shelfLocation;
    this.draggingPoint = $draggingPoint;
    this.offsetPoint = $offsetPoint;
  }

  execute(): PlanogramEventBase | undefined {
    return this.planogram.transientDrag(this.shelfLocation, this.draggingPoint, this.offsetPoint);
  }
}
