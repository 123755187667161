import ErrorDetails from 'infrastructure/exception/ErrorDetails';
import { Action } from 'types/Action';
import * as constants from './constants';

export const toggleAside = (): Action => ({
  type: constants.LAYOUT_TOGGLE_ASIDE,
});

export const openAside = (): Action => ({
  type: constants.LAYOUT_OPEN_ASIDE,
});

export const closeAside = (): Action => ({
  type: constants.LAYOUT_CLOSE_ASIDE,
});

export const appCrash = (): Action => ({
  type: constants.APP_HAS_CRASHED,
});

export interface OnErrorRequestAction extends Action {
  errorDetails: ErrorDetails;
}
