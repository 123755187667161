import { createSelector, Selector } from 'reselect';
import createCachedSelector, { ParametricSelector } from 're-reselect';

import { State } from '../../../state';
import AdminPlanogramsState from './state';
import AdminPlanogramViewModel from './types/AdminPlanogramViewModel';
import { isLoading } from 'infrastructure/utils/RemoteObjectStatus';

const getState: (state: State) => AdminPlanogramsState = state => state.adminPlanograms;

export const isEnablingInspirationImage: Selector<State, boolean> = createSelector(getState, state => {
  return isLoading(state.loadEnableInspirationImagesState);
});

export const getIsInspirationImagesEnabled: ParametricSelector<State, string, boolean> = createCachedSelector(
  getState,
  (state: State, planogramId: string): string => planogramId,
  (state, planogramId) => {
    const planogram = state.loadAdminPlanogramsStatus.data?.find(p => p.planogramId === planogramId) as AdminPlanogramViewModel;
    return planogram.inspirationImages.isInspirationImagesEnabled;
  }
)((state, planogramId) => planogramId);

export const getIsInspirationImagesEnabledBuilderPlangrams: ParametricSelector<State, string, boolean> = createCachedSelector(
  getState,
  (state: State, planogramId: string): string => planogramId,
  (state, planogramId) => {
    const planogram = (state.loadAdminBuildPlanogramsStatus.data?.find(p => p.id === planogramId) as unknown) as AdminPlanogramViewModel;
    return planogram.inspirationImages.isInspirationImagesEnabled;
  }
)((state, planogramId) => planogramId);
