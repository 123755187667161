import * as React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { BugReport } from '@material-ui/icons';

interface MyProps {
  onLogout(): void;
}

export default class UserLoadError extends React.Component<MyProps> {
  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <span className="clearfix">
                <div style={{ justifyContent: 'center', width: '100%', display: 'flex' }}>
                  <BugReport style={{ fontSize: 40 }} color="secondary" />
                  <BugReport style={{ fontSize: 40 }} color="secondary" />
                  <BugReport style={{ fontSize: 40 }} color="secondary" />
                  <BugReport style={{ fontSize: 40 }} color="secondary" />
                </div>
                <h4 className="pt-3">We are having issues loading your account.</h4>
                <p className="float-left text-danger">
                  An unexpected error has ocurred during load of your account. Please refresh the page and try again. If this error continues to occur, try logging out and logging back in. <br />
                  Please{' '}
                  <a href="https://www.flowinsights.com/contact/" target="_blank" rel="noopener noreferrer">
                    send us a message
                  </a>{' '}
                  if nothing from above helps.
                </p>
              </span>
              <Button color="primary" onClick={this.props.onLogout}>
                Logout
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
