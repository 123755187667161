import { Badge, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Table, TableBody, TableHead, Tooltip, Typography } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp, Close, Edit, Flag, PhotoFilter, Style, SupervisorAccount } from '@material-ui/icons';
import { formatDistanceToNow } from 'date-fns';
import trackComponent from 'infrastructure/tracking/withTracking';
import { orderBy } from 'lodash';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { StyledTableCell, StyledTableRow } from 'scss/MaterialStyles';
import AdminPlanogramViewModel from '../types/AdminPlanogramViewModel';
import CompetitorShelf from '../types/CompetitorShelf';
import CompetitorShelfDataset from '../types/CompetitorShelfDataset';
import { RemoteObjectStatusInterface } from './../../../../infrastructure/utils/RemoteObjectStatus';
import ManageCompetitorShelvesModal from './ManageCompetitorShelves/ManageCompetitorShelvesModal';
import EnabledShelfCondition from './EnabledShelfConditions/EnabledShelfCondition';
import EnvFeatureFlag from '../../../../containers/EnvFeatureFlag';
import ManageInspirationImages from './ManageInspirationImages';
import { ReduxPlanogramPropertyNames } from './ManageInspirationImages/types';

interface ShelfPlanogramsModalState {
  orderByProperty: string;
  orderByDescending: boolean;
  selectedPlanogram: AdminPlanogramViewModel;
  showManageCompetitorShelvesModal: boolean;
}

class ShelfPlanogramsModal extends React.Component<ShelfPlanogramsModalProps, ShelfPlanogramsModalState> {
  constructor(props: ShelfPlanogramsModalProps) {
    super(props);
    this.state = {
      orderByProperty: 'name',
      orderByDescending: false,
      selectedPlanogram: {} as AdminPlanogramViewModel,
      showManageCompetitorShelvesModal: false,
    };
  }

  changeOrderBy(propertyName: string) {
    const descending = this.state.orderByProperty === propertyName ? !this.state.orderByDescending : false;
    this.setState({
      orderByProperty: propertyName,
      orderByDescending: descending,
    });
  }

  getOrderByIcon(propertyName: string) {
    if (this.state.orderByProperty !== propertyName) {
      return null;
    }

    if (this.state.orderByDescending) {
      return <ArrowDropDown />;
    } else {
      return <ArrowDropUp />;
    }
  }

  render() {
    const planogram = this.props.planograms[0];
    return (
      <Dialog maxWidth="xl" open={true} onClose={this.props.onClose}>
        <DialogTitle>
          <Typography variant="overline" gutterBottom>
            <PhotoFilter fontSize="large" />
            &nbsp; Planograms for {planogram.projectName}, Store: {planogram.storeName}, Phase #{planogram.phaseNum}, Shelf #{planogram.shelfNum}
          </Typography>
          <Divider light variant="middle" />
        </DialogTitle>
        <DialogContent>{this.renderPlanogramsTable()}</DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={this.props.onClose} className="pull-right">
            <Close />
            &nbsp; Close
          </Button>
        </DialogActions>
        {this.state.showManageCompetitorShelvesModal ? (
          <ManageCompetitorShelvesModal
            planogramId={this.state.selectedPlanogram.planogramId}
            planogramName={this.state.selectedPlanogram.planogramName}
            competitorShelves={this.state.selectedPlanogram.competitorShelves}
            similarProjectStorePhaseShelvesLoadStatus={this.props.competitorShelvesState}
            updateCompetitorShelvesState={this.props.updateCompetitorShelvesState}
            onClose={() => {
              this.setState({ showManageCompetitorShelvesModal: false });
            }}
            loadCompetitorShelvesDatasets={(planogramId: string) => this.props.loadCompetitorShelvesDatasets(planogramId)}
            updateCompetitorShelves={(planogramId: string, competitorShelves: CompetitorShelf[]) => this.props.updateCompetitorShelves(planogramId, competitorShelves)}
            uploadCompetitorShelfDatasetRequest={(dataset: CompetitorShelfDataset) => this.props.uploadCompetitorShelfDatasetRequest(dataset)}
            uploadCompetitorShelfDatasetState={this.props.uploadCompetitorShelfDatasetState}
            competitorShelvesDatasetsStatus={this.props.competitorShelvesDatasetsStatus}
            deleteCompetitorShelfDatasetState={this.props.deleteCompetitorShelfDatasetState}
            deleteCompetitorShelfDataset={(dataset: CompetitorShelfDataset) => this.props.deleteCompetitorShelfDataset(dataset)}
          />
        ) : null}
      </Dialog>
    );
  }

  renderPlanogramsTable() {
    return (
      <Table size="medium" className="sticky-header-table">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell style={{ cursor: 'pointer' }} onClick={() => this.changeOrderBy('planogramName')}>
              <Typography variant="subtitle1">
                Name
                {this.getOrderByIcon('planogramName')}
              </Typography>
            </StyledTableCell>
            <StyledTableCell style={{ cursor: 'pointer' }} onClick={() => this.changeOrderBy('userEmail')}>
              <Typography variant="subtitle1">
                Owner
                {this.getOrderByIcon('userEmail')}
              </Typography>
            </StyledTableCell>
            <StyledTableCell onClick={() => this.changeOrderBy('createdOn')} style={{ cursor: 'pointer' }}>
              <Typography variant="subtitle1">
                Created
                {this.getOrderByIcon('createdOn')}
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography variant="subtitle1">Edit</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography variant="subtitle1">Builders</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography variant="subtitle1">Tests</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography variant="subtitle1">Competitor Shelves</Typography>
            </StyledTableCell>
            <EnvFeatureFlag featureName="inspirationImages">
              <StyledTableCell>
                <Typography variant="subtitle1">Inspiration Images</Typography>
              </StyledTableCell>
            </EnvFeatureFlag>
            <StyledTableCell>
              <Typography variant="subtitle1">Shelf Timelapse</Typography>
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>{this.renderPlanograms()}</TableBody>
      </Table>
    );
  }

  renderPlanograms() {
    const planograms = orderBy(this.props.planograms, [this.state.orderByProperty], [this.state.orderByDescending ? 'desc' : 'asc']);
    return planograms.map((p: AdminPlanogramViewModel) => {
      return (
        <StyledTableRow key={p.planogramId}>
          <StyledTableCell>{p.planogramName}</StyledTableCell>
          <StyledTableCell>{p.userEmail}</StyledTableCell>
          <StyledTableCell>
            <span>{formatDistanceToNow(p.createdOn, { addSuffix: true })}</span>
          </StyledTableCell>
          <StyledTableCell align="center">
            <Link to={'/planograms/' + p.planogramId} target="_blank">
              <Tooltip title="View Planogram">
                <IconButton>
                  <Edit color="secondary" />
                </IconButton>
              </Tooltip>
            </Link>
          </StyledTableCell>
          <StyledTableCell align="center">
            <Tooltip title="View Builder Planograms">
              <IconButton onClick={() => this.props.onViewBuilders(p)}>
                <SupervisorAccount color="secondary" />
              </IconButton>
            </Tooltip>
          </StyledTableCell>
          <StyledTableCell align="center">
            <Tooltip title="View Shelf Tests">
              <IconButton>
                <Badge color="primary" badgeContent={p.testInfoList.length} showZero={true}>
                  <Flag color="secondary" />
                </Badge>
              </IconButton>
            </Tooltip>
          </StyledTableCell>
          <StyledTableCell align="center">
            <Tooltip title="Manage Competitor Shelves">
              <IconButton onClick={() => this.setState({ selectedPlanogram: p, showManageCompetitorShelvesModal: true })}>
                <Badge color="primary" badgeContent={p.competitorShelves.length} showZero={true}>
                  <Style color="secondary" />
                </Badge>
              </IconButton>
            </Tooltip>
          </StyledTableCell>
          <EnvFeatureFlag featureName="inspirationImages">
            <StyledTableCell align="center">
              <ManageInspirationImages planogramId={p.planogramId} reduxPlanogramPropsName={ReduxPlanogramPropertyNames.PLANOGRAMS} />
            </StyledTableCell>
          </EnvFeatureFlag>
          <StyledTableCell align="center">
            <EnvFeatureFlag featureName="shelfConditions">
              <EnabledShelfCondition planogramId={p.planogramId} isShelfConditionsEnabled={p.shelfConditions.isShelfConditionsEnabled} />
            </EnvFeatureFlag>
          </StyledTableCell>
        </StyledTableRow>
      );
    });
  }
}

interface ShelfPlanogramsModalProps {
  planograms: AdminPlanogramViewModel[];
  userId: number;
  competitorShelvesState: RemoteObjectStatusInterface<CompetitorShelf[]>;
  updateCompetitorShelvesState: RemoteObjectStatusInterface<{}>;
  uploadCompetitorShelfDatasetState: RemoteObjectStatusInterface<{}>;
  competitorShelvesDatasetsStatus: RemoteObjectStatusInterface<CompetitorShelfDataset[]>;
  deleteCompetitorShelfDatasetState: RemoteObjectStatusInterface<{}>;
  onClose(): void;
  onViewBuilders(p: AdminPlanogramViewModel): void;
  loadCompetitorShelvesDatasets(planogramId: string): void;
  updateCompetitorShelves(planogramId: string, competitorShelves: CompetitorShelf[]): void;
  uploadCompetitorShelfDatasetRequest(dataset: CompetitorShelfDataset): void;
  deleteCompetitorShelfDataset(dataset: CompetitorShelfDataset): void;
}

export default trackComponent(ShelfPlanogramsModal, 'ShelfPlanogramsModal');
