import * as React from 'react';
import ShelfLocation from 'modules/planogram/domain/ShelfLocation';

interface MyProps {
  shelfLocation: ShelfLocation;
}

const ShelfLocationImageClip: React.FC<MyProps> = ({ shelfLocation }: MyProps) => {
  const g = shelfLocation.geometry;
  const x = g.topLeftPoint.x + g.topLeftPoint.xOffset;
  const y = g.topLeftPoint.y + g.topLeftPoint.yOffset;
  const width = g.tempCurrentWidth || g.currentWidth || g.originalWidth;
  const height = g.tempCurrentHeight || g.currentHeight || g.originalHeight;
  return (
    <clipPath id={`clip-image-${shelfLocation.id}`}>
      <rect x={x} y={y} width={width} height={height} />
    </clipPath>
  );
};

export default ShelfLocationImageClip;
