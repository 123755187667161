import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, InputAdornment, LinearProgress, TextField, Typography } from '@material-ui/core';
import { Close, Flag, Label, Store, TouchApp } from '@material-ui/icons';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { addDays } from 'date-fns';
import * as React from 'react';
import { Col } from 'reactstrap';
import { getDateInInputFormat } from '../../../../utils/dateHelper';
import { removeDomElementsByClassName } from '../../helpers/domHelper';
import PlanogramState from '../../state';
import CreateTestPlanogramRequestDto from '../../types/CreateTestPlanogramRequestDto';
import PlanogramModel from '../../domain/PlanogramModel';

interface TestWithFlowModalState {
  createTestPlanogramDto: CreateTestPlanogramRequestDto;
}

interface TestWithFlowModalProps {
  initialPlanogram: PlanogramModel;
  isCreatingTestPlanogram: boolean;
  createTestPlanogramSuccess: boolean;
  shelfSvg: any;

  onClose(): void;

  createTestPlanogram(createTestPlanogram: CreateTestPlanogramRequestDto): void;
}

export default class TestWithFlowModal extends React.Component<TestWithFlowModalProps, TestWithFlowModalState> {
  private theForm: HTMLFormElement | null = null;

  constructor(props: PlanogramState & TestWithFlowModalProps, state: TestWithFlowModalState) {
    super(props, state);

    const createTestPlanogramDto = new CreateTestPlanogramRequestDto();
    createTestPlanogramDto.operationsInfo.storeName = this.props.initialPlanogram.storeName;
    createTestPlanogramDto.initialPlanogramId = this.props.initialPlanogram.id;
    // createTestPlanogramDto.shelfSvgHtml = this.extractShelfSvgHtml(this.props.shelfSvg);
    this.state = {
      createTestPlanogramDto,
    };
    this.onChangeTestName = this.onChangeTestName.bind(this);
    this.onChangeStoreName = this.onChangeStoreName.bind(this);
    this.createTestShelf = this.createTestShelf.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<TestWithFlowModalProps>): void {
    if (prevProps.createTestPlanogramSuccess !== this.props.createTestPlanogramSuccess && this.props.createTestPlanogramSuccess) {
      this.props.onClose();
    }
  }

  extractShelfSvgHtml(svgElement: any): string {
    const shelfSvg = svgElement.cloneNode(true);
    const gElement = shelfSvg.childNodes[0].childNodes[0];
    const imageWidth = gElement.firstChild.attributes.width.value;
    const imageHeight = gElement.firstChild.attributes.height.value;
    gElement.removeAttribute('transform');
    shelfSvg.setAttribute('width', imageWidth + 'px');
    shelfSvg.setAttribute('height', imageHeight + 'px');
    // remove all children dom elements which have className "excludeFromShelfImage"
    removeDomElementsByClassName(shelfSvg, 'excludeFromShelfImage');
    return shelfSvg.outerHTML;
  }

  onChangeStoreName(evt: React.ChangeEvent<HTMLInputElement>): undefined {
    if (evt !== undefined) {
      const newCreateTestPlanogramDtoState = {
        ...this.state.createTestPlanogramDto,
      };
      newCreateTestPlanogramDtoState.operationsInfo.storeName = evt.target.value;
      this.setState({ createTestPlanogramDto: newCreateTestPlanogramDtoState });
    }
    return undefined;
  }

  onChangeTestName(evt: React.ChangeEvent<HTMLInputElement>): undefined {
    if (evt !== undefined) {
      const newCreateTestPlanogramDtoState = {
        ...this.state.createTestPlanogramDto,
      };
      newCreateTestPlanogramDtoState.testName = evt.target.value;
      this.setState({ createTestPlanogramDto: newCreateTestPlanogramDtoState });
    }
    return undefined;
  }

  haveEnteredRequiredFields(): boolean {
    const testPlanogramDetails = this.state.createTestPlanogramDto;
    return !!(
      testPlanogramDetails.testName !== '' &&
      // testPlanogramDetails.shelfSvgHtml !== '' &&
      testPlanogramDetails.operationsInfo.storeName !== '' &&
      Date.parse(getDateInInputFormat(testPlanogramDetails.operationsInfo.startDate)) &&
      Date.parse(getDateInInputFormat(testPlanogramDetails.operationsInfo.endDate)) &&
      testPlanogramDetails.initialPlanogramId !== '' &&
      testPlanogramDetails.operationsInfo.startDate < testPlanogramDetails.operationsInfo.endDate
    );
  }

  createTestShelf(evt: React.FormEvent<HTMLFormElement>): boolean {
    evt.preventDefault();
    if (this.theForm === null) {
      return false;
    }
    if (!this.theForm.checkValidity()) {
      return false;
    }
    this.props.createTestPlanogram(this.state.createTestPlanogramDto);
    return false;
  }

  render(): JSX.Element {
    return (
      <Dialog open={true} onClose={this.props.onClose}>
        <form
          ref={(form): void => {
            this.theForm = form;
          }}
          onSubmit={this.createTestShelf}
        >
          <DialogTitle>
            <Typography variant="overline" gutterBottom>
              <Flag color="primary" fontSize="large" />
              &nbsp; Create a Shelf Test
            </Typography>
            <Divider light variant="middle" />
          </DialogTitle>
          <DialogContent>{this.renderTestInfoForm()}</DialogContent>
          <DialogActions>
            <Button type="submit" variant="contained" color="primary" disabled={this.props.isCreatingTestPlanogram || !this.haveEnteredRequiredFields()} className="pull-left">
              <TouchApp />
              &nbsp;
              <Typography variant="button">{this.props.isCreatingTestPlanogram ? 'Submitting...' : 'Request Shelf Test'}</Typography>
            </Button>

            <Button variant="contained" color="secondary" onClick={this.props.onClose} className="pull-right">
              <Close />
              &nbsp;
              <Typography variant="button">Cancel</Typography>
            </Button>
          </DialogActions>
          {this.props.isCreatingTestPlanogram ? <LinearProgress color="primary" /> : null}
        </form>
      </Dialog>
    );
  }

  renderTestInfoForm(): JSX.Element {
    return (
      <Col>
        <TextField
          id="testName"
          label="Test Name"
          placeholder="Test Name"
          onChange={this.onChangeTestName}
          autoFocus
          fullWidth
          required
          margin="normal"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Label color="secondary" />
              </InputAdornment>
            ),
            readOnly: false,
          }}
        />
        <TextField
          id="storeName"
          label="Store Name"
          placeholder="Store Name"
          onChange={this.onChangeStoreName}
          value={this.state.createTestPlanogramDto.operationsInfo.storeName}
          required
          fullWidth
          margin="normal"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Store color="secondary" />
              </InputAdornment>
            ),
            readOnly: false,
          }}
        />
        <Grid container justify="space-between" spacing={3}>
          <Grid xs={6} item>
            <KeyboardDatePicker
              margin="normal"
              color="secondary"
              value={this.state.createTestPlanogramDto.operationsInfo.startDate}
              placeholder="dd.mm.yyyy"
              onChange={(date: Date): void => {
                const newCreateTestPlanogramDtoState = {
                  ...this.state.createTestPlanogramDto,
                };
                newCreateTestPlanogramDtoState.operationsInfo.startDate = date;
                this.setState({
                  createTestPlanogramDto: newCreateTestPlanogramDtoState,
                });
              }}
              minDate={new Date()}
              minDateMessage="Start date should be in the future."
              format="dd.MM.yyyy"
              required
              variant="inline"
              autoOk
              inputVariant="outlined"
              label="Start Date"
              InputAdornmentProps={{ position: 'start', color: 'secondary' }}
            />
          </Grid>
          <Grid xs={6} item>
            <KeyboardDatePicker
              margin="normal"
              value={this.state.createTestPlanogramDto.operationsInfo.endDate}
              placeholder="dd.mm.yyyy"
              onChange={(date: Date): void => {
                const newCreateTestPlanogramDtoState = {
                  ...this.state.createTestPlanogramDto,
                };
                newCreateTestPlanogramDtoState.operationsInfo.endDate = date;
                this.setState({
                  createTestPlanogramDto: newCreateTestPlanogramDtoState,
                });
              }}
              minDate={addDays(this.state.createTestPlanogramDto.operationsInfo.startDate, 1)}
              minDateMessage="End date should be at least one day after the start date."
              format="dd.MM.yyyy"
              required
              variant="inline"
              autoOk
              inputVariant="outlined"
              label="End Date"
              InputAdornmentProps={{ position: 'start' }}
            />
          </Grid>
        </Grid>
      </Col>
    );
  }
}
