/* eslint-disable @typescript-eslint/camelcase */
import { Tooltip, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import { ClientOption, ProjectOption } from 'modules/reports/types';
import { ClientSelector } from 'modules/shared/components/DropDownSelectors/ClientSelector';
import { ProjectSelector } from 'modules/shared/components/DropDownSelectors/ProjectSelector';
import moment from 'moment';
import React from 'react';
import CalendarTimeline, { DateHeader, ReactCalendarGroupRendererProps, ReactCalendarItemRendererProps, SidebarHeader, TimelineGroupBase, TimelineHeaders } from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import { useDispatch, useSelector } from 'react-redux';
import { onSelectClientInTimeline, onSelectProjectInTimeline, openTimelinesMenuAction } from '../actions';
import { getCalendarShelvesData, getSelectedClient, getSelectedProject, getTimelinesMenuAnchorElement, isTimelinesMenuOpen } from '../selectors';
import { TimelineCalendarData } from '../types';
import { TimelineItemMenu } from './TimelineItemMenu';

interface TimelinesCalendarProps {
  startDate: string;
  endDate: string;
}
export const TimelinesCalendar: React.FC<TimelinesCalendarProps> = ({ startDate, endDate }: TimelinesCalendarProps) => {
  const dispatch = useDispatch();
  const isMenuOpen = useSelector(isTimelinesMenuOpen);
  const menuAnchorElement = useSelector(getTimelinesMenuAnchorElement);
  const { projectShelves, shelvesPhases, shelfPhaseDetailsDict, clientOptions, projectOptions }: TimelineCalendarData = useSelector(getCalendarShelvesData);
  const selectedClientOption = useSelector(getSelectedClient);
  const selectedProjectOption = useSelector(getSelectedProject);

  const itemRenderer = ({ item, itemContext, getItemProps, getResizeProps }: ReactCalendarItemRendererProps): JSX.Element => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
    const shelfPhaseDetails = shelfPhaseDetailsDict[item.id];
    const itemProps: React.HTMLAttributes<HTMLDivElement> = shelfPhaseDetails.isReadyForReporting ? { style: { background: 'lime' } } : { style: { background: 'red' } };
    const itemStyle: React.CSSProperties = { maxHeight: `${itemContext.dimensions.height}` };
    return (
      <div
        {...getItemProps(itemProps)}
        title={`Phase ${shelfPhaseDetails.phaseNum}: ${format(new Date(shelfPhaseDetails.startDate), 'dd.MM.yyyy')} - ${format(new Date(shelfPhaseDetails.endDate), 'dd.MM.yyyy')}`}
      >
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''}
        <div className="rct-item-content" style={itemStyle}>
          {itemContext.title}
        </div>
        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''}
      </div>
    );
  };

  const groupRenderer = (renderer: ReactCalendarGroupRendererProps<TimelineGroupBase>): JSX.Element => {
    return (
      <div style={{ width: '100%' }}>
        <Tooltip title={renderer.group.title ?? ''}>
          <Typography variant="caption">{renderer.group.title}</Typography>
        </Tooltip>
      </div>
    );
  };

  const handleOnSelectClient = (selectedClient: ClientOption): void => {
    dispatch(onSelectClientInTimeline(selectedClient));
  };
  const handleOnSelectProject = (selectedProject: ProjectOption): void => {
    dispatch(onSelectProjectInTimeline(selectedProject));
  };

  const handleItemSelect = (e: React.SyntheticEvent<Element, Event>, itemId: number): void => {
    const selectedShelfPhase = shelfPhaseDetailsDict[itemId];
    const anchorEl = e.currentTarget;
    dispatch(openTimelinesMenuAction(anchorEl, selectedShelfPhase));
  };

  return (
    <>
      <ClientSelector
        htmlId={'timeline-client-autocomplete-select'}
        clientOptions={clientOptions}
        selectedClient={selectedClientOption}
        handleOnSelectClient={(selectedClient: ClientOption): void => handleOnSelectClient(selectedClient)}
      />
      <ProjectSelector
        htmlId={'timeline-project-autocomplete-select'}
        projectOptions={projectOptions}
        selectedProject={selectedProjectOption}
        handleOnSelectProject={(selectedProject: ProjectOption): void => handleOnSelectProject(selectedProject)}
      />
      <CalendarTimeline
        groups={projectShelves}
        itemRenderer={itemRenderer}
        groupRenderer={groupRenderer}
        items={shelvesPhases}
        lineHeight={40}
        defaultTimeStart={moment(startDate)}
        defaultTimeEnd={moment(endDate)}
        visibleTimeStart={moment(startDate).valueOf() as any}
        visibleTimeEnd={moment(endDate).valueOf() as any}
        traditionalZoom={true}
        canChangeGroup={false}
        canMove={false}
        canResize={false}
        onItemClick={(itemId: number, event: React.SyntheticEvent<Element, Event>): void => {
          handleItemSelect(event, itemId);
        }}
        onItemSelect={(itemId: number, event: React.SyntheticEvent<Element, Event>): void => {
          handleItemSelect(event, itemId);
        }}
      >
        <TimelineHeaders className="sticky-timeline-header" style={{ backgroundColor: '#f0f0f0' }}>
          <SidebarHeader>
            {({ getRootProps }): JSX.Element => {
              return (
                <div
                  className="text-center"
                  {...getRootProps({
                    style: {
                      marginTop: 'auto',
                      marginBottom: 'auto',
                    },
                  })}
                >
                  <span className="text-center">Project Shelves</span>
                </div>
              );
            }}
          </SidebarHeader>
          <DateHeader />
        </TimelineHeaders>
      </CalendarTimeline>
      {menuAnchorElement && <TimelineItemMenu isOpen={isMenuOpen} anchorEl={menuAnchorElement} />}
    </>
  );
};
