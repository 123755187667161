import React from 'react';
import { Grid, FormControlLabel, Checkbox, Typography } from '@material-ui/core';
import { AggregatedMetrics } from '../types';
import _ from 'lodash';

export interface MetricSelectorProps {
  selectedMetrics: AggregatedMetrics[];
  onSelectedMetricChanged: (metrics: AggregatedMetrics[]) => void;
}

function remove<T>(list: T[], item: T) {
  const index = list.indexOf(item, 0);
  if (index > -1) {
    list.splice(index, 1);
  }
}

const MetricSelector: React.FC<MetricSelectorProps> = (props: MetricSelectorProps) => {
  const metrics = Object.keys(AggregatedMetrics).filter(item => {
    return isNaN(Number(item));
  });

  const onSelected = (metric: AggregatedMetrics, event: React.ChangeEvent<HTMLInputElement>): void => {
    let list = [] as AggregatedMetrics[];
    if (event.target.checked) {
      list = [...props.selectedMetrics] ?? [];
      list.push(metric);
    } else {
      list = [...props.selectedMetrics] ?? [];
      remove(list, metric);
    }
    props.onSelectedMetricChanged(list);
  };

  return (
    <Grid container direction="column" style={{ margin: '8px', padding: '16px' }} justify="flex-start" spacing={2}>
      <Typography variant="h5" color="textPrimary" align="left">
        &nbsp;Flow Metrics
      </Typography>
      {metrics.map(m => {
        return (
          <FormControlLabel
            key={`selector-label-${m}`}
            control={<Checkbox onChange={e => onSelected(AggregatedMetrics[m], e)} />}
            label={m}
            checked={_.some(props.selectedMetrics, s => s === AggregatedMetrics[m])}
          />
        );
      })}
    </Grid>
  );
};

export { MetricSelector };
