import ErrorDetails from 'infrastructure/exception/ErrorDetails';
import { FlowDataReportResult } from 'modules/planogram/types/FlowDataReportResult';
import { toastr } from 'react-redux-toastr';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { ApiResponse } from 'utils/apiClient';
import apiSaga from 'utils/apiSaga';
import {
  deleteMessageInConversationFailureAction,
  deleteMessageInConversationSuccessAction,
  fetchFailedAction,
  fetchFlowDataFailedAction,
  fetchFlowDataRequestAction,
  fetchFlowDataSuccessAction,
  fetchRealogramConversationFailureAction,
  fetchRealogramConversationSuccessAction,
  fetchRequestAction,
  fetchSuccessAction,
  postMessageToConversationFailureAction,
  postMessageToConversationSuccessAction,
  updateMessageInConversationFailureAction,
  updateMessageInConversationSuccessAction,
} from './actions';
import {
  DeleteMessageInConversationRequestActionType,
  DELETE_MESSAGE_IN_CONVERSATION_REQUEST,
  FetchRealogramConversationRequestActionType,
  PostMessageToConversationRequestActionType,
  POST_MESSAGE_TO_CONVERSATION_REQUEST,
  REALOGRAM_CONVERSATION_LOAD_REQUEST,
  UpdateMessageInConversationRequestActionType,
  UPDATE_MESSAGE_IN_CONVERSATION_REQUEST,
} from './actionTypes';
import {
  deleteMessageInConversationApiRequest,
  fetchConversationApiRequest,
  fetchFlowDataApiRequest,
  fetchRealogramApiRequest,
  postMessageToConversationApiRequest,
  updateMessageInConversationApiRequest,
} from './apiRequests';
import { FetchFlowDataRequestActionCreator, FetchRealogramRequestActionCreator, RealogramConversation, RealogramViewModel } from './types';

export function* fetchRealogram(action: ReturnType<FetchRealogramRequestActionCreator>): SagaIterator {
  try {
    const response = (yield call(apiSaga, fetchRealogramApiRequest, action.payload)) as ApiResponse<RealogramViewModel>;
    yield put(fetchSuccessAction(response.data));
    yield put(fetchFlowDataRequestAction(action.payload));
  } catch (e) {
    const error = e.response.data as ErrorDetails;
    console.log('failed to fetch realogram.', error);
    yield put(fetchFailedAction(error));
  }
}

export function* fetchFlowData(action: ReturnType<FetchFlowDataRequestActionCreator>): SagaIterator {
  try {
    const response = (yield call(apiSaga, fetchFlowDataApiRequest, action.payload)) as ApiResponse<FlowDataReportResult>;
    const data = response.data;
    yield put(fetchFlowDataSuccessAction(data));
  } catch (e) {
    const error = e.response.data as ErrorDetails;
    console.log('failed to fetch realogram.', error);
    yield put(fetchFlowDataFailedAction(error));
  }
}

export function* fetchConversation(action: FetchRealogramConversationRequestActionType): SagaIterator {
  try {
    const response = (yield call(apiSaga, fetchConversationApiRequest, action.payload.shelfReferenceId)) as ApiResponse<RealogramConversation>;
    const data = response.data;
    yield put(fetchRealogramConversationSuccessAction(data));
  } catch (e) {
    const error = e.response.data as ErrorDetails;
    console.log('failed to fetch conversation.', error);
    yield put(fetchRealogramConversationFailureAction(error));
  }
}

export function* postMessageToConversation(action: PostMessageToConversationRequestActionType): SagaIterator {
  try {
    const response = (yield call(apiSaga, postMessageToConversationApiRequest, action.payload.shelfReferenceId, action.payload.message)) as ApiResponse<{
      messageId: string;
      userId: number;
      username: string;
      timeOfMessage: Date;
    }>;
    yield put(
      postMessageToConversationSuccessAction(
        action.payload.shelfReferenceId,
        action.payload.message,
        response.data.messageId,
        response.data.userId,
        response.data.username,
        response.data.timeOfMessage
      )
    );
  } catch (e) {
    const error = e.response.data as ErrorDetails;
    console.log('failed to post message.', error);
    toastr.error('Failed to post message.', error.errorMessage);
    yield put(postMessageToConversationFailureAction(error));
  }
}

export function* updateMessageInConversation(action: UpdateMessageInConversationRequestActionType): SagaIterator {
  try {
    yield call(apiSaga, updateMessageInConversationApiRequest, action.payload.shelfReferenceId, action.payload.messageId, action.payload.userId, action.payload.editedMessage);
    yield put(updateMessageInConversationSuccessAction(action.payload.shelfReferenceId, action.payload.messageId, action.payload.editedMessage));
  } catch (e) {
    const error = e.response.data as ErrorDetails;
    console.log('failed to edit message.', error);
    toastr.error('Failed to edit message.', error.errorMessage);
    yield put(updateMessageInConversationFailureAction(error));
  }
}

export function* deleteMessageInConversation(action: DeleteMessageInConversationRequestActionType): SagaIterator {
  try {
    yield call(apiSaga, deleteMessageInConversationApiRequest, action.payload.shelfReferenceId, action.payload.messageId);
    yield put(deleteMessageInConversationSuccessAction(action.payload.shelfReferenceId, action.payload.messageId));
  } catch (e) {
    const error = e.response.data as ErrorDetails;
    console.log('failed to delete message.', error);
    toastr.error('Failed to delete message.', error.errorMessage);
    yield put(deleteMessageInConversationFailureAction(error));
  }
}
export default function* watchRealogramPage(): SagaIterator {
  yield takeLatest(getType(fetchRequestAction), fetchRealogram);
  yield takeLatest(getType(fetchFlowDataRequestAction), fetchFlowData);
  yield takeLatest(REALOGRAM_CONVERSATION_LOAD_REQUEST, fetchConversation);
  yield takeLatest(POST_MESSAGE_TO_CONVERSATION_REQUEST, postMessageToConversation);
  yield takeLatest(UPDATE_MESSAGE_IN_CONVERSATION_REQUEST, updateMessageInConversation);
  yield takeLatest(DELETE_MESSAGE_IN_CONVERSATION_REQUEST, deleteMessageInConversation);
}
