import React from 'react';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import { Alert } from 'reactstrap';
import { AggregatedReportComponent } from './AggregatedReportComponent';
import { AvailableAggregatedReportsLoader } from './AvailableAggregatedReportsLoader';

const AggregatedReportIndex: React.FC = () => {
  return (
    <AvailableAggregatedReportsLoader>
      {({ isLoaded, isLoading, loadError }): React.ReactNode => {
        const showComponent = !isLoading && !loadError && isLoaded;
        return (
          <>
            {isLoading && (
              <Grid direction="column" alignItems="center" container justify="center">
                <CircularProgress /> &nbsp;&nbsp;
                <Typography variant="overline">Loading Project Shelves...</Typography>
              </Grid>
            )}
            {loadError && (
              <Alert className="top-buffer" color="danger">
                <Typography variant="caption" color="textSecondary">
                  <ErrorOutline color="secondary" fontSize="small" />
                  &nbsp;Failed to load Project Shelves. {loadError}
                </Typography>
              </Alert>
            )}
            {showComponent && <AggregatedReportComponent />}
          </>
        );
      }}
    </AvailableAggregatedReportsLoader>
  );
};

export { AggregatedReportIndex };
