import { EulaState } from './types';
import { EulaActionTypes, EULA_LOAD_ERROR, EULA_LOAD_SUCCESS, EULA_LOAD_REQUEST, EULA_ACCEPT_REQUEST, EULA_ACCEPT_ERROR, EULA_ACCEPT_SUCCESS } from './actionsTypes';
import { setError, setIdle, setLoading, setSuccess } from 'infrastructure/utils/RemoteObjectStatus';

const InitialState: EulaState = {
  agreeingStatus: setIdle(),
  latestEula: setIdle(),
};

export default function eulaReducer(state = InitialState, action: EulaActionTypes): EulaState {
  switch (action.type) {
    case EULA_LOAD_REQUEST: {
      return {
        ...state,
        latestEula: setLoading(),
      };
    }
    case EULA_LOAD_SUCCESS: {
      return {
        ...state,
        latestEula: setSuccess(action.payload),
      };
    }
    case EULA_LOAD_ERROR: {
      return {
        ...state,
        latestEula: setError(action.error),
      };
    }
    case EULA_ACCEPT_REQUEST: {
      return {
        ...state,
        agreeingStatus: setLoading(),
      };
    }
    case EULA_ACCEPT_ERROR: {
      return {
        ...state,
        agreeingStatus: setError(action.error),
      };
    }
    case EULA_ACCEPT_SUCCESS: {
      return {
        ...state,
        agreeingStatus: setSuccess(true),
      };
    }
  }
  return state;
}
