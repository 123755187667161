import React from 'react';
import { Card, CardMedia, Link, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  media: {
    height: 140,
  },
});

export interface Props {
  imageUrl: string;
}

const OriginalShelfImageCard: React.FC<Props> = ({ imageUrl }: Props): JSX.Element => {
  const styleClasses = useStyles();
  return (
    <Card>
      <Link component="a" rel="noopener noreferrer" href={imageUrl} target="_blank">
        <CardMedia className={styleClasses.media} component="div" image={imageUrl} title="Original Shelf Image" />
      </Link>
    </Card>
  );
};

export default OriginalShelfImageCard;
