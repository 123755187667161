import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import HighlightIcon from '@material-ui/icons/Highlight';
import CloseIcon from '@material-ui/icons/Close';
import InspirationImagesTabs from './InspirationImagesTabs';
import InspirationImagesSwitch from './InspirationImagesSwitch';
import UploadInspirationImages from './UploadInspirationImages';
import InspirationImagesList from './InspirationimagesList';

import { ReduxPlanogramPropertyNames } from './types';
import Typography from '@material-ui/core/Typography';

interface Props {
  planogramId: string;
  reduxPlanogramPropsName: ReduxPlanogramPropertyNames;
}

const ManageInspirationImages: React.FC<Props> = ({ planogramId, reduxPlanogramPropsName }: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOnClick = (): void => {
    setIsOpen(prevValue => !prevValue);
  };

  const handleOnDialogClose = (): void => {
    setIsOpen(false);
  };

  return (
    <>
      <Tooltip title="Manage Inspiration Images">
        <IconButton data-testid="manage-inspiration-images-btn" onClick={handleOnClick}>
          <HighlightIcon color="secondary" />
        </IconButton>
      </Tooltip>
      <Dialog fullWidth maxWidth="xl" open={isOpen} onClose={handleOnDialogClose}>
        <DialogTitle>
          <Typography variant="overline">
            <HighlightIcon fontSize="large" />
            &nbsp; Manage Inspiration Images
          </Typography>
        </DialogTitle>
        <DialogContent dividers={true}>
          <InspirationImagesSwitch planogramId={planogramId} reduxPlanogramPropsName={reduxPlanogramPropsName} />
          <InspirationImagesTabs>
            <UploadInspirationImages planogramId={planogramId} />
            <InspirationImagesList planogramId={planogramId} />
          </InspirationImagesTabs>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={handleOnDialogClose} className="pull-right">
            <CloseIcon />
            &nbsp; Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ManageInspirationImages;
