import * as React from 'react';
import {
  Avatar,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableHead,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Close, Create, DoneAll, ErrorOutline, NotInterested, Photo, ViewList } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import trackComponent from 'infrastructure/tracking/withTracking';
import Alert from 'reactstrap/lib/Alert';
import { StyledTableCell, StyledTableRow } from '../../../../scss/MaterialStyles';
import PlanogramsState from '../../state';
import ShelfViewModel from '../../types/ShelfViewModel';
import { isError, isLoading } from 'infrastructure/utils/RemoteObjectStatus';

interface ShelvesListModalState {
  sortByProperty: string;
  sortByDescending: boolean;
}

class ShelvesListModal extends React.Component<DispatchProps & PlanogramsState, ShelvesListModalState> {
  constructor(props: DispatchProps & PlanogramsState) {
    super(props);
    this.state = {
      sortByDescending: false,
      sortByProperty: 'name',
    };
  }

  componentDidMount(): void {
    this.props.loadShelves();
  }

  render(): JSX.Element {
    return (
      <div>
        <Dialog open={true} onClose={this.props.hideNewModal} maxWidth="xl">
          <DialogTitle>
            <Typography variant="overline" gutterBottom>
              <ViewList color="primary" fontSize="large" />
              &nbsp; Select an existing shelf to get started with your planogram
            </Typography>
            <Divider light variant="middle" />
          </DialogTitle>
          <DialogContent>
            {isError(this.props.loadShelvesStatus) ? this.renderError() : !isLoading(this.props.loadShelvesStatus) && !isLoading(this.props.loadBrandsStatus) ? this.renderShelves() : null}
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={this.props.hideNewModal}>
              <Close />
              &nbsp;
              <Typography variant="button">Cancel</Typography>
            </Button>
          </DialogActions>
          {isLoading(this.props.loadShelvesStatus) || isLoading(this.props.loadBrandsStatus) ? <LinearProgress color="primary" /> : null}
        </Dialog>
      </div>
    );
  }

  renderShelves(): JSX.Element | null {
    if (isLoading(this.props.loadShelvesStatus)) {
      return <div>Loading...</div>;
    }
    if (isError(this.props.loadShelvesStatus)) {
      return null;
    }
    if (this.props.loadShelvesStatus.data?.length === 0) {
      return (
        <div>
          <Alert color="warning">
            <strong>{`You don't have access to any shelf.`}</strong>
          </Alert>
        </div>
      );
    }
    return (
      <Paper>
        <Table size="small" className="sticky-header-table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Project</StyledTableCell>
              <StyledTableCell>Store</StyledTableCell>
              <StyledTableCell>Phase</StyledTableCell>
              <StyledTableCell>Shelf</StyledTableCell>
              <StyledTableCell>Start</StyledTableCell>
              <StyledTableCell>End</StyledTableCell>
              <StyledTableCell>Image</StyledTableCell>
              <StyledTableCell />
              <StyledTableCell />
              <StyledTableCell />
            </StyledTableRow>
          </TableHead>
          <TableBody>{this.renderShelfRows()}</TableBody>
        </Table>
      </Paper>
    );
  }

  renderShelfRows(): JSX.Element[] {
    const shelves = this.props.loadShelvesStatus.data ?? [];
    return shelves.map((shelf: ShelfViewModel) => {
      return (
        <StyledTableRow key={shelf.shelfReferenceId}>
          <StyledTableCell>{shelf.projectName}</StyledTableCell>
          <StyledTableCell>{shelf.storeName}</StyledTableCell>
          <StyledTableCell>{shelf.phaseNum}</StyledTableCell>
          <StyledTableCell>{shelf.shelfNum}</StyledTableCell>
          <StyledTableCell>{format(shelf.startDate, 'dd MMM yyyy')}</StyledTableCell>
          <StyledTableCell>{format(shelf.endDate, 'dd MMMM yyyy')}</StyledTableCell>
          <StyledTableCell style={{ width: 100, padding: '3px 0 3px 0' }}>
            <Tooltip title="Shelf Image">
              <IconButton color="secondary" href={`/realograms/${shelf.shelfReferenceId}`} target="_blank">
                <Photo />
              </IconButton>
            </Tooltip>
          </StyledTableCell>
          <StyledTableCell>
            {shelf.readyForReporting ? (
              <Tooltip title="Flow Data available">
                <Chip
                  color="primary"
                  label="Flow Data Available"
                  size="small"
                  avatar={
                    <Avatar>
                      <DoneAll />
                    </Avatar>
                  }
                />
              </Tooltip>
            ) : (
              <Tooltip title="Flow Data Unavailable">
                <Chip
                  color="secondary"
                  label="Flow Data Unavailable"
                  size="small"
                  avatar={
                    <Avatar>
                      <NotInterested />
                    </Avatar>
                  }
                />
              </Tooltip>
            )}
            &nbsp;
          </StyledTableCell>
          <StyledTableCell>
            {shelf.isMappingQualityAssured ? (
              <Tooltip title="Mapping Quality Assured">
                <Chip
                  color="primary"
                  label="Quality Assured"
                  size="small"
                  avatar={
                    <Avatar>
                      <DoneAll />
                    </Avatar>
                  }
                />
              </Tooltip>
            ) : (
              <Tooltip title="Mapping is Not Quality Assured">
                <Chip
                  color="secondary"
                  label="Not Quality Assured"
                  size="small"
                  avatar={
                    <Avatar>
                      <NotInterested />
                    </Avatar>
                  }
                />
              </Tooltip>
            )}
          </StyledTableCell>
          <StyledTableCell>
            <Button size="small" variant="outlined" color="secondary" to={`/planograms/create/${shelf.shelfReferenceId}`} component={Link}>
              <span>
                <Create fontSize="small" />
                &nbsp;Create
              </span>
            </Button>
          </StyledTableCell>
        </StyledTableRow>
      );
    });
  }

  renderError(): JSX.Element {
    return (
      <div>
        <Alert className="top-buffer" color="danger">
          <Typography variant="caption" color="textSecondary">
            <ErrorOutline color="secondary" fontSize="small" />
            &nbsp; {this.props.loadShelvesStatus.errorDetails?.errorMessage}
          </Typography>
        </Alert>
      </div>
    );
  }
}

interface DispatchProps {
  hideNewModal(): void;

  loadShelves(): void;

  loadBrands(shelfReferenceId: string): void;

  hideCreatePlanogramModal(): void;
}

export default trackComponent(ShelvesListModal, 'PlanogramsListPage_ShelvesListModal');
