import PlanogramEventBase from './PlanogramEventBase';
import GeometryPoint from '../../shared/geometry/GeometryPoint';
import ShelfLocation from '../domain/ShelfLocation';
import { convertHeightToRelative, convertWidthToRelative, convertPointToRelative } from '../helpers/geometry';
import ViewPort from '../types/ViewPort';

class ShelfLocationGeometryMovedDto {
  hasFixedImage = false;
  currentHeight = 0;
  currentWidth = 0;
  heightBottomChanged = 0;
  heightTopChanged = 0;
  originalHeight = 0;
  originalWidth = 0;
  widthLeftChanged = 0;
  widthRightChanged = 0;
  points: GeometryPoint[] = [];
}

class ShelfLocationMovedDto {
  id = '';
  geometry: ShelfLocationGeometryMovedDto = new ShelfLocationGeometryMovedDto();
}

export default class ShelfLocationsMovedEvent extends PlanogramEventBase {
  readonly planogramId: string = '';
  readonly shelfLocationsToMove: ShelfLocationMovedDto[] = [];

  constructor(planogramId: string, shelfLocations: ShelfLocation[], viewPort: ViewPort) {
    super(`/planograms/${planogramId}/shelf-locations/move`, 'ShelfLocationsMoved');

    this.planogramId = planogramId;

    this.shelfLocationsToMove = shelfLocations.map(sl => {
      const shelfLocation = new ShelfLocationMovedDto();
      shelfLocation.id = sl.id;
      const g = sl.geometry;

      shelfLocation.geometry.hasFixedImage = g.hasFixedImage;
      shelfLocation.geometry.currentHeight = convertHeightToRelative(g.currentHeight, viewPort);
      shelfLocation.geometry.currentWidth = convertWidthToRelative(g.currentWidth, viewPort);
      shelfLocation.geometry.heightBottomChanged = convertHeightToRelative(g.heightBottomChanged, viewPort);
      shelfLocation.geometry.heightTopChanged = convertHeightToRelative(g.heightTopChanged, viewPort);
      shelfLocation.geometry.originalHeight = convertHeightToRelative(g.originalHeight, viewPort);
      shelfLocation.geometry.originalWidth = convertWidthToRelative(g.originalWidth, viewPort);
      shelfLocation.geometry.widthLeftChanged = convertWidthToRelative(g.widthLeftChanged, viewPort);
      shelfLocation.geometry.widthRightChanged = convertWidthToRelative(g.widthRightChanged, viewPort);
      shelfLocation.geometry.points = sl.geometry.points.map(p => convertPointToRelative(p, viewPort));

      return shelfLocation;
    });
  }
}
