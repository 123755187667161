export enum LoadState {
  LOADED = 'LOADED',
  LOADING = 'LOADING',
  LOAD_FAILED = 'LOAD_FAILED',
  NOT_LOADED = 'NOT_LOADED',
}

export interface SelectOption {
  value: number;
  label: string;
}

export interface MappedShelfLocation {
  id: string;
  x: string;
  y: string;
  width: string;
  height: string;
  fill: string;
  fillOpacity: number;
  stroke: string;
  strokeOpacity: number;
  text: string;
  imageUrl: string;
  tooltip?: string;
}

export interface ProductDetails {
  gtin: number;
  tradeItemDescription: string;
  gpcBrickDescription: string;
  price: number;
}
