import ShelfLocation from '../domain/ShelfLocation';
import PlanogramModel from '../domain/PlanogramModel';
import PlanogramEventBase from '../events/PlanogramEventBase';
import { PlanogramUndoableCommand } from './_planogram_commands';

export default class DeleteCommand extends PlanogramUndoableCommand {
  private planogram: PlanogramModel;
  private shelfLocationsToDelete: ShelfLocation[];

  constructor(planogram: PlanogramModel, shelfLocationsToDelete: ShelfLocation[]) {
    super();
    this.planogram = planogram;
    this.shelfLocationsToDelete = shelfLocationsToDelete;
  }

  execute(): PlanogramEventBase {
    return this.planogram.deleteShelfLocations(this.shelfLocationsToDelete);
  }

  undo(): PlanogramEventBase {
    return this.planogram.addShelfLocations(this.shelfLocationsToDelete);
  }
}
