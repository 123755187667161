import { Grid, Typography } from '@material-ui/core';
import { chunk } from 'lodash';
import moment from 'moment';
import React from 'react';
import { toMaxPrecision, toMaxPrecisionPercentFloored } from 'utils/Helpers';
import flowLogo from '../../../../../assets/img/brand/flow.jpg';
import { GpcBrickConversionShopperCategoryType, GpcBrickDetailedConversionResult, GpcBrickReportResult, InteractorsType } from '../../types';
import { InteractorsStackedBarChart } from '../InteractorsStackedBarChart';
import '../styles/StandardReportStyle.css';
import '../styles/StandardReportProductGridStyle.css';

const getGpcBrickChartData = (conversionReport: GpcBrickDetailedConversionResult[]): object[] => {
  const result = [{}] as object[];
  Object.keys(GpcBrickConversionShopperCategoryType).forEach(category => {
    result[0][category] = toMaxPrecisionPercentFloored(conversionReport?.find(x => x.gpcConversionShopperCategory === GpcBrickConversionShopperCategoryType[category])?.shopperPercent, 2);
  });
  return result;
};

export interface StandardGpcBrickReportPageProps {
  gpcBrickReport: GpcBrickReportResult;
}

const StandardGpcBrickReportPage: React.FC<StandardGpcBrickReportPageProps> = ({ gpcBrickReport }: StandardGpcBrickReportPageProps) => {
  const gpcBrickOverviewReport = gpcBrickReport.gpcBrickOverviewReport;
  const gpcBrickDemographicsReport = gpcBrickReport.gpcBrickDemographicsReport;
  const gpcBrickInternalBuysMoreThanOneProductReport = gpcBrickReport.gpcBrickInternalBuysMoreThanOneProductReport;
  const gpcBrickInternalSubstitutorsReport = gpcBrickReport.gpcBrickInternalSubstitutorsReport;
  const gpcBricksExternalBoughtTogetherReport = gpcBrickReport.gpcBricksExternalBoughtTogetherReport;
  const gpcBricksExternalLosingToReport = gpcBrickReport.gpcBricksExternalLosingToReport;
  const gpcBricksExternalWinningOverReport = gpcBrickReport.gpcBricksExternalWinningOverReport;
  const gpcBrickDetailedConversionReport = gpcBrickReport.gpcBrickDetailedConversionReport;
  const gpcBrickProductsUrlReport = gpcBrickReport.gpcBrickProductsUrlReport;

  const render4ProductsEveryRow = (): JSX.Element => {
    const productsGrouped = chunk(gpcBrickProductsUrlReport, 4);
    return (
      <div className="row">
        {productsGrouped.map((group, i) => {
          return (
            <div key={i} className="row">
              {group.map(product => {
                return (
                  <div key={product.gtin} className="col-md-3">
                    <img alt="No img" className="img-responsive gridProductImg" src={product.productImageUrl} />
                    <p className="text-center" style={{ fontSize: '.8em', padding: 0, margin: 0 }}>
                      {product.gpcBrickDescription}
                    </p>
                    <p className="text-center" style={{ fontSize: '.8em', padding: 0, margin: 0 }}>
                      {product.gtin}
                    </p>
                    <p className="text-center" style={{ fontSize: '.8em', padding: 0, margin: 0 }}>
                      {product.tradeItemDescription}
                    </p>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <div className="document">
      <div className="page">
        <div id="flowLogo">
          <img alt="flow logo" className="img-responsive" src={flowLogo} />
        </div>
        <div style={{ width: '100%' }}>
          <h3 className="text-center">{gpcBrickOverviewReport.gpcBrickDescription}</h3>
          <h5 className="text-center">
            {gpcBrickOverviewReport.projectName} - {gpcBrickOverviewReport.storeName} - Phase {gpcBrickOverviewReport.phaseNum}
            <br />
            {moment(gpcBrickOverviewReport.dateFrom).format('DD/MM/YYYY')} - {moment(gpcBrickOverviewReport.dateTo).format('DD/MM/YYYY')}
          </h5>
          <h5 className="text-left">
            <b>Visitors: </b>
            {gpcBrickOverviewReport.totalVisitors}
            <br />
            <b>Gpc Brick Interactors: </b>
            {gpcBrickOverviewReport.totalGpcInteractors}
          </h5>
          <div className="row">
            <table style={{ marginTop: '10px' }} className="table table-bordered table-striped">
              <tbody>
                <tr>
                  <td className="text-center metricNameCell">Gpc Brick Conversion rate(percent)</td>
                  <td className="text-center metricNameCell">Gpc Brick turnover per visitor(amount)</td>
                  <td className="text-center metricNameCell">Gpc Brick daily turnover(average amount)</td>
                  <td className="text-center metricNameCell">Gpc Brick destination buyers(percent)</td>
                </tr>
                <tr>
                  <td className="text-center">
                    {gpcBrickOverviewReport.gpcConversionRate.toLocaleString('en', {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    })}
                  </td>
                  <td className="text-center">{toMaxPrecision(gpcBrickOverviewReport.gpcTurnoverPerVisitor, 2)}</td>
                  <td className="text-center">{toMaxPrecision(gpcBrickOverviewReport.gpcDailyTurnover, 2)}</td>
                  <td className="text-center">
                    {gpcBrickOverviewReport.gpcDestinationBuyers.toLocaleString('en', {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    })}
                  </td>
                </tr>
                <tr>
                  <td className="text-center metricNameCell">Gpc Brick return ratio(percent)</td>
                  <td className="text-center metricNameCell">Gpc Brick lost shoppers(percent)</td>
                  <td className="text-center metricNameCell">Gpc Brick out of shelf(daily hours)</td>
                  <td className="text-center metricNameCell">Gpc Brick out of stock(percent)</td>
                </tr>
                <tr>
                  <td className="text-center">
                    {gpcBrickOverviewReport.gpcReturnRatio.toLocaleString('en', {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    })}
                  </td>
                  <td className="text-center">
                    {gpcBrickOverviewReport.gpcLostShoppers.toLocaleString('en', {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    })}
                  </td>
                  <td className="text-center">{gpcBrickOverviewReport.gpcOutOfShelfHours ? toMaxPrecision(gpcBrickOverviewReport.gpcOutOfShelfHours, 2) : '0'}</td>
                  <td className="text-center">
                    {gpcBrickOverviewReport.gpcOutOfStockPercent
                      ? gpcBrickOverviewReport.gpcOutOfStockPercent.toLocaleString('en', {
                          style: 'percent',
                          maximumFractionDigits: 2,
                        })
                      : '0%'}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="row">
            <table style={{ marginTop: '10px' }} className="table table-bordered table-striped">
              <tbody>
                <tr>
                  <td className="text-left metricNameCell">Share of space</td>
                  <td className="text-left">
                    {gpcBrickOverviewReport.shareOfSpace.toLocaleString('en', {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    })}
                  </td>
                  <td className="text-left metricNameCell">Discovery time</td>
                  <td className="text-left">{toMaxPrecision(gpcBrickOverviewReport.discoveryTime, 2)}</td>
                </tr>
                <tr>
                  <td className="text-left metricNameCell">Share of unit sales</td>
                  <td className="text-left">
                    {gpcBrickOverviewReport.shareOfUnitSales.toLocaleString('en', {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    })}
                  </td>
                  <td className="text-left metricNameCell">Seconds to buy and leave (buyers)</td>
                  <td className="text-left">{toMaxPrecision(gpcBrickOverviewReport.secondsToBuyAndLeave, 2)}</td>
                </tr>
                <tr>
                  <td className="text-left metricNameCell">Share of turnover</td>
                  <td className="text-left">
                    {gpcBrickOverviewReport.shareOfTurnover.toLocaleString('en', {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    })}
                  </td>
                  <td className="text-left metricNameCell">Seconds to touch and reject (non-buyers)</td>
                  <td className="text-left">{toMaxPrecision(gpcBrickOverviewReport.secondsToReject, 2)}</td>
                </tr>
                <tr>
                  <td className="text-left metricNameCell">Share of first pickups</td>
                  <td className="text-left">
                    {gpcBrickOverviewReport.shareOfFirstPickups.toLocaleString('en', {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    })}
                  </td>
                  <td className="text-left metricNameCell">Substitutions (number of shoppers)</td>
                  <td className="text-left">{toMaxPrecision(gpcBrickOverviewReport.substitutions, 2)}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="row" style={{ marginTop: '10px' }}>
            <h4>Within Gpc Brick</h4>
            <table className="table table-bordered table-striped">
              <tbody>
                <tr>
                  <td className="text-center metricNameCell">Buys more than one product</td>
                  <td className="text-center metricNameCell">Substitutors</td>
                </tr>
                <tr>
                  <td className="text-center">{gpcBrickInternalBuysMoreThanOneProductReport ? `${gpcBrickInternalBuysMoreThanOneProductReport?.numberOfShoppers}` : '0'}</td>
                  <td className="text-center">{gpcBrickInternalSubstitutorsReport ? `${gpcBrickInternalSubstitutorsReport?.numberOfShoppers}` : '0'}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="row" style={{ marginTop: '10px' }}>
            <h4>Outside Gpc Brick</h4>
            <table className="table table-bordered table-striped">
              <tbody>
                <tr>
                  <td className="text-center metricNameCell">Mostly bought with</td>
                  <td className="text-center metricNameCell">Mostly losing to</td>
                  <td className="text-center metricNameCell">Mostly winning over</td>
                </tr>
                <tr>
                  <td className="text-center">
                    {gpcBricksExternalBoughtTogetherReport.length >= 1
                      ? `${gpcBricksExternalBoughtTogetherReport[0].comparisonGpc} (${gpcBricksExternalBoughtTogetherReport[0].numberOfShoppers})`
                      : '-'}
                  </td>
                  <td className="text-center">
                    {gpcBricksExternalLosingToReport.length >= 1 ? `${gpcBricksExternalLosingToReport[0].losingToGpc} (${gpcBricksExternalLosingToReport[0].numberOfShoppers})` : '-'}
                  </td>
                  <td className="text-center">
                    {gpcBricksExternalWinningOverReport.length >= 1 ? `${gpcBricksExternalWinningOverReport[0].winningOverGpc} (${gpcBricksExternalWinningOverReport[0].numberOfShoppers})` : '-'}
                  </td>
                </tr>
                {gpcBricksExternalBoughtTogetherReport.length >= 2 || gpcBricksExternalLosingToReport.length >= 2 || gpcBricksExternalWinningOverReport.length >= 2 ? (
                  <tr>
                    <td className="text-center">
                      {gpcBricksExternalBoughtTogetherReport.length >= 2
                        ? `${gpcBricksExternalBoughtTogetherReport[1].comparisonGpc} (${gpcBricksExternalBoughtTogetherReport[1].numberOfShoppers})`
                        : '-'}
                    </td>
                    <td className="text-center">
                      {gpcBricksExternalLosingToReport.length >= 2 ? `${gpcBricksExternalLosingToReport[1].losingToGpc} (${gpcBricksExternalLosingToReport[1].numberOfShoppers})` : '-'}
                    </td>
                    <td className="text-center">
                      {gpcBricksExternalWinningOverReport.length >= 2 ? `${gpcBricksExternalWinningOverReport[1].winningOverGpc} (${gpcBricksExternalWinningOverReport[1].numberOfShoppers})` : '-'}
                    </td>
                  </tr>
                ) : null}
                {gpcBricksExternalBoughtTogetherReport.length >= 3 || gpcBricksExternalLosingToReport.length >= 3 || gpcBricksExternalWinningOverReport.length >= 3 ? (
                  <tr>
                    <td className="text-center">
                      {gpcBricksExternalBoughtTogetherReport.length >= 3
                        ? `${gpcBricksExternalBoughtTogetherReport[2].comparisonGpc} (${gpcBricksExternalBoughtTogetherReport[2].numberOfShoppers})`
                        : '-'}
                    </td>
                    <td className="text-center">
                      {gpcBricksExternalLosingToReport.length >= 3 ? `${gpcBricksExternalLosingToReport[2].losingToGpc} (${gpcBricksExternalLosingToReport[2].numberOfShoppers})` : '-'}
                    </td>
                    <td className="text-center">
                      {gpcBricksExternalWinningOverReport.length >= 3 ? `${gpcBricksExternalWinningOverReport[2].winningOverGpc} (${gpcBricksExternalWinningOverReport[2].numberOfShoppers})` : '-'}
                    </td>
                  </tr>
                ) : null}
                {gpcBricksExternalBoughtTogetherReport.length >= 4 || gpcBricksExternalLosingToReport.length >= 4 || gpcBricksExternalWinningOverReport.length >= 4 ? (
                  <tr>
                    <td className="text-center">
                      {gpcBricksExternalBoughtTogetherReport.length >= 4
                        ? `${gpcBricksExternalBoughtTogetherReport[3].comparisonGpc} (${gpcBricksExternalBoughtTogetherReport[3].numberOfShoppers})`
                        : '-'}
                    </td>
                    <td className="text-center">
                      {gpcBricksExternalLosingToReport.length >= 4 ? `${gpcBricksExternalLosingToReport[3].losingToGpc} (${gpcBricksExternalLosingToReport[3].numberOfShoppers})` : '-'}
                    </td>
                    <td className="text-center">
                      {gpcBricksExternalWinningOverReport.length >= 4 ? `${gpcBricksExternalWinningOverReport[3].winningOverGpc} (${gpcBricksExternalWinningOverReport[3].numberOfShoppers})` : '-'}
                    </td>
                  </tr>
                ) : null}
                {gpcBricksExternalBoughtTogetherReport.length >= 5 || gpcBricksExternalLosingToReport.length >= 5 || gpcBricksExternalWinningOverReport.length >= 5 ? (
                  <tr>
                    <td className="text-center">
                      {gpcBricksExternalBoughtTogetherReport.length >= 5
                        ? `${gpcBricksExternalBoughtTogetherReport[4].comparisonGpc} (${gpcBricksExternalBoughtTogetherReport[4].numberOfShoppers})`
                        : '-'}
                    </td>
                    <td className="text-center">
                      {gpcBricksExternalLosingToReport.length >= 5 ? `${gpcBricksExternalLosingToReport[4].losingToGpc} (${gpcBricksExternalLosingToReport[4].numberOfShoppers})` : '-'}
                    </td>
                    <td className="text-center">
                      {gpcBricksExternalWinningOverReport.length >= 5 ? `${gpcBricksExternalWinningOverReport[4].winningOverGpc} (${gpcBricksExternalWinningOverReport[4].numberOfShoppers})` : '-'}
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
          <div className="row" style={{ marginTop: '10px' }}>
            <table className="table table-bordered table-striped">
              <tbody>
                <tr>
                  <td className="text-center metricNameCell">Demographic</td>
                  <td className="text-center metricNameCell">Gpc Brick interactors</td>
                  <td className="text-center metricNameCell">Gpc Brick buyers</td>
                  <td className="text-center metricNameCell">Gpc Brick lost shoppers</td>
                </tr>
                <tr>
                  <td className="text-center metricNameCell">{gpcBrickDemographicsReport.find(x => x.demographicGroup === 'All')?.demographicGroup}</td>
                  <td className="text-center">
                    {gpcBrickDemographicsReport
                      .find(x => x.demographicGroup === 'All')
                      ?.interactorsPercent.toLocaleString('en', {
                        style: 'percent',
                        maximumFractionDigits: 2,
                      })}
                  </td>
                  <td className="text-center">
                    {gpcBrickDemographicsReport
                      .find(x => x.demographicGroup === 'All')
                      ?.buyersPercent.toLocaleString('en', {
                        style: 'percent',
                        maximumFractionDigits: 2,
                      })}
                  </td>
                  <td className="text-center">
                    {gpcBrickDemographicsReport
                      .find(x => x.demographicGroup === 'All')
                      ?.lostShoppersPercent.toLocaleString('en', {
                        style: 'percent',
                        maximumFractionDigits: 2,
                      })}
                  </td>
                </tr>
                <tr>
                  <td className="text-center metricNameCell">{gpcBrickDemographicsReport.find(x => x.demographicGroup === 'Female')?.demographicGroup}</td>
                  <td className="text-center">
                    {gpcBrickDemographicsReport
                      .find(x => x.demographicGroup === 'Female')
                      ?.interactorsPercent.toLocaleString('en', {
                        style: 'percent',
                        maximumFractionDigits: 2,
                      })}
                  </td>
                  <td className="text-center">
                    {gpcBrickDemographicsReport
                      .find(x => x.demographicGroup === 'Female')
                      ?.buyersPercent.toLocaleString('en', {
                        style: 'percent',
                        maximumFractionDigits: 2,
                      })}
                  </td>
                  <td className="text-center">
                    {gpcBrickDemographicsReport
                      .find(x => x.demographicGroup === 'Female')
                      ?.lostShoppersPercent.toLocaleString('en', {
                        style: 'percent',
                        maximumFractionDigits: 2,
                      })}
                  </td>
                </tr>
                <tr>
                  <td className="text-center metricNameCell">{gpcBrickDemographicsReport.find(x => x.demographicGroup === 'Male')?.demographicGroup}</td>
                  <td className="text-center">
                    {gpcBrickDemographicsReport
                      .find(x => x.demographicGroup === 'Male')
                      ?.interactorsPercent.toLocaleString('en', {
                        style: 'percent',
                        maximumFractionDigits: 2,
                      })}
                  </td>
                  <td className="text-center">
                    {gpcBrickDemographicsReport
                      .find(x => x.demographicGroup === 'Male')
                      ?.buyersPercent.toLocaleString('en', {
                        style: 'percent',
                        maximumFractionDigits: 2,
                      })}
                  </td>
                  <td className="text-center">
                    {gpcBrickDemographicsReport
                      .find(x => x.demographicGroup === 'Male')
                      ?.lostShoppersPercent.toLocaleString('en', {
                        style: 'percent',
                        maximumFractionDigits: 2,
                      })}
                  </td>
                </tr>
                <tr>
                  <td className="text-center metricNameCell">{gpcBrickDemographicsReport.find(x => x.demographicGroup === 'With Kids')?.demographicGroup}</td>
                  <td className="text-center">
                    {gpcBrickDemographicsReport
                      .find(x => x.demographicGroup === 'With Kids')
                      ?.interactorsPercent.toLocaleString('en', {
                        style: 'percent',
                        maximumFractionDigits: 2,
                      })}
                  </td>
                  <td className="text-center">
                    {gpcBrickDemographicsReport
                      .find(x => x.demographicGroup === 'With Kids')
                      ?.buyersPercent.toLocaleString('en', {
                        style: 'percent',
                        maximumFractionDigits: 2,
                      })}
                  </td>
                  <td className="text-center">
                    {gpcBrickDemographicsReport
                      .find(x => x.demographicGroup === 'With Kids')
                      ?.lostShoppersPercent.toLocaleString('en', {
                        style: 'percent',
                        maximumFractionDigits: 2,
                      })}
                  </td>
                </tr>
                <tr>
                  <td className="text-center metricNameCell">{gpcBrickDemographicsReport.find(x => x.demographicGroup === 'Group')?.demographicGroup}</td>
                  <td className="text-center">
                    {gpcBrickDemographicsReport
                      .find(x => x.demographicGroup === 'Group')
                      ?.interactorsPercent.toLocaleString('en', {
                        style: 'percent',
                        maximumFractionDigits: 2,
                      })}
                  </td>
                  <td className="text-center">
                    {gpcBrickDemographicsReport
                      .find(x => x.demographicGroup === 'Group')
                      ?.buyersPercent.toLocaleString('en', {
                        style: 'percent',
                        maximumFractionDigits: 2,
                      })}
                  </td>
                  <td className="text-center">
                    {gpcBrickDemographicsReport
                      .find(x => x.demographicGroup === 'Group')
                      ?.lostShoppersPercent.toLocaleString('en', {
                        style: 'percent',
                        maximumFractionDigits: 2,
                      })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <Grid container alignContent="center" alignItems="center" justify={'center'} style={{ marginTop: '10px' }} direction="column">
          <Grid item>
            <Typography variant="overline">Detailed interaction profile</Typography>
          </Grid>
          <Grid item>
            <InteractorsStackedBarChart data={getGpcBrickChartData(gpcBrickDetailedConversionReport)} interactorsType={InteractorsType.GpcBrick} />
          </Grid>
        </Grid>
        <footer className="footer">
          <strong>Copyright &copy; {new Date().getFullYear()} Flow Insights</strong>. Strictly confidential. No distribution allowed. All rights reserved.
        </footer>
      </div>
      <div className="page">
        <div id="flowLogo">
          <img alt="flow logo" className="img-responsive" src={flowLogo} />
        </div>
        <h3 className="text-center">Products in shelf</h3>
        <br />
        {render4ProductsEveryRow()}
        <footer className="footer top-buffer">
          <strong>Copyright &copy; {new Date().getFullYear()} Flow Insights</strong>. Strictly confidential. No distribution allowed. All rights reserved.
        </footer>
      </div>
    </div>
  );
};

export { StandardGpcBrickReportPage };
