import * as yup from 'yup';

export const uploadInspirationImageForm = yup.object({
  inspirationImages: yup.array().of(
    yup
      .object()
      .shape({
        description: yup.string().required('Required'),
      })
      .required()
  ),
});
