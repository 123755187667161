import { Badge, Card, CardContent, CardHeader, Collapse, IconButton, TextField, Typography } from '@material-ui/core';
import { ExpandLess, ExpandMore, FiberNew } from '@material-ui/icons';
import * as React from 'react';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import trackComponent from 'infrastructure/tracking/withTracking';
import { NewProductReason, RecordChangeReasonDto } from 'modules/planogram/types/PlanogramChangeReasons';
import PlanogramModel from 'modules/planogram/domain/PlanogramModel';

interface MyProps {
  currentPlanogram: PlanogramModel;
  onHighlightProduct(priority: boolean, gtin?: number[]): void;
  onRecordChangeReason(reason: RecordChangeReasonDto): void;
}

interface MyState {
  isExpandedNewProductsHeader: boolean;
  reasons: NewProductReasonState[];
}

interface NewProductReasonState {
  gtin: number;
  reason: string;
}

class NewProducts extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {
      isExpandedNewProductsHeader: false,
      reasons: [],
    };
  }

  componentDidUpdate() {
    const reasons = this.state.reasons;
    const newProducts = this.props.currentPlanogram.planogramChanges.newProducts;
    let needsUpdate = false;
    newProducts.forEach(newProduct => {
      let existingReason = reasons.find(r => r.gtin === newProduct.gtin);
      if (!existingReason) {
        const savedReason = this.props.currentPlanogram.changeReasons.newProductReasons.find(newP => newP.gtin === newProduct.gtin);
        existingReason = {
          gtin: newProduct.gtin,
          reason: savedReason ? savedReason.reason : '',
        };
        needsUpdate = true;
        reasons.push(existingReason);
      }
    });
    if (needsUpdate) {
      this.setState({ reasons });
    }
  }

  render() {
    const newProducts = this.props.currentPlanogram.planogramChanges.newProducts;
    const rows = newProducts.map(d => {
      const changeReason = this.state.reasons.find(r => r.gtin === d.gtin);
      if (!changeReason) {
        return null;
      }
      return (
        <div
          key={d.gtin}
          onMouseEnter={() => {
            this.props.onHighlightProduct(false, [d.gtin]);
          }}
          onMouseLeave={() => {
            this.props.onHighlightProduct(false, undefined);
          }}
        >
          <div>
            <li>
              <Typography color="textPrimary" variant="body2">
                {d.tradeItemDescription}
              </Typography>
            </li>
          </div>
          <div>
            <Typography color="textSecondary" variant="caption">
              Gtin: {d.gtin}
            </Typography>
          </div>
          {!this.props.currentPlanogram.isReadOnly ? this.renderReasonTextfield(changeReason, d.gtin, 0) : this.renderReadOnlyReason(changeReason.reason)}
        </div>
      );
    });
    return (
      <Row>
        <Col xs="12">
          <Card raised className="card-accent-info border-info bottom-buffer">
            <CardHeader
              style={{ background: 'rgb(0, 255, 0,0.2)' }}
              disableTypography={true}
              action={
                <div>
                  <Badge color="secondary" badgeContent={rows.length}>
                    <FiberNew color="primary" />
                  </Badge>
                  <IconButton onClick={() => this.setState({ isExpandedNewProductsHeader: !this.state.isExpandedNewProductsHeader })}>
                    {this.state.isExpandedNewProductsHeader ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
                  </IconButton>
                </div>
              }
              title={<Typography variant="button">New Products</Typography>}
            />
            <Collapse in={this.state.isExpandedNewProductsHeader}>
              <CardContent style={{ marginLeft: '0px', paddingLeft: '0px' }}>
                {newProducts.length === 0 ? (
                  <div className="left-buffer">
                    <Typography color="textSecondary" variant="caption">
                      No new products added.
                    </Typography>
                  </div>
                ) : (
                  <div>
                    <ol>{rows}</ol>
                  </div>
                )}
              </CardContent>
            </Collapse>
          </Card>
        </Col>
      </Row>
    );
  }
  renderReadOnlyReason(reason: string): React.ReactNode {
    return (
      <Typography variant="caption" color="textSecondary">
        Reason for change: <strong>{reason ? reason : 'N/A'}</strong>
      </Typography>
    );
  }

  private renderReasonTextfield(changeReason: NewProductReasonState, gtin: number, currentSize: number) {
    return (
      <div>
        <TextField
          value={changeReason.reason}
          onChange={evt => {
            const theReason = evt.target.value ? evt.target.value : '';
            changeReason.reason = theReason;
            this.setState({ reasons: this.state.reasons });
          }}
          id="standard-name"
          label="Reason for change"
          margin="none"
          onFocus={() => {
            this.props.onHighlightProduct(true, [gtin]);
          }}
          onBlur={() => {
            this.props.onHighlightProduct(true, undefined);
            const existingReason = this.props.currentPlanogram.changeReasons.productDelistedReasons.find(r => r.gtin === gtin);
            changeReason.reason = changeReason.reason ? changeReason.reason.trim() : '';
            if (existingReason && existingReason.reason === changeReason.reason) {
              return;
            }
            const reason = new RecordChangeReasonDto(this.props.currentPlanogram.id);
            const newProductReason = new NewProductReason(gtin, currentSize, changeReason.reason);
            reason.newProductReason = newProductReason;
            this.props.onRecordChangeReason(reason);
          }}
        />
      </div>
    );
  }
}

export default trackComponent(NewProducts, 'Aside_NewProducts');
