import PlanogramEventBase from './PlanogramEventBase';

export default class ShelfLocationImageChangedEvent extends PlanogramEventBase {
  readonly planogramId: string = '';
  readonly shelfLocationId: string = '';
  readonly imageUrl: string = '';

  constructor(planogramId: string, shelfLocationId: string, imageUrl: string) {
    super(`/planograms/${planogramId}/shelf-locations/${shelfLocationId}/image-url`, 'ShelfLocationImageChanged');
    this.planogramId = planogramId;
    this.shelfLocationId = shelfLocationId;
    this.imageUrl = imageUrl;
  }
}
