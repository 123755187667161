import ErrorDetails from 'infrastructure/exception/ErrorDetails';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import apiSaga from 'utils/apiSaga';
import {
  loadAggregatedProductReportSuccessAction,
  loadAggregatedProductReportFailureAction,
  loadAvailableAggregatedReportsFailureAction,
  loadAvailableAggregatedReportsSuccessAction,
  loadAggregatedBrandBrickReportSuccessAction,
  loadAggregatedBrandReportSuccessAction,
  loadAggregatedBrickReportSuccessAction,
  loadAggregatedBrandBrickReportFailureAction,
  loadAggregatedBrandReportFailureAction,
  loadAggregatedBrickReportFailureAction,
} from './actions';
import {
  LoadAggregatedBrandBrickReportRequestActionType,
  LoadAggregatedBrandReportRequestActionType,
  LoadAggregatedBrickReportRequestActionType,
  LoadAggregatedProductReportRequestActionType,
  LoadAvailableAggregatedReportsRequestActionType,
} from './actionTypes';
import { getAggregatedBrandBrickReport, getAggregatedBrandReport, getAggregatedBrickReport, getAggregatedProductReport, getAvailableAggregatedReportsRequest } from './apiRequests';

// eslint-disable-next-line no-unused-vars
export function* fetchAvailableAggregatedReports(action: LoadAvailableAggregatedReportsRequestActionType): SagaIterator {
  try {
    const response = yield call(apiSaga, getAvailableAggregatedReportsRequest);
    yield put(loadAvailableAggregatedReportsSuccessAction(response.data));
  } catch (e) {
    const error = e.response.data as ErrorDetails;
    yield put(loadAvailableAggregatedReportsFailureAction(error));
  }
}

export function* fetchAggregatedProductReport(action: LoadAggregatedProductReportRequestActionType): SagaIterator {
  try {
    const response = yield call(apiSaga, getAggregatedProductReport, action.gtin, action.metrics);
    console.log(response);
    yield put(loadAggregatedProductReportSuccessAction(response.data));
  } catch (e) {
    const error = e.response.data as ErrorDetails;
    yield put(loadAggregatedProductReportFailureAction(error));
  }
}

export function* fetchAggregatedBrandReport(action: LoadAggregatedBrandReportRequestActionType): SagaIterator {
  try {
    const response = yield call(apiSaga, getAggregatedBrandReport, action.brandId, action.metrics);
    console.log(response);
    yield put(loadAggregatedBrandReportSuccessAction(response.data));
  } catch (e) {
    const error = e.response.data as ErrorDetails;
    yield put(loadAggregatedBrandReportFailureAction(error));
  }
}

export function* fetchAggregatedBrickReport(action: LoadAggregatedBrickReportRequestActionType): SagaIterator {
  try {
    const response = yield call(apiSaga, getAggregatedBrickReport, action.gpcBrickCode, action.metrics);
    console.log(response);
    yield put(loadAggregatedBrickReportSuccessAction(response.data));
  } catch (e) {
    const error = e.response.data as ErrorDetails;
    yield put(loadAggregatedBrickReportFailureAction(error));
  }
}

export function* fetchAggregatedBrandBrickReport(action: LoadAggregatedBrandBrickReportRequestActionType): SagaIterator {
  try {
    const response = yield call(apiSaga, getAggregatedBrandBrickReport, action.brandId, action.gpcBrickCode, action.metrics);
    console.log(response);
    yield put(loadAggregatedBrandBrickReportSuccessAction(response.data));
  } catch (e) {
    const error = e.response.data as ErrorDetails;
    yield put(loadAggregatedBrandBrickReportFailureAction(error));
  }
}

export default function* watchAggregatedReportsSaga(): SagaIterator {
  yield takeLatest('AGGREGATED_REPORTS_LOAD_AVAILABLE_REPORTS_REQUEST', fetchAvailableAggregatedReports);
  yield takeLatest('AGGREGATED_REPORTS_LOAD_PRODUCT_REPORT_REQUEST', fetchAggregatedProductReport);
  yield takeLatest('AGGREGATED_REPORTS_LOAD_BRAND_REPORT_REQUEST', fetchAggregatedBrandReport);
  yield takeLatest('AGGREGATED_REPORTS_LOAD_BRICK_REPORT_REQUEST', fetchAggregatedBrickReport);
  yield takeLatest('AGGREGATED_REPORTS_LOAD_BRAND_BRICK_REPORT_REQUEST', fetchAggregatedBrandBrickReport);
}
