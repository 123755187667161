import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { ErrorOutline, InfoOutlined } from '@material-ui/icons';
import React from 'react';
import { Alert } from 'reactstrap';
import { StandardShopperReportLoader } from './StandardShopperReportLoader';
import { StandardShopperReportPage } from './StandardShopperReportPage';

const StandardShopperReportIndex: React.FC = () => {
  return (
    <StandardShopperReportLoader>
      {({ isLoaded, isLoading, loadError, isDataEmpty, data }): React.ReactNode => {
        const showShopperReport = !isDataEmpty && !isLoading && !loadError && isLoaded;
        return (
          <>
            {isLoading && (
              <Grid direction="column" alignItems="center" container justify="center">
                <CircularProgress /> &nbsp;&nbsp;
                <Typography variant="overline">Loading Shopper Report...</Typography>
              </Grid>
            )}
            {loadError && (
              <Alert className="top-buffer" color="danger">
                <Typography variant="caption" color="textSecondary">
                  <ErrorOutline color="secondary" fontSize="small" />
                  &nbsp;Failed to load Shopper Report. {loadError}
                </Typography>
              </Alert>
            )}
            {!loadError && !isLoading && isLoaded && isDataEmpty && (
              <Alert className="top-buffer" color="warning">
                <Typography variant="caption" color="textPrimary">
                  <InfoOutlined color="primary" fontSize="small" />
                  &nbsp; No Shopper Report Found.
                </Typography>
              </Alert>
            )}
            {showShopperReport && data && <StandardShopperReportPage shopperReport={data} />}
          </>
        );
      }}
    </StandardShopperReportLoader>
  );
};

export { StandardShopperReportIndex };
