import { isLoaded, isLoading } from 'infrastructure/utils/RemoteObjectStatus';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { loadStandardShopperComparisonReportRequestAction } from '../../actions';
import * as selectors from '../../selectors';
import { ShopperComparisonReportResult } from '../../types';
interface LoadStatusParams {
  isLoaded: boolean;
  isLoading: boolean;
  loadError: string;
  isDataEmpty: boolean;
  data: ShopperComparisonReportResult | undefined;
}
export interface StandardShopperComparisonReportLoaderProps {
  children: (params: LoadStatusParams) => React.ReactNode;
}

const StandardShopperComparisonReportLoader: React.FC<StandardShopperComparisonReportLoaderProps> = ({ children }: StandardShopperComparisonReportLoaderProps) => {
  const { shelfPhaseId1, shelfPhaseId2 } = useParams();
  const dispatch = useDispatch();
  const loadState = useSelector(selectors.getShopperComparisonReportLoadState);
  const isDataEmpty = useSelector(selectors.isShopperComparisonReportEmpty);
  const data = useSelector(selectors.getShopperComparisonReportData);
  useEffect(() => {
    if (isDataEmpty && shelfPhaseId1 && shelfPhaseId2) {
      dispatch(loadStandardShopperComparisonReportRequestAction(shelfPhaseId1, shelfPhaseId2));
    }
  }, [dispatch, isDataEmpty, shelfPhaseId1, shelfPhaseId2]);
  return (
    <>
      {children({
        isLoaded: isLoaded(loadState),
        isLoading: isLoading(loadState),
        loadError: loadState?.errorDetails?.errorMessage ?? '',
        isDataEmpty,
        data,
      })}
    </>
  );
};

export { StandardShopperComparisonReportLoader };
