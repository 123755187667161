import * as ReduxSaga from 'redux-saga';
import { takeLatest, call, put } from 'redux-saga/effects';
import { EULA_LOAD_REQUEST, EULA_ACCEPT_REQUEST, AcceptEulaRequestAction } from './actionsTypes';
import { loadingEulaError, loadingEulaSuccess, acceptEulaSuccess, acceptEulaError } from './actions';
import apiSaga from 'utils/apiSaga';
import ErrorDetails from 'infrastructure/exception/ErrorDetails';
import { getCurrentEulaApiRequest, agreeToTermsAndConditionsApiRequest } from './services';
import { push } from 'connected-react-router';
import { toastr } from 'react-redux-toastr';

export function* fetchLatestEula(): ReduxSaga.SagaIterator {
  try {
    const response = yield call(apiSaga, getCurrentEulaApiRequest);
    console.log('eula', response);
    yield put(loadingEulaSuccess(response.data));
  } catch (e) {
    const error = e.response.data as ErrorDetails;
    yield put(loadingEulaError(error));
  }
}

export function* acceptEula(action: AcceptEulaRequestAction): ReduxSaga.SagaIterator {
  try {
    yield call(apiSaga, agreeToTermsAndConditionsApiRequest, action.version);
    toastr.success('You have successfully accepted our terms.', 'Now you may continue using the system.');
    yield put(acceptEulaSuccess());
    yield put(push('/'));
  } catch (e) {
    const error = e.response.data as ErrorDetails;
    toastr.error('Failed to accept EULA. Please try again.', error.errorMessage);
    yield put(acceptEulaError(error));
  }
}

export default function* watchEulaSaga(): ReduxSaga.SagaIterator {
  yield takeLatest(EULA_LOAD_REQUEST, fetchLatestEula);
  yield takeLatest(EULA_ACCEPT_REQUEST, acceptEula);
}
