export default {
  items: [
    {
      name: 'Home',
      url: '/',
      icon: 'icon-speedometer',
    },
    {
      name: 'Planograms',
      url: '/planograms',
      icon: 'icon-puzzle',
    },
    {
      name: 'Timeline',
      url: '/timeline',
      icon: 'icon-chart',
    },
    {
      name: 'Reports',
      url: '/reports',
      icon: 'icon-chart',
      children: [
        { name: 'Standard Reports', url: '/reports/standardReports', icon: 'icon-chart' },
        { name: 'Aggregated Reports', url: '/reports/aggregatedReports', icon: 'icon-chart' },
      ],
    },
    {
      name: 'Admin',
      url: '/admin',
      icon: 'icon-settings',
      children: [
        {
          name: 'Users',
          url: '/admin/users',
          icon: 'icon-people',
        },
        {
          name: 'Planograms',
          url: '/admin/planograms',
          icon: 'icon-puzzle',
        },
        {
          name: 'Client Datasets',
          url: '/admin/client-datasets',
          icon: 'icon-docs',
        },
      ],
    },
  ],
};
