import * as React from 'react';
import { useSelector } from 'react-redux';
import { getCanManageSalesData } from './selectors';
import { getIsLoading } from '../../state/selectors';
import Form from './Form';
import ManageSalesData from './Manage';

const SalesDataManagementContainer: React.FC = (): JSX.Element | null => {
  const canManage = useSelector(getCanManageSalesData);
  const isLoadingTimelapseData = useSelector(getIsLoading);
  if (isLoadingTimelapseData) {
    return <h6>Loading Timelapse Data</h6>;
  }

  return canManage ? (
    <>
      <Form />
      <ManageSalesData />
    </>
  ) : null;
};

export default SalesDataManagementContainer;
