import { addDays } from 'date-fns';
import { Type } from 'class-transformer';

export class OperationsInfoDto {
  storeName = '';

  @Type(() => Date)
  startDate: Date = new Date();

  @Type(() => Date)
  endDate: Date = new Date();

  constructor() {
    this.startDate = new Date();
    this.endDate = addDays(this.startDate, 1);
  }
}
