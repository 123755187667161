import * as React from 'react';
import { State } from 'state';

import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import AppCrashed from './AppCrashed';

export default function(ComposedComponent: any) {
  class RootWrapper extends React.Component<State & any, any> {
    render(): JSX.Element {
      const state = this.props as State;
      if (state.globalUiState.hasAppCrashed) {
        return <AppCrashed />;
      }
      return <ComposedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state: State): State {
    return state;
  }

  return connect<State, any, RouteComponentProps<any>>(mapStateToProps, null)(RootWrapper);
}
