import React from 'react';
import trackComponent from 'infrastructure/tracking/withTracking';
import { Dialog, LinearProgress, DialogActions, Button, DialogContent, Typography, DialogTitle } from '@material-ui/core';
import { Cancel, SettingsBackupRestore } from '@material-ui/icons';

class ResetConfirmationModal extends React.Component<ResetConfirmationModalProps> {
  render(): JSX.Element {
    return (
      <Dialog open={true} onClose={this.props.onClose}>
        <DialogTitle>
          <Typography variant="overline" gutterBottom>
            <SettingsBackupRestore color="primary" fontSize="large" />
            &nbsp; Reset planogram confirmation
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">Are you absolutely sure that you wish to reset</Typography>
          <Typography variant="button">
            <strong>{this.props.planogramName}</strong>
          </Typography>
          <Typography variant="body1">back to its initial state?</Typography>
          <br />
          <Typography color="textSecondary" variant="body2">
            This action is permanent. All your work on this planogram will be lost.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" disabled={!this.props.enabled} onClick={this.props.onConfirmed}>
            <SettingsBackupRestore />
            &nbsp;{this.props.isResettingPlanogram ? 'Resetting planogram...' : 'Reset planogram'}
          </Button>
          &nbsp;
          <Button variant="contained" color="secondary" disabled={!this.props.enabled} onClick={this.props.onClose}>
            <Cancel />
            &nbsp;Cancel
          </Button>
        </DialogActions>
        {this.props.isResettingPlanogram ? <LinearProgress color="secondary" /> : null}
      </Dialog>
    );
  }
}

interface ResetConfirmationModalProps {
  enabled: boolean;
  isResettingPlanogram: boolean;
  planogramName: string;

  onClose(): void;
  onConfirmed(): void;
}
export default trackComponent(ResetConfirmationModal, 'PlanogramPage_ResetConfirmationModal');
