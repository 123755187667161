export default class ViewPort {
  private width: number;
  private height: number;

  constructor(width: number, height: number) {
    this.width = width;
    this.height = height;
  }

  getWidth(): number {
    return this.width;
  }

  getHeigth(): number {
    return this.height;
  }

  calculateRatio(width: number, height: number): number {
    const hRatio = width / this.width;
    const vRatio = height / this.height;
    const ratio = Math.min(hRatio, vRatio);
    return ratio;
  }

  equals(viewPort: ViewPort): boolean {
    return this.getWidth() === viewPort.getWidth() && this.getHeigth() === viewPort.getHeigth();
  }
}
