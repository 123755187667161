import * as yup from 'yup';

export const schemaCreateNewPlanogram = yup.object().shape({
  planogramName: yup.string().required('Planogram Name is a required field'),
  goalKpis: yup.string().required('Goal KPIs is a required field'),
  goal: yup.string().required('Shelf Goal is a required field'),
  userGroups: yup.array().of(
    yup
      .object()
      .shape({
        emails: yup
          .array()
          .of(yup.string())
          .required(),
      })
      .notRequired()
  ),
});

export const schemaEmail = yup.string().email();
