import * as React from 'react';
import ShelfLocation from 'modules/planogram/domain/ShelfLocation';
import ShelfLocationComponent from './ShelfLocation';
import ShelfLocationGeometryPoint from 'modules/planogram/domain/ShelfLocationGeometryPoint';

interface ShelfLocationsListProps {
  shelfLocations: ShelfLocation[];
  ratio: number;
  isPrintMode: boolean;
  isSelected: boolean;
  isCopying: boolean;
  onShelfLocationMouseDown(shelfLocation: ShelfLocation, evt: React.MouseEvent): void;
  onShelfLocationEdgeMouseDown(shelfLocation: ShelfLocation, point: ShelfLocationGeometryPoint): void;
  onShelfLocationContextMenu(element: Element, shelfLocation: ShelfLocation): void;
}

const ShelfLocationsList: React.FC<ShelfLocationsListProps> = ({
  shelfLocations,
  ratio,
  isPrintMode,
  onShelfLocationMouseDown,
  isSelected,
  onShelfLocationEdgeMouseDown,
  onShelfLocationContextMenu,
  isCopying,
}: ShelfLocationsListProps) => {
  const elements = shelfLocations.map(sl => (
    <ShelfLocationComponent
      onShelfLocationMouseDown={onShelfLocationMouseDown}
      ratio={ratio}
      shelfLocation={sl}
      isPrintMode={isPrintMode}
      key={`slgroup-${sl.id}`}
      isSelected={isSelected}
      onShelfLocationEdgeMouseDown={onShelfLocationEdgeMouseDown}
      isCopying={isCopying}
      onShelfLocationContextMenu={onShelfLocationContextMenu}
    />
  ));
  return <>{elements}</>;
};

export default ShelfLocationsList;
