import { SalesDataModel } from 'modules/planogram/types/SalesDataModel';

export default class CompetitorShelfDataset {
  id = '';
  shelfReferenceId = '';
  planogramId = '';
  datasetName = '';
  createdOn: Date = new Date();
  modifiedOn: Date = new Date();
  items: SalesDataModel[] = [];
}
