import { Avatar, Button, Checkbox, Chip, Table, TableBody, TableHead, Tooltip } from '@material-ui/core';
import { DoneAll, Link, NotInterested } from '@material-ui/icons';
import { plainToClass } from 'class-transformer';
import trackComponent from 'infrastructure/tracking/withTracking';
import { isLoaded, isLoading, RemoteObjectStatusInterface } from 'infrastructure/utils/RemoteObjectStatus';
import * as _ from 'lodash';
import React, { Component } from 'react';
import { StyledTableCell, StyledTableRow } from 'scss/MaterialStyles';
import { getDateInInputFormat } from 'utils/dateHelper';
import CompetitorShelf from '../../types/CompetitorShelf';

interface CompetitorShelvesLinkingTabState {
  competitorShelves: CompetitorShelf[];
}
class CompetitorShelvesLinkingTab extends Component<CompetitorShelvesLinkingTabProps, CompetitorShelvesLinkingTabState> {
  constructor(props: CompetitorShelvesLinkingTabProps, state: CompetitorShelvesLinkingTabState) {
    super(props, state);
    this.state = {
      competitorShelves: plainToClass(CompetitorShelf, this.props.competitorShelves),
    };
  }

  componentDidUpdate(prevProps: CompetitorShelvesLinkingTabProps) {
    if (prevProps.updateCompetitorShelvesState.loadStatus !== this.props.updateCompetitorShelvesState.loadStatus && isLoaded(this.props.updateCompetitorShelvesState)) {
      this.setState({ competitorShelves: this.props.competitorShelves });
    }
  }

  isLinksChanged() {
    const competitorShelvesIdsProps = this.props.competitorShelves.map(s => s.referenceId).sort();
    const competitorShelvesIdsState = this.state.competitorShelves.map(s => s.referenceId).sort();
    const isLinksChanged = !_.isEqual(competitorShelvesIdsProps, competitorShelvesIdsState);
    return isLinksChanged;
  }

  onSelectShelf(shelf: CompetitorShelf, linkedShelvesIds: string[]): void {
    if (linkedShelvesIds.includes(shelf.referenceId)) {
      const competitorShelves = this.state.competitorShelves.filter(x => x.referenceId !== shelf.referenceId);
      this.setState({ competitorShelves: competitorShelves });
    } else {
      const competitorShelves = this.state.competitorShelves;
      competitorShelves.push(shelf);
      this.setState({ competitorShelves: competitorShelves });
    }
  }

  render() {
    const projectStorePhaseShelves = this.props.similarProjectStorePhaseShelvesLoadStatus.data;
    return (
      <div className="buffer">
        {projectStorePhaseShelves && this.renderShelvesTable(projectStorePhaseShelves)}
        <Button
          size="large"
          fullWidth
          variant="contained"
          color="primary"
          disabled={!this.isLinksChanged() || isLoading(this.props.updateCompetitorShelvesState)}
          onClick={() => this.onUpdateCompetitorShelves()}
        >
          <Link />
          &nbsp; {isLoading(this.props.updateCompetitorShelvesState) ? 'Updating...' : 'Update Linked Shelves'}
        </Button>
      </div>
    );
  }
  onUpdateCompetitorShelves(): void {
    this.props.updateCompetitorShelves(this.props.planogramId, this.state.competitorShelves);
  }

  renderShelvesTable(projectStorePhaseShelves: CompetitorShelf[]) {
    return (
      <Table size="small" className="sticky-header-table">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell />
            <StyledTableCell>Id</StyledTableCell>
            <StyledTableCell>Project Info</StyledTableCell>
            <StyledTableCell>Client Info</StyledTableCell>
            <StyledTableCell>Period</StyledTableCell>
            <StyledTableCell>Shelf Image</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>{this.renderShelvesTableRows(projectStorePhaseShelves)}</TableBody>
      </Table>
    );
  }
  renderShelvesTableRows(projectStorePhaseShelves: CompetitorShelf[]) {
    const linkedShelvesIds = this.state.competitorShelves.map(s => s.referenceId);
    return projectStorePhaseShelves.map(shelf => {
      return (
        <StyledTableRow key={shelf.referenceId}>
          <StyledTableCell>
            <Checkbox checked={linkedShelvesIds.includes(shelf.referenceId)} onChange={() => this.onSelectShelf(shelf, linkedShelvesIds)} value={shelf.referenceId} color="primary" />
          </StyledTableCell>
          <StyledTableCell>
            <p>
              Reference GUID: <strong>{shelf.referenceId}</strong>
            </p>
            <p>
              Project ID: <strong>{shelf.projectId}</strong>
            </p>
            <p>
              Store ID: <strong>{shelf.storeId}</strong>
            </p>
            <p>
              Phase: <strong>{shelf.phaseNum}</strong>
            </p>
            <p>
              Shelf: <strong>{shelf.shelfNum}</strong>
            </p>
          </StyledTableCell>
          <StyledTableCell>
            <p>
              Project Name: <strong>{shelf.projectName}</strong>
            </p>
            <p>
              Store Name: <strong>{shelf.storeName}</strong>
            </p>
            {shelf.isMappingQualityAssured ? (
              <Tooltip title="Mapping Quality Assured">
                <Chip
                  color="primary"
                  label="Quality Assured"
                  size="small"
                  avatar={
                    <Avatar>
                      <DoneAll />
                    </Avatar>
                  }
                />
              </Tooltip>
            ) : (
              <Tooltip title="Mapping is Not Quality Assured">
                <Chip
                  color="secondary"
                  label="Not Quality Assured"
                  size="small"
                  avatar={
                    <Avatar>
                      <NotInterested />
                    </Avatar>
                  }
                />
              </Tooltip>
            )}
          </StyledTableCell>
          <StyledTableCell>
            <p>
              Client ID: <strong>{shelf.clientId}</strong>
            </p>
            <p>
              Client Name: <strong>{shelf.clientName}</strong>
            </p>
          </StyledTableCell>
          <StyledTableCell>
            <p>
              Start Date: <strong>{getDateInInputFormat(shelf.startdate)}</strong>
            </p>
            <p>
              End Date <strong>{getDateInInputFormat(shelf.endDate)}</strong>
            </p>
          </StyledTableCell>
          <StyledTableCell>
            <a href={shelf.shelfImageUrl} target="_blank" rel="noopener noreferrer">
              <img style={{ maxWidth: 200, maxHeight: 200 }} src={shelf.shelfImageUrl} alt="" />
            </a>
          </StyledTableCell>
        </StyledTableRow>
      );
    });
  }
}

interface CompetitorShelvesLinkingTabProps {
  planogramId: string;
  planogramName: string;
  competitorShelves: CompetitorShelf[];
  updateCompetitorShelvesState: RemoteObjectStatusInterface<{}>;
  similarProjectStorePhaseShelvesLoadStatus: RemoteObjectStatusInterface<CompetitorShelf[]>;
  updateCompetitorShelves(planogramId: string, competitorShelves: CompetitorShelf[]): void;
}

export default trackComponent(CompetitorShelvesLinkingTab, 'CompetitorShelvesLinkingTab');
