import { onCommandExecutionRequest } from '../../../../../actions';
import { useDispatch } from 'react-redux';
import { resizeOnSales } from 'modules/planogram/autoactions/resizeShelfLocations';
import PlanogramModel from 'modules/planogram/domain/PlanogramModel';
import MultiCommand from 'modules/planogram/commands/MultiCommand';
import { FlowDataShelfLocationReportResult } from 'modules/planogram/types/FlowDataReportResult';

export interface UseResize {
  onAutoResizeForSalesRequest(planogram: PlanogramModel, initialPlanogram: PlanogramModel, shelfLocationData: FlowDataShelfLocationReportResult[]): void;
}

export const useResize = (): UseResize => {
  const dispatch = useDispatch();
  return {
    onAutoResizeForSalesRequest: (planogram: PlanogramModel, initialPlanogram: PlanogramModel, shelfLocationData: FlowDataShelfLocationReportResult[]): void => {
      const commandsForResizing = resizeOnSales(planogram, initialPlanogram, shelfLocationData);
      const command = new MultiCommand(planogram, commandsForResizing);
      dispatch(onCommandExecutionRequest(command));
    },
  };
};
