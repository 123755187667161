import React, { useState } from 'react';
import { Card, CardContent, CardHeader, Collapse, Divider, IconButton, Typography } from '@material-ui/core';
import { Delete, ExpandLess, ExpandMore, People } from '@material-ui/icons';

export interface UserRulesFormProps {
  children: React.ReactNode;
  onDeleteUserGroup: () => void;
}

const CardRules: React.FC<UserRulesFormProps> = ({ children, onDeleteUserGroup }: UserRulesFormProps): JSX.Element => {
  const [expanded, setExpanded] = useState(true);
  return (
    <div className="row">
      <div className="col-lg-12">
        <Card raised className="bottom-buffer">
          <CardHeader
            disableTypography={true}
            action={
              <div>
                <IconButton data-testid="deleteUserGroupIconBtn" onClick={onDeleteUserGroup}>
                  {<Delete color="secondary" />}
                </IconButton>
                <IconButton data-testid="expandUserGroupIconBtn" onClick={(): void => setExpanded(prevState => !prevState)} aria-expanded={expanded} aria-label="Show more">
                  {expanded ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
                </IconButton>
              </div>
            }
            title={
              <Typography color="secondary" variant="overline">
                <People color="secondary" />
                &nbsp;User Group
              </Typography>
            }
          />
          <Divider light variant="middle" />
          <Collapse in={expanded} timeout="auto">
            <CardContent>{children}</CardContent>
          </Collapse>
        </Card>
      </div>
    </div>
  );
};

export default CardRules;
