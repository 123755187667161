import { plainToClass } from 'class-transformer';
import { toastr } from 'react-redux-toastr';
import { all, put, takeLatest } from 'redux-saga/effects';
import ErrorDetails from 'infrastructure/exception/ErrorDetails';
import apiClient from 'utils/apiClient';
import apiSaga from 'utils/apiSaga';
import * as actions from './actions';
import * as constants from './constants';
import { ClientDatasetDto } from './types/ClientDatasetDto';
import { ClientDto } from './types/ClientDto';
import { UploadClientDatasetResponse } from './types/UploadClientDatasetResponse';

function getClientsRequest() {
  return apiClient.get('/clients');
}

function getClientDatasetsRequest() {
  return apiClient.get('/clients/datasets');
}

function uploadClientDatasetRequest(clientDataset: ClientDatasetDto) {
  return apiClient.post('/clients/datasets/', clientDataset);
}

function deleteClientDatasetRequest(datasetId: number) {
  return apiClient.delete(`/clients/datasets/${datasetId}`);
}

export function* loadClients() {
  try {
    const response = yield apiSaga(getClientsRequest);
    const clients = plainToClass(ClientDto, response.data as ClientDto[]);
    yield put(actions.loadClientsSuccess(clients));
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    console.log('Failed to load clients', error.response.data);
    yield put(actions.loadClientsFailure(errorDetails));
  }
}

export function* loadClientDatasets() {
  try {
    const response = yield apiSaga(getClientDatasetsRequest);
    const clientDatasets = plainToClass(ClientDatasetDto, response.data as ClientDatasetDto[]);
    yield put(actions.loadClientDatasetsSuccess(clientDatasets));
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    console.log('Failed to load client datasets', error.response.data);
    yield put(actions.loadClientDatasetsFailure(errorDetails));
  }
}

export function* uploadClientDataset(action: any) {
  try {
    const clientDataset = action.clientDataset as ClientDatasetDto;
    const response = yield apiSaga(uploadClientDatasetRequest, {
      clientId: clientDataset.clientId,
      name: clientDataset.name,
      dataItems: clientDataset.dataItems,
    });
    const uploadClientDatasetResponse = plainToClass(UploadClientDatasetResponse, response.data);
    clientDataset.id = uploadClientDatasetResponse.id;
    clientDataset.createdOn = uploadClientDatasetResponse.createdOn;
    yield put(actions.uploadClientDatasetSuccess(clientDataset));
    toastr.success('Client Dataset', 'Uploaded successfully!');
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    console.log('Failed to upload client dataset', error.response.data);
    yield put(actions.uploadClientDatasetFailure(errorDetails));
    if (errorDetails.statusCode === 400) {
      toastr.error('Incorrect Data!', 'Make sure GTIN and Value are entered in number format.');
    } else {
      toastr.error('Failed to upload!', errorDetails.errorMessage);
    }
  }
}

export function* deleteClientDataset(action: any) {
  try {
    const datasetId = action.datasetId as number;
    yield apiSaga(deleteClientDatasetRequest, datasetId);
    yield put(actions.deleteClientDatasetSuccess(datasetId));
    toastr.success('Delete Dataset', 'Deleted successfully!');
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    console.log('Failed to upload client dataset', error.response.data);
    yield put(actions.deleteClientDatasetFailure(errorDetails));
    toastr.error('Failed to delete client dataset!', errorDetails.errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(constants.LOAD_CLIENTS_REQUEST, loadClients),
    takeLatest(constants.LOAD_CLIENT_DATASETS_REQUEST, loadClientDatasets),
    takeLatest(constants.UPLOAD_CLIENT_DATASET_REQUEST, uploadClientDataset),
    takeLatest(constants.DELETE_CLIENT_DATASET_REQUEST, deleteClientDataset),
  ]);
}
