import { isLoaded, isLoading } from 'infrastructure/utils/RemoteObjectStatus';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { loadStandardShelfComparisonReportRequestAction } from '../../actions';
import * as selectors from '../../selectors';
import { ShelfComparisonReportResult } from '../../types';
interface LoadStatusParams {
  isLoaded: boolean;
  isLoading: boolean;
  loadError: string;
  isDataEmpty: boolean;
  data: ShelfComparisonReportResult | undefined;
}
export interface StandardShelfComparisonReportLoaderProps {
  children: (params: LoadStatusParams) => React.ReactNode;
}

export interface ShelfComparisonUrlParams {
  shelfPhaseId1?: string;
  shelfPhaseId2?: string;
}
const StandardShelfComparisonReportLoader: React.FC<StandardShelfComparisonReportLoaderProps> = ({ children }: StandardShelfComparisonReportLoaderProps) => {
  const { shelfPhaseId1, shelfPhaseId2 }: ShelfComparisonUrlParams = useParams();
  const dispatch = useDispatch();
  const loadState = useSelector(selectors.getShelfComparisonReportLoadState);
  const isDataEmpty = useSelector(selectors.isShelfComparisonReportEmpty);
  const data = useSelector(selectors.getShelfComparisonReportData);
  useEffect(() => {
    if (isDataEmpty && shelfPhaseId1 && shelfPhaseId2) {
      dispatch(loadStandardShelfComparisonReportRequestAction(shelfPhaseId1, shelfPhaseId2));
    }
  }, [dispatch, isDataEmpty, shelfPhaseId1, shelfPhaseId2]);
  return (
    <>
      {children({
        isLoaded: isLoaded(loadState),
        isLoading: isLoading(loadState),
        loadError: loadState?.errorDetails?.errorMessage ?? '',
        isDataEmpty,
        data,
      })}
    </>
  );
};

export { StandardShelfComparisonReportLoader };
