import React from 'react';
import { FieldArray, Formik } from 'formik';
import { GroupAdd } from '@material-ui/icons';
import { Button, Grid, Typography } from '@material-ui/core';

import { schemaCreateNewPlanogram } from '../validation';
import CardPlanogramDetails from './PlanogramDetails/CardPlanogramDetails';
import PlanogramDetailsForm from './PlanogramDetails/PlanogramDetailsForm';
import CardUsersAndRules from './UsersAndRules/Users/CardUsersAndRules';
import CardRules from './UsersAndRules/Users/CardRules';
import UsersEmails from './UsersAndRules/Users/UsersEmails';
import Insights from '../../modals/SharedPlanogramFormGroups/Insights';
import PlanogramRules from '../../modals/SharedPlanogramFormGroups/PlanogramRules';
import BrandRulesTable from '../../modals/SharedPlanogramFormGroups/BrandRules/BrandRulesTable';
import BrandRulesTableRow from '../../modals/SharedPlanogramFormGroups/BrandRules/BrandRulesTableRow';
import SubmitBtn from './SubmitBtn';

import { InitialValuesCreatePlanogramModal } from '../../../types/CreatePlanogram';

export interface CreatePlanogramFormProps {
  initialValues: InitialValuesCreatePlanogramModal;
  handleSubmit: (values: InitialValuesCreatePlanogramModal) => void;
}

const Form: React.FC<CreatePlanogramFormProps> = ({ initialValues, handleSubmit }: CreatePlanogramFormProps): JSX.Element => {
  return (
    <Formik initialValues={initialValues} validationSchema={schemaCreateNewPlanogram} onSubmit={handleSubmit}>
      {({ values, handleSubmit, setFieldValue, isValid, validateForm, dirty }): JSX.Element => {
        return (
          <>
            <CardPlanogramDetails>
              <PlanogramDetailsForm />
            </CardPlanogramDetails>

            <FieldArray name="userGroups" validateOnChange={false}>
              {({ push, remove }): JSX.Element => {
                return (
                  <CardUsersAndRules
                    cardActions={
                      <Button
                        data-testid="add-user-group-btn"
                        size="large"
                        color="primary"
                        onClick={(): void => {
                          push(initialValues.userGroups[0]);
                        }}
                      >
                        <GroupAdd />
                        &nbsp;
                        <Typography variant="button">New user group</Typography>
                      </Button>
                    }
                  >
                    {values.userGroups
                      ? values.userGroups.map((userGroup, index) => {
                          return (
                            <CardRules
                              key={index}
                              onDeleteUserGroup={(): void => {
                                remove(index);
                                /**
                                 *  Formik bug
                                 *  @see: https://github.com/jaredpalmer/formik/issues/784#issuecomment-503135849
                                 */

                                setTimeout(() => {
                                  validateForm();
                                }, 0);
                              }}
                            >
                              <Grid container spacing={4}>
                                <Grid item xl={6} xs={6}>
                                  <UsersEmails userGroupIndex={index} />
                                </Grid>
                                <Grid item xl={6} xs={6}>
                                  <Insights name={`userGroups.${index}.dataInsights`} />
                                </Grid>
                              </Grid>
                              <PlanogramRules namePrefix={`userGroups.${index}.actionRules`} />
                              <div className="top-buffer">
                                <Typography variant="overline">Brand rules:</Typography>
                                <FieldArray name={`userGroups.${index}.actionRules.brandRules`} validateOnChange={false}>
                                  {(): JSX.Element => {
                                    return (
                                      <BrandRulesTable
                                        setAllFieldValues={(actionRuleName, checked): void => {
                                          userGroup.actionRules.brandRules.forEach((value, i) => {
                                            setFieldValue(
                                              // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                              `userGroups.${index}.actionRules.brandRules.${i}.actionsAllowed.${actionRuleName}` as any,
                                              checked
                                            );
                                          });
                                        }}
                                      >
                                        {values.userGroups[index].actionRules.brandRules.map(
                                          (brandRule, brandRuleIndex): JSX.Element => {
                                            return (
                                              <BrandRulesTableRow
                                                key={brandRule.brandId}
                                                brandName={brandRule.brandName}
                                                namePrefix={`userGroups.${index}.actionRules.brandRules.${brandRuleIndex}.actionsAllowed`}
                                              />
                                            );
                                          }
                                        )}
                                      </BrandRulesTable>
                                    );
                                  }}
                                </FieldArray>
                              </div>
                            </CardRules>
                          );
                        })
                      : null}
                  </CardUsersAndRules>
                );
              }}
            </FieldArray>
            <SubmitBtn onClick={handleSubmit} isFormValid={dirty && isValid} />
          </>
        );
      }}
    </Formik>
  );
};

export default Form;
