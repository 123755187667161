import React from 'react';
import Button from '@material-ui/core/Button';

import { ButtonProps } from './types';

const SelectBtn: React.FC<ButtonProps> = ({ highLightAction, isHighLightActionClearState: isClearAction }: ButtonProps): JSX.Element => {
  return (
    <Button onClick={highLightAction} color="primary" size="small">
      {isClearAction ? 'Clear' : 'Select'}
    </Button>
  );
};

export default SelectBtn;
