import React from 'react';
import Select from 'react-select';
import { PhaseOption } from '../../../reports/types';

export interface PhaseSelectorProps {
  htmlId: string;
  phaseOptions: PhaseOption[];
  selectedPhase?: PhaseOption;
  handleOnSelectPhase(selectedPhase: PhaseOption): void;
}

export const PhaseSelector: React.FC<PhaseSelectorProps> = ({ htmlId, phaseOptions, selectedPhase, handleOnSelectPhase }: PhaseSelectorProps) => {
  const defaultPhaseOption: PhaseOption = {
    label: 'Select Phase',
    startDate: '',
    endDate: '',
    phaseNum: -1,
    projectId: -1,
    shelfNum: -1,
    storeId: -1,
    storeName: '',
    value: -1,
  };
  return (
    <Select
      styles={{
        // Fixes the overlapping problem of the component
        menu: provided => ({ ...provided, zIndex: 9999 }),
      }}
      inputId={htmlId}
      placeholder="Select Phase"
      options={phaseOptions}
      value={selectedPhase === undefined ? defaultPhaseOption : selectedPhase}
      onChange={(phase: PhaseOption): void => handleOnSelectPhase(phase)}
      isMulti={false}
      isDisabled={phaseOptions.length === 0}
    />
  );
};
