import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadBrands } from '../../../actions';
import { isLoadedBrands, getLoadBrandsError } from '../../../selectors';

export interface ChildrenParams {
  isLoaded: boolean;
  loadError: string;
}

export interface HTTPRequestBrandProps {
  shelfReferenceId: string;
  children: (params: ChildrenParams) => React.ReactNode;
}

const HTTPRequestBrand: React.FC<HTTPRequestBrandProps> = ({ children, shelfReferenceId }: HTTPRequestBrandProps): JSX.Element => {
  const isLoaded = useSelector(isLoadedBrands);
  const loadError = useSelector(getLoadBrandsError);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadBrands(shelfReferenceId));
  }, [shelfReferenceId, dispatch]);
  return (
    <>
      {children({
        isLoaded,
        loadError,
      })}
    </>
  );
};

export default HTTPRequestBrand;
