import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import { Close, Edit, Label, Store } from '@material-ui/icons';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { plainToClass } from 'class-transformer';
import { addDays } from 'date-fns';
import * as React from 'react';
import { Col } from 'reactstrap';
import { getDateInInputFormat } from '../../../../utils/dateHelper';
import PlanogramState from '../../state';
import TestInfoDto from '../../types/TestInfoDto';

interface EditTestModalState {
  testInfo: TestInfoDto;
  testInfoInitial: TestInfoDto;
}

interface EditTestModalProps {
  testInfo: TestInfoDto;
  isUpdatingTestInfo: boolean;
  isUpdateTestInfoSuccess: boolean;
  isUpdateTestInfoFailure: boolean;

  updateTestInfo(testInfo: TestInfoDto): void;

  onClose(): void;
}

export default class EditTestModal extends React.Component<EditTestModalProps, EditTestModalState> {
  constructor(props: PlanogramState & EditTestModalProps, state: EditTestModalState) {
    super(props, state);

    const testInfo = plainToClass(TestInfoDto, this.props.testInfo);
    const testInfoInitial = plainToClass(TestInfoDto, this.props.testInfo);
    this.state = {
      testInfo,
      testInfoInitial,
    };
    this.onChangeTestName = this.onChangeTestName.bind(this);
    this.onChangeStoreName = this.onChangeStoreName.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<EditTestModalProps>): void {
    if (prevProps.isUpdateTestInfoSuccess !== this.props.isUpdateTestInfoSuccess && this.props.isUpdateTestInfoSuccess) {
      // Upon successful update, set initialTestInfo to the updated testInfo to disable the "Edit" button
      this.setState({
        testInfoInitial: plainToClass(TestInfoDto, this.state.testInfo),
      });
    }
  }

  onChangeStoreName(evt: React.ChangeEvent<HTMLInputElement>): undefined {
    if (evt !== undefined) {
      const newTestInfoState = { ...this.state.testInfo };
      newTestInfoState.operationsInfo.storeName = evt.target.value;
      this.setState({ testInfo: newTestInfoState });
    }
    return undefined;
  }

  onChangeTestName(evt: React.ChangeEvent<HTMLInputElement>): undefined {
    if (evt !== undefined) {
      const newTestInfoState = { ...this.state.testInfo };
      newTestInfoState.testName = evt.target.value;
      this.setState({ testInfo: newTestInfoState });
    }
    return undefined;
  }

  haveEnteredRequiredFields(): boolean | number {
    const testInfo = this.state.testInfo;
    return (
      testInfo.testName !== '' &&
      testInfo.operationsInfo.storeName !== '' &&
      Date.parse(getDateInInputFormat(testInfo.operationsInfo.startDate)) &&
      Date.parse(getDateInInputFormat(testInfo.operationsInfo.endDate))
    );
  }

  areFieldsUnchanged(): boolean {
    const testInfo = this.state.testInfo;
    const testInfoInitial = this.state.testInfoInitial;
    return (
      testInfo.testName === testInfoInitial.testName &&
      testInfo.operationsInfo.storeName === testInfoInitial.operationsInfo.storeName &&
      getDateInInputFormat(testInfo.operationsInfo.startDate) === getDateInInputFormat(testInfoInitial.operationsInfo.startDate) &&
      getDateInInputFormat(testInfo.operationsInfo.endDate) === getDateInInputFormat(testInfoInitial.operationsInfo.endDate)
    );
  }

  render(): JSX.Element {
    return (
      <Dialog open={true} onClose={this.props.onClose}>
        <DialogTitle>
          <Typography variant="overline" gutterBottom>
            <Edit color="primary" fontSize="large" />
            &nbsp; Edit Test Info
          </Typography>
          <Divider light variant="middle" />
        </DialogTitle>
        <DialogContent>{this.renderTestInfoForm()}</DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            disabled={this.props.isUpdatingTestInfo || !this.haveEnteredRequiredFields() || this.areFieldsUnchanged()}
            className="pull-left"
            onClick={(): void => this.props.updateTestInfo(this.state.testInfo)}
          >
            <Edit />
            &nbsp;
            <Typography variant="button">{this.props.isUpdatingTestInfo ? 'Editing Test...' : 'Edit Test'}</Typography>
          </Button>

          <Button variant="contained" color="secondary" onClick={this.props.onClose} className="pull-right">
            <Close />
            &nbsp;
            <Typography variant="button">Cancel</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderTestInfoForm(): JSX.Element {
    return (
      <Col>
        <TextField
          id="testName"
          label="Test Name"
          placeholder="Test Name"
          onChange={this.onChangeTestName}
          value={this.state.testInfo.testName}
          autoFocus
          fullWidth
          required
          margin="normal"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Label color="secondary" />
              </InputAdornment>
            ),
            readOnly: false,
          }}
        />
        <TextField
          id="storeName"
          label="Store Name"
          placeholder="Store Name"
          onChange={this.onChangeStoreName}
          value={this.state.testInfo.operationsInfo.storeName}
          required
          fullWidth
          margin="normal"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Store color="secondary" />
              </InputAdornment>
            ),
            readOnly: false,
          }}
        />
        <Grid container justify="space-between" spacing={3}>
          <Grid xs={6} item>
            <KeyboardDatePicker
              margin="normal"
              color="secondary"
              clearable={true || undefined}
              value={this.state.testInfo.operationsInfo.startDate}
              placeholder="dd.mm.yyyy"
              onChange={(date: Date): void => {
                const newTestInfoState = { ...this.state.testInfo };
                newTestInfoState.operationsInfo.startDate = date;
                this.setState({ testInfo: newTestInfoState });
              }}
              minDate={new Date()}
              minDateMessage="Start date should be in the future."
              format="dd.MM.yyyy"
              required
              variant="inline"
              autoOk
              inputVariant="outlined"
              label="Start Date"
              InputAdornmentProps={{ position: 'start', color: 'secondary' }}
            />
          </Grid>
          <Grid xs={6} item>
            <KeyboardDatePicker
              margin="normal"
              clearable={true || undefined}
              value={this.state.testInfo.operationsInfo.endDate}
              placeholder="dd.mm.yyyy"
              onChange={(date: Date): void => {
                const newTestInfoState = { ...this.state.testInfo };
                newTestInfoState.operationsInfo.endDate = date;
                this.setState({ testInfo: newTestInfoState });
              }}
              minDate={addDays(this.state.testInfo.operationsInfo.startDate, 1)}
              minDateMessage="End date should be at least one day after the start date."
              format="dd.MM.yyyy"
              required
              variant="inline"
              autoOk
              inputVariant="outlined"
              label="End Date"
              InputAdornmentProps={{ position: 'start' }}
            />
          </Grid>
        </Grid>
      </Col>
    );
  }
}
