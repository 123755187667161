import PlanogramAside from '../modules/planogram/components/aside/Aside';
import PublicPlanogramAside from '../modules/planogram/components/aside/PublicPlanogramAside';
import RealogramAside from 'modules/realogram/aside/Aside';
import ShelfTimelapseAside from 'modules/shelfTimelapse/aside/Aside';

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/planograms/:id', exact: true, name: 'Planogram', component: PlanogramAside },
  { path: '/planograms/:id/share/:sharingToken', name: 'PublicPlanogram', component: PublicPlanogramAside },
  { path: '/planograms/:id/test/:testPlanogramId', name: 'TestPlanogram', component: PublicPlanogramAside },
  {
    path: '/realograms/:shelfReferenceId',
    exact: true,
    name: 'Realogram Aside',
    component: RealogramAside,
  },
  {
    path: '/shelves/:shelfReferenceId/timelapse',
    exact: true,
    name: 'Shelves Timelapse',
    component: ShelfTimelapseAside,
  },
];

export default routes;
