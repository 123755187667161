import ShelfLocation from '../domain/ShelfLocation';
import ProductModel from '../types/ProductModel';

export function markGpcShelfLocations(shelfLocations: ShelfLocation[], products: ProductModel[], selectedGpcBrickCodes: number[]): void {
  shelfLocations.forEach(sl => {
    const product = products.find(p => p.gtin === sl.gtin);
    if (!product || selectedGpcBrickCodes.indexOf(product.gpcBrickCode) === -1) {
      sl.displayText = null;
      sl.color = null;
      sl.isHighlighted = false;
      return;
    }
    sl.isHighlighted = true;
  });
}

export function markHighlightedProducts(
  shelfLocations: ShelfLocation[],
  products: ProductModel[],
  priorityHighlightedBrandId?: number,
  highlightedBrandId?: number,
  priorityHighlightedGtin?: number[],
  highlightedGtin?: number[]
): void {
  highlightedBrandId = priorityHighlightedBrandId || highlightedBrandId;
  highlightedGtin = priorityHighlightedGtin || highlightedGtin;
  if (!highlightedBrandId && !highlightedGtin) {
    return;
  }
  if (products.length === 0) {
    return;
  }

  shelfLocations.forEach(sl => {
    const product = products.find(p => p.gtin === sl.gtin && p.brandId === highlightedBrandId);
    if (product || (highlightedGtin && highlightedGtin.indexOf(sl.gtin) > -1)) {
      sl.isHighlighted = true;
    }
  });
}

export function markInvalidShelfLocations(shelfLocations: ShelfLocation[]): void {
  shelfLocations.forEach(sl => {
    let color: string | null = null;

    if (!sl.validStateDescriptor.isValid()) {
      if (!sl.validStateDescriptor.isPositioningValid()) {
        color = 'FF0000';
      } else if (!sl.validStateDescriptor.isBrandRuleValid()) {
        color = 'FF33D0';
      } else {
        color = 'EDFF33';
      }
    }
    sl.color = color;
  });
}
