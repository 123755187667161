import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLatestEulaStatus, getIsAgreengStatus } from '../selectors';
import { useEffect } from 'react';
import { loadEula, acceptEula } from '../actions';
import PulseLoader from 'react-spinners/PulseLoader';
import { Alert } from 'reactstrap';
import { Typography, Card, Grid, makeStyles, Button } from '@material-ui/core';
import { ErrorOutline, Check } from '@material-ui/icons';
import Markdown from 'react-markdown';
import { isError, isLoaded, isLoading } from 'infrastructure/utils/RemoteObjectStatus';

const useStyles = makeStyles(() => ({
  eula: {
    padding: 10,
    marginTop: 10,
    marginBottom: 20,
  },
  eulaHeader: {
    textAlign: 'center',
    marginBottom: 10,
  },
}));

export const EulaPage: React.FC = () => {
  const dispatch = useDispatch();
  const latestEula = useSelector(getLatestEulaStatus);
  const aggreeingStatus = useSelector(getIsAgreengStatus);
  const classes = useStyles();

  useEffect(() => {
    dispatch(loadEula());
  }, [dispatch]);
  return (
    <div>
      {isLoading(latestEula) && <PulseLoader color={'#031f5c'} css="margin-top: 50px;" />}
      {isError(latestEula) && (
        <div>
          <Alert className="top-buffer" color="danger">
            <Typography variant="caption" color="textSecondary">
              <ErrorOutline color="secondary" fontSize="small" />
              &nbsp;
              <strong>Something went wrong. Please refresh the page to try to load EULA again.</strong>
              <p> {latestEula.errorDetails?.errorMessage}</p>
            </Typography>
          </Alert>
        </div>
      )}
      {isLoaded(latestEula) && latestEula.data && (
        <Grid container justify="center">
          <Grid xs={12} lg={6} md={9} sm={12} item>
            <Card className={classes.eula}>
              <h2 className={classes.eulaHeader}>Please Agree to the following terms and conditions before continuing</h2>
              <Markdown source={latestEula.data.markdownText} />
              <Button
                disabled={isLoading(aggreeingStatus)}
                color="primary"
                variant="contained"
                onClick={(): void => {
                  if (latestEula.data) {
                    dispatch(acceptEula(latestEula.data.version));
                  }
                }}
              >
                <Check /> &nbsp; I AGREE
              </Button>
            </Card>
          </Grid>
        </Grid>
      )}
    </div>
  );
};
