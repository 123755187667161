import AuthState from './state';
import { Selector } from 'react-redux';
import { State } from '../../state';
import { createSelector } from 'reselect';
import { OidcUser, UserProfile } from './types/OidcUser';

const getState = (state: State): AuthState => state.auth;

export const getUser: Selector<State, OidcUser | null> = createSelector(getState, state => state.loadUserStatus.data ?? null);
export const getUserProfile: Selector<State, UserProfile | undefined> = createSelector(getUser, user => user?.profile);
export const getUserId: Selector<State, number> = createSelector(getUserProfile, profile => {
  return profile?.sub ? Number(profile.sub) : -1;
});
