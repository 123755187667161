import { isLoaded, isLoading } from 'infrastructure/utils/RemoteObjectStatus';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadAvailableAggregatedReportsRequestAction } from '../actions';
import { getLoadState, isDataEmpty } from '../selectors';
interface LoadStatusParams {
  isLoaded: boolean;
  isLoading: boolean;
  loadError: string;
  isEmpty: boolean;
}

export interface AvailableAggregatedReportsLoaderProps {
  children: (params: LoadStatusParams) => React.ReactNode;
}

const AvailableAggregatedReportsLoader: React.FC<AvailableAggregatedReportsLoaderProps> = ({ children }: AvailableAggregatedReportsLoaderProps) => {
  const dispatch = useDispatch();
  const loadState = useSelector(getLoadState);
  const isEmpty = useSelector(isDataEmpty);

  useEffect(() => {
    if (isEmpty) {
      dispatch(loadAvailableAggregatedReportsRequestAction());
    }
  }, [dispatch, isEmpty]);
  return (
    <>
      {children({
        isLoaded: isLoaded(loadState),
        isLoading: isLoading(loadState),
        loadError: loadState?.errorDetails?.errorMessage ?? '',
        isEmpty,
      })}
    </>
  );
};

export { AvailableAggregatedReportsLoader };
