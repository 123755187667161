import { Mark } from '@material-ui/core/Slider';
import moment from 'moment';
import { createSelector, Selector } from 'reselect';
import { WeekDate } from 'types/CommonTypes';
import { isLoading, RemoteObjectStatusInterface } from '../../../infrastructure/utils/RemoteObjectStatus';
import { State } from '../../../state';
import { CameraImagesDataItem, ShelfConditionImagesState, ShelfConditionsResult, StoreOpenHours, WeekOption } from './types';
import { getOperationalHourTimestamps } from 'utils/dateHelper';
const getState = (state: State): ShelfConditionImagesState => state.shelfConditionImages;

const getShelfConditionsResultStatus: Selector<State, RemoteObjectStatusInterface<ShelfConditionsResult>> = createSelector(getState, state => state.shelfConditionsResultStatus);

export const getIsLoading: Selector<State, boolean> = createSelector(getShelfConditionsResultStatus, status => isLoading(status));

export const getFetchError: Selector<State, string> = createSelector(getShelfConditionsResultStatus, status => status.errorDetails?.errorMessage ?? '');

export interface TempSliderObject {
  camera: string;
  imageUrl: string;
}
export type SliderData = Mark & { time: string };
export interface TimesImagesDataItem {
  imageUrl: string;
  imgPromise: Promise<string>;
}
export interface CarouselData {
  timesImagesData: {
    [i: string]: TimesImagesDataItem[];
  };
  sliderData: SliderData[];
}

export const getShelfConditionsData: Selector<State, ShelfConditionsResult | undefined> = createSelector(getShelfConditionsResultStatus, status => {
  const data = status.data;
  return data;
});
export const getCamImagesData: Selector<State, CameraImagesDataItem[] | undefined> = createSelector(getShelfConditionsData, data => {
  const camImages = data?.cameras;
  return camImages;
});
export const getProjectDates: Selector<State, { projectStartDate: string | undefined; projectEndDate: string | undefined }> = createSelector(getShelfConditionsData, data => {
  const projectStartDate = data?.projectStartDate;
  const projectEndDate = data?.projectEndDate;
  return { projectStartDate, projectEndDate };
});

export const getStoreOpeningHours: Selector<State, StoreOpenHours[] | undefined> = createSelector(getShelfConditionsData, data => {
  const storeOpeningHours = data?.storeOpeningHours;
  return storeOpeningHours;
});
export const getSelectedWeek: Selector<State, WeekDate | undefined> = createSelector(getState, state => {
  return state.selectedWeek;
});
export const getWeeksInTimelapse: Selector<State, WeekDate[] | undefined> = createSelector(getState, state => {
  return state.weeksInTimelapse;
});

export const getSelectedWeekOption: Selector<State, WeekOption | undefined> = createSelector(getSelectedWeek, selectedWeek => {
  if (!selectedWeek) {
    return undefined;
  }
  const weekOption: WeekOption = {
    label: `Week ${moment(selectedWeek.startDate).week().toString()} [${moment(selectedWeek.startDate).format('ddd, Do MMM YY')} to ${moment(selectedWeek.endDate).format('ddd, Do MMM YY')}]`,
    value: { startDate: selectedWeek.startDate, endDate: selectedWeek.endDate },
  };
  return weekOption;
});

export const getWeekOptionsInTimelapse: Selector<State, WeekOption[] | undefined> = createSelector(getWeeksInTimelapse, weeks => {
  if (!weeks) {
    return undefined;
  }
  return weeks.map(week => {
    const weekOption: WeekOption = {
      label: `Week ${moment(week.startDate).week().toString()} [${moment(week.startDate).format('ddd, Do MMM YY')} to ${moment(week.endDate).format('ddd, Do MMM YY')}]`,
      value: { startDate: week.startDate, endDate: week.endDate },
    };
    return weekOption;
  });
});

export const getCarouselData: Selector<State, CarouselData | undefined> = createSelector(
  getCamImagesData,
  getProjectDates,
  getStoreOpeningHours,
  getWeeksInTimelapse,
  getSelectedWeek,
  (camImages, { projectStartDate, projectEndDate }, storeOpeningHours, weeksInTimelapse, selectedWeek) => {
    if (!projectStartDate || !projectEndDate || !storeOpeningHours || !camImages || !selectedWeek || !weeksInTimelapse) {
      return undefined;
    }
    const operationalHourTimestamps = getOperationalHourTimestamps(selectedWeek.startDate, selectedWeek.endDate, storeOpeningHours); // timestamps for every hour of store opening hours for the selected week
    // Attach TimestampImages for hourlyTimestamps
    const timestampImagesDictionary = operationalHourTimestamps.reduce((acc, timestamp: string) => {
      return {
        ...acc,
        ...{
          [timestamp]: camImages
            .map(dataItem => {
              const imageObject = dataItem.shelfImages.find(data => {
                const hourOfTimestamp = moment(data.timestamp).format('YYYY-MM-DDTHH');
                const hourOfImage = moment(timestamp).format('YYYY-MM-DDTHH');
                return hourOfTimestamp === hourOfImage; // find first image which has the same hour
              });
              const imageUrl = imageObject ? imageObject.imageUrl : 'https://via.placeholder.com/350?text=No+Image';
              return { camera: dataItem.name, imageUrl };
            })
            .map((i: { camera: string; imageUrl: string }) => {
              const imgPromise = new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = function (): void {
                  resolve(i.imageUrl);
                };
                img.onerror = (): void => {
                  reject(i.imageUrl);
                };
                img.src = i.imageUrl;
              });
              return {
                imageUrl: i.imageUrl,
                imgPromise,
              };
            }),
        },
      };
    }, {});
    const hourlySliderData = [...operationalHourTimestamps]
      .sort((a, b) => {
        return Date.parse(a) > Date.parse(b) ? 1 : -1;
      })
      .map((value, index) => ({ value: index, label: '', time: value }));

    return {
      timesImagesData: timestampImagesDictionary,
      sliderData: hourlySliderData,
    };
  }
);

export const getCarouselIndex: Selector<State, number> = createSelector(getState, state => {
  return state.currentIndex;
});

export const getCarouselTime: Selector<State, Date | null> = createSelector(getState, state => {
  return state.currentTimestamp === '' ? null : new Date(state.currentTimestamp);
});
