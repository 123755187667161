import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { ErrorOutline, InfoOutlined } from '@material-ui/icons';
import React from 'react';
import { Alert } from 'reactstrap';
import { StandardBrandComparisonReportLoader } from './StandardBrandComparisonReportLoader';
import { StandardBrandComparisonReportPage } from './StandardBrandComparisonReportPage';
const StandardBrandComparisonReportIndex: React.FC = () => {
  return (
    <StandardBrandComparisonReportLoader>
      {({ isLoaded, isLoading, loadError, isDataEmpty, data }): React.ReactNode => {
        const showBrandComparisonReport = !isDataEmpty && !isLoading && !loadError && isLoaded;
        return (
          <>
            {isLoading && (
              <Grid direction="column" alignItems="center" container justify="center">
                <CircularProgress /> &nbsp;&nbsp;
                <Typography variant="overline">Loading Brand Comparison Report...</Typography>
              </Grid>
            )}
            {loadError && (
              <Alert className="top-buffer" color="danger">
                <Typography variant="caption" color="textSecondary">
                  <ErrorOutline color="secondary" fontSize="small" />
                  &nbsp;Failed to load Brand Comparison Report. {loadError}
                </Typography>
              </Alert>
            )}
            {!loadError && !isLoading && isLoaded && isDataEmpty && (
              <Alert className="top-buffer" color="warning">
                <Typography variant="caption" color="textPrimary">
                  <InfoOutlined color="primary" fontSize="small" />
                  &nbsp; No Brand Comparison Report Found.
                </Typography>
              </Alert>
            )}
            {showBrandComparisonReport && data && <StandardBrandComparisonReportPage brandComparisonReport={data} />}
          </>
        );
      }}
    </StandardBrandComparisonReportLoader>
  );
};

export { StandardBrandComparisonReportIndex };
