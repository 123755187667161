import { Button } from '@material-ui/core';
import { AddPhotoAlternate, Videocam } from '@material-ui/icons';
import { isLoaded } from 'infrastructure/utils/RemoteObjectStatus';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { RouteProps, useParams } from 'react-router';
import * as selectors from '../selectors';

const RealogramPageFooter: React.FC<RouteProps> = (): JSX.Element | null => {
  const loadState = useSelector(selectors.realogramLoadingState);

  return isLoaded(loadState) ? <FooterElements /> : null;
};

const FooterElements: React.FC = (): JSX.Element => {
  const { shelfReferenceId } = useParams<{ shelfReferenceId: string }>();
  return (
    <>
      <Button size="small" variant="outlined" color="primary" className="pull-left" href={`/planograms/create/${shelfReferenceId}`} target="_blank">
        <AddPhotoAlternate />
        &nbsp; Create Planogram
      </Button>
      &nbsp;
      <Button href={`/shelves/${shelfReferenceId}/timelapse`} size="small" variant="outlined" color="primary" target="_blank">
        <Videocam />
        &nbsp;Shelf Timelapse
      </Button>
    </>
  );
};

export default RealogramPageFooter;
