import PlanogramModel from '../domain/PlanogramModel';
import ShelfLocation from '../domain/ShelfLocation';
import GeometryPoint from '../../shared/geometry/GeometryPoint';
import PlanogramEventBase from '../events/PlanogramEventBase';
import { PlanogramCommand } from './_planogram_commands';

export default class TransientMoveCommand extends PlanogramCommand {
  private planogram: PlanogramModel;
  private shelfLocations: ShelfLocation[];
  private geometryOffset: GeometryPoint;

  constructor(planogram: PlanogramModel, shelfLocations: ShelfLocation[], geometryOffset: GeometryPoint) {
    super();
    this.planogram = planogram;
    this.shelfLocations = shelfLocations;
    this.geometryOffset = geometryOffset;
  }

  execute(): PlanogramEventBase | undefined {
    return this.planogram.transientMove(this.shelfLocations, this.geometryOffset);
  }
}
