import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Card, Grid, Typography, CircularProgress } from '@material-ui/core';
import * as selectors from '../selectors';
import { PhaseOption, ProjectOption, ShelfOption, StoreOption } from 'modules/reports/types';
import { TrendingUp, ListAlt, Bookmark, GetApp } from '@material-ui/icons';
import { StandardReportsShelfSelector } from './StandardReportsShelfSelector';
import { getProjectCauseEffectReport, getGpcBrickCauseEffectReport, getBrandCauseEffectReport, getProductCauseEffectReport } from './../apiRequests';

type SetLoading = (state: boolean) => void;
export interface CauseEffectReportSelectorProps {
  selectedProjectId?: number;
  selectedStoreId?: number;
  selectedShelf?: number;
  selectedPhase?: number;
}

const CauseEffectReportSelector: React.FC<CauseEffectReportSelectorProps> = (props: CauseEffectReportSelectorProps) => {
  const [selectedProjectId, setSelectedProjectId] = useState(props.selectedProjectId);
  const [selectedStoreId, setSelectedStoreId] = useState(props.selectedStoreId);
  const [selectedShelf, setSelectedShelf] = useState(props.selectedShelf);
  const [selectedPhase, setSelectedPhase] = useState(props.selectedPhase);

  const [selectedComparisonProjectId, setSelectedComparisonProjectId] = useState<number | undefined>();
  const [selectedComparisonStoreId, setSelectedComparisonStoreId] = useState<number | undefined>();
  const [selectedComparisonShelf, setSelectedComparisonShelf] = useState<number | undefined>();
  const [selectedComparisonPhase, setSelectedComparisonPhase] = useState<number | undefined>();

  const [isLoadingProject, setIsLoadingProject] = useState(false);
  const [isLoadingGpcBrick, setIsLoadingGpcBrick] = useState(false);
  const [isLoadingBrand, setIsLoadingBrand] = useState(false);
  const [isLoadingProduct, setIsLoadingProduct] = useState(false);

  const phaseShelves = useSelector(selectors.getData);

  const handleSelectedProjectChanged = (project: ProjectOption): void => {
    setSelectedProjectId(project.projectId);
  };

  const handleSelectedStoreChanged = (store: StoreOption): void => {
    setSelectedStoreId(store.storeId);
  };

  const handleSelectedShelfChanged = (shelf: ShelfOption): void => {
    setSelectedShelf(shelf.shelfNum);
  };

  const handleSelectedPhaseChanged = (phase: PhaseOption): void => {
    setSelectedPhase(phase.phaseNum);
  };

  const handleSelectedComparisonProjectChanged = (project: ProjectOption): void => {
    setSelectedComparisonProjectId(project.projectId);
  };

  const handleSelectedComparisonStoreChanged = (store: StoreOption): void => {
    setSelectedComparisonStoreId(store.storeId);
  };

  const handleSelectedComparisonShelfChanged = (shelf: ShelfOption): void => {
    setSelectedComparisonShelf(shelf.shelfNum);
  };

  const handleSelectedComparisonPhaseChanged = (phase: PhaseOption): void => {
    setSelectedComparisonPhase(phase.phaseNum);
  };

  const canDownloadReports = (): boolean => {
    return (
      selectedProjectId !== undefined &&
      selectedComparisonProjectId !== undefined &&
      selectedStoreId !== undefined &&
      selectedComparisonStoreId !== undefined &&
      selectedShelf !== undefined &&
      selectedComparisonShelf !== undefined &&
      selectedPhase !== undefined &&
      selectedComparisonPhase !== undefined
    );
  };

  const downloadAsCsv = (apiRequest: Promise<any>, name: string, setLoading: SetLoading): void => {
    setLoading(true);
    apiRequest.then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${name}.csv`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      setLoading(false);
    });
  };
  const getTargetAndComparison = (): { target: string; comparison: string } => {
    const target = `${selectedProjectId}-${selectedStoreId}-${selectedPhase}-${selectedShelf}`;
    const comparison = `${selectedComparisonProjectId}-${selectedComparisonStoreId}-${selectedComparisonPhase}-${selectedComparisonShelf}`;
    return { target, comparison };
  };

  const downloadProjectReport = (evt: any): void => {
    evt.preventDefault();
    const { target, comparison } = getTargetAndComparison();
    try {
      downloadAsCsv(getProjectCauseEffectReport(target, comparison), `ProjectCauseEffectReport${target}_${comparison}`, setIsLoadingProject);
    } catch (e) {
      setIsLoadingProject(false);
      alert(`Could not load ProjectCauseEffectReport${target}_${comparison}`);
    }
  };

  const downloadGcpBrickReport = (evt: any): void => {
    evt.preventDefault();
    const { target, comparison } = getTargetAndComparison();
    try {
      downloadAsCsv(getGpcBrickCauseEffectReport(target, comparison), `GpcBrickCauseEffectReport${target}_${comparison}`, setIsLoadingGpcBrick);
    } catch (e) {
      setIsLoadingGpcBrick(false);
      alert(`Could not load GpcBrickCauseEffectReport${target}_${comparison}`);
    }
  };

  const downloadBrandReport = (evt: any): void => {
    evt.preventDefault();
    const { target, comparison } = getTargetAndComparison();
    try {
      downloadAsCsv(getBrandCauseEffectReport(target, comparison), `BrandCauseEffectReport${target}_${comparison}`, setIsLoadingBrand);
    } catch (e) {
      setIsLoadingBrand(false);
      alert(`Could not load BrandCauseEffectReport${target}_${comparison}`);
    }
  };

  const downloadProductReport = (evt: any): void => {
    evt.preventDefault();
    const { target, comparison } = getTargetAndComparison();
    try {
      downloadAsCsv(getProductCauseEffectReport(target, comparison), `ProductCauseEffectReport${target}_${comparison}`, setIsLoadingProduct);
    } catch (e) {
      setIsLoadingProduct(false);
      alert(`Could not load ProductCauseEffectReport${target}_${comparison}`);
    }
  };

  return (
    <Card style={{ margin: '8px', padding: '16px', backgroundColor: '#E0FFFF', width: '100%' }}>
      <Typography variant="h5" color="textPrimary" align="left">
        <TrendingUp fontSize="large" color="primary" />
        &nbsp; Cause Effect Reports
      </Typography>
      <Card style={{ margin: '8px', padding: '16px', backgroundColor: '#e5e4e2', width: '100%' }}>
        <Grid container direction="row" justify="flex-start" spacing={2}>
          <Grid item xs={5}>
            <Grid container direction="column" justify="flex-start" spacing={2}>
              <Grid item>
                <Typography variant="h6" color="textPrimary" align="left">
                  <ListAlt fontSize="large" color="primary" />
                  &nbsp;Shelf 1
                </Typography>
              </Grid>
              <Grid item>
                <StandardReportsShelfSelector
                  phaseShelves={phaseShelves}
                  selectedProjectId={selectedProjectId}
                  selectedStoreId={selectedStoreId}
                  selectedShelfNum={selectedShelf}
                  selectedPhaseNum={selectedPhase}
                  onChangeProject={(project: ProjectOption): void => handleSelectedProjectChanged(project)}
                  onChangeStore={(store: StoreOption): void => handleSelectedStoreChanged(store)}
                  onChangeShelf={(shelf: ShelfOption): void => handleSelectedShelfChanged(shelf)}
                  onChangePhase={(phase: PhaseOption): void => handleSelectedPhaseChanged(phase)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Grid container direction="column" justify="flex-start" spacing={2}>
              <Grid item>
                <Typography variant="h6" color="textPrimary" align="left">
                  <ListAlt fontSize="large" color="primary" />
                  &nbsp;Shelf 2
                </Typography>
              </Grid>
              <Grid item>
                <StandardReportsShelfSelector
                  phaseShelves={phaseShelves}
                  selectedProjectId={selectedComparisonProjectId}
                  selectedStoreId={selectedComparisonStoreId}
                  selectedShelfNum={selectedComparisonShelf}
                  selectedPhaseNum={selectedComparisonPhase}
                  onChangeProject={(project: ProjectOption): void => handleSelectedComparisonProjectChanged(project)}
                  onChangeStore={(store: StoreOption): void => handleSelectedComparisonStoreChanged(store)}
                  onChangeShelf={(shelf: ShelfOption): void => handleSelectedComparisonShelfChanged(shelf)}
                  onChangePhase={(phase: PhaseOption): void => handleSelectedComparisonPhaseChanged(phase)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid container direction="column" justify="flex-start" spacing={2}>
              <Grid item>
                <Typography variant="h6" color="textPrimary" align="left">
                  <GetApp fontSize="large" color="primary" />
                  &nbsp; Download
                </Typography>
              </Grid>
              <Grid item>
                <Button className="text-center" size="large" variant="contained" color="primary" disabled={!canDownloadReports() || isLoadingProject} onClick={downloadProjectReport}>
                  <Bookmark color="inherit" />
                  &nbsp; Project Cause Effect Report &nbsp;
                  {isLoadingProject && <CircularProgress />}
                </Button>
              </Grid>
              <Grid item>
                <Button className="text-center" size="large" variant="contained" color="primary" disabled={!canDownloadReports() || isLoadingGpcBrick} onClick={downloadGcpBrickReport}>
                  <Bookmark color="inherit" />
                  &nbsp; Gpc Brick Cause Effect Report &nbsp;
                  {isLoadingGpcBrick && <CircularProgress />}
                </Button>
              </Grid>
              <Grid item>
                <Button className="text-center" size="large" variant="contained" color="primary" disabled={!canDownloadReports() || isLoadingBrand} onClick={downloadBrandReport}>
                  <Bookmark color="inherit" />
                  &nbsp; Brand Cause Effect Report &nbsp;
                  {isLoadingBrand && <CircularProgress />}
                </Button>
              </Grid>
              <Grid item>
                <Button className="text-center" size="large" variant="contained" color="primary" disabled={!canDownloadReports() || isLoadingProduct} onClick={downloadProductReport}>
                  <Bookmark color="inherit" />
                  &nbsp; Product Cause Effect Report &nbsp;
                  {isLoadingProduct && <CircularProgress />}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Card>
  );
};

export { CauseEffectReportSelector };
