import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { SeverityLevel } from '@microsoft/applicationinsights-common';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import * as React from 'react';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import { Route, Switch } from 'react-router';
import { applyMiddleware, compose as composeRedux, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import './App.css';
import configuration from './config';
import AuthLayout from './containers/AuthLayout/Layout';
import PublicLayout from './containers/PublicLayout/Layout';
import RootWrapper from './containers/_Shared/RootWrapper';
import { telemetryService } from './infrastructure/tracking/TelemetryService';
import trackComponent from './infrastructure/tracking/withTracking';
import AuthComponent from './modules/auth/components/AuthProvider';
import PublicAuthComponent from './modules/auth/components/PublicAuthProvider';
import { APP_HAS_CRASHED } from './modules/shared/constants';
import createRootReducer from './reducers';
import sagas from './sagas';
import { customTheme } from './scss/MaterialStyles';

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware({
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError: (error: Error, errorInfo: any) => {
    telemetryService.getInstance().trackException({
      exception: error,
      severityLevel: SeverityLevel.Critical,
      properties: {
        'Saga Error Info': errorInfo ? errorInfo.sagaStack : 'N/A',
      },
    });
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    configuredStore.dispatch({ type: APP_HAS_CRASHED });
  },
});

const compose: typeof composeWithDevTools = configuration.appStage === 'dev' ? composeWithDevTools : composeRedux;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
function configureStore(preloadedState?: any) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    compose(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        sagaMiddleware
        // ... other middlewares ...
      )
    )
  );
  return store;
}

export const configuredStore = configureStore();

sagaMiddleware.run(sagas);

telemetryService.initialize({
  history,
});

const PublicLayoutInstance = RootWrapper(PublicAuthComponent(PublicLayout));
const ProtectedLayoutInstance = RootWrapper(AuthComponent(AuthLayout));

class App extends React.Component {
  render(): JSX.Element {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Provider store={configuredStore}>
          <ConnectedRouter history={history}>
            <ThemeProvider theme={customTheme}>
              <Switch>
                <Route exact path="/planograms/:id/share/:sharingToken" render={(props): JSX.Element => <PublicLayoutInstance {...props} />} />
                <Route exact path="" render={(props): JSX.Element => <ProtectedLayoutInstance {...props} />} />
              </Switch>
            </ThemeProvider>
          </ConnectedRouter>
          <ReduxToastr timeOut={4000} newestOnTop={true} preventDuplicates position="top-right" transitionIn="fadeIn" transitionOut="fadeOut" progressBar={false} closeOnToastrClick />
        </Provider>
      </MuiPickersUtilsProvider>
    );
  }
}

export default trackComponent(App, 'App');
