import { createAction, PayloadAction } from 'typesafe-actions';
import * as constants from './constants';
import PlanogramView from './types/PlanogramView';
import ShelfViewModel from './types/ShelfViewModel';
import { PlanogramDetailsModel } from './types/PlanogramDetailsModel';
import UserGroupModel from './types/UserGroupModel';
import BuilderPlanogramView from './types/BuilderPlanogramView';
import ErrorDetails from '../../infrastructure/exception/ErrorDetails';
import { openAside, closeAside } from '../shared/actions';

export const loadPlanograms = () => ({
  type: constants.PLANOGRAMS_LOADING,
});

export const planogramsLoaded = (planograms: PlanogramView[]) => ({
  type: constants.PLANOGRAMS_LOADED,
  planograms,
});

export const errorLoadingPlanograms = (errorDetails: ErrorDetails) => ({
  type: constants.ERROR_LOADING_PLANOGRAMS,
  errorDetails,
});

export const showNewModal = () => ({
  type: constants.SHOW_NEW_PLANOGRAM_MODAL,
});

export const hideNewModal = () => ({
  type: constants.HIDE_NEW_PLANOGRAM_MODAL,
});

export const hideCreatePlanogramModal = () => ({
  type: constants.HIDE_CREATE_PLANOGRAM_MODAL,
});

export const loadShelves = () => ({
  type: constants.SHELVES_LOADING,
});

export const shelvesLoaded = (shelves: ShelfViewModel[]) => ({
  type: constants.SHELVES_LOADED,
  shelves,
});

export const errorLoadingShelves = (errorDetails: ErrorDetails) => ({
  type: constants.ERROR_LOADING_SHELVES,
  errorDetails,
});

export type LoadBrands = (shelfReferenceId: string) => PayloadAction<string, string>;
export const loadBrands: LoadBrands = createAction(constants.BRANDS_LOADING)<string>();

export const brandsLoaded = (brands: any) => ({
  type: constants.BRANDS_LOADED,
  brands,
});

export const errorLoadingBrands = (errorDetails: ErrorDetails) => ({
  type: constants.ERROR_LOADING_BRANDS,
  errorDetails,
});

export interface CreatePlanogramFromShelfPayload {
  shelfReferenceId: string;
  planogramDetails: PlanogramDetailsModel;
  userGroups: UserGroupModel[];
}
export type CreatePlanogramFromShelf = (params: CreatePlanogramFromShelfPayload) => PayloadAction<string, CreatePlanogramFromShelfPayload>;
export const createPlanogramFromShelf: CreatePlanogramFromShelf = createAction(constants.CREATE_PLANOGRAM_FROM_SHELF)();

export const errorCreatingPlanogramFromShelf = (errorDetails: ErrorDetails) => ({
  type: constants.ERROR_CREATING_PLANOGRAM_FROM_SHELF,
  errorDetails,
});

export const successCreatingPlanogramFromShelf = () => ({
  type: constants.SUCCESS_CREATING_PLANOGRAM_FROM_SHELF,
});

export const resetState = () => ({
  type: constants.RESET_STATE,
});

export const deletePlanogramRequest = (planogramId: string) => ({
  type: constants.DELETE_PLANOGRAM_REQUEST,
  planogramId,
});

export const deletePlanogramSuccess = (deletedPlanogramId: string) => ({
  type: constants.DELETE_PLANOGRAM_SUCCESS,
  deletedPlanogramId,
});

export const deletePlanogramFailed = () => ({
  type: constants.DELETE_PLANOGRAM_FAILED,
});

export const loadBuilderPlanogramsRequest = (planogram: PlanogramView) => ({
  type: constants.LOAD_BUILDER_PLANOGRAMS_REQUEST,
  planogram,
});

export const loadBuilderPlanogramsSuccess = (builderPlanograms: any) => ({
  type: constants.LOAD_BUILDER_PLANOGRAMS_SUCCESS,
  builderPlanograms,
});

export const loadBuilderPlanogramsFailed = () => ({
  type: constants.LOAD_BUILDER_PLANOGRAMS_FAILED,
});

export const updateBuilderRulesRequest = (selectedBuilderPlanogram: BuilderPlanogramView) => ({
  type: constants.UPDATE_BUILDER_RULES_REQUEST,
  selectedBuilderPlanogram,
});

export const updateBuilderRulesSuccess = (selectedBuilderPlanogram: BuilderPlanogramView) => ({
  type: constants.UPDATE_BUILDER_RULES_SUCCESS,
  selectedBuilderPlanogram,
});

export const updateBuilderRulesFailed = (selectedBuilderPlanogram: BuilderPlanogramView, errorDetails: ErrorDetails) => ({
  type: constants.UPDATE_BUILDER_RULES_FAILED,
  selectedBuilderPlanogram,
  errorDetails,
});

export const restLoadUpdatingBuilderRulesStatus = createAction(constants.RESET_LOAD_UPDATING_BUILDER_RULES_STATUS)();

export const setShowBuildersForPlanogram = createAction(constants.PLANOGRAMS_PAGE_SET_SHOW_BUILDERS_FOR)<string>();

export const setShowTestsForPlanogram = createAction(constants.PLANOGRAMS_PAGE_SET_SHOW_TESTS_FOR)<string>();

export const setShowDeleteForPlanogram = createAction(constants.PLANOGRAMS_PAGE_SET_SHOW_DELETE_FOR)<string>();

export const updateSelectedBuilderPlanogram = (selectedBuilderPlanogram: BuilderPlanogramView) => ({
  type: constants.UPDATE_SELECTED_BUILDER_PLANOGRAM,
  selectedBuilderPlanogram,
});

export { openAside, closeAside };
