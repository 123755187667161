import { Step } from 'react-joyride';

export function getProductTourSteps(): Step[] {
  const customTourStyle = {
    options: {
      arrowColor: '#212bc4',
      backgroundColor: '#212bc4',
      overlayColor: 'rgba(0, 0, 0, 0.8)',
      primaryColor: '#060606',
      textColor: '#ffffff',
      zIndex: 10000,
    },
  };

  return [
    {
      title: 'Planogram Goal',
      content: 'The primary objective to build toward is listed here. Good luck and happy building!',
      target: '[id="planogramGoaltxt"]',
      placement: 'top',
      styles: customTourStyle,
      disableBeacon: true,
    },
    {
      title: 'Start building your planogram!',
      content:
        'You can move products using the mouse. You can increase/decrease facings by pressing keys [+] or [-] on your keyboard. You can delist products by pressing [DEL] key. You can copy products by pressing [CTRL+C] and paste by clicking. You can undo changes by pressing [CTRL+Z] on your keyboard.',
      target: '[id="planogramSvgImg"]',
      styles: customTourStyle,
      disableBeacon: true,
    },
    {
      title: 'Quick Compare',
      content: 'Click here to toggle between current and initial shelf.',
      target: '[id="togglePlanogramStateSwitch"]',
      placement: 'bottom',
      styles: customTourStyle,
      disableBeacon: true,
    },
    {
      title: 'Share Planogram',
      content: 'Click here to generate a shared public link of your planogram (readonly).',
      target: '[id="sharePlanogramBtn"]',
      placement: 'top',
      styles: customTourStyle,
      disableBeacon: true,
    },
    {
      title: 'Want to test your new shelf at a real store?',
      content: 'Click here to initiate a shelf test with FLOW Insights.',
      target: '[id="testWithFlowBtn"]',
      placement: 'top',
      styles: customTourStyle,
      disableBeacon: true,
    },
    {
      title: 'Side Menu',
      content: 'Click here to toggle the side menu.',
      target: '[id="asideToggleBtn"]',
      styles: customTourStyle,
      disableBeacon: true,
    },
    {
      title: 'Data Controls',
      content: 'Select the data you would like to see on each product.',
      target: '[id="settingsAndDataCtrlsNavLnk"]',
      placement: 'left',
      styles: customTourStyle,
      disableBeacon: true,
    },
    {
      title: 'Comment, Reset or Add new products.',
      content: 'Insert your comments, Reset the planogram to original state or add new products to the planogram.',
      target: '[id="planogramInfoAndActionsNavLnk"]',
      placement: 'left',
      styles: customTourStyle,
      disableBeacon: true,
    },
    {
      title: 'Custom Data',
      content: 'Generate and upload sales reports or custom datasets here (excel sheets).',
      target: '[id="manageSalesReportsNavLnk"]',
      placement: 'left',
      styles: customTourStyle,
      disableBeacon: true,
    },
    {
      title: 'FLOW Shelf Tests',
      content: 'Shelf tests initiated with FLOW Insights belong here. You can edit or track their progress here.',
      target: '[id="shelfTestsNavLnk"]',
      placement: 'left',
      styles: customTourStyle,
      disableBeacon: true,
    },
    {
      title: 'Shelf Changes and Reasons',
      content: 'All changes made to the shelf are broken down to an easy to understand granular level. You can state your reasons on each change.',
      target: '[id="previewChangesNavLnk"]',
      placement: 'left',
      styles: customTourStyle,
      disableBeacon: true,
    },

    {
      title: 'Replay Tour',
      content: 'Feel free to replay this instructional guide anytime by clicking this icon.',
      target: '[id="playInstructionalGuideBtn"]',
      placement: 'bottom',
      styles: customTourStyle,
      disableBeacon: true,
    },
  ];
}
