import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableHead, Typography } from '@material-ui/core';
import { Close, InfoOutlined } from '@material-ui/icons';
import React from 'react';
import Alert from 'reactstrap/lib/Alert';
import trackComponent from 'infrastructure/tracking/withTracking';
import { StyledTableCell, StyledTableRow } from 'scss/MaterialStyles';
import { ClientDatasetDto } from '../../../admin/clientDataset/types/ClientDatasetDto';

function renderClientDatasetRows(dataset: ClientDatasetDto): JSX.Element | JSX.Element[] {
  if (dataset.dataItems.length === 0) {
    return (
      <Alert className="top-buffer" color="info">
        <Typography variant="caption" color="textSecondary">
          <InfoOutlined color="primary" fontSize="small" />
          &nbsp; Data does not contain products in this shelf.
        </Typography>
      </Alert>
    );
  }
  return dataset.dataItems.map(row => {
    return (
      <StyledTableRow key={row.gtin}>
        <StyledTableCell>{row.gtin}</StyledTableCell>
        <StyledTableCell>{row.value}</StyledTableCell>
      </StyledTableRow>
    );
  });
}

const ClientDatasetModal = (props: ClientDatasetProps): JSX.Element => (
  <Dialog open={true} onClose={props.onClose}>
    <DialogTitle>{props.clientDataset.name}</DialogTitle>
    <DialogContent>
      <Table size="small">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>GTIN</StyledTableCell>
            <StyledTableCell>Value</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>{renderClientDatasetRows(props.clientDataset)}</TableBody>
      </Table>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.onClose} color="secondary" variant="contained">
        <Close />
        &nbsp;Close
      </Button>
    </DialogActions>
  </Dialog>
);

interface ClientDatasetProps {
  clientDataset: ClientDatasetDto;
  onClose(): void;
}

export default trackComponent(ClientDatasetModal, 'PlanogramPage_ClientDatasetModal');
