import React from 'react';
import Select from 'react-select';
import { WeekOption } from './state/types';

export interface WeekSelectorProps {
  htmlId: string;
  weekOptions?: WeekOption[];
  selectedWeek?: WeekOption;
  handleOnSelectWeek(selectedWeek: WeekOption): void;
}
export const WeekSelector: React.FC<WeekSelectorProps> = ({ htmlId, weekOptions, selectedWeek, handleOnSelectWeek }: WeekSelectorProps) => {
  const defaultWeekOption: WeekOption = {
    label: 'Select Week',
    value: { startDate: '', endDate: '' },
  };
  return (
    <Select
      inputId={htmlId}
      placeholder="Select Week"
      options={weekOptions}
      value={selectedWeek === undefined ? defaultWeekOption : selectedWeek}
      onChange={(week: WeekOption): void => handleOnSelectWeek(week)}
      isMulti={false}
      isDisabled={!weekOptions || weekOptions.length === 0}
    />
  );
};
