import { ClickAwayListener, Grid, IconButton, Paper, Popper } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { AlertErrorTextIndicator } from 'modules/shared/components/LoadStatusIndicators/AlertErrorTextIndicator';
import { AlertWarningIndicator } from 'modules/shared/components/LoadStatusIndicators/AlertWarningIndicator';
import { CircularLoadIndicator } from 'modules/shared/components/LoadStatusIndicators/CircularLoadIndicator';
import * as React from 'react';
import { ConversationMessage } from '../../types';
import InputArea from './InputArea';
import MessageList from './MessageList';

interface ConversationPopperProps {
  isPopperOpen: boolean;
  isConversationLoading: boolean;
  isConversationLoaded: boolean;
  isConversationLoadError: boolean;
  conversationLoadErrorMessage: string;
  isSubmittingMessage: boolean;
  isEditingMessage: boolean;
  isDeletingMessage: boolean;
  conversationAnchorEl?: Element;
  conversationMessages: ConversationMessage[];
  handleOnSubmitMessage(message: string): void;
  handleOnSubmitEdit(messageId: string, userId: number, editedMessage: string): void;
  handleOnSubmitDelete(messageId: string): void;
  handleOnClosePopper(): void;
}
export default class ConversationPopper extends React.Component<ConversationPopperProps> {
  public render(): JSX.Element {
    const isLoading = this.props.isConversationLoading;
    const isLoaded = this.props.isConversationLoaded;
    const isError = this.props.isConversationLoadError;
    const errorMessage = this.props.conversationLoadErrorMessage;
    const isEmpty = isLoaded && !isError && this.props.conversationMessages.length === 0;
    const showMessages = isLoaded && !isError && this.props.conversationMessages.length > 0;
    const showInput = isLoaded && !isError;
    return (
      <>
        <ClickAwayListener
          mouseEvent="onMouseDown"
          onClickAway={(): void => {
            this.props.handleOnClosePopper();
          }}
        >
          <Popper id="realogram-conversation-popper" open={this.props.isPopperOpen} anchorEl={this.props.conversationAnchorEl} style={{ zIndex: 1200 }}>
            <Grid container justify="center">
              <Grid item style={{ maxWidth: 600, minWidth: 600 }}>
                <Grid container>
                  <Grid item xs={12}>
                    <div style={{ textAlign: 'right' }}>
                      <IconButton
                        size="medium"
                        color="secondary"
                        onClick={(): void => {
                          this.props.handleOnClosePopper();
                        }}
                      >
                        <Close fontSize="large" />
                      </IconButton>
                    </div>
                    <Paper elevation={2} style={{ maxHeight: 700, padding: 20, maxWidth: 600, minWidth: 600 }}>
                      {showMessages && (
                        <MessageList
                          messages={this.props.conversationMessages}
                          isEditing={this.props.isEditingMessage}
                          isDeleting={this.props.isDeletingMessage}
                          onSubmitEdit={(messageId: string, userId: number, editedMessage: string): void => {
                            this.props.handleOnSubmitEdit(messageId, userId, editedMessage);
                          }}
                          onSubmitDelete={(messageId: string): void => this.props.handleOnSubmitDelete(messageId)}
                        />
                      )}
                      {isLoading && <CircularLoadIndicator loadingText="loading conversation..." />}
                      {isEmpty && <AlertWarningIndicator warningText="No Messages, yet." />}
                      {isError && <AlertErrorTextIndicator errorText={'Error loading conversation: ' + errorMessage} />}
                      {showInput && <InputArea isSubmitting={this.props.isSubmittingMessage} onSubmitMessage={(message: string): void => this.props.handleOnSubmitMessage(message)} />}
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Popper>
        </ClickAwayListener>
      </>
    );
  }
}
