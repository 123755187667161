import { Type } from 'class-transformer';

export default class UserPlanogramForAdminViewModel {
  builderId = 0;
  ownerId = 0;
  planogramId = '';
  shelfReferenceId = '';
  planogramName = '';
  projectName = 0;
  storeName = '';
  phaseNum = 0;
  shelfNum = 0;

  @Type(() => Date)
  createdOn: Date = new Date();

  @Type(() => Date)
  lastActiveOn?: Date;

  @Type(() => Date)
  lastOpenedOn?: Date;
}
