export const PLANOGRAMS_LOADING = 'PLANOGRAMS_LOADING';
export type PLANOGRAMS_LOADING = typeof PLANOGRAMS_LOADING;

export const PLANOGRAMS_LOADED = 'PLANOGRAMS_LOADED';
export type PLANOGRAMS_LOADED = typeof PLANOGRAMS_LOADED;

export const ERROR_LOADING_PLANOGRAMS = 'ERROR_LOADING_PLANOGRAMS';
export type ERROR_LOADING_PLANOGRAMS = typeof ERROR_LOADING_PLANOGRAMS;

export const SHOW_NEW_PLANOGRAM_MODAL = 'SHOW_NEW_PLANOGRAM_MODAL';
export type SHOW_NEW_PLANOGRAM_MODAL = typeof SHOW_NEW_PLANOGRAM_MODAL;

export const HIDE_NEW_PLANOGRAM_MODAL = 'HIDE_NEW_PLANOGRAM_MODAL';
export type HIDE_NEW_PLANOGRAM_MODAL = typeof HIDE_NEW_PLANOGRAM_MODAL;

export const HIDE_CREATE_PLANOGRAM_MODAL = 'HIDE_CREATE_PLANOGRAM_MODAL';
export type HIDE_CREATE_PLANOGRAM_MODAL = typeof HIDE_CREATE_PLANOGRAM_MODAL;

export const SHELVES_LOADING = 'SHELVES_LOADING';
export type SHELVES_LOADING = typeof SHELVES_LOADING;

export const SHELVES_LOADED = 'SHELVES_LOADED';
export type SHELVES_LOADED = typeof SHELVES_LOADED;

export const ERROR_LOADING_SHELVES = 'ERROR_LOADING_SHELVES';
export type ERROR_LOADING_SHELVES = typeof ERROR_LOADING_SHELVES;

export const BRANDS_LOADING = 'BRANDS_LOADING';
export type BRANDS_LOADING = typeof BRANDS_LOADING;

export const BRANDS_LOADED = 'BRANDS_LOADED';
export type BRANDS_LOADED = typeof BRANDS_LOADED;

export const ERROR_LOADING_BRANDS = 'ERROR_LOADING_BRANDS';
export type ERROR_LOADING_BRANDS = typeof ERROR_LOADING_BRANDS;

export const CREATE_PLANOGRAM_FROM_SHELF = 'CREATE_PLANOGRAM_FROM_SHELF';
export type CREATE_PLANOGRAM_FROM_SHELF = typeof CREATE_PLANOGRAM_FROM_SHELF;

export const ERROR_CREATING_PLANOGRAM_FROM_SHELF = 'ERROR_CREATING_PLANOGRAM_FROM_SHELF';
export type ERROR_CREATING_PLANOGRAM_FROM_SHELF = typeof ERROR_CREATING_PLANOGRAM_FROM_SHELF;

export const SUCCESS_CREATING_PLANOGRAM_FROM_SHELF = 'SUCCESS_CREATING_PLANOGRAM_FROM_SHELF';
export type SUCCESS_CREATING_PLANOGRAM_FROM_SHELF = typeof SUCCESS_CREATING_PLANOGRAM_FROM_SHELF;

export const RESET_STATE = 'RESET_STATE';
export type RESET_STATE = typeof RESET_STATE;

export const DELETE_PLANOGRAM_REQUEST = 'DELETE_PLANOGRAM_FROM_PLANOGRAMS_PAGE_REQUEST';
export type DELETE_PLANOGRAM_REQUEST = typeof DELETE_PLANOGRAM_REQUEST;

export const DELETE_PLANOGRAM_SUCCESS = 'DELETE_PLANOGRAM_FROM_PLANOGRAMS_PAGE_SUCCESS';
export type DELETE_PLANOGRAM_SUCCESS = typeof DELETE_PLANOGRAM_SUCCESS;

export const DELETE_PLANOGRAM_FAILED = 'DELETE_PLANOGRAM_FROM_PLANOGRAMS_PAGE_FAILED';
export type DELETE_PLANOGRAM_FAILED = typeof DELETE_PLANOGRAM_FAILED;

export const LOAD_BUILDER_PLANOGRAMS_REQUEST = 'LOAD_BUILDER_PLANOGRAMS_REQUEST';
export type LOAD_BUILDER_PLANOGRAMS_REQUEST = typeof LOAD_BUILDER_PLANOGRAMS_REQUEST;

export const LOAD_BUILDER_PLANOGRAMS_SUCCESS = 'LOAD_BUILDER_PLANOGRAMS_SUCCESS';
export type LOAD_BUILDER_PLANOGRAMS_SUCCESS = typeof LOAD_BUILDER_PLANOGRAMS_SUCCESS;

export const LOAD_BUILDER_PLANOGRAMS_FAILED = 'LOAD_BUILDER_PLANOGRAMS_FAILED';
export type LOAD_BUILDER_PLANOGRAMS_FAILED = typeof LOAD_BUILDER_PLANOGRAMS_FAILED;

export const UPDATE_BUILDER_RULES_REQUEST = 'UPDATE_BUILDER_RULES_REQUEST';
export type UPDATE_BUILDER_RULES_REQUEST = typeof UPDATE_BUILDER_RULES_REQUEST;

export const UPDATE_BUILDER_RULES_SUCCESS = 'UPDATE_BUILDER_RULES_SUCCESS';
export type UPDATE_BUILDER_RULES_SUCCESS = typeof UPDATE_BUILDER_RULES_SUCCESS;

export const UPDATE_BUILDER_RULES_FAILED = 'UPDATE_BUILDER_RULES_FAILED';
export type UPDATE_BUILDER_RULES_FAILED = typeof UPDATE_BUILDER_RULES_FAILED;

export const RESET_LOAD_UPDATING_BUILDER_RULES_STATUS = 'planograms/RESET_LOAD_UPDATING_BUILDER_RULES_STATUS';

export const UPDATE_SELECTED_BUILDER_PLANOGRAM = 'UPDATE_SELECTED_BUILDER_PLANOGRAM';
export type UPDATE_SELECTED_BUILDER_PLANOGRAM = typeof UPDATE_SELECTED_BUILDER_PLANOGRAM;

export const PLANOGRAMS_PAGE_SET_SHOW_BUILDERS_FOR = 'planograms/SET_SHOW_BUILDERS_FOR';
export type PLANOGRAMS_PAGE_SET_SHOW_BUILDERS_FOR = typeof PLANOGRAMS_PAGE_SET_SHOW_BUILDERS_FOR;

export const PLANOGRAMS_PAGE_SET_SHOW_TESTS_FOR = 'planograms/SET_SHOW_TESTS_FOR';
export type PLANOGRAMS_PAGE_SET_SHOW_TESTS_FOR = typeof PLANOGRAMS_PAGE_SET_SHOW_TESTS_FOR;

export const PLANOGRAMS_PAGE_SET_SHOW_DELETE_FOR = 'planograms/SET_SHOW_DELETE_FOR';
export type PLANOGRAMS_PAGE_SET_SHOW_DELETE_FOR = typeof PLANOGRAMS_PAGE_SET_SHOW_DELETE_FOR;
