import { OidcUser } from './OidcUser';

export default class ApplicationUser {
  id = '';
  name = '';
  roles: string[] = [];

  constructor(oidcUser: OidcUser) {
    this.id = oidcUser.profile.sub as string;
    this.name = oidcUser.profile.name as string;
    this.roles = oidcUser.profile.role as string[];
  }

  isAdmin(): boolean {
    if (this.roles instanceof Array) {
      // TODO: investigate why roles is a string if one value, and string[] when many values
      return this.roles.some((role: string) => role === 'Admin');
    } else {
      return this.roles === 'Admin';
    }
  }

  isPlanogramOwner(): boolean {
    if (this.roles instanceof Array) {
      // TODO: investigate why roles is a string if one value, and string[] when many values
      return this.roles.some((role: string) => role === 'PlanogramOwner');
    } else {
      return this.roles === 'PlanogramOwner';
    }
  }

  isPartner(): boolean {
    if (this.roles instanceof Array) {
      // TODO: investigate why roles is a string if one value, and string[] when many values
      return this.roles.some((role: string) => role === 'Partner');
    } else {
      return this.roles === 'Partner';
    }
  }
}
