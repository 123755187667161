import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { toMaxPrecision } from 'utils/Helpers';
import flowLogo from '../../../../../assets/img/brand/flow.jpg';
import { ShelfComparisonReportResult } from '../../types';
import './StandardShelfComparisonReportPage.css';
import '../styles/StandardReportStyle.css';
interface StandardShelfComparisonReportPageProps {
  shelfComparisonReport: ShelfComparisonReportResult;
}
const StandardShelfComparisonReportPage: React.FC<StandardShelfComparisonReportPageProps> = ({ shelfComparisonReport }: StandardShelfComparisonReportPageProps) => {
  const shelfChanges = shelfComparisonReport.shelfChanges;
  const brandChanges = _.orderBy(shelfComparisonReport.brandChanges, ['productsIn', 'productsOut', 'productsRelocated', 'spaceB', 'spaceA'], 'desc');
  const productChanges = shelfComparisonReport.productChanges;
  return (
    <div className="page">
      <div id="flowLogo">
        <img alt="flow-logo" className="img-responsive" src={flowLogo} />
      </div>
      <div style={{ width: '100%' }}>
        <h3 className="text-center">
          Shelf Comparison Report
          <br />
        </h3>
        <h5 className="text-center">
          <p style={{ margin: '0px' }}>
            <b>Shelf A: </b> {shelfChanges.projectNameA}, {shelfChanges.storeNameA}, Phase {shelfChanges.phaseA}, Shelf {shelfChanges.shelfNumA} (
            <i>
              {moment(shelfChanges.dateFromA).format('DD/MM/YYYY')} - {moment(shelfChanges.dateToA).format('DD/MM/YYYY')}
            </i>
            )
          </p>
          <p style={{ margin: '0px' }}>
            <b>Shelf B: </b> {shelfChanges.projectNameB}, {shelfChanges.storeNameB}, Phase {shelfChanges.phaseB}, Shelf {shelfChanges.shelfNumB} (
            <i>
              {moment(shelfChanges.dateFromB).format('DD/MM/YYYY')} - {moment(shelfChanges.dateToB).format('DD/MM/YYYY')}
            </i>
            )
          </p>
          <br />
        </h5>
        <h5 className="text-left">
          <p style={{ margin: '0px' }}>
            <b>Shelf A Visitors:</b> {shelfChanges.visitorsCountA}
          </p>
          <p style={{ margin: '0px' }}>
            <b>Shelf B Visitors:</b> {shelfChanges.visitorsCountB}
          </p>
          <br />
          <p style={{ margin: '0px' }}>
            <b>Shelf A Products: </b> {shelfChanges.gtinCountA}
          </p>
          <p style={{ margin: '0px' }}>
            <b>Shelf B Products: </b> {shelfChanges.gtinCountB}
          </p>
        </h5>
        <div className="row">
          <table style={{ marginTop: '10px' }} className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-center metricNameCell">Shelf parameters</td>
                <td className="text-center metricNameCell">Width</td>
                <td className="text-center metricNameCell">Height</td>
                <td className="text-center metricNameCell">Depth</td>
                <td className="text-center metricNameCell">Shelf locations</td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">Shelf A</td>
                <td className="text-center">{shelfChanges.widthA}</td>
                <td className="text-center">{shelfChanges.heightA}</td>
                <td className="text-center">{shelfChanges.depthA}</td>
                <td className="text-center">{shelfChanges.shelfLocationsA}</td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">Shelf B</td>
                <td className="text-center">{shelfChanges.widthB}</td>
                <td className="text-center">{shelfChanges.heightB}</td>
                <td className="text-center">{shelfChanges.depthB}</td>
                <td className="text-center">{shelfChanges.shelfLocationsB}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row">
          <table style={{ marginTop: '10px' }} className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-center metricNameCell">Phase parameters</td>
                <td className="text-center metricNameCell">Opening days</td>
                <td className="text-center metricNameCell">Opening hours</td>
                <td className="text-center metricNameCell">Daily opening hours</td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">Shelf A</td>
                <td className="text-center">{shelfChanges.openingDaysA}</td>
                <td className="text-center">{shelfChanges.openingHoursA}</td>
                <td className="text-center">{toMaxPrecision(shelfChanges.dailyOpeningHoursA, 2)}</td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">Shelf B</td>
                <td className="text-center">{shelfChanges.openingDaysB}</td>
                <td className="text-center">{shelfChanges.openingHoursB}</td>
                <td className="text-center">{toMaxPrecision(shelfChanges.dailyOpeningHoursB, 2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row">
          <table style={{ marginTop: '10px' }} className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-center metricNameCell">Assortment</td>
                <td className="text-center metricNameCell">Total</td>
                <td className="text-center metricNameCell">In</td>
                <td className="text-center metricNameCell">Out</td>
                <td className="text-center metricNameCell">Relocated</td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">Products</td>
                <td className="text-center">
                  {shelfChanges.gtinCountA}/{shelfChanges.gtinCountB}
                </td>
                <td className="text-center">{shelfChanges.newGTINCount}</td>
                <td className="text-center">{shelfChanges.removedGTINCount}</td>
                <td className="text-center">{shelfChanges.relocatedGTINCount}</td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">Brands</td>
                <td className="text-center">
                  {shelfChanges.brandCountA}/{shelfChanges.brandCountB}
                </td>
                <td className="text-center">{shelfChanges.newBrandCount}</td>
                <td className="text-center">{shelfChanges.removedBrandCount}</td>
                <td className="text-center">{shelfChanges.relocatedBrandCount}</td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">Categories</td>
                <td className="text-center">
                  {shelfChanges.gpcBrickCountA}/{shelfChanges.gpcBrickCountB}
                </td>
                <td className="text-center">{shelfChanges.newGpcBrickCount}</td>
                <td className="text-center">{shelfChanges.removedGpcBrickCount}</td>
                <td className="text-center">{shelfChanges.relocatedGpcBrickCount}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row">
          <table style={{ marginTop: '10px' }} className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-center metricNameCell">Pricing</td>
                <td className="text-center metricNameCell">Average</td>
                <td className="text-center metricNameCell">Min</td>
                <td className="text-center metricNameCell">Max</td>
                <td className="text-center metricNameCell">Price points</td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">Shelf A</td>
                <td className="text-center">{toMaxPrecision(shelfChanges.averagePriceA, 2)}</td>
                <td className="text-center">{toMaxPrecision(shelfChanges.minPriceA, 2)}</td>
                <td className="text-center">{toMaxPrecision(shelfChanges.maxPriceA, 2)}</td>
                <td className="text-center">{toMaxPrecision(shelfChanges.pricePointsA, 2)}</td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">Shelf B</td>
                <td className="text-center">{toMaxPrecision(shelfChanges.averagePriceB, 2)}</td>
                <td className="text-center">{toMaxPrecision(shelfChanges.minPriceB, 2)}</td>
                <td className="text-center">{toMaxPrecision(shelfChanges.maxPriceB, 2)}</td>
                <td className="text-center">{toMaxPrecision(shelfChanges.pricePointsB, 2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row">
          <table style={{ marginTop: '10px' }} className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-center metricNameCell">Brand</td>
                <td className="text-center metricNameCell" colSpan={2}>
                  Products
                </td>
                <td className="text-center metricNameCell" rowSpan={2}>
                  Price
                </td>
                <td className="text-center metricNameCell" rowSpan={2}>
                  Space
                </td>
                <td className="text-center metricNameCell" colSpan={4}>
                  Locations
                </td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">Name</td>
                <td className="text-center metricNameCell">Number</td>
                <td className="text-center metricNameCell">Out/In/Relocated</td>
                <td className="text-center metricNameCell">Number</td>
                <td className="text-center metricNameCell">Min height</td>
                <td className="text-center metricNameCell">Avg height</td>
                <td className="text-center metricNameCell">Max height</td>
              </tr>
              {brandChanges.map((brand, index) => {
                return (
                  <tr key={index}>
                    <td className="text-center">{brand.brandName}</td>
                    <td className="text-center">
                      {brand.productsA}/{brand.productsB}
                    </td>
                    <td className="text-center">
                      {brand.productsOut}/{brand.productsIn}/{brand.productsRelocated}
                    </td>
                    <td className="text-center">
                      {toMaxPrecision(brand.priceA, 1)}/{toMaxPrecision(brand.priceB, 1)}
                    </td>
                    <td className="text-center">
                      {toMaxPrecision(brand.spaceA, 1)}/{toMaxPrecision(brand.spaceB, 1)}
                    </td>
                    <td className="text-center">
                      {brand.locationsA}/{brand.locationsB}
                    </td>
                    <td className="text-center">
                      {toMaxPrecision(brand.locationMinA, 1)}/{toMaxPrecision(brand.locationMinB, 1)}
                    </td>
                    <td className="text-center">
                      {toMaxPrecision(brand.locationHeightA, 1)}/{toMaxPrecision(brand.locationHeightB, 1)}
                    </td>
                    <td className="text-center">
                      {toMaxPrecision(brand.locationMaxA, 1)}/{toMaxPrecision(brand.locationMaxB, 1)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="row">
          <table style={{ marginTop: '10px' }} className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-center metricNameCell" colSpan={3}>
                  Product
                </td>
                <td className="text-center metricNameCell" rowSpan={2}>
                  Out/In/Relocated
                </td>
                <td className="text-center metricNameCell" rowSpan={2}>
                  Price
                </td>
                <td className="text-center metricNameCell" rowSpan={2}>
                  Space
                </td>
                <td className="text-center metricNameCell" colSpan={4}>
                  Locations
                </td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">GTIN</td>
                <td className="text-center metricNameCell">Brand</td>
                <td className="text-center metricNameCell">Name</td>
                <td className="text-center metricNameCell">Number</td>
                <td className="text-center metricNameCell">Min height</td>
                <td className="text-center metricNameCell">Avg height</td>
                <td className="text-center metricNameCell">Max height</td>
              </tr>
              {productChanges.map((product, index) => {
                return (
                  <tr key={index}>
                    <td className="text-center">{product.gtin}</td>
                    <td className="text-center">{product.brandName}</td>
                    <td className="text-center">{product.tradeItemDescription}</td>
                    <td className="text-center">{product.outInRelocated}</td>
                    <td className="text-center">
                      {toMaxPrecision(product.priceA, 1)}/{toMaxPrecision(product.priceB, 1)}
                    </td>
                    <td className="text-center">
                      {toMaxPrecision(product.spaceA, 1)}/{toMaxPrecision(product.spaceB, 1)}
                    </td>
                    <td className="text-center">
                      {product.locationsA}/{product.locationsB}
                    </td>
                    <td className="text-center">
                      {toMaxPrecision(product.locationMinA, 1)}/{toMaxPrecision(product.locationMinB, 1)}
                    </td>
                    <td className="text-center">
                      {toMaxPrecision(product.locationHeightA, 1)}/{toMaxPrecision(product.locationHeightB, 1)}
                    </td>
                    <td className="text-center">
                      {toMaxPrecision(product.locationMaxA, 1)}/{toMaxPrecision(product.locationMaxB, 1)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <footer>
        <strong>Copyright &copy; {new Date().getFullYear()} Flow Insights</strong>. Strictly confidential. No distribution allowed. All rights reserved.
      </footer>
    </div>
  );
};

export { StandardShelfComparisonReportPage };
