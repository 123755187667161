import React from 'react';

import { getShouldDelistGtinArr } from '../../../../../../selectors';
import useHighLightedGtin from '../useHighLightedGtin';
import SelectBtn from '../SelectBtn';

const DelistBtn: React.FC = (): JSX.Element => {
  const props = useHighLightedGtin(getShouldDelistGtinArr, 'DelistBtn');

  return <SelectBtn {...props} />;
};

export default DelistBtn;
