import ErrorDetails from 'infrastructure/exception/ErrorDetails';
import { setError, setIdle, setLoading, setSuccess, setData } from 'infrastructure/utils/RemoteObjectStatus';
import { FlowDataReportType } from 'modules/planogram/types/ReportingTypes';
import { LoadState } from 'modules/shared/types';
import { ActionType, getType, Reducer } from 'typesafe-actions';
import * as actions from './actions';
import {
  CLOSE_REALOGRAM_CONVERSATION_POPPER,
  DELETE_MESSAGE_IN_CONVERSATION_FAILURE,
  DELETE_MESSAGE_IN_CONVERSATION_REQUEST,
  DELETE_MESSAGE_IN_CONVERSATION_SUCCESS,
  OPEN_REALOGRAM_CONVERSATION_POPPER,
  POST_MESSAGE_TO_CONVERSATION_FAILURE,
  POST_MESSAGE_TO_CONVERSATION_REQUEST,
  POST_MESSAGE_TO_CONVERSATION_SUCCESS,
  REALOGRAM_CONVERSATION_LOAD_FAILURE,
  REALOGRAM_CONVERSATION_LOAD_REQUEST,
  REALOGRAM_CONVERSATION_LOAD_SUCCESS,
  UPDATE_MESSAGE_IN_CONVERSATION_FAILURE,
  UPDATE_MESSAGE_IN_CONVERSATION_REQUEST,
  UPDATE_MESSAGE_IN_CONVERSATION_SUCCESS,
} from './actionTypes';
import RealogramPageState from './state';
import { RealogramConversation } from './types';

type Actions = ActionType<typeof actions>;

export const INITIAL_STATE: RealogramPageState = {
  realogramLoadStatus: setIdle(),
  flowDataLoadStatus: setIdle(),
  selectedFlowDataType: FlowDataReportType.ProductFirstPickupsCount,
  realogramConversationsLoadStatus: setIdle(),
  postMessageToConversationLoadStatus: setIdle(),
  updateMessageInConversationLoadStatus: setIdle(),
  deleteMessageInConversationLoadStatus: setIdle(),
  isOpenConversationPopper: false,
  conversationPopperAnchorEl: undefined,
};

function loadRealogramConversationRequestHandler(state: RealogramPageState): RealogramPageState {
  return { ...state, realogramConversationsLoadStatus: setLoading() };
}

function loadRealogramConversationSuccessHandler(state: RealogramPageState, realogramConversation: RealogramConversation): RealogramPageState {
  const data = state.realogramConversationsLoadStatus.data ?? [];
  data.push(realogramConversation);
  return { ...state, realogramConversationsLoadStatus: setSuccess(data) };
}

function loadRealogramConversationFailureHandler(state: RealogramPageState, errorDetails: ErrorDetails): RealogramPageState {
  return { ...state, realogramConversationsLoadStatus: setError(errorDetails) };
}

function postMessageToConversationRequestLoadHandler(state: RealogramPageState): RealogramPageState {
  return { ...state, postMessageToConversationLoadStatus: setLoading() };
}

function postMessageToConversationSuccessLoadHandler(
  state: RealogramPageState,
  shelfReferenceId: string,
  message: string,
  messageId: string,
  userId: number,
  username: string,
  timeOfMessage: Date
): RealogramPageState {
  const postMessageToConversationLoadStatus = Object.assign({}, state.postMessageToConversationLoadStatus);
  postMessageToConversationLoadStatus.loadStatus = LoadState.LOADED;
  postMessageToConversationLoadStatus.data = { shelfReferenceId, message, messageId, userId, username, timeOfMessage };
  const realogramConversationsLoadStatus = Object.assign({}, state.realogramConversationsLoadStatus);
  const conversationData = realogramConversationsLoadStatus.data?.find(x => x.shelfReferenceId === shelfReferenceId);
  if (!conversationData) {
    realogramConversationsLoadStatus.data = [
      {
        shelfReferenceId: shelfReferenceId,
        conversationMessages: [postMessageToConversationLoadStatus.data],
      },
    ] as RealogramConversation[];
  } else {
    conversationData.conversationMessages.push(postMessageToConversationLoadStatus.data);
  }
  return { ...state, postMessageToConversationLoadStatus, realogramConversationsLoadStatus };
}

function postMessageToConversationFailureLoadHandler(state: RealogramPageState, errorDetails: ErrorDetails): RealogramPageState {
  return { ...state, postMessageToConversationLoadStatus: setError(errorDetails) };
}

function updateMessageInConversationSuccessHandler(state: RealogramPageState, shelfReferenceId: string, messageId: string, editedMessage: string): RealogramPageState {
  const conversations = state.realogramConversationsLoadStatus.data;
  conversations?.forEach(conversation => {
    if (conversation && conversation.conversationMessages && conversation.shelfReferenceId === shelfReferenceId) {
      conversation.conversationMessages.forEach(message => {
        if (message.messageId === messageId) {
          message.message = editedMessage;
          // TODO: Can a message be in multiple conversations? Since this will loop through all messages in all conversations to update one message?!
        }
      });
    }
  });
  return {
    ...state,
    updateMessageInConversationLoadStatus: setSuccess({}),
    realogramConversationsLoadStatus: setData(state.realogramConversationsLoadStatus, conversations ?? []),
  };
}

function deleteMessageInConversationSuccessHandler(state: RealogramPageState, shelfReferenceId: string, messageId: string): RealogramPageState {
  const conversations = state.realogramConversationsLoadStatus.data;
  conversations?.forEach(conversation => {
    if (conversation && conversation.conversationMessages && conversation.shelfReferenceId === shelfReferenceId) {
      conversation.conversationMessages = conversation.conversationMessages.filter(x => x.messageId !== messageId);
    }
  });
  return {
    ...state,
    deleteMessageInConversationLoadStatus: setSuccess({}),
    realogramConversationsLoadStatus: setData(state.realogramConversationsLoadStatus, conversations ?? []),
  };
}

const reducer: Reducer<RealogramPageState, Actions> = (state: RealogramPageState = INITIAL_STATE, action: Actions) => {
  switch (action.type) {
    case getType(actions.fetchRequestAction):
      return {
        ...state,
        realogramLoadStatus: setLoading(),
      };

    case getType(actions.fetchSuccessAction):
      return {
        ...state,
        realogramLoadStatus: setSuccess(action.payload),
      };
    case getType(actions.fetchFailedAction):
      return {
        ...state,
        realogramLoadStatus: setError(action.payload),
      };
    case getType(actions.fetchFlowDataRequestAction):
      return {
        ...state,
        flowDataLoadStatus: setLoading(),
      };
    case getType(actions.fetchFlowDataSuccessAction):
      return {
        ...state,
        flowDataLoadStatus: setSuccess(action.payload),
      };
    case getType(actions.fetchFlowDataFailedAction):
      return {
        ...state,
        flowDataLoadStatus: setError(action.payload),
      };
    case getType(actions.selectFlowReportTypeAction):
      return {
        ...state,
        selectedFlowDataType: action.payload,
      };
    case getType(actions.selectShelfLocationAction):
      return {
        ...state,
        selectedShelfLocation: action.payload,
      };
    case REALOGRAM_CONVERSATION_LOAD_REQUEST: {
      return loadRealogramConversationRequestHandler(state);
    }
    case REALOGRAM_CONVERSATION_LOAD_SUCCESS: {
      return loadRealogramConversationSuccessHandler(state, action.payload.realogramConversation);
    }
    case REALOGRAM_CONVERSATION_LOAD_FAILURE: {
      return loadRealogramConversationFailureHandler(state, action.payload.errorDetails);
    }
    case OPEN_REALOGRAM_CONVERSATION_POPPER:
      return { ...state, isOpenConversationPopper: true, conversationPopperAnchorEl: action.payload.anchorEl };
    case CLOSE_REALOGRAM_CONVERSATION_POPPER:
      return { ...state, isOpenConversationPopper: false, conversationPopperAnchorEl: undefined };
    case POST_MESSAGE_TO_CONVERSATION_REQUEST: {
      return postMessageToConversationRequestLoadHandler(state);
    }
    case POST_MESSAGE_TO_CONVERSATION_SUCCESS: {
      return postMessageToConversationSuccessLoadHandler(
        state,
        action.payload.shelfReferenceId,
        action.payload.message,
        action.payload.messageId,
        action.payload.userId,
        action.payload.username,
        action.payload.timeOfMessage
      );
    }
    case POST_MESSAGE_TO_CONVERSATION_FAILURE: {
      return postMessageToConversationFailureLoadHandler(state, action.payload.errorDetails);
    }

    case UPDATE_MESSAGE_IN_CONVERSATION_REQUEST: {
      return { ...state, updateMessageInConversationLoadStatus: setLoading() };
    }
    case UPDATE_MESSAGE_IN_CONVERSATION_SUCCESS: {
      return updateMessageInConversationSuccessHandler(state, action.payload.shelfReferenceId, action.payload.messageId, action.payload.editedMessage);
    }
    case UPDATE_MESSAGE_IN_CONVERSATION_FAILURE: {
      return { ...state, updateMessageInConversationLoadStatus: setError(action.payload.errorDetails) };
    }
    case DELETE_MESSAGE_IN_CONVERSATION_REQUEST: {
      return { ...state, deleteMessageInConversationLoadStatus: setLoading() };
    }
    case DELETE_MESSAGE_IN_CONVERSATION_SUCCESS: {
      return deleteMessageInConversationSuccessHandler(state, action.payload.shelfReferenceId, action.payload.messageId);
    }
    case DELETE_MESSAGE_IN_CONVERSATION_FAILURE: {
      return { ...state, deleteMessageInConversationLoadStatus: setError(action.payload.errorDetails) };
    }
    default:
      return state;
  }
};

export default reducer;
