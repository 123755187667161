import ShelfLocation from './ShelfLocation';
import GeometryPoint from '../../shared/geometry/GeometryPoint';
import ShelfLocationGeometryPoint from './ShelfLocationGeometryPoint';
import PointPosition from './PointPosition';

function calculateHorizontal(shelfLocation: ShelfLocation, draggingPoint: ShelfLocationGeometryPoint, requestedXOffset: number): number {
  if (shelfLocation.geometry.hasFixedImage) {
    return requestedXOffset;
  }

  const g = shelfLocation.geometry;
  const topLeft = g.getPointByPosition(PointPosition.TopLeft);
  const bottomLeft = g.getPointByPosition(PointPosition.BottomLeft);
  const topRight = g.getPointByPosition(PointPosition.TopRight);
  const bottomRight = g.getPointByPosition(PointPosition.BottomRight);
  const currentWidthLeftChanged = g.tempWidthLeftChanged + g.widthLeftChanged;
  const currentWidthRightChanged = g.tempWidthRightChanged + g.widthRightChanged;
  let newXOffset = requestedXOffset;
  const isMovingRight = newXOffset > draggingPoint.xOffset;
  const isMovingLeft = newXOffset < draggingPoint.xOffset;
  if (draggingPoint === topLeft || draggingPoint === bottomLeft) {
    if (currentWidthLeftChanged >= 0 && isMovingLeft) {
      newXOffset = draggingPoint.xOffset;
    } else if (isMovingLeft) {
      const movedLeft = draggingPoint.xOffset - newXOffset;
      if (currentWidthLeftChanged + movedLeft > 0) {
        newXOffset = currentWidthLeftChanged + movedLeft + newXOffset;
      }
    }
  }
  if (draggingPoint === topRight || draggingPoint === bottomRight) {
    if (currentWidthRightChanged >= 0 && isMovingRight) {
      newXOffset = draggingPoint.xOffset;
    } else if (isMovingRight) {
      const movedRight = newXOffset - draggingPoint.xOffset;
      if (currentWidthRightChanged + movedRight > 0) {
        newXOffset = newXOffset - (currentWidthRightChanged + movedRight);
      }
    }
  }
  return newXOffset;
}

function calculateVerticalOffset(shelfLocation: ShelfLocation, draggingPoint: ShelfLocationGeometryPoint, requestedYOffset: number): number {
  if (shelfLocation.geometry.hasFixedImage) {
    return requestedYOffset;
  }
  const g = shelfLocation.geometry;
  const currentHeightTopChanged = g.tempHeightTopChanged + g.heightTopChanged;
  const currentHeigthBottomChanged = g.tempHeightBottomChanged + g.heightBottomChanged;
  let newYOffset = requestedYOffset;
  const topLeft = g.getPointByPosition(PointPosition.TopLeft);
  const bottomLeft = g.getPointByPosition(PointPosition.BottomLeft);
  const topRight = g.getPointByPosition(PointPosition.TopRight);
  const bottomRight = g.getPointByPosition(PointPosition.BottomRight);
  const isMovingUp = newYOffset < draggingPoint.yOffset;
  const isMovingDown = newYOffset > draggingPoint.yOffset;
  if (draggingPoint === topLeft || draggingPoint === topRight) {
    if (currentHeightTopChanged >= 0 && isMovingUp) {
      newYOffset = draggingPoint.yOffset;
    } else if (isMovingUp) {
      const movedUp = draggingPoint.yOffset - newYOffset;
      if (currentHeightTopChanged + movedUp > 0) {
        newYOffset = currentHeightTopChanged + movedUp + newYOffset;
      }
    }
  }
  if (draggingPoint === bottomRight || draggingPoint === bottomLeft) {
    if (currentHeigthBottomChanged >= 0 && isMovingDown) {
      newYOffset = draggingPoint.yOffset;
    } else if (isMovingDown) {
      const movedDown = newYOffset - draggingPoint.yOffset;
      if (currentHeigthBottomChanged + movedDown > 0) {
        newYOffset = newYOffset - (currentHeigthBottomChanged + movedDown);
      }
    }
  }
  return newYOffset;
}

/**
 * This function will return new coordinate offsets for transient dragging.
 * In case the dragging point extends outside the boundaries of the shelf location,
 * this function will prevent that and will return offset that stick to the border of the shelf location.
 * @param shelfLocation The shelf location whose edge is being dragged.
 * @param draggingPoint The point of the shelf location that is being dragged.
 * @param geometryOffset The new offsets.
 * @returns New offset values to be applied during the transient drag.
 */
export default function calculatePointDragOffsets(shelfLocation: ShelfLocation, draggingPoint: ShelfLocationGeometryPoint, geometryOffset: GeometryPoint): GeometryPoint {
  const newXOffset = calculateHorizontal(shelfLocation, draggingPoint, geometryOffset.x);
  const newYOffset = calculateVerticalOffset(shelfLocation, draggingPoint, geometryOffset.y);
  return {
    x: newXOffset,
    y: newYOffset,
  };
}
