import * as React from 'react';
import ShelfLocation from 'modules/planogram/domain/ShelfLocation';

import ShelfLocationImageClip from './ShelfLocationImageClip';

interface ListProps {
  shelfLocations: ShelfLocation[];
}

const ShelfLocationImageClipsList: React.FC<ListProps> = ({ shelfLocations }: ListProps) => {
  return (
    <>
      {shelfLocations.map(sl => (
        <ShelfLocationImageClip shelfLocation={sl} key={`sl-clips-${sl.id}`} />
      ))}
    </>
  );
};

export default ShelfLocationImageClipsList;
