import { Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import classNames from 'classnames';
import trackComponent from 'infrastructure/tracking/withTracking';
import { isLoaded, isLoading, isError } from 'infrastructure/utils/RemoteObjectStatus';
import { ClientDatasetDto } from 'modules/admin/clientDataset/types/ClientDatasetDto';
import InsertCommand from 'modules/planogram/commands/InsertCommand';
import { PlanogramCommand } from 'modules/planogram/commands/_planogram_commands';
import ShelfLocation from 'modules/planogram/domain/ShelfLocation';
import { NewProductModel } from 'modules/planogram/types/NewProductModel';
import Rectangle from 'modules/planogram/types/Rectangle';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { State } from 'state';
import ApplicationUser from '../../../auth/types/ApplicationUser';
import * as actions from '../../actions';
import PlanogramModel from '../../domain/PlanogramModel';
import { isAllowedSalesData } from '../../helpers/permissionsHelper';
import PlanogramState from '../../state';
import { RecordChangeReasonDto } from '../../types/PlanogramChangeReasons';
import ProductModel from '../../types/ProductModel';
import { FlowDataReportType } from '../../types/ReportingTypes';
import { SalesDataModel, SalesReportModel } from '../../types/SalesDataModel';
import TestInfoDto from '../../types/TestInfoDto';
import ClientDatasetModal from '../modals/ClientDatasetModal';
import SalesDataModal from '../modals/SalesDataModal';
import NewProductComponent from '../new_product/NewProductComponent';
import PreviewChanges from './PreviewChanges/PreviewChanges';
import SalesDataUpload from './SalesDataUpload';
import PlanogramAsideSettings from './TabPanes/PlanogramAsideSettings/Settings';
import PlanogramInfo from './TabPanes/PlanogramInfoAndActions/PlanogramInfo';
import TestsListView from './TestsListView';

interface MyState {
  activeTab: number;
  showSalesData: boolean;
  report: any;
  showClientDatasetData: boolean;
  clientDataset: ClientDatasetDto;
}

class PlanogramPageAside extends React.Component<PlanogramState & DispatchProps & { user: ApplicationUser }, MyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      activeTab: props.activeTab,
      showSalesData: false,
      report: null,
      showClientDatasetData: false,
      clientDataset: {} as ClientDatasetDto,
    };
  }

  uploadSalesData = (reportName: string, salesData: SalesDataModel[]): void => {
    const planogram = this.props.planogram as PlanogramModel;
    this.props.saveSalesDataRequest(planogram.id, reportName, salesData);
  };

  render(): JSX.Element {
    if (!isLoaded(this.props.loadPlanogramStatus) || this.props.planogram === undefined) {
      return <></>;
    }
    const planogram = this.props.planogram as PlanogramModel;
    return (
      <div className="no-print">
        <Nav tabs id="sideNavTabs">
          <NavItem>
            <Tooltip title="Settings and Data Controls">
              <NavLink
                id="settingsAndDataCtrlsNavLnk"
                className={classNames({ active: this.props.planogramAsideActiveTab === 1 })}
                onClick={(): void => {
                  this.props.togglePlanogramAsideActiveTab(1);
                }}
              >
                <i className="fa fa-sliders" />
              </NavLink>
            </Tooltip>
          </NavItem>
          <NavItem>
            <Tooltip title="Planogram Info and Actions">
              <NavLink
                id="planogramInfoAndActionsNavLnk"
                className={classNames({ active: this.props.planogramAsideActiveTab === 2 })}
                onClick={(): void => {
                  this.props.togglePlanogramAsideActiveTab(2);
                }}
              >
                <i className="fa fa-pencil-square-o" />
              </NavLink>
            </Tooltip>
          </NavItem>
          <NavItem>
            <Tooltip title="Manage Sales Reports">
              <NavLink
                id="manageSalesReportsNavLnk"
                className={classNames({ active: this.props.planogramAsideActiveTab === 3 })}
                onClick={(): void => {
                  this.props.togglePlanogramAsideActiveTab(3);
                }}
              >
                <i className="fa fa-file-text" />
              </NavLink>
            </Tooltip>
          </NavItem>
          <NavItem>
            <Tooltip title="Shelf Tests">
              <NavLink
                id="shelfTestsNavLnk"
                className={classNames({ active: this.props.planogramAsideActiveTab === 4 })}
                onClick={(): void => {
                  this.props.togglePlanogramAsideActiveTab(4);
                  if (!isLoaded(this.props.loadTestsInfoStatus)) {
                    // load tests info only the first time user clicks this tab
                    if (!this.props.planogram) {
                      return;
                    }
                    this.props.loadTestsInfoRequest(this.props.planogram.id);
                  }
                }}
              >
                <i className="fa fa-flag" />
              </NavLink>
            </Tooltip>
          </NavItem>
          <NavItem>
            <Tooltip title="Preview Changes">
              <NavLink
                id="previewChangesNavLnk"
                className={classNames({ active: this.props.planogramAsideActiveTab === 5 })}
                onClick={(): void => {
                  this.props.togglePlanogramAsideActiveTab(5);
                }}
              >
                <i className="fa fa-eye" />
              </NavLink>
            </Tooltip>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.props.planogramAsideActiveTab}>
          <TabPane tabId={1} className="p-3">
            {this.props.planogramAsideActiveTab === 1 ? (
              <PlanogramAsideSettings
                enableFlowDataReport={this.props.enableFlowDataReport}
                enableSalesDataReport={this.props.enableSalesDataReport}
                enableClientDatasetReport={this.props.enableClientDatasetReport}
                enableShowProductInfo={this.props.enableShowProductInfo}
                toggleEnableFlowDataReport={this.props.toggleEnableFlowDataReport}
                toggleEnableSalesDataReport={this.props.toggleEnableSalesDataReport}
                toggleEnableClientDatasetReport={this.props.toggleEnableClientDatasetReport}
                toggleEnableShowProductInfo={this.props.toggleEnableShowProductInfo}
                selectedFlowMetric={this.props.selectedFlowDataType}
                setFlowReportType={this.props.setFlowReportTypeRequest}
                salesReports={this.props.salesData}
                setSalesReport={this.props.selectSalesReportRequest}
                selectedSalesReport={this.props.selectedSalesReport}
                clientDatasets={this.props.clientDatasets}
                setClientDatasetReport={this.props.selectClientDatasetReportRequest}
                selectedClientDatasetReport={this.props.selectedClientDatasetReport}
                products={this.props.products as ProductModel[]}
                toggleEnableGpcBrickFilter={this.props.toggleEnableGpcBrickFilter}
                toggleGpcBrickCodeFilter={this.props.toggleGpcBrickCodeFilter}
                enabledGpcBrickFilter={this.props.gpcBrickFilterEnabled}
                gpcBrickCodeFilters={this.props.gpcBrickCodeFilters}
                isAdmin={this.props.user.isAdmin()}
              />
            ) : null}
          </TabPane>

          <TabPane tabId={2}>
            {this.props.planogramAsideActiveTab === 2 ? (
              <PlanogramInfo
                changePlanogramName={this.props.changePlanogramName}
                drawingNewShelfLocation={this.props.drawingNewShelfLocation}
                nameUpdating={isLoading(this.props.loadNameUpdateStatus)}
                newProductRequest={this.props.newProductRequest}
                newProductRequestStop={this.props.newProductRequestStop}
                planogram={this.props.planogram}
                updateCommentsRequest={this.props.updateCommentsRequest}
                updatingComments={isLoading(this.props.updateCommentsStatus)}
                userId={this.props.userId}
                deletePlanogramRequest={this.props.deletePlanogramRequest}
                deleting={isLoading(this.props.loadDeleteStatus)}
                isResettingPlanogram={isLoading(this.props.loadResetPlanogramStatus)}
                resetPlanogramRequest={this.props.resetPlanogramRequest}
              />
            ) : null}
          </TabPane>
          <TabPane tabId={3} className="p-3" style={{ paddingTop: '8px', paddingBottom: '8px' }}>
            {this.props.planogramAsideActiveTab === 3 ? (
              isAllowedSalesData(this.props.planogram.dataInsights) ? (
                <SalesDataUpload
                  userId={this.props.userId}
                  planogram={this.props.planogram}
                  dataInsights={this.props.planogram.dataInsights}
                  products={this.props.products as ProductModel[]}
                  salesData={this.props.salesData}
                  savingSalesData={isLoading(this.props.loadSavingSalesDataStatus)}
                  saveSalesDataRequest={this.uploadSalesData}
                  onShowData={(report: SalesReportModel): void => {
                    this.setState({ showSalesData: true, report });
                  }}
                  onDeleteSalesData={(reportId: string): void => {
                    this.props.deleteSalesDataRequest(planogram.id, reportId);
                  }}
                  deletingSalesReport={isLoading(this.props.deleteSalesReportStatus)}
                  clientDatasets={this.props.clientDatasets}
                  onShowClientDatasetData={(clientDataset: ClientDatasetDto): void => {
                    this.setState({ showClientDatasetData: true, clientDataset });
                  }}
                />
              ) : (
                <div className="alert alert-warning">
                  <Info /> You are not authorized to view or upload sales data.
                </div>
              )
            ) : null}
          </TabPane>

          <TabPane tabId={4} className="p-3">
            {this.props.planogramAsideActiveTab === 4 ? (
              <TestsListView
                testsInfoList={this.props.testsInfoList}
                loadTestsInfo={(): void => {
                  if (!this.props.planogram) {
                    return;
                  }
                  return this.props.loadTestsInfoRequest(this.props.planogram.id);
                }}
                isLoadingTestsInfo={isLoading(this.props.loadTestsInfoStatus)}
                isLoadingTestsInfoSuccess={isLoaded(this.props.loadTestsInfoStatus)}
                isLoadingTestsInfoFailure={isError(this.props.loadTestsInfoStatus)}
                openEditTestModal={(testInfo: TestInfoDto): void => this.props.openEditTestModal(testInfo)}
                openDeleteTestConfirmationModal={(testToDelete: TestInfoDto): void => this.props.openDeleteTestConfirmationModal(testToDelete)}
              />
            ) : null}
          </TabPane>

          <TabPane tabId={5} className="p-3">
            {this.props.planogramAsideActiveTab === 5 ? (
              <PreviewChanges
                undoDelistedProduct={this.props.undoDelistedProduct}
                onHighlightBrand={this.props.onHighlightBrand}
                onHighlightProduct={this.props.onHighlightProduct}
                currentPlanogram={planogram}
                initialPlanogram={this.props.initialPlanogram as PlanogramModel}
                products={this.props.products as ProductModel[]}
                onRecordChangeReason={this.props.onRecordChangeReason}
              />
            ) : null}
          </TabPane>
        </TabContent>
        {this.state.showSalesData && isAllowedSalesData(this.props.planogram.dataInsights) ? (
          <SalesDataModal onClose={(): void => this.setState({ showSalesData: false })} salesData={this.state.report} />
        ) : null}

        {this.state.showClientDatasetData && isAllowedSalesData(this.props.planogram.dataInsights) ? (
          <ClientDatasetModal onClose={(): void => this.setState({ showClientDatasetData: false })} clientDataset={this.state.clientDataset} />
        ) : null}
        {this.props.creatingNewProduct ? (
          <NewProductComponent
            imageUploadStatus={this.props.uploadNewProductImage}
            planogram={this.props.planogram}
            onClose={(): void => {
              this.props.newProductRequestStop();
            }}
            onUploadImage={(base64Data: string, crop: Rectangle): void => {
              this.props.onNewProductImageUploadRequest(base64Data, crop, this.props.id);
            }}
            onNewShelfLocation={(sl: ShelfLocation, newProduct: NewProductModel): void => {
              if (!this.props.planogram) {
                return;
              }
              const command = new InsertCommand(this.props.planogram, [sl]);

              this.props.onCommandExecutionRequest(command);
              this.props.setShelfLocationGtinRequest(this.props.planogram.id, sl, sl.gtin, newProduct);
              this.props.newProductRequestStop();
            }}
          />
        ) : null}
      </div>
    );
  }
}

interface DispatchProps {
  changePlanogramName(planogramId: string, newName: string): void;

  deletePlanogramRequest(planogramId: string): void;

  toggleEnableShowProductInfo(enabled?: boolean): void;

  toggleEnableSalesDataReport(enabled?: boolean): void;

  toggleEnableFlowDataReport(enabled?: boolean): void;

  toggleEnableClientDatasetReport(enabled?: boolean): void;

  saveSalesDataRequest(planogramId: string, reportName: string, salesData: SalesDataModel[]): void;

  newProductRequest(): void;

  newProductRequestStop(): void;

  setFlowReportTypeRequest(reportType?: FlowDataReportType): void;

  selectSalesReportRequest(salesReport?: SalesReportModel): void;

  deleteSalesDataRequest(planogramId: string, reportId: string): void;

  selectClientDatasetReportRequest(clientDataset?: ClientDatasetDto): void;

  updateCommentsRequest(planogramId: string, comments: string): void;

  loadTestsInfoRequest(planogramId: string): void;

  openEditTestModal(testInfo: TestInfoDto): void;

  openDeleteTestConfirmationModal(testToDelete: TestInfoDto): void;

  undoDelistedProduct(gtin: number): void;

  onHighlightBrand(priority: boolean, brandId?: number): void;

  onHighlightProduct(priority: boolean, gtin?: number[]): void;

  onRecordChangeReason(reason: RecordChangeReasonDto): void;

  resetPlanogramRequest(planogramId: string): void;

  togglePlanogramAsideActiveTab(activeTab: number): void;
  toggleEnableGpcBrickFilter(): void;

  toggleGpcBrickCodeFilter(gpcBrickCode: number): void;

  onNewProductImageUploadRequest(base64Data: string, cropping: Rectangle, planogramId: string): void;

  onCommandExecutionRequest(command: PlanogramCommand): void;

  setShelfLocationGtinRequest(planogramId: string, shelfLocation: ShelfLocation, gtin: number, newProduct?: NewProductModel): void;
}

function mapStateToProps(state: State, ownProps: any): PlanogramState & { user: ApplicationUser | undefined } {
  return {
    ...state.planogram,
    id: ownProps.match.params.id,
    userId: Number(state.auth.loadUserStatus.data?.profile.sub),
    user: state.auth.applicationUser,
  };
}

const componentToExport = connect<PlanogramState, DispatchProps, RouteComponentProps<any>>(mapStateToProps, actions)(PlanogramPageAside);

export default trackComponent(componentToExport, 'PlanogramPageAside');
