import PlanogramModel from '../domain/PlanogramModel';
import { PlanogramUndoableCommand } from './_planogram_commands';
import PlanogramEventBase from '../events/PlanogramEventBase';

export default class MultiCommand extends PlanogramUndoableCommand {
  private planogram: PlanogramModel;
  private commands: PlanogramUndoableCommand[];

  constructor(planogram: PlanogramModel, commands: PlanogramUndoableCommand[]) {
    super();
    this.planogram = planogram;
    this.commands = commands;
  }

  execute(): PlanogramEventBase {
    this.planogram.startTransaction();
    const executedCommands: PlanogramUndoableCommand[] = [];
    for (let i = 0; i < this.commands.length; i++) {
      const command = this.commands[i];
      try {
        command.execute();
        executedCommands.push(command);
      } catch (error) {
        for (let j = executedCommands.length - 1; j >= 0; j--) {
          executedCommands[j].undo();
        }
        this.planogram.cancelTransaction();
      }
    }
    return this.planogram.commitTransaction();
  }

  undo(): PlanogramEventBase {
    const undoedCommands: PlanogramUndoableCommand[] = [];
    this.planogram.startTransaction();
    for (let i = this.commands.length - 1; i >= 0; i--) {
      try {
        const command = this.commands[i];
        command.undo();
        undoedCommands.push(command);
      } catch (error) {
        for (let j = 0; j < undoedCommands.length; j++) {
          undoedCommands[j].execute();
        }
        this.planogram.cancelTransaction();
        throw new Error(`Failed to execute undoing of multiple commands. The error is: ${error}`);
      }
    }
    return this.planogram.commitTransaction();
  }
}
