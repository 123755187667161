import { isLoaded, isLoading } from 'infrastructure/utils/RemoteObjectStatus';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { loadAggregatedBrickReportRequestAction } from '../../actions';
import { getAggregatedBrickReportLoadState, getAggregatedBrickReportData, isAggregatedBrickReportEmpty } from '../../selectors';
import { AggregatedMetrics, AggregatedOnePagerReport } from '../../types';

interface LoadStatusParams {
  isLoaded: boolean;
  isLoading: boolean;
  loadError: string;
  isDataEmpty: boolean;
  data: AggregatedOnePagerReport | undefined;
}

export interface AggregatedBrickReportLoaderProps {
  children: (params: LoadStatusParams) => React.ReactNode;
}

const AggregatedBrickReportLoader: React.FC<AggregatedBrickReportLoaderProps> = ({ children }: AggregatedBrickReportLoaderProps) => {
  type RequestParamsType = {
    gpcBrickCode: string;
    metrics: string;
  };

  const { gpcBrickCode, metrics } = useParams<RequestParamsType>();
  const dispatch = useDispatch();
  const loadState = useSelector(getAggregatedBrickReportLoadState);
  const isDataEmpty = useSelector(isAggregatedBrickReportEmpty);
  const data = useSelector(getAggregatedBrickReportData);
  useEffect(() => {
    if (isDataEmpty && gpcBrickCode && metrics !== undefined) {
      const selectedMetrics = metrics.split(',').map(x => parseInt(x)) as AggregatedMetrics[];
      dispatch(loadAggregatedBrickReportRequestAction(parseInt(gpcBrickCode), selectedMetrics));
    }
  }, [dispatch, isDataEmpty, gpcBrickCode, metrics]);
  return (
    <>
      {children({
        isLoaded: isLoaded(loadState),
        isLoading: isLoading(loadState),
        loadError: loadState?.errorDetails?.errorMessage ?? '',
        isDataEmpty,
        data,
      })}
    </>
  );
};

export { AggregatedBrickReportLoader };
