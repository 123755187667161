import { Type } from 'class-transformer';

export class BrandResizeReason {
  readonly brandId: number = 0;
  readonly initialSpaceSquaredMeters: number = 0;
  readonly currentSpaceSquaredMeters: number = 0;
  readonly reason: string = '';

  constructor(brandId: number, initialSpaceSquaredMeters: number, currentSpaceSquaredMeters: number, reason: string) {
    this.brandId = brandId;
    this.initialSpaceSquaredMeters = initialSpaceSquaredMeters;
    this.currentSpaceSquaredMeters = currentSpaceSquaredMeters;
    this.reason = reason;
  }
}

export class ProductDelistedReason {
  gtin = 0;
  initialSpaceSquaredMeters = 0;
  reason = '';

  constructor(gtin: number, initialSpaceSquaredMeters: number, reason: string) {
    this.gtin = gtin;
    this.initialSpaceSquaredMeters = initialSpaceSquaredMeters;
    this.reason = reason;
  }
}

export class NewProductReason {
  gtin = 0;
  currentSpaceSquaredMeters = 0;
  reason = '';

  constructor(gtin: number, currentSpaceSquaredMeters: number, reason: string) {
    this.gtin = gtin;
    this.currentSpaceSquaredMeters = currentSpaceSquaredMeters;
    this.reason = reason;
  }
}

export class ProductResizeReason {
  gtin = 0;
  initialSpaceSquaredMeters = 0;
  currentSpaceSquaredMeters = 0;
  reason = '';

  constructor(gtin: number, initialSpaceSquaredMeters: number, currentSpaceSquaredMeters: number, reason: string) {
    this.gtin = gtin;
    this.initialSpaceSquaredMeters = initialSpaceSquaredMeters;
    this.currentSpaceSquaredMeters = currentSpaceSquaredMeters;
    this.reason = reason;
  }
}

export class PlanogramChangeReasons {
  @Type(() => BrandResizeReason)
  brandResizeReasons: BrandResizeReason[] = [];

  @Type(() => ProductDelistedReason)
  productDelistedReasons: ProductDelistedReason[] = [];

  @Type(() => NewProductReason)
  newProductReasons: NewProductReason[] = [];

  @Type(() => ProductResizeReason)
  productResizeReasons: ProductResizeReason[] = [];
}

/**
 * This class get sent to the API when user enters a reason
 */
export class RecordChangeReasonDto {
  planogramId = '';

  brandResizeReason?: BrandResizeReason;
  productDelistedReason?: ProductDelistedReason;
  newProductReason?: NewProductReason;
  productResizeReason?: ProductResizeReason;

  constructor(planogramId: string) {
    this.planogramId = planogramId;
  }
}
