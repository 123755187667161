import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { InspirationImageItem } from '../types';
import { getFetchingError, getInspirationImages, isFetching as isFechingSelector } from '../selectors';
import { fetchInspirationImagesRequest } from '../actions';

interface ChildrenProps {
  data: InspirationImageItem[];
  isFetching: boolean;
  error: string;
}
interface Props {
  planogramId: string;
  children: (props: ChildrenProps) => React.ReactNode;
}

const HTTPFetchRequest: React.FC<Props> = ({ children, planogramId }: Props): JSX.Element => {
  const data = useSelector(getInspirationImages);
  const isFetching = useSelector(isFechingSelector);
  const error = useSelector(getFetchingError);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchInspirationImagesRequest(planogramId));
  }, [planogramId, dispatch]);
  return (
    <>
      {children({
        data,
        error,
        isFetching,
      })}
    </>
  );
};

export default HTTPFetchRequest;
