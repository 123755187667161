import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { ErrorOutline, InfoOutlined } from '@material-ui/icons';
import React from 'react';
import { Alert } from 'reactstrap';
import { StandardGpcBrickComparisonReportLoader } from './StandardGpcBrickComparisonReportLoader';
import { StandardGpcBrickComparisonReportPage } from './StandardGpcBrickComparisonReportPage';
const StandardGpcBrickComparisonReportIndex: React.FC = () => {
  return (
    <StandardGpcBrickComparisonReportLoader>
      {({ isLoaded, isLoading, loadError, isDataEmpty, data }): React.ReactNode => {
        const showGpcBrickComparisonReport = !isDataEmpty && !isLoading && !loadError && isLoaded;
        return (
          <>
            {isLoading && (
              <Grid direction="column" alignItems="center" container justify="center">
                <CircularProgress /> &nbsp;&nbsp;
                <Typography variant="overline">Loading Gpc Brick Comparison Report...</Typography>
              </Grid>
            )}
            {loadError && (
              <Alert className="top-buffer" color="danger">
                <Typography variant="caption" color="textSecondary">
                  <ErrorOutline color="secondary" fontSize="small" />
                  &nbsp;Failed to load Gpc Brick Comparison Report. {loadError}
                </Typography>
              </Alert>
            )}
            {!loadError && !isLoading && isLoaded && isDataEmpty && (
              <Alert className="top-buffer" color="warning">
                <Typography variant="caption" color="textPrimary">
                  <InfoOutlined color="primary" fontSize="small" />
                  &nbsp; No Gpc Brick Comparison Report Found.
                </Typography>
              </Alert>
            )}
            {showGpcBrickComparisonReport && data && <StandardGpcBrickComparisonReportPage gpcBrickComparisonReport={data} />}
          </>
        );
      }}
    </StandardGpcBrickComparisonReportLoader>
  );
};

export { StandardGpcBrickComparisonReportIndex };
