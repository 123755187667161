import ShelfLocation from '../../../domain/ShelfLocation';
import * as React from 'react';
import ShelfLocationGeometryPoint from 'modules/planogram/domain/ShelfLocationGeometryPoint';

interface ShelfLocationEdgesComponentProps {
  shelfLocation: ShelfLocation;
  ratio: number;
  onShelfLocationEdgeMouseDown(sl: ShelfLocation, point: ShelfLocationGeometryPoint): void;
}

const ShelfLocationEdges: React.FC<ShelfLocationEdgesComponentProps> = ({ shelfLocation, ratio, onShelfLocationEdgeMouseDown }: ShelfLocationEdgesComponentProps) => {
  const elements = shelfLocation.geometry.points.map((p, index) => {
    const cx = p.x + p.xOffset;
    const cy = p.y + p.yOffset;
    const r = 5 / ratio;

    return <circle onMouseDown={(): void => onShelfLocationEdgeMouseDown(shelfLocation, p)} key={`sl-edge-${index}`} cx={cx} cy={cy} r={r} fill="red" cursor="all-scroll" />;
  });
  return <>{elements}</>;
};

export default ShelfLocationEdges;
