export default class ShelfLocationValidStateDescriptor {
  isOutsideShelf = false;
  isOverlapping = false;
  canIncreaseBrandRuleViolation = false;
  canDecreaseBrandRuleViolation = false;

  isValid(): boolean {
    return !this.isOutsideShelf && !this.isOverlapping && !this.canDecreaseBrandRuleViolation && !this.canIncreaseBrandRuleViolation;
  }

  isPositioningValid(): boolean {
    return !this.isOutsideShelf && !this.isOverlapping;
  }

  isBrandRuleValid(): boolean {
    return !this.canDecreaseBrandRuleViolation && !this.canIncreaseBrandRuleViolation;
  }

  reset(): void {
    this.isOutsideShelf = false;
    this.isOverlapping = false;
    this.canIncreaseBrandRuleViolation = false;
    this.canDecreaseBrandRuleViolation = false;
  }
}
