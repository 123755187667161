import { CircularProgress, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { ArrowBack, ArrowForward, ErrorOutline, InfoOutlined } from '@material-ui/icons';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'reactstrap';
import { loadTimelinesInDateRangeRequestAction } from '../actions';
import { getCalendarEndDate, getCalendarStartDate, getViewAs } from '../selectors';
import { TimelinesCalendar } from './TimelinesCalendar';
import { TimelinesLoader } from './TimelinesLoader';

export const TimelinesPage: React.FC = () => {
  const dispatch = useDispatch();
  const startDate = useSelector(getCalendarStartDate);
  const endDate = useSelector(getCalendarEndDate);
  const viewAs = useSelector(getViewAs);

  const onClickPrevious = (): void => {
    const newStartDate = moment(startDate).subtract(1, 'year');
    const newEndDate = moment(endDate).subtract(1, 'year');
    dispatch(loadTimelinesInDateRangeRequestAction(newStartDate.format('YYYY-MM-DD'), newEndDate.format('YYYY-MM-DD'), viewAs));
  };

  const onClickNext = (): void => {
    const newStartDate = moment(startDate).add(1, 'year');
    const newEndDate = moment(endDate).add(1, 'year');
    dispatch(loadTimelinesInDateRangeRequestAction(newStartDate.format('YYYY-MM-DD'), newEndDate.format('YYYY-MM-DD'), viewAs));
  };

  const selectedYear = moment(startDate).year();
  return (
    <TimelinesLoader>
      {({ isLoading, loadError, isDataEmpty }): React.ReactNode => {
        const showCalendarTimeline = !isDataEmpty && !isLoading && !loadError;
        return (
          <>
            {
              <Grid container direction="row" justify="center" alignItems="center" alignContent="center">
                <Tooltip title="Previous year" placement="left">
                  <IconButton disabled={isLoading} onClick={onClickPrevious}>
                    <ArrowBack />
                  </IconButton>
                </Tooltip>
                <Typography color="textPrimary" variant="h6">
                  {selectedYear}
                </Typography>
                <Tooltip title="Next year" placement="right">
                  <IconButton disabled={isLoading} onClick={onClickNext}>
                    <ArrowForward />
                  </IconButton>
                </Tooltip>
              </Grid>
            }
            {isLoading && (
              <Grid direction="column" alignItems="center" container justify="center">
                <CircularProgress /> &nbsp;&nbsp;
                <Typography variant="overline">Loading timelines...</Typography>
              </Grid>
            )}
            {loadError && (
              <Alert className="top-buffer" color="danger">
                <Typography variant="caption" color="textSecondary">
                  <ErrorOutline color="secondary" fontSize="small" />
                  &nbsp;Failed to load timelines. {loadError}
                </Typography>
              </Alert>
            )}
            {!loadError && !isLoading && isDataEmpty && (
              <Alert className="top-buffer" color="warning">
                <Typography variant="caption" color="textPrimary">
                  <InfoOutlined color="primary" fontSize="small" />
                  &nbsp; No Timelines found in {selectedYear}.
                </Typography>
              </Alert>
            )}
            {showCalendarTimeline && <TimelinesCalendar startDate={startDate} endDate={endDate} />}
          </>
        );
      }}
    </TimelinesLoader>
  );
};
