import { EulaAgreement } from './types';
import ErrorDetails from 'infrastructure/exception/ErrorDetails';

export const EULA_LOAD_REQUEST = 'eula/LOAD_REQUEST';
export const EULA_LOAD_SUCCESS = 'eula/LOAD_SUCCESS';
export const EULA_LOAD_ERROR = 'eula/LOAD_ERROR';

export interface LoadEulaRequestAction {
  type: typeof EULA_LOAD_REQUEST;
}

export interface LoadEulaSuccessAction {
  type: typeof EULA_LOAD_SUCCESS;
  payload: EulaAgreement;
}

export interface LoadEulaErrorAction {
  type: typeof EULA_LOAD_ERROR;
  error: ErrorDetails;
}

export const EULA_ACCEPT_REQUEST = 'eula/ACCEPT_REQUEST';
export const EULA_ACCEPT_SUCCESS = 'eula/ACCEPT_SUCCESS';
export const EULA_ACCEPT_ERROR = 'eula/ACCEPT_ERROR';

export interface AcceptEulaRequestAction {
  type: typeof EULA_ACCEPT_REQUEST;
  version: number;
}

export interface AcceptEulaSuccessAction {
  type: typeof EULA_ACCEPT_SUCCESS;
}

export interface AcceptEulaErrorAction {
  type: typeof EULA_ACCEPT_ERROR;
  error: ErrorDetails;
}

export type EulaActionTypes = LoadEulaRequestAction | LoadEulaSuccessAction | LoadEulaErrorAction | AcceptEulaRequestAction | AcceptEulaErrorAction | AcceptEulaSuccessAction;
