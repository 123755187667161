import React, { useState, useRef, MutableRefObject } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';

import { updateDescInspirationImageRequest } from '../../../../../entities/inspirationImages/actions';
import CircularProgress from '@material-ui/core/CircularProgress';

interface Props {
  defaultValue: string;
  planogramId: string;
  inspirationImageId: string;
}

const ImageDescription: React.FC<Props> = ({ defaultValue, planogramId, inspirationImageId }: Props): JSX.Element => {
  const textInput = useRef<HTMLInputElement>();
  const dispatch = useDispatch();
  const [isEditMode, setIsEditMode] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const handleOnClickEdit = (): void => {
    setIsEditMode(prevState => !prevState);

    if (textInput.current) {
      const input = textInput as MutableRefObject<HTMLInputElement>;
      setTimeout((): void => {
        input.current.focus();
      }, 0);
    }
  };

  const handleOnClickSave = (): void => {
    setIsUpdating(true);
    dispatch(
      updateDescInspirationImageRequest(
        {
          inspirationImageId,
          planogramId,
          description: value,
        },
        {
          resetUpdating: () => {
            setIsUpdating(false);
          },
          restEditingMode: () => {
            setIsEditMode(false);
          },
        }
      )
    );
  };
  const isDirty = value !== defaultValue;
  return (
    <Grid container direction="row" alignItems="center" alignContent="center">
      <TextField disabled={!isEditMode} onChange={(e): void => setValue(e.target.value)} defaultValue={defaultValue} placeholder="Store Details" inputRef={textInput} />
      {!isDirty && (
        <Tooltip title="Edit Store Details">
          <IconButton data-testid="inspiration-image-edit-des-btn" onClick={handleOnClickEdit} color="primary">
            <EditIcon />
          </IconButton>
        </Tooltip>
      )}
      {isDirty && (
        <Tooltip title={`${isUpdating ? 'Saving' : 'Save'} changes`}>
          <IconButton data-testid="inspiration-image-save-des-btn" onClick={handleOnClickSave} color="primary">
            {!isUpdating && <SaveIcon />}
            {isUpdating && <CircularProgress data-testid="inspiration-image-save-btn-progress" size={24} />}
          </IconButton>
        </Tooltip>
      )}
    </Grid>
  );
};

export default ImageDescription;
