import { orderBy, take, first } from 'lodash';
import GeometryPoint from './GeometryPoint';

export function getLeftTopMostPoint(geometry: GeometryPoint[]): GeometryPoint {
  let ordered = orderBy(geometry, ['x'], ['asc']);
  ordered = take(ordered, 2);
  ordered = orderBy(ordered, ['y'], ['desc']);
  return first(ordered) as GeometryPoint;
}

export function getLeftBottomMostPoint(geometry: GeometryPoint[]): GeometryPoint {
  let ordered = take(orderBy(geometry, ['x'], ['asc']), 2);
  ordered = orderBy(ordered, ['y'], ['asc']);
  return first(ordered) as GeometryPoint;
}

export function getRightTopMostPoint(geometry: GeometryPoint[]): GeometryPoint {
  let ordered = take(orderBy(geometry, ['x'], ['desc']), 2);
  ordered = orderBy(ordered, ['y'], ['desc']);
  return first(ordered) as GeometryPoint;
}

export function getRightBottomMostPoint(geometry: GeometryPoint[]): GeometryPoint {
  let ordered = take(orderBy(geometry, ['x'], ['desc']), 2);
  ordered = orderBy(ordered, ['y'], ['desc']);
  return first(ordered) as GeometryPoint;
}

export function getLineDistance(c1: GeometryPoint, c2: GeometryPoint): number {
  const xs = c2.x - c1.x;
  const ys = c2.y - c1.y;
  return Math.sqrt(xs * xs + ys * ys);
}
