/* eslint-disable no-case-declarations */
import { setError, setIdle, setLoading, setSuccess } from 'infrastructure/utils/RemoteObjectStatus';
import { Reducer } from 'redux';
import { WeekDate } from 'types/CommonTypes';
import { ActionType, getType } from 'typesafe-actions';
import { getWeekDatesInDateRange } from 'utils/dateHelper';
import ErrorDetails from '../../../infrastructure/exception/ErrorDetails';
import * as salesActions from '../aside/SalesDataManagement/actionTypes';
import { TimeSalesDataReport } from '../aside/SalesDataManagement/types';
import * as actions from './actions';
import { ShelfConditionImagesState, ShelfConditionsResult } from './types';

export const INITIAL_STATE = {
  shelfConditionsResultStatus: setIdle<ShelfConditionsResult>(),
  salesDataUpload: setIdle<TimeSalesDataReport>(),
  availableSalesData: [] as TimeSalesDataReport[],
  getSalesDataStatus: setIdle<TimeSalesDataReport[]>(),
  currentIndex: 0,
  currentTimestamp: '',
  selectedWeek: undefined,
  weeksInTimelapse: undefined,
} as ShelfConditionImagesState;
type Actions = ActionType<typeof actions>;

const reducer: Reducer<ShelfConditionImagesState, Actions> = (state = INITIAL_STATE, action: Actions): ShelfConditionImagesState => {
  const { type, payload } = action;
  switch (type) {
    case getType(actions.fetchRequest):
      return {
        ...state,
        shelfConditionsResultStatus: setLoading<ShelfConditionsResult>('Loading shelf conditions...'),
      };
    case getType(actions.fetchSuccess):
      const shelfConditionsResult = payload as ShelfConditionsResult;
      const projectStartDate = shelfConditionsResult.projectStartDate;
      const projectEndDate = shelfConditionsResult.projectEndDate;
      const weeksInTimelapse: WeekDate[] = getWeekDatesInDateRange(projectStartDate, projectEndDate);
      const selectedWeek = weeksInTimelapse[0];
      return {
        ...state,
        shelfConditionsResultStatus: setSuccess(shelfConditionsResult),
        weeksInTimelapse,
        selectedWeek,
      };
    case getType(actions.fetchFailed):
      return {
        ...state,
        shelfConditionsResultStatus: setError<ShelfConditionsResult>(payload as ErrorDetails),
      };
    case getType(actions.selectSalesReport):
      return {
        ...state,
        selectedSalesDataReport: payload as string,
      };
    case getType(actions.setCarouselIndex):
      return {
        ...state,
        currentIndex: action.payload as number,
      };
    case getType(actions.setCarouselTime):
      return {
        ...state,
        currentTimestamp: action.payload as string,
      };
    case salesActions.SHELVES_TIMELINE_UPLOAD_SALES_REQUEST:
      return {
        ...state,
        salesDataUpload: setLoading<TimeSalesDataReport>('Uploading sales data...'),
      };
    case salesActions.SHELVES_TIMELINE_UPLOAD_SALES_SUCCESS: {
      const newReport = (action as any).newReport;
      const availableReports = [...state.availableSalesData, newReport];
      return {
        ...state,
        salesDataUpload: setSuccess<TimeSalesDataReport>((action as any).newReport),
        availableSalesData: availableReports,
      };
    }
    case salesActions.SHELVES_TIMELINE_UPLOAD_SALES_ERROR:
      return {
        ...state,
        salesDataUpload: setError<TimeSalesDataReport>(payload as ErrorDetails),
      };
    case salesActions.SHELVES_TIMELINE_DELETE_SALES_DATA_REQUEST: {
      const toDelete = state.availableSalesData.find(s => s.id === (action as any).reportId);
      const availableSalesData = state.availableSalesData;
      if (toDelete) {
        const idx = availableSalesData.indexOf(toDelete);
        availableSalesData.splice(idx, 1);
      }
      return {
        ...state,
        availableSalesData,
      };
    }
    case salesActions.SHELVES_TIMELINE_GET_SALES_DATA_REQUEST:
      return {
        ...state,
        getSalesDataStatus: setLoading<TimeSalesDataReport[]>('Loading sales data...'),
      };
    case salesActions.SHELVES_TIMELINE_GET_SALES_DATA_SUCCESS: {
      const act = action as any;
      const data: TimeSalesDataReport[] = act.data;
      return {
        ...state,
        availableSalesData: data,
        getSalesDataStatus: setIdle<TimeSalesDataReport[]>(),
        selectedSalesDataReport: data.length > 0 ? data[0].id : undefined,
      };
    }
    case salesActions.SHELVES_TIMELINE_GET_SALES_DATA_ERROR:
      return {
        ...state,
        getSalesDataStatus: setError<TimeSalesDataReport[]>((action as any).errorDetails),
      };
    case getType(actions.updateSelectedTimelapseWeek):
      return {
        ...state,
        selectedWeek: payload as WeekDate,
      };
    default:
      return state;
  }
};

export default reducer;
