import { Type } from 'class-transformer';

export default class UserForAdminViewModel {
  userEmail = '';
  userId = 0;
  planogramsCount = 0;
  ownerPlanogramsCount = 0;

  @Type(() => Date)
  lastDateTimeActive?: Date;
}
