import PlanogramEventBase from './PlanogramEventBase';
import GeometryPoint from '../../shared/geometry/GeometryPoint';
import ViewPort from '../types/ViewPort';
import ShelfLocation from '../domain/ShelfLocation';
import { convertHeightToRelative, convertWidthToRelative, convertPointToRelative } from '../helpers/geometry';

class ShelfLocationGeometryInsertedDto {
  hasFixedImage = false;
  currentHeight = 0;
  currentWidth = 0;
  heightBottomChanged = 0;
  heightTopChanged = 0;
  originalHeight = 0;
  originalWidth = 0;
  widthLeftChanged = 0;
  widthRightChanged = 0;
  points: GeometryPoint[] = [];
}

class ShelfLocationInsertedDto {
  id = '';
  code = '';
  imageUrl = '';
  gtin = 0;
  consumerPriceLocalCurrency = 0;
  shelfFacings = 0;
  geometry: ShelfLocationGeometryInsertedDto = new ShelfLocationGeometryInsertedDto();
}

export default class ShelfLocationsInsertedEvent extends PlanogramEventBase {
  readonly planogramId: string = '';
  readonly shelfLocations: ShelfLocationInsertedDto[] = [];

  constructor(planogramId: string, shelfLocations: ShelfLocation[], viewPort: ViewPort) {
    super(`planograms/${planogramId}/shelf-locations/insert`, 'ShelfLocationsInserted');
    this.planogramId = planogramId;

    this.shelfLocations = shelfLocations.map(sl => {
      const shelfLocation = new ShelfLocationInsertedDto();
      shelfLocation.id = sl.id;
      shelfLocation.code = sl.code;
      shelfLocation.gtin = sl.gtin;
      shelfLocation.imageUrl = sl.imageUrl;
      const g = sl.geometry;

      shelfLocation.geometry.hasFixedImage = g.hasFixedImage;
      shelfLocation.geometry.currentHeight = convertHeightToRelative(g.currentHeight, viewPort);
      shelfLocation.geometry.currentWidth = convertWidthToRelative(g.currentWidth, viewPort);
      shelfLocation.geometry.heightBottomChanged = convertHeightToRelative(g.heightBottomChanged, viewPort);
      shelfLocation.geometry.heightTopChanged = convertHeightToRelative(g.heightTopChanged, viewPort);
      shelfLocation.geometry.originalHeight = convertHeightToRelative(g.originalHeight, viewPort);
      shelfLocation.geometry.originalWidth = convertWidthToRelative(g.originalWidth, viewPort);
      shelfLocation.geometry.widthLeftChanged = convertWidthToRelative(g.widthLeftChanged, viewPort);
      shelfLocation.geometry.widthRightChanged = convertWidthToRelative(g.widthRightChanged, viewPort);
      shelfLocation.geometry.points = sl.geometry.points.map(p => convertPointToRelative(p, viewPort));

      return shelfLocation;
    });
  }
}
