import { FlowDataReportType } from '../types/ReportingTypes';

export interface TooltipsFlowDataType {
  [enumKey: string]: string;
}

export const tooltipsFlowDataReportType: TooltipsFlowDataType = {
  [FlowDataReportType.ProductPickupCount]: 'Number of pickups for the given product. One shopper can have multiple pickups for one product.',
  [FlowDataReportType.ProductReturnCount]: 'Number of returns for the given product. One shopper can have multiple returns for one product.',
  [FlowDataReportType.ProductTouchCount]: 'Number of touches for the given product. One shopper can have multiple touches for one product.',
  [FlowDataReportType.ProductInteractionCount]: 'Number of interactions for the given product. One shopper can have multiple interactions for one product.',
  [FlowDataReportType.ProductFirstPickupsCount]: 'Number of shoppers that have picked up the product as the very first in their shopping sequence',
  [FlowDataReportType.ProductPurchaseCount]: 'Number of purchases for the given product. One shopper can have multiple purchases for one product.',
  [FlowDataReportType.ProductBuyersCount]: 'Number of shoppers that interacted with the given product.',
  [FlowDataReportType.ProductReturnRatio]: 'Number of returns divided by number of pickups.',
  [FlowDataReportType.ProductTurnover]: 'Average turnover per day in local currency.',
  [FlowDataReportType.ProductConversionRate]: 'Ratio between product buyers and interactors.',
  [FlowDataReportType.ProductDestinationBuyersCount]: 'Number of shoppers that only buys this product without interacting with any other product.',
  [FlowDataReportType.ProductDestinationBuyersShare]:
    'Destination buyer for a product is a shopper that buys only that product, without interacting with any other product. This metric shows the share of destination buyers out of all buyers of the given product.',
  [FlowDataReportType.ProductLostShoppersCount]:
    'Lost shopper is a shopper that has interacted with at least one product on the shelf, but has left the shelf without buying anything. This metric shows how many lost shoppers that interacted with the product during the phase.',
  [FlowDataReportType.ProductCurrentShareOfSpace]: "Current product's share of space as percentage of all products on the planogram.",
  [FlowDataReportType.ProductShareOfUnitSales]: 'Number of units sold for a product as percentage of total units sold during the phase.',
  [FlowDataReportType.ProductShareOfTurnover]: "Product's turnover as percentage of total turnover during the phase.",
  [FlowDataReportType.ProductSalesPerformance]: 'Performance of product by share of sales vs. space.',
  [FlowDataReportType.ProductTurnoverPerformance]: 'Performance of product by share of turnover vs. space.',
  [FlowDataReportType.ProductOosHoursPerDay]: 'Average number of hours per day that a given product has been out of stock during the phase.',
  [FlowDataReportType.ProductLostDailySalesOutOfStock]: 'Potential loss of product sales due to out of stock.',
  [FlowDataReportType.ProductLostDailyTurnoverOutOfStock]: 'Potential loss of product turnover due to out of stock.',
  [FlowDataReportType.ProductDiscoveryTime]: 'Time to first interaction with product.',
  [FlowDataReportType.ProductSecondsToBuyAndLeave]: 'Time from first interaction with product to purchase.',
  [FlowDataReportType.ProductSecondsToTouchAndReject]: 'Time that rejectors spend on interacting with the product.',
};

interface ToolTipsAnimatedActions {
  salesResizePercent: string;
  delist: string;
  primeLocation: string;
  anyShelfLocation: string;
  designPriceChange: string;
}

export const toolTipsAnimatedActions: ToolTipsAnimatedActions = {
  salesResizePercent:
    'This action will resize products based on the "Sales resize" metric. If this metric is < 1 for a product, that product size will decrease, otherwise it will increase the size of the product.',
  delist: 'Products that are on the bottom of the list by number of interactions, that have been on the shelf more than 80% of the time during the phase, have more than 2 alternatives in its brick.',
  primeLocation: 'Products that are sold a lot and picked up first.',
  anyShelfLocation: 'Products that have high share of destination buyers even if it takes long to find.',
  designPriceChange: 'Products with a high return ratio',
};
