import PlanogramModel from '../domain/PlanogramModel';
import { flatten } from 'lodash';
import DeleteCommand from '../commands/DeleteCommand';
import { classToClass } from 'class-transformer';
import InsertCommand from '../commands/InsertCommand';
import { Guid } from 'guid-typescript';
import ShelfLocation from '../domain/ShelfLocation';
import MoveCommand from '../commands/MoveCommand';
import { PlanogramUndoableCommand } from '../commands/_planogram_commands';
import { FlowDataShelfLocationReportResult } from '../types/FlowDataReportResult';

function cutByWidth(shelfLocations: ShelfLocation[], resizePercent: number) {
  shelfLocations.forEach(sl => {
    const widthToCut = sl.geometry.currentWidth * (1 - resizePercent);
    const topRight = sl.geometry.topRightPoint;
    const bottomRight = sl.geometry.bottomRightPoint;
    topRight.xOffset -= widthToCut;
    bottomRight.xOffset -= widthToCut;
    sl.geometry.calculateDraggingPoint(topRight);
    sl.geometry.calculateDraggingPoint(bottomRight);
  });
}

function generateForProduct(planogram: PlanogramModel, initialPlanogram: PlanogramModel, data: FlowDataShelfLocationReportResult): PlanogramUndoableCommand[] {
  const initialShelfLocations = initialPlanogram.shelfLocations.filter(sl => sl.gtin === data.gtin);
  if (initialShelfLocations.length === 0) {
    return [];
  }
  const currentShelfLocations = planogram.shelfLocations.filter(sl => sl.gtin === data.gtin);

  const commands: PlanogramUndoableCommand[] = [];

  const deleteInitial = new DeleteCommand(planogram, currentShelfLocations);
  commands.push(deleteInitial);

  const shelfLocationsToInsert = initialShelfLocations.map(sl => {
    const newSl = classToClass(sl);
    newSl.geometry.calculatePointsPosition();
    return newSl;
  });

  const reinsertInitial = new InsertCommand(planogram, shelfLocationsToInsert);
  commands.push(reinsertInitial);

  // TODO: Re-visit this logic, SalesResizePercent was previously used here
  if (!data || !data.shelfLocationSalesPerformance) {
    return commands;
  }
  if (data && data.shelfLocationSalesPerformance > 1) {
    const expandShelfLocations: ShelfLocation[] = [];
    // duplicate SLs
    shelfLocationsToInsert.forEach(sl => {
      let numberOfNewShelfLocations = data.shelfLocationSalesPerformance ? data.shelfLocationSalesPerformance - 1 : 0;
      let insertedNewShelfLocations = 1;

      // each shelf location, we readd it {numberOfNewShelfLocations} times
      while (numberOfNewShelfLocations > 0) {
        const newSl = classToClass(sl);
        newSl.id = Guid.create().toString();
        newSl.geometry.calculatePointsPosition();

        // Todo: The next line produce eslint warning. Please, fix it. I can't. don't understand it

        // eslint-disable-next-line
        newSl.geometry.points.forEach(point => {
          // move every point to the right
          point.x += sl.geometry.originalWidth * insertedNewShelfLocations;
        });
        if (numberOfNewShelfLocations < 1) {
          // if it is the last shelf location, it might need to be cut by width.
          cutByWidth([newSl], numberOfNewShelfLocations);
        }
        insertedNewShelfLocations++;
        numberOfNewShelfLocations--;
        expandShelfLocations.push(newSl);
      }
    });
    commands.push(new InsertCommand(planogram, expandShelfLocations));
  } else if (data.shelfLocationSalesPerformance === 0) {
    commands.push(new DeleteCommand(planogram, shelfLocationsToInsert));
  } else {
    // when salesResizePercent is >0 && <1
    cutByWidth(shelfLocationsToInsert, data.shelfLocationSalesPerformance);
    commands.push(new MoveCommand(planogram, shelfLocationsToInsert));
  }

  return commands;
}

export function resizeOnSales(planogram: PlanogramModel, initialPlanogram: PlanogramModel, data: FlowDataShelfLocationReportResult[]): PlanogramUndoableCommand[] {
  const commands = data.map(d => {
    return generateForProduct(planogram, initialPlanogram, d);
  });
  return flatten(commands);
}
