import * as React from 'react';
import { Table, TableHead, Typography, TableBody } from '@material-ui/core';
import { StyledTableRow, StyledTableCell } from 'scss/MaterialStyles';
import { useState } from 'react';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import ProjectStorePhaseShelfPlanograms from '../types/ProjectStorePhaseShelfPlanograms';
import ShelvesRow from './ShelvesRow';
import orderBy from 'lodash/orderBy';

interface MyProps {
  shelves: ProjectStorePhaseShelfPlanograms[];
  userId: number;
}

const ShelvesTable: React.FC<MyProps> = (props: MyProps): JSX.Element => {
  const [orderByProperty, setOrderByProperty] = useState('projectName');
  const [orderByDescending, setOrderByDescending] = useState(false);

  const changeOrderBy = (propertyName: string): void => {
    const descending = orderByProperty === propertyName ? !orderByDescending : false;
    setOrderByDescending(descending);
    setOrderByProperty(propertyName);
  };

  const getOrderByIcon = (propertyName: string): JSX.Element | null => {
    if (orderByProperty !== propertyName) {
      return null;
    }

    if (orderByDescending) {
      return <ArrowDropDown />;
    } else {
      return <ArrowDropUp />;
    }
  };

  const shelves = orderBy(props.shelves, [orderByProperty], [orderByDescending ? 'desc' : 'asc']);

  return (
    <Table size="small" className="sticky-header-table">
      <TableHead>
        <StyledTableRow>
          <StyledTableCell style={{ cursor: 'pointer' }} align="center" onClick={(): void => changeOrderBy('projectName')}>
            <Typography variant="subtitle1">
              Project &nbsp;
              {getOrderByIcon('projectName')}
            </Typography>
          </StyledTableCell>
          <StyledTableCell style={{ cursor: 'pointer' }} align="center" onClick={(): void => changeOrderBy('storeName')}>
            <Typography variant="subtitle1">
              Store
              {getOrderByIcon('storeName')}
            </Typography>
          </StyledTableCell>
          <StyledTableCell style={{ cursor: 'pointer' }} align="center" onClick={(): void => changeOrderBy('phaseNum')}>
            <Typography variant="subtitle1">
              Phase
              {getOrderByIcon('phaseNum')}
            </Typography>
          </StyledTableCell>
          <StyledTableCell style={{ cursor: 'pointer' }} align="center" onClick={(): void => changeOrderBy('shelfNum')}>
            <Typography variant="subtitle1">
              Shelf
              {getOrderByIcon('shelfNum')}
            </Typography>
          </StyledTableCell>
          <StyledTableCell align="center">
            <Typography variant="subtitle1">Original Shelf</Typography>
          </StyledTableCell>
          <StyledTableCell align="center">
            <Typography variant="subtitle1">Planograms</Typography>
          </StyledTableCell>
        </StyledTableRow>
      </TableHead>
      <TableBody>
        {shelves.map(row => (
          <ShelvesRow key={row.shelfId} shelf={row} userId={props.userId} />
        ))}
      </TableBody>
    </Table>
  );
};

export default ShelvesTable;
