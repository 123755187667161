import React from 'react';
import { AggregatedOnePagerReport, OnePagerReport } from '../types';
import flowLogo from '../../../../assets/img/brand/flow.jpg';
import './styles/AggregatedReportStyle.css';
export interface AggregatedOnePagerPageProps {
  report: AggregatedOnePagerReport;
}

function toPretti(value: string): string {
  const result = value.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}

const AggregatedOnePagerPage: React.FC<AggregatedOnePagerPageProps> = ({ report }: AggregatedOnePagerPageProps) => {
  const renderOnePager = (page: OnePagerReport, requestId: number): JSX.Element => {
    const interactorsPart = page.interactors < 20 ? 'and less than 20 interactions' : 'and 20 or more interactions';
    return (
      <div className="page">
        <div id="flowLogo">
          <img alt="flow logo" className="img-responsive" src={flowLogo} />
        </div>
        <div className="report-content">
          <div>
            <p className="text-header text-center">INSIGHTS BANK: EXCLUSIVE KPI'S FROM REAL WORLD DATA</p>

            <p className="text-title text-center">{toPretti(page.metric as unknown as string)}</p>
          </div>
          <div>
            <p className="text-medium-bold text-center">Question:</p>

            <p className="text-medium text-center">{page.question}</p>

            <p className="text-important text-center">{page.productName}</p>
            {page.imageUrl && (
              <div className="row" style={{ margin: '10px' }}>
                <img alt="product img" className="img-responsive productImg" src={page.imageUrl} />
              </div>
            )}

            <p className="text-important text-center">Answer: {page.answer}</p>
          </div>
          <div>
            <p className="text-medium-cursive text-center">{page.description}</p>

            <p className="text-small text-center">
              Answer is based on {page.shoppers} shoppers in front of the shelf {interactorsPart}
            </p>
          </div>
        </div>
        <footer className="footer top-buffer">
          <strong>Copyright &copy; {new Date().getFullYear()} Flow Insights</strong>. Strictly confidential. No distribution allowed. All rights reserved. ({requestId})
        </footer>
      </div>
    );
  };

  return (
    <>
      <div className="document">
        {report.reports.map(r => {
          return renderOnePager(r, report.requestId);
        })}
      </div>
    </>
  );
};

export { AggregatedOnePagerPage };
