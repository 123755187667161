export interface LiteEventInterface<T> {
  on(handler: (data?: T) => void): void;
  off(handler: (data?: T) => void): void;
}

export class LiteEvent<T> implements LiteEventInterface<T> {
  private handlers: ((data?: T) => void)[] = [];

  on(handler: (data?: T) => void): void {
    this.handlers.push(handler);
  }

  off(handler: (data?: T) => void): void {
    this.handlers = this.handlers.filter(h => h !== handler);
  }

  trigger(data?: T) {
    this.handlers.slice(0).forEach(h => h(data));
  }

  expose(): LiteEventInterface<T> {
    return this;
  }
}
