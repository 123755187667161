import ErrorDetails from 'infrastructure/exception/ErrorDetails';
import { setError, setIdle, setLoading, setSuccess } from 'infrastructure/utils/RemoteObjectStatus';
import { ActionType, Reducer } from 'typesafe-actions';
import * as actions from './actions';
import StandardReportsState from './state';
import {
  BrandComparisonReportResult,
  BrandReportResult,
  ClientBenchmarkReportResult,
  GpcBrickComparisonReportResult,
  GpcBrickReportResult,
  ProductComparisonReportResult,
  ProductReportResult,
  ProjectBenchmarkReportResult,
  ProjectComparisonReportResult,
  ProjectReportResult,
  ShelfComparisonReportResult,
  ShopperComparisonReportResult,
  ShopperReportResult,
  StandardReportPhaseShelf,
  BrandPotentialReport,
  ProductBenchmarkReport,
} from './types';

type Actions = ActionType<typeof actions>;

export const INITIAL_STATE: StandardReportsState = {
  projectStorePhaseShelvesObject: setIdle<StandardReportPhaseShelf[]>(),
  standardProjectReportStatus: setIdle<ProjectReportResult>(),
  standardShopperReportStatus: setIdle<ShopperReportResult>(),
  standardBrandReportStatus: setIdle<BrandReportResult>(),
  standardProductReportStatus: setIdle<ProductReportResult>(),
  standardGpcBrickReportStatus: setIdle<GpcBrickReportResult>(),
  shelfComparisonReportObject: setIdle<ShelfComparisonReportResult>(),
  projectComparisonReportObject: setIdle<ProjectComparisonReportResult>(),
  shopperComparisonReportObject: setIdle<ShopperComparisonReportResult>(),
  productComparisonReportObject: setIdle<ProductComparisonReportResult>(),
  brandComparisonReportObject: setIdle<BrandComparisonReportResult>(),
  gpcBrickComparisonReportObject: setIdle<GpcBrickComparisonReportResult>(),
  projectBenchmarkReportStatus: setIdle<ProjectBenchmarkReportResult>(),
  clientBenchmarkReportStatus: setIdle<ClientBenchmarkReportResult>(),
  potentialBrandReportStatus: setIdle<BrandPotentialReport>(),
  productBenchmarkReportStatus: setIdle<ProductBenchmarkReport>(),
};

// shelf selector handlers
function loadPhaseShelvesRequestHandler(state: StandardReportsState): StandardReportsState {
  return { ...state, projectStorePhaseShelvesObject: setLoading() };
}

function loadPhaseShelvesSuccessHandler(state: StandardReportsState, data: StandardReportPhaseShelf[]): StandardReportsState {
  return { ...state, projectStorePhaseShelvesObject: setSuccess(data) };
}

function loadPhaseShelvesFailureHandler(state: StandardReportsState, error: ErrorDetails): StandardReportsState {
  return { ...state, projectStorePhaseShelvesObject: setError(error) };
}

// project report handlers
function loadStandardProjectReportRequestHandler(state: StandardReportsState): StandardReportsState {
  return { ...state, standardProjectReportStatus: setLoading() };
}

function loadStandardProjectReportSuccessHandler(state: StandardReportsState, shelfPhaseId: string, data: ProjectReportResult): StandardReportsState {
  return { ...state, standardProjectReportStatus: setSuccess(data) };
}
function loadStandardProjectReportFailureHandler(state: StandardReportsState, shelfPhaseId: string, error: ErrorDetails): StandardReportsState {
  return { ...state, standardProjectReportStatus: setError(error) };
}

// shopper report handlers
function loadStandardShopperReportRequestHandler(state: StandardReportsState): StandardReportsState {
  return { ...state, standardShopperReportStatus: setLoading() };
}

function loadStandardShopperReportSuccessHandler(state: StandardReportsState, shelfPhaseId: string, data: ShopperReportResult): StandardReportsState {
  return { ...state, standardShopperReportStatus: setSuccess(data) };
}
function loadStandardShopperReportFailureHandler(state: StandardReportsState, shelfPhaseId: string, error: ErrorDetails): StandardReportsState {
  return { ...state, standardShopperReportStatus: setError(error) };
}
// brand report handlers
function loadStandardBrandReportRequestHandler(state: StandardReportsState): StandardReportsState {
  return { ...state, standardBrandReportStatus: setLoading() };
}

function loadStandardBrandReportSuccessHandler(state: StandardReportsState, shelfPhaseId: string, brandId: number, data: BrandReportResult): StandardReportsState {
  return { ...state, standardBrandReportStatus: setSuccess(data) };
}
function loadStandardBrandReportFailureHandler(state: StandardReportsState, shelfPhaseId: string, brandId: number, error: ErrorDetails): StandardReportsState {
  return { ...state, standardBrandReportStatus: setError(error) };
}

// gpcBrick report handlers
function loadStandardGpcBrickReportRequestHandler(state: StandardReportsState): StandardReportsState {
  return { ...state, standardGpcBrickReportStatus: setLoading() };
}

function loadStandardGpcBrickReportSuccessHandler(state: StandardReportsState, shelfPhaseId: string, gpcBrickCode: number, data: GpcBrickReportResult): StandardReportsState {
  return { ...state, standardGpcBrickReportStatus: setSuccess(data) };
}
function loadStandardGpcBrickReportFailureHandler(state: StandardReportsState, shelfPhaseId: string, gpcBrickCode: number, error: ErrorDetails): StandardReportsState {
  return { ...state, standardGpcBrickReportStatus: setError(error) };
}

// product report handlers
function loadStandardProductReportRequestHandler(state: StandardReportsState): StandardReportsState {
  return { ...state, standardProductReportStatus: setLoading() };
}

function loadStandardProductReportSuccessHandler(state: StandardReportsState, shelfPhaseId: string, gtin: number, data: ProductReportResult): StandardReportsState {
  return { ...state, standardProductReportStatus: setSuccess(data) };
}
function loadStandardProductReportFailureHandler(state: StandardReportsState, shelfPhaseId: string, gtin: number, error: ErrorDetails): StandardReportsState {
  return { ...state, standardProductReportStatus: setError(error) };
}
// shelf changes report handlers
function loadStandardShelfComparisonReportRequestHandler(state: StandardReportsState): StandardReportsState {
  return { ...state, shelfComparisonReportObject: setLoading() };
}

function loadStandardShelfComparisonReportSuccessHandler(state: StandardReportsState, data: ShelfComparisonReportResult): StandardReportsState {
  return { ...state, shelfComparisonReportObject: setSuccess(data) };
}
function loadStandardShelfComparisonReportFailureHandler(state: StandardReportsState, error: ErrorDetails): StandardReportsState {
  return { ...state, shelfComparisonReportObject: setError(error) };
}

// project comparison report handlers
function loadStandardProjectComparisonReportRequestHandler(state: StandardReportsState): StandardReportsState {
  return { ...state, projectComparisonReportObject: setLoading() };
}

function loadStandardProjectComparisonReportSuccessHandler(state: StandardReportsState, data: ProjectComparisonReportResult): StandardReportsState {
  return { ...state, projectComparisonReportObject: setSuccess(data) };
}
function loadStandardProjectComparisonReportFailureHandler(state: StandardReportsState, error: ErrorDetails): StandardReportsState {
  return { ...state, projectComparisonReportObject: setError(error) };
}

// shopper comparison report handlers
function loadStandardShopperComparisonReportRequestHandler(state: StandardReportsState): StandardReportsState {
  return { ...state, shopperComparisonReportObject: setLoading() };
}

function loadStandardShopperComparisonReportSuccessHandler(state: StandardReportsState, data: ShopperComparisonReportResult): StandardReportsState {
  return { ...state, shopperComparisonReportObject: setSuccess(data) };
}
function loadStandardShopperComparisonReportFailureHandler(state: StandardReportsState, error: ErrorDetails): StandardReportsState {
  return { ...state, shopperComparisonReportObject: setError(error) };
}

// product comparison report handlers
function loadStandardProductComparisonReportRequestHandler(state: StandardReportsState): StandardReportsState {
  return { ...state, productComparisonReportObject: setLoading() };
}

function loadStandardProductComparisonReportSuccessHandler(state: StandardReportsState, data: ProductComparisonReportResult): StandardReportsState {
  return { ...state, productComparisonReportObject: setSuccess(data) };
}
function loadStandardProductComparisonReportFailureHandler(state: StandardReportsState, error: ErrorDetails): StandardReportsState {
  return { ...state, productComparisonReportObject: setError(error) };
}

// brand comparison report handlers
function loadStandardBrandComparisonReportRequestHandler(state: StandardReportsState): StandardReportsState {
  return { ...state, brandComparisonReportObject: setLoading() };
}

function loadStandardBrandComparisonReportSuccessHandler(state: StandardReportsState, data: BrandComparisonReportResult): StandardReportsState {
  return { ...state, brandComparisonReportObject: setSuccess(data) };
}
function loadStandardBrandComparisonReportFailureHandler(state: StandardReportsState, error: ErrorDetails): StandardReportsState {
  return { ...state, brandComparisonReportObject: setError(error) };
}
// gpcBrick comparison report handlers
function loadStandardGpcBrickComparisonReportRequestHandler(state: StandardReportsState): StandardReportsState {
  return { ...state, gpcBrickComparisonReportObject: setLoading() };
}

function loadStandardGpcBrickComparisonReportSuccessHandler(state: StandardReportsState, data: GpcBrickComparisonReportResult): StandardReportsState {
  return { ...state, gpcBrickComparisonReportObject: setSuccess(data) };
}
function loadStandardGpcBrickComparisonReportFailureHandler(state: StandardReportsState, error: ErrorDetails): StandardReportsState {
  return { ...state, gpcBrickComparisonReportObject: setError(error) };
}
// project benchmark report handlers
function loadStandardProjectBenchmarkReportRequestHandler(state: StandardReportsState): StandardReportsState {
  return { ...state, projectBenchmarkReportStatus: setLoading() };
}

function loadStandardProjectBenchmarkReportSuccessHandler(state: StandardReportsState, projectId: number, data: ProjectBenchmarkReportResult): StandardReportsState {
  return { ...state, projectBenchmarkReportStatus: setSuccess(data) };
}
function loadStandardProjectBenchmarkReportFailureHandler(state: StandardReportsState, projectId: number, error: ErrorDetails): StandardReportsState {
  return { ...state, projectBenchmarkReportStatus: setError(error) };
}

// client benchmark report handlers
function loadStandardClientBenchmarkReportRequestHandler(state: StandardReportsState): StandardReportsState {
  return { ...state, clientBenchmarkReportStatus: setLoading() };
}

function loadStandardClientBenchmarkReportSuccessHandler(state: StandardReportsState, clientId: number, data: ClientBenchmarkReportResult): StandardReportsState {
  return { ...state, clientBenchmarkReportStatus: setSuccess(data) };
}
function loadStandardClientBenchmarkReportFailureHandler(state: StandardReportsState, clientId: number, error: ErrorDetails): StandardReportsState {
  return { ...state, clientBenchmarkReportStatus: setError(error) };
}

// Brand potential Report

function loadPotentialBrandReportRequestHandler(state: StandardReportsState): StandardReportsState {
  return { ...state, potentialBrandReportStatus: setLoading() };
}

function loadPotentialBrandReportSuccessHandler(state: StandardReportsState, shelfPhaseId: string, brandId: number, data: BrandPotentialReport): StandardReportsState {
  return { ...state, potentialBrandReportStatus: setSuccess(data) };
}
function loadPotentialBrandReportFailureHandler(state: StandardReportsState, shelfPhaseId: string, brandId: number, error: ErrorDetails): StandardReportsState {
  return { ...state, potentialBrandReportStatus: setError(error) };
}

// Product Benchmark Report
function loadStandardProductBenchmarkReportRequestHandler(state: StandardReportsState): StandardReportsState {
  return { ...state, productBenchmarkReportStatus: setLoading() };
}

function loadStandardProductBenchmarkReportSuccessHandler(
  state: StandardReportsState,
  targetShelfPhaseId: string,
  comparisonShelfPhaseId: string,
  targetGtin: number,
  data: ProductBenchmarkReport
): StandardReportsState {
  return { ...state, productBenchmarkReportStatus: setSuccess(data) };
}
function loadStandardProductBenchmarkReportFailureHandler(
  state: StandardReportsState,
  targetShelfPhaseId: string,
  comparisonShelfPhaseId: string,
  targetGtin: number,
  error: ErrorDetails
): StandardReportsState {
  return { ...state, productBenchmarkReportStatus: setError(error) };
}

const reducer: Reducer<StandardReportsState, Actions> = (state: StandardReportsState = INITIAL_STATE, action: Actions) => {
  switch (action.type) {
    // shelf selector actions
    case 'STANDARD_REPORTS_LOAD_PHASE_SHELVES_REQUEST':
      return loadPhaseShelvesRequestHandler(state);
    case 'STANDARD_REPORTS_LOAD_PHASE_SHELVES_SUCCESS':
      return loadPhaseShelvesSuccessHandler(state, action.data);
    case 'STANDARD_REPORTS_LOAD_PHASE_SHELVES_FAILURE':
      return loadPhaseShelvesFailureHandler(state, action.error);

    // project report actions
    case 'STANDARD_REPORTS_LOAD_PROJECT_REPORT_REQUEST':
      return loadStandardProjectReportRequestHandler(state);
    case 'STANDARD_REPORTS_LOAD_PROJECT_REPORT_SUCCESS':
      return loadStandardProjectReportSuccessHandler(state, action.shelfPhaseId, action.data);
    case 'STANDARD_REPORTS_LOAD_PROJECT_REPORT_FAILURE':
      return loadStandardProjectReportFailureHandler(state, action.shelfPhaseId, action.error);

    // shopper report actions
    case 'STANDARD_REPORTS_LOAD_SHOPPER_REPORT_REQUEST':
      return loadStandardShopperReportRequestHandler(state);
    case 'STANDARD_REPORTS_LOAD_SHOPPER_REPORT_SUCCESS':
      return loadStandardShopperReportSuccessHandler(state, action.shelfPhaseId, action.data);
    case 'STANDARD_REPORTS_LOAD_SHOPPER_REPORT_FAILURE':
      return loadStandardShopperReportFailureHandler(state, action.shelfPhaseId, action.error);

    // brand report actions
    case 'STANDARD_REPORTS_LOAD_BRAND_REPORT_REQUEST':
      return loadStandardBrandReportRequestHandler(state);
    case 'STANDARD_REPORTS_LOAD_BRAND_REPORT_SUCCESS':
      return loadStandardBrandReportSuccessHandler(state, action.shelfPhaseId, action.brandId, action.data);
    case 'STANDARD_REPORTS_LOAD_BRAND_REPORT_FAILURE':
      return loadStandardBrandReportFailureHandler(state, action.shelfPhaseId, action.brandId, action.error);

    // gpcBrick report actions
    case 'STANDARD_REPORTS_LOAD_GPC_BRICK_REPORT_REQUEST':
      return loadStandardGpcBrickReportRequestHandler(state);
    case 'STANDARD_REPORTS_LOAD_GPC_BRICK_REPORT_SUCCESS':
      return loadStandardGpcBrickReportSuccessHandler(state, action.shelfPhaseId, action.gpcBrickCode, action.data);
    case 'STANDARD_REPORTS_LOAD_GPC_BRICK_REPORT_FAILURE':
      return loadStandardGpcBrickReportFailureHandler(state, action.shelfPhaseId, action.gpcBrickCode, action.error);

    // product report actions
    case 'STANDARD_REPORTS_LOAD_PRODUCT_REPORT_REQUEST':
      return loadStandardProductReportRequestHandler(state);
    case 'STANDARD_REPORTS_LOAD_PRODUCT_REPORT_SUCCESS':
      return loadStandardProductReportSuccessHandler(state, action.shelfPhaseId, action.gtin, action.data);
    case 'STANDARD_REPORTS_LOAD_PRODUCT_REPORT_FAILURE':
      return loadStandardProductReportFailureHandler(state, action.shelfPhaseId, action.gtin, action.error);

    // shelf comparison report actions
    case 'STANDARD_REPORTS_LOAD_SHELF_COMPARISON_REPORT_REQUEST':
      return loadStandardShelfComparisonReportRequestHandler(state);
    case 'STANDARD_REPORTS_LOAD_SHELF_COMPARISON_REPORT_SUCCESS':
      return loadStandardShelfComparisonReportSuccessHandler(state, action.data);
    case 'STANDARD_REPORTS_LOAD_SHELF_COMPARISON_REPORT_FAILURE':
      return loadStandardShelfComparisonReportFailureHandler(state, action.error);

    // project comparison report actions
    case 'STANDARD_REPORTS_LOAD_PROJECT_COMPARISON_REPORT_REQUEST':
      return loadStandardProjectComparisonReportRequestHandler(state);
    case 'STANDARD_REPORTS_LOAD_PROJECT_COMPARISON_REPORT_SUCCESS':
      return loadStandardProjectComparisonReportSuccessHandler(state, action.data);
    case 'STANDARD_REPORTS_LOAD_PROJECT_COMPARISON_REPORT_FAILURE':
      return loadStandardProjectComparisonReportFailureHandler(state, action.error);

    // shopper comparison report actions
    case 'STANDARD_REPORTS_LOAD_SHOPPER_COMPARISON_REPORT_REQUEST':
      return loadStandardShopperComparisonReportRequestHandler(state);
    case 'STANDARD_REPORTS_LOAD_SHOPPER_COMPARISON_REPORT_SUCCESS':
      return loadStandardShopperComparisonReportSuccessHandler(state, action.data);
    case 'STANDARD_REPORTS_LOAD_SHOPPER_COMPARISON_REPORT_FAILURE':
      return loadStandardShopperComparisonReportFailureHandler(state, action.error);

    // product comparison report actions
    case 'STANDARD_REPORTS_LOAD_PRODUCT_COMPARISON_REPORT_REQUEST':
      return loadStandardProductComparisonReportRequestHandler(state);
    case 'STANDARD_REPORTS_LOAD_PRODUCT_COMPARISON_REPORT_SUCCESS':
      return loadStandardProductComparisonReportSuccessHandler(state, action.data);
    case 'STANDARD_REPORTS_LOAD_PRODUCT_COMPARISON_REPORT_FAILURE':
      return loadStandardProductComparisonReportFailureHandler(state, action.error);

    // brand comparison report actions
    case 'STANDARD_REPORTS_LOAD_BRAND_COMPARISON_REPORT_REQUEST':
      return loadStandardBrandComparisonReportRequestHandler(state);
    case 'STANDARD_REPORTS_LOAD_BRAND_COMPARISON_REPORT_SUCCESS':
      return loadStandardBrandComparisonReportSuccessHandler(state, action.data);
    case 'STANDARD_REPORTS_LOAD_BRAND_COMPARISON_REPORT_FAILURE':
      return loadStandardBrandComparisonReportFailureHandler(state, action.error);

    // gpcBrick comparison report actions
    case 'STANDARD_REPORTS_LOAD_GPC_BRICK_COMPARISON_REPORT_REQUEST':
      return loadStandardGpcBrickComparisonReportRequestHandler(state);
    case 'STANDARD_REPORTS_LOAD_GPC_BRICK_COMPARISON_REPORT_SUCCESS':
      return loadStandardGpcBrickComparisonReportSuccessHandler(state, action.data);
    case 'STANDARD_REPORTS_LOAD_GPC_BRICK_COMPARISON_REPORT_FAILURE':
      return loadStandardGpcBrickComparisonReportFailureHandler(state, action.error);
    // project benchmark report actions
    case 'STANDARD_REPORTS_LOAD_PROJECT_BENCHMARK_REPORT_REQUEST':
      return loadStandardProjectBenchmarkReportRequestHandler(state);
    case 'STANDARD_REPORTS_LOAD_PROJECT_BENCHMARK_REPORT_SUCCESS':
      return loadStandardProjectBenchmarkReportSuccessHandler(state, action.projectId, action.data);
    case 'STANDARD_REPORTS_LOAD_PROJECT_BENCHMARK_REPORT_FAILURE':
      return loadStandardProjectBenchmarkReportFailureHandler(state, action.projectId, action.error);

    // client benchmark report actions
    case 'STANDARD_REPORTS_LOAD_CLIENT_BENCHMARK_REPORT_REQUEST':
      return loadStandardClientBenchmarkReportRequestHandler(state);
    case 'STANDARD_REPORTS_LOAD_CLIENT_BENCHMARK_REPORT_SUCCESS':
      return loadStandardClientBenchmarkReportSuccessHandler(state, action.clientId, action.data);
    case 'STANDARD_REPORTS_LOAD_CLIENT_BENCHMARK_REPORT_FAILURE':
      return loadStandardClientBenchmarkReportFailureHandler(state, action.clientId, action.error);

    case 'POTENTIAL_REPORTS_LOAD_BRAND_REPORT_REQUEST':
      return loadPotentialBrandReportRequestHandler(state);
    case 'POTENTIAL_REPORTS_LOAD_BRAND_REPORT_SUCCESS':
      return loadPotentialBrandReportSuccessHandler(state, action.shelfPhaseId, action.brandId, action.data);
    case 'POTENTIAL_REPORTS_LOAD_BRAND_REPORT_FAILURE':
      return loadPotentialBrandReportFailureHandler(state, action.shelfPhaseId, action.brandId, action.error);

    // client benchmark report actions
    case 'STANDARD_REPORTS_LOAD_PRODUCT_BENCHMARK_REPORT_REQUEST':
      return loadStandardProductBenchmarkReportRequestHandler(state);
    case 'STANDARD_REPORTS_LOAD_PRODUCT_BENCHMARK_REPORT_SUCCESS':
      return loadStandardProductBenchmarkReportSuccessHandler(state, action.targetShelfPhaseId, action.comparisonShelfPhaseId, action.targetGtin, action.data);
    case 'STANDARD_REPORTS_LOAD_PRODUCT_BENCHMARK_REPORT_FAILURE':
      return loadStandardProductBenchmarkReportFailureHandler(state, action.targetShelfPhaseId, action.comparisonShelfPhaseId, action.targetGtin, action.error);

    default:
      return state;
  }
};
export default reducer;
