import { Type } from 'class-transformer';

export default class ShelfViewModel {
  projectName = '';
  shelfReferenceId = '';
  storeName = 0;
  phaseNum = 0;
  shelfNum = 0;
  shelfImageUrl = '';
  readyForReporting = false;
  isMappingQualityAssured = false;

  @Type(() => Date)
  startDate: Date = new Date();

  @Type(() => Date)
  endDate: Date = new Date();

  constructor() {
    this.startDate = undefined as any;
    this.endDate = undefined as any;
  }
}
