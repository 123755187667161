import * as React from 'react';
import ShelfLocation from 'modules/planogram/domain/ShelfLocation';

import ShadowShelfLocation from './ShadowShelfLocation';

interface ListProps {
  shelfLocations: ShelfLocation[];
}

const ShadowShelfLocationsList: React.FC<ListProps> = ({ shelfLocations }: ListProps): JSX.Element => {
  return (
    <>
      {shelfLocations.map(sl => (
        <ShadowShelfLocation sl={sl} key={`shadow-sl-${sl.id}`} />
      ))}
    </>
  );
};

export default ShadowShelfLocationsList;
