import React from 'react';

import { getNeedsDesignOrPriceChangeGtinArr } from '../../../../../../selectors';
import useHighLightedGtin from '../useHighLightedGtin';
import SelectBtn from '../SelectBtn';

const DesignPriceChangeBtn: React.FC = (): JSX.Element => {
  const props = useHighLightedGtin(getNeedsDesignOrPriceChangeGtinArr, 'DesignPriceChangeBtn');

  return <SelectBtn {...props} />;
};

export default DesignPriceChangeBtn;
