import * as React from 'react';
import trackComponent from 'infrastructure/tracking/withTracking';
import { Redirect } from 'react-router';

class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/*<h3>Welcome to FLOW Planogram!</h3>*/}
        {/*<h6>Please choose a menu from the left.</h6>*/}
        <Redirect to="/planograms" />
      </React.Fragment>
    );
  }
}

export default trackComponent(Home, 'Home');
