import ShelfLocationGeometryPoint from './ShelfLocationGeometryPoint';
import { Type } from 'class-transformer';
import PointPosition from './PointPosition';
import { find, without } from 'lodash';
import GeometryPoint from '../../shared/geometry/GeometryPoint';
import * as geometryFunctions from 'modules/shared/geometry/helpers';
import { calculateRectangleArea, getLeftTopMostPoint, getRightTopMostPoint, getLeftBottomMostPoint, getRightBottomMostPoint } from '../helpers/geometry';

export class ShelfLocationGeometry {
  @Type(() => ShelfLocationGeometryPoint)
  points: ShelfLocationGeometryPoint[] = [];

  areaSquaredMeters = 0;

  /**
   * Flag pointing out whether the image of this shelf location should be scaled within the borders
   * of the shelf location (when true) or whether the image should keep its original width and height, and be covered with the
   * borders of the shelf location.
   */
  hasFixedImage = false;

  originalWidth: number;
  originalHeight: number;

  currentWidth = 0;
  currentHeight = 0;

  tempCurrentWidth: number;
  tempCurrentHeight: number;

  // The following properties show in px how much the width/height has expanded/shrinked compared to the original width/height.

  widthLeftChanged: number;
  widthRightChanged: number;

  tempWidthLeftChanged: number;
  tempWidthRightChanged: number;

  heightTopChanged: number;
  heightBottomChanged: number;

  tempHeightTopChanged: number;
  tempHeightBottomChanged: number;

  @Type(() => ShelfLocationGeometryPoint)
  topLeftPoint: ShelfLocationGeometryPoint = new ShelfLocationGeometryPoint();

  @Type(() => ShelfLocationGeometryPoint)
  topRightPoint: ShelfLocationGeometryPoint = new ShelfLocationGeometryPoint();

  @Type(() => ShelfLocationGeometryPoint)
  bottomLeftPoint: ShelfLocationGeometryPoint = new ShelfLocationGeometryPoint();

  @Type(() => ShelfLocationGeometryPoint)
  bottomRightPoint: ShelfLocationGeometryPoint = new ShelfLocationGeometryPoint();

  constructor() {
    // original is what was saved first
    this.originalWidth = 0;
    this.originalHeight = 0;

    // changed is how much in px the polygon has expanded/shrinked horizontally (width) and vertically (height)
    this.widthLeftChanged = 0;
    this.widthRightChanged = 0;
    this.heightTopChanged = 0;
    this.heightBottomChanged = 0;

    // temps are used to calculate the transient state, for ex. while user is still dragging the points.
    this.tempCurrentHeight = 0;
    this.tempCurrentWidth = 0;
    this.tempHeightBottomChanged = 0;
    this.tempHeightTopChanged = 0;
    this.tempWidthLeftChanged = 0;
    this.tempWidthRightChanged = 0;
  }

  getPointByPosition(position: PointPosition): ShelfLocationGeometryPoint {
    return find(this.points, { position }) as ShelfLocationGeometryPoint;
  }

  calculateArea(cmToPixelRatio: number): void {
    const currentPoints = this.points.map(p => p.getCurrentPoint());
    const area = calculateRectangleArea(currentPoints);
    const cubicCmToCubicMetersRatio = 10000.0;
    this.areaSquaredMeters = (area * Math.pow(cmToPixelRatio, 2)) / cubicCmToCubicMetersRatio;
  }

  calculateDraggingPoint(draggingPoint: ShelfLocationGeometryPoint): void {
    this.calculatePointsPosition();
    if (!draggingPoint) {
      return;
    }
    let horizontalMove = 0; // 1 for expand, -1 for shrink
    let verticalMove = 0; // 1 for expand, -1 for shrink

    const { isLeft, isRight, isTop, isBottom, width, prevWidth, height, prevHeight } = this.getDraggingPointState(draggingPoint);

    if (draggingPoint.xOffset < 0) {
      horizontalMove = isLeft ? 1 : -1;
    } else if (draggingPoint.xOffset > 0) {
      horizontalMove = isRight ? 1 : -1;
    }

    if (draggingPoint.yOffset < 0) {
      verticalMove = isTop ? 1 : -1;
    } else if (draggingPoint.yOffset > 0) {
      verticalMove = isBottom ? 1 : -1;
    }
    const widthDiff = Math.abs(width - prevWidth);
    const heightDiff = Math.abs(height - prevHeight);

    if (horizontalMove === 1) {
      // expand
      if (isRight) {
        this.tempWidthRightChanged = widthDiff;
      } else if (isLeft) {
        this.tempWidthLeftChanged = widthDiff;
      }
    } else if (horizontalMove === -1) {
      // shirnk
      if (isRight) {
        this.tempWidthRightChanged = -widthDiff;
      } else if (isLeft) {
        this.tempWidthLeftChanged = -widthDiff;
      }
    }

    if (verticalMove === 1) {
      if (isTop) {
        this.tempHeightTopChanged = heightDiff;
      } else if (isBottom) {
        this.tempHeightBottomChanged = heightDiff;
      }
    } else if (verticalMove === -1) {
      if (isTop) {
        this.tempHeightTopChanged = -heightDiff;
      } else if (isBottom) {
        this.tempHeightBottomChanged = -heightDiff;
      }
    }
    this.tempCurrentWidth = width;
    this.tempCurrentHeight = height;
  }

  calculatePointsPosition(): void {
    const topLeft = getLeftTopMostPoint(this.points);
    const topRight = getRightTopMostPoint(without(this.points, topLeft));
    const bottomLeft = getLeftBottomMostPoint(without(this.points, topLeft, topRight));
    const bottomRight = getRightBottomMostPoint(without(this.points, topLeft, topRight, bottomLeft));

    topLeft.position = PointPosition.TopLeft;
    topRight.position = PointPosition.TopRight;
    bottomLeft.position = PointPosition.BottomLeft;
    bottomRight.position = PointPosition.BottomRight;

    this.topLeftPoint = topLeft;
    this.topRightPoint = topRight;
    this.bottomLeftPoint = bottomLeft;
    this.bottomRightPoint = bottomRight;
  }

  applyMovingState(): void {
    this.points.forEach(p => {
      p.x += p.xOffset;
      p.y += p.yOffset;
      p.xOffset = 0;
      p.yOffset = 0;
      p.isBeingDragged = false;
    });

    this.currentHeight = this.tempCurrentHeight || this.currentHeight;
    this.currentWidth = this.tempCurrentWidth || this.currentWidth;
    this.heightBottomChanged += this.tempHeightBottomChanged;
    this.heightTopChanged += this.tempHeightTopChanged;
    this.widthLeftChanged += this.tempWidthLeftChanged;
    this.widthRightChanged += this.tempWidthRightChanged;

    this.tempCurrentHeight = 0;
    this.tempCurrentWidth = 0;
    this.tempHeightBottomChanged = 0;
    this.tempHeightTopChanged = 0;
    this.tempWidthLeftChanged = 0;
    this.tempWidthRightChanged = 0;
  }

  getPointsWithOffset(): GeometryPoint[] {
    return this.points.map(p => {
      return {
        x: p.x + p.xOffset,
        y: p.y + p.yOffset,
      } as GeometryPoint;
    });
  }

  private getDraggingPointState(
    draggingPoint: ShelfLocationGeometryPoint
  ): {
    prevWidth: number;
    prevHeight: number;
    width: number;
    height: number;
    isLeft: boolean;
    isRight: boolean;
    isTop: boolean;
    isBottom: boolean;
  } {
    const topLeft = this.getPointByPosition(PointPosition.TopLeft);
    const bottomLeft = this.getPointByPosition(PointPosition.BottomLeft);
    const topRight = this.getPointByPosition(PointPosition.TopRight);

    const prevWidth = geometryFunctions.getLineDistance(topLeft, topRight);
    const prevHeight = geometryFunctions.getLineDistance(topLeft, bottomLeft);

    const width = geometryFunctions.getLineDistance(topLeft.getCurrentPoint(), topRight.getCurrentPoint());
    const height = geometryFunctions.getLineDistance(topLeft.getCurrentPoint(), bottomLeft.getCurrentPoint());
    const position = draggingPoint.position;
    const isLeft = position === PointPosition.BottomLeft || position === PointPosition.TopLeft;
    const isRight = position === PointPosition.BottomRight || position === PointPosition.TopRight;
    const isTop = position === PointPosition.TopLeft || position === PointPosition.TopRight;
    const isBottom = position === PointPosition.BottomLeft || position === PointPosition.BottomRight;
    return {
      prevWidth,
      prevHeight,
      width,
      height,
      isLeft,
      isRight,
      isTop,
      isBottom,
    };
  }
}
