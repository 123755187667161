import ShelfLocation from '../domain/ShelfLocation';
import PlanogramModel from '../domain/PlanogramModel';
import { PlanogramCommand } from './_planogram_commands';
import PlanogramEventBase from '../events/PlanogramEventBase';

export default class ChangeShelfLocationImageUrlCommand extends PlanogramCommand {
  apiUrlPattern = '/planograms/{id}/shelf-locations/{shelfLocationId}/image-url';

  private planogram: PlanogramModel;
  private shelfLocation: ShelfLocation;
  private imageUrl: string;
  private imageWidth: number;
  private imageHeight: number;

  constructor(planogram: PlanogramModel, shelfLocation: ShelfLocation, imageUrl: string, imageWidth: number, imageHeight: number) {
    super();
    this.planogram = planogram;
    this.shelfLocation = shelfLocation;
    this.imageUrl = imageUrl;
    this.imageWidth = imageWidth;
    this.imageHeight = imageHeight;
    this.apiUrlPattern = this.apiUrlPattern.replace('{shelfLocationId}', this.shelfLocation.id);
  }

  execute(): PlanogramEventBase {
    return this.planogram.changeShelfLocationImageUrl(this.shelfLocation, this.imageUrl, this.imageWidth, this.imageHeight);
  }
}
