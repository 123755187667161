import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setCarouselIndex, setCarouselTime } from '../state/actions';
import { CarouselData } from '../state/selectors';

interface UseCarousel {
  loadingAllError: string;
  isLoadingAll: boolean;
  hasLoadedAll: boolean;
  handleOnPreloadAll: () => void;
  handleOnChanges: (e: React.SyntheticEvent, index: number) => void;
  loadedImages: {
    [i: string]: string[];
  };
  selectedIndex: number;
}
const useCarousel = (data: CarouselData): UseCarousel => {
  const { timesImagesData, sliderData } = data;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [loadedImages, setLoadedImages] = useState({});
  const [isLoadingAll, setIsLoadingAll] = useState(false);
  const [hasLoadedAll, setIsLoadedAll] = useState(false);
  const [loadingAllError, setLoadingAllError] = useState('');
  const dispatch = useDispatch();

  const handleOnChanges = (e: React.SyntheticEvent, index: number): void => {
    setSelectedIndex(index);
    const time = data.sliderData[index].time;
    dispatch(setCarouselTime(time));
    dispatch(setCarouselIndex(index));
  };

  useEffect(() => {
    let isSubScribed = true;
    const selectedTime = sliderData[selectedIndex].time;
    if (!loadedImages[selectedTime]) {
      const promises = timesImagesData[selectedTime].map(i => i.imgPromise);
      Promise.all(promises)
        .then(data => {
          if (isSubScribed) {
            setLoadedImages({
              ...loadedImages,
              ...{
                [selectedTime]: data,
              },
            });
          }
        })
        .catch(() => {
          if (isSubScribed) {
            setLoadedImages({
              ...loadedImages,
              ...{
                [selectedTime]: null,
              },
            });
          }
        });
    }
    return (): void => {
      isSubScribed = false;
    };
  }, [selectedIndex, sliderData, loadedImages, timesImagesData]);

  const handleOnPreloadAll = (): void => {
    setIsLoadingAll(true);
    setLoadingAllError('');
    const imagePromises = Object.keys(timesImagesData).reduce((acc, timestamp) => {
      const data = timesImagesData[timestamp].map(i => i.imgPromise);
      return [...acc, ...data];
    }, []);

    Promise.all(imagePromises)
      .then(data => {
        const loadedImages = Object.keys(timesImagesData).reduce((acc, timestamp) => {
          return {
            ...acc,
            ...{
              [timestamp]: timesImagesData[timestamp].map(img => {
                return data.find(url => img.imageUrl === url);
              }),
            },
          };
        }, {});
        setLoadedImages(loadedImages);
        setIsLoadingAll(false);
        setIsLoadedAll(true);
      })
      .catch(() => {
        setIsLoadingAll(false);
        setLoadingAllError('Sorry, can not load all images');
      });
  };
  return {
    loadingAllError,
    isLoadingAll,
    handleOnPreloadAll,
    handleOnChanges,
    loadedImages,
    selectedIndex,
    hasLoadedAll,
  };
};

export default useCarousel;
