import { createAction } from 'typesafe-actions';
import {
  INISPIRATION_IMAGES_FETCH,
  FetchInspirationImagesFailedAction,
  FetchInspirationImagesRequestAction,
  FetchInspirationImagesSuccessAction,
  FetchInspirationImagesRequestPayload,
  INISPIRATION_IMAGE_DELETE,
  DeleteInspirationImagesRequestAction,
  DeleteInspirationImagesPayload,
  DeleteInspirationImagesRequestMeta,
  DeleteInspirationImagesSuccessAction,
  INISPIRATION_IMAGE_UPDATE_DESCRIPTION,
  UpdateDescInspirationImageRequestAction,
  UpdateDescInspirationImageRequestPayload,
  UpdateDescInspirationImageRequestMeta,
  UpdateDescInspirationImageSuccessAction,
  UpdateDescInspirationImageSuccessPayload,
  UPLOAD_INSPIRATION_IMAGES,
  UploadInspirationImagesRequestCreator,
} from './types';
import ErrorDetails from '../../../infrastructure/exception/ErrorDetails';

export const fetchInspirationImagesRequest: FetchInspirationImagesRequestAction = createAction(INISPIRATION_IMAGES_FETCH.REQUEST)<string>();

export const fetchInspirationImagesSuccess: FetchInspirationImagesSuccessAction = createAction(INISPIRATION_IMAGES_FETCH.SUCCESS)<FetchInspirationImagesRequestPayload>();

export const fetchInspirationImagesFailed: FetchInspirationImagesFailedAction = createAction(INISPIRATION_IMAGES_FETCH.FAILED)<ErrorDetails>();

export const deleteInspirationImageRequest: DeleteInspirationImagesRequestAction = createAction(INISPIRATION_IMAGE_DELETE.REQUEST)<
  DeleteInspirationImagesPayload,
  DeleteInspirationImagesRequestMeta
>();

export const deleteInspirationImageSuccess: DeleteInspirationImagesSuccessAction = createAction(INISPIRATION_IMAGE_DELETE.SUCCESS)<DeleteInspirationImagesPayload>();

export const updateDescInspirationImageRequest: UpdateDescInspirationImageRequestAction = createAction(INISPIRATION_IMAGE_UPDATE_DESCRIPTION.REQUEST)<
  UpdateDescInspirationImageRequestPayload,
  UpdateDescInspirationImageRequestMeta
>();

export const updateDescInspirationImageSuccess: UpdateDescInspirationImageSuccessAction = createAction(INISPIRATION_IMAGE_UPDATE_DESCRIPTION.SUCCESS)<UpdateDescInspirationImageSuccessPayload>();

export const uploadInspirationImagesRequest: UploadInspirationImagesRequestCreator = createAction(UPLOAD_INSPIRATION_IMAGES.REQUEST)();
