import { createSelector, Selector } from 'reselect';
import { State } from 'state';
import { ShelfConditionImagesState } from 'modules/shelfTimelapse/state/types';
import { RemoteObjectStatusInterface } from 'infrastructure/utils/RemoteObjectStatus';
import { TimeSalesDataReport } from './types';

const getGlobalState: (state: State) => State = state => state;
const getState: (state: State) => ShelfConditionImagesState = state => state.shelfConditionImages;

export const getCanManageSalesData: Selector<State, boolean> = createSelector(getGlobalState, (state: State) => {
  return state.auth.applicationUser ? state.auth.applicationUser.isAdmin() : false;
});

export const getUploadSalesDataStatus: Selector<State, RemoteObjectStatusInterface<TimeSalesDataReport>> = createSelector(getState, (state: ShelfConditionImagesState) => {
  return state.salesDataUpload;
});

export const getAvailableSalesReports: Selector<State, TimeSalesDataReport[]> = createSelector(getState, (state: ShelfConditionImagesState) => {
  return state.availableSalesData;
});

export const getSelectedReport: Selector<State, string | null | undefined> = createSelector(getState, (state: ShelfConditionImagesState) => {
  return state.selectedSalesDataReport;
});
