import React, { useEffect, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

export interface ArrowControlsProps {
  selectedIndex: number;
  numberOfItem: number;
  handleOnChanges: (e: React.SyntheticEvent, index: number) => void;
}

const ArrowControls: React.FC<ArrowControlsProps> = ({ numberOfItem, selectedIndex, handleOnChanges }: ArrowControlsProps): JSX.Element => {
  const canBack = selectedIndex > 0;
  const canForward = selectedIndex + 1 < numberOfItem;

  const handleBack = useCallback(
    (e: React.SyntheticEvent): void => {
      canBack && handleOnChanges(e, selectedIndex - 1);
    },
    [canBack, handleOnChanges, selectedIndex]
  );

  const handleForward = useCallback(
    (e: React.SyntheticEvent): void => {
      canForward && handleOnChanges(e, selectedIndex + 1);
    },
    [selectedIndex, handleOnChanges, canForward]
  );

  const onDown = useCallback(
    e => {
      const event = (e as unknown) as React.KeyboardEvent<Document>;
      if (event.key === 'ArrowRight') {
        handleForward((e as unknown) as React.SyntheticEvent);
      }
      if (event.key === 'ArrowLeft') {
        handleBack((e as unknown) as React.SyntheticEvent);
      }
    },
    [handleBack, handleForward]
  );

  useEffect(() => {
    document.addEventListener('keydown', onDown);
    return (): void => {
      document.removeEventListener('keydown', onDown);
    };
  }, [selectedIndex, onDown]);

  return (
    <Grid container direction="row" justify="space-between">
      <Button onClick={handleBack} disabled={!canBack}>
        <ArrowBackIosIcon color="primary" />
      </Button>
      <Button onClick={handleForward} disabled={!canForward}>
        <ArrowForwardIosIcon color="primary" />
      </Button>
    </Grid>
  );
};

export default ArrowControls;
