import { classToClass } from 'class-transformer';
import { Guid } from 'guid-typescript';
import StackedCommandManager from 'infrastructure/commands/StackedCommandManager';
import CompetitorShelfDataset from 'modules/admin/planograms/types/CompetitorShelfDataset';
import createReducer from 'utils/createReducer';
import { Value } from 'react-svg-pan-zoom';
import ErrorDetails from '../../infrastructure/exception/ErrorDetails';
import { UserPreferencesDto } from '../auth/types/UserPreferencesDto';
import { ClientDatasetDto } from '../admin/clientDataset/types/ClientDatasetDto';
import InsertCommand from './commands/InsertCommand';
import * as constants from './constants/constants';
import PlanogramState from './state';
import { NewProductModel } from './types/NewProductModel';
import { RecordChangeReasonDto } from './types/PlanogramChangeReasons';
import { PlanogramChanges } from './types/PlanogramChanges';
import PlanogramModel from './domain/PlanogramModel';
import ProductModel from './types/ProductModel';
import { ProductTourState } from './types/ProductTourState';
import { FlowDataReportType } from './types/ReportingTypes';
import ShelfLocation from './domain/ShelfLocation';
import TestInfoDto from './types/TestInfoDto';
import {
  OnNewProductImageUploadSuccessAction,
  OnNewProductImageUploadErrorAction,
  OnImageLoadedAction,
  UpdatePlanogramViewPortAction,
  OnCommandExecutionRequestAction,
  OnSelectShelfLocationsRequest,
  OnPlanogramContextMenuOpenRequestAction,
  OnPlanogramSvgPanZoomValueUpdateRequestAction,
  OnZoomLevelAdjustmentRequestAction,
  OnFlowDataLoadedAction,
} from './types/ActionTypes';
import ViewPort from './types/ViewPort';
import { reject, last } from 'lodash';
import { setError, setIdle, setLoading, setSuccess } from 'infrastructure/utils/RemoteObjectStatus';
import { OnErrorRequestAction } from 'modules/shared/actions';

const initialState: PlanogramState = {
  loadPlanogramStatus: setIdle(),
  loadImageStatus: setIdle<ViewPort>(new ViewPort(100, 100)),
  planogramViewPort: new ViewPort(100, 100),
  selectedShelfLocations: [],
  isCopyingShelfLocations: false,
  copyingShelfLocations: [],
  loadNameUpdateStatus: setIdle(),
  loadDeleteStatus: setIdle(),
  loadSavingSalesDataStatus: setIdle(),
  uploadShelfLocationImageStatus: setIdle(),
  uploadNewProductImage: setIdle(),
  loadFixingImageSizeStatus: setIdle(),
  loadSettingShelfLocationGtinStatus: setIdle(),
  deleteSalesReportStatus: setIdle(),
  updateCommentsStatus: setIdle(),
  togglePublicShareOptionsStatus: setIdle(),
  loadCreatingTestPlanogramStatus: setIdle(),
  loadTestsInfoStatus: setIdle(),
  updateTestInfoStatus: setIdle(),
  deleteTestStatus: setIdle(),
  loadResetPlanogramStatus: setIdle(),
  userId: 0,
  refreshToggler: false,
  id: '',
  sharingToken: '',
  testPlanogramId: '',
  shelfReferenceId: '',
  enableShowProductInfo: true,
  enableSalesDataReport: false,
  enableFlowDataReport: true,
  enableClientDatasetReport: false,
  enableCompetitorShelfData: false,
  salesData: [],
  flowDataReport: setIdle(),
  drawingNewShelfLocation: false,
  creatingNewProduct: false,
  selectedFlowDataType: FlowDataReportType.ProductPickupCount,
  selectedSalesReport: undefined,
  selectedClientDatasetReport: undefined,
  showSharingModal: false,
  showTestWithFlowModal: false,
  showTestInfoModal: false,
  showDeleteTestConfirmationModal: false,
  showEditTestModal: false,
  paintInvalidShelfLocations: false,
  testsInfoList: [],
  testToEdit: new TestInfoDto(),
  testToDelete: new TestInfoDto(),
  showInitialPlanogram: false,
  commandManager: new StackedCommandManager(),
  planogramAsideActiveTab: 1,
  inUnstableState: false,
  gpcBrickFilterEnabled: false,
  gpcBrickCodeFilters: [],
  userPreferences: new UserPreferencesDto(),
  productTourState: new ProductTourState(),
  clientDatasets: [] as ClientDatasetDto[],
  planogramChanges: new PlanogramChanges(),
  showGetCompetitorModal: false,
  savingChanges: false,
  competitorShelfDataset: {} as CompetitorShelfDataset,
  zoomControlNumber: 0,
  resetZoomControlNumber: 0,
  isOpenRightContextMenu: false,
  contextMenuElement: undefined,
  svgPanZoomValue: {
    // this is SVG matrix value
    // a & d is scale
    // e is pan horizontal
    // f is pan vertical
    a: 1,
    d: 1,
    e: 0,
    f: 0,
  } as Value,
};

function forceUpdateHandler(state: PlanogramState): PlanogramState {
  return {
    ...state,
    refreshToggler: !state.refreshToggler,
  };
}

function planogramPageResetHandler(state: PlanogramState): PlanogramState {
  const userPreferences = state.userPreferences;
  return {
    ...initialState,
    userPreferences,
  };
}

function getFlowDataHandler(state: PlanogramState): PlanogramState {
  return {
    ...state,
    flowDataReport: setLoading('Loading flow data...'),
  };
}

function onFlowDataLoadedHandler(state: PlanogramState, action: OnFlowDataLoadedAction): PlanogramState {
  return {
    ...state,
    flowDataReport: setSuccess(action.data),
  };
}

function onFlowDataLoadFailedHandler(state: PlanogramState, action: OnErrorRequestAction): PlanogramState {
  return {
    ...state,
    flowDataReport: setError(action.errorDetails),
  };
}

function onPlanogramEvent(state: PlanogramState): PlanogramState {
  return {
    ...state,
  };
}

function errorLoadingPlanogram(state: PlanogramState, action: OnErrorRequestAction): PlanogramState {
  return {
    ...state,
    loadPlanogramStatus: setError(action.errorDetails),
  };
}

function errorLoadingPublicPlanogram(state: PlanogramState, action: OnErrorRequestAction): PlanogramState {
  return {
    ...state,
    loadPlanogramStatus: setError(action.errorDetails),
  };
}

function errorLoadingTestPlanogram(state: PlanogramState, action: OnErrorRequestAction): PlanogramState {
  return {
    ...state,
    loadPlanogramStatus: setError(action.errorDetails),
  };
}

function planogramImageLoaded(state: PlanogramState, action: OnImageLoadedAction): PlanogramState {
  const imageViewPort = new ViewPort(action.imageWidth, action.imageHeight);
  const planogram = state.planogram;
  const initialPlanogram = state.initialPlanogram;
  if (planogram && initialPlanogram) {
    planogram.setImageViewPort(imageViewPort);
    initialPlanogram.setImageViewPort(imageViewPort);
    initialPlanogram.initialize(state.products as ProductModel[], initialPlanogram.shelfLocations, initialPlanogram.shelfWidthCm, initialPlanogram.shelfImageUrlWidth);
    planogram.initialize(
      state.products as ProductModel[],
      initialPlanogram.shelfLocations,
      initialPlanogram.shelfWidthCm,
      initialPlanogram.shelfImageUrlWidth,
      initialPlanogram.brandSpaces,
      initialPlanogram.totalArea
    );
  }

  return {
    ...state,
    loadImageStatus: setSuccess(imageViewPort),
  };
}

function planogramImageLoading(state: PlanogramState): PlanogramState {
  return {
    ...state,
    loadImageStatus: setLoading('Downloading Shelf image...'),
  };
}

function planogramImageLoadError(state: PlanogramState, action: any): PlanogramState {
  const errorDetails = action.errorDetails as ErrorDetails;
  return {
    ...state,
    loadImageStatus: setError(errorDetails),
  };
}

function planogramLoaded(state: PlanogramState, action: any): PlanogramState {
  const initialPlanogram = action.initialPlanogram as PlanogramModel;
  initialPlanogram.isReadOnly = true;
  const clientDatasets = action.clientDatasets as ClientDatasetDto[];
  return {
    ...state,
    loadPlanogramStatus: setSuccess({}),
    planogram: action.planogram,
    initialPlanogram,
    products: action.products,
    salesData: action.salesData,
    clientDatasets,
    flowDataReport: action.flowDataReport,
  };
}

function planogramLoading(state: PlanogramState): PlanogramState {
  return {
    ...state,
    loadImageStatus: setIdle(),
    planogram: undefined,
    loadPlanogramStatus: setLoading(),
  };
}

function updatePlanogramViewPortHandler(state: PlanogramState, action: UpdatePlanogramViewPortAction): PlanogramState {
  return {
    ...state,
    planogramViewPort: new ViewPort(action.width, action.height),
  };
}

function savingChangesHandler(state: PlanogramState, action: any): PlanogramState {
  const saving = action.saving as boolean;
  return {
    ...state,
    savingChanges: saving,
  };
}

function publicPlanogramLoaded(state: PlanogramState, action: any): PlanogramState {
  return {
    ...state,
    loadPlanogramStatus: setSuccess({}),
    planogram: action.planogram,
    initialPlanogram: action.initialPlanogram,
    products: action.products,
    flowDataReport: action.shelfLocationData,
  };
}

function publicPlanogramLoading(state: PlanogramState): PlanogramState {
  return {
    ...state,
    loadPlanogramStatus: setLoading('Loading planogram...'),
    planogram: undefined,
  };
}

function testPlanogramLoaded(state: PlanogramState, action: any): PlanogramState {
  return {
    ...state,
    loadPlanogramStatus: setSuccess({}),
    planogram: action.planogram,
    initialPlanogram: action.initialPlanogram,
    products: action.products,
    testInfo: action.testInfo,
    flowDataReport: action.shelfLocationData,
  };
}

function testPlanogramLoading(state: PlanogramState): PlanogramState {
  return {
    ...state,
    loadImageStatus: setIdle(),
    loadPlanogramStatus: setLoading('Loading planogram...'),
    planogram: undefined,
  };
}

// Load Competitor Planogram
function loadCompetitorPlanogramRequestHandler(state: PlanogramState): PlanogramState {
  return {
    ...state,
    loadImageStatus: setIdle(),
    loadPlanogramStatus: setLoading('Loading planogram...'),
    planogram: undefined,
  };
}

function loadCompetitorPlanogramSuccessHandler(state: PlanogramState, action: any): PlanogramState {
  return {
    ...state,
    loadPlanogramStatus: setSuccess({}),
    planogram: action.planogram,
    initialPlanogram: action.initialPlanogram,
    products: action.products,
    flowDataReport: action.shelfLocationData,
    competitorShelfDataset: action.competitorShelfDataset,
  };
}

function loadCompetitorPlanogramFailureHandler(state: PlanogramState, action: OnErrorRequestAction): PlanogramState {
  return {
    ...state,
    loadPlanogramStatus: setError(action.errorDetails),
  };
}

function changePlanogramName(state: PlanogramState): PlanogramState {
  return {
    ...state,
    loadNameUpdateStatus: setLoading('Updating ...'),
  };
}

function changePlanogramNameSuccess(state: PlanogramState, action: any): PlanogramState {
  const planogram = state.planogram as PlanogramModel;
  planogram.name = action.newName;
  return {
    ...state,
    planogram,
    loadNameUpdateStatus: setSuccess({}),
  };
}

function changePlanogramNameError(state: PlanogramState, action: OnErrorRequestAction): PlanogramState {
  return {
    ...state,
    loadNameUpdateStatus: setError(action.errorDetails),
  };
}

function deletePlanogramRequest(state: PlanogramState): PlanogramState {
  return {
    ...state,
    loadDeleteStatus: setLoading('Deleting...'),
  };
}

function deletePlanogramSuccess(state: PlanogramState): PlanogramState {
  return {
    ...state,
    loadDeleteStatus: setSuccess({}),
  };
}

function deletePlanogramFailed(state: PlanogramState, action: any): PlanogramState {
  const errorDetails = action.errorDetails as ErrorDetails;

  return {
    ...state,
    loadDeleteStatus: setError(errorDetails),
  };
}

function toggleEnableShowProductInfo(state: PlanogramState, action: any): PlanogramState {
  const enabled = action.enabled;
  return {
    ...state,
    enableShowProductInfo: enabled || !state.enableShowProductInfo,
  };
}

function toggleEnableSalesDataReport(state: PlanogramState, action: any): PlanogramState {
  let enabled = action.enabled;
  enabled = enabled !== undefined ? enabled : !state.enableSalesDataReport;
  const flowDataEnabled = enabled ? false : state.enableFlowDataReport;
  const clientDatasetEnabled = enabled ? false : state.enableClientDatasetReport;
  return {
    ...state,
    enableSalesDataReport: enabled,
    enableClientDatasetReport: clientDatasetEnabled,
    enableFlowDataReport: flowDataEnabled,
  };
}
function toggleEnableClientDatasetReportHandler(state: PlanogramState, action: any): PlanogramState {
  let enabled = action.enabled;
  enabled = enabled !== undefined ? enabled : !state.enableClientDatasetReport;
  const flowDataEnabled = enabled ? false : state.enableFlowDataReport;
  const salesDataEnabled = enabled ? false : state.enableSalesDataReport;
  return {
    ...state,
    enableClientDatasetReport: enabled,
    enableFlowDataReport: flowDataEnabled,
    enableSalesDataReport: salesDataEnabled,
  };
}

function toggleEnableFlowDataReport(state: PlanogramState, action: any): PlanogramState {
  let enabled = action.enabled;
  enabled = enabled !== undefined ? enabled : !state.enableFlowDataReport;
  const salesDataEnabled = enabled ? false : state.enableSalesDataReport;
  const clientDatasetEnabled = enabled ? false : state.enableClientDatasetReport;
  return {
    ...state,
    enableFlowDataReport: enabled,
    enableSalesDataReport: salesDataEnabled,
    enableClientDatasetReport: clientDatasetEnabled,
  };
}

function toggleEnableCompetitorShelfData(state: PlanogramState, action: any): PlanogramState {
  let enabled = action.enabled;
  enabled = enabled !== undefined ? enabled : !state.enableCompetitorShelfData;
  return {
    ...state,
    enableCompetitorShelfData: enabled,
  };
}

function saveSalesDataRequest(state: PlanogramState): PlanogramState {
  return {
    ...state,
    loadSavingSalesDataStatus: setLoading('Saving...'),
  };
}

function saveSalesDataSuccess(state: PlanogramState, action: any): PlanogramState {
  const salesData = state.salesData;
  salesData.push(action.salesReport);
  return {
    ...state,
    loadSavingSalesDataStatus: setSuccess({}),
    salesData,
  };
}

function saveSalesDataFailed(state: PlanogramState, action: OnErrorRequestAction): PlanogramState {
  return {
    ...state,
    loadSavingSalesDataStatus: setError(action.errorDetails),
  };
}

function deleteSalesDataRequest(state: PlanogramState): PlanogramState {
  return {
    ...state,
    deleteSalesReportStatus: setLoading('Saving...'),
  };
}

function deleteSalesDataSuccess(state: PlanogramState, action: any): PlanogramState {
  const salesData = state.salesData;
  const report = state.salesData.find(r => r.reportId === action.reportId);
  if (report !== undefined) {
    const index = state.salesData.indexOf(report);
    state.salesData.splice(index, 1);
  }
  const selectedSalesReport = state.selectedSalesReport;
  return {
    ...state,
    deleteSalesReportStatus: setSuccess({}),
    salesData,
    selectedSalesReport: selectedSalesReport === report ? undefined : selectedSalesReport,
  };
}

function deleteSalesDataFailed(state: PlanogramState, action: OnErrorRequestAction): PlanogramState {
  return {
    ...state,
    deleteSalesReportStatus: setError(action.errorDetails),
  };
}

function newProductRequest(state: PlanogramState): PlanogramState {
  return {
    ...state,
    creatingNewProduct: true,
  };
}

function newProductRequestEnd(state: PlanogramState): PlanogramState {
  return {
    ...state,
    creatingNewProduct: false,
  };
}

function uploadShelfLocationImageRequestHandler(state: PlanogramState): PlanogramState {
  return {
    ...state,
    uploadShelfLocationImageStatus: setLoading('Uploading...'),
  };
}

function uploadShelfLocationImageSuccessHandler(state: PlanogramState): PlanogramState {
  return {
    ...state,
    uploadShelfLocationImageStatus: setSuccess(''),
  };
}

function uploadShelfLocationImageErrorHandler(state: PlanogramState, action: OnErrorRequestAction): PlanogramState {
  return {
    ...state,
    uploadShelfLocationImageStatus: setError(action.errorDetails),
  };
}

function fixImageSizeRequestHandler(state: PlanogramState): PlanogramState {
  return {
    ...state,
    loadFixingImageSizeStatus: setLoading(),
  };
}

function fixImageSizeSuccessHandler(state: PlanogramState, action: any): PlanogramState {
  const shelfLocation = action.shelfLocation as ShelfLocation;
  const g = shelfLocation.geometry;
  const width = g.currentWidth;
  const height = g.currentHeight;
  g.hasFixedImage = false;
  g.heightBottomChanged = 0;
  g.heightTopChanged = 0;
  g.originalHeight = height;
  g.originalWidth = width;
  g.tempCurrentHeight = 0;
  g.tempCurrentWidth = 0;
  g.tempHeightBottomChanged = 0;
  g.tempHeightTopChanged = 0;
  g.tempWidthLeftChanged = 0;
  g.tempWidthRightChanged = 0;
  g.widthLeftChanged = 0;
  g.widthRightChanged = 0;
  return {
    ...state,
    loadFixingImageSizeStatus: setSuccess({}),
  };
}

function fixImageSizeErrorHandler(state: PlanogramState, action: any): PlanogramState {
  const errorDetails = action.errorDetails as ErrorDetails;
  return {
    ...state,
    loadFixingImageSizeStatus: setError(errorDetails),
  };
}

function setShelfLocationGtinRequestHandler(state: PlanogramState): PlanogramState {
  return {
    ...state,
    loadSettingShelfLocationGtinStatus: setLoading(),
  };
}

function setShelfLocationGtinSuccessHandler(state: PlanogramState, action: any): PlanogramState {
  const shelfLocation = action.shelfLocation as ShelfLocation;
  shelfLocation.gtin = parseFloat(action.gtin);
  const newProduct = action.newProduct as NewProductModel;

  if (newProduct) {
    const products = state.products as ProductModel[];
    const product = {} as ProductModel;
    product.gtin = parseFloat(action.gtin);
    product.tradeItemDescription = newProduct.name;
    product.productImageUrl = newProduct.imageUrl;
    products.push(product);
  }

  return {
    ...state,
    loadSettingShelfLocationGtinStatus: setSuccess({}),
  };
}

function setShelfLocationGtinErrorHandler(state: PlanogramState, action: OnErrorRequestAction): PlanogramState {
  return {
    ...state,
    loadSettingShelfLocationGtinStatus: setError(action.errorDetails),
  };
}

function setFlowReportTypeHandler(state: PlanogramState, action: any): PlanogramState {
  return {
    ...state,
    selectedFlowDataType: action.reportType,
    enableFlowDataReport: true,
    enableSalesDataReport: false,
    enableClientDatasetReport: false,
    selectedClientDatasetReport: undefined,
    selectedSalesReport: undefined,
  };
}

function setSalesReportHandler(state: PlanogramState, action: any): PlanogramState {
  const salesReport = action.salesReport === state.selectedSalesReport ? undefined : action.salesReport;
  return {
    ...state,
    selectedSalesReport: salesReport,
    enableSalesDataReport: true,
    enableFlowDataReport: false,
    enableClientDatasetReport: false,
    selectedClientDatasetReport: undefined,
    selectedFlowDataType: undefined,
  };
}

function setClientDatasetReportHandler(state: PlanogramState, action: any): PlanogramState {
  const selectedClientDatasetReport = action.clientDataset === state.selectedClientDatasetReport ? undefined : action.clientDataset;
  return {
    ...state,
    selectedClientDatasetReport: selectedClientDatasetReport,
    enableClientDatasetReport: true,
    enableFlowDataReport: false,
    enableSalesDataReport: false,
    selectedFlowDataType: undefined,
    selectedSalesReport: undefined,
  };
}

function updateCommentsRequestHandler(state: PlanogramState): PlanogramState {
  return {
    ...state,
    updateCommentsStatus: setLoading(),
  };
}

function updateCommentsSuccessHandler(state: PlanogramState, action: any): PlanogramState {
  const planogram = state.planogram as PlanogramModel;
  planogram.comments = action.comments;
  return {
    ...state,
    updateCommentsStatus: setSuccess({}),
  };
}

function updateCommentsErrorHandler(state: PlanogramState, action: any): PlanogramState {
  const errorDetails = action.errorDetails as ErrorDetails;

  return {
    ...state,
    updateCommentsStatus: setError(errorDetails),
  };
}

function shareModalToggleHandler(state: PlanogramState): PlanogramState {
  return {
    ...state,
    showSharingModal: !state.showSharingModal,
  };
}

function showTestWithFlowModalHandler(state: PlanogramState): PlanogramState {
  return {
    ...state,
    showTestWithFlowModal: true,
  };
}

function hideTestWithFlowModalHandler(state: PlanogramState): PlanogramState {
  return {
    ...state,
    showTestWithFlowModal: false,
  };
}

function showTestInfoModalHandler(state: PlanogramState): PlanogramState {
  return {
    ...state,
    showTestInfoModal: true,
  };
}

function hideTestInfoModalHandler(state: PlanogramState): PlanogramState {
  return {
    ...state,
    showTestInfoModal: false,
  };
}

function showEditTestModalHandler(state: PlanogramState, action: any): PlanogramState {
  return {
    ...state,
    testToEdit: action.testToEdit,
    showEditTestModal: true,
  };
}

function hideEditTestModalHandler(state: PlanogramState): PlanogramState {
  return {
    ...state,
    showEditTestModal: false,
  };
}

function showDeleteTestConfirmationModalHandler(state: PlanogramState, action: any): PlanogramState {
  return {
    ...state,
    testToDelete: action.testToDelete,
    showDeleteTestConfirmationModal: true,
  };
}

function hideDeleteTestConfirmationModalHandler(state: PlanogramState): PlanogramState {
  return {
    ...state,
    showDeleteTestConfirmationModal: false,
  };
}

function createTestPlanogramRequestHandler(state: PlanogramState): PlanogramState {
  return {
    ...state,
    loadCreatingTestPlanogramStatus: setLoading(),
  };
}

function createTestPlanogramSuccessHandler(state: PlanogramState, action: any): PlanogramState {
  const createTestResponse = action.createTestResponseDto;
  const createdTestDto = action.createdTestDto;

  const createdTestInfo = new TestInfoDto();
  createdTestInfo.id = createTestResponse.createdTestId;
  createdTestInfo.createdOn = createTestResponse.createdOn;
  createdTestInfo.operationsInfo = createdTestDto.operationsInfo;
  createdTestInfo.testName = createdTestDto.testName;
  createdTestInfo.initialPlanogramId = createdTestDto.initialPlanogramId;
  // TODO: set report data property from dto when implemented
  state.testsInfoList.push(createdTestInfo);

  return {
    ...state,
    testsInfoList: state.testsInfoList,
    loadCreatingTestPlanogramStatus: setSuccess({}),
  };
}

function createTestPlanogramFailureHandler(state: PlanogramState, action: OnErrorRequestAction): PlanogramState {
  return {
    ...state,
    loadCreatingTestPlanogramStatus: setError(action.errorDetails),
  };
}

function updateTestInfoRequestHandler(state: PlanogramState): PlanogramState {
  return {
    ...state,
    updateTestInfoStatus: setLoading(),
  };
}

function updateTestInfoSuccessHandler(state: PlanogramState, action: any): PlanogramState {
  // Update the testsInfoList list on successful edit of testInfo
  const testInfoEdited = action.testInfo;
  const testsInfoList = state.testsInfoList.map(test => {
    if (test.id === testInfoEdited.id) {
      test = testInfoEdited;
    }
    return test;
  });
  return {
    ...state,
    testsInfoList,
    showEditTestModal: false,
    updateTestInfoStatus: setSuccess({}),
  };
}

function updateTestInfoFailureHandler(state: PlanogramState, action: any): PlanogramState {
  const errorDetails = action.errorDetails as ErrorDetails;

  return {
    ...state,
    updateTestInfoStatus: setError(errorDetails),
  };
}

function deleteTestRequestHandler(state: PlanogramState): PlanogramState {
  return {
    ...state,
    deleteTestStatus: setLoading(),
  };
}

function deleteTestSuccessHandler(state: PlanogramState, action: any): PlanogramState {
  const testIdDeleted = action.testIdDeleted;
  const testsInfoList = state.testsInfoList.filter(test => test.id !== testIdDeleted);
  return {
    ...state,
    deleteTestStatus: setSuccess({}),
    showDeleteTestConfirmationModal: false,
    testsInfoList,
  };
}

function deleteTestFailureHandler(state: PlanogramState, action: OnErrorRequestAction): PlanogramState {
  return {
    ...state,
    deleteTestStatus: setError(action.errorDetails),
    showDeleteTestConfirmationModal: false,
  };
}

function loadTestsInfoRequestHandler(state: PlanogramState): PlanogramState {
  return {
    ...state,
    loadTestsInfoStatus: setLoading(),
    testsInfoList: [],
  };
}

function loadTestsInfoSuccessHandler(state: PlanogramState, action: any): PlanogramState {
  return {
    ...state,
    loadTestsInfoStatus: setSuccess({}),
    testsInfoList: action.testsInfoList,
  };
}

function loadTestsInfoFailureHandler(state: PlanogramState, action: OnErrorRequestAction): PlanogramState {
  return {
    ...state,
    loadTestsInfoStatus: setError(action.errorDetails),
  };
}

function planogramPublicShareToggleSuccessHandler(state: PlanogramState, action: any): PlanogramState {
  const planogram = state.planogram as PlanogramModel;
  planogram.sharingOptions.publicLinkEnabled = action.enabled;
  planogram.sharingOptions.publicToken = action.newToken;
  return {
    ...state,
    planogram,
    togglePublicShareOptionsStatus: setSuccess({}),
  };
}

function planogramPublicShareToggleRequestHandler(state: PlanogramState): PlanogramState {
  return {
    ...state,
    togglePublicShareOptionsStatus: setLoading(),
  };
}

function planogramPublicShareToggleErrorHandler(state: PlanogramState, action: any): PlanogramState {
  const errorDetails = action.errorDetails as ErrorDetails;

  return {
    ...state,
    togglePublicShareOptionsStatus: setError(errorDetails),
  };
}

function toggleShowInvalidShelfLocations(state: PlanogramState): PlanogramState {
  return {
    ...state,
    paintInvalidShelfLocations: !state.paintInvalidShelfLocations,
  };
}

function showInitialPlanogram(state: PlanogramState): PlanogramState {
  return {
    ...state,
    showInitialPlanogram: !state.showInitialPlanogram,
    selectedShelfLocations: [],
    copyingShelfLocations: [],
    contextMenuElement: undefined,
    isOpenRightContextMenu: false,
  };
}

function undoDelistedProductRequest(state: PlanogramState, action: any): PlanogramState {
  const gtin: number = action.gtin;
  const planogram = state.initialPlanogram as PlanogramModel;
  const shelfLocation = planogram.shelfLocations.find(sl => sl.gtin === gtin);
  if (state.planogram && shelfLocation) {
    const newShelfLocation = classToClass(shelfLocation);
    newShelfLocation.id = Guid.create().toString();
    newShelfLocation.geometry.calculatePointsPosition();
    const command = new InsertCommand(state.planogram, [newShelfLocation]);
    state.commandManager.executeCommand(command);
  }

  return {
    ...state,
    highlightedGtin: [gtin],
  };
}

function highlightBrandRequest(state: PlanogramState, action: any): PlanogramState {
  const brandId: number = action.brandId;
  const priority: boolean = action.priority;
  return {
    ...state,
    highlightedBrandId: !priority ? brandId : state.highlightedBrandId,
    priorityHighlightedBrandId: priority ? brandId : state.priorityHighlightedBrandId,
    priorityHighlightedGtin: priority ? undefined : state.priorityHighlightedGtin,
    highlightedGtinBy: priority ? '' : state.highlightedGtinBy,
  };
}

function highlightProductRequest(state: PlanogramState, action: any): PlanogramState {
  const gtin: number[] = action.gtin;
  const priority: boolean = action.priority;
  const highLightedBy: string = action.highLightedBy;
  return {
    ...state,
    highlightedGtin: !priority ? gtin : state.highlightedGtin,
    priorityHighlightedGtin: priority ? gtin : state.priorityHighlightedGtin,
    priorityHighlightedBrandId: priority ? undefined : state.priorityHighlightedBrandId,
    highlightedGtinBy: highLightedBy,
  };
}

function recordChangeReasonRequest(state: PlanogramState, action: any): PlanogramState {
  // TODO: State should be updated in a success handler
  const reason = action.reason as RecordChangeReasonDto;
  const planogram = state.planogram;
  if (!planogram) {
    return state;
  }
  if (reason.brandResizeReason) {
    planogram.changeReasons.brandResizeReasons = planogram.changeReasons.brandResizeReasons.filter(b => b.brandId !== reason.brandResizeReason?.brandId);
    planogram.changeReasons.brandResizeReasons.push(reason.brandResizeReason);
  }
  if (reason.productDelistedReason) {
    planogram.changeReasons.productDelistedReasons = planogram.changeReasons.productDelistedReasons.filter(p => p.gtin !== reason.productDelistedReason?.gtin);
    planogram.changeReasons.productDelistedReasons.push(reason.productDelistedReason);
  }
  if (reason.newProductReason) {
    planogram.changeReasons.newProductReasons = planogram.changeReasons.newProductReasons.filter(p => p.gtin !== reason.newProductReason?.gtin);
    planogram.changeReasons.newProductReasons.push(reason.newProductReason);
  }
  if (reason.productResizeReason) {
    planogram.changeReasons.productResizeReasons = planogram.changeReasons.productResizeReasons.filter(p => p.gtin !== reason.productResizeReason?.gtin);
    planogram.changeReasons.productResizeReasons.push(reason.productResizeReason);
  }
  planogram.calculateIsValid();
  return {
    ...state,
    planogram,
  };
}
function resetPlanogramRequestHandler(state: PlanogramState): PlanogramState {
  return {
    ...state,
    loadResetPlanogramStatus: setLoading(),
  };
}

function resetPlanogramSuccessHandler(state: PlanogramState): PlanogramState {
  return {
    ...state,
    loadResetPlanogramStatus: setSuccess({}),
  };
}

function resetPlanogramFailureHandler(state: PlanogramState, action: OnErrorRequestAction): PlanogramState {
  return {
    ...state,
    loadResetPlanogramStatus: setError(action.errorDetails),
  };
}

function planogramInUnstableStateHandler(state: PlanogramState): PlanogramState {
  return {
    ...state,
    inUnstableState: true,
  };
}

function toggleActiveTabHandler(state: PlanogramState, action: any): PlanogramState {
  const activeTab = action.activeTab;
  if (state.planogramAsideActiveTab !== activeTab) {
    return {
      ...state,
      planogramAsideActiveTab: activeTab,
    };
  } else {
    return state;
  }
}

function toggleEnableGpcBrickFilter(state: PlanogramState): PlanogramState {
  return {
    ...state,
    gpcBrickFilterEnabled: !state.gpcBrickFilterEnabled,
  };
}

function toggleGpcBrickCodeFilter(state: PlanogramState, action: any): PlanogramState {
  const gpcBrickCode = action.gpcBrickCode as number;
  const filters = [...state.gpcBrickCodeFilters];
  const indexOfBrick = filters.indexOf(gpcBrickCode);
  if (indexOfBrick > -1) {
    filters.splice(indexOfBrick, 1);
  } else {
    filters.push(gpcBrickCode);
  }

  return {
    ...state,
    gpcBrickCodeFilters: filters,
  };
}

function runProductTourHandler(state: PlanogramState): PlanogramState {
  const productTourState = state.productTourState;
  productTourState.runProductTour = true;
  productTourState.productTourStepIndex = 0;
  return {
    ...state,
    productTourState,
  };
}

function updateProductStoreStepIndexHandler(state: PlanogramState, action: any): PlanogramState {
  const productTourState = state.productTourState;
  productTourState.productTourStepIndex = action.productStoreStepIndex;
  return {
    ...state,
    productTourState,
  };
}

function stopProductTourHandler(state: PlanogramState): PlanogramState {
  const productTourState = state.productTourState;
  productTourState.runProductTour = false;
  productTourState.productTourStepIndex = 0;
  return {
    ...state,
    productTourState,
  };
}

function showCompetitorModalToggleHandler(state: PlanogramState): PlanogramState {
  return {
    ...state,
    showGetCompetitorModal: !state.showGetCompetitorModal,
  };
}

function onNewProductImageUploadRequest(state: PlanogramState): PlanogramState {
  return {
    ...state,
    uploadNewProductImage: setLoading<string>(),
  };
}

function onNewProductImageUploadSuccess(state: PlanogramState, action: OnNewProductImageUploadSuccessAction): PlanogramState {
  return {
    ...state,
    uploadNewProductImage: setSuccess<string>(action.imageUrl),
  };
}

function onNewProductImageUploadError(state: PlanogramState, action: OnNewProductImageUploadErrorAction): PlanogramState {
  return {
    ...state,
    uploadNewProductImage: setError(action.errorDetails),
  };
}

function onZoomLevelAdjustmentHandler(state: PlanogramState, action: OnZoomLevelAdjustmentRequestAction): PlanogramState {
  const value = { ...state.svgPanZoomValue };
  if (action.zoomLevelControlNumber === 0) {
    // reset
    value.a = 1;
    value.e = 0;
    value.f = 0;
  } else if (action.zoomLevelControlNumber === 1) {
    value.a += 0.06;
  } else if (action.zoomLevelControlNumber === -1) {
    value.a -= 0.06;
  }
  value.d = value.a;
  return {
    ...state,
    svgPanZoomValue: value,
  };
}

function onCommandExecutionRequestHandler(state: PlanogramState, action: OnCommandExecutionRequestAction): PlanogramState {
  const event = state.commandManager.executeCommand(action.command);
  return {
    ...state,
    eventToSave: event,
  };
}

function onPlanogramActionUndoRequestHandler(state: PlanogramState): PlanogramState {
  const event = state.commandManager.undo();
  return {
    ...state,
    eventToSave: event,
  };
}

function onSelectShelfLocationsRequestHandler(state: PlanogramState, action: OnSelectShelfLocationsRequest): PlanogramState {
  const currentlySelectedShelfLocations = [...state.selectedShelfLocations];
  let selectedShelfLocations: ShelfLocation[] = action.shelfLocations;
  if (action.append) {
    selectedShelfLocations = reject(currentlySelectedShelfLocations, csl => {
      return action.shelfLocations.find(sl => sl === csl) ? true : false;
    });
    selectedShelfLocations = selectedShelfLocations.concat(action.shelfLocations);
  }
  return {
    ...state,
    selectedShelfLocations: selectedShelfLocations,
    isOpenRightContextMenu: false,
    contextMenuElement: undefined,
  };
}

function onStartCopyingShelfLocationsRequestHandler(state: PlanogramState): PlanogramState {
  const lastSl = last(state.selectedShelfLocations);
  if (!lastSl) {
    return state;
  }
  const copyingShelfLocation = classToClass(lastSl);
  copyingShelfLocation.id = Guid.create().toString();
  return {
    ...state,
    isCopyingShelfLocations: true,
    copyingShelfLocations: [copyingShelfLocation],
    selectedShelfLocations: [],
    isOpenRightContextMenu: false,
    contextMenuElement: undefined,
  };
}

function onCancelCopyingShelfLocationsRequestHandler(state: PlanogramState): PlanogramState {
  return {
    ...state,
    isCopyingShelfLocations: false,
    copyingShelfLocations: [],
  };
}

function onCancelAllPlanogramUiActionsRequestHandler(state: PlanogramState): PlanogramState {
  return {
    ...state,
    isCopyingShelfLocations: false,
    copyingShelfLocations: [],
    selectedShelfLocations: [],
    isOpenRightContextMenu: false,
    contextMenuElement: undefined,
  };
}

function onPlanogramSvgPanZoomValueUpdateRequestHandler(state: PlanogramState, action: OnPlanogramSvgPanZoomValueUpdateRequestAction): PlanogramState {
  return {
    ...state,
    svgPanZoomValue: action.value,
  };
}

function onPlanogramContextMenuOpenRequest(state: PlanogramState, action: OnPlanogramContextMenuOpenRequestAction): PlanogramState {
  return {
    ...state,
    contextMenuElement: action.element,
    isOpenRightContextMenu: action.shouldOpen,
    selectedShelfLocations: action.shelfLocation ? [action.shelfLocation] : [],
  };
}

const handlers = {};

handlers[constants.FORCE_UPDATE] = forceUpdateHandler;
handlers[constants.PLANOGRAM_PAGE_RESET] = planogramPageResetHandler;

handlers[constants.ON_PLANOGRAM_EVENT_REQUEST] = onPlanogramEvent;
handlers[constants.SAVING_PLANOGRAM_CHANGES] = savingChangesHandler;

handlers[constants.PLANOGRAM_IMAGE_LOADED] = planogramImageLoaded;
handlers[constants.PLANOGRAM_IMAGE_LOADING] = planogramImageLoading;
handlers[constants.PLANOGRAM_IMAGE_LOAD_ERROR] = planogramImageLoadError;

handlers[constants.PLANOGRAM_LOADED] = planogramLoaded;
handlers[constants.PLANOGRAM_LOADING] = planogramLoading;
handlers[constants.ERROR_LOADING_PLANOGRAM] = errorLoadingPlanogram;

handlers[constants.FLOW_DATA_LOADING] = getFlowDataHandler;
handlers[constants.FLOW_DATA_LOADED] = onFlowDataLoadedHandler;
handlers[constants.FLOW_DATA_LOAD_FAILED] = onFlowDataLoadFailedHandler;

handlers[constants.UPDATE_PLANOGRAM_VIEW_PORT] = updatePlanogramViewPortHandler;

handlers[constants.PUBLIC_PLANOGRAM_LOADED] = publicPlanogramLoaded;
handlers[constants.PUBLIC_PLANOGRAM_LOADING] = publicPlanogramLoading;
handlers[constants.ERROR_LOADING_PUBLIC_PLANOGRAM] = errorLoadingPublicPlanogram;

handlers[constants.TEST_PLANOGRAM_LOADED] = testPlanogramLoaded;
handlers[constants.TEST_PLANOGRAM_LOADING] = testPlanogramLoading;
handlers[constants.ERROR_LOADING_TEST_PLANOGRAM] = errorLoadingTestPlanogram;

handlers[constants.LOAD_COMPETITOR_PLANOGRAM_REQUEST] = loadCompetitorPlanogramRequestHandler;
handlers[constants.LOAD_COMPETITOR_PLANOGRAM_SUCCESS] = loadCompetitorPlanogramSuccessHandler;
handlers[constants.LOAD_COMPETITOR_PLANOGRAM_FAILURE] = loadCompetitorPlanogramFailureHandler;

handlers[constants.CHANGE_PLANOGRAM_NAME] = changePlanogramName;
handlers[constants.CHANGE_PLANOGRAM_NAME_ERROR] = changePlanogramNameError;
handlers[constants.CHANGE_PLANOGRAM_NAME_SUCCESS] = changePlanogramNameSuccess;

handlers[constants.DELETE_PLANOGRAM_FAILED] = deletePlanogramFailed;
handlers[constants.DELETE_PLANOGRAM_REQUEST] = deletePlanogramRequest;
handlers[constants.DELETE_PLANOGRAM_SUCCESS] = deletePlanogramSuccess;

handlers[constants.TOGGLE_ENABLE_SHOW_PRODUCT_INFO] = toggleEnableShowProductInfo;
handlers[constants.TOGGLE_ENABLE_SALES_DATA_REPORT] = toggleEnableSalesDataReport;
handlers[constants.TOGGLE_ENABLE_FLOW_DATA_REPORT] = toggleEnableFlowDataReport;
handlers[constants.TOGGLE_ENABLE_CLIENT_DATASET_REPORT] = toggleEnableClientDatasetReportHandler;
handlers[constants.TOGGLE_ENABLE_COMPETITOR_SHELF_DATA_REPORT] = toggleEnableCompetitorShelfData;

handlers[constants.SAVE_SALES_DATA_FAILED] = saveSalesDataFailed;
handlers[constants.SAVE_SALES_DATA_REQUEST] = saveSalesDataRequest;
handlers[constants.SAVE_SALES_DATA_SUCCESS] = saveSalesDataSuccess;

handlers[constants.DELETE_SALES_DATA_FAILED] = deleteSalesDataFailed;
handlers[constants.DELETE_SALES_DATA_REQUEST] = deleteSalesDataRequest;
handlers[constants.DELETE_SALES_DATA_SUCCESS] = deleteSalesDataSuccess;

handlers[constants.NEW_PRODUCT_REQUEST] = newProductRequest;
handlers[constants.NEW_PRODUCT_STOP] = newProductRequestEnd;

handlers[constants.UPLOAD_SHELFLOCATION_IMAGE_ERROR] = uploadShelfLocationImageErrorHandler;
handlers[constants.UPLOAD_SHELFLOCATION_IMAGE_REQUEST] = uploadShelfLocationImageRequestHandler;
handlers[constants.UPLOAD_SHELFLOCATION_IMAGE_SUCCESS] = uploadShelfLocationImageSuccessHandler;

handlers[constants.FIX_IMAGE_SIZE_ERROR] = fixImageSizeErrorHandler;
handlers[constants.FIX_IMAGE_SIZE_REQUEST] = fixImageSizeRequestHandler;
handlers[constants.FIX_IMAGE_SIZE_SUCCESS] = fixImageSizeSuccessHandler;

handlers[constants.SET_SHELFLOCATION_GTIN_ERROR] = setShelfLocationGtinErrorHandler;
handlers[constants.SET_SHELFLOCATION_GTIN_REQUEST] = setShelfLocationGtinRequestHandler;
handlers[constants.SET_SHELFLOCATION_GTIN_SUCCESS] = setShelfLocationGtinSuccessHandler;

handlers[constants.SET_FLOW_REPORT_TYPE] = setFlowReportTypeHandler;
handlers[constants.SET_SALES_REPORT_TYPE] = setSalesReportHandler;
handlers[constants.SET_CLIENT_DATASET_REPORT_TYPE] = setClientDatasetReportHandler;

handlers[constants.UPDATE_PLANOGRAM_COMMENTS_REQUEST] = updateCommentsRequestHandler;
handlers[constants.UPDATE_PLANOGRAM_COMMENTS_ERROR] = updateCommentsErrorHandler;
handlers[constants.UPDATE_PLANOGRAM_COMMENTS_SUCCESS] = updateCommentsSuccessHandler;

handlers[constants.PLANOGRAM_PUBLIC_SHARE_TOGGLE_REQUEST] = planogramPublicShareToggleRequestHandler;
handlers[constants.PLANOGRAM_PUBLIC_SHARE_TOGGLE_FAILED] = planogramPublicShareToggleErrorHandler;
handlers[constants.PLANOGRAM_PUBLIC_SHARE_TOGGLE_SUCCESS] = planogramPublicShareToggleSuccessHandler;

handlers[constants.PLANOGRAM_SHARE_MODAL_TOGGLE] = shareModalToggleHandler;

handlers[constants.SHOW_TEST_WITH_FLOW_MODAL] = showTestWithFlowModalHandler;
handlers[constants.HIDE_TEST_WITH_FLOW_MODAL] = hideTestWithFlowModalHandler;

handlers[constants.SHOW_TEST_DETAILS_MODAL] = showTestInfoModalHandler;
handlers[constants.HIDE_TEST_DETAILS_MODAL] = hideTestInfoModalHandler;

handlers[constants.SHOW_EDIT_TEST_MODAL] = showEditTestModalHandler;
handlers[constants.HIDE_EDIT_TEST_MODAL] = hideEditTestModalHandler;

handlers[constants.SHOW_DELETE_TEST_CONFIRMATION_MODAL] = showDeleteTestConfirmationModalHandler;
handlers[constants.HIDE_DELETE_TEST_CONFIRMATION_MODAL] = hideDeleteTestConfirmationModalHandler;

handlers[constants.UPDATE_TEST_INFO_REQUEST] = updateTestInfoRequestHandler;
handlers[constants.UPDATE_TEST_INFO_SUCCESS] = updateTestInfoSuccessHandler;
handlers[constants.UPDATE_TEST_INFO_FAILURE] = updateTestInfoFailureHandler;

handlers[constants.DELETE_TEST_REQUEST] = deleteTestRequestHandler;
handlers[constants.DELETE_TEST_SUCCESS] = deleteTestSuccessHandler;
handlers[constants.DELETE_TEST_FAILURE] = deleteTestFailureHandler;

handlers[constants.CREATE_TEST_PLANOGRAM_REQUEST] = createTestPlanogramRequestHandler;
handlers[constants.CREATE_TEST_PLANOGRAM_SUCCESS] = createTestPlanogramSuccessHandler;
handlers[constants.CREATE_TEST_PLANOGRAM_FAILURE] = createTestPlanogramFailureHandler;

handlers[constants.TOGGLE_SHOW_INVALID_SHELF_LOCATIONS] = toggleShowInvalidShelfLocations;

handlers[constants.LOAD_TESTS_INFO_REQUEST] = loadTestsInfoRequestHandler;
handlers[constants.LOAD_TESTS_INFO_SUCCESS] = loadTestsInfoSuccessHandler;
handlers[constants.LOAD_TESTS_INFO_FAILURE] = loadTestsInfoFailureHandler;

handlers[constants.TOGGLE_SHOW_INITIAL_PLANOGRAM] = showInitialPlanogram;

handlers[constants.UNDO_DELISTED_PRODUCT] = undoDelistedProductRequest;

handlers[constants.ON_HIGHLIGHT_BRAND] = highlightBrandRequest;
handlers[constants.ON_HIGHLIGHT_PRODUCT] = highlightProductRequest;

handlers[constants.RECORD_CHANGE_REASON_REQUEST] = recordChangeReasonRequest;

handlers[constants.RESET_PLANOGRAM_REQUEST] = resetPlanogramRequestHandler;
handlers[constants.RESET_PLANOGRAM_SUCCESS] = resetPlanogramSuccessHandler;
handlers[constants.RESET_PLANOGRAM_FAILURE] = resetPlanogramFailureHandler;

handlers[constants.PLANOGRAM_IN_UNSTABLE_STATE] = planogramInUnstableStateHandler;

handlers[constants.TOGGLE_GPC_BRICK_CODE_FILTER] = toggleGpcBrickCodeFilter;
handlers[constants.TOGGLE_ENABLED_GPC_BRICK_FILTER] = toggleEnableGpcBrickFilter;

handlers[constants.TOGGLE_PLANOGRAM_ASIDE_ACTIVE_TAB] = toggleActiveTabHandler;

handlers[constants.RUN_PRODUCT_TOUR] = runProductTourHandler;
handlers[constants.UPDATE_PRODUCT_STORE_STEP_INDEX] = updateProductStoreStepIndexHandler;
handlers[constants.STOP_PRODUCT_TOUR] = stopProductTourHandler;

handlers[constants.TOGGLE_SHOW_COMPETITOR_MODAL] = showCompetitorModalToggleHandler;

handlers[constants.ON_NEW_PRODUCT_IMAGE_UPLOAD_ERROR] = onNewProductImageUploadError;
handlers[constants.ON_NEW_PRODUCT_IMAGE_UPLOAD_REQUEST] = onNewProductImageUploadRequest;
handlers[constants.ON_NEW_PRODUCT_IMAGE_UPLOAD_SUCCESS] = onNewProductImageUploadSuccess;
handlers[constants.ON_ZOOM_LEVEL_ADJUSTMENT] = onZoomLevelAdjustmentHandler;

handlers[constants.ON_COMMAND_EXECUTION_REQUEST] = onCommandExecutionRequestHandler;
handlers[constants.ON_PLANOGRAM_ACTION_UNDO_REQUEST] = onPlanogramActionUndoRequestHandler;

handlers[constants.ON_SELECT_SHELF_LOCATION_REQUEST] = onSelectShelfLocationsRequestHandler;

handlers[constants.ON_START_COPYING_SHELF_LOCATIONS_REQUEST] = onStartCopyingShelfLocationsRequestHandler;
handlers[constants.ON_CANCEL_COPYING_SHELF_LOCATIONS_REQUEST] = onCancelCopyingShelfLocationsRequestHandler;

handlers[constants.ON_CANCEL_ALL_PLANOGRAM_UI_ACTIONS_REQUEST] = onCancelAllPlanogramUiActionsRequestHandler;

handlers[constants.ON_PLANOGRAM_CONTEXT_MENU_OPEN_REQUEST] = onPlanogramContextMenuOpenRequest;
handlers[constants.ON_PLANOGRAM_SVGPANZOOM_VALUE_UPDATE_REQUEST] = onPlanogramSvgPanZoomValueUpdateRequestHandler;

const planogramReducer = createReducer(initialState, handlers);

export default planogramReducer;
