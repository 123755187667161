import PlanogramEventBase from './PlanogramEventBase';

export default class ShelfLocationsDeletedEvent extends PlanogramEventBase {
  readonly planogramId: string = '';
  readonly shelfLocationIds: string[] = [];

  constructor(planogramId: string, shelfLocationIds: string[]) {
    super(`/planograms/${planogramId}/shelf-locations`, 'ShelfLocationsDeleted', 'delete');
    this.planogramId = planogramId;
    this.shelfLocationIds = shelfLocationIds;
  }
}
