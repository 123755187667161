import React from 'react';
import { Field, FieldProps } from 'formik';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { BarChart, BurstMode, Notes, Store, Timeline, ViewCompact } from '@material-ui/icons';
import AttachMoney from '@material-ui/icons/AttachMoney';

const PlanogramDetailsForm: React.FC = (): JSX.Element => {
  return (
    <div className="row">
      <div className="col-sm-6 col-md-6">
        <Field name="projectName">
          {({ field }: FieldProps): JSX.Element => {
            return (
              <TextField
                value={field.value}
                name={field.name}
                id="projectName"
                label="Project"
                placeholder="Project Name"
                disabled
                fullWidth
                margin="normal"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BurstMode color="secondary" />
                    </InputAdornment>
                  ),
                  readOnly: true,
                }}
              />
            );
          }}
        </Field>
      </div>
      <div className="col-sm-6 col-md-6">
        <Field name="storeName">
          {({ field }: FieldProps): JSX.Element => {
            return (
              <TextField
                value={field.value}
                name={field.name}
                id="storeName"
                label="Store"
                placeholder="Store Name"
                disabled
                fullWidth
                margin="normal"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Store color="secondary" />
                    </InputAdornment>
                  ),
                  readOnly: true,
                }}
              />
            );
          }}
        </Field>
      </div>
      <div className="col-sm-6 col-md-6">
        <Field name="phaseNum">
          {({ field }: FieldProps): JSX.Element => {
            return (
              <TextField
                value={field.value}
                name={field.name}
                id="PhaseNum"
                label="Phase"
                placeholder="Phase Num"
                disabled
                fullWidth
                margin="normal"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Timeline color="secondary" />
                    </InputAdornment>
                  ),
                  readOnly: true,
                }}
              />
            );
          }}
        </Field>
      </div>
      <div className="col-sm-6 col-md-6">
        <Field name="shelfNum">
          {({ field }: FieldProps): JSX.Element => {
            return (
              <TextField
                value={field.value}
                name={field.name}
                id="shelfNum"
                label="Shelf"
                placeholder="Shelf Num"
                fullWidth
                disabled
                margin="normal"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Notes color="secondary" />
                    </InputAdornment>
                  ),
                  readOnly: true,
                }}
              />
            );
          }}
        </Field>
      </div>
      <div className="col-sm-12 col-md-12">
        <Field name="planogramName">
          {({ field, form: { touched, errors } }: FieldProps): JSX.Element => {
            const error = touched[field.name] && errors[field.name];
            return (
              <>
                <TextField
                  error={!!error}
                  value={field.value}
                  name={field.name}
                  id="planogramName"
                  label="Planogram Name"
                  placeholder="Planogram Name"
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  autoFocus
                  fullWidth
                  required
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ViewCompact color="primary" />
                      </InputAdornment>
                    ),
                    readOnly: false,
                  }}
                />
                {error && (
                  <Typography data-testid="planogramNameError" color="error">
                    {error}
                  </Typography>
                )}
              </>
            );
          }}
        </Field>
      </div>
      <div className="col-sm-12 col-md-12">
        <Field name="goal">
          {({ field, form: { touched, errors } }: FieldProps): JSX.Element => {
            const error = touched[field.name] && errors[field.name];
            return (
              <>
                <TextField
                  error={!!error}
                  value={field.value}
                  name={field.name}
                  id="shelfGoal"
                  label="Shelf Goal"
                  placeholder="Shelf Goal"
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  multiline
                  fullWidth
                  required
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AttachMoney color="primary" />
                      </InputAdornment>
                    ),
                    readOnly: false,
                  }}
                />
                {error && (
                  <Typography data-testid="goalError" color="error">
                    {error}
                  </Typography>
                )}
              </>
            );
          }}
        </Field>
      </div>
      <div className="col-sm-12 col-md-12">
        <Field name="goalKpis">
          {({ field, form: { touched, errors } }: FieldProps): JSX.Element => {
            const error = touched[field.name] && errors[field.name];
            return (
              <>
                <TextField
                  error={!!error}
                  value={field.value}
                  name={field.name}
                  id="goalKpis"
                  label="Goal KPIs"
                  placeholder="Goal KPIs"
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  multiline
                  fullWidth
                  required
                  margin="normal"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <BarChart color="primary" />
                      </InputAdornment>
                    ),
                    readOnly: false,
                  }}
                />
                {error && (
                  <Typography data-testid="goalKpisError" color="error">
                    {error}
                  </Typography>
                )}
              </>
            );
          }}
        </Field>
      </div>
    </div>
  );
};

export default PlanogramDetailsForm;
