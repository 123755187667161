import { Badge, Fab } from '@material-ui/core';
import { Chat } from '@material-ui/icons';
import { ProductPanel } from 'modules/shared/components/ProductPanel';
import ReadonlyPlanogram from 'modules/shared/components/ReadonlyPlanogram';
import { ProductDetails } from '../../shared/types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Key } from 'ts-key-enum';
import {
  deleteMessageInConversationRequestAction,
  openRealogramConversationPopperAction,
  postMessageToConversationRequestAction,
  selectShelfLocationAction,
  updateMessageInConversationRequestAction,
} from '../actions';
import * as selectors from '../selectors';
import { getSelectedShelfLocationProductInfo } from '../selectors';
import { ConversationPopperIndex } from './conversation/ConversationPopperIndex';

const RealogramProductPanel: React.FC = (): JSX.Element => {
  const pinfo = useSelector(getSelectedShelfLocationProductInfo);
  const flowShelfLocationData = useSelector(selectors.getFlowShelfLocationsDataForSelectedShelfLocation);
  const flowShelfLocationPairRankingsData = useSelector(selectors.getFlowShelfLocationsPairRankingsDataForSelectedShelfLocation) ?? [];
  const selectedFlowReport = useSelector(selectors.getSelectedFlowDataReportType);
  const dispatch = useDispatch();

  return (
    <ProductPanel
      product={pinfo as ProductDetails}
      imageUrl={pinfo.productImageUrl}
      fixingImageSize={false}
      hasFixedImage={false}
      shelfLocationData={flowShelfLocationData}
      shelfLocationPairRankingData={flowShelfLocationPairRankingsData}
      selectedMetricName={selectedFlowReport}
      onClose={(): void => {
        dispatch(selectShelfLocationAction(undefined));
      }}
    />
  );
};

const RealogramComponent: React.FC = (): JSX.Element => {
  const realogram = useSelector(selectors.getRealogram);
  const shelfLocations = useSelector(selectors.getShelfLocations);
  const selectedShelfLocation = useSelector(selectors.getSelectedShelfLocation);
  const isOpenConversationPopper = useSelector(selectors.isOpenConversationPopper);
  const conversationPopperAnchorEl = useSelector(selectors.conversationPopperAnchorEl);
  const conversations = useSelector(selectors.getRealogramConversationData);

  const getConversationMessagesLength = (): number => {
    const conversationData = conversations.find(x => x.shelfReferenceId === realogram.id);
    if (!conversationData) {
      return 0;
    } else {
      return conversationData.conversationMessages.length;
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const onKeyDown = (evt: KeyboardEvent): void => {
      if (evt.key === Key.Escape) {
        dispatch(selectShelfLocationAction(undefined));
      }
    };
    window.addEventListener('keydown', onKeyDown);
    return (): void => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [dispatch]);

  return (
    <div style={{ marginTop: '5px' }}>
      <ReadonlyPlanogram
        maxHeight="88vh"
        width="100%"
        svgWidth={realogram.shelfImageUrlWidth}
        svgHeight={realogram.shelfImageUrlHeight}
        svgBgImageUrl={realogram.shelfImageUrl}
        shelfLocations={shelfLocations}
        onShelfLocationClick={(shelfLocationId: string): void => {
          dispatch(selectShelfLocationAction(shelfLocationId));
        }}
        onEmptyClick={(): void => {
          dispatch(selectShelfLocationAction(undefined));
        }}
      />
      {selectedShelfLocation && <RealogramProductPanel />}
      <Fab
        size="large"
        className="fabChat"
        color="primary"
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onClick={(evt: any): void => {
          dispatch(openRealogramConversationPopperAction(evt.currentTarget));
        }}
      >
        <Badge color="secondary" badgeContent={getConversationMessagesLength()}>
          <Chat />
        </Badge>
      </Fab>
      {isOpenConversationPopper && conversationPopperAnchorEl && (
        <ConversationPopperIndex
          shelfReferenceId={realogram.id}
          conversationAnchorEl={conversationPopperAnchorEl}
          isOpen={isOpenConversationPopper}
          handleOnSubmitMessage={(message: string): void => {
            dispatch(postMessageToConversationRequestAction(realogram.id, message));
          }}
          handleOnSubmitEdit={(messageId: string, userId: number, editedMessage: string): void => {
            dispatch(updateMessageInConversationRequestAction(realogram.id, messageId, userId, editedMessage));
          }}
          handleOnSubmitDelete={(messageId: string): void => {
            dispatch(deleteMessageInConversationRequestAction(realogram.id, messageId));
          }}
        />
      )}
    </div>
  );
};

export default RealogramComponent;
