import { AdminActionRulesViewModel } from './AdminActionRulesViewModel';

import { Type } from 'class-transformer';
import { ShelfConditionsModel } from '../../../../types/ShelfConditionsModel';
import { InspirationImagesModel } from '../../../../types/InspirationImagesModel';

export default class AdminBuilderPlanogramViewModel {
  id = '';
  name = '';
  email = '';
  ownerId = 0;
  builderId = 0;
  createdOn: Date = new Date();

  @Type(() => Date)
  lastOpenedByBuilder?: Date;

  @Type(() => Date)
  lastEditedByBuilder?: Date;

  imageUrl = '';

  dataInsights: string[] = [];
  actionRules: AdminActionRulesViewModel = new AdminActionRulesViewModel([]);
  shelfConditions: ShelfConditionsModel = {
    isShelfConditionsEnabled: false,
  };
  inspirationImages: InspirationImagesModel = {
    isInspirationImagesEnabled: false,
  };
}
