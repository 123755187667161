export function isValidEmailFormat(email: string): boolean {
  // Todo: the code gives a eslint warning. Please fix it (I can't do it, I don't understand it).
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function toMaxPrecision(number: number | undefined, precision: number): number | undefined {
  if (number !== 0 && !number) {
    return undefined;
  }
  return parseFloat(number.toFixed(precision));
}

export function toMaxPrecisionString(number: number | undefined, precision: number): string {
  if (number !== 0 && !number) {
    return '';
  }
  return parseFloat(number.toFixed(precision)).toString();
}

export function toMaxPrecisionPercent(number: number | undefined, precision: number): string {
  if (number !== 0 && !number) {
    return '';
  }
  return number.toLocaleString('en', {
    style: 'percent',
    maximumFractionDigits: precision,
  });
}

// Convert a number to percentage without rounding digits
export function toMaxPrecisionPercentFloored(number: number | undefined, precision: number): number | undefined {
  if (!number) {
    return undefined;
  }
  const trailingPrecision = Math.pow(10, precision);
  return Math.trunc(number * trailingPrecision * 100) / trailingPrecision;
}
