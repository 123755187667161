import Select from 'react-select';
import React from 'react';
import { GpcBrickOption } from 'modules/reports/types';

export interface GpcBrickSelectorProps {
  htmlId: string;
  gpcBrickOptions: GpcBrickOption[];
  selectedGpcBrick?: GpcBrickOption;
  handleOnSelectGpcBrick(selectedGpcBrick: GpcBrickOption): void;
}
export const GpcBrickSelector: React.FC<GpcBrickSelectorProps> = ({ htmlId, gpcBrickOptions, selectedGpcBrick, handleOnSelectGpcBrick }: GpcBrickSelectorProps) => {
  const defaultGpcBrickOption: GpcBrickOption = {
    label: 'Select Gpc Brick',
    gpcBrickCode: -1,
    gpcBrickDescription: '',
    value: -1,
  };
  return (
    <Select
      styles={{
        // Fixes the overlapping problem of the component
        menu: provided => ({ ...provided, zIndex: 9999 }),
      }}
      inputId={htmlId}
      placeholder="Select Gpc Brick"
      options={gpcBrickOptions}
      value={selectedGpcBrick === undefined ? defaultGpcBrickOption : selectedGpcBrick}
      onChange={(gpcBrick: GpcBrickOption): void => handleOnSelectGpcBrick(gpcBrick)}
      isMulti={false}
      isDisabled={gpcBrickOptions.length === 0}
    />
  );
};
