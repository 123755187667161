import { Badge, Card, CardContent, CardHeader, Divider, IconButton, Table, TableBody, TableHead, Tooltip, Typography } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp, Info, PhotoFilter } from '@material-ui/icons';
import trackComponent from 'infrastructure/tracking/withTracking';
import { isError, isLoaded, isLoading } from 'infrastructure/utils/RemoteObjectStatus';
import { orderBy, uniqBy } from 'lodash';
import { AlertErrorTextIndicator } from 'modules/shared/components/LoadStatusIndicators/AlertErrorTextIndicator';
import * as React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import Alert from 'reactstrap/lib/Alert';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import { StyledTableCell, StyledTableRow } from 'scss/MaterialStyles';
import { State } from 'state';
import * as actions from '../actions';
import AdminPlanogramsState from '../state';
import AdminPlanogramViewModel from '../types/AdminPlanogramViewModel';
import CompetitorShelf from '../types/CompetitorShelf';
import CompetitorShelfDataset from '../types/CompetitorShelfDataset';
import BuildersListModal from './BuildersListModal';
import ShelfPlanogramsModal from './ShelfPlanogramsModal';

interface AdminPlanogramsPageState {
  orderByProperty: string;
  orderByDescending: boolean;
  showPlanogramsForProjectId?: string;
  showPlanogramBuilders?: AdminPlanogramViewModel;
}

interface AdminPlanogramsPageProps {
  userId: number;
}

class AdminPlanogramsPage extends React.Component<AdminPlanogramsState & AdminPlanogramsPageProps & DispatchProps, AdminPlanogramsPageState> {
  constructor(props: AdminPlanogramsState & AdminPlanogramsPageProps & DispatchProps) {
    super(props);
    this.state = {
      orderByDescending: false,
      orderByProperty: 'projectName',
    };
  }

  componentDidMount() {
    this.props.loadAdminPlanogramsRequest();
    this.props.loadAdminCompetitorShelvesRequest();
  }

  render() {
    return (
      <div className="animated fadeIn" style={{ marginTop: '1.5rem' }}>
        {isError(this.props.loadAdminPlanogramsStatus) ? (
          <AlertErrorTextIndicator errorText="Error loading planograms." />
        ) : (
          <LoadingOverlay active={isLoading(this.props.loadAdminPlanogramsStatus)} spinner={true} text="Loading users...">
            <Row>
              <Col xs="12">
                <Card raised={true} className="top-buffer-sm">
                  <CardHeader title="Filter" titleTypographyProps={{ color: 'primary' }} />
                  <Divider light variant="middle" />
                  <CardContent>{this.renderFilteringForm()}</CardContent>
                </Card>
                <Card className="top-buffer" raised={true}>
                  <CardHeader title="Planograms" />
                  <Divider light variant="middle" />
                  <CardContent>{this.renderPlanogramsTable()}</CardContent>
                </Card>
              </Col>
            </Row>
            {this.renderShelfPlanogramsModal()}
            {this.renderBuildersListModal()}
          </LoadingOverlay>
        )}
      </div>
    );
  }

  renderFilteringForm() {
    return null;
  }

  renderPlanogramsTable() {
    if (!isLoaded(this.props.loadAdminPlanogramsStatus)) {
      return null;
    }
    if (!this.props.loadAdminPlanogramsStatus.data?.length) {
      return (
        <Alert color="warning">
          <Row>
            <Info color="primary" />
            &nbsp;
            <Typography color="textSecondary" variant="body2">
              No planograms found.
            </Typography>
          </Row>
        </Alert>
      );
    }
    return (
      <Table size="medium" className="sticky-header-table">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell style={{ cursor: 'pointer' }} align="center" onClick={() => this.changeOrderBy('projectName')}>
              <Typography variant="subtitle1">
                Project Name &nbsp;
                {this.getOrderByIcon('projectName')}
              </Typography>
            </StyledTableCell>
            <StyledTableCell style={{ cursor: 'pointer' }} align="center" onClick={() => this.changeOrderBy('storeName')}>
              <Typography variant="subtitle1">
                Store Name
                {this.getOrderByIcon('storeName')}
              </Typography>
            </StyledTableCell>
            <StyledTableCell style={{ cursor: 'pointer' }} align="center" onClick={() => this.changeOrderBy('phaseNum')}>
              <Typography variant="subtitle1">
                Phase Num
                {this.getOrderByIcon('phaseNum')}
              </Typography>
            </StyledTableCell>
            <StyledTableCell style={{ cursor: 'pointer' }} align="center" onClick={() => this.changeOrderBy('shelfNum')}>
              <Typography variant="subtitle1">
                Shelf Num
                {this.getOrderByIcon('shelfNum')}
              </Typography>
            </StyledTableCell>
            <StyledTableCell align="center">
              <Typography variant="subtitle1">Original Shelf</Typography>
            </StyledTableCell>
            <StyledTableCell>
              <Typography variant="subtitle1">Planograms</Typography>
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>{this.renderPlanograms()}</TableBody>
      </Table>
    );
  }

  renderPlanograms() {
    let byProject = uniqBy(this.props.loadAdminPlanogramsStatus.data, p => {
      return p.shelfReferenceId;
    });
    byProject = orderBy(byProject, [this.state.orderByProperty, 'projectName', 'storeName', 'phaseNum', 'shelfNum'], [this.state.orderByDescending ? 'desc' : 'asc']);

    const rows = byProject.map(project => {
      const projectsOfSameType = this.props.loadAdminPlanogramsStatus.data?.filter(p => p.shelfReferenceId === project.shelfReferenceId);
      const planogramsCount = projectsOfSameType ? projectsOfSameType.length : 0;
      return (
        <StyledTableRow key={project.shelfReferenceId}>
          <StyledTableCell align="center">{project.projectName}</StyledTableCell>
          <StyledTableCell align="center">{project.storeName}</StyledTableCell>
          <StyledTableCell align="center">{project.phaseNum}</StyledTableCell>
          <StyledTableCell align="center">{project.shelfNum}</StyledTableCell>
          <StyledTableCell align="center">
            <a href={project.shelfImageUrl} target="_blank" rel="noopener noreferrer">
              <img style={{ maxWidth: 100, maxHeight: 30 }} src={project.shelfImageUrl} alt="" />
            </a>
          </StyledTableCell>
          <StyledTableCell>
            <Tooltip title="View Planograms">
              <IconButton
                onClick={() => {
                  this.setState({ showPlanogramsForProjectId: project.shelfReferenceId });
                }}
              >
                <Badge color="secondary" badgeContent={planogramsCount}>
                  <PhotoFilter color="primary" fontSize="large" />
                </Badge>
              </IconButton>
            </Tooltip>
          </StyledTableCell>
        </StyledTableRow>
      );
    });

    return rows;
  }

  renderShelfPlanogramsModal() {
    if (this.state.showPlanogramsForProjectId === undefined) {
      return null;
    }
    const projectsOfSameType = this.props.loadAdminPlanogramsStatus.data?.filter(p => p.shelfReferenceId === this.state.showPlanogramsForProjectId) ?? [];
    return (
      <ShelfPlanogramsModal
        onClose={() => this.setState({ showPlanogramsForProjectId: undefined })}
        onViewBuilders={(p: AdminPlanogramViewModel) => this.setState({ showPlanogramBuilders: p })}
        userId={this.props.userId}
        planograms={projectsOfSameType}
        competitorShelvesState={this.props.competitorShelvesState}
        updateCompetitorShelvesState={this.props.updateCompetitorShelvesState}
        competitorShelvesDatasetsStatus={this.props.competitorShelvesDatasetsStatus}
        loadCompetitorShelvesDatasets={(planogramId: string) => this.props.loadAdminCompetitorShelvesDatasetsRequest(planogramId)}
        updateCompetitorShelves={(planogramId: string, competitorShelves: CompetitorShelf[]) => this.props.updateAdminCompetitorShelvesRequest(planogramId, competitorShelves)}
        uploadCompetitorShelfDatasetRequest={(dataset: CompetitorShelfDataset) => this.props.uploadCompetitorShelfDatasetRequest(dataset)}
        uploadCompetitorShelfDatasetState={this.props.uploadCompetitorShelfDatasetState}
        deleteCompetitorShelfDataset={(dataset: CompetitorShelfDataset) => this.props.deleteCompetitorShelfDatasetRequest(dataset)}
        deleteCompetitorShelfDatasetState={this.props.deleteCompetitorShelfDatasetState}
      />
    );
  }

  renderBuildersListModal() {
    if (this.state.showPlanogramBuilders === undefined) {
      return null;
    }

    return (
      <BuildersListModal
        planogram={this.state.showPlanogramBuilders}
        onClose={() => this.setState({ showPlanogramBuilders: undefined })}
        loadBuilderPlanogramsRequest={(planogram: AdminPlanogramViewModel) => this.props.loadAdminPlanogramBuildersRequest(planogram.planogramId)}
        builderPlanograms={this.props.loadAdminBuildPlanogramsStatus}
      />
    );
  }

  changeOrderBy(propertyName: string) {
    const descending = this.state.orderByProperty === propertyName ? !this.state.orderByDescending : false;
    this.setState({
      orderByProperty: propertyName,
      orderByDescending: descending,
    });
  }

  getOrderByIcon(propertyName: string) {
    if (this.state.orderByProperty !== propertyName) {
      return null;
    }

    if (this.state.orderByDescending) {
      return <ArrowDropDown />;
    } else {
      return <ArrowDropUp />;
    }
  }
}

interface DispatchProps {
  loadAdminPlanogramsRequest(): void;
  loadAdminPlanogramBuildersRequest(planogramId: string): void;
  loadAdminCompetitorShelvesRequest(): void;
  loadAdminCompetitorShelvesDatasetsRequest(planogramId: string): void;
  updateAdminCompetitorShelvesRequest(planogramId: string, competitorShelves: CompetitorShelf[]): void;
  uploadCompetitorShelfDatasetRequest(dataset: CompetitorShelfDataset): void;
  deleteCompetitorShelfDatasetRequest(dataset: CompetitorShelfDataset): void;
}

function mapStateToProps(state: State) {
  return {
    ...state.adminPlanograms,
    userId: state.auth.applicationUser?.id,
  };
}

const componentToExport = connect<AdminPlanogramsState, DispatchProps, RouteComponentProps<any>>(mapStateToProps, actions)(AdminPlanogramsPage);

export default trackComponent(componentToExport, 'AdminPlanogramsPage');
