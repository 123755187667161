export const LOAD_CLIENTS_REQUEST = 'LOAD_CLIENTS_REQUEST';
export type LOAD_CLIENTS_REQUEST = typeof LOAD_CLIENTS_REQUEST;

export const LOAD_CLIENTS_SUCCESS = 'LOAD_CLIENTS_SUCCESS';
export type LOAD_CLIENTS_SUCCESS = typeof LOAD_CLIENTS_SUCCESS;

export const LOAD_CLIENTS_FAILURE = 'LOAD_CLIENTS_FAILURE';
export type LOAD_CLIENTS_FAILURE = typeof LOAD_CLIENTS_FAILURE;

export const LOAD_CLIENT_DATASETS_REQUEST = 'LOAD_CLIENT_DATASETS_REQUEST';
export type LOAD_CLIENT_DATASETS_REQUEST = typeof LOAD_CLIENT_DATASETS_REQUEST;

export const LOAD_CLIENT_DATASETS_SUCCESS = 'LOAD_CLIENT_DATASETS_SUCCESS';
export type LOAD_CLIENT_DATASETS_SUCCESS = typeof LOAD_CLIENT_DATASETS_SUCCESS;

export const LOAD_CLIENT_DATASETS_FAILURE = 'LOAD_CLIENT_DATASETS_FAILURE';
export type LOAD_CLIENT_DATASETS_FAILURE = typeof LOAD_CLIENT_DATASETS_FAILURE;

export const UPLOAD_CLIENT_DATASET_REQUEST = 'UPLOAD_CLIENT_DATASET_REQUEST';
export type UPLOAD_CLIENT_DATASET_REQUEST = typeof UPLOAD_CLIENT_DATASET_REQUEST;

export const UPLOAD_CLIENT_DATASET_SUCCESS = 'UPLOAD_CLIENT_DATASET_SUCCESS';
export type UPLOAD_CLIENT_DATASET_SUCCESS = typeof UPLOAD_CLIENT_DATASET_SUCCESS;

export const UPLOAD_CLIENT_DATASET_FAILURE = 'UPLOAD_CLIENT_DATASET_FAILURE';
export type UPLOAD_CLIENT_DATASET_FAILURE = typeof UPLOAD_CLIENT_DATASET_FAILURE;

export const DELETE_CLIENT_DATASET_REQUEST = 'DELETE_CLIENT_DATASET_REQUEST';
export type DELETE_CLIENT_DATASET_REQUEST = typeof DELETE_CLIENT_DATASET_REQUEST;

export const DELETE_CLIENT_DATASET_SUCCESS = 'DELETE_CLIENT_DATASET_SUCCESS';
export type DELETE_CLIENT_DATASET_SUCCESS = typeof DELETE_CLIENT_DATASET_SUCCESS;

export const DELETE_CLIENT_DATASET_FAILURE = 'DELETE_CLIENT_DATASET_FAILURE';
export type DELETE_CLIENT_DATASET_FAILURE = typeof DELETE_CLIENT_DATASET_FAILURE;
