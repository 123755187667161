import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import configuration from 'config';

class TelemetryService {
  isEnabled = true;

  private reactPlugin: ReactPlugin = new ReactPlugin();
  private appInsights?: ApplicationInsights;

  initialize(reactPluginConfig: any) {
    const INSTRUMENTATION_KEY = configuration.tracking.telemetryKey;

    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: INSTRUMENTATION_KEY,
        disableTelemetry: !configuration.tracking.telemetryEnabled,
        maxBatchInterval: 0,
        disableFetchTracking: false,
        extensions: [this.reactPlugin],
        extensionConfig: {
          [this.reactPlugin.identifier]: reactPluginConfig,
        },
      },
    });
    this.appInsights.loadAppInsights();
  }

  getReactPlugin(): ReactPlugin {
    return this.reactPlugin;
  }

  getInstance() {
    return this.appInsights as ApplicationInsights;
  }

  setUserId(userId: string) {
    if (this.appInsights === undefined || !this.isEnabled) {
      return;
    }
    this.appInsights.setAuthenticatedUserContext(userId, undefined, true);
    this.appInsights.config.accountId = userId;
  }
}

export const telemetryService = new TelemetryService();
