import * as React from 'react';
import { TableHead, Table, TableRow, TableCell, TableBody, IconButton } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { getAvailableSalesReports } from './selectors';
import * as actions from './actions';
import { DeleteForever } from '@material-ui/icons';

const ManageSalesData: React.FC = (): JSX.Element => {
  const salesReports = useSelector(getAvailableSalesReports);
  const dispatch = useDispatch();
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {salesReports.map(sr => {
          return (
            <TableRow key={sr.id}>
              <TableCell>{sr.reportName}</TableCell>
              <TableCell>
                <IconButton
                  onClick={(): void => {
                    dispatch(actions.deleteSalesDataRequest(sr.shelfReferenceId, sr.id));
                  }}
                >
                  <DeleteForever color="secondary" />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default ManageSalesData;
