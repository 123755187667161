import AdminBrandRuleViewModel from './AdminBrandRuleModelViewModel';

export class AdminActionRulesViewModel {
  // NOTE: If new rules are added here, be sure to update method 'ifSelectionsAreUnchanged()' in EditBuilderRulesModal, and respective DTO's on the backend.
  isDisabled = false;
  canAddNewShelfLocations = false;
  brandRules: AdminBrandRuleViewModel[] = [];

  selectAllCanView = false;
  selectAllCanMove = false;
  selectAllCanDelist = false;
  selectAllCanIncreaseSize = false;
  selectAllCanDecreaseSize = false;

  constructor(brandRules: AdminBrandRuleViewModel[], byPassRules = false) {
    this.isDisabled = byPassRules;
    this.brandRules = brandRules;
  }
}
