import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import * as selectors from '../../selectors';
import { ProjectBenchmarkReportResult } from '../../types';
import { loadStandardProjectBenchmarkReportRequestAction } from '../../actions';
import { isLoaded, isLoading } from 'infrastructure/utils/RemoteObjectStatus';
interface LoadStatusParams {
  isLoaded: boolean;
  isLoading: boolean;
  loadError: string;
  isDataEmpty: boolean;
  data: ProjectBenchmarkReportResult | undefined;
}
export interface ProjectBenchmarkReportLoaderProps {
  children: (params: LoadStatusParams) => React.ReactNode;
}

const ProjectBenchmarkReportLoader: React.FC<ProjectBenchmarkReportLoaderProps> = ({ children }: ProjectBenchmarkReportLoaderProps) => {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const loadState = useSelector(selectors.getProjectBenchmarkReportLoadState);
  const isDataEmpty = useSelector(selectors.isProjectBenchmarkReportEmpty);
  const data = useSelector(selectors.getProjectBenchmarkReportData);

  useEffect(() => {
    if (isDataEmpty && projectId) {
      dispatch(loadStandardProjectBenchmarkReportRequestAction(parseInt(projectId)));
    }
  }, [dispatch, isDataEmpty, projectId]);
  return (
    <>
      {children({
        isLoaded: isLoaded(loadState),
        isLoading: isLoading(loadState),
        loadError: loadState?.errorDetails?.errorMessage ?? '',
        isDataEmpty,
        data,
      })}
    </>
  );
};

export { ProjectBenchmarkReportLoader };
