import * as actionTypes from './actionTypes';
import ErrorDetails from 'infrastructure/exception/ErrorDetails';
import { TimeSalesDataReport, TimeSalesData } from './types';

export function uploadSalesDataRequest(shelfReferenceId: string, reportName: string, data: TimeSalesData[]): actionTypes.UploadSalesDataRequestAction {
  return {
    type: actionTypes.SHELVES_TIMELINE_UPLOAD_SALES_REQUEST,
    shelfReferenceId,
    reportName,
    data,
  };
}

export function uploadSalesDataSuccess(newReport: TimeSalesDataReport): actionTypes.UploadSalesDataSuccessAction {
  return {
    type: actionTypes.SHELVES_TIMELINE_UPLOAD_SALES_SUCCESS,
    newReport,
  };
}

export function uploadSalesDataFailed(error: ErrorDetails): actionTypes.UploadSalesDataErrorAction {
  return {
    type: actionTypes.SHELVES_TIMELINE_UPLOAD_SALES_ERROR,
    error: error,
  };
}

export function deleteSalesDataRequest(shelfReferenceId: string, reportId: string): actionTypes.DeleteSalesDataRequestAction {
  return {
    reportId,
    shelfReferenceId,
    type: actionTypes.SHELVES_TIMELINE_DELETE_SALES_DATA_REQUEST,
  };
}

export function getSalesDataRequest(shelfReferenceId: string): actionTypes.GetSalesDataRequestAction {
  return {
    shelfReferenceId,
    type: actionTypes.SHELVES_TIMELINE_GET_SALES_DATA_REQUEST,
  };
}

export function getSalesDataSuccess(data: TimeSalesDataReport[]): actionTypes.GetSalesDataSuccessAction {
  return {
    type: actionTypes.SHELVES_TIMELINE_GET_SALES_DATA_SUCCESS,
    data,
  };
}

export function getSalesDataError(error: ErrorDetails): actionTypes.GetSalesDataErrorAction {
  return {
    type: actionTypes.SHELVES_TIMELINE_GET_SALES_DATA_ERROR,
    error,
  };
}
