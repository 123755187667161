import axios, { AxiosInstance } from 'axios';
import config from 'config';

const apiClient: AxiosInstance = axios.create({
  baseURL: config.apiRootUri,
});

export function setAuthHeader(token?: string): void {
  if (token) {
    apiClient.defaults.headers.Authorization = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common.authorization;
  }
}

export interface ApiResponse<T> {
  data: T;
}

export default apiClient;
