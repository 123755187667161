import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import * as selectors from '../../selectors';
import { ClientBenchmarkReportResult } from '../../types';
import { loadStandardClientBenchmarkReportRequestAction } from '../../actions';
import { isLoaded, isLoading } from 'infrastructure/utils/RemoteObjectStatus';
interface LoadStatusParams {
  isLoaded: boolean;
  isLoading: boolean;
  loadError: string;
  isDataEmpty: boolean;
  data: ClientBenchmarkReportResult | undefined;
}
export interface ClientBenchmarkReportLoaderProps {
  children: (params: LoadStatusParams) => React.ReactNode;
}

const ClientBenchmarkReportLoader: React.FC<ClientBenchmarkReportLoaderProps> = ({ children }: ClientBenchmarkReportLoaderProps) => {
  const { clientId } = useParams();
  const dispatch = useDispatch();
  const loadState = useSelector(selectors.getClientBenchmarkReportLoadState);
  const isDataEmpty = useSelector(selectors.isClientBenchmarkReportEmpty);
  const data = useSelector(selectors.getClientBenchmarkReportData);

  useEffect(() => {
    if (isDataEmpty && clientId) {
      dispatch(loadStandardClientBenchmarkReportRequestAction(parseInt(clientId)));
    }
  }, [dispatch, isDataEmpty, clientId]);
  return (
    <>
      {children({
        // TODO : todo just pass LoadState?
        isLoaded: isLoaded(loadState),
        isLoading: isLoading(loadState),
        loadError: loadState?.errorDetails?.errorMessage ?? '',
        isDataEmpty,
        data,
      })}
    </>
  );
};

export { ClientBenchmarkReportLoader };
