/* eslint-disable @typescript-eslint/camelcase */
import { isLoading } from 'infrastructure/utils/RemoteObjectStatus';
import { getQueryStringByName } from 'infrastructure/utils/url';
import { orderBy } from 'lodash';
import { ClientOption, ProjectOption } from 'modules/reports/types';
import moment from 'moment';
import { TimelineGroupBase, TimelineItemBase } from 'react-calendar-timeline';
import { createSelector, Selector } from 'reselect';
import { State } from '../../state';
import { ShelfPhaseDetails, TimelineCalendarData, TimelinesData, TimelinesState } from './types';

const getGlobalState = (state: State): State => state;
const getTimelinesState = (state: State): TimelinesState => state.timelines;

const getTimelinesData: Selector<State, TimelinesData> = createSelector(getTimelinesState, state => {
  return state.timelinesObject.data as TimelinesData;
});

export const isTimelinesLoading: Selector<State, boolean> = createSelector(getTimelinesState, state => {
  return isLoading(state.timelinesObject);
});

export const getTimelinesLoadError: Selector<State, string> = createSelector(getTimelinesState, state => {
  return state.timelinesObject.errorDetails?.errorMessage ?? '';
});

export const isTimelinesDataEmpty: Selector<State, boolean> = createSelector(getTimelinesData, data => {
  return data === ({} as TimelinesData) || data?.projectStoreShelves === null || data?.projectStoreShelves?.length === 0;
});

export const getCalendarStartDate: Selector<State, string> = createSelector(getTimelinesState, state => {
  return state.startDateOfTimeline;
});

export const getCalendarEndDate: Selector<State, string> = createSelector(getTimelinesState, state => {
  return state.endDateOfTimeline;
});

export const getViewAs: Selector<State, string> = createSelector(getGlobalState, () => {
  return getQueryStringByName('viewAs');
});

export const getCalendarShelvesData: Selector<State, TimelineCalendarData> = createSelector(getTimelinesState, state => {
  // TODO: consider splitting this selector into multiple smaller selectors if it gets too complex
  const projectShelves: TimelineGroupBase[] = [];
  const shelvesPhases: TimelineItemBase<number>[] = [];
  const shelfPhaseDetailsDict: { [id: number]: ShelfPhaseDetails } = {};
  let clientOptions: ClientOption[] = [];
  let projectOptions: ProjectOption[] = [];
  const timelinesData = state.timelinesObject.data ?? ({} as TimelinesData);
  const selectedClientOption = state.selectedClientOption;
  const selectedProjectOption = state.selectedProjectOption;

  let projectStoreShelves = timelinesData.projectStoreShelves ?? [];

  // construct client options
  projectStoreShelves.forEach(shelf => {
    if (!clientOptions.some(c => c.value === shelf.clientId)) {
      clientOptions.push({ value: shelf.clientId, label: shelf.clientName, clientName: shelf.clientName, clientId: shelf.clientId });
    }
    if (!projectOptions.some(c => c.value === shelf.projectId)) {
      projectOptions.push({ value: shelf.projectId, label: shelf.projectName, projectName: shelf.projectName, projectId: shelf.projectId });
    }
  });

  // if a client is selected - filter projectShelves for the selected client
  if (selectedClientOption) {
    projectStoreShelves = projectStoreShelves.filter(x => x.clientId === selectedClientOption.value);
    const projectIds = projectStoreShelves.map(x => x.projectId);
    projectOptions = projectOptions.filter(x => projectIds.includes(x.projectId));
  }
  if (selectedProjectOption) {
    projectStoreShelves = projectStoreShelves.filter(x => x.projectId === selectedProjectOption.value);
  }
  clientOptions = orderBy(clientOptions, ['label'], ['asc']);
  clientOptions.unshift({ label: 'ALL', value: -1, clientId: -1, clientName: 'ALL' });

  projectOptions = orderBy(projectOptions, ['label'], ['asc']);
  projectOptions.unshift({ label: 'ALL', value: -1, projectId: -1, projectName: 'ALL' });
  // construct projectStoreShelves, shelvesPhases and shelfPhaseDetails
  projectStoreShelves.forEach(shelf => {
    const projectShelf: TimelineGroupBase = {
      id: Number(`${shelf.projectId}${shelf.storeId}${shelf.shelfNum}`),
      title: `${shelf.projectName} - ${shelf.storeName} - Shelf ${shelf.shelfNum}`,
    };
    projectShelves.push(projectShelf);
    shelf.phases.forEach(phase => {
      const shelfPhase: TimelineItemBase<number> = {
        id: Number(`${shelf.projectId}${shelf.storeId}${shelf.shelfNum}${phase.phaseNum}`),
        group: Number(`${shelf.projectId}${shelf.storeId}${shelf.shelfNum}`),
        title: `${phase.phaseNum}`,
        start_time: moment(phase.startDate).valueOf(),
        end_time: moment(phase.endDate).valueOf(),
        canMove: false,
        canResize: false,
        canChangeGroup: false,
      };

      shelvesPhases.push(shelfPhase);
      shelfPhaseDetailsDict[shelfPhase.id] = {
        projectId: shelf.projectId,
        storeId: shelf.storeId,
        shelfNum: shelf.shelfNum,
        projectName: shelf.projectName,
        clientId: shelf.clientId,
        clientName: shelf.clientName,
        storeName: shelf.storeName,
        storeStreetAddress: shelf.storeStreetAddress,
        storeCity: shelf.storeCity,
        storeCountry: shelf.storeCountry,
        shelfReferenceId: phase.shelfReferenceId,
        startDate: phase.startDate,
        endDate: phase.endDate,
        shelfImageUrl: phase.imageUrl,
        isMappingQualityAssured: phase.isMappingQualityAssured,
        phaseDescription: phase.phaseDescription,
        phaseNum: phase.phaseNum,
        isReadyForReporting: phase.readyForReporting,
        projectType: phase.projectType,
      };
    });
  });

  return { projectShelves, shelvesPhases, shelfPhaseDetailsDict, clientOptions, projectOptions };
});

export const isTimelinesMenuOpen: Selector<State, boolean> = createSelector(getTimelinesState, state => {
  return state.isTimelinesMenuOpen;
});

export const getTimelinesMenuAnchorElement: Selector<State, Element | undefined> = createSelector(getTimelinesState, state => {
  return state.timelinesMenuAnchorEl;
});

export const getSelectedShelfPhaseDetails: Selector<State, ShelfPhaseDetails | undefined> = createSelector(getTimelinesState, state => {
  return state.selectedShelfPhase;
});

export const getSelectedClient: Selector<State, ClientOption | undefined> = createSelector(getTimelinesState, state => {
  return state.selectedClientOption;
});
export const getSelectedProject: Selector<State, ProjectOption | undefined> = createSelector(getTimelinesState, state => {
  return state.selectedProjectOption;
});
