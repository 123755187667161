import * as React from 'react';
import { Dispatch } from 'redux';
import { last, includes } from 'lodash';
import { POSITION_NONE, ReactSVGPanZoom, Tool, ToolbarPosition, Value } from 'react-svg-pan-zoom';

import ViewPort from '../../types/ViewPort';
import PlanogramModel from '../../domain/PlanogramModel';
import ShelfLocation from '../../domain/ShelfLocation';
import GeometryPoint from '../../../shared/geometry/GeometryPoint';
import { getCenterPoint } from '../../helpers/geometry';
import * as actions from '../../actions';
import ShelfLocationImageClipsList from './ShelfLocationImageClipComponents/ShelfLocationImageClipList';
import ShadowShelfLocationsList from './ShadowShelfLocationComponents/ShadowShelfLocationsList';
import ShelfLocationsList from './ShelfLocationComponents/ShelfLocationList';
import InsertCommand from '../../commands/InsertCommand';
import MoveCommand from '../../commands/MoveCommand';
import TransientDragPointCommand from '../../commands/TransientDragPointCommand';
import TransientMoveCommand from '../../commands/TransientMoveCommand';
import ShelfLocationGeometryPoint from 'modules/planogram/domain/ShelfLocationGeometryPoint';

type ReactSVGPanZoomWithViewerDOM = ReactSVGPanZoom & {
  ViewerDOM?: SVGSVGElement | null;
};

interface MyProps {
  viewPort: ViewPort;
  imageViewPort: ViewPort;
  planogram: PlanogramModel;
  initialPlanogram: PlanogramModel;
  dispatch: Dispatch;
  ratio: number;
  notSelectedShelfLocations: ShelfLocation[];
  selectedShelfLocations: ShelfLocation[];
  copyingShelfLocations: ShelfLocation[];
  svgPanZoomValue: Value;
}

interface MyState {
  tool: Tool;
  isDragging: boolean;
  hasDragged: boolean;
  dragStartX: number;
  dragStartY: number;
  draggingEdge?: ShelfLocationGeometryPoint;
  draggingShelfLocation?: ShelfLocation;
}

const miniatureProps = {
  position: POSITION_NONE as typeof POSITION_NONE,
  background: '#fff',
  width: 100,
  height: 80,
};

const toolbarProps = {
  position: POSITION_NONE as ToolbarPosition,
};

class PlanogramComponent extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {
      tool: 'auto',
      isDragging: false,
      hasDragged: false,
      dragStartX: 0,
      dragStartY: 0,
    };
  }

  svgElement: SVGSVGElement | null = null;
  currentMousePosition: GeometryPoint = { x: 0, y: 0 };

  render(): JSX.Element {
    const props = this.props;
    const ratio = props.ratio;
    const scaledWidth = props.viewPort.getWidth() / ratio;
    const scaledHeight = props.viewPort.getHeigth() / ratio;
    const startX = Math.abs((scaledWidth - props.imageViewPort.getWidth()) / 2);
    const startY = Math.abs((scaledHeight - props.imageViewPort.getHeigth()) / 2);
    const scaleFactor: number = this.props.svgPanZoomValue.a || 1;
    const moveX = this.currentMousePosition.x / scaleFactor / this.props.ratio;
    const moveY = this.currentMousePosition.y / scaleFactor / this.props.ratio;
    this.props.copyingShelfLocations.forEach(sl => {
      const centerPoint = getCenterPoint(sl.geometry.points);
      const centerX = moveX - startX - this.props.svgPanZoomValue.e / scaleFactor / this.props.ratio;
      const centerY = moveY - startY - this.props.svgPanZoomValue.f / scaleFactor / this.props.ratio;
      sl.geometry.points.forEach(p => {
        p.x = centerPoint.x - p.x + centerX;
        p.y = centerPoint.y - p.y + centerY;
        if (isNaN(p.x) || isNaN(p.y)) {
          p.x = 0;
          p.y = 0;
        }
      });
      sl.geometry.calculatePointsPosition();
    });

    return (
      <>
        <ReactSVGPanZoom
          width={props.viewPort.getWidth()}
          height={props.viewPort.getHeigth()}
          tool={this.state.tool}
          value={this.props.svgPanZoomValue}
          onChangeTool={(tool: Tool): void => {
            this.setState({ tool });
          }}
          onChangeValue={(value: Value): void => {
            this.props.dispatch(actions.onPlanogramSvgPanZoomValueUpdateReuqest(value));
          }}
          detectAutoPan={false}
          toolbarProps={toolbarProps}
          miniatureProps={miniatureProps}
          onMouseMove={this.onGlobalMouseMove}
          onMouseUp={this.onGlobalMouseUp}
          SVGBackground="#616264"
          onMouseDown={this.onGlobalMouseDown}
          preventPanOutside={true}
          ref={this.setReactSvgPanZoomRef}
        >
          <svg onContextMenu={this.onGlobalContextMenu} width={props.viewPort.getWidth()} height={props.viewPort.getHeigth()}>
            <g id="planogramSvgImg" transform={`scale(${ratio} ${ratio}) translate(${startX} ${startY})`}>
              <image x="0" y="0" href={props.planogram.imageUrl} width={props.planogram.shelfImageUrlWidth} height={props.planogram.shelfImageUrlHeight} />
              <g>
                <ShelfLocationImageClipsList shelfLocations={props.planogram.shelfLocations} />
                <ShelfLocationImageClipsList shelfLocations={props.copyingShelfLocations} />
              </g>
              <ShadowShelfLocationsList shelfLocations={props.initialPlanogram.shelfLocations} />
              <ShelfLocationsList
                onShelfLocationMouseDown={this.onShelfLocationMouseDown}
                isPrintMode={false}
                ratio={ratio}
                shelfLocations={props.notSelectedShelfLocations}
                isSelected={false}
                onShelfLocationEdgeMouseDown={(): void => {
                  return;
                }}
                isCopying={false}
                onShelfLocationContextMenu={(element, shelfLocation): void => {
                  if (this.props.planogram.isReadOnly) {
                    return;
                  }
                  props.dispatch(actions.onPlanogramContextMenuOpenRequest(true, shelfLocation, element));
                }}
              />
              <ShelfLocationsList
                onShelfLocationMouseDown={this.onShelfLocationMouseDown}
                isPrintMode={false}
                ratio={ratio}
                shelfLocations={props.selectedShelfLocations}
                isSelected={true}
                onShelfLocationEdgeMouseDown={this.onShelfLocationEdgeMouseDown}
                isCopying={false}
                onShelfLocationContextMenu={(element, shelfLocation): void => {
                  if (this.props.planogram.isReadOnly) {
                    return;
                  }
                  props.dispatch(actions.onPlanogramContextMenuOpenRequest(true, shelfLocation, element));
                }}
              />
              <ShelfLocationsList
                onShelfLocationMouseDown={this.onShelfLocationMouseDown}
                isPrintMode={false}
                ratio={ratio}
                shelfLocations={props.copyingShelfLocations}
                isSelected={true}
                onShelfLocationEdgeMouseDown={this.onShelfLocationEdgeMouseDown}
                isCopying={true}
                onShelfLocationContextMenu={(): void => {
                  return;
                }}
              />
            </g>
          </svg>
        </ReactSVGPanZoom>
      </>
    );
  }

  private setReactSvgPanZoomRef = (elem: ReactSVGPanZoomWithViewerDOM | null): void => {
    if (elem && elem.ViewerDOM) {
      // a hack of the SVG PAN ZOOM tool to allow mouse events outside the region of interest
      const htmlElement = elem.ViewerDOM;
      const rectElement = htmlElement.children[0] as HTMLElement;
      rectElement.style.pointerEvents = '';
      this.svgElement = elem.ViewerDOM;
    }
  };

  private onShelfLocationMouseDown = (sl: ShelfLocation, evt: React.MouseEvent): void => {
    if (this.props.copyingShelfLocations.length > 0) {
      const command = new InsertCommand(this.props.planogram, this.props.copyingShelfLocations);
      this.props.dispatch(actions.onCommandExecutionRequest(command));
      this.props.dispatch(actions.onCancelCopyingShelfLocationsRequest());
    }
    this.setState({
      isDragging: true,
      dragStartX: this.currentMousePosition.x,
      dragStartY: this.currentMousePosition.y,
    });

    if (includes(this.props.selectedShelfLocations, sl)) {
      // if already selected shelf location, ignore so it can be dragged without deselecting other shelf locations.
      this.props.dispatch(actions.onSelectShelfLocationsRequest([sl], true));
      return;
    }
    const shouldAppendToSelection = evt.ctrlKey;
    this.props.dispatch(actions.onSelectShelfLocationsRequest([sl], shouldAppendToSelection));
    this.setState({ hasDragged: false, isDragging: true });
  };

  private onGlobalMouseDown = (): void => {
    this.props.dispatch(actions.onSelectShelfLocationsRequest([], false));
    if (this.props.planogram.isReadOnly) {
      return;
    }
    this.setState({ hasDragged: false, isDragging: false });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onGlobalMouseMove = (svgPanZoomEvent: any): void => {
    if (this.props.planogram.isReadOnly) {
      return;
    }
    const evt: React.MouseEvent = svgPanZoomEvent.originalEvent;
    this.currentMousePosition = this.getMousePositionRelativeToSvg(evt);

    if (this.props.copyingShelfLocations.length > 0) {
      this.forceUpdate();
      return;
    }
    if (!this.state.isDragging) {
      return;
    }

    const scaleFactor = this.getScaleFactorFromEvent(svgPanZoomEvent);
    const movePoint = this.getOffsetPoint(scaleFactor);

    let hasDragged = false;

    if (this.dragEdge(movePoint) || this.dragShelfLocation(movePoint, evt.ctrlKey)) {
      hasDragged = true;
    }
    this.setState({ hasDragged });
    return;
  };

  private onGlobalMouseUp = (): void => {
    if (this.props.planogram.isReadOnly) {
      return;
    }
    if (this.state.hasDragged) {
      const allMovingSls = this.props.selectedShelfLocations;
      if (allMovingSls.length > 0) {
        const command = new MoveCommand(this.props.planogram, allMovingSls);
        this.props.dispatch(actions.onCommandExecutionRequest(command));
      }
    }
    this.setState({ isDragging: false, hasDragged: false, draggingEdge: undefined, draggingShelfLocation: undefined });
    return;
  };

  private onGlobalContextMenu(): void {
    return;
  }

  private onShelfLocationEdgeMouseDown = (sl: ShelfLocation, point: ShelfLocationGeometryPoint): void => {
    if (this.props.planogram.isReadOnly) {
      return;
    }
    this.setState({ draggingEdge: point, draggingShelfLocation: sl });
  };

  /**
   * Function to do dragging of shelf location edge, if possible.
   * @param offsetPoint The current point where the mouse is on the shelf
   * @returns true if dragging of edge has ocurred, otherwise false.
   */
  private dragEdge(offsetPoint: GeometryPoint): boolean {
    if (!this.state.draggingEdge || !this.state.draggingShelfLocation) {
      return false;
    }
    const command = new TransientDragPointCommand(this.props.planogram, this.state.draggingShelfLocation, this.state.draggingEdge, offsetPoint);
    this.props.dispatch(actions.onCommandExecutionRequest(command));
    return true;
  }

  /**
   * Function to do dragging of shelf location, if any is selected
   * @param offsetPoint The current point where the mouse is on the shelf
   * @param isCtrlPressed Whether the CTRL key is pressed
   * @returns true if dragging of shelf location has ocurred, otherwise false.
   */
  private dragShelfLocation(offsetPoint: GeometryPoint, isCtrlPressed: boolean): boolean {
    if (this.props.selectedShelfLocations.length === 0) {
      return false;
    }
    const lastShelfLocation = last(this.props.selectedShelfLocations);
    if (!lastShelfLocation) {
      return false;
    }
    const selectedShelfLocations = isCtrlPressed ? this.props.selectedShelfLocations : [lastShelfLocation];
    const command = new TransientMoveCommand(this.props.planogram, selectedShelfLocations, offsetPoint);
    this.props.dispatch(actions.onCommandExecutionRequest(command));
    return true;
  }

  private getOffsetPoint(scaleFactor: number): GeometryPoint {
    const moveX = (this.currentMousePosition.x - this.state.dragStartX) / scaleFactor / this.props.ratio;
    const moveY = (this.currentMousePosition.y - this.state.dragStartY) / scaleFactor / this.props.ratio;
    const movePoint: GeometryPoint = {
      x: moveX,
      y: moveY,
    };
    return movePoint;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getScaleFactorFromEvent(svgPanZoomEvent: any): number {
    const scaleFactor = isNaN(svgPanZoomEvent.scaleFactor) ? 1 : svgPanZoomEvent.scaleFactor;
    return scaleFactor;
  }

  private getMousePositionRelativeToSvg(evt: React.MouseEvent): GeometryPoint {
    if (!this.svgElement) {
      return { x: 0, y: 0 };
    }
    const target = this.svgElement;
    const rect = target.getBoundingClientRect();
    const x = evt.clientX - rect.left;
    const y = evt.clientY - rect.top;
    return {
      x,
      y,
    } as GeometryPoint;
  }
}

export default PlanogramComponent;
