import React from 'react';
import moment from 'moment';
import { Grid, Typography } from '@material-ui/core';
import flowLogo from '../../../../../assets/img/brand/flow.jpg';
import { ProductBenchmarkReport, ProductBenchmarkOverviewTitle, ProductBenchmarkOverviewRates, ProductBenchmarkOverviewAverage, ProductBenchmarkDemographics } from '../../types';
import { InteractorComparisonStackedBarChart, GenerateBarItems } from '../InteractorComparisonStackedBarChart';
import { toMaxPrecisionPercent, toMaxPrecision } from 'utils/Helpers';

interface ProductBenchmarkReportPageProps {
  report: ProductBenchmarkReport;
}

function RenderDescription(title: ProductBenchmarkOverviewTitle): React.ReactNode {
  return (
    <div>
      <div className="row">
        <div style={{ width: '50%', marginTop: '25px' }}>
          <h4>{title.tradeItemDescriptionB}</h4>
          <p className="text-medium">
            GTIN: {title.targetGtin} <br />
            {title.projectNameA} - {title.storeNameA} - {title.shelfNumA} - {title.phaseNumA} <br />
            {moment(title.dateFromA).format('MMMM Do YYYY')} - {moment(title.dateToA).format('MMMM Do YYYY')} <br />
            {title.totalShoppersInteractedSKUB} / {title.totalVisitorsB} interacted with SKU. <br />
            Product price ranking: {toMaxPrecision(title.priceQuantileB, 2)} <br />
          </p>
        </div>
        <div style={{ width: '50%', marginTop: '25px' }}>
          <h4 className="">Benchmarks</h4>
          <p className="text-medium">
            <br />
            <br />
            {title.projectNameB} - {title.storeNameB} - {title.shelfNumB} - {title.phaseNumB} <br />
            {moment(title.dateFromB).format('MMMM Do YYYY')} - {moment(title.dateToB).format('MMMM Do YYYY')} <br />
            {title.averageShoppersInteractedSKUA} / {title.totalVisitors} interacted with average benchmark. <br />
            Product price ranking: {toMaxPrecision(title.averagePriceQuantileA, 2)} <br />
          </p>
        </div>
      </div>
      <div className="row" style={{ margin: '10px' }}>
        <img alt="product img" className="img-responsive productImg" src={title.productImageUrlB} />
      </div>
    </div>
  );
}
function RenderRatesTable(rates: ProductBenchmarkOverviewRates, averages: ProductBenchmarkOverviewAverage): React.ReactNode {
  const oos = toMaxPrecisionPercent(averages.averageProductOosHoursPercChange, 2);
  return (
    <table style={{ marginTop: '25px' }} className="table table-bordered table-striped">
      <tbody>
        <tr>
          <td className="text-center metricNameCell text-small">Product Metrics</td>
          <td className="text-center metricNameCell text-small">Conversion rate(percent)</td>
          <td className="text-center metricNameCell text-small">Turnover per visitor(amount)</td>
          <td className="text-center metricNameCell text-small">Daily turnover(average amount)</td>
          <td className="text-center metricNameCell text-small">Destination buyers(percent)</td>
          <td className="text-center metricNameCell text-small">Return ratio(percent)</td>
          <td className="text-center metricNameCell text-small">Lost shoppers(percent)</td>
          <td className="text-center metricNameCell text-small">Out of stock(daily hours)</td>
          <td className="text-center metricNameCell text-small">Out of stock(percent)</td>
        </tr>
        <tr>
          <td className="text-center metricNameCell text-small">Difference, %</td>
          <td className="text-center text-small">
            {toMaxPrecisionPercent(rates.productConversionRatePercChange, 2)} {rates.productConversionRateSignificance ?? ''}
          </td>
          <td className="text-center text-small">
            {toMaxPrecisionPercent(averages.productTurnoverPerVisitorPercChange, 2)} {averages.productTurnoverPerVisitorSignificance ?? ''}
          </td>
          <td className="text-center text-small">
            {toMaxPrecisionPercent(averages.averageProductDailyTurnoverPercChange, 2)} {averages.averageProductDailyTurnoverSignificance ?? ''}
          </td>
          <td className="text-center text-small">
            {toMaxPrecisionPercent(rates.productDestinationBuyersSharePercChange, 2)} {rates.productDestinationBuyersShareSignificance ?? ''}
          </td>
          <td className="text-center text-small">
            {toMaxPrecisionPercent(rates.productReturnRatioPercChange, 2)} {rates.productReturnRatioSignificance ?? ''}
          </td>
          <td className="text-center text-small">
            {toMaxPrecisionPercent(rates.productLostShoppersPercentPercChange, 2)} {rates.productLostShoppersPercentSignificance ?? ''}
          </td>
          <td className="text-center text-small">
            {oos === '' ? '0%' : oos} {averages.averageProductOosHoursSignificance ?? ''}
          </td>
          <td className="text-center text-small">
            {toMaxPrecisionPercent(averages.productOosTimeSharePercChange, 2)} {averages.productOosTimeShareSignificance ?? ''}
          </td>
        </tr>
        <tr>
          <td className="text-center metricNameCell text-small">Product</td>
          <td className="text-center text-small">{toMaxPrecisionPercent(rates.productConversionRateA, 2)}</td>
          <td className="text-center text-small">{toMaxPrecision(averages.productTurnoverPerVisitorA, 2)}</td>
          <td className="text-center text-small">{toMaxPrecision(averages.averageProductDailyTurnoverA, 2)}</td>
          <td className="text-center text-small">{toMaxPrecisionPercent(rates.productDestinationBuyersShareA, 2)}</td>
          <td className="text-center text-small">{toMaxPrecisionPercent(rates.productReturnRatioA, 2)}</td>
          <td className="text-center text-small">{toMaxPrecisionPercent(rates.productLostShoppersPercentA, 2)}</td>
          <td className="text-center text-small">{toMaxPrecision(averages.averageProductOosHoursA, 2)}</td>
          <td className="text-center text-small">{toMaxPrecisionPercent(averages.productOosTimeShareA, 2)}</td>
        </tr>
        <tr>
          <td className="text-center metricNameCell text-small">Benchmarks</td>
          <td className="text-center text-small">{toMaxPrecisionPercent(rates.productConversionRateB, 2)}</td>
          <td className="text-center text-small">{toMaxPrecision(averages.productTurnoverPerVisitorB, 2)}</td>
          <td className="text-center text-small">{toMaxPrecision(averages.averageProductDailyTurnoverB, 2)}</td>
          <td className="text-center text-small">{toMaxPrecisionPercent(rates.productDestinationBuyersShareB, 2)}</td>
          <td className="text-center text-small">{toMaxPrecisionPercent(rates.productReturnRatioB, 2)}</td>
          <td className="text-center text-small">{toMaxPrecisionPercent(rates.productLostShoppersPercentB, 2)}</td>
          <td className="text-center text-small">{toMaxPrecision(averages.averageProductOosHoursB, 2)}</td>
          <td className="text-center text-small">{toMaxPrecisionPercent(averages.productOosTimeShareB, 2)}</td>
        </tr>
      </tbody>
    </table>
  );
}

function RenderShareTable(rates: ProductBenchmarkOverviewRates, averages: ProductBenchmarkOverviewAverage): React.ReactNode {
  return (
    <table style={{ marginTop: '25px' }} className="table table-bordered table-striped">
      <tbody>
        <tr>
          <td className="text-center metricNameCell text-small">Product Metrics</td>
          <td className="text-center metricNameCell text-small">Share of space</td>
          <td className="text-center metricNameCell text-small">Share of unit sales</td>
          <td className="text-center metricNameCell text-small">Share of turnover</td>
          <td className="text-center metricNameCell text-small">Share of first pickups</td>
          <td className="text-center metricNameCell text-small">Discovery time(all shoppers)</td>
          <td className="text-center metricNameCell text-small">Seconds to buy and leave(buyers)</td>
          <td className="text-center metricNameCell text-small">Seconds to touch and reject(non-buyers)</td>
          <td className="text-center metricNameCell text-small">Substitutions</td>
        </tr>
        <tr>
          <td className="text-center metricNameCell text-small">Difference, %</td>
          <td className="text-center text-small">
            {toMaxPrecisionPercent(rates.shareOfSpacePercChange, 2)} {rates.shareOfSpaceSignificance ?? ''}
          </td>
          <td className="text-center text-small">
            {toMaxPrecisionPercent(rates.shareOfUnitSalesPercChange, 2)} {rates.shareOfUnitSalesSignificance ?? ''}
          </td>
          <td className="text-center text-small">
            {toMaxPrecisionPercent(rates.shareOfTurnoverPercChange, 2)} {rates.shareOfTurnoverSignificance ?? ''}
          </td>
          <td className="text-center text-small">
            {toMaxPrecisionPercent(rates.shareOfFirstPickupsPercChange, 2)} {rates.shareOfFirstPickupsSignificance ?? ''}
          </td>
          <td className="text-center text-small">
            {toMaxPrecisionPercent(averages.discoveryTimePercChange, 2)} {averages.discoveryTimeSignificance ?? ''}
          </td>
          <td className="text-center text-small">
            {toMaxPrecisionPercent(averages.secondsToBuyAndLeavePercChange, 2)} {averages.secondsToBuyAndLeaveSignificance ?? ''}
          </td>
          <td className="text-center text-small">
            {toMaxPrecisionPercent(averages.secondsToTouchAndRejectPercChange, 2)} {averages.secondsToTouchAndRejectSignificance ?? ''}
          </td>
          <td className="text-center text-small">
            {toMaxPrecisionPercent(rates.substitutionShopperCountPercChange, 2)} {rates.substitutionShopperCountSignificance ?? ''}
          </td>
        </tr>
        <tr>
          <td className="text-center metricNameCell text-small">Product</td>
          <td className="text-center text-small">{toMaxPrecisionPercent(rates.shareOfSpaceA, 2)}</td>
          <td className="text-center text-small">{toMaxPrecisionPercent(rates.shareOfUnitSalesA, 2)}</td>
          <td className="text-center text-small">{toMaxPrecisionPercent(rates.shareOfTurnoverA, 2)}</td>
          <td className="text-center text-small">{toMaxPrecisionPercent(rates.shareOfFirstPickupsA, 2)}</td>
          <td className="text-center text-small">{toMaxPrecision(averages.discoveryTimeA, 2)}</td>
          <td className="text-center text-small">{toMaxPrecision(averages.secondsToBuyAndLeaveA, 2)}</td>
          <td className="text-center text-small">{toMaxPrecision(averages.secondsToTouchAndRejectA, 2)}</td>
          <td className="text-center text-small">{toMaxPrecision(rates.substitutionShopperCountA, 2)}</td>
        </tr>
        <tr>
          <td className="text-center metricNameCell text-small">Benchmarks</td>
          <td className="text-center text-small">{toMaxPrecisionPercent(rates.shareOfSpaceB, 2)}</td>
          <td className="text-center text-small">{toMaxPrecisionPercent(rates.shareOfUnitSalesB, 2)}</td>
          <td className="text-center text-small">{toMaxPrecisionPercent(rates.shareOfTurnoverB, 2)}</td>
          <td className="text-center text-small">{toMaxPrecisionPercent(rates.shareOfFirstPickupsB, 2)}</td>
          <td className="text-center text-small">{toMaxPrecision(averages.discoveryTimeB, 2)}</td>
          <td className="text-center text-small">{toMaxPrecision(averages.secondsToBuyAndLeaveB, 2)}</td>
          <td className="text-center text-small">{toMaxPrecision(averages.secondsToTouchAndRejectB, 2)}</td>
          <td className="text-center text-small">{toMaxPrecision(rates.substitutionShopperCountB, 2)}</td>
        </tr>
      </tbody>
    </table>
  );
}

function RenderDemographicTable(data: ProductBenchmarkDemographics): React.ReactNode {
  return (
    <table style={{ marginTop: '25px' }} className="table table-bordered table-striped">
      <tbody>
        <tr>
          <td className="text-center metricNameCell text-small" colSpan={2}>
            Demographics
          </td>
          <td className="text-center metricNameCell text-small" colSpan={2}>
            Interactors (Benchmarks/Product)
          </td>
          <td className="text-center metricNameCell text-small" colSpan={2}>
            Buyers (Benchmark/Product)
          </td>
          <td className="text-center metricNameCell text-small" colSpan={2}>
            Lost shoppers (Benchmarks/Product)
          </td>
        </tr>
        <tr>
          <td className="text-center metricNameCell text-medium" colSpan={2} rowSpan={2}>
            All
          </td>
          <td className="text-center text-medium" colSpan={2}>
            {toMaxPrecisionPercent(data.allInteractorsPercChange, 2)} {data.allInteractorsSignificance ?? ''}
          </td>
          <td className="text-center text-medium" colSpan={2}>
            {toMaxPrecisionPercent(data.allBuyersPercChange, 2)} {data.allBuyersSignificance ?? ''}
          </td>
          <td className="text-center text-medium" colSpan={2}>
            {toMaxPrecisionPercent(data.allLostShoppersPercChange, 2)} {data.allLostShoppersSignificance ?? ''}
          </td>
        </tr>
        <tr>
          <td className="text-center text-medium">{toMaxPrecisionPercent(data.allInteractorsA, 2)}</td>
          <td className="text-center text-medium">{toMaxPrecisionPercent(data.allInteractorsB, 2)}</td>
          <td className="text-center text-medium">{toMaxPrecisionPercent(data.allBuyersA, 2)}</td>
          <td className="text-center text-medium">{toMaxPrecisionPercent(data.allBuyersB, 2)}</td>
          <td className="text-center text-medium">{toMaxPrecisionPercent(data.allLostShoppersA, 2)}</td>
          <td className="text-center text-medium">{toMaxPrecisionPercent(data.allLostShoppersB, 2)}</td>
        </tr>
        <tr>
          <td className="text-center metricNameCell text-medium" colSpan={2} rowSpan={2}>
            Female
          </td>
          <td className="text-center text-medium" colSpan={2}>
            {toMaxPrecisionPercent(data.femaleInteractorsPercChange, 2)} {data.femaleInteractorsSignificance ?? ''}
          </td>
          <td className="text-center text-medium" colSpan={2}>
            {toMaxPrecisionPercent(data.femaleBuyersPercChange, 2)} {data.femaleBuyersSignificance ?? ''}
          </td>
          <td className="text-center text-medium" colSpan={2}>
            {toMaxPrecisionPercent(data.femaleLostShoppersPercChange, 2)} {data.femaleLostShoppersSignificance ?? ''}
          </td>
        </tr>
        <tr>
          <td className="text-center text-medium">{toMaxPrecisionPercent(data.femaleInteractorsA, 2)}</td>
          <td className="text-center text-medium">{toMaxPrecisionPercent(data.femaleInteractorsB, 2)}</td>
          <td className="text-center text-medium">{toMaxPrecisionPercent(data.femaleBuyersA, 2)}</td>
          <td className="text-center text-medium">{toMaxPrecisionPercent(data.femaleBuyersB, 2)}</td>
          <td className="text-center text-medium">{toMaxPrecisionPercent(data.femaleLostShoppersA, 2)}</td>
          <td className="text-center text-medium">{toMaxPrecisionPercent(data.femaleLostShoppersB, 2)}</td>
        </tr>
        <tr>
          <td className="text-center metricNameCell text-medium" colSpan={2} rowSpan={2}>
            Male
          </td>
          <td className="text-center text-medium" colSpan={2}>
            {toMaxPrecisionPercent(data.maleInteractorsPercChange, 2)} {data.maleInteractorsSignificance ?? ''}
          </td>
          <td className="text-center text-medium" colSpan={2}>
            {toMaxPrecisionPercent(data.maleBuyersPercChange, 2)} {data.maleBuyersSignificance ?? ''}
          </td>
          <td className="text-center text-medium" colSpan={2}>
            {toMaxPrecisionPercent(data.maleLostShoppersPercChange, 2)} {data.maleLostShoppersSignificance ?? ''}
          </td>
        </tr>
        <tr>
          <td className="text-center text-medium">{toMaxPrecisionPercent(data.maleInteractorsA, 2)}</td>
          <td className="text-center text-medium">{toMaxPrecisionPercent(data.maleInteractorsB, 2)}</td>
          <td className="text-center text-medium">{toMaxPrecisionPercent(data.maleBuyersA, 2)}</td>
          <td className="text-center text-medium">{toMaxPrecisionPercent(data.maleBuyersB, 2)}</td>
          <td className="text-center text-medium">{toMaxPrecisionPercent(data.maleLostShoppersA, 2)}</td>
          <td className="text-center text-medium">{toMaxPrecisionPercent(data.maleLostShoppersB, 2)}</td>
        </tr>
        <tr>
          <td className="text-center metricNameCell text-medium" colSpan={2} rowSpan={2}>
            With kids
          </td>
          <td className="text-center text-medium" colSpan={2}>
            {toMaxPrecisionPercent(data.withKidsInteractorsPercChange, 2)} {data.withKidsInteractorsSignificance ?? ''}
          </td>
          <td className="text-center text-medium" colSpan={2}>
            {toMaxPrecisionPercent(data.withKidsBuyersPercChange, 2)} {data.withKidsBuyersSignificance ?? ''}
          </td>
          <td className="text-center text-medium" colSpan={2}>
            {toMaxPrecisionPercent(data.withKidsLostShoppersPercChange, 2)} {data.withKidsLostShoppersSignificance ?? ''}
          </td>
        </tr>
        <tr>
          <td className="text-center text-medium">{toMaxPrecisionPercent(data.withKidsInteractorsA, 2)}</td>
          <td className="text-center text-medium">{toMaxPrecisionPercent(data.withKidsInteractorsB, 2)}</td>
          <td className="text-center text-medium">{toMaxPrecisionPercent(data.withKidsBuyersA, 2)}</td>
          <td className="text-center text-medium">{toMaxPrecisionPercent(data.withKidsBuyersB, 2)}</td>
          <td className="text-center text-medium">{toMaxPrecisionPercent(data.withKidsLostShoppersA, 2)}</td>
          <td className="text-center text-medium">{toMaxPrecisionPercent(data.withKidsLostShoppersB, 2)}</td>
        </tr>
        <tr>
          <td className="text-center metricNameCell text-medium" colSpan={2} rowSpan={2}>
            Group
          </td>
          <td className="text-center text-medium" colSpan={2}>
            {toMaxPrecisionPercent(data.groupInteractorsPercChange, 2)} {data.groupInteractorsSignificance ?? ''}
          </td>
          <td className="text-center text-medium" colSpan={2}>
            {toMaxPrecisionPercent(data.groupBuyersPercChange, 2)} {data.groupBuyersSignificance ?? ''}
          </td>
          <td className="text-center text-medium" colSpan={2}>
            {toMaxPrecisionPercent(data.groupLostShoppersPercChange, 2)} {data.groupLostShoppersSignificance ?? ''}
          </td>
        </tr>
        <tr>
          <td className="text-center text-medium">{toMaxPrecisionPercent(data.groupInteractorsA, 2)}</td>
          <td className="text-center text-medium">{toMaxPrecisionPercent(data.groupInteractorsB, 2)}</td>
          <td className="text-center text-medium">{toMaxPrecisionPercent(data.groupBuyersA, 2)}</td>
          <td className="text-center text-medium">{toMaxPrecisionPercent(data.groupBuyersB, 2)}</td>
          <td className="text-center text-medium">{toMaxPrecisionPercent(data.groupLostShoppersA, 2)}</td>
          <td className="text-center text-medium">{toMaxPrecisionPercent(data.groupLostShoppersB, 2)}</td>
        </tr>
      </tbody>
    </table>
  );
}

function RenderDemographicGraph(report: ProductBenchmarkReport): React.ReactNode {
  const data = [GenerateBarItems('Benchmark Interactors', report.benchmarkInteractors), GenerateBarItems('Product Interactors', report.productInteractors)];
  return (
    <Grid container alignContent="center" alignItems="center" justify={'center'} style={{ marginTop: '25px' }} direction="column">
      <Grid item>
        <Typography variant="overline">Detailed interaction profile comparison</Typography>
      </Grid>
      <Grid item>
        <InteractorComparisonStackedBarChart data={data} />
      </Grid>
    </Grid>
  );
}

const ProductBenchmarkReportPage: React.FC<ProductBenchmarkReportPageProps> = ({ report }: ProductBenchmarkReportPageProps) => {
  return (
    <div>
      <div className="page">
        <div id="flowLogo">
          <img alt="flow-logo" className="img-responsive" src={flowLogo} />
        </div>
        <div style={{ width: '100%' }}>
          <h3 className="text-center" style={{ marginTop: '25px' }}>
            Product Benchmark Comparison Report
            <br />
          </h3>
          {RenderDescription(report.overviewTitle)}
          {RenderRatesTable(report.overviewRates, report.overviewAverage)}
          {RenderShareTable(report.overviewRates, report.overviewAverage)}
          {RenderDemographicTable(report.demographics)}
          {RenderDemographicGraph(report)}
        </div>
        <footer>
          <strong>Copyright &copy; {new Date().getFullYear()} Flow Insights</strong>. Strictly confidential. No distribution allowed. All rights reserved.
        </footer>
      </div>
    </div>
  );
};

export { ProductBenchmarkReportPage };
