import * as React from 'react';
import { Dialog, DialogTitle, Typography, DialogContent, Divider, Table, TableHead, TableBody, Tooltip, IconButton, Link, FormControl, InputLabel, Select, Input, MenuItem } from '@material-ui/core';
import UserForAdminViewModel from '../types/UserForAdminViewModel';
import UserPlanogramForAdminViewModel from '../types/UserPlanogramForAdminViewModel';
import trackComponent from 'infrastructure/tracking/withTracking';
import Alert from 'reactstrap/lib/Alert';
import { StyledTableRow, StyledTableCell } from 'scss/MaterialStyles';
import { formatDistanceToNow } from 'date-fns';
import { Edit, ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { orderBy, uniqBy } from 'lodash';

interface UserPlanogramsModalState {
  orderByProperty: string;
  orderByDescending: boolean;
  selectedProject?: string;
}

class UserPlanogramsModal extends React.Component<Props & DispatchProps, UserPlanogramsModalState> {
  constructor(props: Props & DispatchProps) {
    super(props);
    this.state = {
      orderByDescending: true,
      orderByProperty: 'lastOpenedOn',
      selectedProject: 'all',
    };
  }

  componentDidMount() {
    this.props.loadUserPlanogramsReqeust(this.props.user.userId);
  }

  render() {
    return (
      <Dialog open={true} maxWidth="xl" onClose={this.props.onClosed}>
        <DialogTitle>
          <Typography variant="overline" gutterBottom>
            Planograms for {this.props.user.userEmail} ({this.props.user.userId})
          </Typography>
          <Divider light variant="middle" />
        </DialogTitle>
        <DialogContent>
          {this.renderErrorLoading()}
          {this.renderLoading()}
          {this.renderLoaded()}
        </DialogContent>
      </Dialog>
    );
  }

  renderErrorLoading() {
    if (!this.props.isError) {
      return null;
    }
    return (
      <div>
        <Alert color="danger">
          <strong>Error loading user planograms. Please try again later.</strong>
        </Alert>
      </div>
    );
  }

  renderLoading() {
    if (!this.props.isLoading) {
      return null;
    }
    return <div>Loading user planograms...`</div>;
  }

  renderLoaded() {
    if (!this.props.isLoaded) {
      return null;
    }
    return (
      <React.Fragment>
        {this.renderFilter()}
        <Table size="medium" className="top-buffer sticky-header-table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell style={{ cursor: 'pointer' }} align="center" onClick={() => this.changeOrderBy('planogramName')}>
                <Typography variant="subtitle1">
                  Planogram
                  {this.getOrderByIcon('planogramName')}
                </Typography>
              </StyledTableCell>
              <StyledTableCell style={{ cursor: 'pointer' }} align="center" onClick={() => this.changeOrderBy('projectName')}>
                <Typography variant="subtitle1">
                  Project
                  {this.getOrderByIcon('projectName')}
                </Typography>
              </StyledTableCell>
              <StyledTableCell style={{ cursor: 'pointer' }} align="center" onClick={() => this.changeOrderBy('storeName')}>
                <Typography variant="subtitle1">
                  Store
                  {this.getOrderByIcon('storeName')}
                </Typography>
              </StyledTableCell>
              <StyledTableCell style={{ cursor: 'pointer' }} align="center" onClick={() => this.changeOrderBy('phaseNum')}>
                <Typography variant="subtitle1">
                  Phase
                  {this.getOrderByIcon('phaseNum')}
                </Typography>
              </StyledTableCell>
              <StyledTableCell style={{ cursor: 'pointer' }} align="center" onClick={() => this.changeOrderBy('shelfNum')}>
                <Typography variant="subtitle1">
                  Shelf
                  {this.getOrderByIcon('shelfNum')}
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="subtitle1">Role</Typography>
              </StyledTableCell>
              <StyledTableCell style={{ cursor: 'pointer' }} align="center" onClick={() => this.changeOrderBy('lastOpenedOn')}>
                <Typography variant="subtitle1">
                  Last Active
                  {this.getOrderByIcon('lastOpenedOn')}
                </Typography>
              </StyledTableCell>
              <StyledTableCell />
            </StyledTableRow>
          </TableHead>
          <TableBody>{this.renderPlanograms()}</TableBody>
        </Table>
      </React.Fragment>
    );
  }

  renderPlanograms() {
    let planograms = this.state.selectedProject === 'all' ? this.props.planograms : this.props.planograms.filter(p => p.shelfReferenceId.toString() === this.state.selectedProject);
    planograms = orderBy(planograms, [this.state.orderByProperty], [this.state.orderByDescending ? 'desc' : 'asc']);
    return planograms.map(p => {
      return (
        <StyledTableRow key={p.planogramId}>
          <StyledTableCell align="center">{p.planogramName}</StyledTableCell>
          <StyledTableCell align="center">{p.projectName}</StyledTableCell>
          <StyledTableCell align="center">{p.storeName}</StyledTableCell>
          <StyledTableCell align="center">{p.phaseNum}</StyledTableCell>
          <StyledTableCell align="center">{p.shelfNum}</StyledTableCell>
          <StyledTableCell align="center">{p.builderId === p.ownerId ? 'Owner' : 'Builder'}</StyledTableCell>
          <StyledTableCell align="center">{p.lastOpenedOn ? <span>{formatDistanceToNow(p.lastOpenedOn, { addSuffix: true })}</span> : 'Never'}</StyledTableCell>
          <StyledTableCell>
            <Link href={'/planograms/' + p.planogramId} target="_blank">
              <Tooltip title="View Planograms">
                <div>
                  <IconButton>
                    <Edit color="secondary" />
                  </IconButton>
                </div>
              </Tooltip>
            </Link>
          </StyledTableCell>
        </StyledTableRow>
      );
    });
  }

  renderFilter() {
    let projects = uniqBy(this.props.planograms, p => {
      return p.shelfReferenceId;
    });
    projects = orderBy(projects, ['projectName']);
    const menuItems = projects.map(project => {
      return (
        <MenuItem key={project.shelfReferenceId} value={project.shelfReferenceId}>
          {project.projectName}
        </MenuItem>
      );
    });
    return (
      <div className="row">
        <div className="col-sm-6">
          <FormControl fullWidth={true}>
            <InputLabel htmlFor="project-filter">Projects</InputLabel>
            <Select
              fullWidth={true}
              value={this.state.selectedProject}
              input={<Input fullWidth={true} id="project-filter" />}
              onChange={(event: any) => {
                this.setState({ selectedProject: event.target.value.toString() });
              }}
            >
              <MenuItem value="all">All</MenuItem>
              {menuItems}
            </Select>
          </FormControl>
        </div>
      </div>
    );
  }

  changeOrderBy(propertyName: string) {
    const descending = this.state.orderByProperty === propertyName ? !this.state.orderByDescending : false;
    this.setState({
      orderByDescending: descending,
      orderByProperty: propertyName,
    });
  }
  getOrderByIcon(propertyName: string) {
    if (this.state.orderByProperty !== propertyName) {
      return null;
    }

    if (this.state.orderByDescending) {
      return <ArrowDropDown />;
    } else {
      return <ArrowDropUp />;
    }
  }
}

interface Props {
  user: UserForAdminViewModel;
  planograms: UserPlanogramForAdminViewModel[];
  isLoading: boolean;
  isLoaded: boolean;
  isError: boolean;
}

interface DispatchProps {
  onClosed(): void;
  loadUserPlanogramsReqeust(userId: number): void;
}

export default trackComponent(UserPlanogramsModal, 'UserPlanogramsModal');
