import _ from 'lodash';
import React from 'react';
import { Bar, BarChart, Legend, ReferenceLine, Tooltip, XAxis, YAxis } from 'recharts';
import { BrandConversionShopperCategoryType, GpcBrickConversionShopperCategoryType, InteractorsType, ProductConversionShopperCategoryType, ProjectConversionShopperCategoryType } from '../types';

export interface InteractorsStackedBarChartProps {
  interactorsType: InteractorsType;
  data: object[];
}

const colorPallete = {
  // TODO: Fix duplicates due to naming issues on database
  GrabAndGo: '#00F910',
  BrandsProductDestinationBuyers: '#26B22F',
  ProductDestinationBuyers: '#26B22F',
  BrickDestinationBuyers: '#1C7D22',
  BrandDestinationBuyers: '#1C7D22',
  WonFromInternal: '#8fbfaa',
  'WonFromCompetitorBrand(s)': '#60A486',
  'WonFromOtherBrick(s)': '#60A486',
  WonFromCompetitorBrand: '#60A486',
  WonFromCompetitors: '#60A486',
  'WonFromCompetitor(s)': '#60A486',
  MultiBrandBuyers: '#1CC0B3',
  MultiBrickBuyers: '#1CC0B3',
  MultiproductBuyers: '#006a44',
  MultiProductBuyers: '#006a44',
  MultiBrandInteractors: '#60A486',
  'LostToCompetitorBrand(s)': '#EAE573',
  'LostToOtherBrick(s)': '#EAE573',
  LostToCompetitorBrand: '#EAE573',
  'LostToCompetitor(s)': '#FD4444',
  LostToCompetitors: '#FD4444',
  ShelfLostShoppers: '#F3B75D',
  LostToInternal: '#aa8041',
  BrandLostShoppers: '#FD4444',
  BrickLostShoppers: '#FD4444',
  ProductLostShoppers: '#CC0202',
  BrandsProductLostShoppers: '#CC0202',
};
// Gets the Y Axis point to draw the reference line between Buyers and Rejectors
const getRejectorsYAxis = (interactor: InteractorsType, chartData: object[]): number | undefined => {
  let result = undefined;
  switch (interactor) {
    case InteractorsType.Brand:
      result =
        100 -
        ((chartData[0][BrandConversionShopperCategoryType.ShelfLostShoppers] || 0) +
          (chartData[0][BrandConversionShopperCategoryType.BrandLostShoppers] || 0) +
          (chartData[0][BrandConversionShopperCategoryType['LostToCompetitorBrand(s)']] || 0) +
          (chartData[0][BrandConversionShopperCategoryType.BrandsProductLostShoppers] || 0));
      break;
    case InteractorsType.Project:
      result =
        100 -
        ((chartData[0][ProjectConversionShopperCategoryType.ShelfLostShoppers] || 0) +
          (chartData[0][ProjectConversionShopperCategoryType.BrandLostShoppers] || 0) +
          (chartData[0][ProjectConversionShopperCategoryType.ProductLostShoppers] || 0));
      break;
    case InteractorsType.Product:
      result =
        100 -
        ((chartData[0][ProductConversionShopperCategoryType.ShelfLostShoppers] || 0) +
          (chartData[0][ProductConversionShopperCategoryType.LostToInternal] || 0) +
          (chartData[0][ProductConversionShopperCategoryType['LostToCompetitor(s)']] || 0) +
          (chartData[0][ProductConversionShopperCategoryType.ProductLostShoppers] || 0));
      break;
    case InteractorsType.GpcBrick:
      result =
        100 -
        ((chartData[0][GpcBrickConversionShopperCategoryType['LostToOtherBrick(s)']] || 0) +
          (chartData[0][GpcBrickConversionShopperCategoryType.ShelfLostShoppers] || 0) +
          (chartData[0][GpcBrickConversionShopperCategoryType.BrickLostShoppers] || 0) +
          (chartData[0][GpcBrickConversionShopperCategoryType.ProductLostShoppers] || 0));
      break;
    default:
      result = undefined;
      break;
  }
  return result;
};

const InteractorsStackedBarChart: React.FC<InteractorsStackedBarChartProps> = ({ data, interactorsType }: InteractorsStackedBarChartProps) => {
  const labelFormatter = (label: string): string => `${label}%`;
  const legendFormatter = (dataKey: string): JSX.Element => {
    return (
      <span>
        {_.startCase(dataKey.replace(/[()]/g, ''))} ({data[0][dataKey] || 0}%)
      </span>
    );
  };
  const getBarStacks = (interactor: InteractorsType): JSX.Element[] => {
    switch (interactor) {
      case InteractorsType.Brand:
        return Object.keys(BrandConversionShopperCategoryType).map(x => {
          return <Bar key={x} dataKey={x} stackId="a" fill={colorPallete[x]}></Bar>;
        });
      case InteractorsType.Project:
        return Object.keys(ProjectConversionShopperCategoryType).map(x => {
          return <Bar key={x} dataKey={x} stackId="a" fill={colorPallete[x]}></Bar>;
        });
      case InteractorsType.Product:
        return Object.keys(ProductConversionShopperCategoryType).map(x => {
          return <Bar key={x} dataKey={x} stackId="a" fill={colorPallete[x]}></Bar>;
        });
      case InteractorsType.GpcBrick:
        return Object.keys(GpcBrickConversionShopperCategoryType).map(x => {
          return <Bar key={x} dataKey={x} stackId="a" fill={colorPallete[x]}></Bar>;
        });
      default:
        return [] as JSX.Element[];
    }
  };

  return (
    <BarChart width={500} height={300} data={data}>
      <XAxis dataKey="name" hide={true} />
      <YAxis reversed={true} hide={true} />
      <Tooltip cursor={{ fill: 'white' }} formatter={labelFormatter} />
      <ReferenceLine y={getRejectorsYAxis(interactorsType, data)} stroke="red" isFront={true} ifOverflow={'extendDomain'} strokeDasharray="4 1" />
      <Legend layout="vertical" verticalAlign="middle" align="right" iconSize={24} formatter={legendFormatter} wrapperStyle={{ right: -20 }} />
      {getBarStacks(interactorsType)}
    </BarChart>
  );
};

export { InteractorsStackedBarChart };
