import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import DeleteIcon from '@material-ui/icons/Delete';
import CopyIcon from '@material-ui/icons/FileCopy';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

const useStyles = makeStyles({
  menuItem: {
    minHeight: 'auto',
  },
  listItemIcon: {
    minWidth: '25px',
  },
  listItemText: {
    '& span': {
      fontSize: '0.8rem',
    },
  },
});

export interface Props {
  element: Element;
  isOpen: boolean;
  deleteShelfLocation: () => void;
  copyShelfLocation: () => void;
  removeFacing: () => void;
}

const RightContextMenuShelfLocation: React.FC<Props> = ({ element, isOpen, deleteShelfLocation, copyShelfLocation, removeFacing }: Props): JSX.Element => {
  const muiClasses = useStyles();
  return (
    <Popper id="right-context-menu-shelf-location" open={isOpen} anchorEl={element}>
      <Paper>
        <MenuItem onClick={deleteShelfLocation} className={muiClasses.menuItem}>
          <ListItemIcon className={muiClasses.listItemIcon}>
            <DeleteIcon color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText className={muiClasses.listItemText}>Delete</ListItemText>
        </MenuItem>
        <MenuItem onClick={copyShelfLocation} className={muiClasses.menuItem}>
          <ListItemIcon className={muiClasses.listItemIcon}>
            <CopyIcon color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText className={muiClasses.listItemText}>Copy</ListItemText>
        </MenuItem>
        <MenuItem onClick={removeFacing} className={muiClasses.menuItem}>
          <ListItemIcon className={muiClasses.listItemIcon}>
            <RemoveCircleOutlineIcon color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText className={muiClasses.listItemText}>Remove 1 facing</ListItemText>
        </MenuItem>
      </Paper>
    </Popper>
  );
};

export default RightContextMenuShelfLocation;
