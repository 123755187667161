export enum AggregatedMetrics {
  ConversionRate,
  DestinationBuyersShare,
  LostShoppersPercent,
  DiscoveryTime,
  ReturnRatio,
}

export interface SelectorOption {
  value: number | string | undefined;
  label: string;
}

export interface AvailableAggregatedReports {
  brandBricks: AvailableBrandBrickReport[];
  brands: AvailableBrandReport[];
  bricks: AvailableBrickReport[];
  products: AvailableProductReport[];
}

export interface AvailableBrandBrickReport {
  brandOwnerId?: number;
  brandId?: number;
  gpcFamilyCode?: number;
  gpcClassCode?: number;
  gpcBrickCode?: number;
  family: string;
  class: string;
  brick: string;
  brandOwner: string;
  brand: string;
  interactorsCount: number;
  numberOfProducts: number;
  productsWith2numberInteractors: number;
  lastCollectionYear: number;
  totalDaysOfCollection: number;
}

export interface AvailableBrandReport {
  brandOwnerId?: number;
  brandId?: number;
  brandOwner: string;
  brand: string;
  interactorsCount: number;
  numberOfProducts: number;
  productsWith2numberInteractors: number;
  lastCollectionYear: number;
  totalDaysOfCollection: number;
}

export interface AvailableBrickReport {
  gpcFamilyCode?: number;
  gpcClassCode?: number;
  gpcBrickCode?: number;
  family: string;
  class: string;
  brick: string;
  interactorsCount: number;
  numberOfProducts: number;
  productsWith2numberInteractors: number;
  lastCollectionYear: number;
  totalDaysOfCollection: number;
}

export interface AvailableProductReport {
  brandOwnerId?: number;
  brandId?: number;
  gpcFamilyCode?: number;
  gpcClassCode?: number;
  gpcBrickCode?: number;
  family: string;
  class: string;
  brick: string;
  brandOwner: string;
  brand: string;
  product: string;
  gtin?: number;
  interactorsCount: number;
  lastCollectionYear: number;
  totalDaysOfCollection: number;
}

export interface OnePagerReport {
  metric: AggregatedMetrics;
  flowName: string;
  productName: string;
  imageUrl: string;
  question: string;
  answer: string;
  description: string;
  shoppers: number;
  interactors: number;
}

export interface AggregatedOnePagerReport {
  requestId: number;
  reports: OnePagerReport[];
}
