import { Grid, Typography } from '@material-ui/core';
import { chunk } from 'lodash';
import moment from 'moment';
import React from 'react';
import { toMaxPrecision, toMaxPrecisionPercentFloored } from 'utils/Helpers';
import flowLogo from '../../../../../assets/img/brand/flow.jpg';
import { BrandConversionShopperCategoryType, BrandDetailedConversionResult, BrandReportResult, InteractorsType } from '../../types';
import { InteractorsStackedBarChart } from '../InteractorsStackedBarChart';
import '../styles/StandardReportStyle.css';
import '../styles/StandardReportProductGridStyle.css';

const getBrandChartData = (conversionReport: BrandDetailedConversionResult[]): object[] => {
  const result = [{}] as object[];
  Object.keys(BrandConversionShopperCategoryType).forEach(category => {
    result[0][category] = toMaxPrecisionPercentFloored(conversionReport?.find(x => x.brandConversionShopperCategory === BrandConversionShopperCategoryType[category])?.shopperPercent, 2);
  });
  return result;
};

export interface StandardBrandReportPageProps {
  brandReport: BrandReportResult;
}

const StandardBrandReportPage: React.FC<StandardBrandReportPageProps> = ({ brandReport }: StandardBrandReportPageProps) => {
  const brandOverviewReport = brandReport.brandOverviewReport;
  const brandDemographicsReport = brandReport.brandDemographicsReport;
  const brandInternalBuysMoreThanOneProductReport = brandReport.brandInternalBuysMoreThanOneProductReport;
  const brandInternalSubstitutorsReport = brandReport.brandInternalSubstitutorsReport;
  const brandsExternalBoughtTogetherReport = brandReport.brandsExternalBoughtTogetherReport;
  const brandsExternalLosingToReport = brandReport.brandsExternalLosingToReport;
  const brandsExternalWinningOverReport = brandReport.brandsExternalWinningOverReport;
  const brandDetailedConversionReport = brandReport.brandDetailedConversionReport;
  const brandProductsUrlReport = brandReport.brandProductsUrlReport;

  const render4ProductsEveryRow = (): JSX.Element => {
    const productsGrouped = chunk(brandProductsUrlReport, 4);
    return (
      <div>
        {productsGrouped.map((group, i) => {
          return (
            <div key={i} className="row">
              {group.map(product => {
                return (
                  <div key={product.gtin} className="col-md-3" style={{ textAlign: 'center' }}>
                    <img alt="No img" className="img-responsive gridProductImg" src={product.productImageUrl} />
                    <p className="text-center" style={{ fontSize: '.8em', padding: 0, margin: 0 }}>
                      {product.gtin}
                    </p>
                    <p className="text-center" style={{ fontSize: '.8em', padding: 0, margin: 0 }}>
                      {product.tradeItemDescription}
                    </p>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <div className="document">
      <div className="page">
        <div id="flowLogo">
          <img alt="flow logo" className="img-responsive" src={flowLogo} />
        </div>
        <div style={{ width: '100%' }}>
          <h3 className="text-center">{brandOverviewReport.brandName}</h3>
          <h5 className="text-center">
            {brandOverviewReport.projectName} - {brandOverviewReport.storeName} - Phase {brandOverviewReport.phaseNum}
            <br />
            {moment(brandOverviewReport.dateFrom).format('DD/MM/YYYY')} - {moment(brandOverviewReport.dateTo).format('DD/MM/YYYY')}
          </h5>
          <h5 className="text-left">
            <b>Visitors: </b>
            {brandOverviewReport.totalVisitors}
            <br />
            <b>Brand Interactors: </b>
            {brandOverviewReport.totalBrandInteractors}
          </h5>
          <div className="row">
            <table style={{ marginTop: '10px' }} className="table table-bordered table-striped">
              <tbody>
                <tr>
                  <td className="text-center metricNameCell">Brand Conversion rate(percent)</td>
                  <td className="text-center metricNameCell">Brand turnover per visitor(amount)</td>
                  <td className="text-center metricNameCell">Brand daily turnover(average amount)</td>
                  <td className="text-center metricNameCell">Brand destination buyers(percent)</td>
                </tr>
                <tr>
                  <td className="text-center">
                    {brandOverviewReport.brandConversionRate.toLocaleString('en', {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    })}
                  </td>
                  <td className="text-center">{toMaxPrecision(brandOverviewReport.brandTurnoverPerVisitor, 2)}</td>
                  <td className="text-center">{toMaxPrecision(brandOverviewReport.brandDailyTurnover, 2)}</td>
                  <td className="text-center">
                    {brandOverviewReport.brandDestinationBuyers.toLocaleString('en', {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    })}
                  </td>
                </tr>
                <tr>
                  <td className="text-center metricNameCell">Brand return ratio(percent)</td>
                  <td className="text-center metricNameCell">Brand lost shoppers(percent)</td>
                  <td className="text-center metricNameCell">Brand out of shelf(daily hours)</td>
                  <td className="text-center metricNameCell">Brand out of stock(percent)</td>
                </tr>
                <tr>
                  <td className="text-center">
                    {brandOverviewReport.brandReturnRatio.toLocaleString('en', {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    })}
                  </td>
                  <td className="text-center">
                    {brandOverviewReport.brandLostShoppers.toLocaleString('en', {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    })}
                  </td>
                  <td className="text-center">{brandOverviewReport.brandOutOfShelfHours ? toMaxPrecision(brandOverviewReport.brandOutOfShelfHours, 2) : '0'}</td>
                  <td className="text-center">
                    {brandOverviewReport.brandOutOfStockPercent
                      ? brandOverviewReport.brandOutOfStockPercent.toLocaleString('en', {
                          style: 'percent',
                          maximumFractionDigits: 2,
                        })
                      : '0%'}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="row">
            <table style={{ marginTop: '10px' }} className="table table-bordered table-striped">
              <tbody>
                <tr>
                  <td className="text-left metricNameCell">Share of space</td>
                  <td className="text-left">
                    {brandOverviewReport.shareOfSpace.toLocaleString('en', {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    })}
                  </td>
                  <td className="text-left metricNameCell">Discovery time</td>
                  <td className="text-left">{toMaxPrecision(brandOverviewReport.discoveryTime, 2)}</td>
                </tr>
                <tr>
                  <td className="text-left metricNameCell">Share of unit sales</td>
                  <td className="text-left">
                    {brandOverviewReport.shareOfUnitSales.toLocaleString('en', {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    })}
                  </td>
                  <td className="text-left metricNameCell">Seconds to buy and leave (buyers)</td>
                  <td className="text-left">{toMaxPrecision(brandOverviewReport.secondsToBuyAndLeave, 2)}</td>
                </tr>
                <tr>
                  <td className="text-left metricNameCell">Share of turnover</td>
                  <td className="text-left">
                    {brandOverviewReport.shareOfTurnover.toLocaleString('en', {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    })}
                  </td>
                  <td className="text-left metricNameCell">Seconds to touch and reject (non-buyers)</td>
                  <td className="text-left">{toMaxPrecision(brandOverviewReport.secondsToReject, 2)}</td>
                </tr>
                <tr>
                  <td className="text-left metricNameCell">Share of first pickups</td>
                  <td className="text-left">
                    {brandOverviewReport.shareOfFirstPickups.toLocaleString('en', {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    })}
                  </td>
                  <td className="text-left metricNameCell">Substitutions (number of shoppers)</td>
                  <td className="text-left">{toMaxPrecision(brandOverviewReport.substitutions, 2)}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="row" style={{ marginTop: '10px' }}>
            <h4>Within Brand</h4>
            <table className="table table-bordered table-striped">
              <tbody>
                <tr>
                  <td className="text-center metricNameCell">Buys more than one product</td>
                  <td className="text-center metricNameCell">Substitutors</td>
                </tr>
                <tr>
                  <td className="text-center">{brandInternalBuysMoreThanOneProductReport ? `${brandInternalBuysMoreThanOneProductReport?.numberOfShoppers}` : '0'}</td>
                  <td className="text-center">{brandInternalSubstitutorsReport ? `${brandInternalSubstitutorsReport?.numberOfShoppers}` : '0'}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="row" style={{ marginTop: '10px' }}>
            <h4>Outside Brand</h4>
            <table className="table table-bordered table-striped">
              <tbody>
                <tr>
                  <td className="text-center metricNameCell">Mostly bought with</td>
                  <td className="text-center metricNameCell">Mostly losing to</td>
                  <td className="text-center metricNameCell">Mostly winning over</td>
                </tr>
                <tr>
                  <td className="text-center">
                    {brandsExternalBoughtTogetherReport.length >= 1 ? `${brandsExternalBoughtTogetherReport[0].comparisonBrand} (${brandsExternalBoughtTogetherReport[0].numberOfShoppers})` : '-'}
                  </td>
                  <td className="text-center">
                    {brandsExternalLosingToReport.length >= 1 ? `${brandsExternalLosingToReport[0].losingToBrand} (${brandsExternalLosingToReport[0].numberOfShoppers})` : '-'}
                  </td>
                  <td className="text-center">
                    {brandsExternalWinningOverReport.length >= 1 ? `${brandsExternalWinningOverReport[0].winningOverBrand} (${brandsExternalWinningOverReport[0].numberOfShoppers})` : '-'}
                  </td>
                </tr>
                {brandsExternalBoughtTogetherReport.length >= 2 || brandsExternalLosingToReport.length >= 2 || brandsExternalWinningOverReport.length >= 2 ? (
                  <tr>
                    <td className="text-center">
                      {brandsExternalBoughtTogetherReport.length >= 2 ? `${brandsExternalBoughtTogetherReport[1].comparisonBrand} (${brandsExternalBoughtTogetherReport[1].numberOfShoppers})` : '-'}
                    </td>
                    <td className="text-center">
                      {brandsExternalLosingToReport.length >= 2 ? `${brandsExternalLosingToReport[1].losingToBrand} (${brandsExternalLosingToReport[1].numberOfShoppers})` : '-'}
                    </td>
                    <td className="text-center">
                      {brandsExternalWinningOverReport.length >= 2 ? `${brandsExternalWinningOverReport[1].winningOverBrand} (${brandsExternalWinningOverReport[1].numberOfShoppers})` : '-'}
                    </td>
                  </tr>
                ) : null}
                {brandsExternalBoughtTogetherReport.length >= 3 || brandsExternalLosingToReport.length >= 3 || brandsExternalWinningOverReport.length >= 3 ? (
                  <tr>
                    <td className="text-center">
                      {brandsExternalBoughtTogetherReport.length >= 3 ? `${brandsExternalBoughtTogetherReport[2].comparisonBrand} (${brandsExternalBoughtTogetherReport[2].numberOfShoppers})` : '-'}
                    </td>
                    <td className="text-center">
                      {brandsExternalLosingToReport.length >= 3 ? `${brandsExternalLosingToReport[2].losingToBrand} (${brandsExternalLosingToReport[2].numberOfShoppers})` : '-'}
                    </td>
                    <td className="text-center">
                      {brandsExternalWinningOverReport.length >= 3 ? `${brandsExternalWinningOverReport[2].winningOverBrand} (${brandsExternalWinningOverReport[2].numberOfShoppers})` : '-'}
                    </td>
                  </tr>
                ) : null}
                {brandsExternalBoughtTogetherReport.length >= 4 || brandsExternalLosingToReport.length >= 4 || brandsExternalWinningOverReport.length >= 4 ? (
                  <tr>
                    <td className="text-center">
                      {brandsExternalBoughtTogetherReport.length >= 4 ? `${brandsExternalBoughtTogetherReport[3].comparisonBrand} (${brandsExternalBoughtTogetherReport[3].numberOfShoppers})` : '-'}
                    </td>
                    <td className="text-center">
                      {brandsExternalLosingToReport.length >= 4 ? `${brandsExternalLosingToReport[3].losingToBrand} (${brandsExternalLosingToReport[3].numberOfShoppers})` : '-'}
                    </td>
                    <td className="text-center">
                      {brandsExternalWinningOverReport.length >= 4 ? `${brandsExternalWinningOverReport[3].winningOverBrand} (${brandsExternalWinningOverReport[3].numberOfShoppers})` : '-'}
                    </td>
                  </tr>
                ) : null}
                {brandsExternalBoughtTogetherReport.length >= 5 || brandsExternalLosingToReport.length >= 5 || brandsExternalWinningOverReport.length >= 5 ? (
                  <tr>
                    <td className="text-center">
                      {brandsExternalBoughtTogetherReport.length >= 5 ? `${brandsExternalBoughtTogetherReport[4].comparisonBrand} (${brandsExternalBoughtTogetherReport[4].numberOfShoppers})` : '-'}
                    </td>
                    <td className="text-center">
                      {brandsExternalLosingToReport.length >= 5 ? `${brandsExternalLosingToReport[4].losingToBrand} (${brandsExternalLosingToReport[4].numberOfShoppers})` : '-'}
                    </td>
                    <td className="text-center">
                      {brandsExternalWinningOverReport.length >= 5 ? `${brandsExternalWinningOverReport[4].winningOverBrand} (${brandsExternalWinningOverReport[4].numberOfShoppers})` : '-'}
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
          <div className="row" style={{ marginTop: '10px' }}>
            <table className="table table-bordered table-striped">
              <tbody>
                <tr>
                  <td className="text-center metricNameCell">Demographic</td>
                  <td className="text-center metricNameCell">Brand interactors</td>
                  <td className="text-center metricNameCell">Brand buyers</td>
                  <td className="text-center metricNameCell">Brand lost shoppers</td>
                </tr>
                <tr>
                  <td className="text-center metricNameCell">{brandDemographicsReport.find(x => x.demographicGroup === 'All')?.demographicGroup}</td>
                  <td className="text-center">
                    {brandDemographicsReport
                      .find(x => x.demographicGroup === 'All')
                      ?.interactorsPercent.toLocaleString('en', {
                        style: 'percent',
                        maximumFractionDigits: 2,
                      })}
                  </td>
                  <td className="text-center">
                    {brandDemographicsReport
                      .find(x => x.demographicGroup === 'All')
                      ?.buyersPercent.toLocaleString('en', {
                        style: 'percent',
                        maximumFractionDigits: 2,
                      })}
                  </td>
                  <td className="text-center">
                    {brandDemographicsReport
                      .find(x => x.demographicGroup === 'All')
                      ?.lostShoppersPercent.toLocaleString('en', {
                        style: 'percent',
                        maximumFractionDigits: 2,
                      })}
                  </td>
                </tr>
                <tr>
                  <td className="text-center metricNameCell">{brandDemographicsReport.find(x => x.demographicGroup === 'Female')?.demographicGroup}</td>
                  <td className="text-center">
                    {brandDemographicsReport
                      .find(x => x.demographicGroup === 'Female')
                      ?.interactorsPercent.toLocaleString('en', {
                        style: 'percent',
                        maximumFractionDigits: 2,
                      })}
                  </td>
                  <td className="text-center">
                    {brandDemographicsReport
                      .find(x => x.demographicGroup === 'Female')
                      ?.buyersPercent.toLocaleString('en', {
                        style: 'percent',
                        maximumFractionDigits: 2,
                      })}
                  </td>
                  <td className="text-center">
                    {brandDemographicsReport
                      .find(x => x.demographicGroup === 'Female')
                      ?.lostShoppersPercent.toLocaleString('en', {
                        style: 'percent',
                        maximumFractionDigits: 2,
                      })}
                  </td>
                </tr>
                <tr>
                  <td className="text-center metricNameCell">{brandDemographicsReport.find(x => x.demographicGroup === 'Male')?.demographicGroup}</td>
                  <td className="text-center">
                    {brandDemographicsReport
                      .find(x => x.demographicGroup === 'Male')
                      ?.interactorsPercent.toLocaleString('en', {
                        style: 'percent',
                        maximumFractionDigits: 2,
                      })}
                  </td>
                  <td className="text-center">
                    {brandDemographicsReport
                      .find(x => x.demographicGroup === 'Male')
                      ?.buyersPercent.toLocaleString('en', {
                        style: 'percent',
                        maximumFractionDigits: 2,
                      })}
                  </td>
                  <td className="text-center">
                    {brandDemographicsReport
                      .find(x => x.demographicGroup === 'Male')
                      ?.lostShoppersPercent.toLocaleString('en', {
                        style: 'percent',
                        maximumFractionDigits: 2,
                      })}
                  </td>
                </tr>
                <tr>
                  <td className="text-center metricNameCell">{brandDemographicsReport.find(x => x.demographicGroup === 'With Kids')?.demographicGroup}</td>
                  <td className="text-center">
                    {brandDemographicsReport
                      .find(x => x.demographicGroup === 'With Kids')
                      ?.interactorsPercent.toLocaleString('en', {
                        style: 'percent',
                        maximumFractionDigits: 2,
                      })}
                  </td>
                  <td className="text-center">
                    {brandDemographicsReport
                      .find(x => x.demographicGroup === 'With Kids')
                      ?.buyersPercent.toLocaleString('en', {
                        style: 'percent',
                        maximumFractionDigits: 2,
                      })}
                  </td>
                  <td className="text-center">
                    {brandDemographicsReport
                      .find(x => x.demographicGroup === 'With Kids')
                      ?.lostShoppersPercent.toLocaleString('en', {
                        style: 'percent',
                        maximumFractionDigits: 2,
                      })}
                  </td>
                </tr>
                <tr>
                  <td className="text-center metricNameCell">{brandDemographicsReport.find(x => x.demographicGroup === 'Group')?.demographicGroup}</td>
                  <td className="text-center">
                    {brandDemographicsReport
                      .find(x => x.demographicGroup === 'Group')
                      ?.interactorsPercent.toLocaleString('en', {
                        style: 'percent',
                        maximumFractionDigits: 2,
                      })}
                  </td>
                  <td className="text-center">
                    {brandDemographicsReport
                      .find(x => x.demographicGroup === 'Group')
                      ?.buyersPercent.toLocaleString('en', {
                        style: 'percent',
                        maximumFractionDigits: 2,
                      })}
                  </td>
                  <td className="text-center">
                    {brandDemographicsReport
                      .find(x => x.demographicGroup === 'Group')
                      ?.lostShoppersPercent.toLocaleString('en', {
                        style: 'percent',
                        maximumFractionDigits: 2,
                      })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <Grid container alignContent="center" alignItems="center" justify={'center'} style={{ marginTop: '10px' }} direction="column">
          <Grid item>
            <Typography variant="overline">Detailed interaction profile</Typography>
          </Grid>
          <Grid item>
            <InteractorsStackedBarChart data={getBrandChartData(brandDetailedConversionReport)} interactorsType={InteractorsType.Brand} />
          </Grid>
        </Grid>
        <footer className="footer">
          <strong>Copyright &copy; {new Date().getFullYear()} Flow Insights</strong>. Strictly confidential. No distribution allowed. All rights reserved.
        </footer>
      </div>
      <div className="page">
        <div id="flowLogo">
          <img alt="flow logo" className="img-responsive" src={flowLogo} />
        </div>
        <h3 className="text-center">Products in shelf</h3>
        <br />
        {render4ProductsEveryRow()}
        <footer className="footer top-buffer">
          <strong>Copyright &copy; {new Date().getFullYear()} Flow Insights</strong>. Strictly confidential. No distribution allowed. All rights reserved.
        </footer>
      </div>
    </div>
  );
};

export { StandardBrandReportPage };
