import { Typography } from '@material-ui/core';
import JsPanel from 'extensions/jspanel/JsPanel';
import _ from 'lodash';
import { FlowDataShelfLocationReportResult, FlowDataShelfLocationsPairRankingsReport } from 'modules/planogram/types/FlowDataReportResult';
import { FlowDataReportType } from 'modules/planogram/types/ReportingTypes';
import React from 'react';
import { Col, Container, Row, Table } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import { toMaxPrecision, toMaxPrecisionPercent } from 'utils/Helpers';
import { ProductDetails } from '../types';

export type ProductPanelProps = {
  product: ProductDetails;
  imageUrl: string;
  fixingImageSize: boolean;
  hasFixedImage: boolean;
  shelfLocationData?: FlowDataShelfLocationReportResult;
  shelfLocationPairRankingData: FlowDataShelfLocationsPairRankingsReport[];
  selectedMetricName?: string;
  onClose(): void;
  onFixImageSize?(): void;
};

const renderRow = (caption: string, value: string | number | undefined, isSelected: boolean): JSX.Element => {
  return (
    <tr style={{ padding: '0px' }} className={isSelected ? 'bg-info' : ''}>
      <th style={{ padding: '0px' }}>
        <Typography variant={'caption'}>{caption}</Typography>
      </th>
      <td style={{ padding: '0px' }}>
        <Typography variant={'caption'}>{value}</Typography>
      </td>
    </tr>
  );
};

const renderComplexRow = (caption: string, values: string[]): JSX.Element[] => {
  const rows = values.map((value, i) => {
    return (
      <tr key={i} style={{ padding: '0px' }}>
        {i === 0 && (
          <th rowSpan={values.length} style={{ padding: '0px' }}>
            <Typography variant={'caption'}>{caption}</Typography>
          </th>
        )}
        <td style={{ padding: '0px' }}>
          <Typography variant={'caption'}>{value}</Typography>
        </td>
      </tr>
    );
  });
  return rows;
};

const renderMetricRow = (metric: string, productMetric: number | string | undefined, shelfMetric: number | string | undefined, isSelected: boolean): JSX.Element => {
  return (
    <tr style={{ padding: '0px' }} className={isSelected ? 'bg-info' : ''}>
      <th style={{ padding: '0px' }}>
        <Typography variant={'caption'}>{metric}</Typography>
      </th>
      <td style={{ padding: '0px' }}>
        <Typography variant={'caption'}>{productMetric}</Typography>
      </td>
      <td style={{ padding: '0px' }}>
        <Typography variant={'caption'}>{shelfMetric}</Typography>
      </td>
    </tr>
  );
};

const renderProperties = (product: ProductDetails): JSX.Element => {
  return (
    <Table style={{ padding: '0px' }} size="sm" responsive>
      <tbody style={{ padding: '0px' }}>
        {renderRow('GTIN', product?.gtin, false)}
        {renderRow('Name', product?.tradeItemDescription, false)}
        {renderRow('Price', product?.price, false)}
        {renderRow('Brick', product?.gpcBrickDescription, false)}
      </tbody>
    </Table>
  );
};

const renderMetrics = (
  slData: FlowDataShelfLocationReportResult,
  slPairRankingData: FlowDataShelfLocationsPairRankingsReport[] | null,
  selectedMetricName: string | FlowDataReportType | undefined
): JSX.Element => {
  const winningOverSlPairs = _.take(
    _.uniqBy(
      // unique products by tradeItemDescriptionComparison
      _.orderBy(
        // sort by count descending
        slPairRankingData?.filter(x => x.winningOverCount && x.winningOverCount > 0), // exclude products with 0 count
        'winningOverCount',
        'desc'
      ),
      'tradeItemDescriptionComparison'
    ),
    3
  );
  const losingToSlPairs = _.take(
    _.uniqBy(
      _.orderBy(
        slPairRankingData?.filter(x => x.losingToCount && x.losingToCount > 0),
        'losingToCount',
        'desc'
      ),
      'tradeItemDescriptionComparison'
    ),
    3
  );
  const boughtTogetherSlPairs = _.take(
    _.uniqBy(
      _.orderBy(
        slPairRankingData?.filter(x => x.boughtTogetherCount && x.boughtTogetherCount > 0),
        'boughtTogetherCount',
        'desc'
      ),
      'tradeItemDescriptionComparison'
    ),
    3
  );
  return (
    <Table style={{ padding: '0px' }} size="sm" responsive>
      <thead>
        <tr>
          <th>Metric</th>
          <th>Product</th>
          <th>Shelf location</th>
        </tr>
      </thead>
      <tbody>
        {renderMetricRow(
          'Pickup count',
          slData.productPickupCount,
          slData.shelfLocationPickupCount,
          selectedMetricName === FlowDataReportType.ProductPickupCount || selectedMetricName === FlowDataReportType.ShelfLocationPickupCount
        )}
        {renderMetricRow(
          'Return count',
          slData.productReturnCount,
          slData.shelfLocationReturnCount,
          selectedMetricName === FlowDataReportType.ProductReturnCount || selectedMetricName === FlowDataReportType.ShelfLocationReturnCount
        )}
        {renderMetricRow(
          'Touch count',
          slData.productTouchCount,
          slData.shelfLocationTouchCount,
          selectedMetricName === FlowDataReportType.ProductTouchCount || selectedMetricName === FlowDataReportType.ShelfLocationTouchCount
        )}
        {renderMetricRow(
          'Interaction count',
          slData.productInteractionCount,
          slData.shelfLocationInteractionCount,
          selectedMetricName === FlowDataReportType.ProductInteractionCount || selectedMetricName === FlowDataReportType.ShelfLocationInteractionCount
        )}
        {renderMetricRow(
          'First pickups count',
          slData.productFirstPickupsCount,
          slData.shelfLocationFirstPickupsCount,
          selectedMetricName === FlowDataReportType.ProductFirstPickupsCount || selectedMetricName === FlowDataReportType.ShelfLocationFirstPickupsCount
        )}
        {renderMetricRow(
          'Purchase count',
          slData.productPurchaseCount,
          slData.shelfLocationPurchaseCount,
          selectedMetricName === FlowDataReportType.ProductPurchaseCount || selectedMetricName === FlowDataReportType.ShelfLocationPurchaseCount
        )}
        {renderMetricRow(
          'Buyers count',
          slData.productBuyersCount,
          slData.shelfLocationBuyersCount,
          selectedMetricName === FlowDataReportType.ProductBuyersCount || selectedMetricName === FlowDataReportType.ShelfLocationBuyersCount
        )}
        {renderMetricRow(
          'Return ratio',
          toMaxPrecisionPercent(slData.productReturnRatio, 2),
          toMaxPrecisionPercent(slData.shelfLocationReturnRatio, 2),
          selectedMetricName === FlowDataReportType.ProductReturnRatio || selectedMetricName === FlowDataReportType.ShelfLocationReturnRatio
        )}
        {renderMetricRow(
          'Turnover',
          toMaxPrecision(slData.productTurnover, 2),
          toMaxPrecision(slData.shelfLocationTurnover, 2),
          selectedMetricName === FlowDataReportType.ProductTurnover || selectedMetricName === FlowDataReportType.ShelfLocationTurnover
        )}
        {renderMetricRow(
          'Conversion rate',
          toMaxPrecisionPercent(slData.productConversionRate, 2),
          toMaxPrecisionPercent(slData.shelfLocationConversionRate, 2),
          selectedMetricName === FlowDataReportType.ProductConversionRate || selectedMetricName === FlowDataReportType.ShelfLocationConversionRate
        )}
        {renderMetricRow(
          'Share of space',
          toMaxPrecisionPercent(slData.productShareOfSpace, 2),
          toMaxPrecisionPercent(slData.shelfLocationShareOfSpace, 2),
          selectedMetricName === FlowDataReportType.ProductCurrentShareOfSpace || selectedMetricName === FlowDataReportType.ShelfLocationCurrentShareOfSpace
        )}
        {renderMetricRow(
          'Share of unit sales',
          toMaxPrecisionPercent(slData.productShareOfUnitSales, 2),
          toMaxPrecisionPercent(slData.shelfLocationShareOfUnitSales, 2),
          selectedMetricName === FlowDataReportType.ProductShareOfUnitSales || selectedMetricName === FlowDataReportType.ShelfLocationShareOfUnitSales
        )}
        {renderMetricRow(
          'Sales performance',
          toMaxPrecisionPercent(slData.productSalesPerformance, 2),
          toMaxPrecisionPercent(slData.shelfLocationSalesPerformance, 2),
          selectedMetricName === FlowDataReportType.ProductSalesPerformance || selectedMetricName === FlowDataReportType.ShelfLocationSalesPerformance
        )}
        {renderMetricRow(
          'Share of turnover',
          toMaxPrecisionPercent(slData.productShareOfTurnover, 2),
          toMaxPrecisionPercent(slData.shelfLocationShareOfTurnover, 2),
          selectedMetricName === FlowDataReportType.ProductShareOfTurnover || selectedMetricName === FlowDataReportType.ShelfLocationShareOfTurnover
        )}
        {renderMetricRow(
          'Turnover performance',
          toMaxPrecisionPercent(slData.productTurnoverPerformance, 2),
          toMaxPrecisionPercent(slData.shelfLocationTurnoverPerformance, 2),
          selectedMetricName === FlowDataReportType.ProductTurnoverPerformance || selectedMetricName === FlowDataReportType.ShelfLocationTurnoverPerformance
        )}
        {renderMetricRow(
          'Out of stock hours per day',
          toMaxPrecision(slData.productHoursOutOfStockPerDay, 2),
          toMaxPrecision(slData.shelfLocationHoursOutOfStockPerDay, 2),
          selectedMetricName === FlowDataReportType.ProductOosHoursPerDay || selectedMetricName === FlowDataReportType.ShelfLocationOosHoursPerDay
        )}
        {renderMetricRow(
          'Discovery time',
          toMaxPrecision(slData.productDiscoveryTime, 2),
          toMaxPrecision(slData.shelfLocationDiscoveryTime, 2),
          selectedMetricName === FlowDataReportType.ProductDiscoveryTime || selectedMetricName === FlowDataReportType.ShelfLocationDiscoveryTime
        )}
        {renderMetricRow(
          'Seconds to buy and leave',
          toMaxPrecision(slData.productSecondsToBuyAndLeave, 2),
          toMaxPrecision(slData.shelfLocationSecondsToBuyAndLeave, 2),
          selectedMetricName === FlowDataReportType.ProductSecondsToBuyAndLeave || selectedMetricName === FlowDataReportType.ShelfLocationSecondsToBuyAndLeave
        )}
        {renderMetricRow(
          'Seconds to touch and reject',
          toMaxPrecision(slData.productSecondsToTouchAndReject, 2),
          toMaxPrecision(slData.shelfLocationSecondsToTouchAndReject, 2),
          selectedMetricName === FlowDataReportType.ProductSecondsToTouchAndReject || selectedMetricName === FlowDataReportType.ShelfLocationSecondsToTouchAndReject
        )}
        {renderRow('Destination buyers count', toMaxPrecision(slData.productDestinationBuyersCount, 2), selectedMetricName === FlowDataReportType.ProductDestinationBuyersCount)}
        {renderRow('Destination buyers share', toMaxPrecisionPercent(slData.productDestinationBuyersShare, 2), selectedMetricName === FlowDataReportType.ProductDestinationBuyersShare)}
        {renderRow('Lost shoppers count', toMaxPrecision(slData.productLostShoppersCount, 2), selectedMetricName === FlowDataReportType.ProductLostShoppersCount)}
        {renderRow('Lost daily sales out of stock', toMaxPrecision(slData.productLostDailySalesOutOfStock, 2), selectedMetricName === FlowDataReportType.ProductLostDailySalesOutOfStock)}
        {renderRow('Lost daily turnover out of stock', toMaxPrecision(slData.productLostDailyTurnoverOutOfStock, 2), selectedMetricName === FlowDataReportType.ProductLostDailyTurnoverOutOfStock)}
        {renderComplexRow(
          'Winning against',
          winningOverSlPairs.map(x => `${x.tradeItemDescriptionComparison}(${x.winningOverCount})`)
        )}
        {renderComplexRow(
          'Loosing against',
          losingToSlPairs.map(x => `${x.tradeItemDescriptionComparison}(${x.losingToCount})`)
        )}
        {renderComplexRow(
          'Bought together',
          boughtTogetherSlPairs.map(x => `${x.tradeItemDescriptionComparison}(${x.boughtTogetherCount})`)
        )}
      </tbody>
    </Table>
  );
};

const renderImage = (imageUrl: string, hasFixedImage: boolean, fixingImageSize: boolean, onFixImageSize?: () => void): JSX.Element => {
  return (
    <React.Fragment>
      {hasFixedImage ? (
        <React.Fragment>
          <Button className="top-buffer bottom-buffer" onClick={onFixImageSize} disabled={fixingImageSize} id="fixProductSizeButton" color="primary" block={true}>
            {fixingImageSize ? <span>Fixing image size...</span> : <span>Fix Product Size</span>}
          </Button>
        </React.Fragment>
      ) : null}

      <a className="d-block" href={imageUrl} target="_blank" rel="noopener noreferrer">
        <img className="img-fluid" src={imageUrl} alt="" />
      </a>
    </React.Fragment>
  );
};

export const ProductPanel = (props: ProductPanelProps): JSX.Element => {
  return (
    <JsPanel title={props.product.tradeItemDescription || 'New Product'} saveStateId="planogram_productInfoPanel" onClosed={(): void => props.onClose()}>
      <Container fluid={true}>
        <Row>
          <Col xs="12">
            {renderProperties(props.product)}
            {props.shelfLocationData ? renderMetrics(props.shelfLocationData, props.shelfLocationPairRankingData, props.selectedMetricName) : null}
          </Col>
          <Col xs="12" className="bottom-buffer">
            {renderImage(props.imageUrl, props.hasFixedImage, props.fixingImageSize, props.onFixImageSize)}
          </Col>
        </Row>
      </Container>
    </JsPanel>
  );
};
