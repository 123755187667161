/* eslint-disable @typescript-eslint/no-explicit-any */
// reducers.js
import { connectRouter } from 'connected-react-router';
import auth from 'modules/auth/reducer';
import planogram from 'modules/planogram/reducer';
import planograms from 'modules/planograms/reducer';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { combineReducers, Reducer } from 'redux';
import { State } from 'state';
import adminClientDatasetReducer from './modules/admin/clientDataset/reducer';
import adminPlanograms from './modules/admin/planograms/reducer';
import adminUsers from './modules/admin/users/reducer';
import entities from './modules/entities';
import eulaReducer from './modules/eula/reducer';
import realogramPageState from './modules/realogram/reducers';
import standardReports from './modules/reports/standardReports/reducer';
import aggregatedReports from './modules/reports/aggregatedReports/reducer';
import { globalUiReducer as globalUiState } from './modules/shared/reducers';
import shelfConditionImages from './modules/shelfTimelapse/state/reducer';
import { timelinesReducer as timelines } from './modules/timelines/reducer';

const rootReducer = (history: any): Reducer<State> =>
  combineReducers({
    router: connectRouter(history),
    auth,
    planogram,
    planograms,
    toastr: toastrReducer,
    adminUsers,
    adminPlanograms,
    adminClientDatasets: adminClientDatasetReducer,
    globalUiState,
    shelfConditionImages,
    entities,
    timelines,
    standardReports,
    aggregatedReports,
    realogramPageState,
    eula: eulaReducer,
  });

export default rootReducer;
