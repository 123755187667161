import * as React from 'react';
import { Typography, Card, CardHeader, IconButton, Collapse, CardContent, TextField, Badge } from '@material-ui/core';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { FiberNew, ExpandLess, ExpandMore } from '@material-ui/icons';
import trackComponent from 'infrastructure/tracking/withTracking';
import PlanogramModel from 'modules/planogram/domain/PlanogramModel';
import ProductModel from 'modules/planogram/types/ProductModel';

interface MyProps {
  products: ProductModel[];
  currentPlanogram: PlanogramModel;
  onHighlightProduct(priority: boolean, gtin?: number[]): void;
}

interface MyState {
  searchText: string;
  isExpanded: boolean;
}

class ProductSeaechComponent extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {
      searchText: '',
      isExpanded: false,
    };
  }

  getProductsMatch(search: string) {
    search = search ? search.trim().toLowerCase() : '';
    if (search.length === 0) {
      return this.props.products;
    }
    const products = this.props.products.filter(product => {
      const gtin = product.gtin.toString();
      const text = product.tradeItemDescription ? product.tradeItemDescription.trim().toLowerCase() : '';
      return gtin.indexOf(search) > -1 || text.indexOf(search) > -1;
    });
    return products;
  }

  onSearchChange = (text: string) => {
    this.setState({ searchText: text });
    const products = this.getProductsMatch(text);
    if (products.length === 1) {
      this.props.onHighlightProduct(false, [products[0].gtin]);
    } else if (text && text.length > 0) {
      const gtinsToHighlight = products.map(p => p.gtin);
      this.props.onHighlightProduct(false, gtinsToHighlight);
    }
  };

  render() {
    const products = this.getProductsMatch(this.state.searchText);
    const rows = products.map(product => {
      return (
        <div
          key={product.gtin}
          onMouseEnter={() => {
            this.props.onHighlightProduct(false, [product.gtin]);
          }}
          onMouseLeave={() => {
            this.props.onHighlightProduct(false, undefined);
          }}
        >
          <div>
            <li>
              <Typography color="textPrimary" variant="body2">
                {product.tradeItemDescription}
              </Typography>
            </li>
          </div>
          <div>
            <Typography color="textSecondary" variant="caption">
              Gtin: {product.gtin}
            </Typography>
          </div>
        </div>
      );
    });
    return (
      <Row>
        <Col xs="12">
          <Card raised className="card-accent-info border-info bottom-buffer">
            <CardHeader
              style={{ background: 'rgb(0, 255, 0,0.2)' }}
              disableTypography={true}
              action={
                <div>
                  <Badge color="secondary" badgeContent={rows.length}>
                    <FiberNew color="primary" />
                  </Badge>
                  <IconButton onClick={() => this.setState({ isExpanded: !this.state.isExpanded })}>
                    {this.state.isExpanded ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
                  </IconButton>
                </div>
              }
              title={<Typography variant="button">Find products</Typography>}
            />
            <Collapse in={this.state.isExpanded}>
              <CardContent style={{ marginLeft: '0px', paddingLeft: '0px' }}>
                <TextField
                  label="Search in GTIN or name"
                  value={this.state.searchText}
                  fullWidth
                  margin="normal"
                  variant="standard"
                  style={{ marginLeft: '5px' }}
                  onChange={evt => this.onSearchChange(evt.target.value)}
                />
                <div style={{ marginLeft: '5px' }} className="alert alert-info">
                  <strong>Mouse over product name will highlight it on the planogram.</strong>
                </div>
                {products.length === 0 ? (
                  <div className="left-buffer">
                    <Typography color="textSecondary" variant="caption">
                      No products matches your search criteria.
                    </Typography>
                  </div>
                ) : (
                  <div>
                    <ol>{rows}</ol>
                  </div>
                )}
              </CardContent>
            </Collapse>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default trackComponent(ProductSeaechComponent, 'Aside_ProductSearchComponent');
