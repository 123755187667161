import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import useCarousel from './useCarousel';

import { CarouselData } from '../state/selectors';
import ListOfImages from '../ListOfImages';
import SelectedTime from '../SelectedTime';
import Slider from '../Slider';
import ArrowControls from '../ArrowControls';
import PulseLoader from 'react-spinners/PulseLoader';

const useStyles = makeStyles(() =>
  createStyles({
    grid: {
      height: 400,
      marginTop: '5%',
    },
  })
);

export interface CarouselProps {
  data: CarouselData;
}

const Carousel: React.FC<CarouselProps> = ({ data }: CarouselProps): JSX.Element => {
  const classes = useStyles();
  const { sliderData } = data;

  const { loadingAllError, isLoadingAll, hasLoadedAll, handleOnPreloadAll, handleOnChanges, loadedImages, selectedIndex } = useCarousel(data);

  const selectedTime = sliderData[selectedIndex].time;

  return (
    <>
      <Grid container direction="row" justify="center" alignItems="center">
        {!hasLoadedAll && (
          <Button variant="contained" disabled={isLoadingAll} onClick={handleOnPreloadAll} color="primary">
            Preload all images
          </Button>
        )}
        {loadingAllError && <Typography color="error">{loadingAllError}</Typography>}
      </Grid>

      {isLoadingAll && (
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item>
            <Typography>Loading All Images</Typography> <br />
          </Grid>
          <Grid item>
            <PulseLoader color={'#031f5c'} />
          </Grid>
        </Grid>
      )}
      {!isLoadingAll && (
        <>
          <Slider handleOnChange={handleOnChanges} marks={sliderData} selectedIndex={selectedIndex} />
          <SelectedTime selectedTime={selectedTime} />
          {loadedImages[selectedTime] && <ListOfImages images={loadedImages[selectedTime]} />}
          {loadedImages[selectedTime] === undefined && (
            <Grid container direction="column" justify="center" alignItems="center">
              <Grid item>
                <Typography>Loading image...</Typography> <br />
              </Grid>
              <Grid item>
                <PulseLoader color={'#031f5c'} />
              </Grid>
            </Grid>
          )}
          {loadedImages[selectedTime] === null && (
            <Grid className={classes.grid} container direction="row" justify="center" alignItems="center">
              <Typography color="error">Can not load images</Typography>
            </Grid>
          )}
          <ArrowControls numberOfItem={sliderData.length} selectedIndex={selectedIndex} handleOnChanges={handleOnChanges} />
        </>
      )}
    </>
  );
};

export default Carousel;
