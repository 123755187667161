import { Button, Card, CardContent, CardHeader, Divider, Typography } from '@material-ui/core';
import { AddPhotoAlternate, Info } from '@material-ui/icons';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Alert, Col, Row } from 'reactstrap';
import trackComponent from 'infrastructure/tracking/withTracking';
import { State } from 'state';
import DeleteConfirmationModal from '../../planogram/components/modals/DeleteConfirmationModal';
import * as actions from '../actions';
import PlanogramsState from '../state';
import BuilderPlanogramView from '../types/BuilderPlanogramView';
import PlanogramView from '../types/PlanogramView';
import ProjectStorePhaseShelfPlanograms from '../types/ProjectStorePhaseShelfPlanograms';
import BuildersListModal from './modals/BuildersListModal';
import ShelvesListModal from './modals/ShelvesListModal';
import TestListModal from './modals/TestListModal';
import ShelvesTable from './ShelvesTable';
import { isError, isLoading, isLoaded } from 'infrastructure/utils/RemoteObjectStatus';
import { CircularLoadIndicator } from 'modules/shared/components/LoadStatusIndicators/CircularLoadIndicator';
import { AlertErrorTextIndicator } from 'modules/shared/components/LoadStatusIndicators/AlertErrorTextIndicator';

interface PlanogramListPageState {
  selectedPlanogram?: PlanogramView;
  showShelfPlanograms?: string;
  orderByProperty: string;
  orderByDescending: boolean;
  expandedShelves: string[];
}

interface PlanogramListPageProps {
  userId: number;
}

export class PlanogramListPage extends React.Component<PlanogramsState & DispatchProps & PlanogramListPageProps, PlanogramListPageState> {
  constructor(props: PlanogramsState & DispatchProps & PlanogramListPageProps, state: PlanogramListPageState) {
    super(props, state);
    this.state = {
      selectedPlanogram: undefined,
      orderByProperty: 'projectName',
      orderByDescending: false,
      expandedShelves: [],
    };
  }

  getProjecStorePhaseShelves(): ProjectStorePhaseShelfPlanograms[] {
    const planograms = this.props.loadPlanogramsStatus.data ?? [];
    const groups = planograms.reduce((g: { [index: string]: { planograms: PlanogramView[] } }, planogram: PlanogramView) => {
      const psps = planogram.shelfReferenceId;
      let val = g[psps];
      if (!val) {
        val = {
          planograms: [],
        };
        g[psps] = val;
      }
      val.planograms.push(planogram);
      return g;
    }, {});

    const shelves = Object.keys(groups).map((pspsId: string) => {
      const shelf = groups[pspsId];
      const p = shelf.planograms[0] as PlanogramView;
      return {
        shelfId: pspsId,
        phaseNum: p.phaseNum,
        projectName: p.projectName,
        shelfNum: p.shelfNum,
        storeName: p.storeName,
        planograms: shelf.planograms,
      } as ProjectStorePhaseShelfPlanograms;
    });
    return shelves;
  }

  componentDidMount(): void {
    this.props.loadPlanograms();
  }

  componentWillUnmount(): void {
    this.props.resetState();
  }

  componentDidUpdate(prevProps: Readonly<PlanogramsState & DispatchProps & PlanogramListPageProps>): void {
    if (prevProps.loadDeletingPlanogramStatus.loadStatus !== this.props.loadDeletingPlanogramStatus.loadStatus && isLoaded(this.props.loadDeletingPlanogramStatus)) {
      this.props.setShowDeleteForPlanogram(undefined);
    }
  }

  render(): JSX.Element {
    return (
      <div className="animated fadeIn" style={{ marginTop: '1.5rem' }}>
        {isError(this.props.loadPlanogramsStatus) ? (
          <AlertErrorTextIndicator errorText="Error loading planograms." subErrorText={this.props.loadPlanogramsStatus.errorDetails?.errorMessage} />
        ) : isLoading(this.props.loadPlanogramsStatus) ? (
          <CircularLoadIndicator loadingText="Loading planograms" />
        ) : (
          <Row>
            <Col xs="12">
              <Card>
                <CardHeader
                  title="Your Shelves"
                  action={
                    <Button color="primary" variant="outlined" onClick={this.props.showNewModal}>
                      <AddPhotoAlternate fontSize="large" />
                      &nbsp; New Planogram
                    </Button>
                  }
                />
                <Divider light variant="middle" />
                <CardContent>{this.renderPlanogramsTable()}</CardContent>
              </Card>
            </Col>
          </Row>
        )}
        {this.renderNewModal()}
        {this.renderDeleteConfirmationModal()}
        {this.renderBuildersListModal()}
        {this.renderTestListModal()}
      </div>
    );
  }

  renderPlanogramsTable(): JSX.Element | null {
    if (isLoading(this.props.loadPlanogramsStatus)) {
      return null;
    }
    if (!this.props.loadPlanogramsStatus.data?.length) {
      return (
        <Alert color="warning">
          <Row>
            <Info color="primary" />
            &nbsp;
            <Typography color="textSecondary" variant="body2">
              {`You do not have any planograms yet. You can start creating one by clicking the "New Planogram" button on
              the top-right side of this page.`}
            </Typography>
          </Row>
        </Alert>
      );
    }
    return <ShelvesTable shelves={this.getProjecStorePhaseShelves()} userId={this.props.userId} />;
  }

  renderNewModal(): JSX.Element | null {
    if (!this.props.addNewModal) {
      return null;
    }
    return <ShelvesListModal {...this.props} />;
  }

  renderDeleteConfirmationModal(): JSX.Element | null {
    if (this.props.showDeleteForPlanogram) {
      const planogram = this.props.loadPlanogramsStatus.data?.find(p => p.id === this.props.showDeleteForPlanogram);
      if (!planogram) {
        return null;
      }
      return (
        <DeleteConfirmationModal
          enabled={!isLoading(this.props.loadDeletingPlanogramStatus)}
          onClose={(): void => this.props.setShowDeleteForPlanogram(undefined)}
          planogramName={planogram.name}
          onConfirmed={(): void => {
            if (planogram) {
              this.props.deletePlanogramRequest(planogram.id);
            }
          }}
          isDeleting={isLoading(this.props.loadDeletingPlanogramStatus)}
        />
      );
    } else {
      return null;
    }
  }

  renderBuildersListModal(): JSX.Element | null {
    if (this.props.showBuildersForPlanogram) {
      const planogram = this.props.loadPlanogramsStatus.data?.find(p => p.id === this.props.showBuildersForPlanogram);
      if (!planogram) {
        return null;
      }
      return (
        <BuildersListModal
          planogram={planogram}
          onClose={(): void => this.props.setShowBuildersForPlanogram(undefined)}
          loadBuilderPlanogramsRequest={(planogramView: PlanogramView): void => this.props.loadBuilderPlanogramsRequest(planogramView)}
          builderPlanograms={this.props.loadBuilderPlanogramsStatus}
          selectedBuilderPlanogram={this.props.selectedBuilderPlanogram}
          updateSelectedBuilderPlanogram={(builderPlanogram: BuilderPlanogramView): void => this.props.updateSelectedBuilderPlanogram(builderPlanogram)}
          updateBuilderRulesRequest={(builderPlanogram: BuilderPlanogramView): void => this.props.updateBuilderRulesRequest(builderPlanogram)}
          isUpdatingBuilderRules={isLoading(this.props.loadUpdatingBuilderRulesStatus)}
          isUpdatingBuilderRulesSuccess={isLoaded(this.props.loadUpdatingBuilderRulesStatus)}
          isUpdatingBuilderRulesFailure={isError(this.props.loadUpdatingBuilderRulesStatus)}
          restLoadUpdatingBuilderRulesStatus={this.props.restLoadUpdatingBuilderRulesStatus}
        />
      );
    } else {
      return null;
    }
  }

  renderTestListModal(): JSX.Element | null {
    if (this.props.showTestsForPlanogram) {
      const planogram = this.props.loadPlanogramsStatus.data?.find(p => p.id === this.props.showTestsForPlanogram);
      if (!planogram) {
        return null;
      }
      return (
        <TestListModal
          planogramName={planogram.name}
          testInfoList={planogram.testInfoList}
          goal={planogram.goal}
          onClose={(): void => {
            this.props.setShowTestsForPlanogram(undefined);
          }}
        />
      );
    }
    return null;
  }
}

interface DispatchProps {
  loadPlanograms(): void;

  planogramsLoaded(planograms: PlanogramView[]): void;

  showNewModal(): void;

  hideNewModal(): void;

  loadShelves(): void;

  loadBrands(shelfReferenceId: string): void;

  hideCreatePlanogramModal(): void;

  resetState(): void;

  deletePlanogramRequest(planogramId: string): void;

  loadBuilderPlanogramsRequest(planogram: PlanogramView): void;

  updateBuilderRulesRequest(builderPlanogram: BuilderPlanogramView): void;

  updateSelectedBuilderPlanogram(builderPlanogram: BuilderPlanogramView): void;
  restLoadUpdatingBuilderRulesStatus(): void;
  setShowBuildersForPlanogram(planogramId?: string): void;
  setShowTestsForPlanogram(planogramId?: string): void;
  setShowDeleteForPlanogram(planogramId?: string): void;
}

function mapStateToProps(state: State): PlanogramsState & { userId: number } {
  return {
    ...state.planograms,
    userId: Number(state.auth.loadUserStatus.data?.profile.sub ?? 0),
  };
}

const componentToExport = connect<PlanogramsState, DispatchProps, RouteComponentProps>(mapStateToProps, actions)(PlanogramListPage);

export default trackComponent(componentToExport, 'PlanogramListPage');
