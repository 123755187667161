import PlanogramEventBase from './PlanogramEventBase';

export default class AggregatedEvents extends PlanogramEventBase {
  readonly planogramId: string = '';
  readonly events: PlanogramEventBase[];

  constructor(planogramId: string, events: PlanogramEventBase[]) {
    super(`/planograms/${planogramId}/aggregated-events`, 'AggregatedEvents', undefined, true);
    this.planogramId = planogramId;
    this.events = events;
  }
}
