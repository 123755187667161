import { Card, CardContent, Divider, Grid, Switch, Tooltip, Typography } from '@material-ui/core';
import { BarChart, Help as HelpIcon, HorizontalSplit, Info, Settings as SettingsIcon } from '@material-ui/icons';
import trackComponent from 'infrastructure/tracking/withTracking';
import * as React from 'react';
import Alert from 'reactstrap/lib/Alert';
import { ClientDatasetDto } from '../../../../../admin/clientDataset/types/ClientDatasetDto';
import { tooltipsFlowDataReportType } from '../../../../constants/toolTipsDefinitions';
import ProductModel from '../../../../types/ProductModel';
import { FlowDataReportType } from '../../../../types/ReportingTypes';
import { SalesReportModel } from '../../../../types/SalesDataModel';
import AutomatedActionsCard from './AutomatedActionsCard/AutomatedActionsCard';

class PlanogramAsideSettings extends React.Component<Props & DispatchProps> {
  render(): JSX.Element {
    return (
      <React.Fragment>
        {this.renderSettingsCard()}
        {this.props.isAdmin && <AutomatedActionsCard />}
        {this.renderDataControlsCard()}
        {this.renderFiltersCard()}
      </React.Fragment>
    );
  }
  // TODO: create a component for rendering data controls and use it here
  renderFlowDataOptions = (): JSX.Element => {
    // const checked = this.props.selectedFlowMetric === typeName;
    return (
      <Grid container style={{ padding: '0px' }} direction="column">
        <Grid container style={{ padding: '0px' }} direction="row">
          <Grid item xs={6}>
            <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
              Pickup count
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="product-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ProductPickupCount}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ProductPickupCount : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="shelf-location-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ShelfLocationPickupCount}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ShelfLocationPickupCount : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductPickupCount]}>
              <HelpIcon className="float-right" color="primary" fontSize="small" />
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container style={{ padding: '0px' }} direction="row">
          <Grid item xs={6}>
            <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
              Return count
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="product-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ProductReturnCount}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ProductReturnCount : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="shelf-location-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ShelfLocationReturnCount}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ShelfLocationReturnCount : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductReturnCount]}>
              <HelpIcon className="float-right" color="primary" fontSize="small" />
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container style={{ padding: '0px' }} direction="row">
          <Grid item xs={6}>
            <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
              Touch count
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="product-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ProductTouchCount}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ProductTouchCount : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="shelf-location-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ShelfLocationTouchCount}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ShelfLocationTouchCount : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductTouchCount]}>
              <HelpIcon className="float-right" color="primary" fontSize="small" />
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container style={{ padding: '0px' }} direction="row">
          <Grid item xs={6}>
            <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
              Interaction count
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="product-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ProductInteractionCount}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ProductInteractionCount : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="shelf-location-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ShelfLocationInteractionCount}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ShelfLocationInteractionCount : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductInteractionCount]}>
              <HelpIcon className="float-right" color="primary" fontSize="small" />
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container style={{ padding: '0px' }} direction="row">
          <Grid item xs={6}>
            <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
              First pickups count
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="product-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ProductFirstPickupsCount}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ProductFirstPickupsCount : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="shelf-location-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ShelfLocationFirstPickupsCount}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ShelfLocationFirstPickupsCount : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductFirstPickupsCount]}>
              <HelpIcon className="float-right" color="primary" fontSize="small" />
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container style={{ padding: '0px' }} direction="row">
          <Grid item xs={6}>
            <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
              Purchase count
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="product-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ProductPurchaseCount}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ProductPurchaseCount : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="shelf-location-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ShelfLocationPurchaseCount}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ShelfLocationPurchaseCount : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductPurchaseCount]}>
              <HelpIcon className="float-right" color="primary" fontSize="small" />
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container style={{ padding: '0px' }} direction="row">
          <Grid item xs={6}>
            <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
              Buyers count
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="product-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ProductBuyersCount}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ProductBuyersCount : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="shelf-location-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ShelfLocationBuyersCount}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ShelfLocationBuyersCount : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductBuyersCount]}>
              <HelpIcon className="float-right" color="primary" fontSize="small" />
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container style={{ padding: '0px' }} direction="row">
          <Grid item xs={6}>
            <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
              Return ratio
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="product-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ProductReturnRatio}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ProductReturnRatio : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="shelf-location-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ShelfLocationReturnRatio}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ShelfLocationReturnRatio : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductReturnRatio]}>
              <HelpIcon className="float-right" color="primary" fontSize="small" />
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container style={{ padding: '0px' }} direction="row">
          <Grid item xs={6}>
            <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
              Turnover
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="product-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ProductTurnover}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ProductTurnover : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="shelf-location-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ShelfLocationTurnover}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ShelfLocationTurnover : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductTurnover]}>
              <HelpIcon className="float-right" color="primary" fontSize="small" />
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container style={{ padding: '0px' }} direction="row">
          <Grid item xs={6}>
            <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
              Conversion Rate
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="product-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ProductConversionRate}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ProductConversionRate : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="shelf-location-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ShelfLocationConversionRate}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ShelfLocationConversionRate : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductConversionRate]}>
              <HelpIcon className="float-right" color="primary" fontSize="small" />
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container style={{ padding: '0px' }} direction="row">
          <Grid item xs={6}>
            <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
              Share of space
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="product-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ProductCurrentShareOfSpace}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ProductCurrentShareOfSpace : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="shelf-location-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ShelfLocationCurrentShareOfSpace}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ShelfLocationCurrentShareOfSpace : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductCurrentShareOfSpace]}>
              <HelpIcon className="float-right" color="primary" fontSize="small" />
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container style={{ padding: '0px' }} direction="row">
          <Grid item xs={6}>
            <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
              Share of unit sales
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="product-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ProductShareOfUnitSales}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ProductShareOfUnitSales : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="shelf-location-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ShelfLocationShareOfUnitSales}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ShelfLocationShareOfUnitSales : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductShareOfUnitSales]}>
              <HelpIcon className="float-right" color="primary" fontSize="small" />
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container style={{ padding: '0px' }} direction="row">
          <Grid item xs={6}>
            <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
              Sales performance
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="product-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ProductSalesPerformance}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ProductSalesPerformance : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="shelf-location-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ShelfLocationSalesPerformance}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ShelfLocationSalesPerformance : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductSalesPerformance]}>
              <HelpIcon className="float-right" color="primary" fontSize="small" />
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container style={{ padding: '0px' }} direction="row">
          <Grid item xs={6}>
            <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
              Share of turnover
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="product-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ProductShareOfTurnover}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ProductShareOfTurnover : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="shelf-location-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ShelfLocationShareOfTurnover}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ShelfLocationShareOfTurnover : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductShareOfTurnover]}>
              <HelpIcon className="float-right" color="primary" fontSize="small" />
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container style={{ padding: '0px' }} direction="row">
          <Grid item xs={6}>
            <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
              Turnover performance
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="product-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ProductTurnoverPerformance}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ProductTurnoverPerformance : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="shelf-location-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ShelfLocationTurnoverPerformance}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ShelfLocationTurnoverPerformance : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductTurnoverPerformance]}>
              <HelpIcon className="float-right" color="primary" fontSize="small" />
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container style={{ padding: '0px' }} direction="row">
          <Grid item xs={6}>
            <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
              Out of stock hours per day
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="product-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ProductOosHoursPerDay}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ProductOosHoursPerDay : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="shelf-location-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ShelfLocationOosHoursPerDay}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ShelfLocationOosHoursPerDay : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductOosHoursPerDay]}>
              <HelpIcon className="float-right" color="primary" fontSize="small" />
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container style={{ padding: '0px' }} direction="row">
          <Grid item xs={6}>
            <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
              Discovery time
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="product-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ProductDiscoveryTime}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ProductDiscoveryTime : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="shelf-location-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ShelfLocationDiscoveryTime}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ShelfLocationDiscoveryTime : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductDiscoveryTime]}>
              <HelpIcon className="float-right" color="primary" fontSize="small" />
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container style={{ padding: '0px' }} direction="row">
          <Grid item xs={6}>
            <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
              Seconds to buy and leave
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="product-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ProductSecondsToBuyAndLeave}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ProductSecondsToBuyAndLeave : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="shelf-location-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ShelfLocationSecondsToBuyAndLeave}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ShelfLocationSecondsToBuyAndLeave : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductSecondsToBuyAndLeave]}>
              <HelpIcon className="float-right" color="primary" fontSize="small" />
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container style={{ padding: '0px' }} direction="row">
          <Grid item xs={6}>
            <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
              Seconds to touch and reject
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="product-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ProductSecondsToTouchAndReject}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ProductSecondsToTouchAndReject : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="shelf-location-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ShelfLocationSecondsToTouchAndReject}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ShelfLocationSecondsToTouchAndReject : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductSecondsToTouchAndReject]}>
              <HelpIcon className="float-right" color="primary" fontSize="small" />
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container style={{ padding: '0px' }} direction="row">
          <Grid item xs={6}>
            <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
              Destination buyers count
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="product-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ProductDestinationBuyersCount}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ProductDestinationBuyersCount : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2}>
            <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductDestinationBuyersCount]}>
              <HelpIcon className="float-right" color="primary" fontSize="small" />
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container style={{ padding: '0px' }} direction="row">
          <Grid item xs={6}>
            <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
              Destination buyers share
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="product-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ProductDestinationBuyersShare}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ProductDestinationBuyersShare : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2}>
            <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductDestinationBuyersShare]}>
              <HelpIcon className="float-right" color="primary" fontSize="small" />
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container style={{ padding: '0px' }} direction="row">
          <Grid item xs={6}>
            <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
              Lost shoppers count
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="product-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ProductLostShoppersCount}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ProductLostShoppersCount : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2}>
            <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductLostShoppersCount]}>
              <HelpIcon className="float-right" color="primary" fontSize="small" />
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container style={{ padding: '0px' }} direction="row">
          <Grid item xs={6}>
            <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
              Lost daily sales out of stock
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="product-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ProductLostDailySalesOutOfStock}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ProductLostDailySalesOutOfStock : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2}>
            <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductLostDailySalesOutOfStock]}>
              <HelpIcon className="float-right" color="primary" fontSize="small" />
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container style={{ padding: '0px' }} direction="row">
          <Grid item xs={6}>
            <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
              Lost daily turnover out of stock
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Switch
              id="product-data-control-switch"
              color="secondary"
              size="small"
              checked={this.props.selectedFlowMetric === FlowDataReportType.ProductLostDailyTurnoverOutOfStock}
              onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
                const type = a.target.checked ? FlowDataReportType.ProductLostDailyTurnoverOutOfStock : undefined;
                this.props.setFlowReportType(type);
              }}
            />
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={2}>
            <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductLostDailyTurnoverOutOfStock]}>
              <HelpIcon className="float-right" color="primary" fontSize="small" />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderSalesDataOptions = (): JSX.Element[] | JSX.Element => {
    const salesReports = this.props.salesReports;
    if (salesReports.length === 0) {
      return (
        <Alert className="top-buffer" color="info">
          <Typography variant="caption" color="textSecondary">
            <Info color="primary" fontSize="small" />
            &nbsp;Sales reports have not been uploaded.
          </Typography>
        </Alert>
      );
    }
    const rows = salesReports.map(report => {
      return (
        <Grid key={report.reportId} direction="row" container justify="space-between" alignItems="center">
          <Typography style={{ fontSize: '10px' }} variant="caption">
            {report.reportName}
          </Typography>
          <Switch
            onChange={(): void => {
              this.props.setSalesReport(report);
            }}
            color="secondary"
            checked={!this.props.selectedSalesReport ? false : this.props.selectedSalesReport === report}
            size="small"
          />
        </Grid>
      );
    });
    return rows;
  };

  renderClientDataOptions = (): JSX.Element | JSX.Element[] => {
    const clientDatasets = this.props.clientDatasets;
    if (clientDatasets.length === 0) {
      return (
        <Alert className="top-buffer" color="info">
          <Typography variant="caption" color="textSecondary">
            <Info color="primary" fontSize="small" />
            &nbsp;Client datasets have not been uploaded.
          </Typography>
        </Alert>
      );
    }
    const rows = clientDatasets.map(dataset => {
      return (
        <Grid key={dataset.id} direction="row" container justify="space-between" alignItems="center">
          <Typography style={{ fontSize: '10px' }} variant="caption">
            {dataset.name}
          </Typography>
          <Switch
            onChange={(): void => {
              this.props.setClientDatasetReport(dataset);
            }}
            color="secondary"
            checked={!this.props.selectedClientDatasetReport ? false : this.props.selectedClientDatasetReport === dataset}
            size="small"
          />
        </Grid>
      );
    });
    return rows;
  };

  renderDataControlsCard(): JSX.Element {
    return (
      <Card raised className="top-buffer">
        <CardContent style={{ padding: '8px' }}>
          <Typography color="primary" variant="subtitle2">
            <BarChart fontSize="small" />
            &nbsp; Data Controls
          </Typography>
          <Divider className="top-buffer" light={true} variant="fullWidth" />

          <Card raised className="top-buffer-sm">
            <CardContent style={{ padding: '8px' }}>
              <Grid direction="row" container justify="space-between" alignItems="center">
                <Typography color="secondary" variant="overline">
                  Flow Insights DATA
                </Typography>
              </Grid>
              <Divider className="top-buffer bottom-buffer" light={true} variant="fullWidth" />
              <Grid style={{ padding: '0px' }} direction="row" container>
                <Grid item xs={6}>
                  <Typography style={{ padding: '0px', fontSize: '10px' }} variant="caption">
                    Metric
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography style={{ padding: '0px', fontSize: '10px' }} variant="caption">
                    Product
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography style={{ padding: '0px', fontSize: '10px' }} variant="caption">
                    Shelf loc
                  </Typography>
                </Grid>
                <Grid item xs={2}></Grid>
              </Grid>
              {this.renderFlowDataOptions()}
            </CardContent>
          </Card>

          <Card raised className="top-buffer-sm">
            <CardContent style={{ padding: '8px' }}>
              <Grid direction="row" container justify="space-between" alignItems="center">
                <Typography color="secondary" variant="overline">
                  Sales Data
                </Typography>
              </Grid>
              <Divider className="top-buffer bottom-buffer" light={true} variant="fullWidth" />
              {this.renderSalesDataOptions()}
            </CardContent>
          </Card>
          <Card raised className="top-buffer-sm">
            <CardContent style={{ padding: '8px' }}>
              <Grid direction="row" container justify="space-between" alignItems="center">
                <Typography color="secondary" variant="overline">
                  Client Data
                </Typography>
              </Grid>
              <Divider className="top-buffer bottom-buffer" light={true} variant="fullWidth" />
              {this.renderClientDataOptions()}
            </CardContent>
          </Card>
        </CardContent>
      </Card>
    );
  }

  renderSettingsCard(): JSX.Element {
    return (
      <Card raised>
        <CardContent style={{ padding: '8px' }}>
          <Typography color="primary" variant="subtitle2">
            <SettingsIcon fontSize="small" />
            &nbsp; Settings
          </Typography>
          <Divider className="top-buffer bottom-buffer" light={true} variant="fullWidth" />
          <Grid direction="row" container justify="space-between" alignItems="center">
            <Typography variant="caption">Show floating product info</Typography>
            <Switch color="primary" size="medium" checked={this.props.enableShowProductInfo} onChange={(): void => this.props.toggleEnableShowProductInfo()} />
          </Grid>
        </CardContent>
      </Card>
    );
  }

  renderFiltersCard(): JSX.Element {
    return (
      <Card raised className="top-buffer">
        <CardContent style={{ padding: '8px' }}>
          <Typography color="primary" variant="subtitle2">
            <HorizontalSplit fontSize="small" />
            &nbsp; Filters
          </Typography>
          <Divider className="top-buffer bottom-buffer" light={true} variant="fullWidth" />
          <Card raised className="top-buffer-sm">
            <CardContent style={{ padding: '8px' }}>
              <Grid direction="row" container justify="space-between" alignItems="center">
                <Typography color="secondary" variant="overline">
                  GPC Bricks
                </Typography>
                <Switch
                  checked={this.props.enabledGpcBrickFilter}
                  onChange={(): void => {
                    this.props.toggleEnableGpcBrickFilter();
                  }}
                  color="primary"
                  size="medium"
                />
              </Grid>
              <Divider className="top-buffer bottom-buffer" light={true} variant="fullWidth" />
              {this.renderBrickFilters()}
            </CardContent>
          </Card>
        </CardContent>
      </Card>
    );
  }

  renderBrickFilters(): JSX.Element[] {
    const bricks = this.props.products.reduce((result, product) => {
      if (!result.find(b => b.gpcBrickCode === product.gpcBrickCode)) {
        result.push({
          gpcBrickCode: product.gpcBrickCode,
          gpcBrickDescription: product.gpcBrickDescription,
        } as Brick);
      }
      return result;
    }, [] as Brick[]);
    const rows = bricks.map(brick => {
      return (
        <React.Fragment key={brick.gpcBrickCode}>
          <Grid direction="row" container justify="space-between" alignItems="center">
            <Grid item xs={10}>
              <Typography style={{ fontSize: '10px' }} variant="caption">
                {brick.gpcBrickDescription}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Switch
                checked={this.props.gpcBrickCodeFilters.indexOf(brick.gpcBrickCode) > -1}
                onChange={(): void => {
                  this.props.toggleGpcBrickCodeFilter(brick.gpcBrickCode);
                }}
                disabled={!this.props.enabledGpcBrickFilter}
                style={{ float: 'right' }}
                color="secondary"
                size="small"
              />
            </Grid>
          </Grid>
          <Divider className="top-buffer bottom-buffer" light={true} variant="fullWidth" />
        </React.Fragment>
      );
    });
    return rows;
  }
}

interface DispatchProps {
  toggleEnableShowProductInfo(enabled?: boolean): void;

  toggleEnableSalesDataReport(enabled?: boolean): void;

  toggleEnableFlowDataReport(enabled?: boolean): void;

  toggleEnableClientDatasetReport(enabled?: boolean): void;

  setFlowReportType(type?: FlowDataReportType): void;

  setSalesReport(report?: SalesReportModel): void;

  setClientDatasetReport(clientDataset?: ClientDatasetDto): void;

  toggleEnableGpcBrickFilter(): void;

  toggleGpcBrickCodeFilter(gpcBrickCode: number): void;
}

interface Props {
  enableFlowDataReport: boolean;
  enableShowProductInfo: boolean;
  enableSalesDataReport: boolean;
  enableClientDatasetReport: boolean;
  enabledGpcBrickFilter: boolean;
  gpcBrickCodeFilters: number[];
  selectedFlowMetric?: FlowDataReportType;
  selectedSalesReport?: SalesReportModel;
  selectedClientDatasetReport?: ClientDatasetDto;
  salesReports: SalesReportModel[];
  clientDatasets: ClientDatasetDto[];
  products: ProductModel[];
  isAdmin: boolean;
}

interface Brick {
  gpcBrickCode: number;
  gpcBrickDescription: string;
}

export default trackComponent(PlanogramAsideSettings, 'PlanogramAsideSettings');
