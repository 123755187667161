import { WeekDate } from 'types/CommonTypes';
import { PayloadAction } from 'typesafe-actions';
import ErrorDetails from '../../../infrastructure/exception/ErrorDetails';
import { RemoteObjectStatusInterface } from '../../../infrastructure/utils/RemoteObjectStatus';
import { TimeSalesDataReport } from '../aside/SalesDataManagement/types';
export enum ActionTypesShelfCondition {
  FETCH_REQUEST = 'shelfConditionImages/FETCH_REQUEST',
  FETCH_SUCCESS = 'shelfConditionImages/FETCH_SUCCESS',
  FETCH_FAILED = 'shelfConditionImages/FETCH_FAILED',
  SELECT_REPORT = 'shelfConditionImages/SELECT_REPORT',
  SET_CAROUSEL_INDEX = 'shelfConditionImages/SET_CAROUSEL_INDEX',
  SET_CAROUSEL_TIMESTAMP = 'shelfConditionImages/SET_CAROUSEL_TIMESTAMP',
  UPDATE_SELECTED_TIMELAPSE_WEEK = 'shelfConditionImages/UPDATE_SELECTED_TIMELAPSE_WEEK',
}

export enum ShelfConditionsTarget {
  planogram = 0,
  realogram = 1,
}

export interface ShelfConditionsResult {
  cameras: CameraImagesDataItem[];
  storeOpeningHours: StoreOpenHours[];
  projectStartDate: string;
  projectEndDate: string;
}
export interface CameraImagesDataItem {
  name: string;
  shelfImages: {
    timestamp: string;
    imageUrl: string;
  }[];
}

export interface StoreOpenHours {
  openAt: string;
  closeAt: string;
  day: string;
}

export interface WeekOption {
  value: WeekDate;
  label: string;
}
export interface ShelfConditionImagesState {
  shelfConditionsResultStatus: RemoteObjectStatusInterface<ShelfConditionsResult>;
  salesDataUpload: RemoteObjectStatusInterface<TimeSalesDataReport>;
  availableSalesData: TimeSalesDataReport[];
  getSalesDataStatus: RemoteObjectStatusInterface<TimeSalesDataReport[]>;
  selectedSalesDataReport?: string;
  currentIndex: number;
  currentTimestamp: string;
  selectedWeek?: WeekDate;
  weeksInTimelapse?: WeekDate[];
}

export type FetchRequestAction = ({ targetId, targetType }: { targetId: string; targetType: ShelfConditionsTarget }) => PayloadAction<string, { targetId: string; targetType: ShelfConditionsTarget }>;

export type FetchSuccessAction = (data: ShelfConditionsResult) => PayloadAction<string, ShelfConditionsResult>;

export type FetchFailedAction = (errorDetails: ErrorDetails) => PayloadAction<string, ErrorDetails>;

export type SelectSalesReportAction = (reportId?: string) => PayloadAction<string, string>;

export type SetCarouselIndexAction = (index: number) => PayloadAction<string, number>;

export type SetCarouselTimestampAction = (timestamp: string) => PayloadAction<string, string>;

export type UpdateSelectedTimelapseWeekAction = (selectedWeek: WeekDate) => PayloadAction<string, WeekDate>;
