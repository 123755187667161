import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, GridList, GridListTile, Paper, Typography } from '@material-ui/core';
import { Close, Style } from '@material-ui/icons';
import trackComponent from 'infrastructure/tracking/withTracking';
import CompetitorShelf from 'modules/admin/planograms/types/CompetitorShelf';
import React, { Component } from 'react';

interface GetCompetitorModalProps {
  planogramId: string;
  competitorShelves: CompetitorShelf[];
  onClose(): void;
}

class GetCompetitorModal extends Component<GetCompetitorModalProps> {
  render(): JSX.Element {
    return (
      <Dialog fullWidth={true} open={true} onClose={this.props.onClose}>
        <DialogTitle>
          <Typography variant="overline" gutterBottom>
            <Style color="primary" fontSize="large" />
            &nbsp; Competitor Shelves
          </Typography>
          <Divider light variant="middle" />
        </DialogTitle>
        <DialogContent>
          <GridList cellHeight={160} cols={3}>
            {this.props.competitorShelves.map(shelf => (
              <GridListTile key={shelf.referenceId} cols={1}>
                <Paper>
                  <Typography variant="subtitle2" color="textPrimary">
                    {shelf.storeName}
                  </Typography>
                  <a href={this.props.planogramId + '/competitorShelves/' + shelf.referenceId} target="_blank" rel="noopener noreferrer">
                    <img style={{ maxWidth: 300, maxHeight: 300 }} alt={shelf.storeName} src={shelf.shelfImageUrl} />
                  </a>
                </Paper>
              </GridListTile>
            ))}
          </GridList>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={this.props.onClose} color="secondary">
            <Close />
            &nbsp; Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default trackComponent(GetCompetitorModal, 'GetCompetitorModal');
