import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { ErrorOutline, InfoOutlined } from '@material-ui/icons';
import React from 'react';
import { RouteComponentProps, useParams } from 'react-router';
import { Alert } from 'reactstrap';
import PhaseShelvesLoader from './PhaseShelvesLoader';
import { StandardReportsComponent } from './StandardReportsComponent';
export type StandardReportsIndexProps = RouteComponentProps;

const StandardReportsIndex: React.FC<StandardReportsIndexProps> = () => {
  const { selectedProjectId, selectedStoreId, selectedShelfNum, selectedPhaseNum } = useParams() as any;
  return (
    <PhaseShelvesLoader>
      {({ isLoaded, isLoading, loadError, isDataEmpty }): React.ReactNode => {
        const showPhaseShelvesComponent = !isDataEmpty && !isLoading && !loadError && isLoaded;

        return (
          <>
            {isLoading && (
              <Grid direction="column" alignItems="center" container justify="center">
                <CircularProgress /> &nbsp;&nbsp;
                <Typography variant="overline">Loading Project Shelves...</Typography>
              </Grid>
            )}
            {loadError && (
              <Alert className="top-buffer" color="danger">
                <Typography variant="caption" color="textSecondary">
                  <ErrorOutline color="secondary" fontSize="small" />
                  &nbsp;Failed to load Project Shelves. {loadError}
                </Typography>
              </Alert>
            )}
            {!loadError && !isLoading && isLoaded && isDataEmpty && (
              <Alert className="top-buffer" color="warning">
                <Typography variant="caption" color="textPrimary">
                  <InfoOutlined color="primary" fontSize="small" />
                  &nbsp; No Project Shelves Found.
                </Typography>
              </Alert>
            )}
            {showPhaseShelvesComponent && (
              <>
                <StandardReportsComponent
                  selectedProjectId1={selectedProjectId ? parseInt(selectedProjectId) : undefined}
                  selectedStoreId1={selectedStoreId ? parseInt(selectedStoreId) : undefined}
                  selectedShelfNum1={selectedShelfNum ? parseInt(selectedShelfNum) : undefined}
                  selectedPhaseNum1={selectedPhaseNum ? parseInt(selectedPhaseNum) : undefined}
                />
              </>
            )}
          </>
        );
      }}
    </PhaseShelvesLoader>
  );
};

export { StandardReportsIndex };
