import * as React from 'react';
import { RouteProps } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import * as selectors from '../selectors';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Tooltip, Grid, Switch, Typography } from '@material-ui/core';
import { Help as HelpIcon } from '@material-ui/icons';
import { useState } from 'react';
import classNames from 'classnames';
import { FlowDataReportType } from 'modules/planogram/types/ReportingTypes';
import { tooltipsFlowDataReportType } from 'modules/planogram/constants/toolTipsDefinitions';
import { selectFlowReportTypeAction } from '../actions';

enum ActiveTab {
  DataControls = 1,
  Blah = 2,
}

interface FlowDataOptionsProps {
  flowReportType: FlowDataReportType | undefined;
  selectReport(flowReportType: FlowDataReportType | undefined): void;
}

// TODO: create a component for rendering data controls and use it here
const renderFlowDataOptions = (props: FlowDataOptionsProps): JSX.Element => {
  // const checked = props.flowReportType === typeName;
  return (
    <Grid container style={{ padding: '0px' }} direction="column">
      <Grid style={{ padding: '0px' }} direction="row" container>
        <Grid item xs={6}>
          <Typography style={{ padding: '0px', fontSize: '10px' }} variant="caption">
            Metric
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography style={{ padding: '0px', fontSize: '10px' }} variant="caption">
            Product
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography style={{ padding: '0px', fontSize: '10px' }} variant="caption">
            Shelf loc
          </Typography>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
      <Grid container style={{ padding: '0px' }} direction="row">
        <Grid item xs={6}>
          <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
            Pickup count
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="product-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ProductPickupCount}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ProductPickupCount : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="shelf-location-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ShelfLocationPickupCount}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ShelfLocationPickupCount : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductPickupCount]}>
            <HelpIcon className="float-right" color="primary" fontSize="small" />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container style={{ padding: '0px' }} direction="row">
        <Grid item xs={6}>
          <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
            Return count
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="product-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ProductReturnCount}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ProductReturnCount : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="shelf-location-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ShelfLocationReturnCount}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ShelfLocationReturnCount : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductReturnCount]}>
            <HelpIcon className="float-right" color="primary" fontSize="small" />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container style={{ padding: '0px' }} direction="row">
        <Grid item xs={6}>
          <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
            Touch count
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="product-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ProductTouchCount}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ProductTouchCount : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="shelf-location-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ShelfLocationTouchCount}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ShelfLocationTouchCount : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductTouchCount]}>
            <HelpIcon className="float-right" color="primary" fontSize="small" />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container style={{ padding: '0px' }} direction="row">
        <Grid item xs={6}>
          <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
            Interaction count
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="product-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ProductInteractionCount}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ProductInteractionCount : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="shelf-location-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ShelfLocationInteractionCount}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ShelfLocationInteractionCount : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductInteractionCount]}>
            <HelpIcon className="float-right" color="primary" fontSize="small" />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container style={{ padding: '0px' }} direction="row">
        <Grid item xs={6}>
          <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
            First pickups count
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="product-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ProductFirstPickupsCount}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ProductFirstPickupsCount : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="shelf-location-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ShelfLocationFirstPickupsCount}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ShelfLocationFirstPickupsCount : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductFirstPickupsCount]}>
            <HelpIcon className="float-right" color="primary" fontSize="small" />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container style={{ padding: '0px' }} direction="row">
        <Grid item xs={6}>
          <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
            Purchase count
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="product-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ProductPurchaseCount}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ProductPurchaseCount : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="shelf-location-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ShelfLocationPurchaseCount}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ShelfLocationPurchaseCount : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductPurchaseCount]}>
            <HelpIcon className="float-right" color="primary" fontSize="small" />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container style={{ padding: '0px' }} direction="row">
        <Grid item xs={6}>
          <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
            Buyers count
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="product-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ProductBuyersCount}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ProductBuyersCount : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="shelf-location-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ShelfLocationBuyersCount}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ShelfLocationBuyersCount : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductBuyersCount]}>
            <HelpIcon className="float-right" color="primary" fontSize="small" />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container style={{ padding: '0px' }} direction="row">
        <Grid item xs={6}>
          <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
            Return ratio
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="product-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ProductReturnRatio}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ProductReturnRatio : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="shelf-location-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ShelfLocationReturnRatio}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ShelfLocationReturnRatio : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductReturnRatio]}>
            <HelpIcon className="float-right" color="primary" fontSize="small" />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container style={{ padding: '0px' }} direction="row">
        <Grid item xs={6}>
          <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
            Turnover
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="product-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ProductTurnover}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ProductTurnover : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="shelf-location-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ShelfLocationTurnover}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ShelfLocationTurnover : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductTurnover]}>
            <HelpIcon className="float-right" color="primary" fontSize="small" />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container style={{ padding: '0px' }} direction="row">
        <Grid item xs={6}>
          <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
            Conversion Rate
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="product-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ProductConversionRate}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ProductConversionRate : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="shelf-location-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ShelfLocationConversionRate}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ShelfLocationConversionRate : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductConversionRate]}>
            <HelpIcon className="float-right" color="primary" fontSize="small" />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container style={{ padding: '0px' }} direction="row">
        <Grid item xs={6}>
          <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
            Share of space
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="product-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ProductCurrentShareOfSpace}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ProductCurrentShareOfSpace : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="shelf-location-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ShelfLocationCurrentShareOfSpace}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ShelfLocationCurrentShareOfSpace : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductCurrentShareOfSpace]}>
            <HelpIcon className="float-right" color="primary" fontSize="small" />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container style={{ padding: '0px' }} direction="row">
        <Grid item xs={6}>
          <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
            Share of unit sales
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="product-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ProductShareOfUnitSales}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ProductShareOfUnitSales : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="shelf-location-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ShelfLocationShareOfUnitSales}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ShelfLocationShareOfUnitSales : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductShareOfUnitSales]}>
            <HelpIcon className="float-right" color="primary" fontSize="small" />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container style={{ padding: '0px' }} direction="row">
        <Grid item xs={6}>
          <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
            Sales performance
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="product-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ProductSalesPerformance}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ProductSalesPerformance : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="shelf-location-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ShelfLocationSalesPerformance}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ShelfLocationSalesPerformance : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductSalesPerformance]}>
            <HelpIcon className="float-right" color="primary" fontSize="small" />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container style={{ padding: '0px' }} direction="row">
        <Grid item xs={6}>
          <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
            Share of turnover
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="product-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ProductShareOfTurnover}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ProductShareOfTurnover : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="shelf-location-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ShelfLocationShareOfTurnover}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ShelfLocationShareOfTurnover : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductShareOfTurnover]}>
            <HelpIcon className="float-right" color="primary" fontSize="small" />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container style={{ padding: '0px' }} direction="row">
        <Grid item xs={6}>
          <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
            Turnover performance
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="product-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ProductTurnoverPerformance}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ProductTurnoverPerformance : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="shelf-location-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ShelfLocationTurnoverPerformance}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ShelfLocationTurnoverPerformance : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductTurnoverPerformance]}>
            <HelpIcon className="float-right" color="primary" fontSize="small" />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container style={{ padding: '0px' }} direction="row">
        <Grid item xs={6}>
          <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
            Out of stock hours per day
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="product-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ProductOosHoursPerDay}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ProductOosHoursPerDay : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="shelf-location-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ShelfLocationOosHoursPerDay}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ShelfLocationOosHoursPerDay : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductOosHoursPerDay]}>
            <HelpIcon className="float-right" color="primary" fontSize="small" />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container style={{ padding: '0px' }} direction="row">
        <Grid item xs={6}>
          <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
            Discovery time
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="product-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ProductDiscoveryTime}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ProductDiscoveryTime : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="shelf-location-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ShelfLocationDiscoveryTime}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ShelfLocationDiscoveryTime : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductDiscoveryTime]}>
            <HelpIcon className="float-right" color="primary" fontSize="small" />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container style={{ padding: '0px' }} direction="row">
        <Grid item xs={6}>
          <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
            Seconds to buy and leave
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="product-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ProductSecondsToBuyAndLeave}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ProductSecondsToBuyAndLeave : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="shelf-location-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ShelfLocationSecondsToBuyAndLeave}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ShelfLocationSecondsToBuyAndLeave : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductSecondsToBuyAndLeave]}>
            <HelpIcon className="float-right" color="primary" fontSize="small" />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container style={{ padding: '0px' }} direction="row">
        <Grid item xs={6}>
          <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
            Seconds to touch and reject
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="product-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ProductSecondsToTouchAndReject}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ProductSecondsToTouchAndReject : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="shelf-location-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ShelfLocationSecondsToTouchAndReject}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ShelfLocationSecondsToTouchAndReject : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductSecondsToTouchAndReject]}>
            <HelpIcon className="float-right" color="primary" fontSize="small" />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container style={{ padding: '0px' }} direction="row">
        <Grid item xs={6}>
          <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
            Destination buyers count
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="product-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ProductDestinationBuyersCount}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ProductDestinationBuyersCount : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductDestinationBuyersCount]}>
            <HelpIcon className="float-right" color="primary" fontSize="small" />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container style={{ padding: '0px' }} direction="row">
        <Grid item xs={6}>
          <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
            Destination buyers share
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="product-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ProductDestinationBuyersShare}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ProductDestinationBuyersShare : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductDestinationBuyersShare]}>
            <HelpIcon className="float-right" color="primary" fontSize="small" />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container style={{ padding: '0px' }} direction="row">
        <Grid item xs={6}>
          <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
            Lost shoppers count
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="product-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ProductLostShoppersCount}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ProductLostShoppersCount : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductLostShoppersCount]}>
            <HelpIcon className="float-right" color="primary" fontSize="small" />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container style={{ padding: '0px' }} direction="row">
        <Grid item xs={6}>
          <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
            Lost daily sales out of stock
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="product-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ProductLostDailySalesOutOfStock}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ProductLostDailySalesOutOfStock : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductLostDailySalesOutOfStock]}>
            <HelpIcon className="float-right" color="primary" fontSize="small" />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container style={{ padding: '0px' }} direction="row">
        <Grid item xs={6}>
          <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
            Lost daily turnover out of stock
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Switch
            id="product-data-control-switch"
            color="secondary"
            size="small"
            checked={props.flowReportType === FlowDataReportType.ProductLostDailyTurnoverOutOfStock}
            onChange={(a: React.ChangeEvent<HTMLInputElement>): void => {
              const type = a.target.checked ? FlowDataReportType.ProductLostDailyTurnoverOutOfStock : undefined;
              props.selectReport(type);
            }}
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          <Tooltip placement="top" title={tooltipsFlowDataReportType[FlowDataReportType.ProductLostDailyTurnoverOutOfStock]}>
            <HelpIcon className="float-right" color="primary" fontSize="small" />
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );
};

const RealogramAside: React.FC<RouteProps> = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const selectedFlowReportType = useSelector(selectors.getSelectedFlowDataReportType);
  const [activeTab, setActiveTab] = useState(ActiveTab.DataControls);
  return (
    <>
      <Nav tabs id="sideNavTabs">
        <NavItem>
          <Tooltip title="Data Controls">
            <NavLink
              id="settingsAndDataCtrlsNavLnk"
              className={classNames({ active: activeTab === ActiveTab.DataControls })}
              onClick={(): void => {
                setActiveTab(ActiveTab.DataControls);
              }}
            >
              <i className="fa fa-sliders" />
            </NavLink>
          </Tooltip>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={ActiveTab.DataControls} className="p-3">
          {renderFlowDataOptions({
            flowReportType: selectedFlowReportType,
            selectReport: (selectedReport: FlowDataReportType): void => {
              dispatch(selectFlowReportTypeAction(selectedReport));
            },
          })}
        </TabPane>
      </TabContent>
    </>
  );
};

export default RealogramAside;
