import ShelfLocation from '../domain/ShelfLocation';
import { BrandPermissionType } from '../../planograms/types/BrandPermissionType';
import { ActionRules } from '../../planograms/types/ActionRules';
import { DataInsightType } from '../../planograms/types/DataInsightType';

export function isPlanogramBuilder(ownerId: number, builderId: number, userId: number): boolean {
  return builderId === userId;
}

/* PermissionsHelper is a set of utility methods used for validating authorization, mostly boolean checks.
 These methods involve computing the planogram rules and data visibility assigned by the Planogram Owner.
 */

export function isAllowedToView(shelfLocation: ShelfLocation | null): boolean {
  return !(shelfLocation === null || (shelfLocation.actionsAllowed[BrandPermissionType.canView] !== undefined && !shelfLocation.actionsAllowed[BrandPermissionType.canView]));
}
export function isAllowedToAdd(actionRules: ActionRules | null, ownerId: number, builderId: number, userId: number): boolean {
  return !(actionRules === null || !isPlanogramBuilder(ownerId, builderId, userId) || (actionRules !== undefined && !actionRules.isDisabled && !actionRules.canAddNewShelfLocations));
}

export function isAllowedToTestWithFlow(actionRules: ActionRules | null, ownerId: number, builderId: number, userId: number): boolean {
  return !(actionRules === null || !isPlanogramBuilder(ownerId, builderId, userId) || (actionRules !== undefined && !actionRules.isDisabled && !actionRules.canTestWithFlow));
}
export function isAllowedSalesData(dataInsights: string[]): boolean {
  return dataInsights.includes(DataInsightType.ALL) || dataInsights.includes(DataInsightType.SALES);
}
export function isMasterPlanogram(ownerId: number, builderId: number): boolean {
  return ownerId === builderId;
}

export function isPlanogramOwner(ownerId: number, builderId: number, userId: number): boolean {
  return ownerId === userId;
}
