import { Button, Card, CardActions, CardContent, Container, Grid, InputAdornment, LinearProgress, TextField } from '@material-ui/core';
import { Add, Cancel, Delete, FormatQuote, InsertComment, SettingsBackupRestore, Stop, Update } from '@material-ui/icons';
import * as React from 'react';
import trackComponent from 'infrastructure/tracking/withTracking';
import { isAllowedToAdd, isPlanogramBuilder } from '../../../../helpers/permissionsHelper';
import PlanogramModel from '../../../../domain/PlanogramModel';
import DeleteConfirmationModal from '../../../modals/DeleteConfirmationModal';
import ResetConfirmationModal from '../../../modals/ResetConfirmationModal';
import OriginalShelfImageCard from './OriginalShelfImageCard/OriginalShelfImageCard';

interface MyProps {
  planogram: PlanogramModel;
  userId: number;
  nameUpdating: boolean;
  updatingComments: boolean;
  drawingNewShelfLocation: boolean;
  deleting: boolean;
  isResettingPlanogram: boolean;
  newProductRequest(): void;
  newProductRequestStop(): void;
  changePlanogramName(planogramId: string, newName: string): void;
  updateCommentsRequest(planogramId: string, comments: string): void;
  deletePlanogramRequest(planogramId: string): void;
  resetPlanogramRequest(planogramId: string): void;
}

interface MyState {
  planogramName: string;
  comments: string;
  showResetConfirmationDialog: boolean;
  showDelete: boolean;
}

class PlanogramInfo extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {
      planogramName: props.planogram.name,
      comments: props.planogram.comments || '',
      showDelete: false,
      showResetConfirmationDialog: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  render() {
    const isBuilder = isPlanogramBuilder(this.props.planogram.ownerId, this.props.planogram.builderId, this.props.userId);
    const planogram = this.props.planogram;
    return (
      <Container style={{ padding: '8px' }}>
        <OriginalShelfImageCard imageUrl={planogram.imageUrl} />
        <Card className="top-buffer-sm">
          <CardContent style={{ paddingBottom: '8px' }}>
            <TextField
              id="name"
              label="Planogram name"
              placeholder="Planogram name"
              onChange={evt => this.setState({ planogramName: evt.target.value })}
              disabled={this.props.nameUpdating || !isBuilder}
              value={this.state.planogramName}
              fullWidth
              margin="normal"
              variant="standard"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FormatQuote color="primary" />
                  </InputAdornment>
                ),
                readOnly: false,
              }}
            />
          </CardContent>
          <CardActions style={{ padding: '16px', paddingTop: '0px' }}>
            <Grid direction="row" container justify="space-between" alignItems="center">
              <Button
                size="small"
                variant="outlined"
                type="submit"
                color="primary"
                onClick={evt => this.onSubmit(evt)}
                disabled={this.props.nameUpdating || planogram.name === this.state.planogramName}
              >
                <Update />
                &nbsp;{this.props.nameUpdating ? 'Updating..' : 'Update'}
              </Button>
              <Button
                size="small"
                variant="outlined"
                disabled={this.props.nameUpdating || planogram.name === this.state.planogramName}
                color="secondary"
                onClick={() => this.setState({ planogramName: planogram.name })}
              >
                <Cancel /> &nbsp;Cancel
              </Button>
            </Grid>
          </CardActions>
          {this.props.nameUpdating ? <LinearProgress /> : null}
        </Card>
        <Card className="top-buffer-sm">
          <CardContent style={{ paddingBottom: '8px' }}>
            <TextField
              id="comments"
              label="Comments"
              placeholder="You can type in comments here."
              onChange={evt => this.setState({ comments: evt.target.value })}
              disabled={this.props.updatingComments || !isBuilder}
              value={this.state.comments}
              fullWidth
              multiline={true}
              margin="normal"
              variant="standard"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <InsertComment color="primary" />
                  </InputAdornment>
                ),
                readOnly: false,
              }}
            />
          </CardContent>
          <CardActions style={{ padding: '16px', paddingTop: '0px' }}>
            <Grid direction="row" container justify="space-between" alignItems="center">
              <Button
                type="submit"
                size="small"
                variant="outlined"
                color="primary"
                onClick={evt => this.onSubmitComments(evt)}
                disabled={this.props.updatingComments || !isBuilder || planogram.comments === this.state.comments}
              >
                <Update />
                &nbsp;{this.props.updatingComments ? 'Updating...' : 'Update'}
              </Button>
              <Button
                size="small"
                variant="outlined"
                disabled={this.props.updatingComments || planogram.comments === this.state.comments}
                color="secondary"
                onClick={() => this.setState({ comments: planogram.comments })}
              >
                <Cancel /> &nbsp;Cancel
              </Button>
            </Grid>
          </CardActions>
          {this.props.updatingComments ? <LinearProgress /> : null}
        </Card>

        <Card className="top-buffer-sm">
          <CardContent>
            <Button
              style={{ marginTop: '10px' }}
              fullWidth={true}
              variant="outlined"
              disabled={!isAllowedToAdd(this.props.planogram.actionRules, this.props.planogram.ownerId, this.props.planogram.builderId, this.props.userId)}
              color={this.props.drawingNewShelfLocation ? 'secondary' : 'primary'}
              onClick={() => {
                if (this.props.drawingNewShelfLocation) {
                  this.props.newProductRequestStop();
                } else {
                  this.props.newProductRequest();
                }
              }}
            >
              {this.props.drawingNewShelfLocation ? <Stop color="secondary" /> : <Add color="primary" />} &nbsp;
              {this.props.drawingNewShelfLocation ? 'Stop New Product' : 'New Product'}
            </Button>
            <Button
              style={{ marginTop: '10px' }}
              fullWidth={true}
              variant="contained"
              disabled={!isBuilder}
              onClick={() => this.setState({ showResetConfirmationDialog: true })}
              color="primary"
              id="planogramConfirmReset"
            >
              <SettingsBackupRestore />
              &nbsp; Reset Planogram
            </Button>
            <Button
              style={{ marginTop: '10px' }}
              fullWidth={true}
              variant="contained"
              disabled={!isBuilder}
              onClick={() => this.setState({ showDelete: true })}
              color="secondary"
              id="planogramConfirmDelete"
            >
              <Delete />
              &nbsp; Delete Planogram
            </Button>
          </CardContent>
        </Card>
        {this.state.showResetConfirmationDialog ? (
          <ResetConfirmationModal
            enabled={!this.props.isResettingPlanogram}
            onClose={() => this.setState({ showResetConfirmationDialog: false })}
            planogramName={planogram.name}
            onConfirmed={() => {
              this.props.resetPlanogramRequest(planogram.id);
            }}
            isResettingPlanogram={this.props.isResettingPlanogram}
          />
        ) : null}
        {this.state.showDelete ? (
          <DeleteConfirmationModal
            enabled={!this.props.deleting}
            onClose={() => this.setState({ showDelete: false })}
            planogramName={planogram.name}
            onConfirmed={() => {
              this.props.deletePlanogramRequest(planogram.id);
            }}
            isDeleting={this.props.deleting}
          />
        ) : null}
      </Container>
    );
  }

  private onSubmit(evt: any) {
    evt.preventDefault();
    const planogram = this.props.planogram;
    this.props.changePlanogramName(planogram.id, this.state.planogramName);
    return false;
  }
  private onSubmitComments = (evt: any) => {
    evt.preventDefault();

    const planogram = this.props.planogram;
    this.props.updateCommentsRequest(planogram.id, this.state.comments);
    return false;
  };
}

export default trackComponent(PlanogramInfo, 'Aside_PlanogramInfo');
