import { Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import { BarChart, Business, Close, DateRange, Info, Label, Store, TrendingUp } from '@material-ui/icons';
import * as React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Alert, Col } from 'reactstrap';
import { getDateInInputFormat } from '../../../../utils/dateHelper';
import TestInfoDto from '../../types/TestInfoDto';

interface TestInfoModalProps {
  testInfo?: TestInfoDto;
  onClose(): void;
}

export default class TestInfoModal extends React.Component<TestInfoModalProps> {
  render(): JSX.Element {
    return (
      <Dialog open={true} onClose={this.props.onClose}>
        <DialogTitle>
          <Typography variant="overline" gutterBottom>
            <BarChart color="primary" fontSize="large" />
            &nbsp; Test Info
          </Typography>
          <Divider light variant="middle" />
        </DialogTitle>
        <LoadingOverlay active={this.props.testInfo === undefined} spinner={true} text="Loading test info...">
          {this.props.testInfo && (
            <DialogContent>
              <Card className="top-buffer-sm">
                <CardHeader
                  disableTypography={true}
                  titleTypographyProps={{ color: 'primary' }}
                  title={
                    <Typography color="primary" variant="overline">
                      <Business />
                      &nbsp;Operations
                    </Typography>
                  }
                />
                <Divider light variant="middle" />
                <CardContent>{this.renderTestOperationsInfoForm(this.props.testInfo)}</CardContent>
              </Card>
              <Card className="top-buffer">
                <CardHeader
                  disableTypography={true}
                  titleTypographyProps={{ color: 'primary' }}
                  title={
                    <Typography color="primary" variant="overline">
                      <TrendingUp />
                      &nbsp;Report
                    </Typography>
                  }
                />
                <Divider light variant="middle" />
                <CardContent className="left-buffer right-buffer">{this.renderTestReport(this.props.testInfo)}</CardContent>
              </Card>
            </DialogContent>
          )}
        </LoadingOverlay>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={this.props.onClose} className="pull-right">
            <Close />
            &nbsp;
            <Typography variant="button">Cancel</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderTestOperationsInfoForm(testInfo: TestInfoDto): JSX.Element {
    return (
      <Col>
        <TextField
          id="testName"
          label="Test Name"
          placeholder="Test Name"
          fullWidth
          disabled={true}
          margin="normal"
          variant="outlined"
          value={testInfo.testName}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Label color="secondary" />
              </InputAdornment>
            ),
            readOnly: false,
          }}
        />
        <TextField
          id="storeName"
          label="Store Name"
          placeholder="Store Name"
          value={testInfo.operationsInfo.storeName}
          disabled={true}
          fullWidth
          margin="normal"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Store color="secondary" />
              </InputAdornment>
            ),
            readOnly: false,
          }}
        />
        <Grid container justify="space-between">
          <TextField
            id="date"
            label="Start Date"
            type="date"
            margin="normal"
            variant="outlined"
            disabled={true}
            value={getDateInInputFormat(testInfo.operationsInfo.startDate)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <DateRange color="secondary" />
                </InputAdornment>
              ),
              readOnly: false,
            }}
          />
          <TextField
            id="date"
            label="End Date"
            type="date"
            disabled={true}
            margin="normal"
            variant="outlined"
            value={getDateInInputFormat(testInfo.operationsInfo.endDate)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <DateRange color="secondary" />
                </InputAdornment>
              ),
              readOnly: false,
            }}
          />
        </Grid>
      </Col>
    );
  }

  renderTestReport(testInfo: TestInfoDto): JSX.Element | null {
    if (testInfo.testReportData === null) {
      return (
        <Alert className="top-buffer" color="info">
          <Typography variant="caption" color="textSecondary">
            <Info color="primary" fontSize="small" />
            &nbsp;Test results have not yet arrived.
          </Typography>
        </Alert>
      );
    }
    return null;
    // TODO: implement rendering test report findings
  }
}
