import ErrorDetails from 'infrastructure/exception/ErrorDetails';
import { AggregatedMetrics, AggregatedOnePagerReport, AvailableAggregatedReports } from './types';

export const AGGREGATED_REPORTS_LOAD_AVAILABLE_REPORTS_REQUEST = 'AGGREGATED_REPORTS_LOAD_AVAILABLE_REPORTS_REQUEST';
export type AGGREGATED_REPORTS_LOAD_AVAILABLE_REPORTS_REQUEST = typeof AGGREGATED_REPORTS_LOAD_AVAILABLE_REPORTS_REQUEST;

export const AGGREGATED_REPORTS_LOAD_AVAILABLE_REPORTS_SUCCESS = 'AGGREGATED_REPORTS_LOAD_AVAILABLE_REPORTS_SUCCESS';
export type AGGREGATED_REPORTS_LOAD_AVAILABLE_REPORTS_SUCCESS = typeof AGGREGATED_REPORTS_LOAD_AVAILABLE_REPORTS_SUCCESS;

export const AGGREGATED_REPORTS_LOAD_AVAILABLE_REPORTS_FAILURE = 'AGGREGATED_REPORTS_LOAD_AVAILABLE_REPORTS_FAILURE';
export type AGGREGATED_REPORTS_LOAD_AVAILABLE_REPORTS_FAILURE = typeof AGGREGATED_REPORTS_LOAD_AVAILABLE_REPORTS_FAILURE;

export const AGGREGATED_REPORTS_LOAD_BRAND_REPORT_REQUEST = 'AGGREGATED_REPORTS_LOAD_BRAND_REPORT_REQUEST';
export type AGGREGATED_REPORTS_LOAD_BRAND_REPORT_REQUEST = typeof AGGREGATED_REPORTS_LOAD_BRAND_REPORT_REQUEST;

export const AGGREGATED_REPORTS_LOAD_BRAND_REPORT_SUCCESS = 'AGGREGATED_REPORTS_LOAD_BRAND_REPORT_SUCCESS';
export type AGGREGATED_REPORTS_LOAD_BRAND_REPORT_SUCCESS = typeof AGGREGATED_REPORTS_LOAD_BRAND_REPORT_SUCCESS;

export const AGGREGATED_REPORTS_LOAD_BRAND_REPORT_FAILURE = 'AGGREGATED_REPORTS_LOAD_BRAND_REPORT_FAILURE';
export type AGGREGATED_REPORTS_LOAD_BRAND_REPORT_FAILURE = typeof AGGREGATED_REPORTS_LOAD_BRAND_REPORT_FAILURE;

export const AGGREGATED_REPORTS_LOAD_BRICK_REPORT_REQUEST = 'AGGREGATED_REPORTS_LOAD_BRICK_REPORT_REQUEST';
export type AGGREGATED_REPORTS_LOAD_BRICK_REPORT_REQUEST = typeof AGGREGATED_REPORTS_LOAD_BRICK_REPORT_REQUEST;

export const AGGREGATED_REPORTS_LOAD_BRICK_REPORT_SUCCESS = 'AGGREGATED_REPORTS_LOAD_BRICK_REPORT_SUCCESS';
export type AGGREGATED_REPORTS_LOAD_BRICK_REPORT_SUCCESS = typeof AGGREGATED_REPORTS_LOAD_BRICK_REPORT_SUCCESS;

export const AGGREGATED_REPORTS_LOAD_BRICK_REPORT_FAILURE = 'AGGREGATED_REPORTS_LOAD_BRICK_REPORT_FAILURE';
export type AGGREGATED_REPORTS_LOAD_BRICK_REPORT_FAILURE = typeof AGGREGATED_REPORTS_LOAD_BRICK_REPORT_FAILURE;

export const AGGREGATED_REPORTS_LOAD_BRAND_BRICK_REPORT_REQUEST = 'AGGREGATED_REPORTS_LOAD_BRAND_BRICK_REPORT_REQUEST';
export type AGGREGATED_REPORTS_LOAD_BRAND_BRICK_REPORT_REQUEST = typeof AGGREGATED_REPORTS_LOAD_BRAND_BRICK_REPORT_REQUEST;

export const AGGREGATED_REPORTS_LOAD_BRAND_BRICK_REPORT_SUCCESS = 'AGGREGATED_REPORTS_LOAD_BRAND_BRICK_REPORT_SUCCESS';
export type AGGREGATED_REPORTS_LOAD_BRAND_BRICK_REPORT_SUCCESS = typeof AGGREGATED_REPORTS_LOAD_BRAND_BRICK_REPORT_SUCCESS;

export const AGGREGATED_REPORTS_LOAD_BRAND_BRICK_REPORT_FAILURE = 'AGGREGATED_REPORTS_LOAD_BRAND_BRICK_REPORT_FAILURE';
export type AGGREGATED_REPORTS_LOAD_BRAND_BRICK_REPORT_FAILURE = typeof AGGREGATED_REPORTS_LOAD_BRAND_BRICK_REPORT_FAILURE;

export const AGGREGATED_REPORTS_LOAD_PRODUCT_REPORT_REQUEST = 'AGGREGATED_REPORTS_LOAD_PRODUCT_REPORT_REQUEST';
export type AGGREGATED_REPORTS_LOAD_PRODUCT_REPORT_REQUEST = typeof AGGREGATED_REPORTS_LOAD_PRODUCT_REPORT_REQUEST;

export const AGGREGATED_REPORTS_LOAD_PRODUCT_REPORT_SUCCESS = 'AGGREGATED_REPORTS_LOAD_PRODUCT_REPORT_SUCCESS';
export type AGGREGATED_REPORTS_LOAD_PRODUCT_REPORT_SUCCESS = typeof AGGREGATED_REPORTS_LOAD_PRODUCT_REPORT_SUCCESS;

export const AGGREGATED_REPORTS_LOAD_PRODUCT_REPORT_FAILURE = 'AGGREGATED_REPORTS_LOAD_PRODUCT_REPORT_FAILURE';
export type AGGREGATED_REPORTS_LOAD_PRODUCT_REPORT_FAILURE = typeof AGGREGATED_REPORTS_LOAD_PRODUCT_REPORT_FAILURE;

export interface LoadAvailableAggregatedReportsRequestActionType {
  type: AGGREGATED_REPORTS_LOAD_AVAILABLE_REPORTS_REQUEST;
}
export interface LoadAvailableAggregatedReportsSuccessActionType {
  type: AGGREGATED_REPORTS_LOAD_AVAILABLE_REPORTS_SUCCESS;
  data: AvailableAggregatedReports;
}
export interface LoadAvailableAggregatedReportsFailureActionType {
  type: AGGREGATED_REPORTS_LOAD_AVAILABLE_REPORTS_FAILURE;
  error: ErrorDetails;
}

export interface LoadAggregatedProductReportRequestActionType {
  type: AGGREGATED_REPORTS_LOAD_PRODUCT_REPORT_REQUEST;
  gtin: number;
  metrics: AggregatedMetrics[];
}
export interface LoadAggregatedProductReportSuccessActionType {
  type: AGGREGATED_REPORTS_LOAD_PRODUCT_REPORT_SUCCESS;
  data: AggregatedOnePagerReport;
}
export interface LoadAggregatedProductReportFailureActionType {
  type: AGGREGATED_REPORTS_LOAD_PRODUCT_REPORT_FAILURE;
  error: ErrorDetails;
}

export interface LoadAggregatedBrandReportRequestActionType {
  type: AGGREGATED_REPORTS_LOAD_BRAND_REPORT_REQUEST;
  brandId: number;
  metrics: AggregatedMetrics[];
}
export interface LoadAggregatedBrandReportSuccessActionType {
  type: AGGREGATED_REPORTS_LOAD_BRAND_REPORT_SUCCESS;
  data: AggregatedOnePagerReport;
}
export interface LoadAggregatedBrandReportFailureActionType {
  type: AGGREGATED_REPORTS_LOAD_BRAND_REPORT_FAILURE;
  error: ErrorDetails;
}

export interface LoadAggregatedBrickReportRequestActionType {
  type: AGGREGATED_REPORTS_LOAD_BRICK_REPORT_REQUEST;
  gpcBrickCode: number;
  metrics: AggregatedMetrics[];
}
export interface LoadAggregatedBrickReportSuccessActionType {
  type: AGGREGATED_REPORTS_LOAD_BRICK_REPORT_SUCCESS;
  data: AggregatedOnePagerReport;
}
export interface LoadAggregatedBrickReportFailureActionType {
  type: AGGREGATED_REPORTS_LOAD_BRICK_REPORT_FAILURE;
  error: ErrorDetails;
}

export interface LoadAggregatedBrandBrickReportRequestActionType {
  type: AGGREGATED_REPORTS_LOAD_BRAND_BRICK_REPORT_REQUEST;
  brandId: number;
  gpcBrickCode: number;
  metrics: AggregatedMetrics[];
}
export interface LoadAggregatedBrandBrickReportSuccessActionType {
  type: AGGREGATED_REPORTS_LOAD_BRAND_BRICK_REPORT_SUCCESS;
  data: AggregatedOnePagerReport;
}
export interface LoadAggregatedBrandBrickReportFailureActionType {
  type: AGGREGATED_REPORTS_LOAD_BRAND_BRICK_REPORT_FAILURE;
  error: ErrorDetails;
}
