import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { ErrorOutline, InfoOutlined } from '@material-ui/icons';
import React from 'react';
import { Alert } from 'reactstrap';
import { ClientBenchmarkReportLoader } from './ClientBenchmarkReportLoader';
import { ClientBenchmarkReportPage } from './ClientBenchmarkReportPage';
const ClientBenchmarkReportIndex: React.FC = () => {
  return (
    <ClientBenchmarkReportLoader>
      {({ isLoaded, isLoading, loadError, isDataEmpty, data }): React.ReactNode => {
        const showClientBenchmarkReport = !isDataEmpty && !isLoading && !loadError && isLoaded;
        return (
          <>
            {isLoading && (
              <Grid direction="column" alignItems="center" container justify="center">
                <CircularProgress /> &nbsp;&nbsp;
                <Typography variant="overline">Loading Client Benchmark Report...</Typography>
              </Grid>
            )}
            {loadError && (
              <Alert className="top-buffer" color="danger">
                <Typography variant="caption" color="textSecondary">
                  <ErrorOutline color="secondary" fontSize="small" />
                  &nbsp;Failed to load Client Benchmark Report. {loadError}
                </Typography>
              </Alert>
            )}
            {!loadError && !isLoading && isLoaded && isDataEmpty && (
              <Alert className="top-buffer" color="warning">
                <Typography variant="caption" color="textPrimary">
                  <InfoOutlined color="primary" fontSize="small" />
                  &nbsp; Client Benchmark Report was not found.
                </Typography>
              </Alert>
            )}
            {showClientBenchmarkReport && data && <ClientBenchmarkReportPage clientBenchmarkReport={data} />}
          </>
        );
      }}
    </ClientBenchmarkReportLoader>
  );
};

export { ClientBenchmarkReportIndex };
