import CompetitorShelfDataset from 'modules/admin/planograms/types/CompetitorShelfDataset';
import { Action } from 'types/Action';
import ErrorDetails from '../../infrastructure/exception/ErrorDetails';
import { ClientDatasetDto } from '../admin/clientDataset/types/ClientDatasetDto';
import { closeAside, OnErrorRequestAction, openAside } from '../shared/actions';
import { PlanogramCommand, PlanogramCommandManager } from './commands/_planogram_commands';
import * as constants from './constants/constants';
import PlanogramModel from './domain/PlanogramModel';
import ShelfLocation from './domain/ShelfLocation';
import PlanogramEventBase from './events/PlanogramEventBase';
import {
  OnImageLoadingAction,
  OnPlanogramSvgPanZoomValueUpdateRequestAction,
  OnImageLoadedAction,
  UpdatePlanogramViewPortAction,
  OnNewProductImageUploadRequestAction,
  OnNewProductImageUploadSuccessAction,
  OnNewProductImageUploadErrorAction,
  OnZoomLevelAdjustmentRequestAction,
  OnCommandExecutionRequestAction,
  OnSelectShelfLocationsRequest,
  ActionWithId,
  OnFlowDataLoadedAction,
} from './types/ActionTypes';
import CreateTestPlanogramRequestDto, { CreateTestPlanogramResponseDto } from './types/CreateTestPlanogramRequestDto';
import { FlowDataReportResult } from './types/FlowDataReportResult';
import { NewProductModel } from './types/NewProductModel';
import { RecordChangeReasonDto } from './types/PlanogramChangeReasons';
import ProductModel from './types/ProductModel';
import Rectangle from './types/Rectangle';
import { FlowDataReportType } from './types/ReportingTypes';
import { SalesDataModel, SalesReportModel } from './types/SalesDataModel';
import TestInfoDto from './types/TestInfoDto';

export const forceUpdate = (): Action => ({
  type: constants.FORCE_UPDATE,
});

export const planogramPageReset = (): Action => ({
  type: constants.PLANOGRAM_PAGE_RESET,
});

export const onImageLoading = (imageUrl: string): OnImageLoadingAction => ({
  type: constants.PLANOGRAM_IMAGE_LOADING,
  imageUrl,
});

export const onImageLoaded = (imageWidth: number, imageHeight: number): OnImageLoadedAction => ({
  type: constants.PLANOGRAM_IMAGE_LOADED,
  imageWidth,
  imageHeight,
});

export const onImageLoadingError = (): Action => ({
  type: constants.PLANOGRAM_IMAGE_LOAD_ERROR,
});

export const loadPlanogram = (id: string) => ({
  type: constants.PLANOGRAM_LOADING,
  id,
});

export const savingChangesRequest = (saving: boolean) => ({
  type: constants.SAVING_PLANOGRAM_CHANGES,
  saving,
});

// Load Planogram
export const planogramLoaded = (initialPlanogram: PlanogramModel, currentPlanogram: PlanogramModel, products: ProductModel[], salesData: SalesReportModel[], clientDatasets: ClientDatasetDto[]) => ({
  type: constants.PLANOGRAM_LOADED,
  initialPlanogram,
  planogram: currentPlanogram,
  products,
  salesData,
  clientDatasets,
});

export const updatePlanogramViewPort = (width: number, height: number): UpdatePlanogramViewPortAction => ({
  type: constants.UPDATE_PLANOGRAM_VIEW_PORT,
  width,
  height,
});

export const errorLoadingPlanogram = (errorDetails: ErrorDetails): OnErrorRequestAction => ({
  type: constants.ERROR_LOADING_PLANOGRAM,
  errorDetails,
});

// Load Public Planogram
export const loadPublicPlanogram = (id: string, sharingToken: string) => ({
  type: constants.PUBLIC_PLANOGRAM_LOADING,
  id,
  sharingToken,
});

export const publicPlanogramLoaded = (initialPlanogram: PlanogramModel, currentPlanogram: PlanogramModel, products: ProductModel[], flowDataReport: FlowDataReportResult) => ({
  type: constants.PLANOGRAM_LOADED,
  initialPlanogram,
  planogram: currentPlanogram,
  products,
  flowDataReport,
});

export const errorLoadingPublicPlanogram = (error: ErrorDetails): OnErrorRequestAction => ({
  type: constants.ERROR_LOADING_PUBLIC_PLANOGRAM,
  errorDetails: error,
});

// Load Test Planogram
export const loadTestPlanogramRequest = (id: string, testPlanogramId: string) => ({
  type: constants.TEST_PLANOGRAM_LOADING,
  id,
  testPlanogramId,
});

export const testPlanogramLoaded = (initialPlanogram: PlanogramModel, testPlanogram: PlanogramModel, testInfo: TestInfoDto, products: ProductModel[], flowDataReport: FlowDataReportResult) => ({
  type: constants.TEST_PLANOGRAM_LOADED,
  initialPlanogram,
  planogram: testPlanogram,
  testInfo,
  products,
  flowDataReport,
});

export const errorLoadingTestPlanogram = (errorDetails: ErrorDetails): OnErrorRequestAction => ({
  type: constants.ERROR_LOADING_TEST_PLANOGRAM,
  errorDetails: errorDetails,
});

// Load Competitor Planogram
export const loadCompetitorPlanogramRequest = (id: string, shelfReferenceId: string) => ({
  type: constants.LOAD_COMPETITOR_PLANOGRAM_REQUEST,
  id,
  shelfReferenceId,
});

export const loadCompetitorPlanogramSuccess = (
  initialPlanogram: PlanogramModel,
  competitorPlanogram: PlanogramModel,
  products: ProductModel[],
  flowDataReport: FlowDataReportResult,
  competitorShelfDataset: CompetitorShelfDataset
) => ({
  type: constants.LOAD_COMPETITOR_PLANOGRAM_SUCCESS,
  initialPlanogram,
  planogram: competitorPlanogram,
  products,
  // TODO REMOVE
  shelfLocationData: flowDataReport,
  competitorShelfDataset,
});

export const loadCompetitorPlanogramFailure = (errorDetails: ErrorDetails): OnErrorRequestAction => ({
  type: constants.LOAD_COMPETITOR_PLANOGRAM_FAILURE,
  errorDetails: errorDetails,
});

export const onPlanogramEventRequest = (event: PlanogramEventBase) => ({
  type: constants.ON_PLANOGRAM_EVENT_REQUEST,
  event,
});

export const changePlanogramName = (planogramId: string, newName: string) => ({
  type: constants.CHANGE_PLANOGRAM_NAME,
  planogramId,
  newName,
});

export const changePlanogramNameSuccess = (newName: string) => ({
  type: constants.CHANGE_PLANOGRAM_NAME_SUCCESS,
  newName,
});

export const changePlanogramNameError = (errorDetails: ErrorDetails): OnErrorRequestAction => ({
  type: constants.CHANGE_PLANOGRAM_NAME_ERROR,
  errorDetails: errorDetails,
});

export const deletePlanogramRequest = (planogramId: string) => ({
  type: constants.DELETE_PLANOGRAM_REQUEST,
  planogramId,
});

export const deletePlanogramSuccess = (): Action => ({
  type: constants.DELETE_PLANOGRAM_SUCCESS,
});

export const deletePlanogramFailed = (errorDetails: ErrorDetails): OnErrorRequestAction => ({
  type: constants.DELETE_PLANOGRAM_FAILED,
  errorDetails: errorDetails,
});

export const toggleEnableShowProductInfo = (enabled?: boolean) => ({
  type: constants.TOGGLE_ENABLE_SHOW_PRODUCT_INFO,
  enabled,
});

export const toggleEnableSalesDataReport = (enabled?: boolean) => ({
  type: constants.TOGGLE_ENABLE_SALES_DATA_REPORT,
  enabled,
});

export const toggleEnableFlowDataReport = (enabled?: boolean) => ({
  type: constants.TOGGLE_ENABLE_FLOW_DATA_REPORT,
  enabled,
});

export const toggleEnableClientDatasetReport = (enabled?: boolean) => ({
  type: constants.TOGGLE_ENABLE_CLIENT_DATASET_REPORT,
  enabled,
});

export const toggleEnableCompetitorShelfDataReport = (enabled?: boolean) => ({
  type: constants.TOGGLE_ENABLE_COMPETITOR_SHELF_DATA_REPORT,
  enabled,
});

export const saveSalesDataRequest = (planogramId: string, reportName: string, salesData: SalesDataModel[]) => ({
  type: constants.SAVE_SALES_DATA_REQUEST,
  planogramId,
  reportName,
  salesData,
});

export const saveSalesDataSuccess = (reportId: string, reportName: string, salesData: SalesDataModel[]) => {
  const salesReport = new SalesReportModel();
  salesReport.items = salesData;
  salesReport.reportId = reportId;
  salesReport.reportName = reportName;
  return {
    type: constants.SAVE_SALES_DATA_SUCCESS,
    salesReport,
  };
};

export const saveSalesDataFailed = (errorDetails: ErrorDetails): OnErrorRequestAction => ({
  type: constants.SAVE_SALES_DATA_FAILED,
  errorDetails: errorDetails,
});

export const deleteSalesDataRequest = (planogramId: string, reportId: string) => ({
  type: constants.DELETE_SALES_DATA_REQUEST,
  planogramId,
  reportId,
});

export const deleteSalesDataSuccess = (planogramId: string, reportId: string) => {
  return {
    type: constants.DELETE_SALES_DATA_SUCCESS,
    reportId,
  };
};

export const deleteSalesDataFailed = (errorDetails: ErrorDetails): OnErrorRequestAction => ({
  type: constants.DELETE_SALES_DATA_FAILED,
  errorDetails: errorDetails,
});

export const newProductRequest = (): Action => ({
  type: constants.NEW_PRODUCT_REQUEST,
});

export const newProductRequestStop = (): Action => ({
  type: constants.NEW_PRODUCT_STOP,
});

export const uploadShelfLocationImageRequest = (planogram: PlanogramModel, imageBase64: string, commandManager: PlanogramCommandManager, shelfLocation: ShelfLocation) => ({
  type: constants.UPLOAD_SHELFLOCATION_IMAGE_REQUEST,
  planogram,
  imageBase64,
  commandManager,
  shelfLocation,
});

export const uploadShelfLocationImageFailed = (errorDetails: ErrorDetails): OnErrorRequestAction => ({
  type: constants.UPLOAD_SHELFLOCATION_IMAGE_ERROR,
  errorDetails: errorDetails,
});

export const uploadShelfLocationImageSuccess = (): Action => ({
  type: constants.UPLOAD_SHELFLOCATION_IMAGE_SUCCESS,
});

export const fixImageSizeRequest = (planogramId: string, shelfLocation: ShelfLocation) => ({
  type: constants.FIX_IMAGE_SIZE_REQUEST,
  planogramId,
  shelfLocation,
  occurredOn: new Date(),
  width: parseInt(shelfLocation.geometry.currentWidth as any, 10),
  height: parseInt(shelfLocation.geometry.currentHeight as any, 10),
});

export const fixImageSizeSuccess = (planogramId: string, shelfLocation: ShelfLocation) => {
  return {
    type: constants.FIX_IMAGE_SIZE_SUCCESS,
    planogramId,
    shelfLocation,
  };
};

export const fixImageSizeError = (planogramId: string, shelfLocation: ShelfLocation, errorDetails: ErrorDetails) => ({
  type: constants.FIX_IMAGE_SIZE_ERROR,
  planogramId,
  shelfLocation,
  errorDetails: errorDetails,
});

export const setShelfLocationGtinRequest = (planogramId: string, shelfLocation: ShelfLocation, gtin: number, newProduct?: NewProductModel) => {
  return {
    type: constants.SET_SHELFLOCATION_GTIN_REQUEST,
    planogramId,
    shelfLocation,
    gtin,
    newProduct,
    occurredOn: new Date(),
  };
};

export const setShelfLocationGtinSuccess = (shelfLocation: ShelfLocation, gtin: number, newProduct?: NewProductModel) => {
  return {
    type: constants.SET_SHELFLOCATION_GTIN_SUCCESS,
    shelfLocation,
    gtin,
    newProduct,
  };
};

export const setShelfLocationGtinError = (errorDetails: ErrorDetails): OnErrorRequestAction => ({
  type: constants.SET_SHELFLOCATION_GTIN_ERROR,
  errorDetails: errorDetails,
});

export const setFlowReportTypeRequest = (reportType?: FlowDataReportType) => ({
  type: constants.SET_FLOW_REPORT_TYPE,
  reportType,
});

export const selectSalesReportRequest = (salesReport?: SalesReportModel) => ({
  type: constants.SET_SALES_REPORT_TYPE,
  salesReport,
});

export const selectClientDatasetReportRequest = (clientDataset?: ClientDatasetDto) => ({
  type: constants.SET_CLIENT_DATASET_REPORT_TYPE,
  clientDataset,
});

export const updateCommentsRequest = (planogramId: string, comments: string) => ({
  type: constants.UPDATE_PLANOGRAM_COMMENTS_REQUEST,
  planogramId,
  comments,
});

export const updateCommentsSuccess = (planogramId: string, comments: string) => ({
  type: constants.UPDATE_PLANOGRAM_COMMENTS_SUCCESS,
  planogramId,
  comments,
});

export const updateCommentsError = (planogramId: string, comments: string, errorDetails: ErrorDetails) => ({
  type: constants.UPDATE_PLANOGRAM_COMMENTS_ERROR,
  planogramId,
  comments,
  errorDetails: errorDetails,
});

export const shareModalToggle = (): Action => ({
  type: constants.PLANOGRAM_SHARE_MODAL_TOGGLE,
});

export const publicSharingToggleRequest = (planogramId: string, enabled: boolean) => ({
  type: constants.PLANOGRAM_PUBLIC_SHARE_TOGGLE_REQUEST,
  planogramId,
  enabled,
});

export const publicSharingToggleSuccess = (planogramId: string, enabled: boolean, newToken: string) => ({
  type: constants.PLANOGRAM_PUBLIC_SHARE_TOGGLE_SUCCESS,
  planogramId,
  enabled,
  newToken,
});

export const publicSharingToggleError = (planogramId: string) => ({
  type: constants.PLANOGRAM_PUBLIC_SHARE_TOGGLE_FAILED,
  planogramId,
});

export const openTestWithFlowModal = (): Action => ({
  type: constants.SHOW_TEST_WITH_FLOW_MODAL,
});

export const hideTestWithFlowModal = (): Action => ({
  type: constants.HIDE_TEST_WITH_FLOW_MODAL,
});

export const openTestInfoModal = (): Action => ({
  type: constants.SHOW_TEST_DETAILS_MODAL,
});

export const hideTestInfoModal = (): Action => ({
  type: constants.HIDE_TEST_DETAILS_MODAL,
});

export const openEditTestModal = (testToEdit: TestInfoDto) => ({
  type: constants.SHOW_EDIT_TEST_MODAL,
  testToEdit,
});

export const hideEditTestModal = (): Action => ({
  type: constants.HIDE_EDIT_TEST_MODAL,
});

export const openDeleteTestConfirmationModal = (testToDelete: TestInfoDto) => ({
  type: constants.SHOW_DELETE_TEST_CONFIRMATION_MODAL,
  testToDelete,
});

export const hideDeleteTestConfirmationModal = (): Action => ({
  type: constants.HIDE_DELETE_TEST_CONFIRMATION_MODAL,
});

export const updateTestInfoRequest = (testInfo: TestInfoDto) => ({
  type: constants.UPDATE_TEST_INFO_REQUEST,
  testInfo,
});

export const deleteTestRequest = (planogramId: string, testIdToDelete: string) => ({
  type: constants.DELETE_TEST_REQUEST,
  planogramId,
  testIdToDelete,
});

export const deleteTestSuccess = (testIdDeleted: string) => ({
  type: constants.DELETE_TEST_SUCCESS,
  testIdDeleted,
});

export const deleteTestFailure = (errorDetails: ErrorDetails): OnErrorRequestAction => ({
  type: constants.DELETE_TEST_FAILURE,
  errorDetails,
});

export const updateTestInfoSuccess = (testInfo: TestInfoDto) => ({
  type: constants.UPDATE_TEST_INFO_SUCCESS,
  testInfo,
});

export const updateTestInfoFailure = (errorDetails: ErrorDetails): OnErrorRequestAction => ({
  type: constants.UPDATE_TEST_INFO_FAILURE,
  errorDetails,
});

export const createTestPlanogramRequest = (createTestPlanogramDto: CreateTestPlanogramRequestDto) => ({
  type: constants.CREATE_TEST_PLANOGRAM_REQUEST,
  createTestPlanogramDto,
});

export const createTestPlanogramSuccess = (createTestResponseDto: CreateTestPlanogramResponseDto, createdTestDto: CreateTestPlanogramRequestDto) => ({
  type: constants.CREATE_TEST_PLANOGRAM_SUCCESS,
  createTestResponseDto,
  createdTestDto,
});

export const createTestPlanogramFailure = (errorDetails: ErrorDetails): OnErrorRequestAction => ({
  type: constants.CREATE_TEST_PLANOGRAM_FAILURE,
  errorDetails: errorDetails,
});

export const loadTestsInfoRequest = (planogramId: string) => ({
  type: constants.LOAD_TESTS_INFO_REQUEST,
  planogramId,
});

export const loadTestsInfoSuccess = (testsInfoList: TestInfoDto[]) => ({
  type: constants.LOAD_TESTS_INFO_SUCCESS,
  testsInfoList,
});

export const loadTestsInfoFailure = (errorDetails: ErrorDetails): OnErrorRequestAction => ({
  type: constants.LOAD_TESTS_INFO_FAILURE,
  errorDetails: errorDetails,
});

export const toggleShowInvalidShelfLocations = (): Action => ({
  type: constants.TOGGLE_SHOW_INVALID_SHELF_LOCATIONS,
});

export const toggleShowInitialPlanogram = (): Action => ({
  type: constants.TOGGLE_SHOW_INITIAL_PLANOGRAM,
});

export const undoDelistedProduct = (gtin: number) => ({
  type: constants.UNDO_DELISTED_PRODUCT,
  gtin,
});

export const onHighlightBrand = (priority: boolean, brandId?: number) => ({
  type: constants.ON_HIGHLIGHT_BRAND,
  brandId,
  priority,
});

export const onHighlightProduct = (priority: boolean, gtin?: number[], highLightedBy = '') => ({
  type: constants.ON_HIGHLIGHT_PRODUCT,
  gtin,
  priority,
  highLightedBy,
});

export const onRecordChangeReason = (reason: RecordChangeReasonDto) => ({
  type: constants.RECORD_CHANGE_REASON_REQUEST,
  reason,
});

export const onRecordChangeReasonSuccess = (reason: RecordChangeReasonDto) => ({
  type: constants.RECORD_CHANGE_REASON_SUCCESS,
  reason,
});

export const resetPlanogramRequest = (planogramId: string) => ({
  type: constants.RESET_PLANOGRAM_REQUEST,
  planogramId,
});

export const resetPlanogramSuccess = (): Action => ({
  type: constants.RESET_PLANOGRAM_SUCCESS,
});

export const resetPlanogramFailure = (error: ErrorDetails): OnErrorRequestAction => ({
  type: constants.RESET_PLANOGRAM_FAILURE,
  errorDetails: error,
});

export const togglePlanogramAsideActiveTab = (activeTab: number) => ({
  type: constants.TOGGLE_PLANOGRAM_ASIDE_ACTIVE_TAB,
  activeTab,
});
export const setIsInUnstableState = (): Action => ({
  type: constants.PLANOGRAM_IN_UNSTABLE_STATE,
});

export const toggleEnableGpcBrickFilter = (): Action => ({
  type: constants.TOGGLE_ENABLED_GPC_BRICK_FILTER,
});

export const toggleGpcBrickCodeFilter = (gpcBrickCode: number) => ({
  type: constants.TOGGLE_GPC_BRICK_CODE_FILTER,
  gpcBrickCode,
});

export const toggleAside = (): Action => ({
  type: constants.LAYOUT_TOGGLE_ASIDE,
});

export const runProductTour = (): Action => ({
  type: constants.RUN_PRODUCT_TOUR,
});

export const updateProductStoreStepIndex = (productStoreStepIndex: number) => ({
  type: constants.UPDATE_PRODUCT_STORE_STEP_INDEX,
  productStoreStepIndex,
});

export const stopProductTour = (): Action => ({
  type: constants.STOP_PRODUCT_TOUR,
});

export const updateHasFinishedProductTourRequest = (): Action => ({
  type: constants.HAS_FINISHED_PRODUCT_TOUR_REQUEST,
});

export const updateHasFinishedProductTourSuccess = (): Action => ({
  type: constants.HAS_FINISHED_PRODUCT_TOUR_SUCCESS,
});

export const toggleShowCompetitorModal = (): Action => ({
  type: constants.TOGGLE_SHOW_COMPETITOR_MODAL,
});

export const loadFlowData = (id: string): ActionWithId => ({
  type: constants.FLOW_DATA_LOADING,
  id: id,
});

export const onFlowDataLoaded = (data: FlowDataReportResult): OnFlowDataLoadedAction => ({
  type: constants.FLOW_DATA_LOADED,
  data: data,
});

export const onFlowDataLoadFailed = (error: ErrorDetails): OnErrorRequestAction => ({
  type: constants.FLOW_DATA_LOAD_FAILED,
  errorDetails: error,
});

export const onErrorRequestAction = (type: string, errorDetails: ErrorDetails): OnErrorRequestAction => ({
  type: type,
  errorDetails: errorDetails,
});

export const onNewProductImageUploadRequest = (base64Data: string, crop: Rectangle, planogramId: string): OnNewProductImageUploadRequestAction => ({
  type: constants.ON_NEW_PRODUCT_IMAGE_UPLOAD_REQUEST,
  base64Data,
  crop,
  planogramId,
});

export const onNewProductImageUploadSuccess = (imageUrl: string): OnNewProductImageUploadSuccessAction => ({
  type: constants.ON_NEW_PRODUCT_IMAGE_UPLOAD_SUCCESS,
  imageUrl,
});

export const onNewProductImageUploadError = (errorDetails: ErrorDetails): OnNewProductImageUploadErrorAction => ({
  type: constants.ON_NEW_PRODUCT_IMAGE_UPLOAD_ERROR,
  errorDetails,
});

/**
 *
 * @param zoomLevelControlNumber +1 to increase zoom, -1 to decrease, 0 to reset zoom
 */
export const onZoomLevelAdjustmentRequest = (zoomLevelControlNumber: number): OnZoomLevelAdjustmentRequestAction => ({
  type: constants.ON_ZOOM_LEVEL_ADJUSTMENT,
  zoomLevelControlNumber,
});

export const onCommandExecutionRequest = (command: PlanogramCommand): OnCommandExecutionRequestAction => ({
  type: constants.ON_COMMAND_EXECUTION_REQUEST,
  command,
});

export const onSelectShelfLocationsRequest = (shelfLocations: ShelfLocation[], append: boolean): OnSelectShelfLocationsRequest => ({
  type: constants.ON_SELECT_SHELF_LOCATION_REQUEST,
  shelfLocations,
  append,
});

export const onStartCopyingShelfLocationsRequest = (): Action => ({
  type: constants.ON_START_COPYING_SHELF_LOCATIONS_REQUEST,
});

export const onCancelCopyingShelfLocationsRequest = (): Action => ({
  type: constants.ON_CANCEL_COPYING_SHELF_LOCATIONS_REQUEST,
});

export const onCancelAllPlanogramUiActionsRequest = (): Action => ({
  type: constants.ON_CANCEL_ALL_PLANOGRAM_UI_ACTIONS_REQUEST,
});

export const onPlanogramActionUndoRequest = (): Action => ({
  type: constants.ON_PLANOGRAM_ACTION_UNDO_REQUEST,
});

export const onPlanogramContextMenuOpenRequest = (shouldOpen: boolean, shelfLocation?: ShelfLocation, element?: Element): Action => ({
  type: constants.ON_PLANOGRAM_CONTEXT_MENU_OPEN_REQUEST,
  shouldOpen,
  element,
  shelfLocation,
});

export const onPlanogramSvgPanZoomValueUpdateReuqest = (value: any): OnPlanogramSvgPanZoomValueUpdateRequestAction => ({
  type: constants.ON_PLANOGRAM_SVGPANZOOM_VALUE_UPDATE_REQUEST,
  value,
});

export { openAside, closeAside };
