import React from 'react';
import { useSelector } from 'react-redux';

import { getIsEnabledInspirationImages } from '../../../selectors';
import Dialog from './Dialog';

interface Props {
  planogramId: string;
}

const InspirationImages: React.FC<Props> = ({ planogramId }: Props): JSX.Element | null => {
  const isEnabled = useSelector(getIsEnabledInspirationImages);
  return isEnabled ? <Dialog planogramId={planogramId} /> : null;
};

export default InspirationImages;
