import { setError, setIdle, setLoading, setSuccess, setData } from 'infrastructure/utils/RemoteObjectStatus';
import { OnErrorRequestAction } from 'modules/shared/actions';
import createReducer from 'utils/createReducer';
import ErrorDetails from '../../infrastructure/exception/ErrorDetails';
import * as constants from './constants';
import PlanogramsState from './state';
import BuilderPlanogramView from './types/BuilderPlanogramView';
import ShelfViewModel from './types/ShelfViewModel';

const initialState: PlanogramsState = {
  // TODO: clean up and make consistent naming conventions
  loadPlanogramsStatus: setIdle(),
  loadShelvesStatus: setIdle(),
  loadCreatePlanogramFromShelfStatus: setIdle(),
  loadBrandsStatus: setIdle(),
  loadBuilderPlanogramsStatus: setIdle(),
  loadUpdatingBuilderRulesStatus: setIdle(),
  loadDeletingPlanogramStatus: setIdle(),
  addNewModal: false,
  selectedBuilderPlanogram: {} as BuilderPlanogramView,
  showBuildersForPlanogram: undefined,
};

function planogramsLoading(state: PlanogramsState): PlanogramsState {
  return {
    ...state,
    loadPlanogramsStatus: setLoading('Loading planograms...'),
  };
}

function planogramsLoaded(state: PlanogramsState, action: any): PlanogramsState {
  return {
    ...state,
    loadPlanogramsStatus: setSuccess(action.planograms),
  };
}

function errorLoadingPlanograms(state: PlanogramsState, action: OnErrorRequestAction): PlanogramsState {
  return {
    ...state,
    loadPlanogramsStatus: setError(action.errorDetails),
  };
}

function showNewPlanogram(state: PlanogramsState): PlanogramsState {
  return {
    ...state,
    addNewModal: true,
  };
}

function hideNewPlanogram(state: PlanogramsState): PlanogramsState {
  return {
    ...state,
    addNewModal: false,
  };
}

function shelvesLoading(state: PlanogramsState): PlanogramsState {
  return {
    ...state,
    loadShelvesStatus: setLoading('Loading shelves...'),
  };
}

function shelvesLoaded(state: PlanogramsState, action: any): PlanogramsState {
  return {
    ...state,
    loadShelvesStatus: setSuccess(action.shelves),
  };
}

function errorLoadingShelves(state: PlanogramsState, action: OnErrorRequestAction): PlanogramsState {
  return {
    ...state,
    loadShelvesStatus: setError(action.errorDetails),
  };
}

function brandsLoading(state: PlanogramsState): PlanogramsState {
  return {
    ...state,
    loadBrandsStatus: setLoading(),
  };
}

function brandsLoaded(state: PlanogramsState, action: any): PlanogramsState {
  return {
    ...state,
    loadBrandsStatus: setSuccess(action.brands),
  };
}

function errorLoadingBrands(state: PlanogramsState, action: OnErrorRequestAction): PlanogramsState {
  return {
    ...state,
    loadBrandsStatus: setError(action.errorDetails),
  };
}

function createPlanogramFromShelf(state: PlanogramsState): PlanogramsState {
  return {
    ...state,
    loadCreatePlanogramFromShelfStatus: setLoading(),
    // todo: remove it
    // creatingPlanogramFromShelf: action.shelf,
  };
}

function errorCreatingPlanogramFromShelf(state: PlanogramsState, action: OnErrorRequestAction): PlanogramsState {
  return {
    ...state,
    loadCreatePlanogramFromShelfStatus: setError(action.errorDetails),
    // todo: remove it
    //creatingPlanogramFromShelf: undefined,
  };
}

function successCreatingPlanogramFromShelf(state: PlanogramsState): PlanogramsState {
  return {
    ...state,
    loadCreatePlanogramFromShelfStatus: setSuccess({}),
    // todo: remove it
    // creatingPlanogramFromShelf: undefined,
    addNewModal: false,
    loadShelvesStatus: setData(state.loadShelvesStatus, [] as ShelfViewModel[]),
  };
}

function resetStateHandler(): PlanogramsState {
  return {
    ...initialState,
  };
}

function deletePlanogramRequest(state: PlanogramsState): PlanogramsState {
  return {
    ...state,
    loadDeletingPlanogramStatus: setLoading(),
  };
}

function deletePlanogramSuccess(state: PlanogramsState, action: any): PlanogramsState {
  const deletedPlanogramId = action.deletedPlanogramId;
  let planograms = state.loadPlanogramsStatus.data ?? [];
  planograms = planograms.filter(p => p.id !== deletedPlanogramId);
  return {
    ...state,
    loadPlanogramsStatus: setData(state.loadPlanogramsStatus, planograms),
    loadDeletingPlanogramStatus: setSuccess({}),
  };
}

function deletePlanogramFailed(state: PlanogramsState): PlanogramsState {
  return {
    ...state,
    loadDeletingPlanogramStatus: setError({} as ErrorDetails),
  };
}

function loadBuilderPlanogramsRequest(state: PlanogramsState): PlanogramsState {
  return {
    ...state,
    loadBuilderPlanogramsStatus: setLoading(),
  };
}

function loadBuilderPlanogramsSuccess(state: PlanogramsState, action: any): PlanogramsState {
  return {
    ...state,
    loadBuilderPlanogramsStatus: setSuccess(action.builderPlanograms),
  };
}

function loadBuilderPlanogramsFailed(state: PlanogramsState): PlanogramsState {
  return {
    ...state,
    loadBuilderPlanogramsStatus: setError({} as ErrorDetails),
  };
}

function updateBuilderRulesRequest(state: PlanogramsState): PlanogramsState {
  return {
    ...state,
    loadUpdatingBuilderRulesStatus: setLoading(),
  };
}

function updateBuilderRulesSuccess(state: PlanogramsState, action: any): PlanogramsState {
  const selectedBuilderPlanogram = action.selectedBuilderPlanogram;
  // update builderPlanograms list with the updated builderPlanogram object (with new rules edited by the user)
  const builderPlanograms = state.loadBuilderPlanogramsStatus.data?.map(bp => {
    if (bp.id === selectedBuilderPlanogram.id) {
      bp = selectedBuilderPlanogram;
    }
    return bp;
  });

  return {
    ...state,
    selectedBuilderPlanogram,
    loadBuilderPlanogramsStatus: setData(state.loadBuilderPlanogramsStatus, builderPlanograms ?? []),
    loadUpdatingBuilderRulesStatus: setSuccess({}),
  };
}

function updateBuilderRulesFailed(state: PlanogramsState, action: OnErrorRequestAction): PlanogramsState {
  return {
    ...state,
    loadUpdatingBuilderRulesStatus: setError(action.errorDetails),
  };
}

export function resetLoadUpdatingBuilderRulesStatus(state: PlanogramsState) {
  return {
    ...state,
    loadUpdatingBuilderRulesStatus: setIdle(),
  };
}

function updateSelectedBuilderPlanogram(state: PlanogramsState, action: any): PlanogramsState {
  const selectedBuilderPlanogram = action.selectedBuilderPlanogram;
  return {
    ...state,
    selectedBuilderPlanogram,
  };
}

function setShowBuildersForplanogramHandler(state: PlanogramsState, action: any): PlanogramsState {
  return {
    ...state,
    showBuildersForPlanogram: action.payload,
  };
}

function setShowTestsForPlanogramHandler(state: PlanogramsState, action: any): PlanogramsState {
  return {
    ...state,
    showTestsForPlanogram: action.payload,
  };
}

function setShowDeleteForPlanogramHandler(state: PlanogramsState, action: any): PlanogramsState {
  return {
    ...state,
    showDeleteForPlanogram: action.payload,
  };
}

const handlers = {};
handlers[constants.ERROR_LOADING_PLANOGRAMS] = errorLoadingPlanograms;
handlers[constants.PLANOGRAMS_LOADED] = planogramsLoaded;
handlers[constants.PLANOGRAMS_LOADING] = planogramsLoading;

handlers[constants.SHELVES_LOADED] = shelvesLoaded;
handlers[constants.SHELVES_LOADING] = shelvesLoading;
handlers[constants.ERROR_LOADING_SHELVES] = errorLoadingShelves;

handlers[constants.BRANDS_LOADING] = brandsLoading;
handlers[constants.BRANDS_LOADED] = brandsLoaded;
handlers[constants.ERROR_LOADING_BRANDS] = errorLoadingBrands;

handlers[constants.SHOW_NEW_PLANOGRAM_MODAL] = showNewPlanogram;
handlers[constants.HIDE_NEW_PLANOGRAM_MODAL] = hideNewPlanogram;

handlers[constants.CREATE_PLANOGRAM_FROM_SHELF] = createPlanogramFromShelf;
handlers[constants.ERROR_CREATING_PLANOGRAM_FROM_SHELF] = errorCreatingPlanogramFromShelf;
handlers[constants.SUCCESS_CREATING_PLANOGRAM_FROM_SHELF] = successCreatingPlanogramFromShelf;

handlers[constants.DELETE_PLANOGRAM_FAILED] = deletePlanogramFailed;
handlers[constants.DELETE_PLANOGRAM_REQUEST] = deletePlanogramRequest;
handlers[constants.DELETE_PLANOGRAM_SUCCESS] = deletePlanogramSuccess;

handlers[constants.LOAD_BUILDER_PLANOGRAMS_REQUEST] = loadBuilderPlanogramsRequest;
handlers[constants.LOAD_BUILDER_PLANOGRAMS_SUCCESS] = loadBuilderPlanogramsSuccess;
handlers[constants.LOAD_BUILDER_PLANOGRAMS_FAILED] = loadBuilderPlanogramsFailed;

handlers[constants.UPDATE_BUILDER_RULES_REQUEST] = updateBuilderRulesRequest;
handlers[constants.UPDATE_BUILDER_RULES_SUCCESS] = updateBuilderRulesSuccess;
handlers[constants.UPDATE_BUILDER_RULES_FAILED] = updateBuilderRulesFailed;
handlers[constants.RESET_LOAD_UPDATING_BUILDER_RULES_STATUS] = resetLoadUpdatingBuilderRulesStatus;

handlers[constants.UPDATE_SELECTED_BUILDER_PLANOGRAM] = updateSelectedBuilderPlanogram;

handlers[constants.RESET_STATE] = resetStateHandler;
handlers[constants.PLANOGRAMS_PAGE_SET_SHOW_BUILDERS_FOR] = setShowBuildersForplanogramHandler;
handlers[constants.PLANOGRAMS_PAGE_SET_SHOW_TESTS_FOR] = setShowTestsForPlanogramHandler;
handlers[constants.PLANOGRAMS_PAGE_SET_SHOW_DELETE_FOR] = setShowDeleteForPlanogramHandler;

const planogramsReducer = createReducer(initialState, handlers);

export default planogramsReducer;
