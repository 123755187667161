import { plainToClass } from 'class-transformer';
import { push } from 'connected-react-router';
import { toastr } from 'react-redux-toastr';
import { all, put, takeLatest, call } from 'redux-saga/effects';
import apiClient from 'utils/apiClient';
import apiSaga from 'utils/apiSaga';
import ErrorDetails from '../../infrastructure/exception/ErrorDetails';
import * as actions from './actions';
import * as constants from './constants';
import BrandViewModel from './types/BrandViewModel';
import BuilderPlanogramView from './types/BuilderPlanogramView';
import { PlanogramDetailsModel } from './types/PlanogramDetailsModel';
import PlanogramView from './types/PlanogramView';
import ShelfViewModel from './types/ShelfViewModel';
import UserGroupModel from './types/UserGroupModel';

function getPlanograms() {
  return apiClient.get('/planograms');
}

function getShelves() {
  return apiClient.get('/shelves');
}

function getBrands(shelfReferenceId: string) {
  return apiClient.get(`/shelves/${shelfReferenceId}/brands/`);
}

function getBuilderPlanograms(masterPlanogramId: string) {
  return apiClient.get('/planograms/' + masterPlanogramId + '/builders/');
}

export function postCreatePlanogramFromShelf(shelfReferenceId: string, planogramDetails: PlanogramDetailsModel, userGroups: UserGroupModel[]) {
  return apiClient.post('/planograms/', {
    shelfReferenceId: shelfReferenceId,
    planogramDetails,
    userGroups,
  });
}

export function putBuilderRulesRequest(builderPlanogram: BuilderPlanogramView) {
  return apiClient.put('/planograms/' + builderPlanogram.id + '/action-rules', builderPlanogram);
}

function deletePlanogramRequest(id: string) {
  return apiClient({
    method: 'delete',
    url: `/planograms/${id}`,
    data: {
      planogramId: id,
    },
  });
}

export function* doLoad() {
  try {
    const response = yield apiSaga(getPlanograms);
    const data = plainToClass(PlanogramView, response.data as PlanogramView[]);
    yield put(actions.planogramsLoaded(data));
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    yield put(actions.errorLoadingPlanograms(errorDetails));
  }
}

export function* loadShelves() {
  try {
    const response = yield apiSaga(getShelves);
    const data = plainToClass(ShelfViewModel, response.data as ShelfViewModel[]);
    yield put(actions.shelvesLoaded(data));
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    yield put(actions.errorLoadingShelves(errorDetails));
  }
}

export function* loadBrands(action: ReturnType<actions.LoadBrands>) {
  try {
    const response = yield apiSaga(getBrands, action.payload);
    const data = plainToClass(BrandViewModel, response.data);
    yield put(actions.brandsLoaded(data));
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    console.log('Error loading brands', errorDetails);
    toastr.error('Error loading brand information for this shelf.', errorDetails.errorMessage);
    yield put(actions.errorLoadingBrands(errorDetails));
  }
}

export function* createPlanogramFromShelf(action: ReturnType<actions.CreatePlanogramFromShelf>) {
  try {
    yield call(toastr.info, 'Saving data', 'Please wait');
    const response = yield call(apiSaga, postCreatePlanogramFromShelf, action.payload.shelfReferenceId, action.payload.planogramDetails, action.payload.userGroups);
    yield call(toastr.success, 'Success', 'Redirecting to the new planograms');
    yield put(push(`/planograms/${response.data.id}`));
    yield put(actions.successCreatingPlanogramFromShelf());
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    yield call(toastr.error, 'Error creating planogram from shelf', errorDetails.errorMessage);
    yield put(actions.errorCreatingPlanogramFromShelf(errorDetails));
  }
}

export function* deletePlanogram(action: any) {
  try {
    yield apiSaga(deletePlanogramRequest, action.planogramId);
    toastr.success('Successfully deleted planogram', '');
    yield put(actions.deletePlanogramSuccess(action.planogramId));
    yield put(push(`/planograms`));
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    console.log('Error deleting planogram.', errorDetails);
    toastr.error('Error deleting planogram.', errorDetails.errorMessage);
    yield put(actions.deletePlanogramFailed());
  }
}

export function* loadBuilderPlanograms(action: any) {
  try {
    const response = yield apiSaga(getBuilderPlanograms, action.planogram.id);
    const planograms = plainToClass(BuilderPlanogramView, response.data.builderPlanograms);
    yield put(actions.loadBuilderPlanogramsSuccess(planograms));
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    toastr.error('Error loading builders.', errorDetails.errorMessage);
    yield put(actions.loadBuilderPlanogramsFailed());
  }
}
export function* updateBuilderRules(action: any) {
  try {
    yield call(toastr.info, 'Saving Changes', 'Please wait');
    yield call(apiSaga, putBuilderRulesRequest, action.selectedBuilderPlanogram);
    yield call(toastr.success, 'Success', 'The changes are saved');
    yield put(actions.updateBuilderRulesSuccess(action.selectedBuilderPlanogram));
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    yield call(toastr.error, `Error editing builder's rules.`, errorDetails.errorMessage);
    yield put(actions.updateBuilderRulesFailed(action.selectedBuilderPlanogram, errorDetails));
  }
}

export default function* root() {
  yield all([
    takeLatest(constants.PLANOGRAMS_LOADING, doLoad),
    takeLatest(constants.SHELVES_LOADING, loadShelves),
    takeLatest(constants.CREATE_PLANOGRAM_FROM_SHELF, createPlanogramFromShelf),
    takeLatest(constants.BRANDS_LOADING, loadBrands),
    takeLatest(constants.DELETE_PLANOGRAM_REQUEST, deletePlanogram),
    takeLatest(constants.LOAD_BUILDER_PLANOGRAMS_REQUEST, loadBuilderPlanograms),
    takeLatest(constants.UPDATE_BUILDER_RULES_REQUEST, updateBuilderRules),
  ]);
}
