import { call, delay, put } from 'redux-saga/effects';
import { notAuthenticated } from 'modules/auth/actions';
import BaseErrorDetails from '../infrastructure/exception/BaseErrorDetails';
import ErrorDetails from '../infrastructure/exception/ErrorDetails';
import { push } from 'connected-react-router';

function setCanRetry(errorDetails: ErrorDetails): void {
  errorDetails.canRetry = false;
  switch (errorDetails.statusCode) {
    case 504:
    case 500:
    case 502:
    case 408:
    case 409:
    case 429:
    case 503:
      errorDetails.canRetry = true;
      break;
  }
}

export default function* apiSaga(fn: any, ...rest: any) {
  try {
    return yield call(fn, ...rest);
  } catch (error) {
    if (error.response) {
      const status = error.response.status;
      const message = error.response.data.errorMessage || ' Something went wrong';
      const errorDetails: ErrorDetails = new BaseErrorDetails();
      errorDetails.errorMessage = message;
      errorDetails.statusCode = status;
      error.response.data = errorDetails;

      if (status === 401) {
        console.log('Unauthorized in apisaga');
        yield put(notAuthenticated());
      } else if (status === 451) {
        yield put(push('/eula'));
      }
    } else {
      const errorDetails: ErrorDetails = new BaseErrorDetails();
      errorDetails.errorMessage = 'Network Error';
      errorDetails.statusCode = 503;
      error.response = { data: errorDetails };
    }
    console.log(error.response);
    setCanRetry(error.response.data);
    throw error;
  }
}

export interface SagaRetryOptions {
  retries: number;
  delayMilliseconds: number;
}

const defaultRetryOptions = {
  delayMilliseconds: 0,
  retries: 0,
} as SagaRetryOptions;

export function* apiSagaWithRetry(fn: any, retryOptions: SagaRetryOptions = defaultRetryOptions, ...rest: any) {
  let currentRun = 1;
  while (true) {
    try {
      const response = yield apiSaga(fn, ...rest);
      return response;
    } catch (error) {
      const errorDetails = error.response.data as BaseErrorDetails;
      if (!errorDetails.canRetry) {
        throw error;
      }
      if (currentRun > retryOptions.retries) {
        throw error;
      }
      currentRun++;
      yield delay(retryOptions.delayMilliseconds);
    }
  }
}
