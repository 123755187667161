import { LoadState } from 'modules/shared/types';
import BaseErrorDetails from '../exception/BaseErrorDetails';
import ErrorDetails from '../exception/ErrorDetails';

export interface RemoteObjectStatusInterface<T = {}> {
  loadStatus: LoadState;
  loadingMessage?: string;
  data?: T;
  errorDetails?: ErrorDetails;
}

export const isLoading = (status: RemoteObjectStatusInterface): boolean => {
  return status.loadStatus === LoadState.LOADING;
};

export const isLoaded = (status: RemoteObjectStatusInterface): boolean => {
  return status.loadStatus === LoadState.LOADED;
};

export const isError = (status: RemoteObjectStatusInterface): boolean => {
  return status.loadStatus === LoadState.LOAD_FAILED;
};

export const isIdle = (status: RemoteObjectStatusInterface): boolean => {
  return status.loadStatus === LoadState.NOT_LOADED;
};

export const setLoading = <T>(message?: string): RemoteObjectStatusInterface<T> => {
  return {
    loadingMessage: message,
    loadStatus: LoadState.LOADING,
  } as RemoteObjectStatusInterface<T>;
};

export const setSuccess = <T>(data: T): RemoteObjectStatusInterface<T> => {
  return {
    data: data,
    loadStatus: LoadState.LOADED,
  } as RemoteObjectStatusInterface<T>;
};

export const setError = <T>(error: BaseErrorDetails): RemoteObjectStatusInterface<T> => {
  return {
    errorDetails: error,
    loadStatus: LoadState.LOAD_FAILED,
  } as RemoteObjectStatusInterface<T>;
};

export const setIdle = <T>(initial?: T): RemoteObjectStatusInterface<T> => {
  return {
    data: initial,
    loadStatus: LoadState.NOT_LOADED,
  } as RemoteObjectStatusInterface<T>;
};

export const setData = <T>(old: RemoteObjectStatusInterface<T>, data: T): RemoteObjectStatusInterface<T> => {
  return {
    ...old,
    data: data,
  };
};
