import * as authSelectors from 'modules/auth/selectors';
import { ConversationMessage } from 'modules/realogram/types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Message } from './Message';
// eslint-disable-next-line @typescript-eslint/interface-name-prefix
export interface IMessageListProps {
  messages: ConversationMessage[];
  isEditing: boolean;
  isDeleting: boolean;
  onSubmitEdit(messageId: string, userId: number, editedMessage: string): void;
  onSubmitDelete(messageId: string): void;
}

export const MessageList = ({ messages, isEditing, onSubmitEdit, isDeleting, onSubmitDelete }: IMessageListProps): JSX.Element => {
  const messagesCount = messages.length;
  const userId = useSelector(authSelectors.getUserId);
  const [messageListEndDivElement, setmessageListEndDivElement] = useState<HTMLDivElement | null>();
  useEffect(() => {
    if (messageListEndDivElement !== undefined && messageListEndDivElement !== null) {
      messageListEndDivElement.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messageListEndDivElement, messagesCount]);
  return (
    <div style={{ maxHeight: 600, maxWidth: 600, minWidth: 600, overflowY: 'scroll' }}>
      {messages
        .sort((a, b) => {
          return new Date(a.timeOfMessage).getTime() - new Date(b.timeOfMessage).getTime();
        })
        .map(message => (
          <Message
            key={message.messageId}
            message={message.message}
            username={message.username}
            timeOfMessage={message.timeOfMessage}
            canEdit={message.userId === userId}
            canDelete={message.userId === userId}
            isEditSubmitting={isEditing}
            isDeleteSubmitting={isDeleting}
            onSubmitEdit={(editedMessage: string): void => {
              onSubmitEdit(message.messageId, message.userId, editedMessage);
            }}
            onSubmitDelete={(): void => onSubmitDelete(message.messageId)}
          />
        ))}
      <div
        style={{ float: 'left', clear: 'both' }}
        ref={(el): void => {
          setmessageListEndDivElement(el);
        }}
      ></div>
    </div>
  );
};

export default MessageList;
