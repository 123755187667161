import ShelfLocation from 'modules/planogram/domain/ShelfLocation';
import GeometryPoint from 'modules/shared/geometry/GeometryPoint';

/**
 * Method to extract geometry points from a shelf location.
 * @param sl Shelf location to extrant geometry points from
 * @param useTransient If true, it will use xOffset & yOffset in the result, otherwise, only x & y. Basically, setting it to true, will get the actual current position of the point
 * @returns Array of GeometryPoint that represent shelf location position.
 */
export function getGeometryPoints(sl: ShelfLocation, useTransient = true): GeometryPoint[] {
  return sl.geometry.points.map(p => {
    const xOffset = useTransient ? p.xOffset : 0;
    const yOffset = useTransient ? p.yOffset : 0;
    return {
      x: p.x + xOffset,
      y: p.y + yOffset,
    };
  });
}

/**
 * Builds an SVG path for a given shelf location
 * @param points Shelf location for which path will be build
 * @returns The path that can be used as an SVG path attribute
 */
export function buildPath(points: GeometryPoint[]): string {
  const pathBuilder: string[] = [];
  for (let i = 0; i < points.length; i++) {
    const command = i === 0 ? 'M' : 'L';
    const point = points[i];
    pathBuilder.push(`${command}${point.x} ${point.y}`);
  }
  pathBuilder.push('Z');
  const path = pathBuilder.join('');
  return path;
}

interface ShelfLocationPathStyle {
  fillOpacity: number;
  fill: string;
  stroke: string;
  strokeWidth: string;
  strokeDasharray: string;
}

export function getShelfLocationPathStyle(sl: ShelfLocation, ratio: number, isSelected: boolean, isCopying: boolean): ShelfLocationPathStyle {
  const style: ShelfLocationPathStyle = {
    fill: '',
    fillOpacity: 0.4,
    stroke: 'blue',
    strokeWidth: `${1 / ratio}px`,
    strokeDasharray: '',
  };

  if (sl.color) {
    style.fill = '#' + sl.color;
  }

  if (sl.isCreating) {
    style.fillOpacity = 1;
    style.fill = 'black';
    style.stroke = 'yellow';
  } else if (!sl.imageUrl) {
    style.fill = 'black';
    style.fillOpacity = 1;
  } else if (sl.isHighlighted) {
    style.stroke = 'green';
    style.strokeWidth = `${4 / ratio}px`;
  } else if (isCopying) {
    style.stroke = 'red';
    style.strokeDasharray = '4px';
  }

  if (sl.color === null) {
    style.fillOpacity = 0;
  }

  if (isSelected) {
    style.stroke = 'red';
  }

  return style;
}
