import moment, { Moment } from 'moment';
import { WeekDate } from 'types/CommonTypes';
import { StoreOpenHours } from 'modules/shelfTimelapse/state/types';

// Gets the number of weeks count between the specified date range
export function getNumberOfWeeksInDateRange(fromDate: Moment, toDate: Moment): number {
  const startOfWeek = fromDate
    .clone()
    .startOf('isoWeek')
    .subtract(1, 'hours'); // subtracting 1 hour, to result in the previous day i.e last day of the previous week - this is done to include the week during diff
  const endOfWeek = toDate
    .clone()
    .endOf('isoWeek')
    .add(1, 'hours'); // adding 1 hour, to result in the next day i.e first day of the next week - this is done to include the week during diff
  return endOfWeek.diff(startOfWeek, 'weeks', false);
}
// Gets Date as string in YYYY-mm-dd format, which is required for input date field used in form
export function getDateInInputFormat(dateMoment: any): string {
  const date = new Date(Date.parse(dateMoment));
  const dd = String(date.getDate()).padStart(2, '0');
  const mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
  const yyyy = date.getFullYear();
  const dateString = yyyy + '-' + mm + '-' + dd;
  return dateString;
}
// Gets the Start and End Dates for each week between the specified date range, first week starts from 'fromDate' and last week will end with 'toDate'.
export function getWeekDatesInDateRange(fromDate: string, toDate: string): WeekDate[] {
  const weekDates: WeekDate[] = [];
  const startDate = moment(fromDate, 'YYYY-MM-DDThh:mm:ss');
  const endDate = moment(toDate, 'YYYY-MM-DDThh:mm:ss');

  const numberOfWeeks = getNumberOfWeeksInDateRange(startDate, endDate);
  let startOfWeek = startDate.clone().startOf('isoWeek');
  for (let i = 1; i <= numberOfWeeks; i++) {
    // for each week
    if (i === 1) {
      // if first week, adjust startOfWeek to be the first day in date range
      const adjustedStartOfWeek = startDate.clone();
      startOfWeek = adjustedStartOfWeek;
    }
    let endOfWeek = startOfWeek.clone().endOf('isoWeek');
    if (i === numberOfWeeks) {
      // if last week, adjust endOfWeek to be the last day in date range
      const adjustedEndOfWeek = endDate.clone();
      endOfWeek = adjustedEndOfWeek;
    }
    weekDates.push({ startDate: startOfWeek.format('YYYY-MM-DDThh:mm:ss'), endDate: endOfWeek.format('YYYY-MM-DDThh:mm:ss') });
    const nextWeekStart = startOfWeek.startOf('isoWeek').add(1, 'week');
    startOfWeek = nextWeekStart; // go to next week
  }
  return weekDates;
}

export function getNumberOfDaysInDateRange(fromDate: Moment, toDate: Moment): number {
  const startOfDay = fromDate
    .clone()
    .startOf('day')
    .subtract(1, 'hours'); // subtracting 1 hour, to result in the previous day i.e last hour of the previous day - this is done to include the day during diff
  const endOfDay = toDate
    .clone()
    .endOf('day')
    .add(1, 'hours'); // adding 1 hour, to result in the next day i.e first hour of the next day - this is done to include the day during diff
  return endOfDay.diff(startOfDay, 'days', false);
}

export function getNumberOfHoursInDateRange(fromHour: Moment, toHour: Moment): number {
  const startHour = fromHour
    .clone()
    .startOf('hour')
    .subtract(1, 'minute'); // subtracting 1 minute, to result in the previous hour i.e last minute of the previous hour - this is done to include the hour during diff
  const endHour = toHour
    .clone()
    .endOf('hour')
    .add(1, 'minute'); // adding 1 minute, to result in the next hour i.e first minute of the next hour - this is done to include the hour during diff
  return endHour.diff(startHour, 'hours', false);
}

// Gets hourly timestamps of the store operational hours within a specified date range
export function getOperationalHourTimestamps(fromDate: string, toDate: string, storeOpeningHours: StoreOpenHours[]): string[] {
  const hourlyTimestampsInWeek: string[] = [];
  const startDate = moment(fromDate);
  const endDate = moment(toDate);
  const daysInWeek = getNumberOfDaysInDateRange(startDate, endDate);
  let currentDate = startDate.clone().startOf('hour'); // initialize currentDate's date with start date of the week
  // For each day in the selected week date range
  for (let currentDay = 1; currentDay <= daysInWeek; currentDay++) {
    const startOfDay = currentDate.clone().startOf('day');
    const dayName = currentDate.format('dddd').toLowerCase(); // day name of the week like 'monday'
    // Get store opening hours for this day
    const openingHoursForDay = storeOpeningHours.find(x => x.day.toLowerCase() === dayName);
    if (!openingHoursForDay) {
      // if day does not exist in operational opening hours, increment day and skip
      currentDate = startOfDay
        .clone()
        .startOf('day')
        .add(1, 'day');
      continue;
    }
    const openingHour = moment(openingHoursForDay.openAt, 'HH:mm:ss'); // store opening hour for this day
    const closingHour = moment(openingHoursForDay.closeAt, 'HH:mm:ss'); // store closing hour for this day

    // initialize currentDate's time with opening hour's hour, minute and seconds
    currentDate.set('hour', openingHour.hour());
    currentDate.set('minute', openingHour.minute());
    currentDate.set('second', openingHour.second());
    const operationalHours = getNumberOfHoursInDateRange(openingHour, closingHour);
    // for each hour in the total operational hours for this day
    for (let currentHour = 1; currentHour <= operationalHours; currentHour++) {
      hourlyTimestampsInWeek.push(currentDate.format('YYYY-MM-DDTHH:mm:ss').concat('Z')); // add hour timestamp to hourlyTimestampsInWeek
      // hourlyTimestampsInWeek.push(currentDate.toISOString()); // add hour timestamp to hourlyTimestampsInWeek
      currentDate.add(1, 'hour'); // increment hour and repeat
    }
    currentDate = startOfDay // increment day until last day of the week
      .clone()
      .startOf('day')
      .add(1, 'day');
  }
  return hourlyTimestampsInWeek;
}
