import apiClient from '../../../utils/apiClient';
import { InspirationImageItem, UploadInspirationImagesRequestPayload } from './types';

interface FetchInspirationImagesResponse {
  inspirationImages: InspirationImageItem[];
}
export const fetchInspirationImages = (planogramId: string): Promise<FetchInspirationImagesResponse> => {
  return apiClient.get(`/planograms/${planogramId}/inspirationimages`);
};

export const deleteInspirationImage = ({ planogramId, inspirationImageId }: { planogramId: string; inspirationImageId: string }): Promise<{}> => {
  return apiClient.delete(`/admin/planograms/${planogramId}/inspirationimages/${inspirationImageId}`);
};

export const updateInspirationImageDesc = ({ planogramId, inspirationImageId, description }: { planogramId: string; inspirationImageId: string; description: string }): Promise<{}> => {
  return apiClient.put(`admin/planograms/${planogramId}/inspirationImages/${inspirationImageId}`, { description });
};

export function uploadInspirationImagesServices(payload: UploadInspirationImagesRequestPayload): Promise<{}> {
  const { planogramId, data } = payload;
  return apiClient.post(`admin/planograms/${planogramId}/inspirationImages`, data);
}
