import { jsPanel } from './jspanel-lib';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

jsPanel.ziBase = 10000;
let nextId = 0;

const stateDictionary = {};

interface JsPanelContentsState {
  children: any;
}

class JsPanelContents extends React.Component<{}, JsPanelContentsState> {
  constructor(props: any) {
    super(props);
    this.state = {
      children: props.children,
    };
  }

  render(): JSX.Element {
    return <>{this.state.children}</>;
  }
}

export default class JsPanel extends React.Component<JsPanelProps> {
  private panel: any;
  private myId: number;
  private myDomId: string;
  private isClosed: boolean;
  private elem: JsPanelContents | null = null;

  constructor(props: JsPanelProps) {
    super(props);
    this.myId = ++nextId;
    this.myDomId = `custom-jspanel-${this.myId}`;
    this.isClosed = false;
  }

  componentDidUpdate(): void {
    if (!this.panel) {
      return;
    }
    this.panel.setHeaderTitle(this.props.title);
    if (this.elem) {
      this.elem.setState({ children: this.props.children }, () => {
        if (this.elem) {
          this.elem.forceUpdate();
        }
      });
    }
    // ReactDOM.render(this.props.children as any, document.getElementById(this.myDomId));
  }

  saveRef = (elem: JsPanelContents | null): void => {
    this.elem = elem;
  };

  componentDidMount(): void {
    const savedState = this.getSavedStateOrDefault();
    this.panel = jsPanel.create({
      theme: 'primary',
      content: `<div id="${this.myDomId}"></div>`,
      panelSize: { width: savedState.width, height: savedState.height },
      headerTitle: this.props.title,
      headerControls: { size: 'xs' },
      position: {
        my: savedState.positionMy,
        at: savedState.positionAt,
        offsetY: savedState.top,
        offsetX: savedState.left,
      },
      setStatus: savedState.status,
      callback: () => {
        ReactDOM.render(<JsPanelContents ref={this.saveRef}>{this.props.children}</JsPanelContents>, document.getElementById(this.myDomId));
      },
      onclosed: () => {
        if (this.isClosed) {
          return;
        }
        this.isClosed = true;
        if (this.props.onClosed) {
          this.props.onClosed();
        }
        this.panel = null;
      },
    });
  }

  componentWillUnmount(): void {
    if (this.isClosed) {
      return;
    }
    if (this.props.saveStateId) {
      const cd = this.panel.currentData;
      stateDictionary[this.props.saveStateId] = {
        width: cd.width,
        height: cd.height,
        left: cd.left,
        top: cd.top,
        positionAt: 'left-top',
        positionMy: 'left-top',
        status: this.panel.status,
      };
    }
    this.isClosed = true;
    this.panel.close();
  }

  render(): null {
    return null;
  }

  private getSavedStateOrDefault(): JsPanelState {
    const defaultState = {
      width: 200,
      height: '70vh',
      left: '0px',
      top: '0px',
      positionAt: 'left-center',
      positionMy: 'left-center',
      status: 'normalized',
    };
    if (!this.props.saveStateId || !stateDictionary[this.props.saveStateId]) {
      return defaultState;
    }
    return stateDictionary[this.props.saveStateId];
  }
}

interface JsPanelProps {
  title: string;

  /**
   *  ID to use when saving state (size, position...) that will be restored
   *  when a panel with same 'saveStateId' will be created. If none provided, state will not be saved
   */
  saveStateId?: string;

  onClosed?(): void;
}

interface JsPanelState {
  width: number;
  height: string;
  left: string;
  top: string;
  positionAt: string;
  positionMy: string;
  status: string;
}
