import * as React from 'react';
import ShelfLocation from 'modules/planogram/domain/ShelfLocation';
import { buildPath, getGeometryPoints } from '../helpers';

interface ShadowShelfLocationProps {
  sl: ShelfLocation;
}

const ShadowShelfLocation: React.FC<ShadowShelfLocationProps> = ({ sl }: ShadowShelfLocationProps): JSX.Element => {
  const key = `shadow-sl-${sl.id}`;
  const path = buildPath(getGeometryPoints(sl));
  return (
    <g key={key} className="shadowshelflocation">
      <path fill="grey" d={path}></path>
    </g>
  );
};

export default ShadowShelfLocation;
