import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Typography } from '@material-ui/core';
import { Cancel, Delete } from '@material-ui/icons';
import React from 'react';
import trackComponent from 'infrastructure/tracking/withTracking';
import { ClientDatasetDto } from '../types/ClientDatasetDto';

class DeleteClientDatasetConfirmationModal extends React.Component<DeleteClientDatasetConfirmationModalProps> {
  render(): JSX.Element {
    return (
      <Dialog open={true} onClose={this.props.onClose}>
        <DialogTitle>
          <Typography variant="overline" gutterBottom>
            <Delete color="primary" fontSize="large" />
            &nbsp; Delete planogram confirmation
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">Are you absolutely sure that you want to delete</Typography>
          <Typography variant="button">
            <strong>{this.props.clientDataset.name}</strong>?
          </Typography>
          <br />
          <Typography color="textSecondary" variant="body2">
            This action is permanent.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" disabled={!this.props.enabled} onClick={this.props.onConfirmed}>
            <Delete />
            &nbsp;{this.props.isDeletingClientDataset ? 'Deleting dataset...' : 'Confirm'}
          </Button>
          &nbsp;
          <Button variant="contained" color="secondary" disabled={!this.props.enabled} onClick={this.props.onClose}>
            <Cancel />
            &nbsp;Cancel
          </Button>
        </DialogActions>
        {this.props.isDeletingClientDataset ? <LinearProgress color="secondary" /> : null}
      </Dialog>
    );
  }
}

interface DeleteClientDatasetConfirmationModalProps {
  enabled: boolean;
  clientDataset: ClientDatasetDto;
  isDeletingClientDataset: boolean;
  onClose(): void;
  onConfirmed(): void;
}
export default trackComponent(DeleteClientDatasetConfirmationModal, 'AdminClientPage_DeleteClientDatasetConfirmationModal');
