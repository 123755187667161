import { toggleAside } from '../shared/actions';
import * as constants from './constants';
import { OidcUser } from './types/OidcUser';
import { UserPreferencesDto } from './types/UserPreferencesDto';

export const onUserLoading = () => {
  return {
    type: constants.USER_LOADING,
  };
};

export const onUserLoaded = (user: OidcUser) => ({
  type: constants.USER_LOADED,
  user,
});

export const onUserLoadedError = () => ({
  type: constants.USER_LOADED_ERROR,
});

export const onLogout = () => {
  return {
    type: constants.REQUEST_SIGNOUT,
  };
};

export const notAuthenticated = () => {
  return {
    type: constants.NOT_AUTHENTICATED,
  };
};

export const loadUserPreferencesRequest = () => {
  return {
    type: constants.LOAD_USER_PREFERENCES_REQUEST,
  };
};
export const loadUserPreferencesSuccess = (userPreferences: UserPreferencesDto) => {
  return {
    type: constants.LOAD_USER_PREFERENCES_SUCCESS,
    userPreferences,
  };
};
export const loadUserPreferencesFailed = () => {
  return {
    type: constants.LOAD_USER_PREFERENCES_FAILED,
  };
};

export { toggleAside };
