import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PulseLoader from 'react-spinners/PulseLoader';

import HttpRequest, { HttpRequestChildrenFnArgs as ChildrenFnArg } from './HttpRequest';
import Carousel from './Carousel';
import { ShelfConditionsTarget } from 'modules/shelfTimelapse/state/types';
import { AlertErrorTextIndicator } from 'modules/shared/components/LoadStatusIndicators/AlertErrorTextIndicator';

export interface ShelfConditionDialogProps {
  open: boolean;
  onClose: () => void;
  targetId: string;
  targetType: ShelfConditionsTarget;
}

const ShelfConditionContainer: React.FC<ShelfConditionDialogProps> = ({ targetId, targetType }: ShelfConditionDialogProps): JSX.Element => {
  return (
    <HttpRequest targetId={targetId} targetType={targetType}>
      {({ isLoading, errorMessage, shelfConditionsData, carouselData }: ChildrenFnArg): JSX.Element => {
        return (
          <>
            {isLoading && (
              <Grid container direction="column" justify="center" alignItems="center">
                <Grid item>
                  <Typography>Loading shelf timelapse data...</Typography> <br />
                </Grid>
                <Grid item>
                  <PulseLoader color={'#031f5c'} />
                </Grid>
              </Grid>
            )}
            {errorMessage && (
              <Grid container direction="row" justify="center" alignItems="center">
                <Typography color="error">{errorMessage}</Typography>
              </Grid>
            )}
            {!isLoading && !errorMessage && !shelfConditionsData && <AlertErrorTextIndicator errorText="Unable to get shelf conditions data." />}
            {!isLoading && !errorMessage && shelfConditionsData && !carouselData && <AlertErrorTextIndicator errorText="Unable to generate carouselData." />}
            {!isLoading && !errorMessage && shelfConditionsData && carouselData && carouselData.sliderData.length === 0 && (
              <Grid container direction="row" justify="center" alignItems="center">
                <Typography color="primary">There are no shelf images captured at the moment</Typography>
              </Grid>
            )}
            {!isLoading && !errorMessage && shelfConditionsData && carouselData && carouselData.sliderData.length > 0 && <Carousel data={carouselData} />}
          </>
        );
      }}
    </HttpRequest>
  );
};

export default ShelfConditionContainer;
