import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import MUITabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export interface InspirationImagesTabsProps {
  children: React.ReactNode[];
}

const InspirationImagesTabs: React.FC<InspirationImagesTabsProps> = ({ children }: InspirationImagesTabsProps): JSX.Element => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleOnTabChanges = (e: React.ChangeEvent<{}>, index: number): void => {
    setTabIndex(index);
  };
  return (
    <Paper>
      <AppBar position="static" color="default">
        <MUITabs onChange={handleOnTabChanges} value={tabIndex} indicatorColor="primary" variant="fullWidth" textColor="primary" centered>
          <Tab data-testid="tab-upload-inspiration-images" label="Upload Inspiration Images" />
          <Tab data-testid="tab-uploaded-inspiration-images" label="Uploaded Inspiration Images" />
        </MUITabs>
      </AppBar>
      {React.Children.map(children, (child, index) => {
        if (index === tabIndex) {
          return <div>{child}</div>;
        }
        return <div style={{ display: 'none' }}>{child}</div>;
      })}
    </Paper>
  );
};

export default InspirationImagesTabs;
