import { reject, sortBy } from 'lodash';
import { ClientDatasetDto } from 'modules/admin/clientDataset/types/ClientDatasetDto';
import CompetitorShelfDataset from 'modules/admin/planograms/types/CompetitorShelfDataset';
import PlanogramModel from 'modules/planogram/domain/PlanogramModel';
import ShelfLocation from 'modules/planogram/domain/ShelfLocation';
import { markGpcShelfLocations, markHighlightedProducts, markInvalidShelfLocations } from 'modules/planogram/helpers/markShelfLocationsHelper';
import { prepareFlowDataReport } from 'modules/planogram/helpers/reportingHelpers';
import { prepareSalesDataReport } from 'modules/planogram/helpers/salesDataReportingHelpers';
import PlanogramState from 'modules/planogram/state';
import { FlowDataReportResult } from 'modules/planogram/types/FlowDataReportResult';
import ProductModel from 'modules/planogram/types/ProductModel';
import { FlowDataReportType } from 'modules/planogram/types/ReportingTypes';
import { SalesReportModel } from 'modules/planogram/types/SalesDataModel';
import ViewPort from 'modules/planogram/types/ViewPort';
import { Value } from 'react-svg-pan-zoom';
import { createSelector, Selector } from 'reselect';
import { State } from 'state';

const getState = (state: State): PlanogramState => state.planogram;

function shouldPrepareFlowData(enableFlowDataReport: boolean, selectedFlowDataType: FlowDataReportType | undefined, flowDataReport: FlowDataReportResult | undefined): boolean {
  return (enableFlowDataReport && selectedFlowDataType && flowDataReport && flowDataReport.shelfLocationsReport && flowDataReport.shelfLocationsReport.length > 0) ?? false;
}

function shouldPrepareSalesData(enableSalesDataReport: boolean, selectedSalesReport: SalesReportModel | undefined): boolean {
  return (enableSalesDataReport && selectedSalesReport && selectedSalesReport.items.length > 0) ?? false;
}

function shouldPrepareClientSalesData(enableClientDatasetReport: boolean, selectedClientDatasetReport: ClientDatasetDto | undefined): boolean {
  return (enableClientDatasetReport && selectedClientDatasetReport && selectedClientDatasetReport.dataItems.length > 0) ?? false;
}

function shouldMarkGpcShelfLocations(gpcBrickFilterEnabled: boolean, gpcBrickCodeFilters: number[]): boolean {
  return gpcBrickFilterEnabled && gpcBrickCodeFilters.length > 0;
}

function shouldMarkInvalidShelfLocations(planogram: PlanogramModel | undefined, paintInvalidShelfLocations: boolean): boolean {
  return (planogram && !planogram.isValid && paintInvalidShelfLocations) ?? false;
}

function shouldPrepareCompetitorSalesData(enableCompetitorShelfData: boolean, competitorShelfDataset: CompetitorShelfDataset): boolean {
  return enableCompetitorShelfData && competitorShelfDataset.items.length > 0;
}

function appendDataToShelfLocations(planogram: PlanogramModel, shelfLocations: ShelfLocation[], state: PlanogramState): void {
  if (shouldPrepareFlowData(state.enableFlowDataReport, state.selectedFlowDataType, state.flowDataReport.data)) {
    if (state.selectedFlowDataType === FlowDataReportType.ProductCurrentShareOfSpace) {
      // here, we are injecting the current share of space for a product, because it is a value
      // that can change
      const shelfAreaMeters = (planogram.shelfWidthCm / 100.0) * (planogram.shelfHeightCm / 100.0);
      state.flowDataReport.data?.shelfLocationsReport.forEach(slReport => {
        const productArea = planogram.shelfLocations
          .filter(sl => sl.gtin === slReport.gtin)
          .reduce((sum, sl) => {
            return sl.geometry.areaSquaredMeters + sum;
          }, 0);
        slReport.productShareOfSpace = isFinite(productArea / shelfAreaMeters) ? productArea / shelfAreaMeters : 0;
      });
    }
    prepareFlowDataReport(shelfLocations, planogram.actionRules, state.flowDataReport.data?.shelfLocationsReport ?? [], state.selectedFlowDataType);
  } else if (shouldPrepareSalesData(state.enableSalesDataReport, state.selectedSalesReport)) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    prepareSalesDataReport(shelfLocations, state.selectedSalesReport!.items);
  } else if (shouldPrepareClientSalesData(state.enableClientDatasetReport, state.selectedClientDatasetReport)) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    prepareSalesDataReport(shelfLocations, state.selectedClientDatasetReport!.dataItems);
  } else if (shouldPrepareCompetitorSalesData(state.enableCompetitorShelfData, state.competitorShelfDataset)) {
    prepareSalesDataReport(shelfLocations, state.competitorShelfDataset.items);
  } else {
    shelfLocations.forEach(sl => {
      sl.color = null;
      sl.displayText = null;
      sl.isHighlighted = false;
    });
  }
}

export const getGlobalState: Selector<State, PlanogramState> = createSelector(getState, state => state);

export const getViewPort: Selector<State, ViewPort> = createSelector(getState, state => state.planogramViewPort);

export const getPlanogram: Selector<State, PlanogramModel> = createSelector(getState, state => {
  return state.showInitialPlanogram ? (state.initialPlanogram as PlanogramModel) : (state.planogram as PlanogramModel);
});

export const getInitialPlanogram: Selector<State, PlanogramModel> = createSelector(getState, state => state.initialPlanogram as PlanogramModel);

export const getImageViewPort: Selector<State, ViewPort> = createSelector(getState, state => state.loadImageStatus.data as ViewPort);

function prepareMarkingOfShelfLocations(state: PlanogramState, planogram: PlanogramModel, shelfLocations: ShelfLocation[]): void {
  appendDataToShelfLocations(planogram, shelfLocations, state);
  if (shouldMarkGpcShelfLocations(state.gpcBrickFilterEnabled, state.gpcBrickCodeFilters)) {
    markGpcShelfLocations(shelfLocations, state.products || [], state.gpcBrickCodeFilters);
  }
  markHighlightedProducts(shelfLocations, state.products as ProductModel[], state.priorityHighlightedBrandId, state.highlightedBrandId, state.priorityHighlightedGtin, state.highlightedGtin);
  if (shouldMarkInvalidShelfLocations(state.planogram, state.paintInvalidShelfLocations)) {
    markInvalidShelfLocations(shelfLocations);
  }
}

const getShelfLocations = (state: State): ShelfLocation[] => {
  const planogram = getPlanogram(state);
  const shelfLocations = sortBy(planogram.shelfLocations, ['lastChangedOn']);
  prepareMarkingOfShelfLocations(state.planogram, planogram, shelfLocations);
  return shelfLocations;
};

export const getSelectedShelfLocations: Selector<State, ShelfLocation[]> = createSelector(getState, getShelfLocations, state => {
  const shelfLocations = sortBy(state.selectedShelfLocations, ['lastChangedOn']);
  return shelfLocations;
});

export const getNotSelectedShelfLocations: Selector<State, ShelfLocation[]> = createSelector(
  getState,
  getShelfLocations,
  getSelectedShelfLocations,
  (state, shelfLocations, selectedShelfLocations) => {
    if (!state.planogram) {
      return [];
    }
    const notSelectedShelfLocations = reject(shelfLocations, (sl: ShelfLocation): boolean => {
      return selectedShelfLocations.find(ssl => ssl === sl) ? true : false;
    });
    return notSelectedShelfLocations;
  }
);

export const getCopyingShelfLocations: Selector<State, ShelfLocation[]> = createSelector(getState, state => {
  const copyingShelfLocations = state.copyingShelfLocations;
  return copyingShelfLocations;
});

export const getSvgPanZoomValue: Selector<State, Value> = createSelector(getState, state => state.svgPanZoomValue);
