import React from 'react';
import { Redirect } from 'react-router';
import trackComponent from 'infrastructure/tracking/withTracking';

class PostLogoutComponent extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Redirect to="/planograms" />
      </React.Fragment>
    );
  }
}

export default trackComponent(PostLogoutComponent, 'PostLogoutComponent');
