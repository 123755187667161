import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableHead, TablePagination, Typography } from '@material-ui/core';
import { Close, InfoOutlined } from '@material-ui/icons';
import React from 'react';
import Alert from 'reactstrap/lib/Alert';
import trackComponent from 'infrastructure/tracking/withTracking';
import { StyledTableCell, StyledTableRow } from 'scss/MaterialStyles';
import { ClientDatasetDto } from '../types/ClientDatasetDto';

interface PreviewClientDatasetModalState {
  page: number;
  rowsPerPage: number;
}

class PreviewClientDatasetModal extends React.Component<PreviewClientDatasetModalProps, PreviewClientDatasetModalState> {
  constructor(props: PreviewClientDatasetModalProps, state: PreviewClientDatasetModalState) {
    super(props, state);

    this.state = {
      page: 0,
      rowsPerPage: 10,
    };
  }

  handleChangePage(event: any, newPage: any) {
    this.setState({ page: newPage });
  }

  handleChangeRowsPerPage(event: any) {
    this.setState({ rowsPerPage: event.target.value, page: 0 });
  }

  render() {
    return (
      <Dialog open={true} onClose={this.props.onClose} fullWidth>
        <DialogTitle>{this.props.clientDataset.name}</DialogTitle>
        <DialogContent>
          <Table size="small">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>GTIN</StyledTableCell>
                <StyledTableCell>Value</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>{this.renderClientDatasetRows(this.props.clientDataset)}</TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            count={this.props.clientDataset.dataItems.length}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onChangePage={(evt: any, newPage: any) => this.handleChangePage(evt, newPage)}
            onChangeRowsPerPage={(evt: any) => this.handleChangeRowsPerPage(evt)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose} color="secondary" variant="contained">
            <Close />
            &nbsp;Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderClientDatasetRows(dataset: ClientDatasetDto) {
    if (dataset.dataItems.length === 0) {
      return (
        <Alert className="top-buffer" color="info">
          <Typography variant="caption" color="textSecondary">
            <InfoOutlined color="primary" fontSize="small" />
            &nbsp; Data does not contain products in this shelf.
          </Typography>
        </Alert>
      );
    }
    return dataset.dataItems.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map(row => {
      return (
        <StyledTableRow hover key={row.gtin}>
          <StyledTableCell>{row.gtin}</StyledTableCell>
          <StyledTableCell>{row.value}</StyledTableCell>
        </StyledTableRow>
      );
    });
  }
}

interface PreviewClientDatasetModalProps {
  clientDataset: ClientDatasetDto;
  onClose(): void;
}

export default trackComponent(PreviewClientDatasetModal, 'AdminClientsPage_PreviewClientDatasetModal');
