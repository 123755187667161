import PlanogramHeader from '../modules/planogram/components/header/PlanogramHeader';
import RealogramPageHeader from 'modules/realogram/header/Header';

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/planograms/:id', exact: true, name: 'Planogram', component: PlanogramHeader },
  { path: '/planograms/:id/share/:sharingToken', name: 'PublicPlanogram', component: PlanogramHeader },
  { path: '/planograms/:id/test/:testPlanogramId', name: 'TestPlanogram', component: PlanogramHeader },
  // Realogram routes
  {
    path: '/realograms/:shelfReferenceId',
    exact: true,
    name: 'Realogram Header',
    component: RealogramPageHeader,
  },
];

export default routes;
