import React from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LaunchIcon from '@material-ui/icons/Launch';
import Tooltip from '@material-ui/core/Tooltip';
import { InspirationImageItem } from '../../../../entities/inspirationImages/types';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  card: {
    maxWidth: 350,
    marginTop: 15,
  },
  img: {
    maxWidth: '100%',
    height: 'auto',
    width: 'auto',
    margin: '0 auto',
    cursor: 'auto',
  },
  cardActions: {
    justifyContent: 'space-between',
  },
});

interface Props {
  data: InspirationImageItem[];
}

const InspirationImagesList: React.FC<Props> = ({ data }: Props): JSX.Element => {
  const classes = useStyles();
  return (
    <Grid container direction="row" justify="space-around" wrap="wrap">
      {data.map(i => (
        <Card key={i.id} className={classes.card}>
          <CardActionArea>
            <CardMedia className={classes.img} image={i.imageUrl} component="img" />
          </CardActionArea>
          <CardActions className={classes.cardActions}>
            <Typography>{i.description}</Typography>
            <Tooltip title="Open image in new tab">
              <IconButton href={i.imageUrl} target="_blank" rel="noopener noreferrer">
                <LaunchIcon color="primary" />
              </IconButton>
            </Tooltip>
          </CardActions>
        </Card>
      ))}
    </Grid>
  );
};

export default InspirationImagesList;
