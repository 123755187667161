import { Badge, Card, CardContent, CardHeader, Collapse, Grid, IconButton, Typography } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp, ArrowLeft, ArrowRight, ExpandLess, ExpandMore, Info, OpenWith } from '@material-ui/icons';
import * as React from 'react';
import { Col, Row } from 'reactstrap';
import trackComponent from 'infrastructure/tracking/withTracking';
import { RecordChangeReasonDto } from '../../../types/PlanogramChangeReasons';
import PlanogramModel from '../../../domain/PlanogramModel';
import ProductModel from '../../../types/ProductModel';
import BrandsPreviewChanges from './BrandsPreviewChanges';
import DelistedProducts from './DelistedProducts';
import NewProducts from './NewProducts';
import ResizedProducts from './ResizedProducts';
import ProductSearch from './ProductSearch';

interface PreviewChangesState {
  isExpandedBrandsHeader: boolean;
  isExpandedDelistHeader: boolean;
  isExpandedMovementsHeader: boolean;
  isExpandedNewProductsHeader: boolean;
  isExpandedProductChangesHeader: boolean;
  delistReason: string;
  brandResizeReason: string;
  isExpandedGeneralHeader: boolean;
}

class PreviewChanges extends React.Component<PreviewChangesModalProps, PreviewChangesState> {
  constructor(props: PreviewChangesModalProps, state: PreviewChangesState) {
    super(props, state);
    this.state = {
      isExpandedDelistHeader: false,
      isExpandedMovementsHeader: false,
      isExpandedNewProductsHeader: false,
      isExpandedProductChangesHeader: false,
      isExpandedBrandsHeader: false,
      delistReason: '',
      brandResizeReason: '',
      isExpandedGeneralHeader: false,
    };
  }

  render() {
    return (
      <div
        onMouseLeave={() => {
          this.props.onHighlightProduct(false, undefined);
          this.props.onHighlightBrand(false, undefined);
        }}
      >
        {this.renderGeneral()}
        {this.renderListOfProducts()}
        {this.renderBrands()}
        {this.renderDelisted()}
        {this.renderNew()}
        {this.renderMovements()}
        {this.renderProductsResized()}
      </div>
    );
  }

  renderGeneral() {
    const initialGtinCount = new Set(this.props.initialPlanogram.shelfLocations.map(x => x.gtin)).size;
    const currentGtinCount = new Set(this.props.currentPlanogram.shelfLocations.map(x => x.gtin)).size;
    return (
      <div>
        <Card raised className="card-accent-info border-info bottom-buffer">
          <CardHeader
            style={{ background: 'rgb(108, 107, 117,0.2)' }}
            disableTypography={true}
            action={
              <div>
                {<Info color="primary" />}
                <IconButton onClick={() => this.setState({ isExpandedGeneralHeader: !this.state.isExpandedGeneralHeader })}>
                  {this.state.isExpandedGeneralHeader ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
                </IconButton>
              </div>
            }
            title={<Typography variant="button">General</Typography>}
          />
          <Collapse in={this.state.isExpandedGeneralHeader} timeout="auto">
            <CardContent>
              {
                <Grid container direction="column" justify="center" alignContent="flex-start" alignItems="flex-start">
                  <Typography color="textSecondary" variant="caption">
                    Initial GTIN Count: {initialGtinCount}
                  </Typography>
                  <Typography color="textSecondary" variant="caption">
                    Current GTIN Count: {currentGtinCount}
                  </Typography>
                </Grid>
              }
            </CardContent>
          </Collapse>
        </Card>
      </div>
    );
  }

  renderListOfProducts() {
    return <ProductSearch products={this.props.products} onHighlightProduct={this.props.onHighlightProduct} currentPlanogram={this.props.currentPlanogram} />;
  }

  renderBrands() {
    return (
      <BrandsPreviewChanges
        onHighlightBrand={this.props.onHighlightBrand}
        onRecordChangeReason={this.props.onRecordChangeReason}
        products={this.props.products}
        currentPlanogram={this.props.currentPlanogram}
      />
    );
  }

  renderDelisted() {
    return (
      <DelistedProducts
        currentPlanogram={this.props.currentPlanogram}
        initialPlanogram={this.props.initialPlanogram}
        undoDelistedProduct={this.props.undoDelistedProduct}
        onRecordChangeReason={this.props.onRecordChangeReason}
        onHighlightProduct={this.props.onHighlightProduct}
      />
    );
  }

  renderMovements() {
    // TODO: Create 'ProductsMoved' Component and encapsulate all of this logic within it
    const productsMoved = this.props.currentPlanogram.planogramChanges.productsMoved;
    const rows = productsMoved.map(sl => {
      return (
        <div
          key={sl.gtin}
          onMouseEnter={() => {
            this.props.onHighlightProduct(false, [sl.gtin]);
          }}
          onMouseLeave={() => {
            this.props.onHighlightProduct(false, undefined);
          }}
        >
          <div>
            <div>
              <li>
                <Typography color="textPrimary" variant="body2">
                  {sl.tradeItemDescription}
                </Typography>
              </li>
            </div>
            <div>
              <Typography variant="caption" color="textSecondary">
                Gtin: {sl.gtin}
              </Typography>
            </div>
            <div>
              <Typography variant="caption" color="textSecondary">
                Changes:
                {
                  <div key={sl.id}>
                    <span>
                      {sl.movedHorizontal < 0 ? <ArrowRight /> : <ArrowLeft />} {Math.abs(sl.movedHorizontal).toFixed(0) + ' cm'}
                    </span>
                    <span>
                      {sl.movedVertical < 0 ? <ArrowDropDown /> : <ArrowDropUp />}
                      {Math.abs(sl.movedVertical).toFixed(0) + ' cm'}
                    </span>
                  </div>
                }
              </Typography>
            </div>
          </div>
        </div>
      );
    });
    return (
      <Row>
        <Col xs="12">
          <Card raised className="card-accent-info border-warning bottom-buffer">
            <CardHeader
              style={{ background: 'rgb(255, 128, 0,.2)' }}
              disableTypography={true}
              action={
                <div>
                  {
                    <Badge color="secondary" badgeContent={rows.length}>
                      <OpenWith color="primary" />
                    </Badge>
                  }
                  <IconButton onClick={() => this.setState({ isExpandedMovementsHeader: !this.state.isExpandedMovementsHeader })}>
                    {this.state.isExpandedMovementsHeader ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
                  </IconButton>
                </div>
              }
              title={<Typography variant="button">Product Locations</Typography>}
            />
            <Collapse in={this.state.isExpandedMovementsHeader}>
              <CardContent style={{ marginLeft: '0px', paddingLeft: '0px' }}>
                {productsMoved.length === 0 ? (
                  <div className="left-buffer">
                    <Typography color="secondary" variant="caption">
                      No products moved.
                    </Typography>
                  </div>
                ) : (
                  <div>
                    <ol>{rows}</ol>
                  </div>
                )}
              </CardContent>
            </Collapse>
          </Card>
        </Col>
      </Row>
    );
  }

  renderProductsResized() {
    return <ResizedProducts currentPlanogram={this.props.currentPlanogram} onHighlightProduct={this.props.onHighlightProduct} onRecordChangeReason={this.props.onRecordChangeReason} />;
  }

  renderNew() {
    return <NewProducts onHighlightProduct={this.props.onHighlightProduct} currentPlanogram={this.props.currentPlanogram} onRecordChangeReason={this.props.onRecordChangeReason} />;
  }
}

interface PreviewChangesModalProps {
  initialPlanogram: PlanogramModel;
  currentPlanogram: PlanogramModel;
  products: ProductModel[];
  undoDelistedProduct(gtin: number): void;
  onHighlightBrand(priority: boolean, brandId?: number): void;
  onHighlightProduct(priority: boolean, gtin?: number[]): void;
  onRecordChangeReason(reason: RecordChangeReasonDto): void;
}

export default trackComponent(PreviewChanges, 'Aside_PreviewChanges');
