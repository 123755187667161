import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { ErrorOutline, InfoOutlined } from '@material-ui/icons';
import React from 'react';
import { Alert } from 'reactstrap';
import { StandardProjectReportLoader } from './StandardProjectReportLoader';
import { StandardProjectReportPage } from './StandardProjectReportPage';
const StandardProjectReportIndex: React.FC = () => {
  return (
    <StandardProjectReportLoader>
      {({ isLoaded, isLoading, loadError, isDataEmpty, data }): React.ReactNode => {
        const showProjectReport = !isDataEmpty && !isLoading && !loadError && isLoaded;
        return (
          <>
            {isLoading && (
              <Grid direction="column" alignItems="center" container justify="center">
                <CircularProgress /> &nbsp;&nbsp;
                <Typography variant="overline">Loading Project Report...</Typography>
              </Grid>
            )}
            {loadError && (
              <Alert className="top-buffer" color="danger">
                <Typography variant="caption" color="textSecondary">
                  <ErrorOutline color="secondary" fontSize="small" />
                  &nbsp;Failed to load Project Report. {loadError}
                </Typography>
              </Alert>
            )}
            {!loadError && !isLoading && isLoaded && isDataEmpty && (
              <Alert className="top-buffer" color="warning">
                <Typography variant="caption" color="textPrimary">
                  <InfoOutlined color="primary" fontSize="small" />
                  &nbsp; No Project Report Found.
                </Typography>
              </Alert>
            )}
            {showProjectReport && data && <StandardProjectReportPage projectReport={data} />}
          </>
        );
      }}
    </StandardProjectReportLoader>
  );
};

export { StandardProjectReportIndex };
