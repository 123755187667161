import GlobalUiState from './states/GlobalUiState';
import createReducer from 'utils/createReducer';
import * as constants from './constants';
import { closeAside, openAside } from '../../utils/asideToggler';

const initialGlobalUiState: GlobalUiState = {
  isAsideOpened: false,
  hasAppCrashed: false,
};

const handlers = {};

handlers[constants.LAYOUT_CLOSE_ASIDE] = (state: GlobalUiState): GlobalUiState => {
  closeAside();
  return {
    ...state,
    isAsideOpened: false,
  };
};

handlers[constants.LAYOUT_OPEN_ASIDE] = (state: GlobalUiState): GlobalUiState => {
  openAside();
  return {
    ...state,
    isAsideOpened: true,
  };
};

handlers[constants.LAYOUT_TOGGLE_ASIDE] = (state: GlobalUiState): GlobalUiState => {
  const newValue = !state.isAsideOpened;
  newValue ? openAside() : closeAside();
  return {
    ...state,
    isAsideOpened: newValue,
  };
};

handlers[constants.APP_HAS_CRASHED] = (state: GlobalUiState): GlobalUiState => {
  return {
    ...state,
    hasAppCrashed: true,
  };
};

export const globalUiReducer = createReducer(initialGlobalUiState, handlers);
