import { Button, Card, Grid } from '@material-ui/core';
import { Bookmark, Bookmarks, Store, Wc } from '@material-ui/icons';
import { BrandOption, GpcBrickOption, ProductOption } from 'modules/reports/types';
import { BrandSelector } from 'modules/shared/components/DropDownSelectors/BrandSelector';
import { GpcBrickSelector } from 'modules/shared/components/DropDownSelectors/GpcBrickSelector';
import { ProductSelector } from 'modules/shared/components/DropDownSelectors/ProductSelector';
import React from 'react';
import * as helpers from '../helpers';
import { StandardReportPhaseShelf } from '../types';
export interface StandardReportButtonsProps {
  phaseShelf: StandardReportPhaseShelf;
  selectedGtin?: number;
  selectedBrandId?: number;
  selectedProductBrandFilterId?: number;
  selectedGpcBrickCode?: number;
  handleOnChangeGtin(selectedGtin: number): void;
  handleOnChangeBrand(selectedBrand: number): void;
  handleOnChangeGpcBrickCode(selectedGpcBrickCode: number): void;
  handleOnChangeProductBrandFilter(selectedBrand: number): void;
}

const StandardReportButtons: React.FC<StandardReportButtonsProps> = ({
  phaseShelf,
  selectedGtin,
  selectedBrandId,
  selectedProductBrandFilterId,
  selectedGpcBrickCode,
  handleOnChangeGtin,
  handleOnChangeBrand,
  handleOnChangeProductBrandFilter,
  handleOnChangeGpcBrickCode,
}: StandardReportButtonsProps) => {
  const selectedProduct: ProductOption | undefined = helpers.getProductOptions(phaseShelf.products, selectedProductBrandFilterId).find(x => x.gtin === selectedGtin);
  const selectedBrand: BrandOption | undefined = helpers.getBrandOptions(phaseShelf.products).find(x => x.brandId === selectedBrandId);
  const selectedProductBrandFilter: BrandOption | undefined = helpers.getBrandOptions(phaseShelf.products).find(x => x.brandId === selectedProductBrandFilterId);
  const selectedGpcBrick: GpcBrickOption | undefined = helpers.getGpcBrickOptions(phaseShelf.products).find(x => x.gpcBrickCode === selectedGpcBrickCode);
  const shelfPhaseId = `${phaseShelf.projectId}-${phaseShelf.storeId}-${phaseShelf.phaseNum}-${phaseShelf.shelfNum}`;
  return (
    <>
      <Card style={{ margin: '8px', padding: '16px' }}>
        <Grid container direction="column" justify="flex-start" spacing={2}>
          <Grid item xs={12} style={{ width: '100%' }}>
            <Button
              size="large"
              fullWidth
              variant="contained"
              color="primary"
              onClick={(): void => {
                window.open(`/reports/standardReports/projectReport/${shelfPhaseId}`, '_blank');
              }}
            >
              <Store color="inherit" />
              &nbsp; Project Report
            </Button>
          </Grid>
          <Grid item xs={12} style={{ width: '100%' }}>
            <Button
              size="large"
              fullWidth
              variant="contained"
              color="primary"
              onClick={(): void => {
                window.open(`/reports/standardReports/shopperReports/projects/${shelfPhaseId}/shoppers`, '_blank');
              }}
            >
              <Wc color="inherit" />
              &nbsp; Shopper Report
            </Button>
          </Grid>
          <Grid container item direction="row" xs={12} justify="flex-start" spacing={2}>
            <Grid container item direction="row" xs={12} justify="flex-start" spacing={2}>
              <Grid item xs={6} style={{ width: '100%' }}>
                <GpcBrickSelector
                  htmlId={`StandardReport_GpcBrickSelector_${shelfPhaseId}`}
                  selectedGpcBrick={selectedGpcBrick}
                  gpcBrickOptions={helpers.getGpcBrickOptions(phaseShelf.products)}
                  handleOnSelectGpcBrick={(gpcBrick: GpcBrickOption): void => {
                    handleOnChangeGpcBrickCode(gpcBrick.gpcBrickCode);
                  }}
                />
              </Grid>
              <Grid item xs={3} style={{ width: '100%' }} justify="flex-end">
                {
                  <Button
                    size="large"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={selectedGpcBrick === undefined}
                    onClick={(): void => {
                      window.open(`/reports/standardReports/gpcBrickReports/projects/${shelfPhaseId}/gpcBricks/${selectedGpcBrick?.gpcBrickCode}`, '_blank');
                    }}
                  >
                    <Bookmarks color="inherit" />
                    &nbsp; Gpc Brick Report
                  </Button>
                }
              </Grid>
              <Grid item xs={3} style={{ width: '100%' }} justify="flex-end">
                {
                  <Button
                    size="large"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={true} // TODO {selectedGpcBrick === undefined} Not Implemented yet
                    onClick={(): void => {
                      window.open(`/reports/standardReports/gpcBrickReports/projects/${shelfPhaseId}/gpcBricks/${selectedGpcBrick?.gpcBrickCode}`, '_blank');
                    }}
                  >
                    <Bookmarks color="inherit" />
                    &nbsp; Gpc Brick Potential
                  </Button>
                }
              </Grid>
            </Grid>
            <Grid container item direction="row" xs={12} justify="flex-start" spacing={2}>
              <Grid item xs={6} style={{ width: '100%' }}>
                <BrandSelector
                  htmlId={`StandardReport_BrandSelector_${shelfPhaseId}`}
                  selectedBrand={selectedBrand}
                  brandOptions={helpers.getBrandOptions(phaseShelf.products)}
                  handleOnSelectBrand={(brand: BrandOption): void => {
                    handleOnChangeBrand(brand.brandId);
                  }}
                />
              </Grid>
              <Grid item xs={3} style={{ width: '100%' }}>
                {
                  <Button
                    size="large"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={selectedBrand === undefined}
                    onClick={(): void => {
                      window.open(`/reports/standardReports/brandReports/projects/${shelfPhaseId}/brands/${selectedBrand?.brandId}`, '_blank');
                    }}
                  >
                    <Bookmarks color="inherit" />
                    &nbsp; Brand Report
                  </Button>
                }
              </Grid>
              <Grid item xs={3} style={{ width: '100%' }}>
                {
                  <Button
                    size="large"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={selectedBrand === undefined}
                    onClick={(): void => {
                      window.open(`/reports/potentialReports/brandReports/projects/${shelfPhaseId}/brands/${selectedBrand?.brandId}`, '_blank');
                    }}
                  >
                    <Bookmarks color="inherit" />
                    &nbsp; Brand Potential
                  </Button>
                }
              </Grid>
            </Grid>

            <Grid container item direction="row" xs={12} justify="flex-start" spacing={2}>
              <Grid item xs={3} style={{ width: '100%' }}>
                <BrandSelector
                  htmlId={`StandardReport_ProductBrandSelector_${shelfPhaseId}`}
                  selectedBrand={selectedProductBrandFilter}
                  brandOptions={helpers.getBrandOptions(phaseShelf.products)}
                  handleOnSelectBrand={(brand: BrandOption): void => {
                    handleOnChangeProductBrandFilter(brand.brandId);
                  }}
                />
              </Grid>
              <Grid item xs={3} style={{ width: '100%' }}>
                <ProductSelector
                  htmlId={`StandardReport_ProductSelector_${shelfPhaseId}`}
                  selectedProduct={selectedProduct}
                  productOptions={helpers.getProductOptions(phaseShelf.products, selectedProductBrandFilterId)}
                  handleOnSelectProduct={(product: ProductOption): void => {
                    handleOnChangeGtin(product.gtin);
                  }}
                />
              </Grid>
              <Grid item xs={3} style={{ width: '100%' }}>
                {
                  <Button
                    size="large"
                    fullWidth
                    variant="contained"
                    disabled={selectedProduct === undefined}
                    color="primary"
                    onClick={(): void => {
                      window.open(`/reports/standardReports/productReports/projects/${shelfPhaseId}/products/${selectedProduct?.gtin}`, '_blank');
                    }}
                  >
                    <Bookmark color="inherit" />
                    &nbsp; Product Report
                  </Button>
                }
              </Grid>
              <Grid item xs={3} style={{ width: '100%' }}>
                {
                  <Button
                    size="large"
                    fullWidth
                    variant="contained"
                    disabled={true} // TODO {selectedProduct === undefined} Not implemented yet.
                    color="primary"
                    onClick={(): void => {
                      window.open(`/reports/standardReports/productReports/projects/${shelfPhaseId}/products/${selectedProduct?.gtin}`, '_blank');
                    }}
                  >
                    <Bookmark color="inherit" />
                    &nbsp; Product Potential
                  </Button>
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export { StandardReportButtons };
