import * as React from 'react';
import { Button, Typography, Dialog, DialogTitle, DialogContent, DialogActions, LinearProgress } from '@material-ui/core';
import { Cancel, Delete } from '@material-ui/icons';
import trackComponent from 'infrastructure/tracking/withTracking';

const DeleteConfirmationModal = (props: DeleteConfirmationModalProps) => (
  <Dialog open={true} onClose={props.onClose}>
    <DialogTitle>
      <Typography variant="overline" gutterBottom>
        <Delete color="primary" fontSize="large" />
        &nbsp; Delete planogram confirmation
      </Typography>
    </DialogTitle>
    <DialogContent>
      <Typography variant="body1">Are you absolutely sure that you want to delete planogram</Typography>
      <Typography variant="button">
        <strong>{props.planogramName}</strong>
      </Typography>
      <br />
      <Typography color="textSecondary" variant="body2">
        This action is permanent. All your work on this planogram will be lost.
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" color="primary" disabled={!props.enabled} onClick={props.onConfirmed}>
        <Delete />
        &nbsp;{props.isDeleting ? 'Deleting planogram...' : 'Delete permanently'}
      </Button>
      &nbsp;
      <Button variant="contained" color="secondary" disabled={!props.enabled} onClick={props.onClose}>
        <Cancel />
        &nbsp;Cancel
      </Button>
    </DialogActions>
    {props.isDeleting ? <LinearProgress color="secondary" /> : null}
  </Dialog>
);

interface DeleteConfirmationModalProps {
  planogramName: string;
  enabled: boolean;
  isDeleting: boolean;

  onClose(): void;

  onConfirmed(): void;
}

export default trackComponent(DeleteConfirmationModal, 'PlanogramPage_DeleteConfirmationModal');
