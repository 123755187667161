import * as React from 'react';
import { Typography } from '@material-ui/core';
import { State } from '../../../../state';
import { connect } from 'react-redux';
import PlanogramState from '../../state';
import { RouteComponentProps } from 'react-router';
import * as actions from '../../actions';
import trackComponent from 'infrastructure/tracking/withTracking';
import { isLoaded } from 'infrastructure/utils/RemoteObjectStatus';
type MyProps = PlanogramState & DispatchProps;

class PublicPlanogramFooter extends React.Component<MyProps> {
  render(): JSX.Element | null {
    if (!isLoaded(this.props.loadPlanogramStatus)) {
      return null;
    }
    return <React.Fragment>{this.renderPlanogramGoal()}</React.Fragment>;
  }

  renderPlanogramGoal(): JSX.Element {
    return (
      <Typography style={{ margin: '8px' }} className="col-xs-4 col-xs-offset-2" variant="overline" color="textPrimary">
        {this.props.planogram?.goal}
      </Typography>
    );
  }
}

// TODO: Remove? probably dont need planogram as a prop here.
function mapStateToProps(state: State, ownProps: any) {
  return {
    ...state.planogram,
    id: ownProps.match.params.id,
  };
}

interface DispatchProps {
  shareModalToggle(): void;

  publicSharingToggleRequest(planogramId: string, enabled: boolean): void;
}

const componentToExport = connect<PlanogramState, DispatchProps, RouteComponentProps<any>>(mapStateToProps, actions)(PublicPlanogramFooter);

export default trackComponent(componentToExport, 'PublicPlanogramFooter');
