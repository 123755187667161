import { ClientOption, ProjectOption } from 'modules/reports/types';
import ErrorDetails from '../../infrastructure/exception/ErrorDetails';
import { ShelfPhaseDetails, TimelinesData } from './types';
/* eslint-disable @typescript-eslint/explicit-function-return-type */

export const TIMELINES_LOAD_REQUEST_IN_CURRENT_YEAR = 'TIMELINES_LOAD_REQUEST_IN_CURRENT_YEAR';
export const TIMELINES_LOAD_REQUEST_IN_DATE_RANGE = 'TIMELINES_LOAD_REQUEST_IN_DATE_RANGE';
export const TIMELINES_LOAD_SUCCESS = 'TIMELINES_LOAD_SUCCESS';
export const TIMELINES_LOAD_FAILURE = 'TIMELINES_LOAD_FAILURE';
export const OPEN_TIMELINES_MENU = 'OPEN_TIMELINES_MENU';
export const CLOSE_TIMELINES_MENU = 'CLOSE_TIMELINES_MENU';
export const ON_SELECT_CLIENT_IN_TIMELINE = 'ON_SELECT_CLIENT_IN_TIMELINE';
export const ON_SELECT_PROJECT_IN_TIMELINE = 'ON_SELECT_PROJECT_IN_TIMELINE';

export function loadTimelinesInCurrentYearRequestAction(viewAs?: string) {
  return {
    type: TIMELINES_LOAD_REQUEST_IN_CURRENT_YEAR,
    viewAs,
  };
}

export function loadTimelinesInDateRangeRequestAction(startDate: string, endDate: string, viewAs: string) {
  return {
    type: TIMELINES_LOAD_REQUEST_IN_DATE_RANGE,
    startDate,
    endDate,
    viewAs,
  };
}
export function loadTimelinesSuccessAction(data: TimelinesData) {
  return {
    type: TIMELINES_LOAD_SUCCESS,
    data,
  };
}
export function loadTimelinesFailureAction(error: ErrorDetails) {
  return {
    type: TIMELINES_LOAD_FAILURE,
    error,
  };
}

export function openTimelinesMenuAction(anchorEl: Element, selectedShelfPhase: ShelfPhaseDetails) {
  return {
    type: OPEN_TIMELINES_MENU,
    anchorEl,
    selectedShelfPhase,
  };
}

export function closeTimelinesMenuAction() {
  return {
    type: CLOSE_TIMELINES_MENU,
  };
}
export function onSelectClientInTimeline(selectedClientOption: ClientOption) {
  return {
    type: ON_SELECT_CLIENT_IN_TIMELINE,
    selectedClientOption,
  };
}
export function onSelectProjectInTimeline(selectedProjectOption: ProjectOption) {
  return {
    type: ON_SELECT_PROJECT_IN_TIMELINE,
    selectedProjectOption,
  };
}
