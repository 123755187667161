import * as React from 'react';
import { getCenterPoint } from '../../../helpers/geometry';
import ShelfLocation from '../../../domain/ShelfLocation';

interface ShelfLocationTextProps {
  shelfLocation: ShelfLocation;
  ratio: number;
  isPrintMode: boolean;
}

const ShelfLocationText: React.FC<ShelfLocationTextProps> = ({ shelfLocation, ratio, isPrintMode }: ShelfLocationTextProps) => {
  const centerPoint = getCenterPoint(shelfLocation.geometry.getPointsWithOffset());
  const x = centerPoint.x;
  const y = centerPoint.y;
  const text = isPrintMode ? shelfLocation.gridPosition : shelfLocation.displayText;
  const fontSize = 12 / ratio;
  return (
    <>
      <text className="excludeFromShelfImage" textAnchor="middle" dominantBaseline="middle" fontWeight="bold" fill="black" x={x} y={y} fontSize={fontSize}>
        {text}
      </text>
    </>
  );
};

export default ShelfLocationText;
