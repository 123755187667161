import { IconButton, Tooltip, Divider } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { Help } from '@material-ui/icons';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { State } from 'state';
import * as actions from '../../actions';
import PlanogramState from '../../state';
import ZoomControlsInHeader from '../ZoomControls';
import { isError, isLoading, isLoaded } from 'infrastructure/utils/RemoteObjectStatus';
import { AlertErrorTextIndicator } from 'modules/shared/components/LoadStatusIndicators/AlertErrorTextIndicator';
import { HorizontalLoadIndicator } from 'modules/shared/components/LoadStatusIndicators/CircularLoadIndicator';

class PlanogramPageHeader extends React.Component<PlanogramState & DispatchProps> {
  render(): JSX.Element | null {
    if (!isLoaded(this.props.loadPlanogramStatus)) {
      return null;
    }
    const text = this.props.showInitialPlanogram ? 'Original' : 'Current';
    return (
      <>
        <Tooltip title="Start Product Guide">
          <IconButton
            id="playInstructionalGuideBtn"
            onClick={(evt: React.MouseEvent): void => {
              evt.preventDefault();
              this.props.runProductTour();
            }}
          >
            <Help fontSize="large" color="primary" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Toggle between current and original shelf">
          <div>
            <Switch
              id="togglePlanogramStateSwitch"
              checked={!this.props.showInitialPlanogram}
              onChange={this.props.toggleShowInitialPlanogram}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              style={{ textAlign: 'center' }}
              color="primary"
            />{' '}
            {text}
          </div>
        </Tooltip>
        <Divider orientation="vertical" variant="middle" style={{ marginTop: '-4px' }} />
        <ZoomControlsInHeader
          zoomIn={(): void => {
            this.props.onZoomLevelAdjustmentRequest(+1);
          }}
          zoomOut={(): void => {
            this.props.onZoomLevelAdjustmentRequest(-1);
          }}
          reset={(): void => {
            this.props.onZoomLevelAdjustmentRequest(0);
          }}
        />
        {this.props.flowDataReport && isLoading(this.props.flowDataReport) && <HorizontalLoadIndicator loadingText="Loading Flow data" />}
        {this.props.flowDataReport && isError(this.props.flowDataReport) && <AlertErrorTextIndicator errorText="Failed to load Flow data" />}
      </>
    );
  }
}

interface MatchParams {
  id: string;
}

function mapStateToProps(state: State, ownProps: RouteComponentProps<MatchParams>): PlanogramState {
  return {
    ...state.planogram,
    id: ownProps.match.params.id,
  };
}

interface DispatchProps {
  toggleShowInitialPlanogram(): void;
  runProductTour(): void;
  onZoomLevelAdjustmentRequest(zoomLevelControlNumber: number): void;
}

export default connect<PlanogramState, DispatchProps, RouteComponentProps<MatchParams>>(mapStateToProps, actions)(PlanogramPageHeader);
