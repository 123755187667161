import React from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import HTTPFetchRequest from '../../../../../entities/inspirationImages/components/HTTPFetchRequest';
import ImagesList from './ImagesList';
import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles({
  grid: { minHeight: 200 },
});

interface Props {
  planogramId: string;
}
const InspirationImagesList: React.FC<Props> = ({ planogramId }: Props): JSX.Element => {
  const { grid: gridClass } = useStyle();
  return (
    <HTTPFetchRequest planogramId={planogramId}>
      {({ data, error, isFetching }): JSX.Element => {
        return (
          <>
            {isFetching && (
              <Grid className={gridClass} container direction="row" justify="center" alignItems="center">
                <CircularProgress />
              </Grid>
            )}
            {error && (
              <Grid className={gridClass} container direction="row" justify="center" alignItems="center">
                <Typography color="error">{error}</Typography>
              </Grid>
            )}
            {!isFetching && !error && data.length === 0 && (
              <Grid className={gridClass} container direction="row" justify="center" alignItems="center">
                <Typography color="primary">There are no inspiration images at the moment</Typography>
              </Grid>
            )}
            {!isFetching && !error && data.length > 0 && <ImagesList planogramId={planogramId} data={data} />}
          </>
        );
      }}
    </HTTPFetchRequest>
  );
};

export default InspirationImagesList;
