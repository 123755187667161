import { Grid, IconButton, Input, InputAdornment, LinearProgress } from '@material-ui/core';
import { Send } from '@material-ui/icons';
import React, { useState } from 'react';
interface InputAreaProps {
  isSubmitting: boolean;
  onSubmitMessage(message: string): void;
}

const InputArea = ({ isSubmitting, onSubmitMessage }: InputAreaProps): JSX.Element => {
  const [messageInput, setMessageInput] = useState('');

  const handleSubmit = (evt: any): void => {
    evt.preventDefault();
    if (messageInput === '') {
      return;
    } else {
      onSubmitMessage(messageInput);
      setMessageInput('');
    }
  };

  return (
    <Grid container style={{ marginTop: 10 }} direction="row" justify="space-between">
      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <Input
            key="input-message-field"
            inputRef={inputRef => inputRef && inputRef.focus()}
            autoFocus={true}
            color="primary"
            multiline
            fullWidth
            type="text"
            name="messageInput"
            style={{ wordWrap: 'break-word' }}
            disabled={isSubmitting}
            placeholder="Enter message..."
            onChange={(evt): void => setMessageInput(evt.target.value)}
            value={messageInput}
            endAdornment={
              <InputAdornment key="input-message-field-adornment" position="end">
                <IconButton key="input-message-field-icon-button" type="submit" disabled={isSubmitting}>
                  <Send key="input-message-field-send-icon" color="primary" fontSize="small" />
                </IconButton>
              </InputAdornment>
            }
          />
        </form>
      </Grid>
      {isSubmitting && (
        <Grid item xs={12}>
          <LinearProgress variant="indeterminate" />
        </Grid>
      )}
    </Grid>
  );
};

export default InputArea;
