import React from 'react';
import { useDispatch } from 'react-redux';
import Switch from '@material-ui/core/Switch';

import { enableShelfConditionRequest } from '../../actions';

export interface EnabledShelfConditionProps {
  planogramId: string;
  isShelfConditionsEnabled: boolean;
}

const EnabledShelfCondition: React.FC<EnabledShelfConditionProps> = ({ planogramId, isShelfConditionsEnabled }: EnabledShelfConditionProps): JSX.Element => {
  const dispatch = useDispatch();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(
      enableShelfConditionRequest({
        planogramId: planogramId,
        isShelfConditionsEnabled: event.target.checked,
      })
    );
  };

  return (
    <Switch
      checked={isShelfConditionsEnabled}
      onChange={handleChange}
      value="checkedB"
      color="primary"
      inputProps={{
        'aria-label': 'primary checkbox',
        title: `${isShelfConditionsEnabled ? 'Disable' : 'Enable'} Shelf Timelapse`,
      }}
    />
  );
};

export default EnabledShelfCondition;
