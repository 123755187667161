import * as React from 'react';
import { TableRow, TableCell, Typography, Tooltip, IconButton, Badge, Paper } from '@material-ui/core';
import ProjectStorePhaseShelfPlanograms from '../types/ProjectStorePhaseShelfPlanograms';
import ShelfPlanogramsModal from './modals/ShelfPlanogramsModal';
import { useState } from 'react';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { setShowBuildersForPlanogram, setShowTestsForPlanogram, setShowDeleteForPlanogram } from '../actions';

interface MyProps {
  shelf: ProjectStorePhaseShelfPlanograms;
  userId: number;
}

const ShelvesRow: React.FC<MyProps> = (props: MyProps): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(false);
  const shelf = props.shelf;
  const firstPlanogram = shelf.planograms[0];
  const dispatch = useDispatch();
  return (
    <React.Fragment key={shelf.shelfId}>
      <TableRow style={{ backgroundColor: 'white' }}>
        <TableCell align="center">
          {firstPlanogram.projectName === '' ? (
            <Typography variant="caption" color="textSecondary">
              (Hidden)
            </Typography>
          ) : (
            firstPlanogram.projectName
          )}
        </TableCell>
        <TableCell align="center">
          {firstPlanogram.storeName === '' ? (
            <Typography variant="caption" color="textSecondary">
              (Hidden)
            </Typography>
          ) : (
            firstPlanogram.storeName
          )}
        </TableCell>
        <TableCell align="center">
          {firstPlanogram.phaseNum === 0 ? (
            <Typography variant="caption" color="textSecondary">
              (Hidden)
            </Typography>
          ) : (
            firstPlanogram.phaseNum
          )}
        </TableCell>
        <TableCell align="center">
          {firstPlanogram.shelfNum === 0 ? (
            <Typography variant="caption" color="textSecondary">
              (Hidden)
            </Typography>
          ) : (
            firstPlanogram.shelfNum
          )}
        </TableCell>
        <TableCell align="center">
          <a href={firstPlanogram.imageUrl} target="_blank" rel="noopener noreferrer">
            <img style={{ maxWidth: 100, maxHeight: 30 }} src={firstPlanogram.imageUrl} alt="" />
          </a>
        </TableCell>
        <TableCell align="center">
          <Tooltip title="View Planograms">
            <div>
              <IconButton
                onClick={(): void => {
                  setIsExpanded(!isExpanded);
                }}
              >
                <Badge color="secondary" badgeContent={shelf.planograms.length}>
                  {isExpanded ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
                </Badge>
              </IconButton>
            </div>
          </Tooltip>
        </TableCell>
      </TableRow>
      {isExpanded && (
        <TableRow style={{ backgroundColor: '#fafafa' }}>
          <TableCell colSpan={100}>
            <Paper style={{ width: '50%', margin: 'auto' }}>
              <ShelfPlanogramsModal
                planograms={shelf.planograms}
                userId={props.userId}
                onDeletePlanogram={(planogramId: string): void => {
                  dispatch(setShowDeleteForPlanogram(planogramId));
                }}
                onViewBuilders={(planogramId: string): void => {
                  dispatch(setShowBuildersForPlanogram(planogramId));
                }}
                openTestListModal={(planogramId: string): void => {
                  dispatch(setShowTestsForPlanogram(planogramId));
                }}
              />
            </Paper>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};

export default ShelvesRow;
