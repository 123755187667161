import React from 'react';
import Image from './Image';
import { Grid } from '@material-ui/core';

export interface ImagesProps {
  images: string[];
}

const ListOfImages: React.FC<ImagesProps> = ({ images }: ImagesProps): JSX.Element => {
  const xs = 6;
  return (
    <Grid container>
      {images.map(
        (src, i: number): JSX.Element => (
          <Grid key={i} item xs={xs}>
            <Image src={src} />
          </Grid>
        )
      )}
    </Grid>
  );
};

export default ListOfImages;
