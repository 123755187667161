import { createSelector, Selector } from 'reselect';
import { State } from '../../../state';
import { InspirationImageItem, InspirationImagesState } from './types';
import { isLoading } from 'infrastructure/utils/RemoteObjectStatus';

const getState: (state: State) => InspirationImagesState = state => state.entities.inspirationImages;

export const getInspirationImages: Selector<State, InspirationImageItem[]> = createSelector(getState, state => {
  return state.data as InspirationImageItem[];
});

export const isFetching: Selector<State, boolean> = createSelector(getState, state => isLoading(state));

export const getFetchingError: Selector<State, string> = createSelector(getState, state => state.errorDetails?.errorMessage ?? '');
