import { createAction } from 'typesafe-actions';
import ErrorDetails from '../../../infrastructure/exception/ErrorDetails';
import {
  ActionTypesShelfCondition,
  FetchFailedAction,
  FetchRequestAction,
  FetchSuccessAction,
  SelectSalesReportAction,
  SetCarouselIndexAction,
  SetCarouselTimestampAction,
  ShelfConditionsResult,
  ShelfConditionsTarget,
  UpdateSelectedTimelapseWeekAction,
} from './types';
import { WeekDate } from 'types/CommonTypes';

export const fetchRequest: FetchRequestAction = createAction(ActionTypesShelfCondition.FETCH_REQUEST)<{
  targetId: string;
  targetType: ShelfConditionsTarget;
}>();

export const fetchSuccess: FetchSuccessAction = createAction(ActionTypesShelfCondition.FETCH_SUCCESS)<ShelfConditionsResult>();

export const fetchFailed: FetchFailedAction = createAction(ActionTypesShelfCondition.FETCH_FAILED)<ErrorDetails>();

export const selectSalesReport: SelectSalesReportAction = createAction(ActionTypesShelfCondition.SELECT_REPORT)<string>();

export const setCarouselIndex: SetCarouselIndexAction = createAction(ActionTypesShelfCondition.SET_CAROUSEL_INDEX)<number>();

export const setCarouselTime: SetCarouselTimestampAction = createAction(ActionTypesShelfCondition.SET_CAROUSEL_TIMESTAMP)<string>();

export const updateSelectedTimelapseWeek: UpdateSelectedTimelapseWeekAction = createAction(ActionTypesShelfCondition.UPDATE_SELECTED_TIMELAPSE_WEEK)<WeekDate>();
