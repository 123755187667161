import React from 'react';
import { Grid } from '@material-ui/core';
import Select from 'react-select';
import { SelectorOption } from '../types';

export interface BrandAndProductSelectorProps {
  selectedBrand: SelectorOption | undefined;
  selectedBrandOwner: SelectorOption | undefined;
  selectedProduct: SelectorOption | undefined;
  selectedGtin: SelectorOption | undefined;

  brands: SelectorOption[];
  brandOwners: SelectorOption[];
  products: SelectorOption[];
  gtins: SelectorOption[];

  onBrandSelected: (option: SelectorOption) => void;
  onBrandOwnerSelected: (option: SelectorOption) => void;
  onProductSelected: (option: SelectorOption) => void;
  onGtinSelected: (option: SelectorOption) => void;
}

const BrandAndProductSelector: React.FC<BrandAndProductSelectorProps> = (props: BrandAndProductSelectorProps) => {
  return (
    <Grid container direction="row" style={{ margin: '8px', padding: '16px' }} justify="flex-start" spacing={2}>
      <Grid item xs={12} style={{ width: '100%' }}>
        <Select
          styles={{
            // Fixes the overlapping problem of the component
            menu: provided => ({ ...provided, zIndex: 9999 }),
          }}
          placeholder="Select Brand owner"
          value={props.selectedBrandOwner ? props.selectedBrandOwner : null}
          options={props.brandOwners}
          onChange={(selected: SelectorOption): void => props.onBrandOwnerSelected(selected)}
          isDisabled={props.brandOwners.length === 0}
          isClearable={true}
          isSearchable={true}
          isMulti={false}
        />
      </Grid>
      <Grid item xs={12} style={{ width: '100%' }}>
        <Select
          styles={{
            // Fixes the overlapping problem of the component
            menu: provided => ({ ...provided, zIndex: 9999 }),
          }}
          placeholder="Select Brand"
          value={props.selectedBrand ? props.selectedBrand : null}
          options={props.brands}
          onChange={(selected: SelectorOption): void => props.onBrandSelected(selected)}
          isDisabled={props.brands.length === 0}
          isClearable={true}
          isSearchable={true}
          isMulti={false}
        />
      </Grid>
      <Grid item xs={12} style={{ width: '100%' }}>
        <Select
          styles={{
            // Fixes the overlapping problem of the component
            menu: provided => ({ ...provided, zIndex: 9999 }),
          }}
          placeholder="Select Product"
          value={props.selectedProduct ? props.selectedProduct : null}
          options={props.products}
          onChange={(selected: SelectorOption): void => props.onProductSelected(selected)}
          isDisabled={props.products.length === 0}
          isClearable={true}
          isSearchable={true}
          isMulti={false}
        />
      </Grid>
      <Grid item xs={12} style={{ width: '100%' }}>
        <Select
          styles={{
            // Fixes the overlapping problem of the component
            menu: provided => ({ ...provided, zIndex: 9999 }),
          }}
          placeholder="Select GTIN"
          value={props.selectedGtin ? props.selectedGtin : null}
          options={props.gtins}
          onChange={(selected: SelectorOption): void => props.onGtinSelected(selected)}
          isDisabled={props.gtins.length === 0}
          isClearable={true}
          isSearchable={true}
          isMulti={false}
        />
      </Grid>
    </Grid>
  );
};

export { BrandAndProductSelector };
