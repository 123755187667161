import ErrorDetails from 'infrastructure/exception/ErrorDetails';
import { TimeSalesData, TimeSalesDataReport } from './types';

export const SHELVES_TIMELINE_UPLOAD_SALES_REQUEST = 'shelvesTimeline/UPLOAD_SALES_REQUEST';
export const SHELVES_TIMELINE_UPLOAD_SALES_SUCCESS = 'shelvesTimeline/UPLOAD_SALES_SUCCESS';
export const SHELVES_TIMELINE_UPLOAD_SALES_ERROR = 'shelvesTimeline/UPLOAD_SALES_ERROR';

export interface UploadSalesDataRequestAction {
  type: typeof SHELVES_TIMELINE_UPLOAD_SALES_REQUEST;
  shelfReferenceId: string;
  reportName: string;
  data: TimeSalesData[];
}

export interface UploadSalesDataSuccessAction {
  type: typeof SHELVES_TIMELINE_UPLOAD_SALES_SUCCESS;
  newReport: TimeSalesDataReport;
}

export interface UploadSalesDataErrorAction {
  type: typeof SHELVES_TIMELINE_UPLOAD_SALES_ERROR;
  error: ErrorDetails;
}

export const SHELVES_TIMELINE_DELETE_SALES_DATA_REQUEST = 'shelvesTimeline/DELETE_SALES_DATA_REQUEST';

export interface DeleteSalesDataRequestAction {
  type: typeof SHELVES_TIMELINE_DELETE_SALES_DATA_REQUEST;
  shelfReferenceId: string;
  reportId: string;
}

export const SHELVES_TIMELINE_GET_SALES_DATA_REQUEST = 'shelvesTimeline/GET_SALES_DATA_REQUEST';
export const SHELVES_TIMELINE_GET_SALES_DATA_SUCCESS = 'shelvesTimeline/GET_SALES_DATA_SUCCESS';
export const SHELVES_TIMELINE_GET_SALES_DATA_ERROR = 'shelvesTimeline/GET_SALES_DATA_ERROR';

export interface GetSalesDataRequestAction {
  type: typeof SHELVES_TIMELINE_GET_SALES_DATA_REQUEST;
  shelfReferenceId: string;
}

export interface GetSalesDataSuccessAction {
  type: typeof SHELVES_TIMELINE_GET_SALES_DATA_SUCCESS;
  data: TimeSalesDataReport[];
}

export interface GetSalesDataErrorAction {
  type: typeof SHELVES_TIMELINE_GET_SALES_DATA_ERROR;
  error: ErrorDetails;
}
