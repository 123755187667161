import {
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  Link,
  Table,
  TableCell,
  TableBody,
  TableHead,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Build, Close, ErrorOutline, Flag, People, Warning, Visibility } from '@material-ui/icons';
import { formatDistanceToNow } from 'date-fns';
import * as React from 'react';
import { Alert, Row } from 'reactstrap';
import trackComponent from 'infrastructure/tracking/withTracking';
import { StyledTableCell, StyledTableRow } from '../../../../scss/MaterialStyles';
import BuilderPlanogramView from '../../types/BuilderPlanogramView';
import PlanogramView from '../../types/PlanogramView';
import EditBuilderRulesModal from './EditBuilderRulesModal';
import TestListModal from './TestListModal';
import { isError, isLoading, RemoteObjectStatusInterface } from 'infrastructure/utils/RemoteObjectStatus';

interface BuildersListModalProps {
  planogram: PlanogramView;
  builderPlanograms: RemoteObjectStatusInterface<BuilderPlanogramView[]>;
  selectedBuilderPlanogram: BuilderPlanogramView;
  isUpdatingBuilderRules: boolean;
  isUpdatingBuilderRulesSuccess: boolean;
  isUpdatingBuilderRulesFailure: boolean;

  onClose(): void;
}

interface BuildersListModalState {
  showEditBuilderRulesModal: boolean;
  orderByProperty: string;
  orderByDescending: boolean;
  showTestListModal: boolean;
  selectedPlanogram?: BuilderPlanogramView;
}

class BuildersListModal extends React.Component<BuildersListModalProps & DispatchProps, BuildersListModalState> {
  constructor(props: BuildersListModalProps & DispatchProps, state: BuildersListModalState) {
    super(props, state);
    this.state = {
      showEditBuilderRulesModal: false,
      orderByProperty: 'name',
      orderByDescending: false,
      showTestListModal: false,
    };
  }

  componentDidMount(): void {
    this.props.loadBuilderPlanogramsRequest(this.props.planogram);
  }

  onShowEditBuilderRulesModal(selectedBuilderPlanogram: BuilderPlanogramView): void {
    this.setState({
      showEditBuilderRulesModal: true,
    });
    this.props.updateSelectedBuilderPlanogram(selectedBuilderPlanogram);
  }

  render(): JSX.Element {
    return (
      <Dialog maxWidth="xl" open={true} onClose={this.props.onClose}>
        <DialogTitle>
          <Typography variant="overline" gutterBottom>
            <People color="primary" fontSize="large" />
            &nbsp; Builder Planograms
          </Typography>
          <Divider light variant="middle" />
        </DialogTitle>
        <DialogContent>{isError(this.props.builderPlanograms) ? this.renderErrorMessage() : !isLoading(this.props.builderPlanograms) ? this.renderBuilderPlanogramsList() : null}</DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={this.props.onClose} className="pull-right">
            <Close />
            &nbsp; Close
          </Button>
        </DialogActions>
        {this.state.showEditBuilderRulesModal && this.props.selectedBuilderPlanogram !== undefined ? (
          <EditBuilderRulesModal
            onClose={(): void => {
              this.setState({ showEditBuilderRulesModal: false });
              this.props.restLoadUpdatingBuilderRulesStatus();
            }}
            builderPlanogram={this.props.selectedBuilderPlanogram}
            updateBuilderRulesRequest={(builderPlanogram: BuilderPlanogramView): void => this.props.updateBuilderRulesRequest(builderPlanogram)}
            isUpdatingBuilderRules={this.props.isUpdatingBuilderRules}
            isUpdatingBuilderRulesSuccess={this.props.isUpdatingBuilderRulesSuccess}
            isDialogOpen={this.state.showEditBuilderRulesModal}
          />
        ) : null}
        {this.renderTestListModal()}
        {isLoading(this.props.builderPlanograms) ? <LinearProgress color="primary" /> : null}
      </Dialog>
    );
  }

  renderBuilderPlanogramsList(): JSX.Element {
    if (isLoading(this.props.builderPlanograms)) {
      return <div>Loading...</div>;
    }
    if (this.props.builderPlanograms !== undefined && this.props.builderPlanograms.data?.length !== 0) {
      return (
        <Table size="small" className="sticky-header-table">
          <TableHead>
            <tr>
              <TableCell>Email</TableCell>
              <TableCell align="center">Last Opened</TableCell>
              <TableCell align="center">View</TableCell>
              <TableCell align="center">Tests</TableCell>
              <TableCell align="center">Edit Rules</TableCell>
            </tr>
          </TableHead>
          <TableBody>{this.renderBuilderPlanogramRow()}</TableBody>
        </Table>
      );
    } else {
      return (
        <Alert color="warning">
          <Row>
            <Warning />
            &nbsp; There are no Builders for this Planogram.
          </Row>
        </Alert>
      );
    }
  }

  renderBuilderPlanogramRow(): JSX.Element[] {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const data = this.props.builderPlanograms.data ?? ([] as BuilderPlanogramView[]);
    return data.map(builderPlanogram => {
      return (
        <StyledTableRow key={builderPlanogram.id}>
          <StyledTableCell>{builderPlanogram.email}</StyledTableCell>
          <StyledTableCell align="center">
            {builderPlanogram.lastOpenedByBuilder ? <span>{formatDistanceToNow(builderPlanogram.lastOpenedByBuilder, { addSuffix: true })}</span> : 'Never'}
          </StyledTableCell>
          <StyledTableCell align="center">
            <Link href={'/planograms/' + builderPlanogram.id} target="_blank">
              <Tooltip title="View Builder Planogram">
                <IconButton>
                  <Visibility color="secondary" />
                </IconButton>
              </Tooltip>
            </Link>
          </StyledTableCell>
          <StyledTableCell align="center">
            <Tooltip title="View Shelf Tests">
              <IconButton
                onClick={(): void =>
                  this.setState({
                    selectedPlanogram: builderPlanogram,
                    showTestListModal: true,
                  })
                }
              >
                <Badge color="primary" badgeContent={builderPlanogram.testInfoList.length} showZero={true}>
                  <Flag color="secondary" />
                </Badge>
              </IconButton>
            </Tooltip>
          </StyledTableCell>
          <StyledTableCell align="center">
            <Tooltip title="Edit Builder's Rules">
              <IconButton
                onClick={(): void => {
                  return this.onShowEditBuilderRulesModal(builderPlanogram);
                }}
              >
                <Build color="secondary" />
              </IconButton>
            </Tooltip>
          </StyledTableCell>
        </StyledTableRow>
      );
    });
  }

  renderErrorMessage(): JSX.Element {
    return (
      <Alert color="danger">
        <Row>
          <ErrorOutline />
          &nbsp; There seems to be an error with loading builders for this planogram. Please try again later.
        </Row>
      </Alert>
    );
  }

  renderTestListModal(): JSX.Element | null {
    if (this.state.showTestListModal && this.state.selectedPlanogram) {
      return (
        <TestListModal
          testInfoList={this.state.selectedPlanogram.testInfoList}
          goal={this.state.selectedPlanogram.goal}
          planogramName={this.state.selectedPlanogram.name}
          onClose={(): void => this.setState({ showTestListModal: false })}
        />
      );
    } else {
      return null;
    }
  }
}

interface DispatchProps {
  loadBuilderPlanogramsRequest(planogram: PlanogramView): void;

  updateBuilderRulesRequest(builderPlanogram: BuilderPlanogramView): void;

  updateSelectedBuilderPlanogram(builderPlanogram: BuilderPlanogramView): void;
  restLoadUpdatingBuilderRulesStatus(): void;
}

export default trackComponent(BuildersListModal, 'PlanogramsListPage_BuildersListModal');
