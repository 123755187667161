import React from 'react';
import { FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Switch, Typography } from '@material-ui/core';
import { FieldArray, FieldArrayRenderProps } from 'formik';
import { DataInsightType } from '../../../types/DataInsightType';

export interface InsightsProps {
  name: string;
}

const Insights: React.FC<InsightsProps> = ({ name }: InsightsProps): JSX.Element => {
  return (
    <FormControl>
      <FormLabel>
        <Typography variant="overline">Provide insights from</Typography>
      </FormLabel>
      <FormGroup>
        <FieldArray name={name}>
          {({ remove, push, form }: FieldArrayRenderProps): JSX.Element[] => {
            return Object.keys(DataInsightType)
              .filter(i => i !== DataInsightType.ALL && i !== DataInsightType.NONE)
              .map(type => {
                const values = name.split('.').reduce((o, i) => o[i], form.values) as string[];
                return (
                  <FormControlLabel
                    key={type}
                    control={
                      <Switch
                        checked={values ? values.includes(type) : false}
                        data-testid={`dataInsights-${name}-${type}`}
                        color="primary"
                        onChange={(e): void => {
                          if (e.target.checked) {
                            push(DataInsightType[type]);
                          } else {
                            const indexForRemove = values.findIndex(item => item === DataInsightType[type]);
                            remove(indexForRemove);
                          }
                        }}
                      />
                    }
                    label={`${type.substring(0, 1)}${type.substring(1).toLowerCase()} Data`}
                  />
                );
              });
          }}
        </FieldArray>
        <FormHelperText>Data selected above will be used to provide product level insights.</FormHelperText>
      </FormGroup>
    </FormControl>
  );
};

export default Insights;
