import { useSelector, useDispatch } from 'react-redux';

import { AutomatedActionsSelectors, gethighlightedGtinBy } from '../../../../../selectors';
import { onHighlightProduct } from '../../../../../actions';

export interface UseHighLightedGtin {
  highLightAction: () => void;
  isHighLightActionClearState: boolean;
}

const useHighLightedGtin = (selector: AutomatedActionsSelectors, highlightedGtinBy = ''): UseHighLightedGtin => {
  const gtinArr: number[] = useSelector(selector);
  const fromStateHighLightedGtinBy = useSelector(gethighlightedGtinBy);
  const isHighLightActionClearState = fromStateHighLightedGtinBy === highlightedGtinBy;
  const dispatch = useDispatch();

  return {
    isHighLightActionClearState,
    highLightAction: () => {
      dispatch(isHighLightActionClearState ? onHighlightProduct(true) : onHighlightProduct(true, gtinArr, highlightedGtinBy));
    },
  };
};

export default useHighLightedGtin;
