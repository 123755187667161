import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';

interface SubmitBtnProps {
  onClick: () => void;
  isFormValid: boolean;
  isSubmitting: boolean;
}

const SubmitBtn: React.FC<SubmitBtnProps> = ({ onClick, isFormValid, isSubmitting }: SubmitBtnProps): JSX.Element => {
  const text = isSubmitting ? 'Uploading' : 'Upload';
  const icon = isSubmitting ? <CircularProgress size={20} /> : <CloudUploadIcon />;
  return (
    <>
      <Button onClick={onClick} disabled={!isFormValid || isSubmitting} startIcon={icon} variant="contained" color="primary" data-testid="upload-inspiration-images-btn">
        {text}
      </Button>
    </>
  );
};

export default SubmitBtn;
