import { Button, Card, CardActions, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, LinearProgress, Switch, Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { Close, FilterNone, Info, Link as LinkIcon, Share } from '@material-ui/icons';
import * as React from 'react';
import * as CopyToClipboard from 'react-copy-to-clipboard';
import { toastr } from 'react-redux-toastr';
import { Row } from 'reactstrap';
import trackComponent from 'infrastructure/tracking/withTracking';
import PlanogramModel from '../../domain/PlanogramModel';

interface SharedModelState {
  isEnabled: boolean;
}

class ShareModal extends React.Component<Props & DispatchProps, SharedModelState> {
  constructor(props: Props & DispatchProps) {
    super(props);
    this.state = {
      isEnabled: this.props.planogram.sharingOptions.publicLinkEnabled,
    };
  }

  render(): JSX.Element {
    const planogram = this.props.planogram;
    return (
      <Dialog fullWidth={true} open={true} onClose={this.props.onClose}>
        <DialogTitle>
          <Typography variant="overline" gutterBottom>
            <Share color="primary" fontSize="large" />
            &nbsp; Share planogram
          </Typography>
          <Divider light variant="middle" />
        </DialogTitle>
        <DialogContent>{this.renderSharingSwitch(planogram)}</DialogContent>
        <DialogActions>
          {planogram.sharingOptions.publicLinkEnabled && planogram.sharingOptions.publicToken !== '' ? (
            <CopyToClipboard
              text={window.location.origin + '/planograms/' + planogram.id + '/share/' + planogram.sharingOptions.publicToken}
              onCopy={(): void => {
                return toastr.success('Copied!', 'Planogram link has been copied to your clipboard.');
              }}
            >
              <Button variant="contained" color="primary" disabled={this.props.isTogglingPublicShare}>
                <FilterNone />
                &nbsp; Copy link
              </Button>
            </CopyToClipboard>
          ) : null}
          <Button variant="contained" onClick={this.props.onClose} color="secondary">
            <Close />
            &nbsp; Close
          </Button>
        </DialogActions>
        {this.props.isTogglingPublicShare ? <LinearProgress color="primary" /> : null}
      </Dialog>
    );
  }

  renderSharingSwitch(planogram: PlanogramModel): JSX.Element {
    return (
      <React.Fragment>
        <FormControlLabel
          value="top"
          control={
            <Switch
              color="primary"
              value={planogram.sharingOptions.publicLinkEnabled}
              checked={planogram.sharingOptions.publicLinkEnabled}
              onChange={this.props.onTogglePublicShare}
              disabled={this.props.isTogglingPublicShare}
            />
          }
          label={planogram.sharingOptions.publicLinkEnabled ? <Typography variant="button">Public sharing: ON</Typography> : <Typography variant="button"> Public sharing: OFF</Typography>}
          labelPlacement="end"
        />

        {planogram.sharingOptions.publicLinkEnabled && planogram.sharingOptions.publicToken !== '' ? this.renderSharingLink(planogram) : null}
      </React.Fragment>
    );
  }

  renderSharingLink(planogram: PlanogramModel): JSX.Element {
    return (
      <Card raised={true}>
        <CardContent>
          <LinkIcon color="primary" /> &nbsp;
          <Link color="primary" href={window.location.origin + '/planograms/' + planogram.id + '/share/' + planogram.sharingOptions.publicToken} target="_blank">
            <Typography style={{ overflowWrap: 'break-word' }} variant="subtitle2">
              {window.location.origin + '/planograms/' + planogram.id + '/share/' + planogram.sharingOptions.publicToken}
            </Typography>
          </Link>
        </CardContent>
        <CardActions>
          <Row className="left-buffer-sm">
            <Info fontSize="small" color="primary" />
            &nbsp;
            <Typography color="textSecondary" variant="caption">
              <i>Planograms accessed via this link are in view-only mode.</i>
              <br />
              <i>Data and editing capabilities will not be available.</i>
            </Typography>
          </Row>
        </CardActions>
      </Card>
    );
  }
}

interface DispatchProps {
  onClose(): void;

  onTogglePublicShare(): void;
}

interface Props {
  planogram: PlanogramModel;
  isTogglingPublicShare: boolean;
}

export default trackComponent(ShareModal, 'PlanogramPage_ShareModal');
