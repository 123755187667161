import apiClient, { ApiResponse } from 'utils/apiClient';
import { AggregatedMetrics, AggregatedOnePagerReport, AvailableAggregatedReports } from './types';

export const getAvailableAggregatedReportsRequest = (): Promise<ApiResponse<AvailableAggregatedReports>> => {
  return apiClient.get(`reports/aggregatedreports/availablereports`);
};

export const downloadAggregatedReportRequest = (): Promise<any> => {
  return apiClient.get(`reports/aggregatedreports/availablereports/download`, {
    responseType: 'blob',
  });
};

export const getAggregatedProductReport = (gtin: number, metrics: AggregatedMetrics[]): Promise<ApiResponse<AggregatedOnePagerReport>> => {
  const query = metrics.map(m => `metrics=${AggregatedMetrics[m]}`).join('&');
  return apiClient.get(`reports/aggregatedreports/product/${gtin}?${query}`);
};

export const getAggregatedBrandReport = (brandId: number, metrics: AggregatedMetrics[]): Promise<ApiResponse<AggregatedOnePagerReport>> => {
  const query = metrics.map(m => `metrics=${AggregatedMetrics[m]}`).join('&');
  return apiClient.get(`/reports/aggregatedreports/brand/${brandId}?${query}`);
};

export const getAggregatedBrickReport = (gpcBrickCode: number, metrics: AggregatedMetrics[]): Promise<ApiResponse<AggregatedOnePagerReport>> => {
  const query = metrics.map(m => `metrics=${AggregatedMetrics[m]}`).join('&');
  return apiClient.get(`/reports/aggregatedreports/brick/${gpcBrickCode}?${query}`);
};

export const getAggregatedBrandBrickReport = (brandId: number, gpcBrickCode: number, metrics: AggregatedMetrics[]): Promise<ApiResponse<AggregatedOnePagerReport>> => {
  const query = metrics.map(m => `metrics=${AggregatedMetrics[m]}`).join('&');
  return apiClient.get(`/reports/aggregatedreports/brand/${brandId}/brick/${gpcBrickCode}?${query}`);
};
