import { TableCell, TableRow, withStyles } from '@material-ui/core';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
export const customTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#D1C4E9',
      main: '#673AB7',
      dark: '#512DA8',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#f50056',
      contrastText: '#FFFFFF',
    },
  },
});

export const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 12,
  },
}))(TableCell);

export const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);
