import { Grid, Switch, Typography } from '@material-ui/core';
import trackComponent from 'infrastructure/tracking/withTracking';
import { isLoaded } from 'infrastructure/utils/RemoteObjectStatus';
import PlanogramState from 'modules/planogram/state';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { State } from 'state';
import * as actions from '../../actions';
type MyProps = PlanogramState & DispatchProps;

class CompetitorPlanogramFooter extends React.Component<MyProps, any> {
  isDataUnavailable() {
    return !this.props.competitorShelfDataset || this.props.competitorShelfDataset.items.length === 0;
  }

  render() {
    if (!isLoaded(this.props.loadPlanogramStatus)) {
      return null;
    }
    return (
      <React.Fragment>
        <div className="no-print">
          <Typography style={{ margin: '8px' }} className="col-xs-4 col-xs-offset-2" variant="overline" color="primary">
            {this.props.planogram?.name}
          </Typography>
          <Grid direction="row" container justify="space-between" alignItems="center">
            <Typography variant="caption">{this.isDataUnavailable() ? 'Data Unavailable' : this.props.competitorShelfDataset.datasetName}</Typography>
            <Switch
              disabled={this.isDataUnavailable()}
              color="primary"
              size="medium"
              checked={this.props.enableCompetitorShelfData}
              onChange={() => this.props.toggleEnableCompetitorShelfDataReport()}
            />
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: State, ownProps: any) {
  return {
    ...state.planogram,
    id: ownProps.match.params.id,
    shelfReferenceId: ownProps.match.params.shelfReferenceId,
  };
}

interface DispatchProps {
  toggleEnableCompetitorShelfDataReport(): void;
}

const componentToExport = connect<PlanogramState, DispatchProps, RouteComponentProps<any>>(mapStateToProps, actions)(CompetitorPlanogramFooter);

export default trackComponent(componentToExport, 'CompetitorPlanogramFooter');
