import React from 'react';
import MUISlider, { Mark } from '@material-ui/core/Slider';

export interface SliderProps {
  marks: Mark[];
  handleOnChange: (e: React.SyntheticEvent, index: number) => void;
  selectedIndex: number;
}

const Slider: React.FC<SliderProps> = ({ marks, handleOnChange, selectedIndex }: SliderProps): JSX.Element => {
  return <MUISlider aria-labelledby="shelf-condition-slider-restrict" step={null} marks={marks} max={marks.length} onChange={handleOnChange} valueLabelDisplay="off" value={selectedIndex} />;
};

export default Slider;
