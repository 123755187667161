import { Popper } from '@material-ui/core';
import {
  deleteMessageInConversationRequestAction,
  openRealogramConversationPopperAction,
  postMessageToConversationRequestAction,
  updateMessageInConversationRequestAction,
} from 'modules/realogram/actions';
import * as realogramSelectors from 'modules/realogram/selectors';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeTimelinesMenuAction } from '../actions';
import { getSelectedShelfPhaseDetails } from '../selectors';
import TimelineItemMenuContent from './TimelineItemMenuContent';
export interface TimelineItemMenuProps {
  isOpen: boolean;
  anchorEl: Element;
}
export const TimelineItemMenu: React.FC<TimelineItemMenuProps> = ({ isOpen, anchorEl }: TimelineItemMenuProps) => {
  const dispatch = useDispatch();
  const selectedShelfPhase = useSelector(getSelectedShelfPhaseDetails);
  const [isShelfConditionsOpen, setIsShelfConditionsOpen] = useState(false);
  const isConversationPopperOpen = useSelector(realogramSelectors.isOpenConversationPopper);
  const conversationPopperAnchorEl = useSelector(realogramSelectors.conversationPopperAnchorEl);
  const conversations = useSelector(realogramSelectors.getRealogramConversationData);

  const getConversationMessagesLength = (): number => {
    const conversationData = conversations.find(x => x.shelfReferenceId === selectedShelfPhase?.shelfReferenceId);
    if (!conversationData) {
      return 0;
    } else {
      return conversationData.conversationMessages.length;
    }
  };

  const handleOpenShelfConditionsModal = (): void => {
    setIsShelfConditionsOpen(true);
  };
  const handleCloseShelfConditionsModal = (): void => {
    setIsShelfConditionsOpen(false);
  };

  const handleOpenConversationPopper = (refElement: Element): void => {
    dispatch(openRealogramConversationPopperAction(refElement));
  };

  const handleOnSubmitMessage = (message: string): void => {
    if (selectedShelfPhase) {
      dispatch(postMessageToConversationRequestAction(selectedShelfPhase.shelfReferenceId, message));
    }
  };

  const handleOnEditMessage = (shelfReferenceId: string, messageId: string, userId: number, editedMessage: string): void => {
    dispatch(updateMessageInConversationRequestAction(shelfReferenceId, messageId, userId, editedMessage));
  };

  const handleOnSubmitDeleteMessage = (messageId: string): void => {
    if (selectedShelfPhase) {
      dispatch(deleteMessageInConversationRequestAction(selectedShelfPhase.shelfReferenceId, messageId));
    }
  };
  const handleOnCloseTimelinesMenu = (): void => {
    const shouldCloseMenu = !isShelfConditionsOpen && !isConversationPopperOpen;
    if (shouldCloseMenu) {
      dispatch(closeTimelinesMenuAction());
    }
  };
  return (
    <>
      {isOpen && anchorEl && selectedShelfPhase && (
        <>
          <Popper id="timeline-menu-popper" open={isOpen} anchorEl={anchorEl} placement="bottom" style={{ zIndex: 1100 }}>
            <TimelineItemMenuContent
              isOpen={isOpen}
              onClose={(): { type: string } => dispatch(closeTimelinesMenuAction())}
              projectId={selectedShelfPhase.projectId}
              storeId={selectedShelfPhase.storeId}
              phaseNum={selectedShelfPhase.phaseNum}
              shelfNum={selectedShelfPhase.shelfNum}
              projectName={selectedShelfPhase.projectName}
              storeName={selectedShelfPhase.storeName}
              shelfImageUrl={selectedShelfPhase.shelfImageUrl}
              shelfReferenceId={selectedShelfPhase.shelfReferenceId}
              isReadyForReporting={selectedShelfPhase.isReadyForReporting}
              projectType={selectedShelfPhase.projectType}
              isShelfConditionsOpen={isShelfConditionsOpen}
              isConversationPopperOpen={isConversationPopperOpen}
              conversationPopperAnchorEl={conversationPopperAnchorEl}
              conversationMessagesLength={getConversationMessagesLength()}
              handleOpenShelfConditionsModal={handleOpenShelfConditionsModal}
              handleCloseShelfConditionsModal={handleCloseShelfConditionsModal}
              handleOpenConversationPopper={handleOpenConversationPopper}
              handleOnSubmitMessage={(message: string): void => handleOnSubmitMessage(message)}
              handleOnEditMessage={(shelfReferenceId: string, messageId: string, userId: number, editedMessage: string): void =>
                handleOnEditMessage(shelfReferenceId, messageId, userId, editedMessage)
              }
              handleOnSubmitDeleteMessage={(messageId: string): void => {
                handleOnSubmitDeleteMessage(messageId);
              }}
              handleOnCloseTimelinesMenu={(): void => handleOnCloseTimelinesMenu()}
            />
          </Popper>
        </>
      )}
    </>
  );
};
