import { Card, CardContent, CardHeader, Divider, FormControl, IconButton, Input, InputLabel, MenuItem, Select, Table, TableBody, TableHead, Tooltip, Typography } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp, Grain, Info } from '@material-ui/icons';
import { formatDistanceToNow } from 'date-fns';
import { orderBy, sortBy, uniq } from 'lodash';
import * as React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import Alert from 'reactstrap/lib/Alert';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import trackComponent from 'infrastructure/tracking/withTracking';
import { StyledTableCell, StyledTableRow } from 'scss/MaterialStyles';
import { State } from 'state';
import * as actions from '../actions';
import AdminUsersState from '../state';
import UserForAdminViewModel from '../types/UserForAdminViewModel';
import UserPlanogramsModal from './UserPlanogramsModal';
import { isError, isLoading, isLoaded } from 'infrastructure/utils/RemoteObjectStatus';

interface UsersPageState {
  orderByProperty: string;
  orderByDescending: boolean;
  selectedEmails: string[];
  showPlanogramsForUser?: UserForAdminViewModel;
}

class UsersPage extends React.Component<AdminUsersState & DispatchProps, UsersPageState> {
  constructor(props: AdminUsersState & DispatchProps) {
    super(props);
    this.state = {
      orderByDescending: true,
      orderByProperty: 'planogramsCount',
      selectedEmails: [],
    };
  }

  changeOrderBy(propertyName: string) {
    const descending = this.state.orderByProperty === propertyName ? !this.state.orderByDescending : false;
    this.setState({
      orderByProperty: propertyName,
      orderByDescending: descending,
    });
  }

  getOrderByIcon(propertyName: string) {
    if (this.state.orderByProperty !== propertyName) {
      return null;
    }

    if (this.state.orderByDescending) {
      return <ArrowDropDown />;
    } else {
      return <ArrowDropUp />;
    }
  }

  getUsers() {
    let users =
      this.state.selectedEmails.length === 0
        ? this.props.loadAdminUsersStatus.data
        : this.props.loadAdminUsersStatus.data?.filter(u => {
            const indexOfAt = u?.userEmail?.indexOf('@');
            const email = u?.userEmail?.substring(indexOfAt + 1);
            return this.state.selectedEmails.indexOf(email) > -1;
          });
    users = orderBy(users, [this.state.orderByProperty], [this.state.orderByDescending ? 'desc' : 'asc']);
    return users;
  }

  componentDidMount() {
    this.props.loadAdminUsersRequest();
  }

  render() {
    return (
      <div className="animated fadeIn" style={{ marginTop: '1.5rem' }}>
        {isError(this.props.loadAdminUsersStatus) ? (
          this.renderErrorLoading()
        ) : (
          <LoadingOverlay active={isLoading(this.props.loadAdminUsersStatus)} spinner={true} text="Loading users...">
            <Row>
              <Col xs="12">
                <Card raised={true} className="top-buffer-sm">
                  <CardHeader title="Filter" titleTypographyProps={{ color: 'primary' }} />
                  <Divider light variant="middle" />
                  <CardContent>{this.renderFilteringForm()}</CardContent>
                </Card>
                <Card className="top-buffer" raised={true}>
                  <CardHeader title="Planogram Users" />
                  <Divider light variant="middle" />
                  <CardContent>{this.renderPlanogramsTable()}</CardContent>
                </Card>
              </Col>
            </Row>
          </LoadingOverlay>
        )}
        {this.state.showPlanogramsForUser === undefined ? null : (
          <UserPlanogramsModal
            isError={isError(this.props.loadAdminUserPlanagramsStatus)}
            isLoaded={isLoaded(this.props.loadAdminUserPlanagramsStatus)}
            isLoading={isLoading(this.props.loadAdminUserPlanagramsStatus)}
            user={this.state.showPlanogramsForUser}
            planograms={this.props.loadAdminUserPlanagramsStatus.data ?? []}
            onClosed={() => this.setState({ showPlanogramsForUser: undefined })}
            loadUserPlanogramsReqeust={this.props.loadAdminUserPlanogramsRequest}
          />
        )}
      </div>
    );
  }

  renderErrorLoading(): JSX.Element {
    return (
      <div>
        <Alert color="danger">
          <strong>Error loading users. Please try again later.</strong>
        </Alert>
      </div>
    );
  }

  renderPlanogramsTable(): JSX.Element | null {
    if (isLoading(this.props.loadAdminUsersStatus)) {
      return null;
    }
    if (!this.props.loadAdminUsersStatus.data?.length) {
      return (
        <Alert color="warning">
          <Row>
            <Info color="primary" />
            &nbsp;
            <Typography color="textSecondary" variant="body2">
              No users found.
            </Typography>
          </Row>
        </Alert>
      );
    }
    return (
      <Table size="medium" className="sticky-header-table">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell style={{ cursor: 'pointer' }} align="center" onClick={() => this.changeOrderBy('userId')}>
              <Typography variant="subtitle1">
                User ID
                {this.getOrderByIcon('userId')}
              </Typography>
            </StyledTableCell>
            <StyledTableCell style={{ cursor: 'pointer' }} align="center" onClick={() => this.changeOrderBy('userEmail')}>
              <Typography variant="subtitle1">
                User Email
                {this.getOrderByIcon('userEmail')}
              </Typography>
            </StyledTableCell>
            <StyledTableCell style={{ cursor: 'pointer' }} align="center" onClick={() => this.changeOrderBy('planogramsCount')}>
              <Typography variant="subtitle1">
                No. of Planograms
                {this.getOrderByIcon('planogramsCount')}
              </Typography>
            </StyledTableCell>
            <StyledTableCell style={{ cursor: 'pointer' }} align="center" onClick={() => this.changeOrderBy('ownerPlanogramsCount')}>
              <Typography variant="subtitle1">
                Owner Planograms
                {this.getOrderByIcon('ownerPlanogramsCount')}
              </Typography>
            </StyledTableCell>
            <StyledTableCell style={{ cursor: 'pointer' }} align="center" onClick={() => this.changeOrderBy('lastDateTimeActive')}>
              <Typography variant="subtitle1">
                Last Active
                {this.getOrderByIcon('lastDateTimeActive')}
              </Typography>
            </StyledTableCell>
            <StyledTableCell />
          </StyledTableRow>
        </TableHead>
        <TableBody>{this.renderUsers()}</TableBody>
      </Table>
    );
  }

  renderUsers() {
    const users = this.getUsers();
    return users.map((user: UserForAdminViewModel) => {
      return (
        <StyledTableRow key={user.userId}>
          <StyledTableCell align="center">{user.userId}</StyledTableCell>
          <StyledTableCell align="center">{user.userEmail}</StyledTableCell>
          <StyledTableCell align="center">{user.planogramsCount}</StyledTableCell>
          <StyledTableCell align="center">{user.ownerPlanogramsCount}</StyledTableCell>
          <StyledTableCell align="center">{user.lastDateTimeActive ? <span>{formatDistanceToNow(user.lastDateTimeActive, { addSuffix: true })}</span> : 'Never'}</StyledTableCell>
          <StyledTableCell>
            <Tooltip title="View Planograms">
              <div>
                <IconButton
                  onClick={() => {
                    this.setState({
                      showPlanogramsForUser: user,
                    });
                  }}
                >
                  <Grain color="secondary" />
                </IconButton>
              </div>
            </Tooltip>
          </StyledTableCell>
        </StyledTableRow>
      );
    });
  }

  renderFilteringForm() {
    let emails = uniq(
      this.props.loadAdminUsersStatus.data?.map(u => {
        if (u.userEmail == null) {
          return '';
        }
        const indexOfAt = u.userEmail.indexOf('@');
        return u.userEmail.substring(indexOfAt + 1);
      })
    );
    emails = sortBy(emails, [email => email]);
    const menuItems = emails.map(email => {
      return (
        <MenuItem key={email} value={email}>
          {email}
        </MenuItem>
      );
    });
    return (
      <div className="row">
        <div className="col-sm-6 col-md-3 col-lg-2">
          <FormControl fullWidth={true}>
            <InputLabel htmlFor="email-filter">Emails</InputLabel>
            <Select
              fullWidth={true}
              multiple
              value={this.state.selectedEmails}
              input={<Input fullWidth={true} id="email-filter" />}
              onChange={(event: any) => {
                this.setState({ selectedEmails: event.target.value });
              }}
            >
              {menuItems}
            </Select>
          </FormControl>
        </div>
      </div>
    );
  }
}

interface DispatchProps {
  loadAdminUsersRequest(): void;
  loadAdminUserPlanogramsRequest(userId: number): void;
}

function mapStateToProps(state: State) {
  return {
    ...state.adminUsers,
  };
}

const componentToExport = connect<AdminUsersState, DispatchProps, RouteComponentProps<any>>(mapStateToProps, actions)(UsersPage);

export default trackComponent(componentToExport, 'UsersPage');
