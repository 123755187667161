import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormGroup, LinearProgress, TextField, Tooltip, Typography } from '@material-ui/core';
import { AttachFile, Close, CloudUpload, GetApp, InsertDriveFile } from '@material-ui/icons';
import { orderBy } from 'lodash';
import React from 'react';
import { toastr } from 'react-redux-toastr';
import Select from 'react-select';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import trackComponent from 'infrastructure/tracking/withTracking';
import * as XLSX from 'xlsx';
import { ClientDto } from '../types/ClientDto';
import { ClientDatasetDto, DataItem } from './../types/ClientDatasetDto';

class ClientOption {
  value = 0;
  label = '';
}
interface ImportDatasetModalState {
  selectedClientOption: ClientOption | null;
  clientOptions: ClientOption[];
  datasetName: string;
  selectedExcelFile: any;
}
class ImportDatasetModal extends React.Component<ImportDatasetModalProps, ImportDatasetModalState> {
  constructor(props: ImportDatasetModalProps, state: ImportDatasetModalState) {
    super(props, state);
    const clientOptions: ClientOption[] = orderBy(this.props.clients, 'name').map(client => ({
      value: client.id,
      label: client.name,
    }));

    this.state = {
      selectedClientOption: null,
      clientOptions,
      datasetName: '',
      selectedExcelFile: null,
    };
  }

  hasEnteredRequiredInputs() {
    return this.state.selectedClientOption && this.state.datasetName && this.state.selectedExcelFile;
  }

  onChangeSelectedClient(selectedOption: ClientOption) {
    this.setState({ selectedClientOption: selectedOption });
  }

  onSelectFile(event: React.ChangeEvent<HTMLInputElement>): void {
    if (event && event.target && event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      if (file.name.includes('.xls') || file.name.includes('.xlsx') || file.name.includes('.csv')) {
        this.setState({
          selectedExcelFile: file,
        });
      } else {
        toastr.error('File not permitted.', 'Please select CSV, XLS or XLSX files only');
      }
    }
  }

  generateExcelTemplate() {
    const wb = XLSX.utils.book_new();
    wb.SheetNames.push('Sheet 1');
    const opts = {
      header: ['GTIN', 'Value'],
    };

    const data = [
      {
        GTIN: '1',
        Value: '100',
      },
    ];

    const ws = XLSX.utils.json_to_sheet(data, opts);
    wb.Sheets['Sheet 1'] = ws;
    XLSX.writeFile(wb, 'Client Dataset.xlsx');
  }

  onUploadClientDataset() {
    if (!this.state.selectedClientOption) {
      return;
    }
    const clientDataset = new ClientDatasetDto();
    clientDataset.name = this.state.datasetName;
    clientDataset.clientId = this.state.selectedClientOption.value;
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const clientDataXlsx = XLSX.utils.sheet_to_json(sheet);
      const dataset: DataItem[] = [];
      try {
        clientDataXlsx.forEach((cd: any) => {
          const keys = Object.keys(cd);
          const dataItem = new DataItem();
          dataItem.gtin = cd[keys[0]];
          dataItem.value = cd[keys[1]];
          if (dataItem.gtin === undefined || dataItem.value === undefined) {
            // if empty value ignore
            return;
          }
          if (typeof dataItem.gtin === 'string' || typeof dataItem.value === 'string') {
            // if string, throw error
            throw new Error('string detected');
          }
          dataset.push(dataItem);
        });
        clientDataset.dataItems = dataset;
        this.props.uploadClientDataset(clientDataset);
      } catch (err) {
        toastr.error('Incorrect data format', 'String data detected, make sure excel file has data in number format');
      }
    };
    reader.readAsArrayBuffer(this.state.selectedExcelFile);
  }

  render() {
    return (
      <Row>
        <Col xs="12">
          <Dialog fullWidth open={true} onClose={this.props.onClose}>
            <DialogTitle>
              <Typography variant="overline" gutterBottom>
                <GetApp color="primary" />
                &nbsp; Import Client Dataset
              </Typography>
              <Divider light variant="middle" />
            </DialogTitle>

            <DialogContent style={{ paddingBottom: '200px' }}>
              <FormGroup>
                <Tooltip title="Generate sample client dataset in expected format">
                  <div>
                    <Button size="small" variant="outlined" color="secondary" onClick={() => this.generateExcelTemplate()}>
                      <InsertDriveFile />
                      &nbsp;Generate Excel Template
                    </Button>
                  </div>
                </Tooltip>
              </FormGroup>
              <FormGroup className="top-buffer">
                <TextField
                  autoFocus={true}
                  fullWidth={true}
                  variant="outlined"
                  label="Dataset Name"
                  disabled={this.props.isUploadingClientDataset}
                  required
                  value={this.state.datasetName}
                  onChange={evt => this.setState({ datasetName: evt.target.value })}
                />
              </FormGroup>
              <FormGroup className="top-buffer">
                <Select
                  inputId="clientAutocompleteSelect"
                  placeholder="Select a client"
                  options={this.state.clientOptions}
                  value={this.state.selectedClientOption}
                  onChange={(selectedOption: ClientOption) => this.onChangeSelectedClient(selectedOption)}
                  isMulti={false}
                  isDisabled={this.props.clients.length === 0}
                />
              </FormGroup>
              <FormGroup className="top-buffer">
                <input accept=".xlsx, .xls, .csv" style={{ display: 'none' }} id="selectExcelFileBtn" type="file" onChange={e => this.onSelectFile(e)} />
                <label htmlFor="selectExcelFileBtn">
                  <Tooltip title="Choose an excel or csv file">
                    <Button fullWidth variant="contained" color="primary" component="span">
                      <AttachFile />
                      &nbsp; Select excel file
                    </Button>
                  </Tooltip>
                </label>
                {this.state.selectedExcelFile ? 'Selected file: ' + this.state.selectedExcelFile.name : 'No file chosen.'}
              </FormGroup>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                disabled={this.props.isUploadingClientDataset || !this.hasEnteredRequiredInputs()}
                className="pull-left"
                onClick={() => {
                  this.onUploadClientDataset();
                }}
              >
                <CloudUpload />
                &nbsp;
                {this.props.isUploadingClientDataset ? 'Uploading...' : 'Upload'}
              </Button>
              <Button variant="contained" color="secondary" onClick={this.props.onClose} className="pull-right">
                <Close />
                &nbsp; Cancel
              </Button>
            </DialogActions>
            {this.props.isUploadingClientDataset ? <LinearProgress color="primary" /> : null}
          </Dialog>
        </Col>
      </Row>
    );
  }
}
interface ImportDatasetModalProps {
  clients: ClientDto[];
  isUploadingClientDataset: boolean;
  uploadClientDataset(clientDataset: ClientDatasetDto): void;
  onClose(): void;
}

export default trackComponent(ImportDatasetModal, 'ImportDatasetModal');
