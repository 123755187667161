import React from 'react';
import Select from 'react-select';
import { ShelfOption } from '../../../reports/types';

export interface ShelfSelectorProps {
  htmlId: string;
  shelfOptions: ShelfOption[];
  selectedShelf?: ShelfOption;
  handleOnSelectShelf(selectedShelf: ShelfOption): void;
}

export const ShelfSelector: React.FC<ShelfSelectorProps> = ({ htmlId, shelfOptions, selectedShelf, handleOnSelectShelf }: ShelfSelectorProps) => {
  const defaultShelfOption: ShelfOption = {
    label: 'Select Shelf',
    storeName: '',
    projectId: -1,
    storeId: -1,
    shelfNum: -1,
    value: -1,
  };
  return (
    <Select
      styles={{
        // Fixes the overlapping problem of the component
        menu: provided => ({ ...provided, zIndex: 9999 }),
      }}
      inputId={htmlId}
      placeholder="Select Shelf"
      options={shelfOptions}
      value={selectedShelf === undefined ? defaultShelfOption : selectedShelf}
      onChange={(shelf: ShelfOption): void => handleOnSelectShelf(shelf)}
      isMulti={false}
      isDisabled={shelfOptions.length === 0}
    />
  );
};
