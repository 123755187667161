import React from 'react';
import { Grid } from '@material-ui/core';
import Select from 'react-select';
import { SelectorOption } from '../types';
export interface GpcSelectorProps {
  selectedFamily: SelectorOption | undefined;
  selectedClass: SelectorOption | undefined;
  selectedBrick: SelectorOption | undefined;
  familyOptions: SelectorOption[];
  classOptions: SelectorOption[];
  brickOptions: SelectorOption[];

  onFamilySelected: (option: SelectorOption) => void;
  onClassSelected: (option: SelectorOption) => void;
  onBrickSelected: (option: SelectorOption) => void;
}

const GpcSelector: React.FC<GpcSelectorProps> = (props: GpcSelectorProps) => {
  return (
    <Grid container direction="row" style={{ margin: '8px', padding: '16px' }} justify="flex-start" spacing={2}>
      <Grid item xs={12} style={{ width: '100%' }}>
        <Select
          styles={{
            // Fixes the overlapping problem of the component
            menu: provided => ({ ...provided, zIndex: 9999 }),
          }}
          placeholder="Select Family"
          value={props.selectedFamily ? props.selectedFamily : null}
          options={props.familyOptions}
          onChange={(selected: SelectorOption): void => props.onFamilySelected(selected)}
          isDisabled={props.familyOptions.length === 0}
          isClearable={true}
          isSearchable={true}
          isMulti={false}
        />
      </Grid>
      <Grid item xs={12} style={{ width: '100%' }}>
        <Select
          styles={{
            // Fixes the overlapping problem of the component
            menu: provided => ({ ...provided, zIndex: 9999 }),
          }}
          placeholder="Select Class"
          value={props.selectedClass ? props.selectedClass : null}
          options={props.classOptions}
          onChange={(selected: SelectorOption): void => props.onClassSelected(selected)}
          isDisabled={props.classOptions.length === 0}
          isClearable={true}
          isSearchable={true}
          isMulti={false}
        />
      </Grid>
      <Grid item xs={12} style={{ width: '100%' }}>
        <Select
          styles={{
            // Fixes the overlapping problem of the component
            menu: provided => ({ ...provided, zIndex: 9999 }),
          }}
          placeholder="Select Brick"
          value={props.selectedBrick ? props.selectedBrick : null}
          options={props.brickOptions}
          onChange={(selected: SelectorOption): void => props.onBrickSelected(selected)}
          isDisabled={props.brickOptions.length === 0}
          isClearable={true}
          isSearchable={true}
          isMulti={false}
        />
      </Grid>
    </Grid>
  );
};

export { GpcSelector };
