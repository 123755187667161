import adminClients from 'modules/admin/clientDataset/sagas';
import adminPlanograms from 'modules/admin/planograms/sagas';
import adminUsers from 'modules/admin/users/sagas';
import auth from 'modules/auth/sagas';
import planogram from 'modules/planogram/sagas';
import planograms from 'modules/planograms/sagas';
import watchRealogramPage from 'modules/realogram/sagas';
import { SagaIterator } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import inspirationImages from './modules/entities/inspirationImages/sagas';
import shelfConditionImages from './modules/shelfTimelapse/state/sagas';
import watchTimelinesSaga from './modules/timelines/sagas';
import watchShelfTimelapseSalesData from 'modules/shelfTimelapse/aside/SalesDataManagement/sagas';
import watchEulaSaga from 'modules/eula/sagas';
import watchStandardReportsSaga from 'modules/reports/standardReports/saga';
import watchAggregatedReportsSaga from 'modules/reports/aggregatedReports/saga';

export default function* sagas(): SagaIterator {
  yield all([
    fork(planograms),
    fork(planogram),
    fork(adminUsers),
    fork(adminPlanograms),
    fork(auth),
    fork(adminClients),
    fork(shelfConditionImages),
    fork(inspirationImages),
    fork(watchTimelinesSaga),
    fork(watchRealogramPage),
    fork(watchStandardReportsSaga),
    fork(watchAggregatedReportsSaga),
    fork(watchShelfTimelapseSalesData),
    fork(watchEulaSaga),
  ]);
}
