import React from 'react';
import { Card, CardContent, Typography, Divider, Grid, Tooltip } from '@material-ui/core';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import HelpIcon from '@material-ui/icons/Help';
import DelistBtn from './ButtonsContainers/DelistBtn';
import DesignPriceChangeBtn from './ButtonsContainers/DesignPriceChangeBtn';
import PrimeLocationBtn from './ButtonsContainers/PrimeLocationBtn';
import ShelfLocationBtn from './ButtonsContainers/ShelfLocationBtn';
import ResizeBtn from './ButtonsContainers/ResizeBtn';
import { toolTipsAnimatedActions as tooltips } from '../../../../../constants/toolTipsDefinitions';

const AutomatedActionsCard: React.FC = (): JSX.Element => {
  return (
    <Card raised className="top-buffer">
      <CardContent style={{ padding: '8px' }}>
        <Typography color="primary" variant="subtitle2">
          <AccountBalanceWalletIcon fontSize="small" />
          &nbsp; FLOW Wizard
        </Typography>
        <Divider component="hr" className="top-buffer bottom-buffer" light={true} variant="fullWidth" />
        <Grid component="div" direction="row" container justify="space-between" alignItems="center">
          <Grid container component="div" direction="row" justify="space-between" alignItems="center">
            <Grid item xs={7}>
              <Typography variant="caption">Adjust space to unit sales</Typography>
            </Grid>
            <Grid item xs={5}>
              <ResizeBtn />
              <Tooltip title={tooltips.salesResizePercent}>
                <HelpIcon className="float-right" color="primary" fontSize="small" />
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container component="div" direction="row" justify="space-between" alignItems="center">
            <Grid item xs={7}>
              <Typography variant="caption">Consider delisting</Typography>
            </Grid>
            <Grid item xs={5}>
              <DelistBtn />
              <Tooltip title={tooltips.delist}>
                <HelpIcon className="float-right" color="primary" fontSize="small" />
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container component="div" direction="row" justify="space-between" alignItems="center">
            <Grid item xs={7}>
              <Typography variant="caption">Ensure prime location</Typography>
            </Grid>
            <Grid item xs={5}>
              <PrimeLocationBtn />
              <Tooltip title={tooltips.primeLocation}>
                <HelpIcon className="float-right" color="primary" fontSize="small" />
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container component="div" direction="row" justify="space-between" alignItems="center">
            <Grid item xs={7}>
              <Typography variant="caption">Can be placed anywhere</Typography>
            </Grid>
            <Grid item xs={5}>
              <ShelfLocationBtn />
              <Tooltip title={tooltips.anyShelfLocation}>
                <HelpIcon className="float-right" color="primary" fontSize="small" />
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container component="div" direction="row" justify="space-between" alignItems="center">
            <Grid item xs={7}>
              <Typography variant="caption">Consider design improvement</Typography>
            </Grid>
            <Grid item xs={5}>
              <DesignPriceChangeBtn />
              <Tooltip title={tooltips.designPriceChange}>
                <HelpIcon className="float-right" color="primary" fontSize="small" />
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AutomatedActionsCard;
