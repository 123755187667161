import React from 'react';
import { useResize } from '../useResize';

import { Button } from '@material-ui/core';

import { getCurrentPlanogram, getInitialPlanogram, getShelfLocationsData } from '../../../../../../selectors';
import { useSelector } from 'react-redux';

const ResizeBtn: React.FC = (): JSX.Element => {
  const { onAutoResizeForSalesRequest } = useResize();
  const planogram = useSelector(getCurrentPlanogram);
  const initialPlanogram = useSelector(getInitialPlanogram);
  const shelfLocationsData = useSelector(getShelfLocationsData);
  return (
    <Button
      color="primary"
      size="small"
      disabled={shelfLocationsData === undefined || shelfLocationsData === null}
      onClick={(): void => {
        onAutoResizeForSalesRequest(planogram, initialPlanogram, shelfLocationsData);
      }}
    >
      Resize
    </Button>
  );
};

export default ResizeBtn;
