import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadTimelinesInCurrentYearRequestAction } from '../actions';
import { getTimelinesLoadError, isTimelinesDataEmpty, isTimelinesLoading, getViewAs } from '../selectors';

interface ChildrenParams {
  isLoading: boolean;
  loadError: string;
  isDataEmpty: boolean;
}

export interface TimelinesLoaderProps {
  children: (params: ChildrenParams) => React.ReactNode;
}

export const TimelinesLoader: React.FC<TimelinesLoaderProps> = ({ children }: TimelinesLoaderProps) => {
  const dispatch = useDispatch();
  const loadError = useSelector(getTimelinesLoadError);
  const isDataEmpty = useSelector(isTimelinesDataEmpty);
  const isLoading = useSelector(isTimelinesLoading);
  const viewAs = useSelector(getViewAs);

  useEffect(() => {
    dispatch(loadTimelinesInCurrentYearRequestAction(viewAs));
  }, [dispatch, viewAs]);

  return (
    <>
      {children({
        isLoading,
        loadError,
        isDataEmpty,
      })}
    </>
  );
};
