import React from 'react';
import { Checkbox, FormControlLabel, FormGroup, Typography } from '@material-ui/core';
import { Field, FieldProps } from 'formik';

export interface PlanogramRulesProps {
  namePrefix: string;
}

const PlanogramRules: React.FC<PlanogramRulesProps> = ({ namePrefix }: PlanogramRulesProps): JSX.Element => {
  return (
    <>
      <div className="top-buffer">
        <Typography variant="overline">Planogram rules:</Typography>
      </div>
      <FormGroup>
        <FormControlLabel
          control={
            <Field name={`${namePrefix}.canAddNewShelfLocations`}>
              {({ field }: FieldProps): JSX.Element => {
                return <Checkbox data-testid={`${namePrefix}.canAddNewShelfLocations`} name={field.name} checked={field.value} onChange={field.onChange} color="primary" />;
              }}
            </Field>
          }
          label="Can add new product"
        />
        <FormControlLabel
          control={
            <Field name={`${namePrefix}.canTestWithFlow`}>
              {({ field }: FieldProps): JSX.Element => {
                return <Checkbox data-testid={`${namePrefix}.canTestWithFlow`} name={field.name} checked={field.value} onChange={field.onChange} color="primary" />;
              }}
            </Field>
          }
          label="Can request shelf tests with Flow Insights"
        />
        <FormControlLabel
          control={
            <Field name={`${namePrefix}.canViewProjectInfo`}>
              {({ field }: FieldProps): JSX.Element => {
                return <Checkbox data-testid={`${namePrefix}.canViewProjectInfo`} name={field.name} checked={field.value} onChange={field.onChange} color="primary" />;
              }}
            </Field>
          }
          label="Show project and store info"
        />
        <FormControlLabel
          control={
            <Field name={`${namePrefix}.mustStateChangeReasons`}>
              {({ field }: FieldProps): JSX.Element => {
                return <Checkbox data-testid={`${namePrefix}.mustStateChangeReasons`} name={field.name} checked={field.value} onChange={field.onChange} color="primary" />;
              }}
            </Field>
          }
          label="Enforce stating reasons for critical changes"
        />
        <FormControlLabel
          control={
            <Field name={`${namePrefix}.canViewShelfReport`}>
              {({ field }: FieldProps): JSX.Element => {
                return <Checkbox data-testid={`${namePrefix}.canViewShelfReport`} name={field.name} checked={field.value} onChange={field.onChange} color="primary" />;
              }}
            </Field>
          }
          label="Can View Shelf Reports"
        />
      </FormGroup>
    </>
  );
};

export default PlanogramRules;
