import { OperationsInfoDto } from './OperationsInfoDto';
import { TestReportDataDto } from './TestReportDataDto';
import { Type } from 'class-transformer';

export default class TestInfoDto {
  id = '';
  testName = '';
  initialPlanogramId = '';
  createdOn: Date = new Date();
  shelfImageUrl = '';

  @Type(() => OperationsInfoDto)
  operationsInfo: OperationsInfoDto = new OperationsInfoDto();

  @Type(() => TestReportDataDto)
  testReportData: TestReportDataDto = new TestReportDataDto();
  createdBy = '';
}
