import { Button, Card, CardContent, Divider, Fab, FormGroup, Grid, Input, Tooltip, Typography } from '@material-ui/core';
import { CloudUpload, InsertDriveFile } from '@material-ui/icons';
import { isLoading } from 'infrastructure/utils/RemoteObjectStatus';
import { StoreOpenHours } from 'modules/shelfTimelapse/state/types';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getOperationalHourTimestamps } from 'utils/dateHelper';
import * as XLSX from 'xlsx';
import { getProjectDates, getStoreOpeningHours } from '../../state/selectors';
import * as actions from './actions';
import { getUploadSalesDataStatus } from './selectors';
import { TimeSalesData } from './types';

// Generates an excel sheet with timestamps containing every hour of store operation for every day within the Project duration
function generateExcelTemplate(projectStartDate: string | undefined, projectEndDate: string | undefined, storeOpeningHours: StoreOpenHours[] | undefined): void {
  const wb = XLSX.utils.book_new();
  wb.SheetNames.push('Sheet 1');
  const opts = {
    header: ['Time', 'Value'],
  };
  const data: { Time: string; Value: string }[] = [];
  if (projectStartDate && projectEndDate && storeOpeningHours) {
    const hourlyOperationalTimestamps = getOperationalHourTimestamps(projectStartDate, projectEndDate, storeOpeningHours);
    hourlyOperationalTimestamps.forEach(timestamp => {
      data.push({
        Time: timestamp,
        Value: '',
      });
    });
  }
  const ws = XLSX.utils.json_to_sheet(data, opts);
  wb.Sheets['Sheet 1'] = ws;
  XLSX.writeFile(wb, 'Sales Data.xlsx');
}

const Form: React.FC = (): JSX.Element => {
  const { shelfReferenceId } = useParams();
  const uploadStatus = useSelector(getUploadSalesDataStatus);
  const [selectedDataFromExcel, setSelectedDataFromExcel] = useState<TimeSalesData[]>([]);
  const [reportName, setReportName] = useState<string>('');
  const { projectStartDate, projectEndDate } = useSelector(getProjectDates);
  const storeOpeningHours = useSelector(getStoreOpeningHours);
  const dispatch = useDispatch();
  const isUploading = isLoading(uploadStatus);
  return (
    <>
      <Grid container>
        <Grid item>
          <Card style={{ paddingTop: '8px', paddingBottom: '8px' }}>
            <CardContent style={{ paddingTop: '8px', paddingBottom: '8px' }}>
              <Typography color="primary" variant="subtitle1">
                Upload Sales Report
              </Typography>
              <Divider className="top-buffer bottom-buffer" light={true} variant="fullWidth" />
              <form>
                <FormGroup className="top-buffer">
                  <Tooltip title="Generate sample excel file with pre-populated product IDs">
                    <div>
                      <Button
                        style={{ fontSize: '10px' }}
                        disabled={isUploading}
                        size="small"
                        variant="outlined"
                        color="secondary"
                        onClick={(): void => generateExcelTemplate(projectStartDate, projectEndDate, storeOpeningHours)}
                      >
                        Generate Report Template &nbsp;
                        <InsertDriveFile />
                      </Button>
                    </div>
                  </Tooltip>
                </FormGroup>
                <FormGroup className="top-buffer">
                  <Input
                    placeholder="Report Name"
                    disabled={isUploading}
                    value={reportName}
                    onChange={(e): void => {
                      setReportName(e.target.value);
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    disabled={isUploading}
                    style={{ margin: '7px 0' }}
                    id="salesdataexcelfile"
                    type="file"
                    onChange={(eChange: React.ChangeEvent<HTMLInputElement>): void => {
                      if (!eChange || !eChange.target || !eChange.target.files || !eChange.target.files[0]) {
                        return;
                      }
                      eChange.preventDefault();
                      const reader = new FileReader();
                      reader.onload = (e: ProgressEvent<FileReader>): void => {
                        if (!e || !e.target || !e.target.result) {
                          return;
                        }
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const data = new Uint8Array(e.target.result as any);
                        const workbook = XLSX.read(data, { type: 'array' });
                        const sheet = workbook.Sheets[workbook.SheetNames[0]];
                        const salesDataXlsx = XLSX.utils.sheet_to_json(sheet);
                        const salesData: TimeSalesData[] = [];
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        salesDataXlsx.forEach((sd: any) => {
                          const keys = Object.keys(sd);
                          const value = parseFloat(sd[keys[1]]);
                          const saleD = {
                            timestamp: new Date(sd[keys[0]]),
                            value: isNaN(value) ? undefined : value,
                          } as TimeSalesData;
                          salesData.push(saleD);
                        });
                        setSelectedDataFromExcel(salesData);
                      };
                      reader.readAsArrayBuffer(eChange.target.files[0]);
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <Fab
                    disabled={isUploading}
                    onClick={(): void => {
                      if (reportName.length === 0) {
                        alert('Please select report name');
                        return;
                      }
                      if (selectedDataFromExcel.length === 0) {
                        alert('Please select file with data.');
                        return;
                      }
                      dispatch(actions.uploadSalesDataRequest(shelfReferenceId as string, reportName, selectedDataFromExcel));
                    }}
                    size="medium"
                    variant="extended"
                    type="button"
                    color="primary"
                  >
                    {isUploading ? 'Uploading...' : 'Upload'}
                    <CloudUpload />
                  </Fab>
                </FormGroup>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default Form;
