import ShelfLocation from '../domain/ShelfLocation';
import Rainbow from 'rainbowvis.js';
import { SalesDataModel } from '../types/SalesDataModel';

interface ShelfData {
  values: number[];
  min: number;
  max: number;
  spectrum: string[];
  canFill: boolean;
}

/**
 * Creates a dictionary with a key for each gtin and value is summed up for that gtin from all occurances in salesData.
 * If GTIN is missing in the salesData, there will be no value in the dictionary for it.
 * @param gtins Array of gtin numbers to be used
 * @param salesData The data to be used
 * @returns Data object with a dictionary with gtin number as key.
 */
function generateDataForGtin(gtins: number[], salesData: SalesDataModel[]): ShelfData {
  const values = {};
  let min = Infinity;
  let max = -Infinity;
  let canFill = true;
  const spectrum = ['ff0000', 'fffa00', '17ff00'];

  gtins.forEach(gtin => {
    const gtinSalesData = salesData.filter(sd => sd.gtin === gtin);
    if (gtinSalesData.length === 0) {
      return;
    }
    const totalSum = gtinSalesData.reduce((sum: number, sd: SalesDataModel) => {
      return sum + (isNaN(sd.value) ? 0 : sd.value);
    }, 0);
    if (isNaN(totalSum)) {
      return;
    }
    min = Math.min(min, totalSum);
    max = Math.max(max, totalSum);
    values[gtin] = totalSum;
  });

  if (min === Infinity || max === -Infinity) {
    canFill = false;
  }

  if (min === max) {
    min = max - 1;
  }

  return {
    values,
    min,
    max,
    spectrum,
    canFill,
  } as ShelfData;
}

/**
 * Adds reporting data to given shelf locations.
 * @param shelfLocations The shelf locations to apply report data to.
 * @param salesData Data to use
 */
export function prepareSalesDataReport(shelfLocations: ShelfLocation[], salesData: SalesDataModel[]): void {
  const gtins = shelfLocations.map(sl => sl.gtin);
  const data = generateDataForGtin(gtins, salesData);
  const rainbow = new Rainbow();
  if (data.canFill) {
    rainbow.setSpectrum(...data.spectrum);
    rainbow.setNumberRange(data.min, data.max);
  }
  shelfLocations.forEach(sl => {
    if (data.values[sl.gtin]) {
      sl.color = rainbow.colourAt(data.values[sl.gtin]);
      sl.displayText = data.values[sl.gtin].toFixed(0);
    } else {
      sl.color = null;
      sl.displayText = null;
      sl.isHighlighted = false;
    }
  });
}
