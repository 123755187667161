import { isLoaded, isLoading } from 'infrastructure/utils/RemoteObjectStatus';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { loadAggregatedBrandBrickReportRequestAction } from '../../actions';
import { getAggregatedBrandBrickReportLoadState, getAggregatedBrandBrickReportData, isAggregatedBrandBrickReportEmpty } from '../../selectors';
import { AggregatedMetrics, AggregatedOnePagerReport } from '../../types';

interface LoadStatusParams {
  isLoaded: boolean;
  isLoading: boolean;
  loadError: string;
  isDataEmpty: boolean;
  data: AggregatedOnePagerReport | undefined;
}

export interface AggregatedBrandBrickReportLoaderProps {
  children: (params: LoadStatusParams) => React.ReactNode;
}

const AggregatedBrandBrickReportLoader: React.FC<AggregatedBrandBrickReportLoaderProps> = ({ children }: AggregatedBrandBrickReportLoaderProps) => {
  type RequestParamsType = {
    brandId: string;
    gpcBrickCode: string;
    metrics: string;
  };

  const { brandId, gpcBrickCode, metrics } = useParams<RequestParamsType>();
  const dispatch = useDispatch();
  const loadState = useSelector(getAggregatedBrandBrickReportLoadState);
  const isDataEmpty = useSelector(isAggregatedBrandBrickReportEmpty);
  const data = useSelector(getAggregatedBrandBrickReportData);
  useEffect(() => {
    if (isDataEmpty && brandId && gpcBrickCode && metrics !== undefined) {
      const selectedMetrics = metrics.split(',').map(x => parseInt(x)) as AggregatedMetrics[];
      dispatch(loadAggregatedBrandBrickReportRequestAction(parseInt(brandId), parseInt(gpcBrickCode), selectedMetrics));
    }
  }, [dispatch, isDataEmpty, brandId, gpcBrickCode, metrics]);
  return (
    <>
      {children({
        isLoaded: isLoaded(loadState),
        isLoading: isLoading(loadState),
        loadError: loadState?.errorDetails?.errorMessage ?? '',
        isDataEmpty,
        data,
      })}
    </>
  );
};

export { AggregatedBrandBrickReportLoader };
