import { isLoading, RemoteObjectStatusInterface } from 'infrastructure/utils/RemoteObjectStatus';
import { last } from 'lodash';
import { ProductDetails } from 'modules/shared/types';
import { createSelector, Selector } from 'reselect';
import { State } from '../../state';
import PlanogramModel from './domain/PlanogramModel';
import PlanogramState from './state';
import { FlowDataShelfLocationReportResult } from './types/FlowDataReportResult';
import { ProductInfoData } from './types/ProductInfoData';
import { FlowDataReportType } from './types/ReportingTypes';
import ViewPort from './types/ViewPort';

const getPlanogramState = (state: State): PlanogramState => state.planogram;

const getShelfLocationDataState = (state: State): FlowDataShelfLocationReportResult[] => state.planogram.flowDataReport?.data?.shelfLocationsReport ?? ([] as FlowDataShelfLocationReportResult[]);

export type AutomatedActionsSelectors = Selector<State, number[]>;

const reduceAutomatedAction = (propName: string) => {
  return (arr: number[], item: FlowDataShelfLocationReportResult): number[] => {
    if (item[propName]) {
      return [...arr, ...[item.gtin]];
    }
    return arr;
  };
};

export const getShouldDelistGtinArr: AutomatedActionsSelectors = createSelector(getShelfLocationDataState, state => {
  return state.reduce(reduceAutomatedAction('shouldDelist'), []);
});

export const getNeedsPrimeLocationGtinArr: AutomatedActionsSelectors = createSelector(getShelfLocationDataState, state => {
  return state.reduce(reduceAutomatedAction('needsPrimeLocation'), []);
});

export const getCanHaveAnyShelfLocationGtinArr: AutomatedActionsSelectors = createSelector(getShelfLocationDataState, state => {
  return state.reduce(reduceAutomatedAction('canHaveAnyShelfLocation'), []);
});

export const getNeedsDesignOrPriceChangeGtinArr: AutomatedActionsSelectors = createSelector(getShelfLocationDataState, state => {
  return state.reduce(reduceAutomatedAction('needsDesignOrPriceChange'), []);
});

export const gethighlightedGtinBy: Selector<State, string | undefined> = createSelector(getPlanogramState, (state: PlanogramState) => state.highlightedGtinBy);

export const getIsEnableShelfCondition: Selector<State, boolean> = createSelector(getPlanogramState, (state: PlanogramState) => {
  const planogam = state.planogram as PlanogramModel;
  return planogam.shelfConditions.isShelfConditionsEnabled;
});

export const getIsEnabledInspirationImages: Selector<State, boolean> = createSelector(getPlanogramState, state => {
  const planogram = state.planogram as PlanogramModel;
  return planogram.inspirationImages.isInspirationImagesEnabled;
});

export const getCurrentPlanogram: Selector<State, PlanogramModel> = createSelector(getPlanogramState, state => {
  const planogram = state.planogram as PlanogramModel;
  return planogram;
});

export const getInitialPlanogram: Selector<State, PlanogramModel> = createSelector(getPlanogramState, state => {
  const initialPlanogram = state.initialPlanogram as PlanogramModel;
  return initialPlanogram;
});

export const getShelfLocationsData: Selector<State, FlowDataShelfLocationReportResult[]> = createSelector(getPlanogramState, state => {
  return state.flowDataReport.data?.shelfLocationsReport ?? ([] as FlowDataShelfLocationReportResult[]);
});

export const getImageLoadingStatus: Selector<State, RemoteObjectStatusInterface<ViewPort>> = createSelector(getPlanogramState, state => {
  return state.loadImageStatus;
});

export const getPlanogramLoadingStatus: Selector<State, RemoteObjectStatusInterface<{}>> = createSelector(getPlanogramState, state => {
  return state.loadPlanogramStatus;
});

export const getSelectedProductInfo: Selector<State, ProductInfoData | null> = createSelector(getPlanogramState, state => {
  const shelfLocation = last(state.selectedShelfLocations);

  if (!shelfLocation) return null;

  const details = ((state.products?.find(p => p.gtin === shelfLocation.gtin) as unknown) as ProductDetails) ?? ({} as ProductDetails);
  details.price = shelfLocation.consumerPriceLocalCurrency;

  const shelfLocationData = state.flowDataReport.data?.shelfLocationsReport?.find(slReport => slReport.gtin === shelfLocation.gtin && slReport.shelfLocation === shelfLocation.code) ?? null;
  const shelfLocationPairRankingData =
    state.flowDataReport.data?.shelfLocationsPairRankingsReport?.filter(slReport => slReport.gtinTarget === shelfLocation.gtin && slReport.shelfLocationTarget === shelfLocation.code) || [];

  let selectedMetricsName = '';
  if (state.enableFlowDataReport) {
    selectedMetricsName = state.selectedFlowDataType as FlowDataReportType;
  } else if (state.enableSalesDataReport) {
    selectedMetricsName = 'Sales Data';
  } else if (state.enableClientDatasetReport) {
    selectedMetricsName = 'Client Data';
  }

  return {
    planogramId: state.id,
    shelfLocation,
    details,
    shelfLocationData,
    shelfLocationPairRankingData,
    selectedMetricsName,
    isFixingImageSize: isLoading(state.loadFixingImageSizeStatus),
  } as ProductInfoData;
});
