import { isLoaded, isLoading } from 'infrastructure/utils/RemoteObjectStatus';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { loadStandardGpcBrickComparisonReportRequestAction } from '../../actions';
import * as selectors from '../../selectors';
import { GpcBrickComparisonReportResult } from '../../types';
interface LoadStatusParams {
  isLoaded: boolean;
  isLoading: boolean;
  loadError: string;
  isDataEmpty: boolean;
  data: GpcBrickComparisonReportResult | undefined;
}
export interface StandardGpcBrickComparisonReportLoaderProps {
  children: (params: LoadStatusParams) => React.ReactNode;
}

const StandardGpcBrickComparisonReportLoader: React.FC<StandardGpcBrickComparisonReportLoaderProps> = ({ children }: StandardGpcBrickComparisonReportLoaderProps) => {
  type RequestParamsType = {
    shelfPhaseId1: string;
    shelfPhaseId2: string;
    gpcBrickCode1: string;
    gpcBrickCode2: string;
  };

  const { shelfPhaseId1, shelfPhaseId2, gpcBrickCode1, gpcBrickCode2 } = useParams<RequestParamsType>();
  const dispatch = useDispatch();
  const loadState = useSelector(selectors.getGpcBrickComparisonReportLoadState);
  const isDataEmpty = useSelector(selectors.isGpcBrickComparisonReportEmpty);
  const data = useSelector(selectors.getGpcBrickComparisonReportData);
  useEffect(() => {
    if (isDataEmpty && shelfPhaseId1 && shelfPhaseId2 && gpcBrickCode1 && gpcBrickCode2) {
      dispatch(loadStandardGpcBrickComparisonReportRequestAction(shelfPhaseId1, shelfPhaseId2, parseInt(gpcBrickCode1), parseInt(gpcBrickCode2)));
    }
  }, [dispatch, isDataEmpty, shelfPhaseId1, shelfPhaseId2, gpcBrickCode1, gpcBrickCode2]);
  return (
    <>
      {children({
        isLoaded: isLoaded(loadState),
        isLoading: isLoading(loadState),
        loadError: loadState?.errorDetails?.errorMessage ?? '',
        isDataEmpty,
        data,
      })}
    </>
  );
};

export { StandardGpcBrickComparisonReportLoader };
