import * as constants from './constants';
import UserForAdminViewModel from './types/UserForAdminViewModel';
import UserPlanogramForAdminViewModel from './types/UserPlanogramForAdminViewModel';

export const loadAdminUsersRequest = () => ({
  type: constants.ADMIN_USERS_LOAD_REQUEST,
});

export const loadAdminUsersError = () => ({
  type: constants.ADMIN_USERS_LOAD_ERROR,
});

export const loadAdminUsersSuccess = (users: UserForAdminViewModel[]) => ({
  type: constants.ADMIN_USERS_LOAD_SUCCESS,
  users,
});

export const loadAdminUserPlanogramsRequest = (userId: number) => ({
  type: constants.ADMIN_USER_PLANOGRAMS_LOAD_REQUEST,
  userId,
});

export const loadAdminUserPlanogramsSuccess = (planograms: UserPlanogramForAdminViewModel[]) => ({
  type: constants.ADMIN_USER_PLANOGRAMS_LOAD_SUCCESS,
  planograms,
});

export const loadAdminUserPlanogramsError = () => ({
  type: constants.ADMIN_USER_PLANOGRAMS_LOAD_ERROR,
});
