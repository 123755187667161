import { Button, Card, CardContent, CardHeader, CircularProgress, Divider, Grid, Grow, IconButton, Table, TableBody, TableHead, Tooltip, Typography } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp, CloudDownload, Delete, Description, ErrorOutline, GetApp, InfoOutlined } from '@material-ui/icons';
import { orderBy } from 'lodash';
import { default as React } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import Alert from 'reactstrap/lib/Alert';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import trackComponent from 'infrastructure/tracking/withTracking';
import { StyledTableCell, StyledTableRow } from 'scss/MaterialStyles';
import { State } from 'state';
import * as XLSX from 'xlsx';
import * as actions from '../actions';
import { ClientDatasetDto } from '../types/ClientDatasetDto';
import AdminClientDatasetsState from './../state';
import DeleteClientDatasetConfirmationModal from './DeleteClientDatasetConfirmationModal';
import ImportDatasetModal from './ImportDatasetModal';
import PreviewClientDatasetModal from './PreviewClientDatasetModal';
import { isError, isLoaded, isLoading } from 'infrastructure/utils/RemoteObjectStatus';
import { AlertErrorTextIndicator } from 'modules/shared/components/LoadStatusIndicators/AlertErrorTextIndicator';
import { CircularLoadIndicator } from 'modules/shared/components/LoadStatusIndicators/CircularLoadIndicator';

interface ClientDatasetPageState {
  orderByProperty: string;
  orderByDescending: boolean;
  showImportDatasetModal: boolean;
  showPreviewClientDatasetModal: boolean;
  selectedDataset: ClientDatasetDto | undefined;
  showDeleteClientDatasetConfirmationModal: boolean;
}

class ClientDatasetsPage extends React.Component<AdminClientDatasetsState & DispatchProps, ClientDatasetPageState> {
  constructor(props: AdminClientDatasetsState & DispatchProps, state: ClientDatasetPageState) {
    super(props, state);
    this.state = {
      orderByDescending: false,
      orderByProperty: 'id',
      showImportDatasetModal: false,
      showPreviewClientDatasetModal: false,
      selectedDataset: undefined,
      showDeleteClientDatasetConfirmationModal: false,
    };
  }

  componentDidMount() {
    this.props.loadClientsRequest();
    this.props.loadClientDatasetsRequest();
  }

  componentDidUpdate(prevProps: AdminClientDatasetsState & DispatchProps) {
    if (prevProps.uploadClientDataSetStatus.loadStatus !== this.props.uploadClientDataSetStatus.loadStatus && isLoaded(this.props.uploadClientDataSetStatus)) {
      this.setState({ showImportDatasetModal: false });
    }
    if (prevProps.deleteClientDataSetStatus.loadStatus !== this.props.deleteClientDataSetStatus.loadStatus && isLoaded(this.props.deleteClientDataSetStatus)) {
      this.setState({ showDeleteClientDatasetConfirmationModal: false });
    }
  }

  changeOrderBy(propertyName: string) {
    const descending = this.state.orderByProperty === propertyName ? !this.state.orderByDescending : false;
    this.setState({
      orderByProperty: propertyName,
      orderByDescending: descending,
    });
  }

  getOrderByIcon(propertyName: string) {
    if (this.state.orderByProperty !== propertyName) {
      return null;
    }
    if (this.state.orderByDescending) {
      return <ArrowDropDown />;
    } else {
      return <ArrowDropUp />;
    }
  }

  downloadDataAsExcelFile(dataset: ClientDatasetDto) {
    const wb = XLSX.utils.book_new();
    wb.SheetNames.push('Sheet 1');
    const opts = {
      header: dataset.dataItems.length === 0 ? ['GTIN', 'Value'] : [],
    };

    const data = dataset.dataItems.map(i => ({
      GTIN: i.gtin,
      Value: i.value,
    }));

    const ws = XLSX.utils.json_to_sheet(data, opts);
    wb.Sheets['Sheet 1'] = ws;
    XLSX.writeFile(wb, `${dataset.name}.xlsx`);
  }

  render() {
    return (
      <div style={{ marginTop: '1.5rem' }}>
        <Grow in={true}>
          <Row>
            <Col xs="12">
              {isLoading(this.props.loadClientsStatus) || isLoading(this.props.loadDataSetsStatus) ? (
                <CircularLoadIndicator loadingText="Loading clients and datasets..." />
              ) : isError(this.props.loadClientsStatus) ? (
                <AlertErrorTextIndicator errorText={`Failed to load clients. ${this.props.loadClientsStatus.errorDetails?.errorMessage}`} />
              ) : (
                this.renderClientDatasetsCard()
              )}
            </Col>
          </Row>
        </Grow>
        {this.state.showImportDatasetModal ? (
          <ImportDatasetModal
            onClose={() => {
              this.setState({ showImportDatasetModal: false });
            }}
            isUploadingClientDataset={isLoading(this.props.uploadClientDataSetStatus)}
            uploadClientDataset={(clientDataset: ClientDatasetDto) => {
              this.props.uploadClientDatasetRequest(clientDataset);
            }}
            clients={this.props.loadClientsStatus.data ?? []}
          />
        ) : null}
        {this.state.showPreviewClientDatasetModal && this.state.selectedDataset !== undefined ? (
          <PreviewClientDatasetModal
            onClose={() => {
              this.setState({ showPreviewClientDatasetModal: false });
            }}
            clientDataset={this.state.selectedDataset}
          />
        ) : null}

        {this.state.showDeleteClientDatasetConfirmationModal && this.state.selectedDataset !== undefined ? (
          <DeleteClientDatasetConfirmationModal
            enabled={!isLoading(this.props.deleteClientDataSetStatus)}
            onClose={() => this.setState({ showDeleteClientDatasetConfirmationModal: false })}
            clientDataset={this.state.selectedDataset}
            onConfirmed={() => {
              if (this.state.selectedDataset) {
                this.props.deleteClientDatasetRequest(this.state.selectedDataset.id);
              }
            }}
            isDeletingClientDataset={isLoading(this.props.deleteClientDataSetStatus)}
          />
        ) : null}
      </div>
    );
  }

  renderClientDatasetsCard() {
    return (
      <Card raised={true} className="top-buffer-sm">
        <CardHeader
          title="Client Datasets"
          titleTypographyProps={{ color: 'primary' }}
          action={
            <Tooltip title="Import dataset for clients">
              <Button size="large" variant="outlined" onClick={() => this.setState({ showImportDatasetModal: true })} type="submit" color="primary">
                <GetApp color="primary" />
                &nbsp; Import Dataset
              </Button>
            </Tooltip>
          }
        />
        <Divider light variant="middle" />
        <CardContent>{this.renderClientDatasetsTable()}</CardContent>
      </Card>
    );
  }

  renderClientDatasetsTable() {
    if (isError(this.props.loadDataSetsStatus)) {
      return (
        <Alert className="top-buffer" color="danger">
          <Typography variant="caption" color="textSecondary">
            <ErrorOutline color="secondary" fontSize="small" />
            &nbsp;Failed to load client datasets. {this.props.loadDataSetsStatus.errorDetails?.errorMessage}
          </Typography>
        </Alert>
      );
    } else if (isLoading(this.props.loadDataSetsStatus)) {
      return (
        <Grid container justify="center">
          <CircularProgress />
        </Grid>
      );
    } else if (this.props.loadDataSetsStatus.data?.length === 0) {
      return (
        <Alert className="top-buffer" color="danger">
          <Typography variant="caption" color="textSecondary">
            <InfoOutlined color="primary" fontSize="small" />
            &nbsp; No client datasets found.
          </Typography>
        </Alert>
      );
    } else {
      return (
        <Table size="medium">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell style={{ cursor: 'pointer' }} align="center" onClick={() => this.changeOrderBy('id')}>
                <Typography variant="subtitle1">
                  Id &nbsp;
                  {this.getOrderByIcon('id')}
                </Typography>
              </StyledTableCell>
              <StyledTableCell style={{ cursor: 'pointer' }} align="center" onClick={() => this.changeOrderBy('name')}>
                <Typography variant="subtitle1">
                  Dataset Name &nbsp;
                  {this.getOrderByIcon('name')}
                </Typography>
              </StyledTableCell>
              <StyledTableCell style={{ cursor: 'pointer' }} align="center">
                <Typography variant="subtitle1">Client Name &nbsp;</Typography>
              </StyledTableCell>
              <StyledTableCell style={{ cursor: 'pointer' }} align="center" onClick={() => this.changeOrderBy('clientId')}>
                <Typography variant="subtitle1">
                  Client Id &nbsp;
                  {this.getOrderByIcon('clientId')}
                </Typography>
              </StyledTableCell>
              <StyledTableCell style={{ cursor: 'pointer' }} align="center" onClick={() => this.changeOrderBy('createdOn')}>
                <Typography variant="subtitle1">
                  Created On &nbsp;
                  {this.getOrderByIcon('createdOn')}
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography variant="subtitle1">Data</Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography variant="subtitle1">Download</Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography variant="subtitle1">Delete</Typography>
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>{this.renderDatasets()}</TableBody>
        </Table>
      );
    }
  }
  renderDatasets() {
    const clientDatasets = orderBy(this.props.loadDataSetsStatus.data, [this.state.orderByProperty], [this.state.orderByDescending ? 'desc' : 'asc']);
    return clientDatasets.map((clientDataset: ClientDatasetDto) => {
      const client = this.props.loadClientsStatus.data?.find(c => c.id === clientDataset.clientId);
      const clientName = client ? client.name : '';
      return (
        <StyledTableRow key={clientDataset.id}>
          <StyledTableCell align="center">{clientDataset.id}</StyledTableCell>
          <StyledTableCell align="center">{clientDataset.name}</StyledTableCell>
          <StyledTableCell align="center">{clientName}</StyledTableCell>
          <StyledTableCell align="center">{clientDataset.clientId}</StyledTableCell>
          <StyledTableCell align="center">{clientDataset.createdOn}</StyledTableCell>
          <StyledTableCell align="center">
            <Tooltip title="View Dataset">
              <IconButton
                size="medium"
                className="pull-left"
                color="primary"
                onClick={() => {
                  this.setState({ showPreviewClientDatasetModal: true, selectedDataset: clientDataset });
                }}
              >
                <Description />
              </IconButton>
            </Tooltip>
          </StyledTableCell>
          <StyledTableCell align="center">
            <Tooltip title="Download Dataset">
              <IconButton
                size="medium"
                className="pull-left"
                color="primary"
                onClick={() => {
                  this.downloadDataAsExcelFile(clientDataset);
                }}
              >
                <CloudDownload />
              </IconButton>
            </Tooltip>
          </StyledTableCell>
          <StyledTableCell align="center">
            <Tooltip title="Delete Dataset">
              <IconButton size="medium" className="pull-left" color="secondary" onClick={() => this.setState({ showDeleteClientDatasetConfirmationModal: true, selectedDataset: clientDataset })}>
                <Delete />
              </IconButton>
            </Tooltip>
          </StyledTableCell>
        </StyledTableRow>
      );
    });
  }
}

interface DispatchProps {
  loadClientsRequest(): void;
  loadClientDatasetsRequest(): void;
  uploadClientDatasetRequest(clientDataset: ClientDatasetDto): void;
  deleteClientDatasetRequest(datasetId: number): void;
}

function mapStateToProps(state: State) {
  return {
    ...state.adminClientDatasets,
  };
}

const componentToExport = connect<AdminClientDatasetsState, DispatchProps, RouteComponentProps<any>>(mapStateToProps, actions)(ClientDatasetsPage);
export default trackComponent(componentToExport, 'ClientDatasetsPage');
