import ErrorDetails from 'infrastructure/exception/ErrorDetails';
import {
  LoadPhaseShelvesFailureActionType,
  LoadPhaseShelvesRequestActionType,
  LoadPhaseShelvesSuccessActionType,
  LoadStandardBrandComparisonReportFailureActionType,
  LoadStandardBrandComparisonReportRequestActionType,
  LoadStandardBrandComparisonReportSuccessActionType,
  LoadStandardBrandReportFailureActionType,
  LoadStandardBrandReportRequestActionType,
  LoadStandardBrandReportSuccessActionType,
  LoadStandardClientBenchmarkReportFailureActionType,
  LoadStandardClientBenchmarkReportRequestActionType,
  LoadStandardClientBenchmarkReportSuccessActionType,
  LoadStandardGpcBrickComparisonReportFailureActionType,
  LoadStandardGpcBrickComparisonReportRequestActionType,
  LoadStandardGpcBrickComparisonReportSuccessActionType,
  LoadStandardGpcBrickReportFailureActionType,
  LoadStandardGpcBrickReportRequestActionType,
  LoadStandardGpcBrickReportSuccessActionType,
  LoadStandardProductComparisonReportFailureActionType,
  LoadStandardProductComparisonReportRequestActionType,
  LoadStandardProductComparisonReportSuccessActionType,
  LoadStandardProductReportFailureActionType,
  LoadStandardProductReportRequestActionType,
  LoadStandardProductReportSuccessActionType,
  LoadStandardProjectBenchmarkReportFailureActionType,
  LoadStandardProjectBenchmarkReportRequestActionType,
  LoadStandardProjectBenchmarkReportSuccessActionType,
  LoadStandardProjectComparisonReportFailureActionType,
  LoadStandardProjectComparisonReportRequestActionType,
  LoadStandardProjectComparisonReportSuccessActionType,
  LoadStandardProjectReportFailureActionType,
  LoadStandardProjectReportRequestActionType,
  LoadStandardProjectReportSuccessActionType,
  LoadStandardShelfComparisonReportFailureActionType,
  LoadStandardShelfComparisonReportRequestActionType,
  LoadStandardShelfComparisonReportSuccessActionType,
  LoadStandardShopperComparisonReportFailureActionType,
  LoadStandardShopperComparisonReportRequestActionType,
  LoadStandardShopperComparisonReportSuccessActionType,
  LoadStandardShopperReportFailureActionType,
  LoadStandardShopperReportRequestActionType,
  LoadStandardShopperReportSuccessActionType,
  LoadPotentialBrandReportRequestActionType,
  LoadPotentialBrandReportSuccessActionType,
  LoadPotentialBrandReportFailureActionType,
  LoadStandardProductBenchmarkReportRequestActionType,
  LoadStandardProductBenchmarkReportSuccessActionType,
  LoadStandardProductBenchmarkReportFailureActionType,
  STANDARD_REPORTS_LOAD_BRAND_COMPARISON_REPORT_FAILURE,
  STANDARD_REPORTS_LOAD_BRAND_COMPARISON_REPORT_REQUEST,
  STANDARD_REPORTS_LOAD_BRAND_COMPARISON_REPORT_SUCCESS,
  STANDARD_REPORTS_LOAD_BRAND_REPORT_FAILURE,
  STANDARD_REPORTS_LOAD_BRAND_REPORT_REQUEST,
  STANDARD_REPORTS_LOAD_BRAND_REPORT_SUCCESS,
  STANDARD_REPORTS_LOAD_CLIENT_BENCHMARK_REPORT_FAILURE,
  STANDARD_REPORTS_LOAD_CLIENT_BENCHMARK_REPORT_REQUEST,
  STANDARD_REPORTS_LOAD_CLIENT_BENCHMARK_REPORT_SUCCESS,
  STANDARD_REPORTS_LOAD_GPC_BRICK_COMPARISON_REPORT_FAILURE,
  STANDARD_REPORTS_LOAD_GPC_BRICK_COMPARISON_REPORT_REQUEST,
  STANDARD_REPORTS_LOAD_GPC_BRICK_COMPARISON_REPORT_SUCCESS,
  STANDARD_REPORTS_LOAD_GPC_BRICK_REPORT_FAILURE,
  STANDARD_REPORTS_LOAD_GPC_BRICK_REPORT_REQUEST,
  STANDARD_REPORTS_LOAD_GPC_BRICK_REPORT_SUCCESS,
  STANDARD_REPORTS_LOAD_PHASE_SHELVES_FAILURE,
  STANDARD_REPORTS_LOAD_PHASE_SHELVES_REQUEST,
  STANDARD_REPORTS_LOAD_PHASE_SHELVES_SUCCESS,
  STANDARD_REPORTS_LOAD_PRODUCT_COMPARISON_REPORT_FAILURE,
  STANDARD_REPORTS_LOAD_PRODUCT_COMPARISON_REPORT_REQUEST,
  STANDARD_REPORTS_LOAD_PRODUCT_COMPARISON_REPORT_SUCCESS,
  STANDARD_REPORTS_LOAD_PRODUCT_REPORT_FAILURE,
  STANDARD_REPORTS_LOAD_PRODUCT_REPORT_REQUEST,
  STANDARD_REPORTS_LOAD_PRODUCT_REPORT_SUCCESS,
  STANDARD_REPORTS_LOAD_PROJECT_BENCHMARK_REPORT_FAILURE,
  STANDARD_REPORTS_LOAD_PROJECT_BENCHMARK_REPORT_REQUEST,
  STANDARD_REPORTS_LOAD_PROJECT_BENCHMARK_REPORT_SUCCESS,
  STANDARD_REPORTS_LOAD_PROJECT_COMPARISON_REPORT_FAILURE,
  STANDARD_REPORTS_LOAD_PROJECT_COMPARISON_REPORT_REQUEST,
  STANDARD_REPORTS_LOAD_PROJECT_COMPARISON_REPORT_SUCCESS,
  STANDARD_REPORTS_LOAD_PROJECT_REPORT_FAILURE,
  STANDARD_REPORTS_LOAD_PROJECT_REPORT_REQUEST,
  STANDARD_REPORTS_LOAD_PROJECT_REPORT_SUCCESS,
  STANDARD_REPORTS_LOAD_SHELF_COMPARISON_REPORT_FAILURE,
  STANDARD_REPORTS_LOAD_SHELF_COMPARISON_REPORT_REQUEST,
  STANDARD_REPORTS_LOAD_SHELF_COMPARISON_REPORT_SUCCESS,
  STANDARD_REPORTS_LOAD_SHOPPER_COMPARISON_REPORT_FAILURE,
  STANDARD_REPORTS_LOAD_SHOPPER_COMPARISON_REPORT_REQUEST,
  STANDARD_REPORTS_LOAD_SHOPPER_COMPARISON_REPORT_SUCCESS,
  STANDARD_REPORTS_LOAD_SHOPPER_REPORT_FAILURE,
  STANDARD_REPORTS_LOAD_SHOPPER_REPORT_REQUEST,
  STANDARD_REPORTS_LOAD_SHOPPER_REPORT_SUCCESS,
  POTENTIAL_REPORTS_LOAD_BRAND_REPORT_REQUEST,
  POTENTIAL_REPORTS_LOAD_BRAND_REPORT_SUCCESS,
  POTENTIAL_REPORTS_LOAD_BRAND_REPORT_FAILURE,
  STANDARD_REPORTS_LOAD_PRODUCT_BENCHMARK_REPORT_REQUEST,
  STANDARD_REPORTS_LOAD_PRODUCT_BENCHMARK_REPORT_SUCCESS,
  STANDARD_REPORTS_LOAD_PRODUCT_BENCHMARK_REPORT_FAILURE,
} from './actionTypes';
import {
  BrandComparisonReportResult,
  BrandReportResult,
  BrandPotentialReport,
  ClientBenchmarkReportResult,
  GpcBrickComparisonReportResult,
  GpcBrickReportResult,
  ProductComparisonReportResult,
  ProductReportResult,
  ProjectBenchmarkReportResult,
  ProjectComparisonReportResult,
  ProjectReportResult,
  ShelfComparisonReportResult,
  ShopperComparisonReportResult,
  ShopperReportResult,
  StandardReportPhaseShelf,
  ProductBenchmarkReport,
} from './types';

// shelf selector actions
export function loadPhaseShelvesRequestAction(): LoadPhaseShelvesRequestActionType {
  return {
    type: STANDARD_REPORTS_LOAD_PHASE_SHELVES_REQUEST,
  };
}

export function loadPhaseShelvesSuccessAction(data: StandardReportPhaseShelf[]): LoadPhaseShelvesSuccessActionType {
  return {
    type: STANDARD_REPORTS_LOAD_PHASE_SHELVES_SUCCESS,
    data,
  };
}
export function loadPhaseShelvesFailureAction(error: ErrorDetails): LoadPhaseShelvesFailureActionType {
  return {
    type: STANDARD_REPORTS_LOAD_PHASE_SHELVES_FAILURE,
    error,
  };
}

// project report actions

export function loadStandardProjectReportRequestAction(shelfPhaseId: string): LoadStandardProjectReportRequestActionType {
  return {
    type: STANDARD_REPORTS_LOAD_PROJECT_REPORT_REQUEST,
    shelfPhaseId,
  };
}

export function loadStandardProjectReportSuccessAction(shelfPhaseId: string, data: ProjectReportResult): LoadStandardProjectReportSuccessActionType {
  return {
    type: STANDARD_REPORTS_LOAD_PROJECT_REPORT_SUCCESS,
    shelfPhaseId,
    data,
  };
}

export function loadStandardProjectReportFailureAction(shelfPhaseId: string, error: ErrorDetails): LoadStandardProjectReportFailureActionType {
  return {
    type: STANDARD_REPORTS_LOAD_PROJECT_REPORT_FAILURE,
    shelfPhaseId,
    error,
  };
}

// shopper report actions
export function loadStandardShopperReportRequestAction(shelfPhaseId: string): LoadStandardShopperReportRequestActionType {
  return {
    type: STANDARD_REPORTS_LOAD_SHOPPER_REPORT_REQUEST,
    shelfPhaseId,
  };
}

export function loadStandardShopperReportSuccessAction(shelfPhaseId: string, data: ShopperReportResult): LoadStandardShopperReportSuccessActionType {
  return {
    type: STANDARD_REPORTS_LOAD_SHOPPER_REPORT_SUCCESS,
    shelfPhaseId,
    data,
  };
}

export function loadStandardShopperReportFailureAction(shelfPhaseId: string, error: ErrorDetails): LoadStandardShopperReportFailureActionType {
  return {
    type: STANDARD_REPORTS_LOAD_SHOPPER_REPORT_FAILURE,
    shelfPhaseId,
    error,
  };
}

// brand report actions
export function loadStandardBrandReportRequestAction(shelfPhaseId: string, brandId: number): LoadStandardBrandReportRequestActionType {
  return {
    type: STANDARD_REPORTS_LOAD_BRAND_REPORT_REQUEST,
    shelfPhaseId,
    brandId,
  };
}

export function loadStandardBrandReportSuccessAction(shelfPhaseId: string, brandId: number, data: BrandReportResult): LoadStandardBrandReportSuccessActionType {
  return {
    type: STANDARD_REPORTS_LOAD_BRAND_REPORT_SUCCESS,
    shelfPhaseId,
    brandId,
    data,
  };
}

export function loadStandardBrandReportFailureAction(shelfPhaseId: string, brandId: number, error: ErrorDetails): LoadStandardBrandReportFailureActionType {
  return {
    type: STANDARD_REPORTS_LOAD_BRAND_REPORT_FAILURE,
    shelfPhaseId,
    brandId,
    error,
  };
}

// product report actions
export function loadStandardProductReportRequestAction(shelfPhaseId: string, gtin: number): LoadStandardProductReportRequestActionType {
  return {
    type: STANDARD_REPORTS_LOAD_PRODUCT_REPORT_REQUEST,
    shelfPhaseId,
    gtin,
  };
}

export function loadStandardProductReportSuccessAction(shelfPhaseId: string, gtin: number, data: ProductReportResult): LoadStandardProductReportSuccessActionType {
  return {
    type: STANDARD_REPORTS_LOAD_PRODUCT_REPORT_SUCCESS,
    shelfPhaseId,
    gtin,
    data,
  };
}

export function loadStandardProductReportFailureAction(shelfPhaseId: string, gtin: number, error: ErrorDetails): LoadStandardProductReportFailureActionType {
  return {
    type: STANDARD_REPORTS_LOAD_PRODUCT_REPORT_FAILURE,
    shelfPhaseId,
    gtin,
    error,
  };
}

// brand report actions
export function loadStandardGpcBrickReportRequestAction(shelfPhaseId: string, gpcBrickCode: number): LoadStandardGpcBrickReportRequestActionType {
  return {
    type: STANDARD_REPORTS_LOAD_GPC_BRICK_REPORT_REQUEST,
    shelfPhaseId,
    gpcBrickCode,
  };
}

export function loadStandardGpcBrickReportSuccessAction(shelfPhaseId: string, gpcBrickCode: number, data: GpcBrickReportResult): LoadStandardGpcBrickReportSuccessActionType {
  return {
    type: STANDARD_REPORTS_LOAD_GPC_BRICK_REPORT_SUCCESS,
    shelfPhaseId,
    gpcBrickCode,
    data,
  };
}

export function loadStandardGpcBrickReportFailureAction(shelfPhaseId: string, gpcBrickCode: number, error: ErrorDetails): LoadStandardGpcBrickReportFailureActionType {
  return {
    type: STANDARD_REPORTS_LOAD_GPC_BRICK_REPORT_FAILURE,
    shelfPhaseId,
    gpcBrickCode,
    error,
  };
}

// shelf changes report actions
export function loadStandardShelfComparisonReportRequestAction(shelfPhaseId1: string, shelfPhaseId2: string): LoadStandardShelfComparisonReportRequestActionType {
  return {
    type: STANDARD_REPORTS_LOAD_SHELF_COMPARISON_REPORT_REQUEST,
    shelfPhaseId1,
    shelfPhaseId2,
  };
}

export function loadStandardShelfComparisonReportSuccessAction(data: ShelfComparisonReportResult): LoadStandardShelfComparisonReportSuccessActionType {
  return {
    type: STANDARD_REPORTS_LOAD_SHELF_COMPARISON_REPORT_SUCCESS,
    data,
  };
}

export function loadStandardShelfComparisonReportFailureAction(error: ErrorDetails): LoadStandardShelfComparisonReportFailureActionType {
  return {
    type: STANDARD_REPORTS_LOAD_SHELF_COMPARISON_REPORT_FAILURE,
    error,
  };
}

// project comparison report actions
export function loadStandardProjectComparisonReportRequestAction(shelfPhaseId1: string, shelfPhaseId2: string): LoadStandardProjectComparisonReportRequestActionType {
  return {
    type: STANDARD_REPORTS_LOAD_PROJECT_COMPARISON_REPORT_REQUEST,
    shelfPhaseId1,
    shelfPhaseId2,
  };
}

export function loadStandardProjectComparisonReportSuccessAction(data: ProjectComparisonReportResult): LoadStandardProjectComparisonReportSuccessActionType {
  return {
    type: STANDARD_REPORTS_LOAD_PROJECT_COMPARISON_REPORT_SUCCESS,
    data,
  };
}

export function loadStandardProjectComparisonReportFailureAction(error: ErrorDetails): LoadStandardProjectComparisonReportFailureActionType {
  return {
    type: STANDARD_REPORTS_LOAD_PROJECT_COMPARISON_REPORT_FAILURE,
    error,
  };
}

// shopper comparison report actions
export function loadStandardShopperComparisonReportRequestAction(shelfPhaseId1: string, shelfPhaseId2: string): LoadStandardShopperComparisonReportRequestActionType {
  return {
    type: STANDARD_REPORTS_LOAD_SHOPPER_COMPARISON_REPORT_REQUEST,
    shelfPhaseId1,
    shelfPhaseId2,
  };
}

export function loadStandardShopperComparisonReportSuccessAction(data: ShopperComparisonReportResult): LoadStandardShopperComparisonReportSuccessActionType {
  return {
    type: STANDARD_REPORTS_LOAD_SHOPPER_COMPARISON_REPORT_SUCCESS,
    data,
  };
}

export function loadStandardShopperComparisonReportFailureAction(error: ErrorDetails): LoadStandardShopperComparisonReportFailureActionType {
  return {
    type: STANDARD_REPORTS_LOAD_SHOPPER_COMPARISON_REPORT_FAILURE,
    error,
  };
}

// product comparison report actions
export function loadStandardProductComparisonReportRequestAction(shelfPhaseId1: string, shelfPhaseId2: string, gtin1: number, gtin2: number): LoadStandardProductComparisonReportRequestActionType {
  return {
    type: STANDARD_REPORTS_LOAD_PRODUCT_COMPARISON_REPORT_REQUEST,
    shelfPhaseId1,
    shelfPhaseId2,
    gtin1,
    gtin2,
  };
}

export function loadStandardProductComparisonReportSuccessAction(data: ProductComparisonReportResult): LoadStandardProductComparisonReportSuccessActionType {
  return {
    type: STANDARD_REPORTS_LOAD_PRODUCT_COMPARISON_REPORT_SUCCESS,
    data,
  };
}

export function loadStandardProductComparisonReportFailureAction(error: ErrorDetails): LoadStandardProductComparisonReportFailureActionType {
  return {
    type: STANDARD_REPORTS_LOAD_PRODUCT_COMPARISON_REPORT_FAILURE,
    error,
  };
}

// brand comparison report actions
export function loadStandardBrandComparisonReportRequestAction(shelfPhaseId1: string, shelfPhaseId2: string, brandId1: number, brandId2: number): LoadStandardBrandComparisonReportRequestActionType {
  return {
    type: STANDARD_REPORTS_LOAD_BRAND_COMPARISON_REPORT_REQUEST,
    shelfPhaseId1,
    shelfPhaseId2,
    brandId1,
    brandId2,
  };
}

export function loadStandardBrandComparisonReportSuccessAction(data: BrandComparisonReportResult): LoadStandardBrandComparisonReportSuccessActionType {
  return {
    type: STANDARD_REPORTS_LOAD_BRAND_COMPARISON_REPORT_SUCCESS,
    data,
  };
}

export function loadStandardBrandComparisonReportFailureAction(error: ErrorDetails): LoadStandardBrandComparisonReportFailureActionType {
  return {
    type: STANDARD_REPORTS_LOAD_BRAND_COMPARISON_REPORT_FAILURE,
    error,
  };
}
// gpc brick comparison report actions
export function loadStandardGpcBrickComparisonReportRequestAction(
  shelfPhaseId1: string,
  shelfPhaseId2: string,
  gpcBrickCode1: number,
  gpcBrickCode2: number
): LoadStandardGpcBrickComparisonReportRequestActionType {
  return {
    type: STANDARD_REPORTS_LOAD_GPC_BRICK_COMPARISON_REPORT_REQUEST,
    shelfPhaseId1,
    shelfPhaseId2,
    gpcBrickCode1,
    gpcBrickCode2,
  };
}

export function loadStandardGpcBrickComparisonReportSuccessAction(data: GpcBrickComparisonReportResult): LoadStandardGpcBrickComparisonReportSuccessActionType {
  return {
    type: STANDARD_REPORTS_LOAD_GPC_BRICK_COMPARISON_REPORT_SUCCESS,
    data,
  };
}

export function loadStandardGpcBrickComparisonReportFailureAction(error: ErrorDetails): LoadStandardGpcBrickComparisonReportFailureActionType {
  return {
    type: STANDARD_REPORTS_LOAD_GPC_BRICK_COMPARISON_REPORT_FAILURE,
    error,
  };
}

// project benchmark report actions

export function loadStandardProjectBenchmarkReportRequestAction(projectId: number): LoadStandardProjectBenchmarkReportRequestActionType {
  return {
    type: STANDARD_REPORTS_LOAD_PROJECT_BENCHMARK_REPORT_REQUEST,
    projectId,
  };
}

export function loadStandardProjectBenchmarkReportSuccessAction(projectId: number, data: ProjectBenchmarkReportResult): LoadStandardProjectBenchmarkReportSuccessActionType {
  return {
    type: STANDARD_REPORTS_LOAD_PROJECT_BENCHMARK_REPORT_SUCCESS,
    projectId,
    data,
  };
}

export function loadStandardProjectBenchmarkReportFailureAction(projectId: number, error: ErrorDetails): LoadStandardProjectBenchmarkReportFailureActionType {
  return {
    type: STANDARD_REPORTS_LOAD_PROJECT_BENCHMARK_REPORT_FAILURE,
    projectId,
    error,
  };
}

// client benchmark report actions

export function loadStandardClientBenchmarkReportRequestAction(clientId: number): LoadStandardClientBenchmarkReportRequestActionType {
  return {
    type: STANDARD_REPORTS_LOAD_CLIENT_BENCHMARK_REPORT_REQUEST,
    clientId,
  };
}

export function loadStandardClientBenchmarkReportSuccessAction(clientId: number, data: ClientBenchmarkReportResult): LoadStandardClientBenchmarkReportSuccessActionType {
  return {
    type: STANDARD_REPORTS_LOAD_CLIENT_BENCHMARK_REPORT_SUCCESS,
    clientId,
    data,
  };
}

export function loadStandardClientBenchmarkReportFailureAction(clientId: number, error: ErrorDetails): LoadStandardClientBenchmarkReportFailureActionType {
  return {
    type: STANDARD_REPORTS_LOAD_CLIENT_BENCHMARK_REPORT_FAILURE,
    clientId,
    error,
  };
}

export function loadPotentialBrandReportRequestAction(shelfPhaseId: string, brandId: number): LoadPotentialBrandReportRequestActionType {
  return {
    type: POTENTIAL_REPORTS_LOAD_BRAND_REPORT_REQUEST,
    shelfPhaseId,
    brandId,
  };
}

export function loadPotentialBrandReportSuccessAction(shelfPhaseId: string, brandId: number, data: BrandPotentialReport): LoadPotentialBrandReportSuccessActionType {
  return {
    type: POTENTIAL_REPORTS_LOAD_BRAND_REPORT_SUCCESS,
    shelfPhaseId,
    brandId,
    data,
  };
}

export function loadPotentialBrandReportFailureAction(shelfPhaseId: string, brandId: number, error: ErrorDetails): LoadPotentialBrandReportFailureActionType {
  return {
    type: POTENTIAL_REPORTS_LOAD_BRAND_REPORT_FAILURE,
    shelfPhaseId,
    brandId,
    error,
  };
}
export function loadStandardProductBenchmarkReportRequestAction(targetShelfPhaseId: string, comparisonShelfPhaseId: string, targetGtin: number): LoadStandardProductBenchmarkReportRequestActionType {
  return {
    type: STANDARD_REPORTS_LOAD_PRODUCT_BENCHMARK_REPORT_REQUEST,
    targetShelfPhaseId,
    comparisonShelfPhaseId,
    targetGtin,
  };
}

export function loadStandardProductBenchmarkReportSuccessAction(
  targetShelfPhaseId: string,
  comparisonShelfPhaseId: string,
  targetGtin: number,
  data: ProductBenchmarkReport
): LoadStandardProductBenchmarkReportSuccessActionType {
  return {
    type: STANDARD_REPORTS_LOAD_PRODUCT_BENCHMARK_REPORT_SUCCESS,
    targetShelfPhaseId,
    comparisonShelfPhaseId,
    targetGtin,
    data,
  };
}

export function loadStandardProductBenchmarkReportFailureAction(
  targetShelfPhaseId: string,
  comparisonShelfPhaseId: string,
  targetGtin: number,
  error: ErrorDetails
): LoadStandardProductBenchmarkReportFailureActionType {
  return {
    type: STANDARD_REPORTS_LOAD_PRODUCT_BENCHMARK_REPORT_FAILURE,
    targetShelfPhaseId,
    comparisonShelfPhaseId,
    targetGtin,
    error,
  };
}
