import { AlertWarningIndicator } from 'modules/shared/components/LoadStatusIndicators/AlertWarningIndicator';
import * as React from 'react';
import { Chart } from 'react-google-charts';
import { useSelector } from 'react-redux';
import { getAvailableSalesReports, getSelectedReport } from '../aside/SalesDataManagement/selectors';
import { getCarouselTime, getSelectedWeek } from '../state/selectors';

const SalesReportComponent: React.FC = (): JSX.Element | null => {
  const selectedReport = useSelector(getSelectedReport);
  const salesReports = useSelector(getAvailableSalesReports);
  const carouselTime = useSelector(getCarouselTime);
  const selectedWeek = useSelector(getSelectedWeek);

  if (!selectedReport || !selectedWeek) {
    return null;
  }
  const theReport = salesReports.find(r => r.id === selectedReport);
  if (!theReport || !theReport.data) {
    return null;
  }

  // filter sales data for the selected week
  const weeklySalesData = theReport.data.filter(x => x.timestamp >= new Date(selectedWeek.startDate) && x.timestamp <= new Date(selectedWeek.endDate));
  if (weeklySalesData.length === 0) {
    return <AlertWarningIndicator warningText={'There is no sales data uploaded for the selected week.'} />;
  }
  const data = [
    ['Date and time', { role: 'annotation' }, 'Value'],
    ...weeklySalesData.map((r, index) => {
      const utcTimestamp = new Date(
        r.timestamp.getUTCFullYear(),
        r.timestamp.getUTCMonth(),
        r.timestamp.getUTCDate(),
        r.timestamp.getUTCHours(),
        r.timestamp.getUTCMinutes(),
        r.timestamp.getUTCSeconds()
      );
      let hasAnnotation = false;
      if (carouselTime === null) {
        hasAnnotation = index === 0;
      } else {
        hasAnnotation = carouselTime.getTime() === r.timestamp.getTime();
      }
      return [utcTimestamp, hasAnnotation ? '' : null, r.value];
    }),
  ];

  const options = {
    annotations: {
      stemColor: 'red',
      style: 'line',
    },
    annotation: {
      1: {
        style: 'line',
        color: 'black',
      },
      2: {
        style: 'line',
        color: 'blue',
      },
    },
    hAxis: {
      textStyle: {
        fontSize: '8',
        bold: true,
      },
      gridlines: {
        units: {
          days: { format: ['MMM dd'] },
          hours: { format: ['HH:mm', 'ha'] },
        },
      },
      minorGridlines: {
        units: {
          hours: { format: ['hh:mm a', 'ha'] },
          minutes: { format: ['HH:mm a Z', ':mm'] },
        },
      },
    },
  };

  return <Chart chartType="LineChart" options={options} data={data} width="100%" height="700px" legendToggle />;
};

export default SalesReportComponent;
