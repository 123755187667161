import ShelfLocation from '../domain/ShelfLocation';
import PlanogramModel from '../domain/PlanogramModel';
import { PlanogramUndoableCommand } from './_planogram_commands';
import PlanogramEventBase from '../events/PlanogramEventBase';

/**
 * This command takes a shelf location and cuts it by the specified number
 */
export default class CutFacingsCommand extends PlanogramUndoableCommand {
  private planogram: PlanogramModel;
  private shelfLocation: ShelfLocation;

  constructor(planogram: PlanogramModel, shelfLocation: ShelfLocation, numberOfFacings: number) {
    super();
    this.planogram = planogram;
    this.shelfLocation = shelfLocation;
    if (numberOfFacings !== 1) {
      throw new Error("This command can't handle number of facings that is not 1.");
    }
  }

  execute(): PlanogramEventBase {
    const sl = this.shelfLocation;
    if (sl.getCurrentShelfFacings() <= 1) {
      throw new Error('Currently there is only 1 facing. It is impossible to reduce the size anymore');
    }
    const oneFacingWidth = sl.geometry.originalWidth > 0 ? sl.geometry.originalWidth / sl.shelfFacings : 0;

    sl.geometry.topRightPoint.xOffset = -oneFacingWidth;
    sl.geometry.calculateDraggingPoint(sl.geometry.topRightPoint);
    sl.geometry.bottomRightPoint.xOffset = -oneFacingWidth;
    sl.geometry.calculateDraggingPoint(sl.geometry.bottomRightPoint);

    return this.planogram.applyTransientStateToShelfLocations([sl]);
  }

  undo(): PlanogramEventBase {
    const sl = this.shelfLocation;
    if (sl.getCurrentShelfFacings() === sl.shelfFacings) {
      // ignore, we can't epxand it anymore
      throw new Error('The product has reached maximum number of facings. You need to duplicate the product in order to increase the space');
    }
    const oneFacingWidth = sl.geometry.originalWidth > 0 ? sl.geometry.originalWidth / sl.shelfFacings : 0;

    sl.geometry.topRightPoint.xOffset = +oneFacingWidth;
    sl.geometry.calculateDraggingPoint(sl.geometry.topRightPoint);
    sl.geometry.bottomRightPoint.xOffset = +oneFacingWidth;
    sl.geometry.calculateDraggingPoint(sl.geometry.bottomRightPoint);

    return this.planogram.applyTransientStateToShelfLocations([sl]);
  }
}
