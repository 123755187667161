import * as React from 'react';
import SalesDataManagementContainer from './SalesDataManagement';
import ReportSelector from './ReportSelector';

const ShelfTimelapseAside: React.FC = (): JSX.Element => {
  return (
    <>
      <SalesDataManagementContainer />
      <ReportSelector />
    </>
  );
};

export default ShelfTimelapseAside;
