import ShelfLocation from '../domain/ShelfLocation';
import PlanogramModel from '../domain/PlanogramModel';
import { PlanogramUndoableCommand } from './_planogram_commands';
import PlanogramEventBase from '../events/PlanogramEventBase';
import ShelfLocationGeometryPoint from '../domain/ShelfLocationGeometryPoint';

class PreMovePointState {
  dataShelfLocation: ShelfLocation;

  constructor(targetShelfLocation: ShelfLocation) {
    const geometry = targetShelfLocation.geometry;
    this.dataShelfLocation = new ShelfLocation();
    const dsl = this.dataShelfLocation;
    dsl.id = targetShelfLocation.id;
    dsl.geometry.currentWidth = geometry.currentWidth;
    dsl.geometry.currentHeight = geometry.currentHeight;
    dsl.geometry.widthLeftChanged = geometry.widthLeftChanged;
    dsl.geometry.widthRightChanged = geometry.widthRightChanged;
    dsl.geometry.heightTopChanged = geometry.heightTopChanged;
    dsl.geometry.heightBottomChanged = geometry.heightBottomChanged;

    for (let i = 0; i < targetShelfLocation.geometry.points.length; i++) {
      const targetPoint = targetShelfLocation.geometry.points[i];
      const dataPoint = new ShelfLocationGeometryPoint();
      dataPoint.x = targetPoint.x;
      dataPoint.y = targetPoint.y;
      dataPoint.yOffset = 0;
      dataPoint.xOffset = 0;
      dsl.geometry.points.push(dataPoint);
    }
  }
}

export default class MoveCommand extends PlanogramUndoableCommand {
  readonly apiUrlPattern: string = '/planograms/{id}/shelf-locations/move';

  private planogram: PlanogramModel;
  private shelfLocations: ShelfLocation[];
  private prevState: PreMovePointState[] = [];

  constructor(planogram: PlanogramModel, shelfLocations: ShelfLocation[]) {
    super();
    this.shelfLocations = shelfLocations;
    this.planogram = planogram;
  }

  execute(): PlanogramEventBase {
    this.prevState = this.shelfLocations.map(sl => new PreMovePointState(sl));
    return this.planogram.applyTransientStateToShelfLocations(this.shelfLocations);
  }

  undo(): PlanogramEventBase {
    const dataShelfLocations = this.prevState.map(x => x.dataShelfLocation);
    return this.planogram.copyDataForGeometry(this.shelfLocations, dataShelfLocations);
  }
}
