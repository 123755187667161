import { RemoteObjectStatusInterface } from '../../../infrastructure/utils/RemoteObjectStatus';
import { PayloadAction, PayloadMetaAction } from 'typesafe-actions';
import ErrorDetails from '../../../infrastructure/exception/ErrorDetails';

export interface InspirationImageItem {
  id: string;
  imageUrl: string;
  description: string;
}

export type InspirationImagesState = RemoteObjectStatusInterface<InspirationImageItem[]>;

export enum INISPIRATION_IMAGES_FETCH {
  REQUEST = 'entities/inspirationImages/INISPIRATION_IMAGES_FETCH/REQUEST',
  SUCCESS = 'entities/inspirationImages/INISPIRATION_IMAGES_FETCH/SUCCESS',
  FAILED = 'entities/inspirationImages/INISPIRATION_IMAGES_FETCH/FAILED',
}

export type FetchInspirationImagesRequestAction = (planogramId: string) => PayloadAction<string, string>;
export type FetchInspirationImagesRequestPayload = InspirationImageItem[];

export type FetchInspirationImagesSuccessAction = (data: FetchInspirationImagesRequestPayload) => PayloadAction<string, FetchInspirationImagesRequestPayload>;

export type FetchInspirationImagesFailedAction = (e: ErrorDetails) => PayloadAction<string, ErrorDetails>;

export enum INISPIRATION_IMAGE_DELETE {
  REQUEST = 'entities/inspirationImages/INISPIRATION_IMAGE_DELETE/REQUEST',
  SUCCESS = 'entities/inspirationImages/INISPIRATION_IMAGE_DELETE/SUCCESS',
  FAILED = 'entities/inspirationImages/INISPIRATION_IMAGE_DELETE/FAILED',
}

export interface DeleteInspirationImagesPayload {
  planogramId: string;
  inspirationImageId: string;
}
export interface DeleteInspirationImagesRequestMeta {
  resetLoading: () => void;
}
export type DeleteInspirationImagesRequestAction = (
  payload: DeleteInspirationImagesPayload,
  meta: DeleteInspirationImagesRequestMeta
) => PayloadMetaAction<string, DeleteInspirationImagesPayload, DeleteInspirationImagesRequestMeta>;

export type DeleteInspirationImagesSuccessAction = (payload: DeleteInspirationImagesPayload) => PayloadAction<string, DeleteInspirationImagesPayload>;

export enum INISPIRATION_IMAGE_UPDATE_DESCRIPTION {
  REQUEST = 'entities/inspirationImages/INISPIRATION_IMAGE_UPDATE_DESCRIPTION/REQUEST',
  SUCCESS = 'entities/inspirationImages/INISPIRATION_IMAGE_UPDATE_DESCRIPTION/SUCCESS',
  FAILED = 'entities/inspirationImages/INISPIRATION_IMAGE_UPDATE_DESCRIPTION/FAILED',
}

export interface UpdateDescInspirationImageRequestPayload {
  planogramId: string;
  inspirationImageId: string;
  description: string;
}

export interface UpdateDescInspirationImageRequestMeta {
  resetUpdating: () => void;
  restEditingMode: () => void;
}
export type UpdateDescInspirationImageRequestAction = (
  payload: UpdateDescInspirationImageRequestPayload,
  meta: UpdateDescInspirationImageRequestMeta
) => PayloadMetaAction<string, UpdateDescInspirationImageRequestPayload, UpdateDescInspirationImageRequestMeta>;

export interface UpdateDescInspirationImageSuccessPayload {
  inspirationImageId: string;
  description: string;
}

export type UpdateDescInspirationImageSuccessAction = (payload: UpdateDescInspirationImageSuccessPayload) => PayloadAction<string, UpdateDescInspirationImageSuccessPayload>;

export enum UPLOAD_INSPIRATION_IMAGES {
  REQUEST = 'admin/planograms/UPLOAD_INSPIRATION_IMAGES_REQUEST',
  SUCCESS = 'admin/planograms/UPLOAD_INSPIRATION_IMAGES_SUCCESS',
  FAILED = 'admin/planograms/UPLOAD_INSPIRATION_IMAGES_FAILED',
}

export interface UploadInspirationImagesRequestPayload {
  planogramId: string;
  data: {
    inspirationImages: {
      imageBase64: string;
      description: string;
    }[];
  };
}

export interface UploadInspirationImagesRequestMeta {
  resetForm: () => void;
  setSubmitting: (isSubmitting: boolean) => void;
}

export type UploadInspirationImagesRequestCreator = (
  payload: UploadInspirationImagesRequestPayload,
  meta: UploadInspirationImagesRequestMeta
) => PayloadMetaAction<string, UploadInspirationImagesRequestPayload, UploadInspirationImagesRequestMeta>;
