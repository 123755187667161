export class ClientDatasetDto {
  id = 0;
  name = '';
  clientId = 0;
  createdOn: Date = new Date();
  dataItems: DataItem[] = [];
}

export class DataItem {
  gtin = 0;
  value = 0;
}
