import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { SelectorOption } from '../types';

export interface AggregatedReportButtonsProps {
  brick: SelectorOption | undefined;
  brand: SelectorOption | undefined;
  gtin: SelectorOption | undefined;
  isBrandLessThan: boolean;
  isBrickLessThan: boolean;
  isBrandBrickLessThan: boolean;
  isProductLessThan: boolean;
  metricSelected: boolean;
  requestBrickReport: () => void;
  requestBrandBrickReport: () => void;
  requestBrandReport: () => void;
  requestProductReport: () => void;
}

const AggregatedReportButtons: React.FC<AggregatedReportButtonsProps> = ({
  brick,
  brand,
  gtin,
  isBrandLessThan,
  isBrickLessThan,
  isBrandBrickLessThan,
  isProductLessThan,
  requestBrickReport,
  requestBrandBrickReport,
  requestBrandReport,
  requestProductReport,
  metricSelected,
}: AggregatedReportButtonsProps) => {
  const canSelectBrandBrick = brick && brand && metricSelected;
  const canSelectBrand = brand && metricSelected;
  const canSelectBrick = brick && metricSelected;
  const canSelectProduct = gtin && metricSelected;

  const getButtonText = (lessThan: boolean) => {
    return lessThan ? '(Has less than 20 interactors)' : '';
  };

  const getButtonColor = (lessThan: boolean) => {
    return lessThan ? 'secondary' : 'primary';
  };

  return (
    <Grid container direction="row" style={{ margin: '8px', padding: '16px' }} justify="flex-start" spacing={2}>
      <Grid item xs={12}>
        <Button fullWidth className="text-center" disabled={!canSelectBrick} size="large" variant="contained" color={getButtonColor(isBrickLessThan)} onClick={requestBrickReport}>
          Brick Report {getButtonText(isBrickLessThan)}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button fullWidth className="text-center" disabled={!canSelectBrandBrick} size="large" variant="contained" color={getButtonColor(isBrandBrickLessThan)} onClick={requestBrandBrickReport}>
          Brand Brick Report {getButtonText(isBrandBrickLessThan)}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button fullWidth className="text-center" disabled={!canSelectBrand} size="large" variant="contained" color={getButtonColor(isBrandLessThan)} onClick={requestBrandReport}>
          Brand Report {getButtonText(isBrandLessThan)}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button fullWidth className="text-center" disabled={!canSelectProduct} size="large" variant="contained" color={getButtonColor(isProductLessThan)} onClick={requestProductReport}>
          Product Report {getButtonText(isProductLessThan)}
        </Button>
      </Grid>
    </Grid>
  );
};

export { AggregatedReportButtons };
