import { telemetryService } from 'infrastructure/tracking/TelemetryService';
import { Action } from 'types/Action';
import * as constants from './constants';
import AuthState from './state';
import ApplicationUser from './types/ApplicationUser';
import { setAuthHeader } from 'utils/apiClient';
import { setError, setIdle, setLoading, setSuccess } from 'infrastructure/utils/RemoteObjectStatus';
import ErrorDetails from 'infrastructure/exception/ErrorDetails';

const initialState: AuthState = {
  isAuthenticated: false,
  signoutRequested: false,
  loadUserStatus: setLoading(),
  loadUserPreferencesStatus: setIdle(),
};

const authReducer = (state: AuthState = initialState, action: Action): AuthState => {
  switch (action.type) {
    case constants.REQUEST_SIGNOUT:
      return {
        ...state,
        signoutRequested: true,
      };

    case constants.USER_LOADING:
    case constants.NOT_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: false,
        loadUserStatus: setLoading(),
      };

    case constants.USER_LOADED:
      telemetryService.setUserId(action.user.profile.sub as string);
      setAuthHeader(action.user.access_token);
      return {
        ...state,
        isAuthenticated: true,
        loadUserStatus: setSuccess(action.user),
        applicationUser: new ApplicationUser(action.user),
      };

    case constants.USER_LOADED_ERROR:
      return {
        ...state,
        loadUserStatus: setError({} as ErrorDetails),
        loadUserPreferencesStatus: setIdle(),
      };

    case constants.LOAD_USER_PREFERENCES_REQUEST:
      return {
        ...state,
        loadUserPreferencesStatus: setLoading(),
      };

    case constants.LOAD_USER_PREFERENCES_SUCCESS:
      return {
        ...state,
        loadUserPreferencesStatus: setSuccess(action.userPreferences),
      };

    case constants.LOAD_USER_PREFERENCES_FAILED:
      return {
        ...state,
        loadUserPreferencesStatus: setError({} as ErrorDetails),
      };

    case constants.HAS_FINISHED_PRODUCT_TOUR_SUCCESS:
      return {
        ...state,
        loadUserPreferencesStatus: {
          ...state.loadUserPreferencesStatus,
          ...{
            productTour: {
              ...state.loadUserPreferencesStatus.data?.productTour,
              ...{
                hasFinishedTour: true,
              },
            },
          },
        },
      };
  }
  return state;
};

export default authReducer;
