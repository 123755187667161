import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  card: {
    maxWidth: 350,
    marginTop: 15,
  },
  img: {
    maxWidth: '100%',
    height: 'auto',
    width: 'auto',
    margin: '0 auto',
  },
});
