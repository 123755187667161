import { RemoteObjectStatusInterface } from 'infrastructure/utils/RemoteObjectStatus';
import { createSelector, Selector } from 'reselect';
import { State } from 'state';
import AggregatedReportsState from './state';
import { AggregatedOnePagerReport, AvailableAggregatedReports } from './types';

const getState = (state: State): AggregatedReportsState => state.aggregatedReports;

export const getLoadState: Selector<State, RemoteObjectStatusInterface<AvailableAggregatedReports>> = createSelector(getState, state => state.availableReports);

export const getData: Selector<State, AvailableAggregatedReports> = createSelector(getState, state => {
  return state.availableReports.data as AvailableAggregatedReports;
});

export const isDataEmpty: Selector<State, boolean> = createSelector(getData, data => {
  return data === null || data === undefined;
});

export const getAggregatedProductReportLoadState: Selector<State, RemoteObjectStatusInterface<AggregatedOnePagerReport>> = createSelector(getState, state => {
  return state.aggregatedProductReport;
});
export const getAggregatedProductReportData: Selector<State, AggregatedOnePagerReport | undefined> = createSelector(getState, state => {
  return state.aggregatedProductReport.data;
});
export const isAggregatedProductReportEmpty: Selector<State, boolean> = createSelector(getAggregatedProductReportData, data => {
  return data === null || data === undefined;
});

export const getAggregatedBrandReportLoadState: Selector<State, RemoteObjectStatusInterface<AggregatedOnePagerReport>> = createSelector(getState, state => {
  return state.aggregatedBrandReport;
});
export const getAggregatedBrandReportData: Selector<State, AggregatedOnePagerReport | undefined> = createSelector(getState, state => {
  return state.aggregatedBrandReport.data;
});
export const isAggregatedBrandReportEmpty: Selector<State, boolean> = createSelector(getAggregatedBrandReportData, data => {
  return data === null || data === undefined;
});

export const getAggregatedBrickReportLoadState: Selector<State, RemoteObjectStatusInterface<AggregatedOnePagerReport>> = createSelector(getState, state => {
  return state.aggregatedBrickReport;
});
export const getAggregatedBrickReportData: Selector<State, AggregatedOnePagerReport | undefined> = createSelector(getState, state => {
  return state.aggregatedBrickReport.data;
});
export const isAggregatedBrickReportEmpty: Selector<State, boolean> = createSelector(getAggregatedBrickReportData, data => {
  return data === null || data === undefined;
});

export const getAggregatedBrandBrickReportLoadState: Selector<State, RemoteObjectStatusInterface<AggregatedOnePagerReport>> = createSelector(getState, state => {
  return state.aggregatedBrandBrickReport;
});
export const getAggregatedBrandBrickReportData: Selector<State, AggregatedOnePagerReport | undefined> = createSelector(getState, state => {
  return state.aggregatedBrandBrickReport.data;
});
export const isAggregatedBrandBrickReportEmpty: Selector<State, boolean> = createSelector(getAggregatedBrandBrickReportData, data => {
  return data === null || data === undefined;
});
