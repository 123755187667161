import { AppBar, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import { Close, Description, Link, Style } from '@material-ui/icons';
import trackComponent from 'infrastructure/tracking/withTracking';
import { isError, isLoading, RemoteObjectStatusInterface } from 'infrastructure/utils/RemoteObjectStatus';
import { AlertErrorTextIndicator } from 'modules/shared/components/LoadStatusIndicators/AlertErrorTextIndicator';
import { AlertInfoIndicator } from 'modules/shared/components/LoadStatusIndicators/AlertInfoIndicator';
import { CircularLoadIndicator } from 'modules/shared/components/LoadStatusIndicators/CircularLoadIndicator';
import React, { Component } from 'react';
import CompetitorShelf from '../../types/CompetitorShelf';
import CompetitorShelfDataset from '../../types/CompetitorShelfDataset';
import CompetitorShelvesDatasetsTab from './CompetitorShelvesDatasetsTab';
import CompetitorShelvesLinkingTab from './CompetitorShelvesLinkingTab';

interface ManageCompetitorShelvesModalState {
  activeTab: TabSelected;
}

enum TabSelected {
  LINKING_TAB = 0,
  DATASETS_TAB = 1,
}

class ManageCompetitorShelvesModal extends Component<ManageCompetitorShelvesModalProps, ManageCompetitorShelvesModalState> {
  constructor(props: ManageCompetitorShelvesModalProps, state: ManageCompetitorShelvesModalState) {
    super(props, state);
    this.state = {
      activeTab: TabSelected.LINKING_TAB,
    };
  }

  componentDidMount() {
    this.props.loadCompetitorShelvesDatasets(this.props.planogramId);
  }

  onTabChange(event: React.ChangeEvent<{}>, newValue: number) {
    this.setState({ activeTab: newValue });
  }

  render(): JSX.Element {
    const projectStorePhaseShelves = this.props.similarProjectStorePhaseShelvesLoadStatus.data;
    const shelvesLoadStatus = this.props.similarProjectStorePhaseShelvesLoadStatus;
    return (
      <Dialog fullWidth maxWidth="xl" open={true} onClose={this.props.onClose}>
        <DialogTitle>
          <Typography variant="overline" gutterBottom>
            <Style fontSize="large" color="primary" />
            &nbsp; Manage competitor shelves - {this.props.planogramName}
          </Typography>
          <Divider light variant="middle" />
        </DialogTitle>
        <DialogContent>
          {isLoading(shelvesLoadStatus) ? (
            <CircularLoadIndicator loadingText="Loading Project-Store-Phase-Shelves..." />
          ) : isError(shelvesLoadStatus) ? (
            <AlertErrorTextIndicator errorText={`No Project-Store-Phase-Shelves found with Project Type 'Competitor Shelf'.`} />
          ) : !projectStorePhaseShelves || projectStorePhaseShelves.length === 0 ? (
            <AlertInfoIndicator textColor="textSecondary" infoText={`No Project-Store-Phase-Shelves found with Project Type 'Competitor Shelf'.`} />
          ) : (
            this.renderTabComponents()
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={this.props.onClose} className="pull-right">
            <Close />
            &nbsp; Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderTabComponents(): React.ReactNode {
    return (
      <Paper>
        <AppBar position="static" color="default">
          <Tabs value={this.state.activeTab} onChange={(evt: any, tabNumber: number) => this.onTabChange(evt, tabNumber)} indicatorColor="primary" variant="fullWidth" textColor="primary" centered>
            <Tab icon={<Link />} label="Link Shelves" />
            <Tab icon={<Description />} label="Manage Datasets" />
          </Tabs>
        </AppBar>
        {this.state.activeTab === TabSelected.LINKING_TAB ? (
          <CompetitorShelvesLinkingTab
            planogramId={this.props.planogramId}
            planogramName={this.props.planogramName}
            competitorShelves={this.props.competitorShelves}
            similarProjectStorePhaseShelvesLoadStatus={this.props.similarProjectStorePhaseShelvesLoadStatus}
            updateCompetitorShelves={(planogramId: string, competitorShelves: CompetitorShelf[]) => this.props.updateCompetitorShelves(planogramId, competitorShelves)}
            updateCompetitorShelvesState={this.props.updateCompetitorShelvesState}
          />
        ) : null}
        {this.state.activeTab === TabSelected.DATASETS_TAB ? (
          <CompetitorShelvesDatasetsTab
            planogramId={this.props.planogramId}
            planogramName={this.props.planogramName}
            competitorShelves={this.props.competitorShelves}
            uploadDataset={(dataset: CompetitorShelfDataset) => this.props.uploadCompetitorShelfDatasetRequest(dataset)}
            uploadCompetitorShelfDatasetState={this.props.uploadCompetitorShelfDatasetState}
            competitorShelvesDatasetsStatus={this.props.competitorShelvesDatasetsStatus}
            deleteCompetitorShelfDatasetState={this.props.deleteCompetitorShelfDatasetState}
            deleteCompetitorShelfDataset={(dataset: CompetitorShelfDataset) => this.props.deleteCompetitorShelfDataset(dataset)}
          />
        ) : null}
      </Paper>
    );
  }
}

interface ManageCompetitorShelvesModalProps {
  planogramId: string;
  planogramName: string;
  competitorShelves: CompetitorShelf[];
  similarProjectStorePhaseShelvesLoadStatus: RemoteObjectStatusInterface<CompetitorShelf[]>;
  updateCompetitorShelvesState: RemoteObjectStatusInterface<{}>;
  uploadCompetitorShelfDatasetState: RemoteObjectStatusInterface<{}>;
  competitorShelvesDatasetsStatus: RemoteObjectStatusInterface<CompetitorShelfDataset[]>;
  deleteCompetitorShelfDatasetState: RemoteObjectStatusInterface<{}>;
  onClose(): void;
  loadCompetitorShelvesDatasets(planogramId: string): void;
  updateCompetitorShelves(planogramId: string, competitorShelves: CompetitorShelf[]): void;
  uploadCompetitorShelfDatasetRequest(dataset: CompetitorShelfDataset): void;
  deleteCompetitorShelfDataset(dataset: CompetitorShelfDataset): void;
}
export default trackComponent(ManageCompetitorShelvesModal, 'ManageCompetitorShelvesModal');
