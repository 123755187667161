import apiClient from '../../../utils/apiClient';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const fetchShelfConditionFromPlanogram = (planogramId: string) => {
  return apiClient.get(`/planograms/${planogramId}/shelf-conditions`);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const fetchShelfConditionFromRealogram = (realogramId: string) => {
  return apiClient.get(`/realograms/${realogramId}/shelf-conditions`);
};
