import React from 'react';
import { Card, CardActions, CardContent, CardHeader, Divider } from '@material-ui/core';

export interface CardUsersAndRulesProps {
  children: React.ReactNode;
  cardActions: React.ReactNode;
}

const CardUsersAndRules: React.FC<CardUsersAndRulesProps> = ({ children, cardActions }: CardUsersAndRulesProps): JSX.Element => {
  return (
    <Card raised={true} className="top-buffer">
      <CardHeader title="USERS AND RULES" subheader="Create user groups and assign planogram rules to each user group." titleTypographyProps={{ color: 'primary' }} />
      <Divider light variant="middle" />
      <CardContent className="left-buffer right-buffer">{children}</CardContent>
      <CardActions>{cardActions}</CardActions>
    </Card>
  );
};

export default CardUsersAndRules;
