import ErrorDetails from 'infrastructure/exception/ErrorDetails';
import { FlowDataReportType } from 'modules/planogram/types/ReportingTypes';
import { createAction } from 'typesafe-actions';
import {
  CloseRealogramConversationPopperActionType,
  CLOSE_REALOGRAM_CONVERSATION_POPPER,
  DeleteMessageInConversationFailureActionType,
  DeleteMessageInConversationRequestActionType,
  DeleteMessageInConversationSuccessActionType,
  DELETE_MESSAGE_IN_CONVERSATION_FAILURE,
  DELETE_MESSAGE_IN_CONVERSATION_REQUEST,
  DELETE_MESSAGE_IN_CONVERSATION_SUCCESS,
  FetchRealogramConversationFailureActionType,
  FetchRealogramConversationRequestActionType,
  FetchRealogramConversationSuccessActionType,
  OpenRealogramConversationPopperActionType,
  OPEN_REALOGRAM_CONVERSATION_POPPER,
  PostMessageToConversationFailureActionType,
  PostMessageToConversationRequestActionType,
  PostMessageToConversationSuccessActionType,
  POST_MESSAGE_TO_CONVERSATION_FAILURE,
  POST_MESSAGE_TO_CONVERSATION_REQUEST,
  POST_MESSAGE_TO_CONVERSATION_SUCCESS,
  REALOGRAM_CONVERSATION_LOAD_FAILURE,
  REALOGRAM_CONVERSATION_LOAD_REQUEST,
  REALOGRAM_CONVERSATION_LOAD_SUCCESS,
  REALOGRAM_FLOW_DATA_FAILED,
  REALOGRAM_FLOW_DATA_REQUEST,
  REALOGRAM_FLOW_DATA_SUCCESS,
  REALOGRAM_LOAD_FAILED,
  REALOGRAM_LOAD_REQUEST,
  REALOGRAM_LOAD_SUCCESS,
  REALOGRAM_SELECT_FLOW_REPORT_TYPE,
  REALOGRAM_SHELF_LOCATION_SELECT,
  UpdateMessageInConversationFailureActionType,
  UpdateMessageInConversationRequestActionType,
  UpdateMessageInConversationSuccessActionType,
  UPDATE_MESSAGE_IN_CONVERSATION_FAILURE,
  UPDATE_MESSAGE_IN_CONVERSATION_REQUEST,
  UPDATE_MESSAGE_IN_CONVERSATION_SUCCESS,
} from './actionTypes';
import {
  FetchFlowDataFailedActionCreator,
  FetchFlowDataRequestActionCreator,
  FetchFlowDataSuccessActionCreator,
  FetchRealogramFailedActionCreator,
  FetchRealogramRequestActionCreator,
  FetchRealogramSuccessActionCreator,
  RealogramConversation,
  RealogramViewModel,
  SelectFlowReportTypeActionCreator,
  SelectShelfLocationActionCreator,
} from './types';
import { FlowDataReportResult } from 'modules/planogram/types/FlowDataReportResult';

export const fetchRequestAction: FetchRealogramRequestActionCreator = createAction(REALOGRAM_LOAD_REQUEST)<string>();

export const fetchSuccessAction: FetchRealogramSuccessActionCreator = createAction(REALOGRAM_LOAD_SUCCESS)<RealogramViewModel>();

export const fetchFailedAction: FetchRealogramFailedActionCreator = createAction(REALOGRAM_LOAD_FAILED)<ErrorDetails>();

export const fetchFlowDataRequestAction: FetchFlowDataRequestActionCreator = createAction(REALOGRAM_FLOW_DATA_REQUEST)<string>();

export const fetchFlowDataSuccessAction: FetchFlowDataSuccessActionCreator = createAction(REALOGRAM_FLOW_DATA_SUCCESS)<FlowDataReportResult>();

export const fetchFlowDataFailedAction: FetchFlowDataFailedActionCreator = createAction(REALOGRAM_FLOW_DATA_FAILED)<ErrorDetails>();

export const selectFlowReportTypeAction: SelectFlowReportTypeActionCreator = createAction(REALOGRAM_SELECT_FLOW_REPORT_TYPE)<FlowDataReportType>();

export const selectShelfLocationAction: SelectShelfLocationActionCreator = createAction(REALOGRAM_SHELF_LOCATION_SELECT)<string | undefined>();

// Realogram Conversation Actions
export const fetchRealogramConversationRequestAction = (shelfReferenceId: string): FetchRealogramConversationRequestActionType => {
  return { type: REALOGRAM_CONVERSATION_LOAD_REQUEST, payload: { shelfReferenceId } };
};

export const fetchRealogramConversationSuccessAction = (realogramConversation: RealogramConversation): FetchRealogramConversationSuccessActionType => {
  return { type: REALOGRAM_CONVERSATION_LOAD_SUCCESS, payload: { realogramConversation } };
};

export const fetchRealogramConversationFailureAction = (errorDetails: ErrorDetails): FetchRealogramConversationFailureActionType => {
  return { type: REALOGRAM_CONVERSATION_LOAD_FAILURE, payload: { errorDetails } };
};

export const openRealogramConversationPopperAction = (anchorEl: Element): OpenRealogramConversationPopperActionType => {
  return { type: OPEN_REALOGRAM_CONVERSATION_POPPER, payload: { anchorEl } };
};

export const closeRealogramConversationPopperAction = (): CloseRealogramConversationPopperActionType => {
  return { type: CLOSE_REALOGRAM_CONVERSATION_POPPER };
};

export const postMessageToConversationRequestAction = (shelfReferenceId: string, message: string): PostMessageToConversationRequestActionType => {
  return { type: POST_MESSAGE_TO_CONVERSATION_REQUEST, payload: { shelfReferenceId, message } };
};

export const postMessageToConversationSuccessAction = (
  shelfReferenceId: string,
  message: string,
  messageId: string,
  userId: number,
  username: string,
  timeOfMessage: Date
): PostMessageToConversationSuccessActionType => {
  return {
    type: POST_MESSAGE_TO_CONVERSATION_SUCCESS,
    payload: { shelfReferenceId, message, messageId, userId, username, timeOfMessage },
  };
};

export const postMessageToConversationFailureAction = (errorDetails: ErrorDetails): PostMessageToConversationFailureActionType => {
  return { type: POST_MESSAGE_TO_CONVERSATION_FAILURE, payload: { errorDetails } };
};

export const updateMessageInConversationRequestAction = (shelfReferenceId: string, messageId: string, userId: number, editedMessage: string): UpdateMessageInConversationRequestActionType => {
  return {
    type: UPDATE_MESSAGE_IN_CONVERSATION_REQUEST,
    payload: { shelfReferenceId, messageId, userId, editedMessage },
  };
};

export const updateMessageInConversationSuccessAction = (shelfReferenceId: string, messageId: string, editedMessage: string): UpdateMessageInConversationSuccessActionType => {
  return {
    type: UPDATE_MESSAGE_IN_CONVERSATION_SUCCESS,
    payload: { shelfReferenceId, messageId, editedMessage },
  };
};

export const updateMessageInConversationFailureAction = (errorDetails: ErrorDetails): UpdateMessageInConversationFailureActionType => {
  return {
    type: UPDATE_MESSAGE_IN_CONVERSATION_FAILURE,
    payload: { errorDetails },
  };
};

export const deleteMessageInConversationRequestAction = (shelfReferenceId: string, messageId: string): DeleteMessageInConversationRequestActionType => {
  return {
    type: DELETE_MESSAGE_IN_CONVERSATION_REQUEST,
    payload: { shelfReferenceId, messageId },
  };
};

export const deleteMessageInConversationSuccessAction = (shelfReferenceId: string, messageId: string): DeleteMessageInConversationSuccessActionType => {
  return {
    type: DELETE_MESSAGE_IN_CONVERSATION_SUCCESS,
    payload: { shelfReferenceId, messageId },
  };
};

export const deleteMessageInConversationFailureAction = (errorDetails: ErrorDetails): DeleteMessageInConversationFailureActionType => {
  return {
    type: DELETE_MESSAGE_IN_CONVERSATION_FAILURE,
    payload: { errorDetails },
  };
};
