import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import * as selectors from '../../selectors';
import { ProductBenchmarkReport } from '../../types';
import { loadStandardProductBenchmarkReportRequestAction } from '../../actions';
import { isLoaded, isLoading } from 'infrastructure/utils/RemoteObjectStatus';
interface LoadStatusParams {
  isLoaded: boolean;
  isLoading: boolean;
  loadError: string;
  isDataEmpty: boolean;
  data: ProductBenchmarkReport | undefined;
}
export interface ProductBenchmarkReportLoaderProps {
  children: (params: LoadStatusParams) => React.ReactNode;
}

const ProductBenchmarkReportLoader: React.FC<ProductBenchmarkReportLoaderProps> = ({ children }: ProductBenchmarkReportLoaderProps) => {
  type RequestParamsType = {
    targetShelfPhaseId: string;
    comparisonShelfPhaseId: string;
    targetGtin: string;
  };

  const { targetShelfPhaseId, comparisonShelfPhaseId, targetGtin } = useParams<RequestParamsType>();
  const dispatch = useDispatch();
  const loadState = useSelector(selectors.getProductBenchmarkReportLoadState);
  const isDataEmpty = useSelector(selectors.isProductBenchmarkReportEmpty);
  const data = useSelector(selectors.getProductBenchmarkReportData);

  useEffect(() => {
    if (isDataEmpty && targetShelfPhaseId && comparisonShelfPhaseId && targetGtin) {
      dispatch(loadStandardProductBenchmarkReportRequestAction(targetShelfPhaseId, comparisonShelfPhaseId, parseInt(targetGtin)));
    }
  }, [dispatch, isDataEmpty, targetShelfPhaseId, comparisonShelfPhaseId, targetGtin]);
  return (
    <>
      {children({
        isLoaded: isLoaded(loadState),
        isLoading: isLoading(loadState),
        loadError: loadState?.errorDetails?.errorMessage ?? '',
        isDataEmpty,
        data,
      })}
    </>
  );
};

export { ProductBenchmarkReportLoader };
