import { plainToClass } from 'class-transformer';
import { toastr } from 'react-redux-toastr';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import apiClient from 'utils/apiClient';
import apiSaga from 'utils/apiSaga';
import ErrorDetails from '../../../infrastructure/exception/ErrorDetails';
import * as actions from './actions';
import { enableShelfConditionFailed, enableShelfConditionSuccess } from './actions';
import * as constants from './constants';
import AdminBuilderPlanogramViewModel from './types/AdminBuilderPlanogramViewModel';
import AdminPlanogramViewModel from './types/AdminPlanogramViewModel';
import CompetitorShelf from './types/CompetitorShelf';
import CompetitorShelfDataset from './types/CompetitorShelfDataset';

function getPlanogramsApi() {
  return apiClient.get('/admin/planograms');
}

function getBuildersPlanogramsApi(masterPlanogramId: string) {
  return apiClient.get(`/admin/planograms/${masterPlanogramId}/builders-planograms`);
}

function getCompetitorShelvesRequest() {
  return apiClient.get(`/shelves/competitorShelves`);
}

function getCompetitorShelvesDatasetsRequest(planogramId: string) {
  return apiClient.get(`/admin/planograms/${planogramId}/competitorShelves/datasets`);
}

function updateAdminCompetitorShelvesRequest(planogramId: string, competitorShelves: CompetitorShelf[]) {
  const requestBody = {
    planogramId,
    competitorShelves: competitorShelves,
  };
  return apiClient.post(`/admin/planograms/${planogramId}/competitorShelves`, requestBody);
}

function uploadCompetitorShelfDatasetRequest(planogramId: string, shelfReferenceId: string, dataset: CompetitorShelfDataset) {
  const requestBody = {
    ...dataset,
  };
  return apiClient.post(`/admin/planograms/${planogramId}/competitorShelves/${shelfReferenceId}/datasets`, requestBody);
}

function deleteCompetitorShelfDatasetRequest(planogramId: string, shelfReferenceId: string, dataset: CompetitorShelfDataset) {
  return apiClient.delete(`/admin/planograms/${planogramId}/competitorShelves/${shelfReferenceId}/datasets/${dataset.id}`);
}

export function enableShelfConditionServices(data: { planogramId: string; isShelfConditionsEnabled: boolean }) {
  return apiClient.put(`/planograms/${data.planogramId}/shelf-conditions`, data);
}

export function enableInspirationImagesServices(data: { planogramId: string; isShelfConditionsEnabled: boolean }) {
  const { planogramId, ...rest } = data;
  return apiClient.put(`/admin/planograms/${planogramId}/inspirationImages`, rest);
}

function* getPlanogramsApiRequest() {
  try {
    const response = yield apiSaga(getPlanogramsApi);
    const data = plainToClass(AdminPlanogramViewModel, response.data as AdminPlanogramViewModel[]);
    yield put(actions.loadAdminPlanogramsSuccess(data));
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    console.log('Error loading planograms', errorDetails);
    toastr.error('Error loading planograms', errorDetails.errorMessage);
    yield put(actions.loadAdminPlanogramsError(errorDetails));
  }
}

function* getBuildersPlanogramsApiRequest(action: any) {
  try {
    const response = yield apiSaga(getBuildersPlanogramsApi, action.masterPlanogramId);
    const data = plainToClass(AdminBuilderPlanogramViewModel, response.data as AdminBuilderPlanogramViewModel[]);
    yield put(actions.loadAdminPlanogramBuildersSuccess(data));
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    console.log('Error loading builders planograms', errorDetails);
    toastr.error('Error loading builders planograms', errorDetails.errorMessage);
    yield put(actions.loadAdminPlanogramBuildersError(errorDetails));
  }
}

function* getCompetitorShelves() {
  try {
    const response = yield apiSaga(getCompetitorShelvesRequest);
    const data = plainToClass(CompetitorShelf, response.data as CompetitorShelf[]);
    yield put(actions.loadAdminCompetitorShelvesSuccess(data));
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    console.log('Error loading similar shelves', errorDetails);
    toastr.error('Error loading similar shelves', errorDetails.errorMessage);
    yield put(actions.loadAdminCompetitorShelvesFailure(errorDetails));
  }
}

function* getCompetitorShelvesDatasets(action: any) {
  try {
    const planogramId = action.planogramId;
    const response = yield apiSaga(getCompetitorShelvesDatasetsRequest, planogramId);
    const data = plainToClass(CompetitorShelfDataset, response.data as CompetitorShelfDataset[]);
    yield put(actions.loadAdminCompetitorShelvesDatasetsSuccess(data));
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    console.log('Error loading similar shelves datasets for planogram', errorDetails);
    toastr.error('Error loading similar shelves datasets for planogram', errorDetails.errorMessage);
    yield put(actions.loadAdminCompetitorShelvesDatasetsFailure(errorDetails));
  }
}

function* updateAdminCompetitorShelves(action: any) {
  try {
    const planogramId = action.planogramId as string;
    const competitorShelves = action.competitorShelves as CompetitorShelf[];
    yield apiSaga(updateAdminCompetitorShelvesRequest, planogramId, competitorShelves);
    toastr.success('Update Success', 'Updated similar shelves successfully!');
    yield put(actions.updateAdminCompetitorShelvesSuccess(planogramId, competitorShelves));
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    console.log('Error updating similar shelves for planogram', errorDetails);
    toastr.error('Error updating similar shelves for planogram', errorDetails.errorMessage);
    yield put(actions.updateAdminCompetitorShelvesFailure(errorDetails));
  }
}

function* uploadCompetitorShelfDataset(action: any) {
  try {
    const dataset = action.dataset as CompetitorShelfDataset;
    const planogramId = dataset.planogramId;
    const shelfReferenceId = dataset.shelfReferenceId;
    const response = yield apiSaga(uploadCompetitorShelfDatasetRequest, planogramId, shelfReferenceId, dataset);
    const uploadedDatasetId = response.data as string;
    dataset.id = uploadedDatasetId;
    toastr.success('Upload Success', 'Uploaded dataset successfully!');
    yield put(actions.uploadCompetitorShelfDatasetSuccess(dataset));
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    console.log('Error uploading dataset for similar shelf', errorDetails);
    toastr.error('Error uploading dataset for similar shelf', errorDetails.errorMessage);
    yield put(actions.uploadCompetitorShelfDatasetFailure(errorDetails));
  }
}

function* deleteCompetitorShelfDataset(action: any) {
  try {
    const dataset = action.dataset as CompetitorShelfDataset;
    const planogramId = dataset.planogramId;
    const shelfReferenceId = dataset.shelfReferenceId;
    yield apiSaga(deleteCompetitorShelfDatasetRequest, planogramId, shelfReferenceId, dataset);
    toastr.success('Delete Success', 'Deleted dataset successfully!');
    yield put(actions.deleteCompetitorShelfDatasetSuccess(dataset));
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    console.log('Error deleting dataset for similar shelf', errorDetails);
    toastr.error('Error deleting dataset for similar shelf', errorDetails.errorMessage);
    yield put(actions.deleteCompetitorShelfDatasetFailure(errorDetails));
  }
}

export function* enableShelfCondition(action: ReturnType<actions.EnableShelfConditionRequestActionCreator>) {
  try {
    yield call(toastr.info, `${action.payload.isShelfConditionsEnabled ? 'Enabling' : 'Disabling'} Shelf Timelapse`, 'Saving...');
    yield call(apiSaga, enableShelfConditionServices, {
      planogramId: action.payload.planogramId,
      isShelfConditionsEnabled: action.payload.isShelfConditionsEnabled,
    });
    yield put(enableShelfConditionSuccess());
    yield call(toastr.success, 'Saved', `Shelf Timelapse is ${action.payload.isShelfConditionsEnabled ? 'Enabled' : 'Disabled'}`);
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    yield put(
      enableShelfConditionFailed({
        planogramId: action.payload.planogramId,
        errorDetails,
        previousState: !action.payload.isShelfConditionsEnabled,
      })
    );
    yield call(toastr.error, 'Error', errorDetails.errorMessage);
  }
}

export function* enableInspirationImages(action: ReturnType<actions.EnableInspirationImagesRequestCreator>) {
  try {
    yield call(apiSaga, enableInspirationImagesServices, action.payload);
    yield put(actions.enableInspirationImagesSuccess(action.payload));
  } catch (e) {
    const error: ErrorDetails = e.response.data;
    yield put(actions.enableInspirationImagesFailed(error));
    yield call(toastr.error, 'Error', error.errorMessage);
  }
}

export default function* root() {
  yield all([
    takeLatest(constants.ADMIN_PLANOGRAMS_LOAD_REQUEST, getPlanogramsApiRequest),
    takeLatest(constants.ADMIN_LOAD_PLANOGRAM_BUILDERS_REQUEST, getBuildersPlanogramsApiRequest),
    takeLatest(constants.ADMIN_LOAD_PLANOGRAM_COMPETITOR_SHELVES_REQUEST, getCompetitorShelves),
    takeLatest(constants.ADMIN_LOAD_PLANOGRAM_COMPETITOR_SHELVES_DATASETS_REQUEST, getCompetitorShelvesDatasets),
    takeLatest(constants.ADMIN_UPDATE_PLANOGRAM_COMPETITOR_SHELVES_REQUEST, updateAdminCompetitorShelves),
    takeLatest(constants.ADMIN_UPLOAD_COMPETITOR_SHELF_DATASET_REQUEST, uploadCompetitorShelfDataset),
    takeLatest(constants.ADMIN_DELETE_COMPETITOR_SHELF_DATASET_REQUEST, deleteCompetitorShelfDataset),
    takeLatest(constants.ENABLE_SHELF_CONDITION.REQUEST, enableShelfCondition),
    takeLatest(constants.ENABLE_INSPIRATION_IMAGES.REQUEST, enableInspirationImages),
  ]);
}
