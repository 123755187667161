import trackComponent from 'infrastructure/tracking/withTracking';
import ResizeObserverComponent from 'modules/shared/components/ResizeObserverComponent';
import * as React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { State } from 'state';
import * as actions from '../actions';
import PlanogramState from '../state';
import PlanogramComponent from './PlanogramComponent/PlanogramComponentWithHook';
import { isLoading, isError } from 'infrastructure/utils/RemoteObjectStatus';
import { AlertErrorTextIndicator } from 'modules/shared/components/LoadStatusIndicators/AlertErrorTextIndicator';
import { PlanogramFloatingPanel } from './PlanogramFloatingPanel';

class PublicPlanogramPage extends React.Component<PlanogramState & DispatchProps> {
  componentWillUnmount(): void {
    document.onkeydown = null;
    this.props.planogramPageReset();
  }

  componentDidMount(): void {
    this.props.loadPublicPlanogram(this.props.id, this.props.sharingToken);
  }
  render(): JSX.Element {
    return (
      <div>
        {isError(this.props.loadPlanogramStatus) ? (
          <AlertErrorTextIndicator errorText={this.props.loadPlanogramStatus.errorDetails?.errorMessage} />
        ) : (
          <LoadingOverlay
            active={isLoading(this.props.loadImageStatus) || isLoading(this.props.loadPlanogramStatus)}
            spinner={true}
            text={isLoading(this.props.loadPlanogramStatus) ? 'Loading planogram...' : isLoading(this.props.loadImageStatus) ? 'Downloading Shelf image...' : ''}
          >
            <ResizeObserverComponent
              domElementIdToWatch="content-main-element"
              domElementIdHeightToUse="content-main-parent-element"
              onResizeDetected={(width: number, height: number): void => {
                this.props.updatePlanogramViewPort(width, height);
              }}
            />
            {this.props.planogram && <PlanogramComponent />}
            {this.props.enableShowProductInfo && <PlanogramFloatingPanel canFixImageSize={false} />}
          </LoadingOverlay>
        )}
      </div>
    );
  }
}
interface DispatchProps {
  loadPublicPlanogram(id: string, sharingToken: string): void;
  toggleEnableShowProductInfo(enabled?: boolean): void;
  planogramPageReset(): void;
  updatePlanogramViewPort(width: number, height: number): void;
}

interface MatchParams {
  id: string;
  sharingToken: string;
}

function mapStateToProps(state: State, ownProps: RouteComponentProps<MatchParams>): PlanogramState {
  return {
    ...state.planogram,
    id: ownProps.match.params.id,
    sharingToken: ownProps.match.params.sharingToken,
  };
}

const componentToExport = connect<PlanogramState, DispatchProps, RouteComponentProps<MatchParams>>(mapStateToProps, actions)(PublicPlanogramPage);

export default trackComponent(componentToExport, 'PublicPlanogramPage');
