import { OnErrorRequestAction } from 'modules/shared/actions';
import { Action, createAction, PayloadAction } from 'typesafe-actions';
import ErrorDetails from '../../../infrastructure/exception/ErrorDetails';
import * as constants from './constants';
import AdminBuilderPlanogramViewModel from './types/AdminBuilderPlanogramViewModel';
import AdminPlanogramViewModel from './types/AdminPlanogramViewModel';
import CompetitorShelf from './types/CompetitorShelf';
import CompetitorShelfDataset from './types/CompetitorShelfDataset';

// Load planograms for admin
export const loadAdminPlanogramsRequest = () => ({
  type: constants.ADMIN_PLANOGRAMS_LOAD_REQUEST,
});

export const loadAdminPlanogramsError = (errorDetails: ErrorDetails): OnErrorRequestAction => ({
  type: constants.ADMIN_PLANOGRAMS_LOAD_ERROR,
  errorDetails,
});

export const loadAdminPlanogramsSuccess = (planograms: AdminPlanogramViewModel[]) => ({
  type: constants.ADMIN_PLANOGRAMS_LOAD_SUCCESS,
  planograms,
});

// Load Builders of planogram for admin
export const loadAdminPlanogramBuildersRequest = (planogramId: string) => ({
  type: constants.ADMIN_LOAD_PLANOGRAM_BUILDERS_REQUEST,
  masterPlanogramId: planogramId,
});

export const loadAdminPlanogramBuildersError = (errorDetails: ErrorDetails): OnErrorRequestAction => ({
  type: constants.ADMIN_LOAD_PLANOGRAM_BUILDERS_ERROR,
  errorDetails,
});

export const loadAdminPlanogramBuildersSuccess = (builders: AdminBuilderPlanogramViewModel[]) => ({
  type: constants.ADMIN_LOAD_PLANOGRAM_BUILDERS_SUCCESS,
  builders,
});

// Load 'Competitor Shelf' type ProjectStorePhaseShelves
export const loadAdminCompetitorShelvesRequest = () => ({
  type: constants.ADMIN_LOAD_PLANOGRAM_COMPETITOR_SHELVES_REQUEST,
});

export const loadAdminCompetitorShelvesSuccess = (competitorShelves: CompetitorShelf[]) => ({
  type: constants.ADMIN_LOAD_PLANOGRAM_COMPETITOR_SHELVES_SUCCESS,
  competitorShelves,
});

export const loadAdminCompetitorShelvesFailure = (errorDetails: ErrorDetails): OnErrorRequestAction => ({
  type: constants.ADMIN_LOAD_PLANOGRAM_COMPETITOR_SHELVES_FAILURE,
  errorDetails,
});

// Load CompetitorShelves datasets for a planogram
export const loadAdminCompetitorShelvesDatasetsRequest = (planogramId: string) => ({
  type: constants.ADMIN_LOAD_PLANOGRAM_COMPETITOR_SHELVES_DATASETS_REQUEST,
  planogramId,
});

export const loadAdminCompetitorShelvesDatasetsSuccess = (competitorShelvesDatasets: CompetitorShelfDataset[]) => ({
  type: constants.ADMIN_LOAD_PLANOGRAM_COMPETITOR_SHELVES_DATASETS_SUCCESS,
  competitorShelvesDatasets,
});

export const loadAdminCompetitorShelvesDatasetsFailure = (errorDetails: ErrorDetails): OnErrorRequestAction => ({
  type: constants.ADMIN_LOAD_PLANOGRAM_COMPETITOR_SHELVES_DATASETS_FAILURE,
  errorDetails,
});

// Update Competitor Shelves for a planogram
export const updateAdminCompetitorShelvesRequest = (planogramId: string, competitorShelves: CompetitorShelf[]) => ({
  type: constants.ADMIN_UPDATE_PLANOGRAM_COMPETITOR_SHELVES_REQUEST,
  planogramId,
  competitorShelves,
});

export const updateAdminCompetitorShelvesSuccess = (planogramId: string, competitorShelves: CompetitorShelf[]) => ({
  type: constants.ADMIN_UPDATE_PLANOGRAM_COMPETITOR_SHELVES_SUCCESS,
  planogramId,
  competitorShelves,
});

export const updateAdminCompetitorShelvesFailure = (errorDetails: ErrorDetails): OnErrorRequestAction => ({
  type: constants.ADMIN_UPDATE_PLANOGRAM_COMPETITOR_SHELVES_FAILURE,
  errorDetails,
});

// Upload Competitor Shelf Dataset
export const uploadCompetitorShelfDatasetRequest = (dataset: CompetitorShelfDataset) => ({
  type: constants.ADMIN_UPLOAD_COMPETITOR_SHELF_DATASET_REQUEST,
  dataset,
});

export const uploadCompetitorShelfDatasetSuccess = (planogramCompetitorShelfDataset: CompetitorShelfDataset) => ({
  type: constants.ADMIN_UPLOAD_COMPETITOR_SHELF_DATASET_SUCCESS,
  planogramCompetitorShelfDataset,
});

export const uploadCompetitorShelfDatasetFailure = (errorDetails: ErrorDetails): OnErrorRequestAction => ({
  type: constants.ADMIN_UPLOAD_COMPETITOR_SHELF_DATASET_FAILURE,
  errorDetails,
});

// Delete Dataset for planogram's Competitor Shelf
export const deleteCompetitorShelfDatasetRequest = (dataset: CompetitorShelfDataset) => ({
  type: constants.ADMIN_DELETE_COMPETITOR_SHELF_DATASET_REQUEST,
  dataset,
});

export const deleteCompetitorShelfDatasetSuccess = (dataset: CompetitorShelfDataset) => ({
  type: constants.ADMIN_DELETE_COMPETITOR_SHELF_DATASET_SUCCESS,
  dataset,
});

export const deleteCompetitorShelfDatasetFailure = (errorDetails: ErrorDetails): OnErrorRequestAction => ({
  type: constants.ADMIN_DELETE_COMPETITOR_SHELF_DATASET_FAILURE,
  errorDetails,
});

export interface EnableShelfConditionRequest {
  type: string;
  payload: { planogramId: string; isShelfConditionsEnabled: boolean };
}

interface EnableShelfConditionRequestPayload {
  planogramId: string;
  isShelfConditionsEnabled: boolean;
}
export type EnableShelfConditionRequestActionCreator = (data: EnableShelfConditionRequestPayload) => PayloadAction<string, EnableShelfConditionRequestPayload>;

export const enableShelfConditionRequest: EnableShelfConditionRequestActionCreator = createAction(constants.ENABLE_SHELF_CONDITION.REQUEST)<EnableShelfConditionRequestPayload>();

export type EnableShelfConditionSuccessActionCreator = () => Action;

export const enableShelfConditionSuccess: EnableShelfConditionSuccessActionCreator = createAction(constants.ENABLE_SHELF_CONDITION.SUCCESS)();

interface EnableShelfConditionFailedPayload {
  planogramId: string;
  errorDetails: ErrorDetails;
  previousState: boolean;
}
export type EnableShelfConditionFailedActionCreator = (data: EnableShelfConditionFailedPayload) => PayloadAction<string, EnableShelfConditionFailedPayload>;

export const enableShelfConditionFailed: EnableShelfConditionFailedActionCreator = createAction(constants.ENABLE_SHELF_CONDITION.FAILED)<EnableShelfConditionFailedPayload>();

type EnableInspirationImagesPayload = {
  planogramId: string;
  isInspirationImagesEnabled: boolean;
};
export type EnableInspirationImagesRequestCreator = (params: EnableInspirationImagesPayload) => PayloadAction<string, EnableInspirationImagesPayload>;
export const enableInspirationImagesRequest: EnableInspirationImagesRequestCreator = createAction(constants.ENABLE_INSPIRATION_IMAGES.REQUEST)<EnableInspirationImagesPayload>();

export type EnableInspirationImagesSuccessCreator = (params: EnableInspirationImagesPayload) => PayloadAction<string, EnableInspirationImagesPayload>;

export const enableInspirationImagesSuccess: EnableInspirationImagesSuccessCreator = createAction(constants.ENABLE_INSPIRATION_IMAGES.SUCCESS)<EnableInspirationImagesPayload>();

export type EnableInspirationImagesFailedCreator = (params: ErrorDetails) => PayloadAction<string, ErrorDetails>;
export const enableInspirationImagesFailed: EnableInspirationImagesFailedCreator = createAction(constants.ENABLE_INSPIRATION_IMAGES.FAILED)<ErrorDetails>();
