import React from 'react';
import { useDispatch } from 'react-redux';

import UploadInspirationImagesForm from './UploadInspirationImagesForm';
import { SubmittedValues, FormActions, InitialValues } from './types';
import { uploadInspirationImagesRequest } from '../../../../../entities/inspirationImages/actions';

const initialValues: InitialValues = { inspirationImages: [] };

interface UploadInspirationImagesProps {
  planogramId: string;
}

const UploadInspirationImages: React.FC<UploadInspirationImagesProps> = ({ planogramId }: UploadInspirationImagesProps): JSX.Element => {
  const dispatch = useDispatch();

  const handleOnSubmit = (values: SubmittedValues, formAction: FormActions): void => {
    const action = uploadInspirationImagesRequest(
      {
        planogramId,
        data: values,
      },
      formAction
    );
    dispatch(action);
  };

  return <UploadInspirationImagesForm initialValues={initialValues} onSubmit={handleOnSubmit} />;
};

export default UploadInspirationImages;
