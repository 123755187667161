import React from 'react';
import { Alert } from 'reactstrap';
import { Typography } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';

export interface AlertInfoIndicatorProps {
  alertColor?: string;
  textColor?: 'initial' | 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error';
  outlineColor?: 'inherit' | 'primary' | 'secondary' | 'action' | 'disabled' | 'error';
  size?: 'inherit' | 'default' | 'small' | 'large';
  infoText: string;
}

export const AlertInfoIndicator: React.FC<AlertInfoIndicatorProps> = ({
  alertColor = 'info',
  textColor = 'textPrimary',
  outlineColor = 'primary',
  size = 'small',
  infoText,
}: AlertInfoIndicatorProps) => {
  return (
    <Alert className="top-buffer" color={alertColor}>
      <Typography variant="caption" color={textColor}>
        <InfoOutlined color={outlineColor} fontSize={size} />
        &nbsp; {infoText}
      </Typography>
    </Alert>
  );
};
