import { Avatar, Grid, IconButton, Input, InputAdornment, makeStyles, Typography } from '@material-ui/core';
import { Clear, DeleteForever, Edit, Save } from '@material-ui/icons';
import moment from 'moment';
import React, { useState } from 'react';
// eslint-disable-next-line @typescript-eslint/interface-name-prefix
interface IMessageProps {
  message: string;
  username: string;
  timeOfMessage: Date;
  canEdit: boolean;
  canDelete: boolean;
  isEditSubmitting: boolean;
  isDeleteSubmitting: boolean;
  onSubmitEdit(editedMessage: string): void;
  onSubmitDelete(): void;
}
const useStyles = makeStyles(() => ({
  avatar: {
    maxWidth: 32,
    maxHeight: 32,
    marginRight: 5,
  },
  username: { display: 'inline-block', marginRight: 5 },
  timeOfMessage: { display: 'inline-block' },
  editIcon: { display: 'inline-block' },
  deleteIcon: { display: 'inline-block' },
  iconButton: { paddingLeft: 8, paddingRight: 8, paddingTop: 0, paddingBottom: 0 },
}));

const stringToHslColor = (str: string, saturation: number, lightness: number): string => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return 'hsl(' + h + ', ' + saturation + '%, ' + lightness + '%)';
};
export const Message = ({ message, username, timeOfMessage, canEdit, canDelete, isEditSubmitting, isDeleteSubmitting, onSubmitEdit, onSubmitDelete }: IMessageProps): JSX.Element => {
  const classes = useStyles();
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedMessage, setEditedMessage] = useState(message);
  const avatarColor = stringToHslColor(username, 30, 50);

  const handleOnStartEditing = () => {
    setEditedMessage(message);
    setIsEditMode(true);
  };
  const handleOnEndEditing = () => {
    setIsEditMode(false);
  };

  const handleOnSubmitEdit = (evt: any): void => {
    evt.preventDefault();
    if (!editedMessage || editedMessage === '' || editedMessage === message) {
      setIsEditMode(false);
    } else {
      onSubmitEdit(editedMessage);
      setIsEditMode(false);
    }
  };

  const handleOnSubmitDelete = (evt: any): void => {
    evt.preventDefault();
    onSubmitDelete();
  };
  return (
    <Grid container justify="flex-start" direction="row">
      <Grid item xs={1}>
        <Avatar className={classes.avatar} alt={username} src={''} style={{ color: '#F0FFFF', backgroundColor: avatarColor }}>
          {username.charAt(0).toUpperCase()}
        </Avatar>
      </Grid>
      <Grid item xs={11}>
        <div style={{ paddingLeft: '11px' }}>
          <Grid container direction="column">
            <Grid item container direction="row" justify="space-between" xs={12}>
              <Grid item xs={10}>
                <Typography className={classes.username} variant="body2">
                  {username}
                </Typography>
                <Typography className={classes.timeOfMessage} variant="caption" color="textSecondary">
                  {moment(timeOfMessage).fromNow()}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                {canEdit && (
                  <IconButton className={classes.iconButton} disabled={isEditSubmitting || isDeleteSubmitting} onClick={handleOnStartEditing}>
                    <Edit fontSize="small" />
                  </IconButton>
                )}
                {canDelete && (
                  <IconButton className={classes.iconButton} disabled={isEditSubmitting || isDeleteSubmitting} onClick={handleOnSubmitDelete}>
                    <DeleteForever fontSize="small" />
                  </IconButton>
                )}
              </Grid>
            </Grid>
            <Grid item xs={10}>
              {!isEditMode && (
                <Typography variant="body1" paragraph style={{ wordWrap: 'break-word' }}>
                  {message}
                </Typography>
              )}
              {isEditMode && (
                <form onSubmit={handleOnSubmitEdit}>
                  <Input
                    autoFocus
                    fullWidth
                    multiline
                    className="alert-warning"
                    style={{ wordWrap: 'break-word' }}
                    value={editedMessage}
                    onChange={e => setEditedMessage(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton disabled={isEditSubmitting || isDeleteSubmitting} onClick={handleOnEndEditing} size="small">
                          <Clear fontSize="small" />
                        </IconButton>
                        <IconButton disabled={isEditSubmitting || isDeleteSubmitting || editedMessage === message} type="submit" size="small">
                          <Save fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </form>
              )}
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};
