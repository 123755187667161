import * as React from 'react';
import { Route, Switch, withRouter } from 'react-router';

interface MyProps {
  routes: AsideRoute[];
}

interface AsideRoute {
  path: string;
  exact: boolean;
  name: string;
  component: any;
}

class DefaultAside extends React.Component<MyProps & any, any> {
  render(): JSX.Element {
    return (
      <Switch>
        {this.props.routes.map((route: AsideRoute, idx: number) => {
          return route.component ? <Route key={idx} path={route.path} exact={route.exact} name={route.name} component={route.component} /> : null;
        })}
      </Switch>
    );
  }
}

export default withRouter(DefaultAside);
