import { SagaIterator } from 'redux-saga';
import { takeLatest, put, call } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import ErrorDetails from 'infrastructure/exception/ErrorDetails';
import { uploadSalesDataApiRequest, deleteSalesDataApiRequest, getSalesDataApiRequest } from './apiRequests';
import apiSaga from 'utils/apiSaga';
import { TimeSalesDataReport } from './types';
import { toastr } from 'react-redux-toastr';

function* uploadSalesData(action: actionTypes.UploadSalesDataRequestAction): SagaIterator {
  try {
    const result = yield call(apiSaga, uploadSalesDataApiRequest, action.shelfReferenceId, action.reportName, action.data);
    const uploadId = result.data.salesDataId;
    console.log('salesDataId', uploadId);
    const newReport = {
      data: action.data,
      id: uploadId,
      reportName: action.reportName,
      shelfReferenceId: action.shelfReferenceId,
    } as TimeSalesDataReport;
    toastr.success('Successfully uploaded sales data', '');
    yield put(actions.uploadSalesDataSuccess(newReport));
  } catch (e) {
    const errorDetails = e as ErrorDetails;
    yield put(actions.uploadSalesDataFailed(errorDetails));
    toastr.error('Failed to upload sales data.', errorDetails.errorMessage);
  }
}

function* deleteSalesData(action: actionTypes.DeleteSalesDataRequestAction): SagaIterator {
  try {
    yield call(apiSaga, deleteSalesDataApiRequest, action.shelfReferenceId, action.reportId);
  } catch (e) {
    const errorDetails = e as ErrorDetails;
    console.log('Failed to delete', errorDetails);
  }
}

function* getSalesData(action: actionTypes.GetSalesDataRequestAction): SagaIterator {
  try {
    if (!action) {
      console.log('ignoring');
      return;
    }
    const response = yield call(apiSaga, getSalesDataApiRequest, action.shelfReferenceId);

    const result: TimeSalesDataReport[] = response.data;
    result.forEach(r => {
      r.data.forEach(row => {
        row.timestamp = new Date(row.timestamp);
      });
    });
    yield put(actions.getSalesDataSuccess(result));
  } catch (e) {
    const errorDetails = e as ErrorDetails;
    console.log('Error loading sales data', errorDetails);
    toastr.error('Failed to load sales data reports', errorDetails.errorMessage);
    yield put(actions.getSalesDataError(errorDetails));
  }
}

function* watch(): SagaIterator {
  yield takeLatest(actionTypes.SHELVES_TIMELINE_UPLOAD_SALES_REQUEST, uploadSalesData);
  yield takeLatest(actionTypes.SHELVES_TIMELINE_DELETE_SALES_DATA_REQUEST, deleteSalesData);
  yield takeLatest(actionTypes.SHELVES_TIMELINE_GET_SALES_DATA_REQUEST, getSalesData);
}

export default watch;
