import { Card, CardContent, CardHeader, Collapse, Divider, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Delete, Edit, ErrorOutline, ExpandLess, ExpandMore, Info, Launch } from '@material-ui/icons';
import * as React from 'react';
import { Alert } from 'reactstrap';
import trackComponent from '../../../../infrastructure/tracking/withTracking';
import { getDateInInputFormat } from '../../../../utils/dateHelper';
import TestInfoDto from '../../types/TestInfoDto';

interface TestsListState {
  isExpandedCardHead: { [testId: string]: boolean };
}

interface TestsListProps {
  testsInfoList: TestInfoDto[];
  isLoadingTestsInfo: boolean;
  isLoadingTestsInfoSuccess: boolean;
  isLoadingTestsInfoFailure: boolean;

  openEditTestModal(testInfo: TestInfoDto): void;

  openDeleteTestConfirmationModal(testToDelete: TestInfoDto): void;

  loadTestsInfo(): void;
}

class TestsListView extends React.Component<TestsListProps, TestsListState> {
  constructor(props: TestsListProps, state: TestsListState) {
    super(props, state);

    this.state = {
      isExpandedCardHead: {}, // dictionary of booleans to collapse individual test card contents
    };
  }

  render(): JSX.Element {
    return (
      <React.Fragment>
        {this.props.isLoadingTestsInfoFailure
          ? this.renderErrorState()
          : this.props.isLoadingTestsInfo
          ? this.renderLoadingState()
          : this.props.testsInfoList.length === 0
          ? this.renderEmptyState()
          : this.renderTests()}
      </React.Fragment>
    );
  }

  renderErrorState(): JSX.Element {
    return (
      <Alert className="top-buffer" color="danger">
        <Typography variant="caption" color="textSecondary">
          <ErrorOutline color="secondary" fontSize="small" />
          &nbsp;Failed to load shelf tests.
        </Typography>
      </Alert>
    );
  }

  renderEmptyState(): JSX.Element {
    return (
      <Alert className="top-buffer" color="info">
        <Typography variant="caption" color="textSecondary">
          <Info color="primary" fontSize="small" />
          &nbsp;No shelf tests have been requested for this planogram yet.
        </Typography>
      </Alert>
    );
  }

  renderLoadingState(): JSX.Element {
    return (
      <Grid container justify="center">
        <CircularProgress />
      </Grid>
    );
  }

  renderTests(): JSX.Element {
    return (
      <div>
        <Typography color="primary" style={{ fontSize: '14px' }} variant="subtitle1">
          Shelf Tests
        </Typography>
        <Divider className="top-buffer bottom-buffer" light={true} variant="fullWidth" />
        {this.props.testsInfoList.map(testInfo => {
          return this.renderTestInfoCard(testInfo);
        })}
      </div>
    );
  }

  toggleExpandCardHead(id: string): void {
    const newIsExpandedCardHeadState = { ...this.state.isExpandedCardHead };
    if (this.state.isExpandedCardHead === null || this.state.isExpandedCardHead[id] === null || this.state.isExpandedCardHead[id] === undefined) {
      newIsExpandedCardHeadState[id] = true;
    } else {
      newIsExpandedCardHeadState[id] = !this.state.isExpandedCardHead[id];
    }
    this.setState({ isExpandedCardHead: newIsExpandedCardHeadState });
  }

  renderTestInfoCard(testInfo: TestInfoDto): JSX.Element {
    return (
      <Card key={testInfo.id} className="top-buffer card-accent-danger border-danger bottom-buffer">
        <CardHeader
          disableTypography={true}
          action={
            <div>
              <Tooltip title="Open Shelf Test">
                <IconButton href={window.location.origin + '/planograms/' + testInfo.initialPlanogramId + '/test/' + testInfo.id} target="_blank">
                  <Launch fontSize="small" color="primary" />
                </IconButton>
              </Tooltip>

              <Tooltip title="Edit Test">
                <IconButton onClick={(): void => this.props.openEditTestModal(testInfo)}>
                  <Edit fontSize="small" color="primary" />
                </IconButton>
              </Tooltip>

              <Tooltip title="Delete Test">
                <IconButton onClick={(): void => this.props.openDeleteTestConfirmationModal(testInfo)}>
                  <Delete fontSize="small" color="secondary" />
                </IconButton>
              </Tooltip>

              <IconButton
                onClick={(): void => {
                  this.toggleExpandCardHead(testInfo.id);
                }}
              >
                {this.state.isExpandedCardHead[testInfo.id] !== null && this.state.isExpandedCardHead[testInfo.id] ? (
                  <ExpandLess fontSize="small" color="primary" />
                ) : (
                  <ExpandMore fontSize="small" color="primary" />
                )}
              </IconButton>
            </div>
          }
          title={
            <Typography
              variant="subtitle2"
              style={{
                overflowWrap: 'break-word',
                wordBreak: 'break-word',
              }}
            >
              {testInfo.testName}
            </Typography>
          }
        />
        <Collapse in={this.state.isExpandedCardHead[testInfo.id]}>
          <Divider light variant="middle" />
          <CardContent>
            {
              <div>
                <div>
                  <Typography variant="caption" color="textPrimary">
                    Store: {testInfo.operationsInfo.storeName}{' '}
                  </Typography>
                </div>
                <div>
                  <Typography color="textSecondary" variant="caption">
                    Created On: {getDateInInputFormat(testInfo.createdOn)}
                  </Typography>
                </div>

                <div>
                  <Typography variant="caption" color="textSecondary">
                    Start Date: {getDateInInputFormat(testInfo.operationsInfo.startDate)}
                  </Typography>
                </div>
                <div>
                  <Typography variant="caption" color="textSecondary">
                    End Date: {getDateInInputFormat(testInfo.operationsInfo.endDate)}
                  </Typography>
                </div>
              </div>
            }
          </CardContent>
        </Collapse>
      </Card>
    );
  }
}

export default trackComponent(TestsListView, 'Aside_TestsListView');
