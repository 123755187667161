import { ShelfConditionsTarget, WeekOption } from 'modules/shelfTimelapse/state/types';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import SalesReportComponent from './SalesReport';
import ShelfConditionContainer from './ShelfTimelapseContainer';
import { getSelectedWeekOption, getWeekOptionsInTimelapse } from './state/selectors';
import { WeekSelector } from './WeekSelector';
import { updateSelectedTimelapseWeek } from './state/actions';

const ShelfTimelapsePage: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const { shelfReferenceId } = useParams();
  const selectedWeekOption = useSelector(getSelectedWeekOption);
  const weekOptions = useSelector(getWeekOptionsInTimelapse);
  const handleOnSelectWeek = (selectedWeek: WeekOption): void => {
    dispatch(updateSelectedTimelapseWeek(selectedWeek.value));
  };
  return (
    <>
      <WeekSelector
        handleOnSelectWeek={(selectedWeek: WeekOption) => handleOnSelectWeek(selectedWeek)}
        htmlId={'shelf-timelapse-week-selector'}
        selectedWeek={selectedWeekOption}
        weekOptions={weekOptions}
      />
      <SalesReportComponent />
      <ShelfConditionContainer
        targetId={shelfReferenceId as string}
        targetType={ShelfConditionsTarget.realogram}
        open={true}
        onClose={(): void => {
          console.log('dialog closed');
        }}
      />
    </>
  );
};

export default ShelfTimelapsePage;
