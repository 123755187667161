import { Badge, Card, CardContent, CardHeader, Collapse, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp, ExpandLess, ExpandMore, Loyalty } from '@material-ui/icons';
import * as React from 'react';
import trackComponent from 'infrastructure/tracking/withTracking';
import { BrandResizeReason, RecordChangeReasonDto } from 'modules/planogram/types/PlanogramChangeReasons';
import { BrandResized } from 'modules/planogram/types/PlanogramChanges';
import PlanogramModel from 'modules/planogram/domain/PlanogramModel';
import ProductModel from 'modules/planogram/types/ProductModel';

interface MyProps {
  currentPlanogram: PlanogramModel;
  products: ProductModel[];
  onHighlightBrand(priority: boolean, brandId?: number): void;
  onRecordChangeReason(reason: RecordChangeReasonDto): void;
}

interface MyState {
  isExpandedBrandsHeader: boolean;
  brandResizeReason: string;
  reasons: BrandReason[];
}

interface BrandReason {
  brandId: number;
  reason: string;
}

class BrandsPreviewChanges extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {
      isExpandedBrandsHeader: false,
      brandResizeReason: '',
      reasons: [],
    };
  }

  componentDidUpdate() {
    const reasons = this.state.reasons;
    let needsUpdate = false;
    this.props.currentPlanogram.brandSpaces.forEach(bs => {
      let existingReason = reasons.find(r => r.brandId === bs.brandId);
      if (!existingReason) {
        const savedReason = this.props.currentPlanogram.changeReasons.brandResizeReasons.find(brr => brr.brandId === bs.brandId);
        existingReason = {
          brandId: bs.brandId,
          reason: savedReason ? savedReason.reason : '',
        };
        needsUpdate = true;
        reasons.push(existingReason);
      }
    });
    if (needsUpdate) {
      this.setState({ reasons });
    }
  }

  render() {
    const brandsChanged: BrandResized[] = this.props.currentPlanogram.planogramChanges.brandsResized;
    const rows = brandsChanged.map(brandResized => {
      const initialSpace = brandResized.initialSpace;
      const currentSpace = brandResized.currentSpace;
      const changeReason = this.state.reasons.find(r => r.brandId === brandResized.brandId);

      if (!changeReason) {
        // still not initialized, wait in next render.
        return null;
      }
      return (
        <div
          key={brandResized.brandId}
          onMouseEnter={(): void => {
            this.props.onHighlightBrand(false, brandResized.brandId);
          }}
          onMouseLeave={(): void => {
            this.props.onHighlightBrand(false, undefined);
          }}
        >
          <div>
            <li>
              <Grid container direction="row" justify="space-around" alignContent="center" alignItems="center">
                <Typography color="textPrimary" variant="body2">
                  {brandResized.brandName}
                </Typography>
                {initialSpace - currentSpace > 0 ? <ArrowDropDown color="secondary" fontSize="small" /> : <ArrowDropUp color="primary" fontSize="small" />}
              </Grid>
            </li>
          </div>
          <div>
            <Typography variant="caption" color="textSecondary">
              Initial Space:
              {initialSpace.toFixed(2)} m<sup>2</sup>
            </Typography>
          </div>
          <div>
            <Typography variant="caption" color="textSecondary">
              Current Space:
              {currentSpace.toFixed(2)} m<sup>2</sup>
            </Typography>
          </div>
          {!this.props.currentPlanogram.isReadOnly ? this.renderReasonText(changeReason, brandResized.brandId, initialSpace, currentSpace) : this.renderReadOnlyReason(changeReason.reason)}
        </div>
      );
    });
    return (
      <div>
        <Card raised className="card-accent-info border-info bottom-buffer">
          <CardHeader
            style={{ background: 'rgb(102, 178, 255,0.2)' }}
            disableTypography={true}
            action={
              <div>
                <Badge color="secondary" badgeContent={brandsChanged.length}>
                  <Loyalty color="primary" />
                </Badge>
                <IconButton onClick={(): void => this.setState({ isExpandedBrandsHeader: !this.state.isExpandedBrandsHeader })}>
                  {this.state.isExpandedBrandsHeader ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
                </IconButton>
              </div>
            }
            title={<Typography variant="button">Brands Share of Space</Typography>}
          />
          <Collapse in={this.state.isExpandedBrandsHeader} timeout="auto">
            <CardContent style={{ marginLeft: '0px', paddingLeft: '0px' }}>
              {rows.length === 0 ? (
                <div className="left-buffer">
                  <Typography color="textSecondary" variant="caption">
                    No brands changed.
                  </Typography>
                </div>
              ) : (
                <div>
                  <ol className="pull-left">{rows}</ol>
                </div>
              )}
            </CardContent>
          </Collapse>
        </Card>
      </div>
    );
  }
  renderReadOnlyReason(reason: string): React.ReactNode {
    return (
      <Typography variant="caption" color="textSecondary">
        Reason for change: <strong>{reason ? reason : 'N/A'}</strong>
      </Typography>
    );
  }

  private renderReasonText(changeReason: BrandReason, brandId: number, initialSize: number, currentSize: number) {
    return (
      <div>
        <TextField
          value={changeReason.reason ?? ''}
          onChange={evt => {
            const theReason = evt.target.value ? evt.target.value : '';
            changeReason.reason = theReason;
            this.setState({ reasons: this.state.reasons });
          }}
          id="standard-name"
          label="Reason for change"
          margin="none"
          onFocus={() => {
            this.props.onHighlightBrand(true, brandId);
          }}
          onBlur={() => {
            this.props.onHighlightBrand(true, undefined);
            const existingReason = this.props.currentPlanogram.changeReasons.brandResizeReasons.find(r => r.brandId === brandId);
            changeReason.reason = changeReason.reason ? changeReason.reason.trim() : '';
            if (existingReason && existingReason.reason === changeReason.reason) {
              return;
            }
            const reason = new RecordChangeReasonDto(this.props.currentPlanogram.id);
            const brandResizeReason = new BrandResizeReason(brandId, initialSize, currentSize, changeReason.reason);
            reason.brandResizeReason = brandResizeReason;
            this.props.onRecordChangeReason(reason);
          }}
        />
      </div>
    );
  }
}

export default trackComponent(BrandsPreviewChanges, 'Aside_BrandsPreviewChanges');
