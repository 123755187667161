import { Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { toMaxPrecision, toMaxPrecisionPercentFloored } from 'utils/Helpers';
import flowLogo from '../../../../../assets/img/brand/flow.jpg';
import { InteractorsType, ProductConversionShopperCategoryType, ProductDetailedConversionResult, ProductReportResult } from '../../types';
import { InteractorsStackedBarChart } from '../InteractorsStackedBarChart';
import './StandardProductReportPage.css';
import '../styles/StandardReportStyle.css';
import '../styles/StandardReportProductImageStyle.css';
export interface StandardProductReportPageProps {
  productReport: ProductReportResult;
}
const getProductChartData = (conversionReport: ProductDetailedConversionResult[]): object[] => {
  const result = [{}] as object[];
  Object.keys(ProductConversionShopperCategoryType).forEach(category => {
    result[0][category] = toMaxPrecisionPercentFloored(conversionReport?.find(x => x.productConversionShopperCategory === ProductConversionShopperCategoryType[category])?.shopperPercent, 2);
  });
  return result;
};

const StandardProductReportPage: React.FC<StandardProductReportPageProps> = ({ productReport }: StandardProductReportPageProps) => {
  const productOverviewReport = productReport.productOverviewReport;
  const productDemographicsReport = productReport.productDemographicsReport;
  const productBoughtTogetherReport = productReport.productsBoughtTogetherReport;
  const productWinningOverReport = productReport.productsWinningOverReport;
  const productLosingToReport = productReport.productsLosingToReport;
  const productShelfLocationReport = productReport.productShelfLocationReport;
  const productDetailedConversionReport = productReport.productDetailedConversionReport;

  return (
    <div className="page">
      <div id="flowLogo">
        <img alt="flow logo" className="img-responsive text-center" src={flowLogo} />
      </div>
      <div style={{ width: '100%' }}>
        <h3 className="text-center productTitle">{productOverviewReport.tradeItemDescription}</h3>
        <h5 className="text-center">
          {productOverviewReport.projectName} - {productOverviewReport.storeName} - Phase {productOverviewReport.phaseNum}
          <br />
          {moment(productOverviewReport.dateFrom).format('DD/MM/YYYY')} - {moment(productOverviewReport.dateTo).format('DD/MM/YYYY')}
        </h5>
        <h5 className="text-left">
          <b>Gtin: </b>
          {productOverviewReport.gtin}
          <br />
          <b>Vertical shelf location: </b>
          {productShelfLocationReport.verticalLocation}/{productShelfLocationReport.maxVerticalLocation}
          <br />
          <b>Visitors: </b>
          {productOverviewReport.totalVisitors}
          <br />
          <b>Product Interactors: </b>
          {productOverviewReport.totalShoppersInteractedSKU}
        </h5>
        <div>
          <img alt="product img" className="img-responsive productImg" src={productOverviewReport.productImageURL} />
        </div>
        <div className="row">
          <table className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-center metricNameCell">Product Conversion rate(percent)</td>
                <td className="text-center metricNameCell">Product turnover per visitor(amount)</td>
                <td className="text-center metricNameCell">Product daily turnover(average amount)</td>
                <td className="text-center metricNameCell">Product destination buyers(percent)</td>
              </tr>
              <tr>
                <td className="text-center">
                  {productOverviewReport.productConversionRate.toLocaleString('en', {
                    style: 'percent',
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td className="text-center">{toMaxPrecision(productOverviewReport.productTurnoverPerVisitor, 2)}</td>
                <td className="text-center">{toMaxPrecision(productOverviewReport.averageProductDailyTurnover, 2)}</td>
                <td className="text-center">
                  {productOverviewReport.productDestinationBuyersShare.toLocaleString('en', {
                    style: 'percent',
                    maximumFractionDigits: 2,
                  })}
                </td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">Product return ratio(percent)</td>
                <td className="text-center metricNameCell">Product lost shoppers(percent)</td>
                <td className="text-center metricNameCell">Product out of shelf(daily hours)</td>
                <td className="text-center metricNameCell">Product out of stock(percent)</td>
              </tr>
              <tr>
                <td className="text-center">
                  {productOverviewReport.productReturnRatio.toLocaleString('en', {
                    style: 'percent',
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td className="text-center">
                  {productOverviewReport.productLostShoppersPercent.toLocaleString('en', {
                    style: 'percent',
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td className="text-center">{productOverviewReport.averageProductOosHours ? toMaxPrecision(productOverviewReport.averageProductOosHours, 2) : '0'}</td>
                <td className="text-center">
                  {productOverviewReport.productOosTimeShare
                    ? productOverviewReport.productOosTimeShare.toLocaleString('en', {
                        style: 'percent',
                        maximumFractionDigits: 2,
                      })
                    : '0%'}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row">
          <table className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-left metricNameCell">Share of space</td>
                <td className="text-left">
                  {productOverviewReport.shareOfSpace.toLocaleString('en', {
                    style: 'percent',
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td className="text-left metricNameCell">Discovery time</td>
                <td className="text-left">{toMaxPrecision(productOverviewReport.discoveryTime, 2)}</td>
              </tr>
              <tr>
                <td className="text-left metricNameCell">Share of unit sales</td>
                <td className="text-left">
                  {productOverviewReport.shareOfUnitSales.toLocaleString('en', {
                    style: 'percent',
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td className="text-left metricNameCell">Seconds to buy and leave (buyers)</td>
                <td className="text-left">{toMaxPrecision(productOverviewReport.secondsToBuyAndLeave, 2)}</td>
              </tr>
              <tr>
                <td className="text-left metricNameCell">Share of turnover</td>
                <td className="text-left">
                  {productOverviewReport.shareOfTurnover.toLocaleString('en', {
                    style: 'percent',
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td className="text-left metricNameCell">Seconds to touch and reject (non-buyers)</td>
                <td className="text-left">{toMaxPrecision(productOverviewReport.secondsToTouchAndReject, 2)}</td>
              </tr>
              <tr>
                <td className="text-left metricNameCell">Share of first pickups</td>
                <td className="text-left">
                  {productOverviewReport.shareOfFirstPickups.toLocaleString('en', {
                    style: 'percent',
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td className="text-left metricNameCell">Substitutions (number of shoppers)</td>
                <td className="text-left">{toMaxPrecision(productOverviewReport.substitutionShopperCount, 2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row">
          <table className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-center metricNameCell">Mostly bought with</td>
                <td className="text-center metricNameCell">Mostly losing to</td>
                <td className="text-center metricNameCell">Mostly winning over</td>
              </tr>
              <tr>
                <td className="text-center">
                  {productBoughtTogetherReport.length >= 1
                    ? `
                    ${productBoughtTogetherReport[0].comparisonTradeItemDescription} (
                    ${productBoughtTogetherReport[0].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {productLosingToReport.length >= 1
                    ? `
                    ${productLosingToReport[0].comparisonTradeItemDescription} (
                    ${productLosingToReport[0].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {productWinningOverReport.length >= 1
                    ? `
                    ${productWinningOverReport[0].comparisonTradeItemDescription} (
                    ${productWinningOverReport[0].numberOfShoppers})`
                    : ''}
                </td>
              </tr>
              <tr>
                <td className="text-center">
                  {productBoughtTogetherReport.length >= 2
                    ? `
                    ${productBoughtTogetherReport[1].comparisonTradeItemDescription} (
                    ${productBoughtTogetherReport[1].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {productLosingToReport.length >= 2
                    ? `
                    ${productLosingToReport[1].comparisonTradeItemDescription} (
                    ${productLosingToReport[1].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {productWinningOverReport.length >= 2
                    ? `
                    ${productWinningOverReport[1].comparisonTradeItemDescription} (
                    ${productWinningOverReport[1].numberOfShoppers})`
                    : ''}
                </td>
              </tr>
              <tr>
                <td className="text-center">
                  {productBoughtTogetherReport.length >= 3
                    ? `
                    ${productBoughtTogetherReport[2].comparisonTradeItemDescription} (
                    ${productBoughtTogetherReport[2].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {productLosingToReport.length >= 3
                    ? `
                    ${productLosingToReport[2].comparisonTradeItemDescription} (
                    ${productLosingToReport[2].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {productWinningOverReport.length >= 3
                    ? `
                    ${productWinningOverReport[2].comparisonTradeItemDescription} (
                    ${productWinningOverReport[2].numberOfShoppers})`
                    : ''}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row">
          <table className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-center metricNameCell">Demographic</td>
                <td className="text-center metricNameCell">Product interactors</td>
                <td className="text-center metricNameCell">Product buyers</td>
                <td className="text-center metricNameCell">Product lost shoppers</td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">{productDemographicsReport.find(x => x.demographicGroup === 'All')?.demographicGroup}</td>
                <td className="text-center">
                  {productDemographicsReport
                    .find(x => x.demographicGroup === 'All')
                    ?.interactorsPercent.toLocaleString('en', {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    })}
                </td>
                <td className="text-center">
                  {productDemographicsReport
                    .find(x => x.demographicGroup === 'All')
                    ?.buyersPercent.toLocaleString('en', {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    })}
                </td>
                <td className="text-center">
                  {productDemographicsReport
                    .find(x => x.demographicGroup === 'All')
                    ?.lostShoppersPercent.toLocaleString('en', {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    })}
                </td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">{productDemographicsReport.find(x => x.demographicGroup === 'Female')?.demographicGroup}</td>
                <td className="text-center">
                  {productDemographicsReport
                    .find(x => x.demographicGroup === 'Female')
                    ?.interactorsPercent.toLocaleString('en', {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    })}
                </td>
                <td className="text-center">
                  {productDemographicsReport
                    .find(x => x.demographicGroup === 'Female')
                    ?.buyersPercent.toLocaleString('en', {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    })}
                </td>
                <td className="text-center">
                  {productDemographicsReport
                    .find(x => x.demographicGroup === 'Female')
                    ?.lostShoppersPercent.toLocaleString('en', {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    })}
                </td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">{productDemographicsReport.find(x => x.demographicGroup === 'Male')?.demographicGroup}</td>
                <td className="text-center">
                  {productDemographicsReport
                    .find(x => x.demographicGroup === 'Male')
                    ?.interactorsPercent.toLocaleString('en', {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    })}
                </td>
                <td className="text-center">
                  {productDemographicsReport
                    .find(x => x.demographicGroup === 'Male')
                    ?.buyersPercent.toLocaleString('en', {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    })}
                </td>
                <td className="text-center">
                  {productDemographicsReport
                    .find(x => x.demographicGroup === 'Male')
                    ?.lostShoppersPercent.toLocaleString('en', {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    })}
                </td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">{productDemographicsReport.find(x => x.demographicGroup === 'With Kids')?.demographicGroup}</td>
                <td className="text-center">
                  {productDemographicsReport
                    .find(x => x.demographicGroup === 'With Kids')
                    ?.interactorsPercent.toLocaleString('en', {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    })}
                </td>
                <td className="text-center">
                  {productDemographicsReport
                    .find(x => x.demographicGroup === 'With Kids')
                    ?.buyersPercent.toLocaleString('en', {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    })}
                </td>
                <td className="text-center">
                  {productDemographicsReport
                    .find(x => x.demographicGroup === 'With Kids')
                    ?.lostShoppersPercent.toLocaleString('en', {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    })}
                </td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">{productDemographicsReport.find(x => x.demographicGroup === 'Group')?.demographicGroup}</td>
                <td className="text-center">
                  {productDemographicsReport
                    .find(x => x.demographicGroup === 'Group')
                    ?.interactorsPercent.toLocaleString('en', {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    })}
                </td>
                <td className="text-center">
                  {productDemographicsReport
                    .find(x => x.demographicGroup === 'Group')
                    ?.buyersPercent.toLocaleString('en', {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    })}
                </td>
                <td className="text-center">
                  {productDemographicsReport
                    .find(x => x.demographicGroup === 'Group')
                    ?.lostShoppersPercent.toLocaleString('en', {
                      style: 'percent',
                      maximumFractionDigits: 2,
                    })}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Grid container alignContent="center" alignItems="center" justify={'center'} style={{ marginTop: '10px' }} direction="column">
        <Grid item>
          <Typography variant="overline">Detailed interaction profile</Typography>
        </Grid>
        <Grid item>
          <InteractorsStackedBarChart data={getProductChartData(productDetailedConversionReport)} interactorsType={InteractorsType.Product} />
        </Grid>
      </Grid>
      <footer className="footer">
        <strong>Copyright &copy; {new Date().getFullYear()} Flow Insights</strong>. Strictly confidential. No distribution allowed. All rights reserved.
      </footer>
    </div>
  );
};

export { StandardProductReportPage };
