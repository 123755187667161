import trackComponent from 'infrastructure/tracking/withTracking';
import { isError, isLoaded, isLoading } from 'infrastructure/utils/RemoteObjectStatus';
import { last } from 'lodash';
import { UserPreferencesDto } from 'modules/auth/types/UserPreferencesDto';
import { AlertErrorTextIndicator } from 'modules/shared/components/LoadStatusIndicators/AlertErrorTextIndicator';
import ResizeObserverComponent from 'modules/shared/components/ResizeObserverComponent';
import * as React from 'react';
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS } from 'react-joyride';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import PulseLoader from 'react-spinners/PulseLoader';
import { State } from 'state';
import { Key } from 'ts-key-enum';
import * as actions from '../actions';
import CutFacingsCommand from '../commands/CutFacingsCommand';
import DeleteCommand from '../commands/DeleteCommand';
import DuplicateNextToCommand from '../commands/DuplicateNextToCommand';
import { PlanogramCommand, PlanogramCommandManager } from '../commands/_planogram_commands';
import PlanogramModel from '../domain/PlanogramModel';
import ShelfLocation from '../domain/ShelfLocation';
import { getProductTourSteps } from '../helpers/productTourSteps';
import PlanogramState from '../state';
import CreateTestPlanogramRequestDto from '../types/CreateTestPlanogramRequestDto';
import { NewProductModel } from '../types/NewProductModel';
import TestInfoDto from '../types/TestInfoDto';
import DeleteTestConfirmationModal from './modals/DeleteTestConfirmationModal';
import EditTestModal from './modals/EditTestModal';
import TestWithFlowModal from './modals/TestWithFlowModal';
import UnstableStateModal from './modals/UnstableStateModal';
import PlanogramComponent from './PlanogramComponent/PlanogramComponentWithHook';
import { PlanogramFloatingPanel } from './PlanogramFloatingPanel';
import RightContextMenuShelfLocation from './RightContextMenuShelfLocation';

class PlanogramPage extends React.Component<PlanogramState & DispatchProps> {
  componentWillUnmount(): void {
    document.removeEventListener('keydown', this.handleKeyDown);
    this.props.planogramPageReset();
    this.props.closeAside();
  }

  componentDidUpdate(prevProps: PlanogramState & DispatchProps): void {
    if (!isLoading(this.props.loadPlanogramStatus) && isLoaded(this.props.loadImageStatus) && !isLoaded(prevProps.loadImageStatus) && this.props.planogram !== undefined) {
      // Run planogram product tour if !UserPeferences.ProductTour.hasFinishedTour
      if (!this.props.userPreferences.productTour.hasFinishedTour) {
        this.props.runProductTour();
      }
    }
  }

  componentDidMount(): void {
    this.props.loadPlanogram(this.props.id);
    document.addEventListener('keydown', this.handleKeyDown);
  }

  render(): JSX.Element {
    let loadingMessage = '';
    if (isLoading(this.props.loadPlanogramStatus)) {
      loadingMessage = 'Loading planogram...';
    } else if (this.props.loadImageStatus) {
      loadingMessage = 'Downloading Shelf image...';
    } else if (this.props.savingChanges) {
      loadingMessage = 'Saving changes...';
    }
    return (
      <div>
        {isError(this.props.loadPlanogramStatus) ? (
          <AlertErrorTextIndicator errorText={this.props.loadPlanogramStatus.errorDetails?.errorMessage} />
        ) : (
          <LoadingOverlay
            fadeSpeed={0}
            active={isLoading(this.props.loadImageStatus) || isLoading(this.props.loadPlanogramStatus) || this.props.savingChanges}
            spinner={
              <>
                <h3 style={{ color: '#031f5c' }}>{loadingMessage}</h3>
                <PulseLoader color={'#031f5c'} css="margin-top: 50px;" />
              </>
            }
          >
            <ResizeObserverComponent
              domElementIdToWatch="content-main-element"
              domElementIdHeightToUse="content-main-parent-element"
              onResizeDetected={(width: number, height: number): void => {
                this.props.updatePlanogramViewPort(width, height);
              }}
            />
            {this.props.planogram && isLoaded(this.props.loadImageStatus) && <PlanogramComponent />}
            {this.props.enableShowProductInfo && <PlanogramFloatingPanel canFixImageSize={true} />}

            {this.props.showTestWithFlowModal && this.props.planogram ? (
              <TestWithFlowModal
                initialPlanogram={this.props.planogram}
                onClose={this.props.hideTestWithFlowModal}
                createTestPlanogram={(createTestPlanogramDto: CreateTestPlanogramRequestDto): void => {
                  this.props.createTestPlanogramRequest(createTestPlanogramDto);
                }}
                createTestPlanogramSuccess={isLoaded(this.props.loadCreatingTestPlanogramStatus)}
                isCreatingTestPlanogram={isLoading(this.props.loadCreatingTestPlanogramStatus)}
                shelfSvg={undefined}
              />
            ) : null}

            {this.props.showEditTestModal ? (
              <EditTestModal
                testInfo={this.props.testToEdit}
                onClose={this.props.hideEditTestModal}
                updateTestInfo={(testInfo: TestInfoDto): void => this.props.updateTestInfoRequest(testInfo)}
                isUpdatingTestInfo={isLoading(this.props.updateTestInfoStatus)}
                isUpdateTestInfoSuccess={isLoaded(this.props.updateTestInfoStatus)}
                isUpdateTestInfoFailure={isError(this.props.updateTestInfoStatus)}
              />
            ) : null}

            {this.props.showDeleteTestConfirmationModal ? (
              <DeleteTestConfirmationModal
                testInfo={this.props.testToDelete}
                enabled={!isLoading(this.props.deleteTestStatus)}
                onClose={this.props.hideDeleteTestConfirmationModal}
                onConfirmed={(planogramId: string, testIdToDelete: string): void => {
                  this.props.deleteTestRequest(planogramId, testIdToDelete);
                }}
                isDeleting={isLoading(this.props.deleteTestStatus)}
              />
            ) : null}
            {isLoaded(this.props.loadImageStatus) && isLoaded(this.props.loadPlanogramStatus) && this.props.productTourState.runProductTour ? (
              <Joyride
                continuous={true}
                run={this.props.productTourState.runProductTour}
                steps={getProductTourSteps()}
                stepIndex={this.props.productTourState.productTourStepIndex}
                scrollToFirstStep={true}
                disableCloseOnEsc={true}
                disableOverlayClose={true}
                hideBackButton={true}
                showProgress={true}
                showSkipButton={true}
                callback={this.handleJoyrideCallback}
              />
            ) : null}
            {isLoaded(this.props.loadPlanogramStatus) && isLoaded(this.props.loadImageStatus) && (
              <>
                {this.props.contextMenuElement !== undefined ? (
                  <RightContextMenuShelfLocation
                    isOpen={this.props.isOpenRightContextMenu}
                    element={this.props.contextMenuElement}
                    deleteShelfLocation={(): void => {
                      this.handleCloseContextMenu();
                      if (!this.props.planogram) {
                        return;
                      }
                      const deleteCommand = new DeleteCommand(this.props.planogram, this.props.selectedShelfLocations);
                      this.props.onCommandExecutionRequest(deleteCommand);
                      this.props.onSelectShelfLocationsRequest([], false);
                    }}
                    copyShelfLocation={(): void => {
                      this.handleCloseContextMenu();
                      if (!this.props.planogram) {
                        return;
                      }
                      if (this.props.selectedShelfLocations.length > 0) {
                        const shelfLocationToDuplicate = last(this.props.selectedShelfLocations);
                        if (shelfLocationToDuplicate) {
                          const command = new DuplicateNextToCommand(this.props.planogram, shelfLocationToDuplicate);
                          this.props.onCommandExecutionRequest(command);
                          this.props.onSelectShelfLocationsRequest([], false);
                        }
                      }
                    }}
                    removeFacing={(): void => {
                      this.handleCloseContextMenu();
                      if (!this.props.planogram) {
                        return;
                      }
                      if (this.props.selectedShelfLocations.length > 0) {
                        const shelfLocationToCut = last(this.props.selectedShelfLocations);
                        if (shelfLocationToCut) {
                          const command = new CutFacingsCommand(this.props.planogram, shelfLocationToCut, 1);
                          this.props.onCommandExecutionRequest(command);
                        }
                      }
                    }}
                  />
                ) : null}
              </>
            )}
          </LoadingOverlay>
        )}
        {this.props.inUnstableState ? <UnstableStateModal /> : null}
      </div>
    );
  }
  private handleJoyrideCallback = (data: CallBackProps): void => {
    const { action, index, type, status } = data;

    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      this.props.stopProductTour();
      if (!this.props.userPreferences.productTour.hasFinishedTour) {
        this.props.updateHasFinishedProductTourRequest();
      }
    } else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
      const productTourStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      this.props.updateProductStoreStepIndex(productTourStepIndex);
    } else if (([EVENTS.STEP_BEFORE, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
      if (index === 5) {
        // open side bar here
        this.props.toggleAside();
      } else if (index === 6) {
        // change tab
        this.props.togglePlanogramAsideActiveTab(1);
      } else if (index === 7) {
        // change tab
        this.props.togglePlanogramAsideActiveTab(2);
      } else if (index === 8) {
        // change tab
        this.props.togglePlanogramAsideActiveTab(3);
      } else if (index === 9) {
        // change tab
        this.props.togglePlanogramAsideActiveTab(4);
      } else if (index === 10) {
        // change tab
        this.props.togglePlanogramAsideActiveTab(5);
      } else if (index === 11) {
        // change tab
        this.props.togglePlanogramAsideActiveTab(6);
      }
    }
  };

  private handleCloseContextMenu = (): void => {
    this.props.onPlanogramContextMenuOpenRequest(false);
  };
  private handleKeyDown = (e: KeyboardEvent): void => {
    if (!e?.key) {
      return;
    }
    if (!this.props.planogram || this.props.planogram.isReadOnly) {
      return;
    }
    if (e.ctrlKey && e.key.toLowerCase() === 'c') {
      this.props.onStartCopyingShelfLocationsRequest();
      return;
    }
    if (e.key === Key.Escape) {
      this.props.onCancelAllPlanogramUiActionsRequest();
      return;
    }
    if (e.key === Key.Delete || e.key === Key.Backspace) {
      if (this.props.selectedShelfLocations.length === 0) {
        return;
      }
      if (document.activeElement) {
        const activeElement = document.activeElement;
        const elName = document.activeElement.nodeName.toLowerCase();
        const ignoreDelForElements = ['textarea'];

        if (elName === 'input') {
          const activeElementInput = activeElement as HTMLInputElement;
          const ignoreForTypes = ['email', 'password', 'number', 'text'];
          if (ignoreForTypes.indexOf(activeElementInput.type) > -1) {
            return;
          }
        }

        if (ignoreDelForElements.indexOf(elName) > -1) {
          return;
        }
      }
      const deleteCommand = new DeleteCommand(this.props.planogram, this.props.selectedShelfLocations);
      this.props.onCommandExecutionRequest(deleteCommand);
      this.props.onSelectShelfLocationsRequest([], false);
      return;
    }
    if (e.key.toLowerCase() === 'z' && e.ctrlKey) {
      this.props.onPlanogramActionUndoRequest();
      return;
    }
    if (e.key === '+') {
      if (this.props.selectedShelfLocations.length > 0) {
        const shelfLocationToDuplicate = last(this.props.selectedShelfLocations);
        if (shelfLocationToDuplicate) {
          const command = new DuplicateNextToCommand(this.props.planogram, shelfLocationToDuplicate);
          const prevShelfLocationsLength = this.props.planogram.shelfLocations.length;
          this.props.onCommandExecutionRequest(command);
          this.props.onSelectShelfLocationsRequest([], false);
          setTimeout(() => {
            // TODO: Refactor needed
            // since we don't have a way to react after a specific command has executed
            // selecting the Shelf Location after duplicating it is done with a hack: setting timer
            // once we figure out a mechanism to react after a specific command has executed, we need to refactor this hack.
            if (!this.props.planogram) {
              return;
            }
            const currentShelfLocationsLength = this.props.planogram.shelfLocations.length;
            if (currentShelfLocationsLength > prevShelfLocationsLength) {
              const slsToSelect = this.props.planogram.shelfLocations.slice(prevShelfLocationsLength);
              this.props.onSelectShelfLocationsRequest(slsToSelect, false);
            }
          }, 1);
        }
      }
    }
    if (e.key === '-') {
      if (this.props.selectedShelfLocations.length > 0) {
        const shelfLocationToCut = last(this.props.selectedShelfLocations);
        if (shelfLocationToCut) {
          const command = new CutFacingsCommand(this.props.planogram, shelfLocationToCut, 1);
          this.props.onCommandExecutionRequest(command);
        }
      }
    }
    return;
  };
}

interface DispatchProps {
  planogramPageReset(): void;

  toggleEnableShowProductInfo(enabled?: boolean): void;
  updatePlanogramViewPort(width: number, height: number): void;

  loadPlanogram(id: string): void;

  // TODO: This action is never used?
  uploadShelfLocationImageRequest(planogram: PlanogramModel, imageBase64: string, commandManager: PlanogramCommandManager, shelfLocation: ShelfLocation): void;

  fixImageSizeRequest(planogramId: string, shelfLocation: ShelfLocation): void;

  // TODO: This action is never used?
  setShelfLocationGtinRequest(planogramId: string, shelfLocation: ShelfLocation, gtin: number, newProduct?: NewProductModel): void;

  hideTestWithFlowModal(): void;

  hideEditTestModal(): void;

  updateTestInfoRequest(testInfo: TestInfoDto): void;

  createTestPlanogramRequest(createTestPlanogramDto: CreateTestPlanogramRequestDto): void;
  onStartCopyingShelfLocationsRequest(): void;
  onCancelAllPlanogramUiActionsRequest(): void;

  hideDeleteTestConfirmationModal(): void;

  deleteTestRequest(planogramId: string, testIdToDelete: string): void;

  closeAside(): void;
  toggleAside(): void;
  togglePlanogramAsideActiveTab(activeTab: number): void;
  updateHasFinishedProductTourRequest(): void;
  runProductTour(): void;
  updateProductStoreStepIndex(productStoreStepIndex: number): void;
  stopProductTour(): void;
  onCommandExecutionRequest(command: PlanogramCommand): void;
  onPlanogramActionUndoRequest(): void;
  onSelectShelfLocationsRequest(shelfLocations: ShelfLocation[], append: boolean): void;
  onPlanogramContextMenuOpenRequest(shouldOpen: boolean, shelfLocation?: ShelfLocation, element?: Element): void;
}

function mapStateToProps(state: State, ownProps: RouteComponentProps<MatchParams>): PlanogramState {
  return {
    ...state.planogram,
    id: ownProps.match.params.id,
    userId: Number(state.auth.loadUserStatus.data?.profile.sub ?? 0),
    userPreferences: state.auth.loadUserPreferencesStatus.data ?? ({} as UserPreferencesDto),
  };
}

interface MatchParams {
  id: string;
}

const componentToExport = connect<PlanogramState, DispatchProps, RouteComponentProps<MatchParams>>(mapStateToProps, actions)(PlanogramPage);

export default trackComponent(componentToExport, 'PlanogramPage');
