import { Grid } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { toMaxPrecision, toMaxPrecisionPercent } from 'utils/Helpers';
import flowLogo from '../../../../../assets/img/brand/flow.jpg';
import { ProductComparisonReportResult } from '../../types';
import '../styles/StandardReportStyle.css';
import '../styles/StandardReportProductImageStyle.css';
interface StandardProductComparisonReportPageProps {
  productComparisonReport: ProductComparisonReportResult;
}
const StandardProductComparisonReportPage: React.FC<StandardProductComparisonReportPageProps> = ({ productComparisonReport }: StandardProductComparisonReportPageProps) => {
  const overviewTitleReport = productComparisonReport.overviewTitleReport;
  const overviewRatesReport = productComparisonReport.overviewRatesReport;
  const overviewAveragesReport = productComparisonReport.overviewAveragesReport;
  const demographicsReport = productComparisonReport.demographicsReport;
  const product1BoughtTogetherReport = _.orderBy(productComparisonReport.product1BoughtTogetherReport, 'numberOfShoppers', 'desc');
  const product2BoughtTogetherReport = _.orderBy(productComparisonReport.product2BoughtTogetherReport, 'numberOfShoppers', 'desc');
  const product1WinningOverReport = _.orderBy(productComparisonReport.product1WinningOverReport, 'numberOfShoppers', 'desc');
  const product2WinningOverReport = _.orderBy(productComparisonReport.product2WinningOverReport, 'numberOfShoppers', 'desc');
  const product1LosingToReport = _.orderBy(productComparisonReport.product1LosingToReport, 'numberOfShoppers', 'desc');
  const product2LosingToReport = _.orderBy(productComparisonReport.product2LosingToReport, 'numberOfShoppers', 'desc');
  return (
    <div className="page">
      <div id="flowLogo">
        <img alt="flow-logo" className="img-responsive" src={flowLogo} />
      </div>
      <div style={{ width: '100%' }}>
        <h3 className="text-center">
          Product Comparison Report
          <br />
        </h3>
        <h5 className="text-center">
          <p style={{ margin: '0px' }}>
            <b>Shelf A: </b> {overviewTitleReport.projectNameA}, {overviewTitleReport.storeNameA}, Phase {overviewTitleReport.phaseNumA}, Shelf {overviewTitleReport.shelfNumA} (
            <i>
              {moment(overviewTitleReport.dateFromA).format('DD/MM/YYYY')} - {moment(overviewTitleReport.dateToA).format('DD/MM/YYYY')}
            </i>
            )
          </p>
          <p style={{ margin: '0px' }}>
            <b>Shelf B: </b> {overviewTitleReport.projectNameB}, {overviewTitleReport.storeNameB}, Phase {overviewTitleReport.phaseNumB}, Shelf {overviewTitleReport.shelfNumB} (
            <i>
              {moment(overviewTitleReport.dateFromB).format('DD/MM/YYYY')} - {moment(overviewTitleReport.dateToB).format('DD/MM/YYYY')}
            </i>
            )
          </p>
          <br />
        </h5>
        <h5 className="text-left">
          <p style={{ margin: '0px' }}>
            <b>Shelf A Visitors:</b> {overviewTitleReport.totalVisitorsA}
          </p>
          <p style={{ margin: '0px' }}>
            <b>Shelf B Visitors:</b> {overviewTitleReport.totalVisitorsB}
          </p>
          <br />
        </h5>
        <Grid container direction="row" justify="space-evenly">
          <Grid item xs={6}>
            <p style={{ margin: '0px' }}>
              <b>Product A</b>
            </p>
            <p style={{ margin: '0px' }}>{overviewTitleReport.tradeItemDescriptionA}</p>
            <p style={{ margin: '0px' }}>GTIN: {overviewTitleReport.gtinA}</p>
            <p style={{ margin: '0px' }}>Interactors: {overviewTitleReport.totalShoppersInteractedSKUA}</p>
            <img alt="product img" className="img-responsive productImg" src={overviewTitleReport.productImageUrlA} />
          </Grid>
          <Grid item xs={6}>
            <p style={{ margin: '0px' }}>
              <b>Product B</b>
            </p>
            <p style={{ margin: '0px' }}>{overviewTitleReport.tradeItemDescriptionB}</p>
            <p style={{ margin: '0px' }}>GTIN: {overviewTitleReport.gtinB}</p>
            <p style={{ margin: '0px' }}>Interactors: {overviewTitleReport.totalShoppersInteractedSKUB}</p>
            <img alt="product img" className="img-responsive productImg" src={overviewTitleReport.productImageUrlB} />
          </Grid>
        </Grid>
        <div className="row">
          <table style={{ marginTop: '10px' }} className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-center metricNameCell">Product Metrics</td>
                <td className="text-center metricNameCell">Conversion rate(percent)</td>
                <td className="text-center metricNameCell">Turnover per visitor(amount)</td>
                <td className="text-center metricNameCell">Daily turnover(average amount)</td>
                <td className="text-center metricNameCell">Destination buyers(percent)</td>
                <td className="text-center metricNameCell">Return ratio(percent)</td>
                <td className="text-center metricNameCell">Lost shoppers(percent)</td>
                <td className="text-center metricNameCell">Out of stock(daily hours)</td>
                <td className="text-center metricNameCell">Out of stock(percent)</td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">% change</td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewRatesReport.productConversionRatePercChange, 2)} {overviewRatesReport.productConversionRateSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewAveragesReport.productTurnoverPerVisitorPercChange, 2)} {overviewAveragesReport.productTurnoverPerVisitorSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewAveragesReport.averageProductDailyTurnoverPercChange, 2)} {overviewAveragesReport.averageProductDailyTurnoverSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewRatesReport.productDestinationBuyersSharePercChange, 2)} {overviewRatesReport.productDestinationBuyersShareSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewRatesReport.productReturnRatioPercChange, 2)} {overviewRatesReport.productReturnRatioSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewRatesReport.productLostShoppersPercentPercChange, 2)} {overviewRatesReport.productLostShoppersPercentSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewAveragesReport.averageProductOosHoursPercChange, 2)} {overviewAveragesReport.averageProductOosHoursSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewAveragesReport.productOosTimeSharePercChange, 2)} {overviewAveragesReport.productOosTimeShareSignificance}
                </td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">Product A</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.productConversionRateA, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewAveragesReport.productTurnoverPerVisitorA, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewAveragesReport.averageProductDailyTurnoverA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.productDestinationBuyersShareA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.productReturnRatioA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.productLostShoppersPercentA, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewAveragesReport.averageProductOosHoursA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewAveragesReport.productOosTimeShareA, 2)}</td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">Product B</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.productConversionRateB, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewAveragesReport.productTurnoverPerVisitorB, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewAveragesReport.averageProductDailyTurnoverB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.productDestinationBuyersShareB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.productReturnRatioB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.productLostShoppersPercentB, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewAveragesReport.averageProductOosHoursB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewAveragesReport.productOosTimeShareB, 2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row">
          <table style={{ marginTop: '10px' }} className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-center metricNameCell">Product Metrics</td>
                <td className="text-center metricNameCell">Share of space</td>
                <td className="text-center metricNameCell">Share of unit sales</td>
                <td className="text-center metricNameCell">Share of turnover</td>
                <td className="text-center metricNameCell">Share of first pickups</td>
                <td className="text-center metricNameCell">Discovery time(all shoppers)</td>
                <td className="text-center metricNameCell">Seconds to buy and leave(buyers)</td>
                <td className="text-center metricNameCell">Seconds to touch and reject(non-buyers)</td>
                <td className="text-center metricNameCell">Substitutions</td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">% change</td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewRatesReport.shareOfSpacePercChange, 2)} {overviewRatesReport.shareOfSpaceSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewRatesReport.shareOfUnitSalesPercChange, 2)} {overviewRatesReport.shareOfUnitSalesSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewRatesReport.shareOfTurnoverPercChange, 2)} {overviewRatesReport.shareOfTurnoverSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewRatesReport.shareOfFirstPickupsPercChange, 2)} {overviewRatesReport.shareOfFirstPickupsSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewAveragesReport.discoveryTimePercChange, 2)} {overviewAveragesReport.discoveryTimeSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewAveragesReport.secondsToBuyAndLeavePercChange, 2)} {overviewAveragesReport.secondsToBuyAndLeaveSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewAveragesReport.secondsToTouchAndRejectPercChange, 2)} {overviewAveragesReport.secondsToTouchAndRejectSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewRatesReport.substitutionShopperCountPercChange, 2)} {overviewRatesReport.substitutionShopperCountSignificance}
                </td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">Product A</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.shareOfSpaceA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.shareOfUnitSalesA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.shareOfTurnoverA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.shareOfFirstPickupsA, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewAveragesReport.discoveryTimeA, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewAveragesReport.secondsToBuyAndLeaveA, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewAveragesReport.secondsToTouchAndRejectA, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewRatesReport.substitutionShopperCountA, 2)}</td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">Product B</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.shareOfSpaceB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.shareOfUnitSalesB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.shareOfTurnoverB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.shareOfFirstPickupsB, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewAveragesReport.discoveryTimeB, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewAveragesReport.secondsToBuyAndLeaveB, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewAveragesReport.secondsToTouchAndRejectB, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewRatesReport.substitutionShopperCountB, 2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row">
          <table className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-center metricNameCell" colSpan={3}>
                  Product A - {overviewTitleReport.tradeItemDescriptionA}
                </td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">Mostly bought with</td>
                <td className="text-center metricNameCell">Mostly losing to</td>
                <td className="text-center metricNameCell">Mostly winning over</td>
              </tr>
              <tr>
                <td className="text-center">
                  {product1BoughtTogetherReport.length >= 1
                    ? `
                    ${product1BoughtTogetherReport[0].comparisonTradeItemDescription} (
                    ${product1BoughtTogetherReport[0].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {product1LosingToReport.length >= 1
                    ? `
                    ${product1LosingToReport[0].comparisonTradeItemDescription} (
                    ${product1LosingToReport[0].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {product1WinningOverReport.length >= 1
                    ? `
                    ${product1WinningOverReport[0].comparisonTradeItemDescription} (
                    ${product1WinningOverReport[0].numberOfShoppers})`
                    : ''}
                </td>
              </tr>
              <tr>
                <td className="text-center">
                  {product1BoughtTogetherReport.length >= 2
                    ? `
                    ${product1BoughtTogetherReport[1].comparisonTradeItemDescription} (
                    ${product1BoughtTogetherReport[1].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {product1LosingToReport.length >= 2
                    ? `
                    ${product1LosingToReport[1].comparisonTradeItemDescription} (
                    ${product1LosingToReport[1].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {product1WinningOverReport.length >= 2
                    ? `
                    ${product1WinningOverReport[1].comparisonTradeItemDescription} (
                    ${product1WinningOverReport[1].numberOfShoppers})`
                    : ''}
                </td>
              </tr>
              <tr>
                <td className="text-center">
                  {product1BoughtTogetherReport.length >= 3
                    ? `
                    ${product1BoughtTogetherReport[2].comparisonTradeItemDescription} (
                    ${product1BoughtTogetherReport[2].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {product1LosingToReport.length >= 3
                    ? `
                    ${product1LosingToReport[2].comparisonTradeItemDescription} (
                    ${product1LosingToReport[2].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {product1WinningOverReport.length >= 3
                    ? `
                    ${product1WinningOverReport[2].comparisonTradeItemDescription} (
                    ${product1WinningOverReport[2].numberOfShoppers})`
                    : ''}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row">
          <table className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-center metricNameCell" colSpan={3}>
                  Product B - {overviewTitleReport.tradeItemDescriptionB}
                </td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">Mostly bought with</td>
                <td className="text-center metricNameCell">Mostly losing to</td>
                <td className="text-center metricNameCell">Mostly winning over</td>
              </tr>
              <tr>
                <td className="text-center">
                  {product2BoughtTogetherReport.length >= 1
                    ? `
                    ${product2BoughtTogetherReport[0].comparisonTradeItemDescription} (
                    ${product2BoughtTogetherReport[0].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {product2LosingToReport.length >= 1
                    ? `
                    ${product2LosingToReport[0].comparisonTradeItemDescription} (
                    ${product2LosingToReport[0].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {product2WinningOverReport.length >= 1
                    ? `
                    ${product2WinningOverReport[0].comparisonTradeItemDescription} (
                    ${product2WinningOverReport[0].numberOfShoppers})`
                    : ''}
                </td>
              </tr>
              <tr>
                <td className="text-center">
                  {product2BoughtTogetherReport.length >= 2
                    ? `
                    ${product2BoughtTogetherReport[1].comparisonTradeItemDescription} (
                    ${product2BoughtTogetherReport[1].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {product2LosingToReport.length >= 2
                    ? `
                    ${product2LosingToReport[1].comparisonTradeItemDescription} (
                    ${product2LosingToReport[1].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {product2WinningOverReport.length >= 2
                    ? `
                    ${product2WinningOverReport[1].comparisonTradeItemDescription} (
                    ${product2WinningOverReport[1].numberOfShoppers})`
                    : ''}
                </td>
              </tr>
              <tr>
                <td className="text-center">
                  {product2BoughtTogetherReport.length >= 3
                    ? `
                    ${product2BoughtTogetherReport[2].comparisonTradeItemDescription} (
                    ${product2BoughtTogetherReport[2].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {product2LosingToReport.length >= 3
                    ? `
                    ${product2LosingToReport[2].comparisonTradeItemDescription} (
                    ${product2LosingToReport[2].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {product2WinningOverReport.length >= 3
                    ? `
                    ${product2WinningOverReport[2].comparisonTradeItemDescription} (
                    ${product2WinningOverReport[2].numberOfShoppers})`
                    : ''}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row">
          <table style={{ marginTop: '10px' }} className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-center metricNameCell">Demographics</td>
                <td className="text-center metricNameCell" colSpan={2}>
                  Interactors
                </td>
                <td className="text-center metricNameCell" colSpan={2}>
                  Buyers
                </td>
                <td className="text-center metricNameCell" colSpan={2}>
                  Lost shoppers
                </td>
              </tr>

              <tr>
                <td className="text-center metricNameCell" rowSpan={2}>
                  All
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.allInteractorsPercChange, 2)} {demographicsReport.allInteractorsSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.allBuyersPercChange, 2)} {demographicsReport.allBuyersSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.allLostShoppersPercChange, 2)} {demographicsReport.allLostShoppersSignificance}
                </td>
              </tr>
              <tr>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.allInteractorsA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.allInteractorsB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.allBuyersA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.allBuyersB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.allLostShoppersA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.allLostShoppersB, 2)}</td>
              </tr>
              <tr>
                <td className="text-center metricNameCell" rowSpan={2}>
                  Female
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.femaleInteractorsPercChange, 2)} {demographicsReport.femaleInteractorsSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.femaleBuyersPercChange, 2)} {demographicsReport.femaleBuyersSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.femaleLostShoppersPercChange, 2)} {demographicsReport.femaleLostShoppersSignificance}
                </td>
              </tr>
              <tr>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.femaleInteractorsA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.femaleInteractorsB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.femaleBuyersA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.femaleBuyersB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.femaleLostShoppersA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.femaleLostShoppersB, 2)}</td>
              </tr>

              <tr>
                <td className="text-center metricNameCell" rowSpan={2}>
                  Male
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.maleInteractorsPercChange, 2)} {demographicsReport.maleInteractorsSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.maleBuyersPercChange, 2)} {demographicsReport.maleBuyersSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.maleLostShoppersPercChange, 2)} {demographicsReport.maleLostShoppersSignificance}
                </td>
              </tr>
              <tr>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.maleInteractorsA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.maleInteractorsB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.maleBuyersA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.maleBuyersB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.maleLostShoppersA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.maleLostShoppersB, 2)}</td>
              </tr>

              <tr>
                <td className="text-center metricNameCell" rowSpan={2}>
                  WithKids
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.withKidsInteractorsPercChange, 2)} {demographicsReport.withKidsInteractorsSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.withKidsBuyersPercChange, 2)} {demographicsReport.withKidsBuyersSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.withKidsLostShoppersPercChange, 2)} {demographicsReport.withKidsLostShoppersSignificance}
                </td>
              </tr>
              <tr>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.withKidsInteractorsA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.withKidsInteractorsB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.withKidsBuyersA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.withKidsBuyersB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.withKidsLostShoppersA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.withKidsLostShoppersB, 2)}</td>
              </tr>

              <tr>
                <td className="text-center metricNameCell" rowSpan={2}>
                  Group
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.groupInteractorsPercChange, 2)} {demographicsReport.groupInteractorsSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.groupBuyersPercChange, 2)} {demographicsReport.groupBuyersSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.groupLostShoppersPercChange, 2)} {demographicsReport.groupLostShoppersSignificance}
                </td>
              </tr>
              <tr>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.groupInteractorsA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.groupInteractorsB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.groupBuyersA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.groupBuyersB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.groupLostShoppersA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.groupLostShoppersB, 2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <footer>
        <strong>Copyright &copy; {new Date().getFullYear()} Flow Insights</strong>. Strictly confidential. No distribution allowed. All rights reserved.
      </footer>
    </div>
  );
};

export { StandardProductComparisonReportPage };
