import React from 'react';
import Select from 'react-select';
import { ClientOption } from '../../../reports/types';

export interface ClientSelectorProps {
  htmlId: string;
  clientOptions: ClientOption[];
  selectedClient?: ClientOption;
  handleOnSelectClient(selectedClient: ClientOption): void;
}

export const ClientSelector: React.FC<ClientSelectorProps> = ({ htmlId, clientOptions, selectedClient, handleOnSelectClient }: ClientSelectorProps) => {
  return (
    <Select
      styles={{
        // Fixes the overlapping problem of the component
        menu: provided => ({ ...provided, zIndex: 9999 }),
      }}
      inputId={htmlId}
      placeholder="Select Client"
      options={clientOptions}
      value={selectedClient}
      onChange={(client: ClientOption): void => handleOnSelectClient(client)}
      isMulti={false}
      isDisabled={clientOptions.length === 0}
    />
  );
};
