import { FlowDataReportResult } from 'modules/planogram/types/FlowDataReportResult';
import apiClient, { ApiResponse } from 'utils/apiClient';
import { RealogramConversation, RealogramViewModel } from './types';

export const fetchRealogramApiRequest = (realogramID: string): Promise<ApiResponse<RealogramViewModel>> => {
  return apiClient.get(`/realograms/${realogramID}`);
};

export const fetchFlowDataApiRequest = (realogramID: string): Promise<ApiResponse<FlowDataReportResult>> => {
  return apiClient.get(`/reports/shelves/${realogramID}/flow-products-report`);
};

export const fetchConversationApiRequest = (shelfReferenceId: string): Promise<ApiResponse<RealogramConversation>> => {
  return apiClient.get(`/realograms/${shelfReferenceId}/conversation`);
};

export const postMessageToConversationApiRequest = (shelfReferenceId: string, message: string): Promise<ApiResponse<{ messageId: string; userId: number; username: string; timeOfMessage: Date }>> => {
  return apiClient.post(`/realograms/${shelfReferenceId}/conversation`, {
    shelfReferenceId,
    message,
  });
};

export const updateMessageInConversationApiRequest = (shelfReferenceId: string, messageId: string, userId: number, editedMessage: string): Promise<ApiResponse<{}>> => {
  return apiClient.put(`/realograms/${shelfReferenceId}/conversation/${messageId}`, {
    shelfReferenceId: shelfReferenceId,
    messageId: messageId,
    userId: userId,
    editedMessage: editedMessage,
  });
};

export const deleteMessageInConversationApiRequest = (shelfReferenceId: string, messageId: string): Promise<ApiResponse<{}>> => {
  return apiClient.delete(`/realograms/${shelfReferenceId}/conversation/${messageId}`);
};
