import CompetitorPlanogramFooter from 'modules/planogram/components/footer/CompetitorPlanogramFooter';
import PlanogramFooter from '../modules/planogram/components/footer/Footer';
import PublicPlanogramFooter from '../modules/planogram/components/footer/PublicPlanogramFooter';
import TestPlanogramFooter from '../modules/planogram/components/footer/TestPlanogramFooter';
import RealogramPageFooter from 'modules/realogram/footer/Footer';

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/planograms/:id', exact: true, name: 'Planogram', component: PlanogramFooter },
  { path: '/planograms/:id/share/:sharingToken', name: 'PublicPlanogram', component: PublicPlanogramFooter },
  { path: '/planograms/:id/test/:testPlanogramId', name: 'TestPlanogram', component: TestPlanogramFooter },
  {
    path: '/planograms/:id/competitorShelves/:shelfReferenceId',
    name: 'CompetitorPlanogramFooter',
    component: CompetitorPlanogramFooter,
  },
  // Realogram routes
  {
    path: '/realograms/:shelfReferenceId',
    exact: true,
    name: 'Realogram Footer',
    component: RealogramPageFooter,
  },
];

export default routes;
