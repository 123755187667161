import { AppAside, AppFooter, AppHeader, AppSidebar, AppSidebarFooter, AppSidebarForm, AppSidebarHeader, AppSidebarMinimizer, AppSidebarNav } from '@coreui/react';
import { classToClass } from 'class-transformer';
import ApplicationUser from 'modules/auth/types/ApplicationUser';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import Container from 'reactstrap/lib/Container';
import { State } from 'state';
import asideRoutes from '../../routing/asideRoutes';
import footerRoutes from '../../routing/footerRoutes';
import routes from '../../routing/routes';
import Aside from '../_Shared/Aside';
import Header from './Header';
import navigation from './NavigationMeta';

export default class Layout extends React.Component<State> {
  loading = (): JSX.Element => <div className="animated fadeIn pt-1 text-center">Loading...</div>;

  render(): JSX.Element {
    const props = this.props as State;
    const user = props.auth.applicationUser as ApplicationUser;
    const navRoutes = classToClass(navigation);
    if (!user.isAdmin()) {
      navRoutes.items = navRoutes.items.filter(r => r.name.toLowerCase() !== 'admin');
      navRoutes.items = navRoutes.items.filter(r => r.name.toLowerCase() !== 'timeline');
    }
    if (!user.isAdmin() && !user.isPartner()) {
      navRoutes.items = navRoutes.items.filter(r => r.name.toLowerCase() !== 'reports');
    }

    return (
      <div className="app">
        <AppHeader fixed={true}>
          <React.Suspense fallback={this.loading()}>
            <Header {...this.props} />
          </React.Suspense>
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed={true} display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <React.Suspense fallback={this.loading()}>
              <AppSidebarNav navConfig={navRoutes} {...this.props} />
            </React.Suspense>
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main" id="content-main-parent-element">
            <Container fluid={true} id="content-main-element">
              <React.Suspense fallback={this.loading()}>
                <Switch>
                  {routes.map((route, idx) => {
                    if (!user.isAdmin()) {
                      if (route.path.startsWith('/admin')) {
                        return null;
                      }
                    }
                    // TODO: remove this temporary access blocker after timelines feature complete
                    if (!user.isAdmin()) {
                      if (route.path.startsWith('/timeline')) {
                        return null;
                      }
                    }
                    if (!user.isAdmin() && !user.isPlanogramOwner() && !user.isPartner()) {
                      if (route.path.startsWith('/reports')) {
                        return null;
                      }
                    }
                    return route.component ? (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        render={(props: any): JSX.Element => {
                          return <route.component {...props} />;
                        }}
                      />
                    ) : null;
                  })}
                  <Route
                    render={(): JSX.Element => {
                      return <h3 style={{ color: 'red' }}>The requested page can not be found.</h3>;
                    }}
                  />
                </Switch>
              </React.Suspense>
            </Container>
          </main>
          <AppAside fixed className="no-print">
            {props.globalUiState.isAsideOpened ? <Aside routes={asideRoutes} /> : null}
          </AppAside>
        </div>
        <AppFooter className="no-print">
          <Switch>
            {footerRoutes.map((route, idx) => {
              return route.component ? <Route key={idx} path={route.path} exact={route.exact} name={route.name} component={route.component} /> : null;
            })}
          </Switch>
        </AppFooter>
      </div>
    );
  }
}
