import _ from 'lodash';
import { ActionRules } from 'modules/planograms/types/ActionRules';
import Rainbow from 'rainbowvis.js';
import { toMaxPrecisionPercent, toMaxPrecisionString } from 'utils/Helpers';
import ShelfLocation from '../domain/ShelfLocation';
import { FlowDataShelfLocationReportResult } from '../types/FlowDataReportResult';
import { FlowDataReportType } from '../types/ReportingTypes';
import { isAllowedToView } from './permissionsHelper';

export function isReverseMetric(type: FlowDataReportType): boolean {
  switch (type) {
    case FlowDataReportType.ProductReturnRatio:
    case FlowDataReportType.ProductOosHoursPerDay:
    case FlowDataReportType.ProductLostShoppersCount:
    case FlowDataReportType.ProductReturnCount:
    case FlowDataReportType.ProductLostDailySalesOutOfStock:
    case FlowDataReportType.ProductLostDailyTurnoverOutOfStock:
    case FlowDataReportType.ProductDiscoveryTime:
    case FlowDataReportType.ProductSecondsToBuyAndLeave:
    case FlowDataReportType.ProductSecondsToTouchAndReject:
    case FlowDataReportType.ShelfLocationReturnRatio:
    case FlowDataReportType.ShelfLocationOosHoursPerDay:
    case FlowDataReportType.ShelfLocationReturnCount:
    case FlowDataReportType.ShelfLocationDiscoveryTime:
    case FlowDataReportType.ShelfLocationSecondsToBuyAndLeave:
    case FlowDataReportType.ShelfLocationSecondsToTouchAndReject:
      return true;
    default:
      return false;
  }
}

export function getReportTypeValue(data: FlowDataShelfLocationReportResult, type: FlowDataReportType): number | undefined {
  switch (type) {
    case FlowDataReportType.ProductPickupCount:
      return data.productPickupCount;
    case FlowDataReportType.ProductReturnCount:
      return data.productReturnCount;
    case FlowDataReportType.ProductTouchCount:
      return data.productTouchCount;
    case FlowDataReportType.ProductInteractionCount:
      return data.productInteractionCount;
    case FlowDataReportType.ProductFirstPickupsCount:
      return data.productFirstPickupsCount;
    case FlowDataReportType.ProductPurchaseCount:
      return data.productPurchaseCount;
    case FlowDataReportType.ProductBuyersCount:
      return data.productBuyersCount;
    case FlowDataReportType.ProductReturnRatio:
      return data.productReturnRatio;
    case FlowDataReportType.ProductTurnover:
      return data.productTurnover;
    case FlowDataReportType.ProductConversionRate:
      return data.productConversionRate;
    case FlowDataReportType.ProductDestinationBuyersCount:
      return data.productDestinationBuyersCount;
    case FlowDataReportType.ProductDestinationBuyersShare:
      return data.productDestinationBuyersShare;
    case FlowDataReportType.ProductLostShoppersCount:
      return data.productLostShoppersCount;
    case FlowDataReportType.ProductCurrentShareOfSpace:
      return data.productShareOfSpace;
    case FlowDataReportType.ProductShareOfUnitSales:
      return data.productShareOfUnitSales ? data.productShareOfUnitSales : undefined;
    case FlowDataReportType.ProductSalesPerformance:
      return data.productSalesPerformance ? data.productSalesPerformance : undefined;
    case FlowDataReportType.ProductShareOfTurnover:
      return data.productShareOfTurnover ? data.productShareOfTurnover : undefined;
    case FlowDataReportType.ProductTurnoverPerformance:
      return data.productTurnoverPerformance ? data.productTurnoverPerformance : undefined;
    case FlowDataReportType.ProductOosHoursPerDay:
      return data.productHoursOutOfStockPerDay ? data.productHoursOutOfStockPerDay : undefined;
    case FlowDataReportType.ProductLostDailySalesOutOfStock:
      return data.productLostDailySalesOutOfStock ? data.productLostDailySalesOutOfStock : undefined;
    case FlowDataReportType.ProductLostDailyTurnoverOutOfStock:
      return data.productLostDailyTurnoverOutOfStock ? data.productLostDailyTurnoverOutOfStock : undefined;
    case FlowDataReportType.ProductDiscoveryTime:
      return data.productDiscoveryTime ? data.productDiscoveryTime : undefined;
    case FlowDataReportType.ProductSecondsToBuyAndLeave:
      return data.productSecondsToBuyAndLeave ? data.productSecondsToBuyAndLeave : undefined;
    case FlowDataReportType.ProductSecondsToTouchAndReject:
      return data.productSecondsToTouchAndReject ? data.productSecondsToTouchAndReject : undefined;
    case FlowDataReportType.ShelfLocationPickupCount:
      return data.shelfLocationPickupCount;
    case FlowDataReportType.ShelfLocationReturnCount:
      return data.shelfLocationReturnCount;
    case FlowDataReportType.ShelfLocationTouchCount:
      return data.shelfLocationTouchCount;
    case FlowDataReportType.ShelfLocationInteractionCount:
      return data.shelfLocationInteractionCount;
    case FlowDataReportType.ShelfLocationFirstPickupsCount:
      return data.shelfLocationFirstPickupsCount;
    case FlowDataReportType.ShelfLocationPurchaseCount:
      return data.shelfLocationPurchaseCount;
    case FlowDataReportType.ShelfLocationBuyersCount:
      return data.shelfLocationBuyersCount;
    case FlowDataReportType.ShelfLocationReturnRatio:
      return data.shelfLocationReturnRatio;
    case FlowDataReportType.ShelfLocationTurnover:
      return data.shelfLocationTurnover;
    case FlowDataReportType.ShelfLocationConversionRate:
      return data.shelfLocationConversionRate;
    case FlowDataReportType.ShelfLocationCurrentShareOfSpace:
      return data.shelfLocationShareOfSpace;
    case FlowDataReportType.ShelfLocationShareOfUnitSales:
      return data.shelfLocationShareOfUnitSales ? data.shelfLocationShareOfUnitSales : undefined;
    case FlowDataReportType.ShelfLocationSalesPerformance:
      return data.shelfLocationSalesPerformance ? data.shelfLocationSalesPerformance : undefined;
    case FlowDataReportType.ShelfLocationShareOfTurnover:
      return data.shelfLocationShareOfTurnover ? data.shelfLocationShareOfTurnover : undefined;
    case FlowDataReportType.ShelfLocationTurnoverPerformance:
      return data.shelfLocationTurnoverPerformance ? data.shelfLocationTurnoverPerformance : undefined;
    case FlowDataReportType.ShelfLocationOosHoursPerDay:
      return data.shelfLocationHoursOutOfStockPerDay ? data.shelfLocationHoursOutOfStockPerDay : undefined;
    case FlowDataReportType.ShelfLocationDiscoveryTime:
      return data.shelfLocationDiscoveryTime ? data.shelfLocationDiscoveryTime : undefined;
    case FlowDataReportType.ShelfLocationSecondsToBuyAndLeave:
      return data.shelfLocationSecondsToBuyAndLeave ? data.shelfLocationSecondsToBuyAndLeave : undefined;
    case FlowDataReportType.ShelfLocationSecondsToTouchAndReject:
      return data.shelfLocationSecondsToTouchAndReject ? data.shelfLocationSecondsToTouchAndReject : undefined;
  }
}

export function formatValue(value: number | undefined, type: FlowDataReportType): string {
  switch (type) {
    case FlowDataReportType.ProductTurnover:
    case FlowDataReportType.ProductOosHoursPerDay:
    case FlowDataReportType.ProductLostDailySalesOutOfStock:
    case FlowDataReportType.ProductLostDailyTurnoverOutOfStock:
    case FlowDataReportType.ProductDiscoveryTime:
    case FlowDataReportType.ProductSecondsToBuyAndLeave:
    case FlowDataReportType.ProductSecondsToTouchAndReject:
    case FlowDataReportType.ShelfLocationTurnover:
    case FlowDataReportType.ShelfLocationOosHoursPerDay:
    case FlowDataReportType.ShelfLocationDiscoveryTime:
    case FlowDataReportType.ShelfLocationSecondsToBuyAndLeave:
    case FlowDataReportType.ShelfLocationSecondsToTouchAndReject:
      return toMaxPrecisionString(value, 2);
    case FlowDataReportType.ProductDestinationBuyersShare:
    case FlowDataReportType.ProductCurrentShareOfSpace:
    case FlowDataReportType.ProductShareOfUnitSales:
    case FlowDataReportType.ProductShareOfTurnover:
    case FlowDataReportType.ProductReturnRatio:
    case FlowDataReportType.ProductConversionRate:
    case FlowDataReportType.ProductSalesPerformance:
    case FlowDataReportType.ProductTurnoverPerformance:
    case FlowDataReportType.ShelfLocationCurrentShareOfSpace:
    case FlowDataReportType.ShelfLocationShareOfUnitSales:
    case FlowDataReportType.ShelfLocationShareOfTurnover:
    case FlowDataReportType.ShelfLocationReturnRatio:
    case FlowDataReportType.ShelfLocationConversionRate:
    case FlowDataReportType.ShelfLocationSalesPerformance:
    case FlowDataReportType.ShelfLocationTurnoverPerformance:
      return toMaxPrecisionPercent(value, 2);
    // return toMaxPrecision(value, 2) + '%';

    default:
      return value ? value.toString() : '';
  }
}

/**
 * From a given array of shelf locations, extracts an array of unique shelf locations for which data can be displayed.
 * @param shelfLocations Array of shelf locaitons
 * @param actionRules Action rules for this planogram
 * @returns Array of shelf locations for which data can be reported
 */
function extractShelfLocsForReporting(shelfLocations: ShelfLocation[], actionRules: ActionRules): string[] {
  const shelfLocs = shelfLocations.filter(sl => actionRules.isDisabled || isAllowedToView(sl)); // filter shelf locations that are allowed to be viewed
  const uniqueShelfLocs = _.uniqBy(shelfLocs, function(shelfLoc) {
    // get unique shelf locations
    return shelfLoc.code;
  }).map(sl => sl.code);
  return uniqueShelfLocs;
}

interface ShelfData {
  values: number[];
  min: number;
  max: number;
  spectrum: string[];
  canFill: boolean;
}

/**
 * Creates reporting data per shelf location for a given report type.
 * @param shelfLocations Array of shelf locations for which data is to be created.
 * @param shelfLocationData Array of data to extract from.
 * @param reportType Type of reporting that is required
 * @returns A dictionary with key shelf location and value is the value for the report.
 */
export function generateDataForShelfLocation(shelfLocationsIds: string[], shelfLocationData: FlowDataShelfLocationReportResult[], reportType?: FlowDataReportType): ShelfData {
  const data = {};
  let min = Infinity;
  let max = -Infinity;
  let canFill = true;
  let spectrum = ['ff0000', 'fffa00', '17ff00'];
  if (reportType) {
    shelfLocationsIds.forEach(sl => {
      const slFlowData = shelfLocationData.find(slData => slData.shelfLocation === sl);
      if (!slFlowData) {
        return;
      }
      const result = getReportTypeValue(slFlowData, reportType);

      if (!result || isNaN(result)) {
        return;
      }

      min = Math.min(min, result);
      max = Math.max(max, result);
      data[sl] = result;
    });

    if (min === Infinity || max === -Infinity) {
      canFill = false;
    }

    if (min === max) {
      min = max - 1;
    }

    if (isReverseMetric(reportType)) {
      spectrum = ['17ff00', 'fffa00', 'ff0000'];
    }
  } else {
    canFill = false;
  }

  return {
    values: data,
    min,
    max,
    spectrum,
    canFill,
  } as ShelfData;
}

/**
 * Uses FLOW data to set shelf locations' data fields (color & text).
 * @param shelfLocations Shelf locations to apply data fields to.
 * @param actionRules Action rules of the planogram.
 * @param shelfLocationData FLOW data.
 * @param reportType Type of report selected (First Pickups, OOS Time etc).
 */
export function prepareFlowDataReport(shelfLocations: ShelfLocation[], actionRules: ActionRules, shelfLocationData: FlowDataShelfLocationReportResult[], reportType?: FlowDataReportType): void {
  const shelfLocs = extractShelfLocsForReporting(shelfLocations, actionRules);
  const data = generateDataForShelfLocation(shelfLocs, shelfLocationData, reportType);

  const rainbow = new Rainbow();
  if (data.canFill) {
    rainbow.setSpectrum(...data.spectrum);
    rainbow.setNumberRange(data.min, data.max);
  }

  shelfLocations.forEach(sl => {
    if (data.values[sl.code] && data.canFill && reportType !== undefined) {
      sl.color = rainbow.colourAt(data.values[sl.code]);
      sl.displayText = formatValue(data.values[sl.code], reportType) as string | null;
    } else {
      sl.color = null;
      sl.displayText = null;
      sl.isHighlighted = false;
    }
  });
}
