import { isLoaded, isLoading, isError } from 'infrastructure/utils/RemoteObjectStatus';
import { fetchRealogramConversationRequestAction } from 'modules/realogram/actions';
import { RealogramConversation } from 'modules/realogram/types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as selectors from '../../selectors';
interface ConversationLoaderProps {
  shelfReferenceId: string;
  children: (params: ChildrenParams) => React.ReactNode;
}

interface ChildrenParams {
  isLoaded: boolean;
  isError: boolean;
  errorMessage: string;
  isLoading: boolean;
  data?: RealogramConversation;
}

export const ConversationLoader: React.FC<ConversationLoaderProps> = ({ shelfReferenceId, children }: ConversationLoaderProps): JSX.Element => {
  const dispatch = useDispatch();
  const loadState = useSelector(selectors.getRealogramConversationLoadingState);
  const conversations = useSelector(selectors.getRealogramConversationData);
  const conversationData = conversations.find(x => x.shelfReferenceId === shelfReferenceId);

  useEffect(() => {
    if (!conversationData) {
      dispatch(fetchRealogramConversationRequestAction(shelfReferenceId));
    }
  }, [conversationData, shelfReferenceId, dispatch]);

  return (
    <>
      {children({
        isLoaded: isLoaded(loadState),
        isError: isError(loadState),
        errorMessage: loadState.errorDetails?.errorMessage ?? '',
        isLoading: isLoading(loadState),
        data: conversationData,
      })}
    </>
  );
};
