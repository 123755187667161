import React from 'react';
import { Grid, CircularProgress, Typography } from '@material-ui/core';
import { PulseLoader } from 'react-spinners';

export interface LoadIndicatorProps {
  loadingText: string;
}

export const CircularLoadIndicator: React.FC<LoadIndicatorProps> = ({ loadingText }: LoadIndicatorProps) => {
  return (
    <Grid direction="column" alignItems="center" container justify="center">
      <CircularProgress /> &nbsp;&nbsp;
      <Typography variant="overline">{loadingText}</Typography>
    </Grid>
  );
};

export const HorizontalLoadIndicator: React.FC<LoadIndicatorProps> = ({ loadingText }: LoadIndicatorProps) => {
  return (
    <>
      {loadingText} &nbsp; <PulseLoader color={'#031f5c'} size={5} />
    </>
  );
};
