import React from 'react';
import { Field, FieldProps } from 'formik';
import { Checkbox } from '@material-ui/core';
import { StyledTableCell, StyledTableRow } from '../../../../../../scss/MaterialStyles';

export interface BrandRulesTableRowProps {
  brandName: string;
  namePrefix: string;
}

const BrandRulesTableRow: React.FC<BrandRulesTableRowProps> = ({ brandName, namePrefix }: BrandRulesTableRowProps): JSX.Element => {
  return (
    <StyledTableRow>
      <StyledTableCell data-testid={`brandName-${namePrefix}`} component="th" scope="row">
        {brandName}
      </StyledTableCell>
      <StyledTableCell align="right">
        <Field name={`${namePrefix}.canView`}>
          {({ field }: FieldProps): JSX.Element => {
            return <Checkbox data-testid={`checkbox-canView-${namePrefix}`} name={field.name} checked={field.value} onChange={field.onChange} color="primary" />;
          }}
        </Field>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Field name={`${namePrefix}.canMove`}>
          {({ field }: FieldProps): JSX.Element => {
            return <Checkbox data-testid={`checkbox-canMove-${namePrefix}`} name={field.name} checked={field.value} onChange={field.onChange} color="primary" />;
          }}
        </Field>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Field name={`${namePrefix}.canDelist`}>
          {({ field }: FieldProps): JSX.Element => {
            return <Checkbox data-testid={`checkbox-canDelist-${namePrefix}`} name={field.name} checked={field.value} onChange={field.onChange} color="primary" />;
          }}
        </Field>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Field name={`${namePrefix}.canIncreaseSize`}>
          {({ field }: FieldProps): JSX.Element => {
            return <Checkbox data-testid={`checkbox-canIncreaseSize-${namePrefix}`} name={field.name} checked={field.value} onChange={field.onChange} color="primary" />;
          }}
        </Field>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Field name={`${namePrefix}.canDecreaseSize`}>
          {({ field }: FieldProps): JSX.Element => {
            return <Checkbox data-testid={`checkbox-canDecreaseSize-${namePrefix}`} name={field.name} checked={field.value} onChange={field.onChange} color="primary" />;
          }}
        </Field>
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default BrandRulesTableRow;
