import ErrorDetails from 'infrastructure/exception/ErrorDetails';
import * as constants from './constants';
import { ClientDatasetDto } from './types/ClientDatasetDto';
import { ClientDto } from './types/ClientDto';
import { UploadClientDatasetResponse } from './types/UploadClientDatasetResponse';

export const loadClientsRequest = () => ({
  type: constants.LOAD_CLIENTS_REQUEST,
});

export const loadClientsSuccess = (clients: ClientDto[]) => ({
  type: constants.LOAD_CLIENTS_SUCCESS,
  clients,
});

export const loadClientsFailure = (errorDetails: ErrorDetails) => ({
  type: constants.LOAD_CLIENTS_FAILURE,
  errorDetails,
});

export const loadClientDatasetsRequest = () => ({
  type: constants.LOAD_CLIENT_DATASETS_REQUEST,
});

export const loadClientDatasetsSuccess = (clientDatasets: ClientDatasetDto[]) => ({
  type: constants.LOAD_CLIENT_DATASETS_SUCCESS,
  clientDatasets,
});

export const loadClientDatasetsFailure = (errorDetails: ErrorDetails) => ({
  type: constants.LOAD_CLIENT_DATASETS_FAILURE,
  errorDetails,
});

export const uploadClientDatasetRequest = (clientDataset: ClientDatasetDto) => ({
  type: constants.UPLOAD_CLIENT_DATASET_REQUEST,
  clientDataset,
});

export const uploadClientDatasetSuccess = (response: UploadClientDatasetResponse) => ({
  type: constants.UPLOAD_CLIENT_DATASET_SUCCESS,
  response,
});

export const uploadClientDatasetFailure = (errorDetails: ErrorDetails) => ({
  type: constants.UPLOAD_CLIENT_DATASET_FAILURE,
  errorDetails,
});

export const deleteClientDatasetRequest = (datasetId: number) => ({
  type: constants.DELETE_CLIENT_DATASET_REQUEST,
  datasetId,
});

export const deleteClientDatasetSuccess = (datasetId: number) => ({
  type: constants.DELETE_CLIENT_DATASET_SUCCESS,
  datasetId,
});

export const deleteClientDatasetFailure = (errorDetails: ErrorDetails) => ({
  type: constants.DELETE_CLIENT_DATASET_FAILURE,
  errorDetails,
});
