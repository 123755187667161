import * as React from 'react';
import ShelfLocation from 'modules/planogram/domain/ShelfLocation';
import GeometryPoint from 'modules/shared/geometry/GeometryPoint';
import { getLineDistance } from 'modules/shared/geometry/helpers';

interface MyProps {
  shelfLocation: ShelfLocation;
}

interface ImageProps {
  x: number;
  y: number;
  width: number;
  height: number;
  clipPath: string | undefined;
}

function getImageProps(sl: ShelfLocation): ImageProps {
  if (sl.gtin === 123) {
    console.log('1');
  }
  const topLeft = {
    x: sl.geometry.topLeftPoint.x + sl.geometry.topLeftPoint.xOffset,
    y: sl.geometry.topLeftPoint.y + sl.geometry.topLeftPoint.yOffset,
  } as GeometryPoint;

  const props: ImageProps = {
    x: topLeft.x + sl.geometry.widthLeftChanged + sl.geometry.tempWidthLeftChanged,
    y: topLeft.y + sl.geometry.heightTopChanged + sl.geometry.tempHeightTopChanged,
    width: sl.geometry.originalWidth,
    height: sl.geometry.originalHeight,
    clipPath: `url(#clip-image-${sl.id})`,
  };

  if (sl.geometry.hasFixedImage) {
    const topRight = {
      x: sl.geometry.topRightPoint.x + sl.geometry.topRightPoint.xOffset,
      y: sl.geometry.topRightPoint.y + sl.geometry.topRightPoint.yOffset,
    } as GeometryPoint;
    const bottomLeft = {
      x: sl.geometry.bottomLeftPoint.x + sl.geometry.bottomLeftPoint.xOffset,
      y: sl.geometry.bottomLeftPoint.y + sl.geometry.bottomLeftPoint.yOffset,
    } as GeometryPoint;
    props.clipPath = undefined;
    props.x = topLeft.x;
    props.y = topLeft.y;
    props.width = getLineDistance(topLeft, topRight);
    props.height = getLineDistance(topLeft, bottomLeft);
  }

  return props;
}

const ShelfLocationImageComponent: React.FC<MyProps> = ({ shelfLocation }: MyProps) => {
  const imageProps = getImageProps(shelfLocation);
  return <image xlinkHref={shelfLocation.imageUrl} style={{ pointerEvents: 'none' }} {...imageProps} />;
};

export default ShelfLocationImageComponent;
