export const ADMIN_USERS_LOAD_REQUEST = 'ADMIN_USERS_LOAD_REQUEST';
export type ADMIN_USERS_LOAD_REQUEST = typeof ADMIN_USERS_LOAD_REQUEST;

export const ADMIN_USERS_LOAD_ERROR = 'ADMIN_USERS_LOAD_ERROR';
export type ADMIN_USERS_LOAD_ERROR = typeof ADMIN_USERS_LOAD_ERROR;

export const ADMIN_USERS_LOAD_SUCCESS = 'ADMIN_USERS_LOAD_SUCCESS';
export type ADMIN_USERS_LOAD_SUCCESS = typeof ADMIN_USERS_LOAD_SUCCESS;

export const ADMIN_USER_PLANOGRAMS_LOAD_REQUEST = 'ADMIN_USER_PLANOGRAMS_LOAD_REQUEST';
export type ADMIN_USER_PLANOGRAMS_LOAD_REQUEST = typeof ADMIN_USER_PLANOGRAMS_LOAD_REQUEST;

export const ADMIN_USER_PLANOGRAMS_LOAD_ERROR = 'ADMIN_USER_PLANOGRAMS_LOAD_ERROR';
export type ADMIN_USER_PLANOGRAMS_LOAD_ERROR = typeof ADMIN_USER_PLANOGRAMS_LOAD_ERROR;

export const ADMIN_USER_PLANOGRAMS_LOAD_SUCCESS = 'ADMIN_USER_PLANOGRAMS_LOAD_SUCCESS';
export type ADMIN_USER_PLANOGRAMS_LOAD_SUCCESS = typeof ADMIN_USER_PLANOGRAMS_LOAD_SUCCESS;
