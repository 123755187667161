import React, { useState } from 'react';
import { FieldArray, FieldArrayRenderProps } from 'formik';
import ChipInput from 'material-ui-chip-input';

import { schemaEmail } from '../../../validation';
import { Typography } from '@material-ui/core';

export interface UsersEmailsProps {
  userGroupIndex: number;
}

const UsersEmails: React.FC<UsersEmailsProps> = ({ userGroupIndex }: UsersEmailsProps): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState('');
  const handleOnBeforeAdd = (chip: string): boolean => {
    const isValid = schemaEmail.isValidSync(chip);
    setErrorMessage(isValid ? '' : 'Email is not valid');
    return isValid;
  };
  return (
    <FieldArray name={`userGroups.${userGroupIndex}.emails`}>
      {({ push, remove, form }: FieldArrayRenderProps): JSX.Element => {
        return (
          <>
            <ChipInput
              blurBehavior="add"
              label="Emails"
              fullWidth={true}
              fullWidthInput={true}
              placeholder="Enter user's email addresses here.."
              variant="outlined"
              defaultValue={form.values.userGroups[userGroupIndex].emails}
              value={form.values.userGroups[userGroupIndex].emails}
              onBeforeAdd={handleOnBeforeAdd}
              onAdd={(chip: string): void => {
                push(chip);
              }}
              onDelete={(chip: string, index: number): void => {
                remove(index);
              }}
            />
            {errorMessage && <Typography color="error">{errorMessage}</Typography>}
          </>
        );
      }}
    </FieldArray>
  );
};

export default UsersEmails;
