import { isLoaded, isLoading } from 'infrastructure/utils/RemoteObjectStatus';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { loadAggregatedBrandReportRequestAction } from '../../actions';
import { getAggregatedBrandReportLoadState, isAggregatedBrandReportEmpty, getAggregatedBrandReportData } from '../../selectors';
import { AggregatedMetrics, AggregatedOnePagerReport } from '../../types';

interface LoadStatusParams {
  isLoaded: boolean;
  isLoading: boolean;
  loadError: string;
  isDataEmpty: boolean;
  data: AggregatedOnePagerReport | undefined;
}

export interface AggregatedBrandReportLoaderProps {
  children: (params: LoadStatusParams) => React.ReactNode;
}

const AggregatedBrandReportLoader: React.FC<AggregatedBrandReportLoaderProps> = ({ children }: AggregatedBrandReportLoaderProps) => {
  type RequestParamsType = {
    brandId: string;
    metrics: string;
  };

  const { brandId, metrics } = useParams<RequestParamsType>();
  const dispatch = useDispatch();
  const loadState = useSelector(getAggregatedBrandReportLoadState);
  const isDataEmpty = useSelector(isAggregatedBrandReportEmpty);
  const data = useSelector(getAggregatedBrandReportData);
  useEffect(() => {
    if (isDataEmpty && brandId && metrics !== undefined) {
      const selectedMetrics = metrics.split(',').map(x => parseInt(x)) as AggregatedMetrics[];
      dispatch(loadAggregatedBrandReportRequestAction(parseInt(brandId), selectedMetrics));
    }
  }, [dispatch, isDataEmpty, brandId, metrics]);
  return (
    <>
      {children({
        isLoaded: isLoaded(loadState),
        isLoading: isLoading(loadState),
        loadError: loadState?.errorDetails?.errorMessage ?? '',
        isDataEmpty,
        data,
      })}
    </>
  );
};

export { AggregatedBrandReportLoader };
