import { isLoaded, isLoading } from 'infrastructure/utils/RemoteObjectStatus';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { loadStandardProjectReportRequestAction } from '../../actions';
import * as selectors from '../../selectors';
import { ProjectReportResult } from '../../types';
interface LoadStatusParams {
  isLoaded: boolean;
  isLoading: boolean;
  loadError: string;
  isDataEmpty: boolean;
  data: ProjectReportResult | undefined;
}
export interface StandardProjectReportLoaderProps {
  children: (params: LoadStatusParams) => React.ReactNode;
}

const StandardProjectReportLoader: React.FC<StandardProjectReportLoaderProps> = ({ children }: StandardProjectReportLoaderProps) => {
  const { shelfPhaseId } = useParams();
  const dispatch = useDispatch();
  const loadState = useSelector(selectors.getProjectReportLoadState);
  const isDataEmpty = useSelector(selectors.isProjectReportEmpty);
  const data = useSelector(selectors.getProjectReportData);

  useEffect(() => {
    if (isDataEmpty && shelfPhaseId) {
      dispatch(loadStandardProjectReportRequestAction(shelfPhaseId));
    }
  }, [dispatch, isDataEmpty, shelfPhaseId]);
  return (
    <>
      {children({
        isLoaded: isLoaded(loadState),
        isLoading: isLoading(loadState),
        loadError: loadState?.errorDetails?.errorMessage ?? '',
        isDataEmpty,
        data,
      })}
    </>
  );
};

export { StandardProjectReportLoader };
