import { isLoaded, isLoading } from 'infrastructure/utils/RemoteObjectStatus';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { loadAggregatedProductReportRequestAction } from '../../actions';
import { getAggregatedProductReportLoadState, getAggregatedProductReportData, isAggregatedProductReportEmpty } from '../../selectors';
import { AggregatedMetrics, AggregatedOnePagerReport } from '../../types';

interface LoadStatusParams {
  isLoaded: boolean;
  isLoading: boolean;
  loadError: string;
  isDataEmpty: boolean;
  data: AggregatedOnePagerReport | undefined;
}

export interface AggregatedProductReportLoaderProps {
  children: (params: LoadStatusParams) => React.ReactNode;
}

const AggregatedProductReportLoader: React.FC<AggregatedProductReportLoaderProps> = ({ children }: AggregatedProductReportLoaderProps) => {
  type RequestParamsType = {
    gtin: string;
    metrics: string;
  };

  const { gtin, metrics } = useParams<RequestParamsType>();
  const dispatch = useDispatch();
  const loadState = useSelector(getAggregatedProductReportLoadState);
  const isDataEmpty = useSelector(isAggregatedProductReportEmpty);
  const data = useSelector(getAggregatedProductReportData);
  useEffect(() => {
    if (isDataEmpty && gtin && metrics !== undefined) {
      const selectedMetrics = metrics.split(',').map(x => parseInt(x)) as AggregatedMetrics[];
      dispatch(loadAggregatedProductReportRequestAction(parseInt(gtin), selectedMetrics));
    }
  }, [dispatch, isDataEmpty, gtin, metrics]);
  return (
    <>
      {children({
        isLoaded: isLoaded(loadState),
        isLoading: isLoading(loadState),
        loadError: loadState?.errorDetails?.errorMessage ?? '',
        isDataEmpty,
        data,
      })}
    </>
  );
};

export { AggregatedProductReportLoader };
