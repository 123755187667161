import { Card, Grid } from '@material-ui/core';
import _ from 'lodash';
import { PhaseOption, ProjectOption, ShelfOption, StoreOption } from 'modules/reports/types';
import { PhaseSelector } from 'modules/shared/components/DropDownSelectors/PhaseSelector';
import { ProjectSelector } from 'modules/shared/components/DropDownSelectors/ProjectSelector';
import { ShelfSelector } from 'modules/shared/components/DropDownSelectors/ShelfSelector';
import { StoreSelector } from 'modules/shared/components/DropDownSelectors/StoreSelector';
import React from 'react';
import { StandardReportPhaseShelf } from '../types';
export interface StandardReportsShelfSelectorProps {
  phaseShelves: StandardReportPhaseShelf[];
  selectedProjectId?: number;
  selectedStoreId?: number;
  selectedPhaseNum?: number;
  selectedShelfNum?: number;
  onChangeProject(projectOption: ProjectOption): void;
  onChangeStore(storeOption: StoreOption): void;
  onChangePhase(phaseOption: PhaseOption): void;
  onChangeShelf(shelfOption: ShelfOption): void;
}

const StandardReportsShelfSelector: React.FC<StandardReportsShelfSelectorProps> = ({
  phaseShelves,
  selectedProjectId,
  selectedStoreId,
  selectedPhaseNum,
  selectedShelfNum,
  onChangeProject,
  onChangeStore,
  onChangePhase,
  onChangeShelf,
}: StandardReportsShelfSelectorProps) => {
  const getProjectOption = (projectId: number | undefined): ProjectOption | undefined => {
    const selectedShelf = phaseShelves.find(x => x.projectId === projectId);
    if (selectedShelf === undefined || projectId === undefined) {
      return undefined;
    }

    const projectOption: ProjectOption = {
      label: selectedShelf.projectName,
      projectName: selectedShelf.projectName,
      projectId: projectId,
      value: projectId,
    };
    return projectOption;
  };
  const getStoreOption = (storeId: number | undefined): StoreOption | undefined => {
    const selectedShelf = phaseShelves.find(x => x.storeId === storeId);
    if (selectedShelf === undefined || storeId === undefined) {
      return undefined;
    }

    const storeOption: StoreOption = {
      label: selectedShelf.storeName,
      projectId: selectedShelf.projectId,
      storeName: selectedShelf.storeName,
      storeId: storeId,
      value: storeId,
    };
    return storeOption;
  };
  const getShelfOption = (shelfNum: number | undefined): ShelfOption | undefined => {
    if (selectedProjectId === undefined || selectedStoreId === undefined || shelfNum === undefined) {
      return undefined;
    }
    const selectedShelf = phaseShelves.find(x => x.projectId === selectedProjectId && x.storeId === selectedStoreId);
    if (selectedShelf === undefined) {
      return undefined;
    }
    const shelfOption: ShelfOption = {
      label: shelfNum.toString(),
      projectId: selectedShelf.projectId,
      storeId: selectedShelf.storeId,
      storeName: selectedShelf.storeName,
      shelfNum: shelfNum,
      value: shelfNum,
    };
    return shelfOption;
  };
  const getPhaseOption = (phaseNum: number | undefined): PhaseOption | undefined => {
    if (selectedProjectId === undefined || selectedStoreId === undefined || selectedShelfNum === undefined || phaseNum === undefined) {
      return undefined;
    }
    const selectedShelf = phaseShelves.find(x => x.projectId === selectedProjectId && x.storeId === selectedStoreId && x.shelfNum === selectedShelfNum);
    if (selectedShelf === undefined) {
      return undefined;
    }
    const phaseOption: PhaseOption = {
      label: phaseNum.toString(),
      projectId: selectedShelf.projectId,
      storeId: selectedShelf.storeId,
      shelfNum: selectedShelf.shelfNum,
      startDate: '',
      endDate: '',
      storeName: selectedShelf.storeName,
      phaseNum: phaseNum,
      value: phaseNum,
    };
    return phaseOption;
  };

  const getProjectOptions = (): ProjectOption[] => {
    const projectOptions: ProjectOption[] = _.orderBy(
      _.uniqBy(
        phaseShelves.map(shelf => {
          const projectOption: ProjectOption = {
            label: shelf.projectName,
            projectId: shelf.projectId,
            projectName: shelf.projectName,
            value: shelf.projectId,
          };
          return projectOption;
        }),
        'projectId' // unique projects
      ),
      ['projectName'], // ordered by projectName ASC
      ['asc']
    );
    return projectOptions;
  };
  const getStoreOptions = (): StoreOption[] => {
    if (selectedProjectId === undefined) {
      return [] as StoreOption[];
    }
    const shelves = phaseShelves.filter(shelf => shelf.projectId === selectedProjectId);
    const storeOptions: StoreOption[] = _.orderBy(
      _.uniqBy(
        shelves.map(shelf => {
          const storeOption: StoreOption = {
            label: shelf.storeName,
            projectId: shelf.projectId,
            storeId: shelf.storeId,
            storeName: shelf.storeName,
            value: shelf.storeId,
          };
          return storeOption;
        }),
        'storeId' // unique storeIds
      ),
      ['storeName'], // ordered by storeName ASC
      ['asc']
    );
    return storeOptions;
  };
  const getShelfOptions = (): ShelfOption[] => {
    if (selectedProjectId === undefined || selectedStoreId === undefined) {
      return [] as ShelfOption[];
    }
    const shelves = phaseShelves.filter(shelf => shelf.projectId === selectedProjectId && shelf.storeId === selectedStoreId);
    const shelfOptions: ShelfOption[] = _.orderBy(
      _.uniqBy(
        shelves.map(shelf => {
          const shelfOption: ShelfOption = {
            label: shelf.shelfNum.toString(),
            projectId: shelf.projectId,
            storeId: shelf.storeId,
            shelfNum: shelf.shelfNum,
            value: shelf.shelfNum,
            storeName: shelf.storeName,
          };
          return shelfOption;
        }),
        'shelfNum' // unique shelf nums
      ),
      ['shelfNum'], // ordered by shelfNum ASC
      ['asc']
    );
    return shelfOptions;
  };
  const getPhaseOptions = (): PhaseOption[] => {
    if (selectedProjectId === undefined || selectedStoreId === undefined || selectedShelfNum === undefined) {
      return [] as PhaseOption[];
    }
    const shelves = phaseShelves.filter(shelf => shelf.projectId === selectedProjectId && shelf.storeId === selectedStoreId && shelf.shelfNum === selectedShelfNum);
    const phaseOptions: PhaseOption[] = _.orderBy(
      _.uniqBy(
        shelves.map(shelf => {
          const phaseOption: PhaseOption = {
            label: shelf.phaseNum.toString(),
            projectId: shelf.projectId,
            storeId: shelf.storeId,
            shelfNum: shelf.shelfNum,
            phaseNum: shelf.phaseNum,
            startDate: '',
            endDate: '',
            storeName: shelf.storeName,
            value: shelf.phaseNum,
          };
          return phaseOption;
        }),
        'phaseNum' // unique projects
      ),
      ['phaseNum'], // ordered by phaseNum ASC
      ['asc']
    );
    return phaseOptions;
  };

  return (
    <>
      <Card style={{ margin: '8px', padding: '16px' }}>
        <Grid container direction="column" justify="flex-start" spacing={2}>
          <Grid item xs={12} style={{ width: '100%' }}>
            <ProjectSelector
              htmlId={`StandardReport_ProjectSelector_${selectedProjectId}-${selectedStoreId}-${selectedShelfNum}-${selectedPhaseNum}`}
              projectOptions={getProjectOptions()}
              selectedProject={getProjectOption(selectedProjectId)}
              handleOnSelectProject={(projectOption: ProjectOption): void => {
                return onChangeProject(projectOption);
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ width: '100%' }}>
            <StoreSelector
              htmlId={`StandardReport_StoreSelector_${selectedProjectId}-${selectedStoreId}-${selectedShelfNum}-${selectedPhaseNum}`}
              storeOptions={getStoreOptions()}
              selectedStore={getStoreOption(selectedStoreId)}
              handleOnSelectStore={(storeOption: StoreOption): void => {
                return onChangeStore(storeOption);
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ width: '100%' }}>
            <ShelfSelector
              htmlId={`StandardReport_ShelfSelector_${selectedProjectId}-${selectedStoreId}-${selectedShelfNum}-${selectedPhaseNum}`}
              shelfOptions={getShelfOptions()}
              selectedShelf={getShelfOption(selectedShelfNum)}
              handleOnSelectShelf={(shelfOption: ShelfOption): void => {
                return onChangeShelf(shelfOption);
              }}
            />
          </Grid>
          <Grid item xs={12} style={{ width: '100%' }}>
            <PhaseSelector
              htmlId={`StandardReport_PhaseSelector_${selectedProjectId}-${selectedStoreId}-${selectedShelfNum}-${selectedPhaseNum}`}
              phaseOptions={getPhaseOptions()}
              selectedPhase={getPhaseOption(selectedPhaseNum)}
              handleOnSelectPhase={(phaseOption: PhaseOption): void => {
                return onChangePhase(phaseOption);
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export { StandardReportsShelfSelector };
