import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles(() =>
  createStyles({
    image: {
      maxWidth: '100%',
      height: 'auto',
    },
  })
);

export interface ImageProps {
  src: string;
}

const Image: React.FC<ImageProps> = ({ src }: ImageProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Link href={src} target="_blank" rel="noopener noreferrer">
      <img className={classes.image} src={src} alt={src} />
    </Link>
  );
};

export default Image;
