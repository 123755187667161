export const FORCE_UPDATE = 'FORCE_UPDATE';
export type FORCE_UPDATE = typeof FORCE_UPDATE;

export const PLANOGRAM_PAGE_RESET = 'PLANOGRAM_PAGE_RESET';
export type PLANOGRAM_PAGE_RESET = typeof PLANOGRAM_PAGE_RESET;

export const PLANOGRAM_IMAGE_LOADING = 'PLANOGRAM_IMAGE_LOADING';
export type PLANOGRAM_IMAGE_LOADING = typeof PLANOGRAM_IMAGE_LOADING;

export const PLANOGRAM_IMAGE_LOADED = 'PLANOGRAM_IMAGE_LOADED';
export type PLANOGRAM_IMAGE_LOADED = typeof PLANOGRAM_IMAGE_LOADED;

export const PLANOGRAM_IMAGE_LOAD_ERROR = 'PLANOGRAM_IMAGE_LOAD_ERROR';
export type PLANOGRAM_IMAGE_LOAD_ERROR = typeof PLANOGRAM_IMAGE_LOAD_ERROR;

// Load Planogram
export const PLANOGRAM_LOADING = 'PLANOGRAM_LOADING';
export type PLANOGRAM_LOADING = typeof PLANOGRAM_LOADING;

export const PLANOGRAM_LOADED = 'PLANOGRAM_LOADED';
export type PLANOGRAM_LOADED = typeof PLANOGRAM_LOADED;

export const ERROR_LOADING_PLANOGRAM = 'ERROR_LOADING_PLANOGRAM';
export type ERROR_LOADING_PLANOGRAM = typeof ERROR_LOADING_PLANOGRAM;

export const SAVING_PLANOGRAM_CHANGES = 'SAVING_PLANOGRAM_CHANGES';
export type SAVING_PLANOGRAM_CHANGES = typeof SAVING_PLANOGRAM_CHANGES;

export const UPDATE_PLANOGRAM_VIEW_PORT = 'UPDATE_PLANOGRAM_VIEW_PORT';
export type UPDATE_PLANOGRAM_VIEW_PORT = typeof UPDATE_PLANOGRAM_VIEW_PORT;

// Load Public Planogram
export const PUBLIC_PLANOGRAM_LOADING = 'PUBLIC_PLANOGRAM_LOADING';
export type PUBLIC_PLANOGRAM_LOADING = typeof PUBLIC_PLANOGRAM_LOADING;

export const PUBLIC_PLANOGRAM_LOADED = 'PUBLIC_PLANOGRAM_LOADED';
export type PUBLIC_PLANOGRAM_LOADED = typeof PUBLIC_PLANOGRAM_LOADED;

export const ERROR_LOADING_PUBLIC_PLANOGRAM = 'ERROR_LOADING_PUBLIC_PLANOGRAM';
export type ERROR_LOADING_PUBLIC_PLANOGRAM = typeof ERROR_LOADING_PUBLIC_PLANOGRAM;

// Load Test Planogram
export const TEST_PLANOGRAM_LOADING = 'TEST_PLANOGRAM_LOADING';
export type TEST_PLANOGRAM_LOADING = typeof TEST_PLANOGRAM_LOADING;

export const TEST_PLANOGRAM_LOADED = 'TEST_PLANOGRAM_LOADED';
export type TEST_PLANOGRAM_LOADED = typeof TEST_PLANOGRAM_LOADED;

export const ERROR_LOADING_TEST_PLANOGRAM = 'ERROR_LOADING_TEST_PLANOGRAM';
export type ERROR_LOADING_TEST_PLANOGRAM = typeof ERROR_LOADING_TEST_PLANOGRAM;

// Load FlowData for Planogram
export const FLOW_DATA_LOADING = 'FLOW_DATA_LOADING';
export type FLOW_DATA_LOADING = typeof FLOW_DATA_LOADING;

export const FLOW_DATA_LOADED = 'FLOW_DATA_LOADED';
export type FLOW_DATA_LOADED = typeof FLOW_DATA_LOADED;

export const FLOW_DATA_LOAD_FAILED = 'FLOW_DATA_LOAD_FAILED';
export type FLOW_DATA_LOAD_FAILED = typeof FLOW_DATA_LOAD_FAILED;

// Load Competitor Planogram
export const LOAD_COMPETITOR_PLANOGRAM_REQUEST = 'LOAD_COMPETITOR_PLANOGRAM_REQUEST';
export type LOAD_COMPETITOR_PLANOGRAM_REQUEST = typeof LOAD_COMPETITOR_PLANOGRAM_REQUEST;

export const LOAD_COMPETITOR_PLANOGRAM_SUCCESS = 'LOAD_COMPETITOR_PLANOGRAM_SUCCESS';
export type LOAD_COMPETITOR_PLANOGRAM_SUCCESS = typeof LOAD_COMPETITOR_PLANOGRAM_SUCCESS;

export const LOAD_COMPETITOR_PLANOGRAM_FAILURE = 'LOAD_COMPETITOR_PLANOGRAM_FAILURE';
export type LOAD_COMPETITOR_PLANOGRAM_FAILURE = typeof LOAD_COMPETITOR_PLANOGRAM_FAILURE;

export const ON_PLANOGRAM_EVENT_REQUEST = 'ON_PLANOGRAM_EVENT_REQUEST';
export type ON_PLANOGRAM_EVENT_REQUEST = typeof ON_PLANOGRAM_EVENT_REQUEST;

export const CHANGE_PLANOGRAM_NAME = 'CHANGE_PLANOGRAM_NAME';
export type CHANGE_PLANOGRAM_NAME = typeof CHANGE_PLANOGRAM_NAME;

export const CHANGE_PLANOGRAM_NAME_SUCCESS = 'CHANGE_PLANOGRAM_NAME_SUCCESS';
export type CHANGE_PLANOGRAM_NAME_SUCCESS = typeof CHANGE_PLANOGRAM_NAME_SUCCESS;

export const CHANGE_PLANOGRAM_NAME_ERROR = 'CHANGE_PLANOGRAM_NAME_ERROR';
export type CHANGE_PLANOGRAM_NAME_ERROR = typeof CHANGE_PLANOGRAM_NAME_ERROR;

export const DELETE_PLANOGRAM_REQUEST = 'DELETE_PLANOGRAM_REQUEST';
export type DELETE_PLANOGRAM_REQUEST = typeof DELETE_PLANOGRAM_REQUEST;

export const DELETE_PLANOGRAM_SUCCESS = 'DELETE_PLANOGRAM_SUCCESS';
export type DELETE_PLANOGRAM_SUCCESS = typeof DELETE_PLANOGRAM_SUCCESS;

export const DELETE_PLANOGRAM_FAILED = 'DELETE_PLANOGRAM_FAILED';
export type DELETE_PLANOGRAM_FAILED = typeof DELETE_PLANOGRAM_FAILED;

export const TOGGLE_ENABLE_SHOW_PRODUCT_INFO = 'TOGGLE_ENABLE_SHOW_PRODUCT_INFO';
export type TOGGLE_ENABLE_SHOW_PRODUCT_INFO = typeof TOGGLE_ENABLE_SHOW_PRODUCT_INFO;

export const TOGGLE_ENABLE_SALES_DATA_REPORT = 'TOGGLE_ENABLE_SALES_DATA_REPORT';
export type TOGGLE_ENABLE_SALES_DATA_REPORT = typeof TOGGLE_ENABLE_SALES_DATA_REPORT;

export const TOGGLE_ENABLE_FLOW_DATA_REPORT = 'TOGGLE_ENABLE_FLOW_DATA_REPORT';
export type TOGGLE_ENABLE_FLOW_DATA_REPORT = typeof TOGGLE_ENABLE_FLOW_DATA_REPORT;

export const TOGGLE_ENABLE_CLIENT_DATASET_REPORT = 'TOGGLE_ENABLE_CLIENT_DATASET_REPORT';
export type TOGGLE_ENABLE_CLIENT_DATASET_REPORT = typeof TOGGLE_ENABLE_CLIENT_DATASET_REPORT;

export const TOGGLE_ENABLE_COMPETITOR_SHELF_DATA_REPORT = 'TOGGLE_ENABLE_COMPETITOR_SHELF_DATA_REPORT';
export type TOGGLE_ENABLE_COMPETITOR_SHELF_DATA_REPORT = typeof TOGGLE_ENABLE_COMPETITOR_SHELF_DATA_REPORT;

export const SAVE_SALES_DATA_REQUEST = 'SAVE_SALES_DATA_REQUEST';
export type SAVE_SALES_DATA_REQUEST = typeof SAVE_SALES_DATA_REQUEST;

export const SAVE_SALES_DATA_SUCCESS = 'SAVE_SALES_DATA_SUCCESS';
export type SAVE_SALES_DATA_SUCCESS = typeof SAVE_SALES_DATA_SUCCESS;

export const SAVE_SALES_DATA_FAILED = 'SAVE_SALES_DATA_FAILED';
export type SAVE_SALES_DATA_FAILED = typeof SAVE_SALES_DATA_FAILED;

export const DELETE_SALES_DATA_REQUEST = 'DELETE_SALES_DATA_REQUEST';
export type DELETE_SALES_DATA_REQUEST = typeof DELETE_SALES_DATA_REQUEST;

export const DELETE_SALES_DATA_SUCCESS = 'DELETE_SALES_DATA_SUCCESS';
export type DELETE_SALES_DATA_SUCCESS = typeof DELETE_SALES_DATA_SUCCESS;

export const DELETE_SALES_DATA_FAILED = 'DELETE_SALES_DATA_FAILED';
export type DELETE_SALES_DATA_FAILED = typeof DELETE_SALES_DATA_FAILED;

export const NEW_PRODUCT_REQUEST = 'NEW_PRODUCT_REQUEST';
export type NEW_PRODUCT_REQUEST = typeof NEW_PRODUCT_REQUEST;

export const NEW_PRODUCT_STOP = 'NEW_PRODUCT_STOP';
export type NEW_PRODUCT_STOP = typeof NEW_PRODUCT_STOP;

export const UPLOAD_SHELFLOCATION_IMAGE_REQUEST = 'UPLOAD_SHELFLOCATION_IMAGE_REQUEST';
export type UPLOAD_SHELFLOCATION_IMAGE_REQUEST = typeof UPLOAD_SHELFLOCATION_IMAGE_REQUEST;

export const UPLOAD_SHELFLOCATION_IMAGE_SUCCESS = 'UPLOAD_SHELFLOCATION_IMAGE_SUCCESS';
export type UPLOAD_SHELFLOCATION_IMAGE_SUCCESS = typeof UPLOAD_SHELFLOCATION_IMAGE_SUCCESS;

export const UPLOAD_SHELFLOCATION_IMAGE_ERROR = 'UPLOAD_SHELFLOCATION_IMAGE_ERROR';
export type UPLOAD_SHELFLOCATION_IMAGE_ERROR = typeof UPLOAD_SHELFLOCATION_IMAGE_ERROR;

export const FIX_IMAGE_SIZE_REQUEST = 'FIX_IMAGE_SIZE_REQUEST';
export type FIX_IMAGE_SIZE_REQUEST = typeof FIX_IMAGE_SIZE_REQUEST;

export const FIX_IMAGE_SIZE_SUCCESS = 'FIX_IMAGE_SIZE_SUCCESS';
export type FIX_IMAGE_SIZE_SUCCESS = typeof FIX_IMAGE_SIZE_SUCCESS;

export const FIX_IMAGE_SIZE_ERROR = 'FIX_IMAGE_SIZE_ERROR';
export type FIX_IMAGE_SIZE_ERROR = typeof FIX_IMAGE_SIZE_ERROR;

export const SET_SHELFLOCATION_GTIN_REQUEST = 'SET_SHELFLOCATION_GTIN_REQUEST';
export type SET_SHELFLOCATION_GTIN_REQUEST = typeof SET_SHELFLOCATION_GTIN_REQUEST;

export const SET_SHELFLOCATION_GTIN_SUCCESS = 'SET_SHELFLOCATION_GTIN_SUCCESS';
export type SET_SHELFLOCATION_GTIN_SUCCESS = typeof SET_SHELFLOCATION_GTIN_SUCCESS;

export const SET_SHELFLOCATION_GTIN_ERROR = 'SET_SHELFLOCATION_GTIN_ERROR';
export type SET_SHELFLOCATION_GTIN_ERROR = typeof SET_SHELFLOCATION_GTIN_ERROR;

export const SET_FLOW_REPORT_TYPE = 'SET_FLOW_REPORT_TYPE';
export type SET_FLOW_REPORT_TYPE = typeof SET_FLOW_REPORT_TYPE;

export const SET_SALES_REPORT_TYPE = 'SET_SALES_REPORT_TYPE';
export type SET_SALES_REPORT_TYPE = typeof SET_SALES_REPORT_TYPE;

export const SET_CLIENT_DATASET_REPORT_TYPE = 'SET_CLIENT_DATASET_REPORT_TYPE';
export type SET_CLIENT_DATASET_REPORT_TYPE = typeof SET_CLIENT_DATASET_REPORT_TYPE;

export const UPDATE_PLANOGRAM_COMMENTS_REQUEST = 'UPDATE_PLANOGRAM_COMMENTS_REQUEST';
export type UPDATE_PLANOGRAM_COMMENTS_REQUEST = typeof UPDATE_PLANOGRAM_COMMENTS_REQUEST;

export const UPDATE_PLANOGRAM_COMMENTS_SUCCESS = 'UPDATE_PLANOGRAM_COMMENTS_SUCCESS';
export type UPDATE_PLANOGRAM_COMMENTS_SUCCESS = typeof UPDATE_PLANOGRAM_COMMENTS_SUCCESS;

export const UPDATE_PLANOGRAM_COMMENTS_ERROR = 'UPDATE_PLANOGRAM_COMMENTS_ERROR';
export type UPDATE_PLANOGRAM_COMMENTS_ERROR = typeof UPDATE_PLANOGRAM_COMMENTS_ERROR;

export const PLANOGRAM_SHARE_MODAL_TOGGLE = 'PLANOGRAM_SHARE_MODAL_TOGGLE';
export type PLANOGRAM_SHARE_MODAL_TOGGLE = typeof PLANOGRAM_SHARE_MODAL_TOGGLE;

export const PLANOGRAM_PUBLIC_SHARE_TOGGLE_REQUEST = 'PLANOGRAM_PUBLIC_SHARE_TOGGLE_REQUEST';
export type PLANOGRAM_PUBLIC_SHARE_TOGGLE_REQUEST = typeof PLANOGRAM_PUBLIC_SHARE_TOGGLE_REQUEST;

export const PLANOGRAM_PUBLIC_SHARE_TOGGLE_SUCCESS = 'PLANOGRAM_PUBLIC_SHARE_TOGGLE_SUCCESS';
export type PLANOGRAM_PUBLIC_SHARE_TOGGLE_SUCCESS = typeof PLANOGRAM_PUBLIC_SHARE_TOGGLE_SUCCESS;

export const PLANOGRAM_PUBLIC_SHARE_TOGGLE_FAILED = 'PLANOGRAM_PUBLIC_SHARE_TOGGLE_FAILED';
export type PLANOGRAM_PUBLIC_SHARE_TOGGLE_FAILED = typeof PLANOGRAM_PUBLIC_SHARE_TOGGLE_FAILED;

export const SHOW_TEST_WITH_FLOW_MODAL = 'SHOW_TEST_WITH_FLOW_MODAL';
export type SHOW_TEST_WITH_FLOW_MODAL = typeof SHOW_TEST_WITH_FLOW_MODAL;

export const HIDE_TEST_WITH_FLOW_MODAL = 'HIDE_TEST_WITH_FLOW_MODAL';
export type HIDE_TEST_WITH_FLOW_MODAL = typeof HIDE_TEST_WITH_FLOW_MODAL;

export const SHOW_TEST_DETAILS_MODAL = 'SHOW_TEST_DETAILS_MODAL';
export type SHOW_TEST_DETAILS_MODAL = typeof SHOW_TEST_DETAILS_MODAL;

export const HIDE_TEST_DETAILS_MODAL = 'HIDE_TEST_DETAILS_MODAL';
export type HIDE_TEST_DETAILS_MODAL = typeof HIDE_TEST_DETAILS_MODAL;

export const SHOW_EDIT_TEST_MODAL = 'SHOW_EDIT_TEST_MODAL';
export type SHOW_EDIT_TEST_MODAL = typeof SHOW_EDIT_TEST_MODAL;

export const HIDE_EDIT_TEST_MODAL = 'HIDE_EDIT_TEST_MODAL';
export type HIDE_EDIT_TEST_MODAL = typeof HIDE_EDIT_TEST_MODAL;

export const SHOW_DELETE_TEST_CONFIRMATION_MODAL = 'SHOW_DELETE_TEST_CONFIRMATION_MODAL';
export type SHOW_DELETE_TEST_CONFIRMATION_MODAL = typeof SHOW_DELETE_TEST_CONFIRMATION_MODAL;

export const HIDE_DELETE_TEST_CONFIRMATION_MODAL = 'HIDE_DELETE_TEST_CONFIRMATION_MODAL';
export type HIDE_DELETE_TEST_CONFIRMATION_MODAL = typeof HIDE_DELETE_TEST_CONFIRMATION_MODAL;

export const UPDATE_TEST_INFO_REQUEST = 'UPDATE_TEST_INFO_REQUEST';
export type UPDATE_TEST_INFO_REQUEST = typeof UPDATE_TEST_INFO_REQUEST;

export const UPDATE_TEST_INFO_SUCCESS = 'UPDATE_TEST_INFO_SUCCESS';
export type UPDATE_TEST_INFO_SUCCESS = typeof UPDATE_TEST_INFO_SUCCESS;

export const UPDATE_TEST_INFO_FAILURE = 'UPDATE_TEST_INFO_FAILURE';
export type UPDATE_TEST_INFO_FAILURE = typeof UPDATE_TEST_INFO_FAILURE;

export const DELETE_TEST_REQUEST = 'DELETE_TEST_REQUEST';
export type DELETE_TEST_REQUEST = typeof DELETE_TEST_REQUEST;

export const DELETE_TEST_SUCCESS = 'DELETE_TEST_SUCCESS';
export type DELETE_TEST_SUCCESS = typeof DELETE_TEST_SUCCESS;

export const DELETE_TEST_FAILURE = 'DELETE_TEST_FAILURE';
export type DELETE_TEST_FAILURE = typeof DELETE_TEST_FAILURE;

export const CREATE_TEST_PLANOGRAM_REQUEST = 'CREATE_TEST_PLANOGRAM_REQUEST';
export type CREATE_TEST_PLANOGRAM_REQUEST = typeof CREATE_TEST_PLANOGRAM_REQUEST;

export const CREATE_TEST_PLANOGRAM_SUCCESS = 'CREATE_TEST_PLANOGRAM_SUCCESS';
export type CREATE_TEST_PLANOGRAM_SUCCESS = typeof CREATE_TEST_PLANOGRAM_SUCCESS;

export const CREATE_TEST_PLANOGRAM_FAILURE = 'CREATE_TEST_PLANOGRAM_FAILURE';
export type CREATE_TEST_PLANOGRAM_FAILURE = typeof CREATE_TEST_PLANOGRAM_FAILURE;

export const TOGGLE_SHOW_INVALID_SHELF_LOCATIONS = 'TOGGLE_SHOW_INVALID_SHELF_LOCATIONS';
export type TOGGLE_SHOW_INVALID_SHELF_LOCATIONS = typeof TOGGLE_SHOW_INVALID_SHELF_LOCATIONS;

export const LOAD_TESTS_INFO_REQUEST = 'LOAD_TESTS_INFO_REQUEST';
export type LOAD_TESTS_INFO_REQUEST = typeof LOAD_TESTS_INFO_REQUEST;

export const LOAD_TESTS_INFO_SUCCESS = 'LOAD_TESTS_INFO_SUCCESS';
export type LOAD_TESTS_INFO_SUCCESS = typeof LOAD_TESTS_INFO_SUCCESS;

export const LOAD_TESTS_INFO_FAILURE = 'LOAD_TESTS_INFO_FAILURE';
export type LOAD_TESTS_INFO_FAILURE = typeof LOAD_TESTS_INFO_FAILURE;

export const TOGGLE_SHOW_INITIAL_PLANOGRAM = 'TOGGLE_SHOW_INITIAL_PLANOGRAM';
export type TOGGLE_SHOW_INITIAL_PLANOGRAM = typeof TOGGLE_SHOW_INITIAL_PLANOGRAM;

export const UNDO_DELISTED_PRODUCT = 'UNDO_DELISTED_PRODUCT';
export type UNDO_DELISTED_PRODUCT = typeof UNDO_DELISTED_PRODUCT;

export const ON_HIGHLIGHT_BRAND = 'ON_HIGHLIGHT_BRAND';
export type ON_HIGHLIGHT_BRAND = typeof ON_HIGHLIGHT_BRAND;

export const ON_HIGHLIGHT_PRODUCT = 'ON_HIGHLIGH_PRODUCT';
export type ON_HIGHLIGHT_PRODUCT = typeof ON_HIGHLIGHT_PRODUCT;

export const RECORD_CHANGE_REASON_REQUEST = 'RECORD_CHANGE_REASON_REQUEST';
export type RECORD_CHANGE_REASON_REQUEST = typeof RECORD_CHANGE_REASON_REQUEST;

export const RECORD_CHANGE_REASON_SUCCESS = 'RECORD_CHANGE_REASON_SUCCESS';
export type RECORD_CHANGE_REASON_SUCCESS = typeof RECORD_CHANGE_REASON_SUCCESS;

export const RECORD_CHANGE_REASON_ERROR = 'RECORD_CHANGE_REASON_ERROR';
export type RECORD_CHANGE_REASON_ERROR = typeof RECORD_CHANGE_REASON_ERROR;

export const RESET_PLANOGRAM_REQUEST = 'RESET_PLANOGRAM_REQUEST';
export type RESET_PLANOGRAM_REQUEST = typeof RESET_PLANOGRAM_REQUEST;

export const RESET_PLANOGRAM_SUCCESS = 'RESET_PLANOGRAM_SUCCESS';
export type RESET_PLANOGRAM_SUCCESS = typeof RESET_PLANOGRAM_SUCCESS;

export const RESET_PLANOGRAM_FAILURE = 'RESET_PLANOGRAM_FAILURE';
export type RESET_PLANOGRAM_FAILURE = typeof RESET_PLANOGRAM_FAILURE;

export const TOGGLE_PLANOGRAM_ASIDE_ACTIVE_TAB = 'TOGGLE_PLANOGRAM_ASIDE_ACTIVE_TAB';
export type TOGGLE_PLANOGRAM_ASIDE_ACTIVE_TAB = typeof TOGGLE_PLANOGRAM_ASIDE_ACTIVE_TAB;

export const PLANOGRAM_IN_UNSTABLE_STATE = 'PLANOGRAM_IN_UNSTABLE_STATE';
export type PLANOGRAM_IN_UNSTABLE_STATE = typeof PLANOGRAM_IN_UNSTABLE_STATE;

export const LAYOUT_TOGGLE_ASIDE = 'LAYOUT_TOGGLE_ASIDE';
export type LAYOUT_TOGGLE_ASIDE = typeof LAYOUT_TOGGLE_ASIDE;

export const TOGGLE_ENABLED_GPC_BRICK_FILTER = 'TOGGLE_ENABLED_GPC_BRICK_FILTER';
export type TOGGLE_ENABLED_GPC_BRICK_FILTER = typeof TOGGLE_ENABLED_GPC_BRICK_FILTER;

export const TOGGLE_GPC_BRICK_CODE_FILTER = 'TOGGLE_GPC_BRICK_CODE_FILTER';
export type TOGGLE_GPC_BRICK_CODE_FILTER = typeof TOGGLE_GPC_BRICK_CODE_FILTER;

export const HAS_FINISHED_PRODUCT_TOUR_REQUEST = 'HAS_FINISHED_PRODUCT_TOUR_REQUEST';
export type HAS_FINISHED_PRODUCT_TOUR_REQUEST = typeof HAS_FINISHED_PRODUCT_TOUR_REQUEST;

export const RUN_PRODUCT_TOUR = 'RUN_PRODUCT_TOUR';
export type RUN_PRODUCT_TOUR = typeof RUN_PRODUCT_TOUR;

export const UPDATE_PRODUCT_STORE_STEP_INDEX = 'UPDATE_PRODUCT_STORE_STEP_INDEX';
export type UPDATE_PRODUCT_STORE_STEP_INDEX = typeof UPDATE_PRODUCT_STORE_STEP_INDEX;

export const STOP_PRODUCT_TOUR = 'STOP_PRODUCT_TOUR';
export type STOP_PRODUCT_TOUR = typeof STOP_PRODUCT_TOUR;

export const HAS_FINISHED_PRODUCT_TOUR_SUCCESS = 'HAS_FINISHED_PRODUCT_TOUR_SUCCESS';
export type HAS_FINISHED_PRODUCT_TOUR_SUCCESS = typeof HAS_FINISHED_PRODUCT_TOUR_SUCCESS;

export const TOGGLE_SHOW_COMPETITOR_MODAL = 'TOGGLE_SHOW_COMPETITOR_MODAL';
export type TOGGLE_SHOW_COMPETITOR_MODAL = typeof TOGGLE_SHOW_COMPETITOR_MODAL;

export const ON_AUTORESIZE_FOR_SALES_REQUEST = 'ON_AUTORESIZE_FOR_SALES_REQUEST';
export type ON_AUTORESIZE_FOR_SALES_REQUEST = typeof ON_AUTORESIZE_FOR_SALES_REQUEST;

export const ON_NEW_PRODUCT_IMAGE_UPLOAD_REQUEST = 'ON_NEW_PRODUCT_IMAGE_UPLOAD_REQUEST';
export type ON_NEW_PRODUCT_IMAGE_UPLOAD_REQUEST = typeof ON_NEW_PRODUCT_IMAGE_UPLOAD_REQUEST;

export const ON_NEW_PRODUCT_IMAGE_UPLOAD_SUCCESS = 'ON_NEW_PRODUCT_IMAGE_UPLOAD_SUCCESS';
export type ON_NEW_PRODUCT_IMAGE_UPLOAD_SUCCESS = typeof ON_NEW_PRODUCT_IMAGE_UPLOAD_SUCCESS;

export const ON_NEW_PRODUCT_IMAGE_UPLOAD_ERROR = 'ON_NEW_PRODUCT_IMAGE_UPLOAD_ERROR';
export type ON_NEW_PRODUCT_IMAGE_UPLOAD_ERROR = typeof ON_NEW_PRODUCT_IMAGE_UPLOAD_ERROR;

export const ON_ZOOM_LEVEL_ADJUSTMENT = 'ON_ZOOM_LEVEL_ADJUSTMENT';
export type ON_ZOOM_LEVEL_ADJUSTMENT = 'ON_ZOOM_LEVEL_ADJUSTMENT';

export const ON_COMMAND_EXECUTION_REQUEST = 'ON_COMMAND_EXECUTION_REQUEST';
export type ON_COMMAND_EXECUTION_REQUEST = typeof ON_COMMAND_EXECUTION_REQUEST;

export const ON_SELECT_SHELF_LOCATION_REQUEST = 'ON_SELECT_SHELF_LOCATION_REQUEST';
export type ON_SELECT_SHELF_LOCATION_REQUEST = typeof ON_SELECT_SHELF_LOCATION_REQUEST;

export const ON_START_COPYING_SHELF_LOCATIONS_REQUEST = 'ON_START_COPYING_SHELF_LOCATIONS_REQUEST';
export type ON_START_COPYING_SHELF_LOCATIONS_REQUEST = typeof ON_START_COPYING_SHELF_LOCATIONS_REQUEST;

export const ON_CANCEL_COPYING_SHELF_LOCATIONS_REQUEST = 'ON_CANCEL_COPYING_SHELF_LOCATIONS_REQUEST';
export type ON_CANCEL_COPYING_SHELF_LOCATIONS_REQUEST = typeof ON_CANCEL_COPYING_SHELF_LOCATIONS_REQUEST;

export const ON_CANCEL_ALL_PLANOGRAM_UI_ACTIONS_REQUEST = 'ON_CANCEL_ALL_PLANOGRAM_UI_ACTIONS_REQUEST';
export type ON_CANCEL_ALL_PLANOGRAM_UI_ACTIONS_REQUEST = typeof ON_CANCEL_ALL_PLANOGRAM_UI_ACTIONS_REQUEST;

export const ON_PLANOGRAM_ACTION_UNDO_REQUEST = 'ON_PLANOGRAM_ACTION_UNDO_REQUEST';
export type ON_PLANOGRAM_ACTION_UNDO_REQUEST = typeof ON_PLANOGRAM_ACTION_UNDO_REQUEST;

export const ON_PLANOGRAM_CONTEXT_MENU_OPEN_REQUEST = 'ON_PLANOGRAM_CONTEXT_MENU_OPEN_REQUEST';
export type ON_PLANOGRAM_CONTEXT_MENU_OPEN_REQUEST = typeof ON_PLANOGRAM_CONTEXT_MENU_OPEN_REQUEST;

export const ON_PLANOGRAM_SVGPANZOOM_VALUE_UPDATE_REQUEST = 'ON_PLANOGRAM_SVGPANZOOM_VALUE_UPDATE_REQUEST';
export type ON_PLANOGRAM_SVGPANZOOM_VALUE_UPDATE_REQUEST = typeof ON_PLANOGRAM_SVGPANZOOM_VALUE_UPDATE_REQUEST;
