import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { deleteInspirationImageRequest } from '../../../../../entities/inspirationImages/actions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },

    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -14,
      marginLeft: -14,
    },
  })
);

interface Props {
  planogramId: string;
  inspirationImageId: string;
}

const DeleteBtn: React.FC<Props> = ({ planogramId, inspirationImageId }: Props): JSX.Element => {
  const classes = useStyles();
  const dispacth = useDispatch();
  const [isDeleting, setIsDeleting] = useState(false);

  const resetDeleting = (): void => {
    setIsDeleting(false);
  };
  const handleOnClick = (): void => {
    setIsDeleting(true);
    dispacth(
      deleteInspirationImageRequest(
        {
          planogramId,
          inspirationImageId,
        },
        {
          resetLoading: resetDeleting,
        }
      )
    );
  };

  return (
    <div className={classes.wrapper}>
      <Tooltip title="Delete Image">
        <IconButton data-testid="delete-inspiration-image-btn" disabled={isDeleting} onClick={handleOnClick} color="secondary">
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      {isDeleting && <CircularProgress size={28} className={classes.buttonProgress} />}
    </div>
  );
};

export default DeleteBtn;
