import { Log, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client';
import config from 'config';
import * as constants from '../constants';

// Log.logger = console;
Log.level = Log.INFO;

const settings: UserManagerSettings = {
  authority: config.auth.authority,
  // eslint-disable-next-line @typescript-eslint/camelcase
  client_id: 'planogram.implicit',
  // eslint-disable-next-line @typescript-eslint/camelcase
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}${constants.AUTH_CALLBACK_PATH}`,
  // eslint-disable-next-line @typescript-eslint/camelcase
  post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}${constants.AUTH_LOGOUT_PATH}`,
  // eslint-disable-next-line @typescript-eslint/camelcase
  response_type: 'id_token token',
  scope: 'openid profile role planogram-service',
  userStore: new WebStorageStateStore({ store: localStorage }),
  // automaticSilentRenew: true,
  // silent_redirect_uri: `${rootUrl}/_auth/silent-callback`,
  // accessTokenExpiringNotificationTime: 60,
  // filterProtocolClaims: true,
  // loadUserInfo: false,
};

const userManager = new UserManager(settings);

export default userManager;
