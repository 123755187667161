import React from 'react';
import { RouteProps, useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import HTTPRequestBrand from './components/HTTPRequestBrand';
import FormContainer from './components/FormContainer';
import HTTPRequestShelves from './components/HTTPRequestShelves';
import { Typography } from '@material-ui/core';

const CratePlanogramPage: React.FC<RouteProps> = (): JSX.Element => {
  const { shelfReferenceId } = useParams<{ shelfReferenceId: string }>();
  return (
    <HTTPRequestShelves>
      {({ isLoaded: isLoadedShelves, loadError: loadShelvesError }): React.ReactNode => {
        return (
          <HTTPRequestBrand shelfReferenceId={shelfReferenceId}>
            {({ isLoaded: isLoadedBrands, loadError: loadBrandsError }): React.ReactNode => {
              const isErrorExist = loadBrandsError || loadShelvesError;
              const isLoadedAll = isLoadedBrands && isLoadedShelves;
              return (
                <>
                  {isErrorExist && (
                    <Grid style={{ marginTop: '22%' }} container direction="row" justify="center" alignItems="center">
                      <Typography color="error" variant="overline">
                        Sorry, something went wrong. At the moment we can not load data.
                      </Typography>
                    </Grid>
                  )}

                  {!isLoadedAll && !isErrorExist && (
                    <Grid style={{ marginTop: '22%' }} container direction="row" justify="center" alignItems="center">
                      <CircularProgress /> &nbsp;&nbsp;
                      <Typography variant="overline">Loading brands and shelf data...</Typography>
                    </Grid>
                  )}

                  {isLoadedAll && <FormContainer shelfReferenceId={shelfReferenceId} />}
                </>
              );
            }}
          </HTTPRequestBrand>
        );
      }}
    </HTTPRequestShelves>
  );
};

export default CratePlanogramPage;
