export function svgToUri(svgElement: any) {
  const svgString = new XMLSerializer().serializeToString(svgElement);
  const base64EncodedSvg = window.btoa(svgString);
  const base64EncodedSvgUri = `data:image/svg+xml,${base64EncodedSvg}`;
  return base64EncodedSvgUri;
}

export function removeDomElementsByClassName(domElement: any, className: string) {
  const elementsWithClassName = domElement.getElementsByClassName(className);
  while (elementsWithClassName.length > 0) {
    elementsWithClassName[0].parentNode.removeChild(elementsWithClassName[0]);
  }
}
