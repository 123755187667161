import React, { useState } from 'react';
import { Checkbox, Paper, Table, TableBody, TableHead, Tooltip } from '@material-ui/core';
import { StyledTableCell, StyledTableRow } from '../../../../../../scss/MaterialStyles';
import { Remove, RemoveRedEye, UnfoldLess, UnfoldMore, ZoomOutMap } from '@material-ui/icons';
import { BrandPermissionType } from '../../../../types/BrandPermissionType';

type HandleOnChange = (checkBoxName: string) => (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;

export interface BrandRulesTableProps {
  children: React.ReactNodeArray;
  setAllFieldValues: (fieldName: string, checked: boolean) => void;
}

const BrandRulesTable: React.FC<BrandRulesTableProps> = ({ children, setAllFieldValues }: BrandRulesTableProps): JSX.Element => {
  const [checkBoxes, setCheckBoxes] = useState({
    [BrandPermissionType.canView]: false,
    [BrandPermissionType.canMove]: false,
    [BrandPermissionType.canDelist]: false,
    [BrandPermissionType.canIncreaseSize]: false,
    [BrandPermissionType.canDecreaseSize]: false,
  });

  const handleOnChange: HandleOnChange = checkBoxName => (e, checked): void => {
    setCheckBoxes({
      ...checkBoxes,
      ...{
        [checkBoxName]: checked,
      },
    });
    setAllFieldValues(checkBoxName, checked);
  };

  return (
    <Paper>
      <Table size="small" className="sticky-header-table">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>Brand</StyledTableCell>
            <StyledTableCell align="right">
              <Tooltip title="View" interactive>
                <RemoveRedEye />
              </Tooltip>
              &nbsp; View
              <Checkbox
                data-testid={`setAllFieldValues-${BrandPermissionType.canView}`}
                color="secondary"
                checked={checkBoxes[BrandPermissionType.canView]}
                onChange={handleOnChange(BrandPermissionType.canView)}
                value={BrandPermissionType.canView}
              />
            </StyledTableCell>
            <StyledTableCell align="right">
              <Tooltip title="Move" interactive>
                <ZoomOutMap />
              </Tooltip>
              &nbsp; Move
              <Checkbox
                data-testid={`setAllFieldValues-${BrandPermissionType.canMove}`}
                color="secondary"
                checked={checkBoxes[BrandPermissionType.canMove]}
                onChange={handleOnChange(BrandPermissionType.canMove)}
                value={BrandPermissionType.canMove}
              />
            </StyledTableCell>
            <StyledTableCell align="right">
              <Tooltip title="De-list" interactive>
                <Remove />
              </Tooltip>
              &nbsp; De-list
              <Checkbox
                data-testid={`setAllFieldValues-${BrandPermissionType.canDelist}`}
                color="secondary"
                checked={checkBoxes[BrandPermissionType.canDelist]}
                onChange={handleOnChange(BrandPermissionType.canDelist)}
                value={BrandPermissionType.canDelist}
              />
            </StyledTableCell>
            <StyledTableCell align="right">
              <Tooltip title="Increase share of space" interactive>
                <UnfoldMore />
              </Tooltip>
              &nbsp; Increase share of space
              <Checkbox
                data-testid={`setAllFieldValues-${BrandPermissionType.canIncreaseSize}`}
                color="secondary"
                checked={checkBoxes[BrandPermissionType.canIncreaseSize]}
                onChange={handleOnChange(BrandPermissionType.canIncreaseSize)}
                value={BrandPermissionType.canIncreaseSize}
              />
            </StyledTableCell>
            <StyledTableCell align="right">
              {' '}
              <Tooltip title="Decrease share of space" interactive>
                <UnfoldLess />
              </Tooltip>
              &nbsp; Decrease share of space
              <Checkbox
                data-testid={`setAllFieldValues-${BrandPermissionType.canDecreaseSize}`}
                color="secondary"
                checked={checkBoxes[BrandPermissionType.canDecreaseSize]}
                onChange={handleOnChange(BrandPermissionType.canDecreaseSize)}
                value={BrandPermissionType.canDecreaseSize}
              />
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </Paper>
  );
};

export default BrandRulesTable;
