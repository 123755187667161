import * as React from 'react';
import ShelfLocationModel from '../../../domain/ShelfLocation';
import { buildPath, getGeometryPoints, getShelfLocationPathStyle } from '../helpers';
import ShelfLocationImageComponent from '../ShelfLocationImageComponent';
import ShelfLocationText from './ShelfLocationText';
import ShelfLocationEdges from './ShelfLocationEdges';
import ShelfLocationGeometryPoint from 'modules/planogram/domain/ShelfLocationGeometryPoint';

interface ShelfLocationProps {
  shelfLocation: ShelfLocationModel;
  ratio: number;
  isPrintMode: boolean;
  isSelected: boolean;
  isCopying: boolean;
  onShelfLocationMouseDown(shelfLocation: ShelfLocationModel, evt: React.MouseEvent): void;
  onShelfLocationEdgeMouseDown(shelfLocation: ShelfLocationModel, point: ShelfLocationGeometryPoint): void;
  onShelfLocationContextMenu(element: Element, shelfLocation: ShelfLocationModel): void;
}

const ShelfLocation: React.FC<ShelfLocationProps> = (props: ShelfLocationProps) => {
  const shelfLocation = props.shelfLocation;
  const ratio = props.ratio;
  const pathStyle = getShelfLocationPathStyle(shelfLocation, ratio, props.isSelected, props.isCopying);
  const path = buildPath(getGeometryPoints(shelfLocation));
  return (
    <g
      className="shelflocation"
      onMouseDown={(evt: React.MouseEvent): void => {
        if (evt.button !== 0) {
          // if not left click.
          return;
        }
        evt.preventDefault();
        evt.stopPropagation();
        props.onShelfLocationMouseDown(shelfLocation, evt);
      }}
      onContextMenu={(evt: React.MouseEvent): void => {
        if (evt.ctrlKey) {
          // on macOS, CTRL + left click will invoke the context menu
          // we are preventing that here.
          evt.preventDefault();
          return;
        }
        evt.preventDefault();
        evt.stopPropagation();
        const el = evt.currentTarget;
        const boundingClientRect = el.getBoundingClientRect();
        // creating an artificial element here, in order to preserve the coordinates.
        // otherwise, React might reuse the component and attach different coordinate in the meantime
        // which will effectivly show the context menu on the wrong place.
        const element = {
          clientWidth: el.clientWidth,
          clientHeight: el.clientHeight,
          getBoundingClientRect: (): ClientRect => {
            return boundingClientRect;
          },
        } as Element;
        props.onShelfLocationContextMenu(element, shelfLocation);
      }}
    >
      <ShelfLocationImageComponent shelfLocation={shelfLocation} />
      <path {...pathStyle} d={path} />
      <ShelfLocationText {...props} />
      {props.isSelected && <ShelfLocationEdges onShelfLocationEdgeMouseDown={props.onShelfLocationEdgeMouseDown} shelfLocation={shelfLocation} ratio={ratio} />}
    </g>
  );
};

export default ShelfLocation;
