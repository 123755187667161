import ClientDatasetsPage from 'modules/admin/clientDataset/components/ClientDatasetsPage';
import AdminPlanogramsPage from 'modules/admin/planograms/components/AdminPlanogramsPage';
import UsersPage from 'modules/admin/users/components/UsersPage';
import HomeComponent from 'modules/dashboard/components/HomeComponent';
import PostLogoutComponent from 'modules/dashboard/components/PostLogoutComponent';
import { EulaPage } from 'modules/eula/components/EulaPage';
import CompetitorPlanogramPage from 'modules/planogram/components/CompetitorPlanogramPage';
import RealogramPage from 'modules/realogram/components';
import { ClientBenchmarkReportIndex } from 'modules/reports/standardReports/components/ClientBenchmarkReport/ClientBenchmarkReportIndex';
import { ProjectBenchmarkReportIndex } from 'modules/reports/standardReports/components/ProjectBenchmarkReport/ProjectBenchmarkReportIndex';
import { StandardBrandComparisonReportIndex } from 'modules/reports/standardReports/components/StandardBrandComparisonReport/StandardBrandComparisonReportIndex';
import { StandardBrandReportIndex } from 'modules/reports/standardReports/components/StandardBrandReport/StandardBrandReportIndex';
import { BrandPotentialReportIndex } from 'modules/reports/standardReports/components/BrandPotentialReport/BrandPotentialReportIndex';
import { StandardGpcBrickComparisonReportIndex } from 'modules/reports/standardReports/components/StandardGpcBrickComparisonReport/StandardGpcBrickComparisonReportIndex';
import { StandardGpcBrickReportIndex } from 'modules/reports/standardReports/components/StandardGpcBrickReport/StandardGpcBrickReportIndex';
import { StandardProductComparisonReportIndex } from 'modules/reports/standardReports/components/StandardProductComparisonReport/StandardProductComparisonReportIndex';
import { StandardProductReportIndex } from 'modules/reports/standardReports/components/StandardProductReport/StandardProductReportIndex';
import { StandardProjectComparisonReportIndex } from 'modules/reports/standardReports/components/StandardProjectComparisonReport/StandardProjectComparisonReportIndex';
import { StandardProjectReportIndex } from 'modules/reports/standardReports/components/StandardProjectReport/StandardProjectReportIndex';
import { StandardReportsIndex } from 'modules/reports/standardReports/components/StandardReportsIndex';
import { StandardShopperComparisonReportIndex } from 'modules/reports/standardReports/components/StandardShopperComparisonReport/StandardShopperComparisonReportIndex';
import { StandardShopperReportIndex } from 'modules/reports/standardReports/components/StandardShopperReport/StandardShopperReportIndex';
import { ProductBenchmarkReportIndex } from 'modules/reports/standardReports/components/ProductBenchmarkReport/ProductBenchmarkReportIndex';
import { AggregatedReportIndex } from 'modules/reports/aggregatedReports/components/AggregatedReportIndex';
import ShelfTimelapsePage from 'modules/shelfTimelapse';
import PlanogramPage from '../modules/planogram/components/PlanogramPage';
import PublicPlanogramPage from '../modules/planogram/components/PublicPlanogramPage';
import TestPlanogramPage from '../modules/planogram/components/TestPlanogramPage';
import CreatePlanogramPage from '../modules/planograms/components/CreatePlanogramPage';
import PlanogramListPage from '../modules/planograms/components/PlanogramListPage';
import { StandardShelfComparisonReportIndex } from '../modules/reports/standardReports/components/StandardShelfComparisonReport/StandardShelfComparisonReportIndex';
import { TimelinesPage } from '../modules/timelines/components/TimelinesPage';
import { AggregatedProductReportIndex } from 'modules/reports/aggregatedReports/components/AggregatedProductReport/AggregatedProductReportIndex';
import { AggregatedBrandBrickReportIndex } from 'modules/reports/aggregatedReports/components/AggregatedBrandBrickReport.tsx/AggregatedBrandBrickReportIndex';
import { AggregatedBrandReportIndex } from 'modules/reports/aggregatedReports/components/AggregatedBrandReport/AggregatedBrandReportIndex';
import { AggregatedBrickReportIndex } from 'modules/reports/aggregatedReports/components/AggregatedBrickReport/AggregatedBrickReportIndex';
// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config

const routes = [
  { path: '/', exact: true, name: 'Home', component: HomeComponent },

  { path: '/eula', exact: true, name: 'EULA', component: EulaPage },

  // Planogram Routes
  { path: '/planograms', exact: true, name: 'Planograms', component: PlanogramListPage },

  // Planograms Routes
  {
    path: '/planograms/create/:shelfReferenceId',
    exact: true,
    name: 'CratePlanogramPage',
    component: CreatePlanogramPage,
  },
  { path: '/planograms/:id', exact: true, name: 'Planogram', component: PlanogramPage },
  { path: '/planograms/:id/share/:sharingToken', name: 'PublicPlanogram', component: PublicPlanogramPage },
  { path: '/planograms/:id/test/:testPlanogramId', name: 'TestPlanogram', component: TestPlanogramPage },
  {
    path: '/planograms/:id/competitorShelves/:shelfReferenceId',
    name: 'CompetitorPlanogramPage',
    component: CompetitorPlanogramPage,
  },
  // Reports
  { path: '/reports/standardReports', exact: true, name: 'Standard Reports', component: StandardReportsIndex },
  {
    path: '/reports/standardReports/shopperReports/projects/:shelfPhaseId/shoppers',
    exact: true,
    name: 'Standard Shopper Report Page',
    component: StandardShopperReportIndex,
  },
  {
    path: '/reports/standardReports/projectReports/projects/:projectId/benchmark',
    exact: true,
    name: 'Standard Project Benchmark Report Page',
    component: ProjectBenchmarkReportIndex,
  },
  {
    path: '/reports/standardReports/clientReports/clients/:clientId/benchmark',
    exact: true,
    name: 'Standard Client Benchmark Report Page',
    component: ClientBenchmarkReportIndex,
  },
  {
    path: '/reports/standardReports/projectReport/:shelfPhaseId',
    exact: true,
    name: 'Standard Project Report Page',
    component: StandardProjectReportIndex,
  },

  {
    path: '/reports/standardReports/brandReports/projects/:shelfPhaseId/brands/:brandId',
    exact: true,
    name: 'Standard Brand Report Page',
    component: StandardBrandReportIndex,
  },
  {
    path: '/reports/potentialReports/brandReports/projects/:shelfPhaseId/brands/:brandId',
    exact: true,
    name: 'Potential Brand Report Page',
    component: BrandPotentialReportIndex,
  },
  {
    path: '/reports/standardReports/productReports/projects/:shelfPhaseId/products/:gtin',
    exact: true,
    name: 'Standard Product Report Page',
    component: StandardProductReportIndex,
  },
  {
    path: '/reports/standardReports/gpcBrickReports/projects/:shelfPhaseId/gpcBricks/:gpcBrickCode',
    exact: true,
    name: 'Standard Gpc Brick Report Page',
    component: StandardGpcBrickReportIndex,
  },
  {
    path: '/reports/standardReports/compareReports/shelfComparison/:shelfPhaseId1/:shelfPhaseId2',
    exact: true,
    name: 'Standard Shelf Comparison Report Page',
    component: StandardShelfComparisonReportIndex,
  },
  {
    path: '/reports/standardReports/compareReports/projects/:shelfPhaseId1/:shelfPhaseId2',
    exact: true,
    name: 'Standard Project Comparison Report Page',
    component: StandardProjectComparisonReportIndex,
  },
  {
    path: '/reports/standardReports/compareReports/projects/:shelfPhaseId1/:shelfPhaseId2/shoppers',
    exact: true,
    name: 'Standard Shopper Comparison Report Page',
    component: StandardShopperComparisonReportIndex,
  },
  {
    path: '/reports/standardReports/compareReports/projects/:shelfPhaseId1/:shelfPhaseId2/products/:gtin1/:gtin2',
    exact: true,
    name: 'Standard Products Comparison Report Page',
    component: StandardProductComparisonReportIndex,
  },
  {
    path: '/reports/standardReports/projects/:targetShelfPhaseId/:comparisonShelfPhaseId/products/:targetGtin/benchmark',
    exact: true,
    name: 'Standard Products Benchmark Report Page',
    component: ProductBenchmarkReportIndex,
  },
  {
    path: '/reports/standardReports/compareReports/projects/:shelfPhaseId1/:shelfPhaseId2/brands/:brandId1/:brandId2',
    exact: true,
    name: 'Standard Brand Comparison Report Page',
    component: StandardBrandComparisonReportIndex,
  },
  {
    path: '/reports/standardReports/compareReports/projects/:shelfPhaseId1/:shelfPhaseId2/gpcBricks/:gpcBrickCode1/:gpcBrickCode2',
    exact: true,
    name: 'Standard Gpc Brick Comparison Report Page',
    component: StandardGpcBrickComparisonReportIndex,
  },
  { path: '/reports/standardReports/:selectedProjectId?/:selectedStoreId?/:selectedShelfNum?/:selectedPhaseNum?', exact: true, name: 'Standard Reports', component: StandardReportsIndex },

  { path: '/reports/aggregatedReports', exact: true, name: 'Aggregated Reports', component: AggregatedReportIndex },

  { path: '/reports/aggregatedReports/product/:gtin/:metrics?', exact: true, name: 'Aggregated Product Report', component: AggregatedProductReportIndex },
  { path: '/reports/aggregatedReports/brand/:brandId/:metrics?', exact: true, name: 'Aggregated Brand Report', component: AggregatedBrandReportIndex },
  { path: '/reports/aggregatedReports/brick/:gpcBrickCode/:metrics?', exact: true, name: 'Aggregated Brick Report', component: AggregatedBrickReportIndex },
  { path: '/reports/aggregatedReports/brand/:brandId/brick/:gpcBrickCode/:metrics?', exact: true, name: 'Aggregated Brand Brick Report', component: AggregatedBrandBrickReportIndex },

  // Realogram routes
  {
    path: '/realograms/:shelfReferenceId',
    exact: true,
    name: 'Realogram',
    component: RealogramPage,
  },
  // Admin Routes
  { path: '/admin/users', name: 'AdminUsers', component: UsersPage },
  { path: '/admin/planograms', name: 'AdminPlanograms', component: AdminPlanogramsPage },
  { path: '/admin/client-datasets', name: 'AdminClientDatasets', component: ClientDatasetsPage },

  // Auth Routes
  { path: '/_auth/post-logout', exact: true, name: 'PostLogout', component: PostLogoutComponent },

  // Timeline Routes
  { path: '/timeline', exact: true, name: 'Timelines', component: TimelinesPage },

  // Timelapse
  { path: '/shelves/:shelfReferenceId/timelapse', exact: true, name: 'Shelf Timelapse', component: ShelfTimelapsePage },
];

export default routes;
