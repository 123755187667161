import * as React from 'react';
import { Card, CardContent, Typography, Divider, Grid, Switch } from '@material-ui/core';
import { Settings as SettingsIcon } from '@material-ui/icons';
export default class PublicSettings extends React.Component<Props & DispatchProps> {
  render() {
    return (
      <React.Fragment>
        <Card raised>
          <CardContent style={{ padding: '8px' }}>
            <Typography color="primary" variant="subtitle2">
              <SettingsIcon fontSize="small" />
              &nbsp; Settings
            </Typography>
            <Divider className="top-buffer bottom-buffer" light={true} variant="fullWidth" />
            <Grid direction="row" container justify="space-between" alignItems="center">
              <Typography variant="caption">Show floating product info</Typography>
              <Switch style={{ padding: '8px' }} color="primary" size="medium" checked={this.props.enableShowProductInfo} onChange={() => this.props.toggleEnableShowProductInfo()} />
            </Grid>
          </CardContent>
        </Card>
      </React.Fragment>
    );
  }
}

interface DispatchProps {
  toggleEnableShowProductInfo(enabled?: boolean): void;

  onShowSharing(): void;
}

interface Props {
  enableShowProductInfo: boolean;
}
