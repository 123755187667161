import { isLoaded, isLoading } from 'infrastructure/utils/RemoteObjectStatus';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { loadStandardGpcBrickReportRequestAction } from '../../actions';
import * as selectors from '../../selectors';
import { GpcBrickReportResult } from '../../types';
interface LoadStatusParams {
  isLoaded: boolean;
  isLoading: boolean;
  loadError: string;
  isDataEmpty: boolean;
  data: GpcBrickReportResult | undefined;
}
export interface StandardGpcBrickReportLoaderProps {
  children: (params: LoadStatusParams) => React.ReactNode;
}

const StandardGpcBrickReportLoader: React.FC<StandardGpcBrickReportLoaderProps> = ({ children }: StandardGpcBrickReportLoaderProps) => {
  type RequestParamsType = {
    shelfPhaseId: string;
    gpcBrickCode: string;
  };

  const { shelfPhaseId, gpcBrickCode } = useParams<RequestParamsType>();
  const dispatch = useDispatch();
  const loadState = useSelector(selectors.getGpcBrickReportLoadState);
  const isDataEmpty = useSelector(selectors.isGpcBrickReportEmpty);
  const data = useSelector(selectors.getGpcBrickReportData);
  useEffect(() => {
    if (isDataEmpty && shelfPhaseId && gpcBrickCode !== undefined) {
      dispatch(loadStandardGpcBrickReportRequestAction(shelfPhaseId, parseInt(gpcBrickCode)));
    }
  }, [dispatch, isDataEmpty, shelfPhaseId, gpcBrickCode]);
  return (
    <>
      {children({
        isLoaded: isLoaded(loadState),
        isLoading: isLoading(loadState),
        loadError: loadState?.errorDetails?.errorMessage ?? '',
        isDataEmpty,
        data,
      })}
    </>
  );
};

export { StandardGpcBrickReportLoader };
