import { Type } from 'class-transformer';
import { Guid } from 'guid-typescript';
import { BrandPermissionType } from 'modules/planograms/types/BrandPermissionType';
import { ActionRules } from '../../planograms/types/ActionRules';
import ShelfLocationValidStateDescriptor from './ShelfLocationValidStateDescriptor';
import { ShelfLocationGeometry } from './ShelfLocationGeometry';

export default class ShelfLocation {
  id = '';
  code = '';
  gtin = 0;
  consumerPriceLocalCurrency = 0;
  shelfFacings = 0;

  @Type(() => ShelfLocationGeometry)
  geometry: ShelfLocationGeometry = new ShelfLocationGeometry();

  imageUrl = '';

  @Type(() => Date)
  lastChangedOn: Date = new Date();

  isCreating = false;
  isHighlighted = false;

  validStateDescriptor: ShelfLocationValidStateDescriptor = new ShelfLocationValidStateDescriptor();

  gridPosition = 0;
  color: string | null = null;
  displayText: string | null = null;
  actionsAllowed: { [action: string]: boolean } = {};

  constructor() {
    this.id = Guid.create().toString();
    this.lastChangedOn = new Date();
    this.setDefaultActionRules();
  }

  canMove(): boolean {
    return this.actionsAllowed[BrandPermissionType.canMove];
  }
  setDefaultActionRules(): void {
    this.actionsAllowed[BrandPermissionType.canView] = true;
    this.actionsAllowed[BrandPermissionType.canMove] = true;
    this.actionsAllowed[BrandPermissionType.canDelist] = true;
    this.actionsAllowed[BrandPermissionType.canIncreaseSize] = true;
    this.actionsAllowed[BrandPermissionType.canDecreaseSize] = true;
  }
  computeActionsAllowed(brandId: number, actionRules: ActionRules): void {
    const brandRule = actionRules.brandRules.find(br => br.brandId === brandId);
    if (brandRule !== undefined) {
      this.actionsAllowed = brandRule.actionsAllowed;
    }
  }

  getCurrentShelfFacings(): number {
    if (this.shelfFacings <= 0) {
      return 0;
    }
    const oneFacingWidth = this.geometry.originalWidth / this.shelfFacings;
    if (oneFacingWidth <= 0 || this.geometry.currentWidth <= 0) {
      return 0;
    }
    const currentFacings = this.geometry.currentWidth / oneFacingWidth;
    return Math.round(currentFacings);
  }
}
