import createReducer from 'utils/createReducer';
import ErrorDetails from '../../../infrastructure/exception/ErrorDetails';
import * as constants from './constants';
import AdminClientDatasetState from './state';
import { ClientDatasetDto } from './types/ClientDatasetDto';
import { ClientDto } from './types/ClientDto';
import { setError, setIdle, setLoading, setSuccess, setData } from 'infrastructure/utils/RemoteObjectStatus';
import { Action } from 'types/Action';

const initialState: AdminClientDatasetState = {
  // Get Clients
  loadClientsStatus: setIdle<ClientDto[]>(),
  // Get Client Datasets
  loadDataSetsStatus: setIdle(),

  // Upload Client Datasets
  uploadClientDataSetStatus: setIdle(),

  // Delete Client dataset
  deleteClientDataSetStatus: setIdle(),
};

function loadClientsRequestHandler(state: AdminClientDatasetState): AdminClientDatasetState {
  return {
    ...state,
    loadClientsStatus: setLoading('Loading clients...'),
  };
}

function loadClientsSuccessHandler(state: AdminClientDatasetState, action: Action): AdminClientDatasetState {
  return {
    ...state,
    loadClientsStatus: setSuccess(action.clients as ClientDto[]),
  };
}

function loadClientsFailureHandler(state: AdminClientDatasetState, action: any): AdminClientDatasetState {
  return {
    ...state,
    loadClientsStatus: setError(action.errorDetails as ErrorDetails),
  };
}

function loadClientDatasetsRequestHandler(state: AdminClientDatasetState): AdminClientDatasetState {
  return {
    ...state,
    loadDataSetsStatus: setLoading<ClientDatasetDto[]>('Loading clients data...'),
  };
}

function loadClientDatasetsSuccessHandler(state: AdminClientDatasetState, action: any): AdminClientDatasetState {
  return {
    ...state,
    loadDataSetsStatus: setSuccess(action.clientDatasets as ClientDatasetDto[]),
  };
}

function loadClientDatasetsFailureHandler(state: AdminClientDatasetState, action: any): AdminClientDatasetState {
  return {
    ...state,
    loadClientsStatus: setError(action.errorDetails as ErrorDetails),
  };
}

function uploadClientDatasetRequestHandler(state: AdminClientDatasetState): AdminClientDatasetState {
  return {
    ...state,
    uploadClientDataSetStatus: setLoading('Saving client data...'),
  };
}

function uploadClientDatasetSuccessHandler(state: AdminClientDatasetState, action: any): AdminClientDatasetState {
  const clientDataset = action.response as ClientDatasetDto;
  const clientDatasets = state.loadDataSetsStatus.data ?? [];
  clientDatasets.push(clientDataset);

  return {
    ...state,
    uploadClientDataSetStatus: setSuccess({}),
    loadClientsStatus: setSuccess(clientDatasets),
  };
}

function uploadClientDatasetFailureHandler(state: AdminClientDatasetState, action: any): AdminClientDatasetState {
  return {
    ...state,
    uploadClientDataSetStatus: setError(action.errorDetails as ErrorDetails),
  };
}

function deleteClientDatasetRequestHandler(state: AdminClientDatasetState): AdminClientDatasetState {
  return {
    ...state,
    deleteClientDataSetStatus: setLoading('Deleting client data...'),
  };
}

function deleteClientDatasetSuccessHandler(state: AdminClientDatasetState, action: any): AdminClientDatasetState {
  const deletedId = action.datasetId as number;
  const clientDatasets = state.loadDataSetsStatus.data?.filter(d => d.id !== deletedId);
  return {
    ...state,
    deleteClientDataSetStatus: setSuccess({}),
    loadDataSetsStatus: setData(state.loadDataSetsStatus, clientDatasets ?? []),
  };
}

function deleteClientDatasetFailureHandler(state: AdminClientDatasetState, action: any): AdminClientDatasetState {
  return {
    ...state,
    deleteClientDataSetStatus: setError(action.errorDetails as ErrorDetails),
  };
}

const handlers = {};

handlers[constants.LOAD_CLIENTS_REQUEST] = loadClientsRequestHandler;
handlers[constants.LOAD_CLIENTS_SUCCESS] = loadClientsSuccessHandler;
handlers[constants.LOAD_CLIENTS_FAILURE] = loadClientsFailureHandler;

handlers[constants.LOAD_CLIENT_DATASETS_REQUEST] = loadClientDatasetsRequestHandler;
handlers[constants.LOAD_CLIENT_DATASETS_SUCCESS] = loadClientDatasetsSuccessHandler;
handlers[constants.LOAD_CLIENT_DATASETS_FAILURE] = loadClientDatasetsFailureHandler;

handlers[constants.UPLOAD_CLIENT_DATASET_REQUEST] = uploadClientDatasetRequestHandler;
handlers[constants.UPLOAD_CLIENT_DATASET_SUCCESS] = uploadClientDatasetSuccessHandler;
handlers[constants.UPLOAD_CLIENT_DATASET_FAILURE] = uploadClientDatasetFailureHandler;

handlers[constants.DELETE_CLIENT_DATASET_REQUEST] = deleteClientDatasetRequestHandler;
handlers[constants.DELETE_CLIENT_DATASET_SUCCESS] = deleteClientDatasetSuccessHandler;
handlers[constants.DELETE_CLIENT_DATASET_FAILURE] = deleteClientDatasetFailureHandler;

const adminClientDatasetReducer = createReducer(initialState, handlers);

export default adminClientDatasetReducer;
