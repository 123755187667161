import ShelfLocation from '../domain/ShelfLocation';
import PlanogramModel from '../domain/PlanogramModel';
import { PlanogramUndoableCommand } from './_planogram_commands';
import PlanogramEventBase from '../events/PlanogramEventBase';

export default class InsertCommand extends PlanogramUndoableCommand {
  private planogram: PlanogramModel;
  private shelfLocationsToInsert: ShelfLocation[];

  constructor(planogram: PlanogramModel, shelfLocationsToInsert: ShelfLocation[]) {
    super();
    this.planogram = planogram;
    this.shelfLocationsToInsert = shelfLocationsToInsert;
  }

  execute(): PlanogramEventBase {
    return this.planogram.addShelfLocations(this.shelfLocationsToInsert);
  }

  undo(): PlanogramEventBase {
    return this.planogram.deleteShelfLocations(this.shelfLocationsToInsert);
  }
}
