import Select from 'react-select';
import React from 'react';
import { BrandOption } from 'modules/reports/types';

export interface BrandSelectorProps {
  htmlId: string;
  brandOptions: BrandOption[];
  selectedBrand?: BrandOption;
  handleOnSelectBrand(selectedBrand: BrandOption): void;
}

export const BrandSelector: React.FC<BrandSelectorProps> = ({ htmlId, brandOptions, selectedBrand, handleOnSelectBrand }: BrandSelectorProps) => {
  const defaultBrandOption: BrandOption = {
    label: 'Select Brand',
    brandId: -1,
    brandName: '',
    value: -1,
  };
  return (
    <Select
      styles={{
        // Fixes the overlapping problem of the component
        menu: provided => ({ ...provided, zIndex: 9999 }),
      }}
      inputId={htmlId}
      placeholder="Select Brand"
      options={brandOptions}
      value={selectedBrand === undefined ? defaultBrandOption : selectedBrand}
      onChange={(brand: BrandOption): void => handleOnSelectBrand(brand)}
      isMulti={false}
      isDisabled={brandOptions.length === 0}
    />
  );
};
