import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';

const useStyles = makeStyles(() =>
  createStyles({
    input: {
      display: 'none',
    },
  })
);

export interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SelectImagesBtn: React.FC<Props> = ({ onChange }: Props): JSX.Element => {
  const classes = useStyles();
  return (
    <>
      <input data-testid="select-inspiration-images-input" onChange={onChange} accept="image/*" className={classes.input} id="contained-button-file" multiple type="file" value="" />
      <label htmlFor="contained-button-file">
        <Button startIcon={<Add />} color="primary" variant="contained" component="span">
          Select Images
        </Button>
      </label>
    </>
  );
};

export default SelectImagesBtn;
