import ErrorDetails from 'infrastructure/exception/ErrorDetails';
import { setError, setIdle, setSuccess, setLoading } from 'infrastructure/utils/RemoteObjectStatus';
import AggregatedReportsState from './state';
import { AggregatedOnePagerReport, AvailableAggregatedReports } from './types';

import * as actions from './actions';
import { ActionType, Reducer } from 'typesafe-actions';

type Actions = ActionType<typeof actions>;

export const INITIAL_STATE: AggregatedReportsState = {
  availableReports: setIdle<AvailableAggregatedReports>(),
  aggregatedProductReport: setIdle<AggregatedOnePagerReport>(),
  aggregatedBrandReport: setIdle<AggregatedOnePagerReport>(),
  aggregatedBrickReport: setIdle<AggregatedOnePagerReport>(),
  aggregatedBrandBrickReport: setIdle<AggregatedOnePagerReport>(),
};

function loadAvailableAggregatedReportsRequestHandler(state: AggregatedReportsState): AggregatedReportsState {
  return { ...state, availableReports: setLoading() };
}

function loadAvailableAggregatedReportsSuccessHandler(state: AggregatedReportsState, data: AvailableAggregatedReports): AggregatedReportsState {
  return { ...state, availableReports: setSuccess(data) };
}

function loadAvailableAggregatedReportsFailureHandler(state: AggregatedReportsState, error: ErrorDetails): AggregatedReportsState {
  return { ...state, availableReports: setError(error) };
}

function loadAggregatedProductReportRequestHandler(state: AggregatedReportsState): AggregatedReportsState {
  return { ...state, aggregatedProductReport: setLoading() };
}

function loadAggregatedProductReportSuccessHandler(state: AggregatedReportsState, data: AggregatedOnePagerReport): AggregatedReportsState {
  return { ...state, aggregatedProductReport: setSuccess(data) };
}

function loadAggregatedProductReportFailureHandler(state: AggregatedReportsState, error: ErrorDetails): AggregatedReportsState {
  return { ...state, aggregatedProductReport: setError(error) };
}

function loadAggregatedBrandReportRequestHandler(state: AggregatedReportsState): AggregatedReportsState {
  return { ...state, aggregatedBrandReport: setLoading() };
}

function loadAggregatedBrandReportSuccessHandler(state: AggregatedReportsState, data: AggregatedOnePagerReport): AggregatedReportsState {
  return { ...state, aggregatedBrandReport: setSuccess(data) };
}

function loadAggregatedBrandReportFailureHandler(state: AggregatedReportsState, error: ErrorDetails): AggregatedReportsState {
  return { ...state, aggregatedBrandReport: setError(error) };
}

function loadAggregatedBrickReportRequestHandler(state: AggregatedReportsState): AggregatedReportsState {
  return { ...state, aggregatedBrickReport: setLoading() };
}

function loadAggregatedBrickReportSuccessHandler(state: AggregatedReportsState, data: AggregatedOnePagerReport): AggregatedReportsState {
  return { ...state, aggregatedBrickReport: setSuccess(data) };
}

function loadAggregatedBrickReportFailureHandler(state: AggregatedReportsState, error: ErrorDetails): AggregatedReportsState {
  return { ...state, aggregatedBrickReport: setError(error) };
}

function loadAggregatedBrandBrickReportRequestHandler(state: AggregatedReportsState): AggregatedReportsState {
  return { ...state, aggregatedBrandBrickReport: setLoading() };
}

function loadAggregatedBrandBrickReportSuccessHandler(state: AggregatedReportsState, data: AggregatedOnePagerReport): AggregatedReportsState {
  return { ...state, aggregatedBrandBrickReport: setSuccess(data) };
}

function loadAggregatedBrandBrickReportFailureHandler(state: AggregatedReportsState, error: ErrorDetails): AggregatedReportsState {
  return { ...state, aggregatedBrandBrickReport: setError(error) };
}

const reducer: Reducer<AggregatedReportsState, Actions> = (state: AggregatedReportsState = INITIAL_STATE, action: Actions) => {
  switch (action.type) {
    case 'AGGREGATED_REPORTS_LOAD_AVAILABLE_REPORTS_REQUEST':
      return loadAvailableAggregatedReportsRequestHandler(state);
    case 'AGGREGATED_REPORTS_LOAD_AVAILABLE_REPORTS_SUCCESS':
      return loadAvailableAggregatedReportsSuccessHandler(state, action.data);
    case 'AGGREGATED_REPORTS_LOAD_AVAILABLE_REPORTS_FAILURE':
      return loadAvailableAggregatedReportsFailureHandler(state, action.error);

    case 'AGGREGATED_REPORTS_LOAD_PRODUCT_REPORT_REQUEST':
      return loadAggregatedProductReportRequestHandler(state);
    case 'AGGREGATED_REPORTS_LOAD_PRODUCT_REPORT_SUCCESS':
      return loadAggregatedProductReportSuccessHandler(state, action.data);
    case 'AGGREGATED_REPORTS_LOAD_PRODUCT_REPORT_FAILURE':
      return loadAggregatedProductReportFailureHandler(state, action.error);

    case 'AGGREGATED_REPORTS_LOAD_BRAND_REPORT_REQUEST':
      return loadAggregatedBrandReportRequestHandler(state);
    case 'AGGREGATED_REPORTS_LOAD_BRAND_REPORT_SUCCESS':
      return loadAggregatedBrandReportSuccessHandler(state, action.data);
    case 'AGGREGATED_REPORTS_LOAD_BRAND_REPORT_FAILURE':
      return loadAggregatedBrandReportFailureHandler(state, action.error);

    case 'AGGREGATED_REPORTS_LOAD_BRICK_REPORT_REQUEST':
      return loadAggregatedBrickReportRequestHandler(state);
    case 'AGGREGATED_REPORTS_LOAD_BRICK_REPORT_SUCCESS':
      return loadAggregatedBrickReportSuccessHandler(state, action.data);
    case 'AGGREGATED_REPORTS_LOAD_BRICK_REPORT_FAILURE':
      return loadAggregatedBrickReportFailureHandler(state, action.error);

    case 'AGGREGATED_REPORTS_LOAD_BRAND_BRICK_REPORT_REQUEST':
      return loadAggregatedBrandBrickReportRequestHandler(state);
    case 'AGGREGATED_REPORTS_LOAD_BRAND_BRICK_REPORT_SUCCESS':
      return loadAggregatedBrandBrickReportSuccessHandler(state, action.data);
    case 'AGGREGATED_REPORTS_LOAD_BRAND_BRICK_REPORT_FAILURE':
      return loadAggregatedBrandBrickReportFailureHandler(state, action.error);
    default:
      return state;
  }
};

export default reducer;
