import ErrorDetails from 'infrastructure/exception/ErrorDetails';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import apiSaga from 'utils/apiSaga';
import {
  loadPhaseShelvesFailureAction,
  loadPhaseShelvesSuccessAction,
  loadStandardBrandComparisonReportFailureAction,
  loadStandardBrandComparisonReportSuccessAction,
  loadStandardBrandReportFailureAction,
  loadStandardBrandReportSuccessAction,
  loadStandardClientBenchmarkReportFailureAction,
  loadStandardClientBenchmarkReportSuccessAction,
  loadStandardGpcBrickComparisonReportFailureAction,
  loadStandardGpcBrickComparisonReportSuccessAction,
  loadStandardGpcBrickReportFailureAction,
  loadStandardGpcBrickReportSuccessAction,
  loadStandardProductComparisonReportFailureAction,
  loadStandardProductComparisonReportSuccessAction,
  loadStandardProductReportFailureAction,
  loadStandardProductReportSuccessAction,
  loadStandardProjectBenchmarkReportFailureAction,
  loadStandardProjectBenchmarkReportSuccessAction,
  loadStandardProjectComparisonReportFailureAction,
  loadStandardProjectComparisonReportSuccessAction,
  loadStandardProjectReportFailureAction,
  loadStandardProjectReportSuccessAction,
  loadStandardShelfComparisonReportFailureAction,
  loadStandardShelfComparisonReportSuccessAction,
  loadStandardShopperComparisonReportFailureAction,
  loadStandardShopperComparisonReportSuccessAction,
  loadStandardShopperReportFailureAction,
  loadStandardShopperReportSuccessAction,
  loadPotentialBrandReportSuccessAction,
  loadPotentialBrandReportFailureAction,
  loadStandardProductBenchmarkReportSuccessAction,
  loadStandardProductBenchmarkReportFailureAction,
} from './actions';
import {
  LoadStandardBrandComparisonReportRequestActionType,
  LoadStandardBrandReportRequestActionType,
  LoadStandardClientBenchmarkReportRequestActionType,
  LoadStandardGpcBrickComparisonReportRequestActionType,
  LoadStandardGpcBrickReportRequestActionType,
  LoadStandardProductComparisonReportRequestActionType,
  LoadStandardProductReportRequestActionType,
  LoadStandardProjectBenchmarkReportRequestActionType,
  LoadStandardProjectComparisonReportRequestActionType,
  LoadStandardProjectReportRequestActionType,
  LoadStandardShelfComparisonReportRequestActionType,
  LoadStandardShopperComparisonReportRequestActionType,
  LoadStandardShopperReportRequestActionType,
  LoadPotentialBrandReportRequestActionType,
  LoadStandardProductBenchmarkReportRequestActionType,
} from './actionTypes';
import {
  getBrandComparisonReport,
  getBrandReport,
  getClientBenchmarkReport,
  getGpcBrickComparisonReport,
  getGpcBrickReport,
  getPhaseShelvesRequest,
  getProductComparisonReport,
  getProductReport,
  getProjectBenchmarkReport,
  getProjectComparisonReport,
  getProjectReport,
  getShelfComparisonReport,
  getShopperComparisonReport,
  getShopperReport,
  getBrandPotentialReport,
  getProductBenchmarkReport,
} from './apiRequests';

export function* fetchPhaseShelves(): SagaIterator {
  try {
    const response = yield call(apiSaga, getPhaseShelvesRequest);
    yield put(loadPhaseShelvesSuccessAction(response.data));
  } catch (e) {
    const error = e.response.data as ErrorDetails;
    yield put(loadPhaseShelvesFailureAction(error));
  }
}

export function* fetchProjectReport(action: LoadStandardProjectReportRequestActionType): SagaIterator {
  try {
    const response = yield call(apiSaga, getProjectReport, action.shelfPhaseId);
    yield put(loadStandardProjectReportSuccessAction(action.shelfPhaseId, response.data));
  } catch (e) {
    const error = e.response.data as ErrorDetails;
    yield put(loadStandardProjectReportFailureAction(action.shelfPhaseId, error));
  }
}

export function* fetchShopperReport(action: LoadStandardShopperReportRequestActionType): SagaIterator {
  try {
    const response = yield call(apiSaga, getShopperReport, action.shelfPhaseId);
    yield put(loadStandardShopperReportSuccessAction(action.shelfPhaseId, response.data));
  } catch (e) {
    const error = e.response.data as ErrorDetails;
    yield put(loadStandardShopperReportFailureAction(action.shelfPhaseId, error));
  }
}

export function* fetchBrandReport(action: LoadStandardBrandReportRequestActionType): SagaIterator {
  try {
    const response = yield call(apiSaga, getBrandReport, action.shelfPhaseId, action.brandId);
    yield put(loadStandardBrandReportSuccessAction(action.shelfPhaseId, action.brandId, response.data));
  } catch (e) {
    const error = e.response.data as ErrorDetails;
    yield put(loadStandardBrandReportFailureAction(action.shelfPhaseId, action.brandId, error));
  }
}

export function* fetchProductReport(action: LoadStandardProductReportRequestActionType): SagaIterator {
  try {
    const response = yield call(apiSaga, getProductReport, action.shelfPhaseId, action.gtin);
    yield put(loadStandardProductReportSuccessAction(action.shelfPhaseId, action.gtin, response.data));
  } catch (e) {
    const error = e.response.data as ErrorDetails;
    yield put(loadStandardProductReportFailureAction(action.shelfPhaseId, action.gtin, error));
  }
}

export function* fetchGpcBrickReport(action: LoadStandardGpcBrickReportRequestActionType): SagaIterator {
  try {
    const response = yield call(apiSaga, getGpcBrickReport, action.shelfPhaseId, action.gpcBrickCode);
    yield put(loadStandardGpcBrickReportSuccessAction(action.shelfPhaseId, action.gpcBrickCode, response.data));
  } catch (e) {
    const error = e.response.data as ErrorDetails;
    yield put(loadStandardGpcBrickReportFailureAction(action.shelfPhaseId, action.gpcBrickCode, error));
  }
}

export function* fetchGpcBrickComparisonReport(action: LoadStandardGpcBrickComparisonReportRequestActionType): SagaIterator {
  try {
    const response = yield call(apiSaga, getGpcBrickComparisonReport, action.shelfPhaseId1, action.shelfPhaseId2, action.gpcBrickCode1, action.gpcBrickCode2);
    yield put(loadStandardGpcBrickComparisonReportSuccessAction(response.data));
  } catch (e) {
    const error = e.response.data as ErrorDetails;
    yield put(loadStandardGpcBrickComparisonReportFailureAction(error));
  }
}
export function* fetchShelfComparisonReport(action: LoadStandardShelfComparisonReportRequestActionType): SagaIterator {
  try {
    const response = yield call(apiSaga, getShelfComparisonReport, action.shelfPhaseId1, action.shelfPhaseId2);
    yield put(loadStandardShelfComparisonReportSuccessAction(response.data));
  } catch (e) {
    const error = e.response.data as ErrorDetails;
    yield put(loadStandardShelfComparisonReportFailureAction(error));
  }
}

export function* fetchProjectComparisonReport(action: LoadStandardProjectComparisonReportRequestActionType): SagaIterator {
  try {
    const response = yield call(apiSaga, getProjectComparisonReport, action.shelfPhaseId1, action.shelfPhaseId2);
    yield put(loadStandardProjectComparisonReportSuccessAction(response.data));
  } catch (e) {
    const error = e.response.data as ErrorDetails;
    yield put(loadStandardProjectComparisonReportFailureAction(error));
  }
}
export function* fetchShopperComparisonReport(action: LoadStandardShopperComparisonReportRequestActionType): SagaIterator {
  try {
    const response = yield call(apiSaga, getShopperComparisonReport, action.shelfPhaseId1, action.shelfPhaseId2);
    yield put(loadStandardShopperComparisonReportSuccessAction(response.data));
  } catch (e) {
    const error = e.response.data as ErrorDetails;
    yield put(loadStandardShopperComparisonReportFailureAction(error));
  }
}
export function* fetchProductComparisonReport(action: LoadStandardProductComparisonReportRequestActionType): SagaIterator {
  try {
    const response = yield call(apiSaga, getProductComparisonReport, action.shelfPhaseId1, action.shelfPhaseId2, action.gtin1, action.gtin2);
    yield put(loadStandardProductComparisonReportSuccessAction(response.data));
  } catch (e) {
    const error = e.response.data as ErrorDetails;
    yield put(loadStandardProductComparisonReportFailureAction(error));
  }
}
export function* fetchBrandComparisonReport(action: LoadStandardBrandComparisonReportRequestActionType): SagaIterator {
  try {
    const response = yield call(apiSaga, getBrandComparisonReport, action.shelfPhaseId1, action.shelfPhaseId2, action.brandId1, action.brandId2);
    yield put(loadStandardBrandComparisonReportSuccessAction(response.data));
  } catch (e) {
    const error = e.response.data as ErrorDetails;
    yield put(loadStandardBrandComparisonReportFailureAction(error));
  }
}
export function* fetchProjectBenchmarkReport(action: LoadStandardProjectBenchmarkReportRequestActionType): SagaIterator {
  try {
    const response = yield call(apiSaga, getProjectBenchmarkReport, action.projectId);
    yield put(loadStandardProjectBenchmarkReportSuccessAction(action.projectId, response.data));
  } catch (e) {
    const error = e.response.data as ErrorDetails;
    yield put(loadStandardProjectBenchmarkReportFailureAction(action.projectId, error));
  }
}
export function* fetchClientBenchmarkReport(action: LoadStandardClientBenchmarkReportRequestActionType): SagaIterator {
  try {
    const response = yield call(apiSaga, getClientBenchmarkReport, action.clientId);
    yield put(loadStandardClientBenchmarkReportSuccessAction(action.clientId, response.data));
  } catch (e) {
    const error = e.response.data as ErrorDetails;
    yield put(loadStandardClientBenchmarkReportFailureAction(action.clientId, error));
  }
}

export function* fetchBrandPotentialReport(action: LoadPotentialBrandReportRequestActionType): SagaIterator {
  try {
    const response = yield call(apiSaga, getBrandPotentialReport, action.shelfPhaseId, action.brandId);
    yield put(loadPotentialBrandReportSuccessAction(action.shelfPhaseId, action.brandId, response.data));
  } catch (e) {
    const error = e.response.data as ErrorDetails;
    yield put(loadPotentialBrandReportFailureAction(action.shelfPhaseId, action.brandId, error));
  }
}

export function* fetchProductBenchmarkReport(action: LoadStandardProductBenchmarkReportRequestActionType): SagaIterator {
  try {
    const response = yield call(apiSaga, getProductBenchmarkReport, action.targetShelfPhaseId, action.comparisonShelfPhaseId, action.targetGtin);
    yield put(loadStandardProductBenchmarkReportSuccessAction(action.targetShelfPhaseId, action.comparisonShelfPhaseId, action.targetGtin, response.data));
  } catch (e) {
    const error = e.response.data as ErrorDetails;
    yield put(loadStandardProductBenchmarkReportFailureAction(action.targetShelfPhaseId, action.comparisonShelfPhaseId, action.targetGtin, error));
  }
}
export default function* watchStandardReportsSaga(): SagaIterator {
  yield takeLatest('STANDARD_REPORTS_LOAD_PHASE_SHELVES_REQUEST', fetchPhaseShelves);
  yield takeLatest('STANDARD_REPORTS_LOAD_PROJECT_REPORT_REQUEST', fetchProjectReport);
  yield takeLatest('STANDARD_REPORTS_LOAD_SHOPPER_REPORT_REQUEST', fetchShopperReport);
  yield takeLatest('STANDARD_REPORTS_LOAD_BRAND_REPORT_REQUEST', fetchBrandReport);
  yield takeLatest('STANDARD_REPORTS_LOAD_PRODUCT_REPORT_REQUEST', fetchProductReport);
  yield takeLatest('STANDARD_REPORTS_LOAD_GPC_BRICK_REPORT_REQUEST', fetchGpcBrickReport);
  yield takeLatest('STANDARD_REPORTS_LOAD_SHELF_COMPARISON_REPORT_REQUEST', fetchShelfComparisonReport);
  yield takeLatest('STANDARD_REPORTS_LOAD_PROJECT_COMPARISON_REPORT_REQUEST', fetchProjectComparisonReport);
  yield takeLatest('STANDARD_REPORTS_LOAD_SHOPPER_COMPARISON_REPORT_REQUEST', fetchShopperComparisonReport);
  yield takeLatest('STANDARD_REPORTS_LOAD_PRODUCT_COMPARISON_REPORT_REQUEST', fetchProductComparisonReport);
  yield takeLatest('STANDARD_REPORTS_LOAD_BRAND_COMPARISON_REPORT_REQUEST', fetchBrandComparisonReport);
  yield takeLatest('STANDARD_REPORTS_LOAD_GPC_BRICK_COMPARISON_REPORT_REQUEST', fetchGpcBrickComparisonReport);
  yield takeLatest('STANDARD_REPORTS_LOAD_PROJECT_BENCHMARK_REPORT_REQUEST', fetchProjectBenchmarkReport);
  yield takeLatest('STANDARD_REPORTS_LOAD_CLIENT_BENCHMARK_REPORT_REQUEST', fetchClientBenchmarkReport);
  yield takeLatest('POTENTIAL_REPORTS_LOAD_BRAND_REPORT_REQUEST', fetchBrandPotentialReport);
  yield takeLatest('STANDARD_REPORTS_LOAD_PRODUCT_BENCHMARK_REPORT_REQUEST', fetchProductBenchmarkReport);
}
