import React from 'react';
import Grid from '@material-ui/core/Grid';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Card from '@material-ui/core/Card';

import DeleteBtn from './DeleteBtn';
import ImageDescription from './ImageDescription';

import { useStyles } from '../styles';
import { InspirationImageItem } from '../../../../../entities/inspirationImages/types';

interface Props {
  data: InspirationImageItem[];
  planogramId: string;
}

const ImagesList: React.FC<Props> = ({ data, planogramId }: Props): JSX.Element => {
  const classes = useStyles();
  return (
    <Grid container direction="row" justify="space-around" wrap="wrap">
      {data.map(i => {
        return (
          <Card key={i.id} className={classes.card}>
            <CardActionArea>
              <CardMedia className={classes.img} image={i.imageUrl} component="img" />
            </CardActionArea>
            <CardActions>
              <ImageDescription defaultValue={i.description} planogramId={planogramId} inspirationImageId={i.id} />
              <DeleteBtn planogramId={planogramId} inspirationImageId={i.id} />
            </CardActions>
          </Card>
        );
      })}
    </Grid>
  );
};

export default ImagesList;
