import { Table, TableBody, TableHead, Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import trackComponent from 'infrastructure/tracking/withTracking';
import ResizeObserverComponent from 'modules/shared/components/ResizeObserverComponent';
import * as React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import Alert from 'reactstrap/lib/Alert';
import { State } from 'state';
import { StyledTableCell, StyledTableRow } from '../../../scss/MaterialStyles';
import { getDateInInputFormat } from '../../../utils/dateHelper';
import * as actions from '../actions';
import ShelfLocation from '../domain/ShelfLocation';
import PlanogramState from '../state';
import { NewProductModel } from '../types/NewProductModel';
import ProductModel from '../types/ProductModel';
import TestInfoDto from '../types/TestInfoDto';
import PlanogramComponent from './PlanogramComponent/PlanogramComponentWithHook';
import { isLoading, isError } from 'infrastructure/utils/RemoteObjectStatus';
import { AlertErrorTextIndicator } from 'modules/shared/components/LoadStatusIndicators/AlertErrorTextIndicator';
import { PlanogramFloatingPanel } from './PlanogramFloatingPanel';

class TestPlanogramPage extends React.Component<PlanogramState & DispatchProps> {
  componentWillUnmount(): void {
    document.onkeydown = null;
    this.props.planogramPageReset();
  }

  componentDidMount(): void {
    this.props.loadTestPlanogramRequest(this.props.id, this.props.testPlanogramId);
  }

  render(): JSX.Element {
    return (
      <React.Fragment>
        <div>
          {isError(this.props.loadPlanogramStatus) ? (
            <AlertErrorTextIndicator errorText={this.props.loadPlanogramStatus.errorDetails?.errorMessage} />
          ) : (
            <LoadingOverlay
              active={isLoading(this.props.loadImageStatus) || isLoading(this.props.loadPlanogramStatus)}
              spinner={true}
              text={isLoading(this.props.loadPlanogramStatus) ? 'Loading planogram...' : isLoading(this.props.loadImageStatus) ? 'Downloading Shelf image...' : ''}
            >
              <ResizeObserverComponent
                domElementIdToWatch="content-main-element"
                domElementIdHeightToUse="content-main-parent-element"
                onResizeDetected={(width: number, height: number): void => {
                  this.props.updatePlanogramViewPort(width, height);
                }}
              />
              {this.props.planogram && <PlanogramComponent />}
              {this.props.enableShowProductInfo && <PlanogramFloatingPanel canFixImageSize={false} />}
            </LoadingOverlay>
          )}
        </div>

        {/* THIS DIV WILL ONLY APPEAR IN PRINT MODE */}
        <div className="print-only">
          {isLoading(this.props.loadPlanogramStatus) || this.props.testInfo === undefined ? (
            <Alert color="info">
              <Typography variant="caption" color="textSecondary">
                <Info color="secondary" fontSize="small" />
                &nbsp;Data has not been loaded yet. Try re-entering print mode after a few seconds.
              </Typography>
            </Alert>
          ) : (
            <div style={{ width: '21cm', minHeight: '29.7cm' }}>
              {this.props.testInfo && this.renderOperationsInfoTable(this.props.testInfo)}
              {this.renderProductsInfoTable()}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }

  renderProductsInfoTable(): JSX.Element {
    return (
      <Table size="small" title="Product Info">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>
              {' '}
              <strong>Marking number</strong>{' '}
            </StyledTableCell>
            <StyledTableCell>
              {' '}
              <strong>GTIN </strong>
            </StyledTableCell>
            <StyledTableCell>
              {' '}
              <strong>Product</strong>{' '}
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>{this.renderProductsInfoRows()}</TableBody>
      </Table>
    );
  }

  renderOperationsInfoTable(testInfo: TestInfoDto): JSX.Element {
    return (
      <Table size="small" title="Operations Info">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>
              {' '}
              <strong>Test Name</strong>{' '}
            </StyledTableCell>
            <StyledTableCell>
              {' '}
              <strong>Store </strong>
            </StyledTableCell>
            <StyledTableCell>
              {' '}
              <strong>Start Date</strong>{' '}
            </StyledTableCell>
            <StyledTableCell>
              {' '}
              <strong>End Date</strong>{' '}
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell>
              {' '}
              <strong>{testInfo.testName}</strong>{' '}
            </StyledTableCell>
            <StyledTableCell>
              <strong>{testInfo.operationsInfo.storeName} </strong>
            </StyledTableCell>
            <StyledTableCell>
              {' '}
              <strong>{getDateInInputFormat(testInfo.operationsInfo.startDate)}</strong>
            </StyledTableCell>
            <StyledTableCell>
              {' '}
              <strong>{getDateInInputFormat(testInfo.operationsInfo.endDate)}</strong>
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    );
  }

  renderProductsInfoRows(): JSX.Element[] | null {
    if (this.props.planogram === undefined) {
      return null;
    }
    return this.props.planogram.shelfLocations
      .sort((a, b) => {
        return a.gridPosition - b.gridPosition;
      })
      .map(sl => {
        const products = this.props.products || ([] as ProductModel[]);
        const product = products.find(p => {
          return p.gtin === sl.gtin;
        });
        const tradeItemDescription = product && product.tradeItemDescription ? product.tradeItemDescription : `product details unavailable`;
        return (
          <StyledTableRow key={sl.id}>
            <StyledTableCell>{sl.gridPosition}</StyledTableCell>
            <StyledTableCell>{sl.gtin}</StyledTableCell>
            <StyledTableCell>{tradeItemDescription}</StyledTableCell>
          </StyledTableRow>
        );
      });
  }
}

interface DispatchProps {
  loadTestPlanogramRequest(id: string, testPlanogramId: string): void;
  toggleEnableShowProductInfo(enabled?: boolean): void;
  planogramPageReset(): void;
  updatePlanogramViewPort(width: number, height: number): void;
  fixImageSizeRequest(planogramId: string, shelfLocation: ShelfLocation): void;
  setShelfLocationGtinRequest(planogramId: string, shelfLocation: ShelfLocation, gtin: number, newProduct?: NewProductModel): void;
}

interface MatchParams {
  id: string;
  testPlanogramId: string;
}

function mapStateToProps(state: State, ownProps: RouteComponentProps<MatchParams>): PlanogramState {
  return {
    ...state.planogram,
    id: ownProps.match.params.id,
    testPlanogramId: ownProps.match.params.testPlanogramId,
    userId: Number(state.auth.loadUserStatus.data?.profile.sub ?? 0),
  };
}

const componentToExport = connect<PlanogramState, DispatchProps, RouteComponentProps<MatchParams>>(mapStateToProps, actions)(TestPlanogramPage);

export default trackComponent(componentToExport, 'TestPlanogramPage');
