import { State } from 'state';
import { EulaState, EulaAgreement } from './types';
import { createSelector, Selector } from 'reselect';
import { RemoteObjectStatusInterface } from 'infrastructure/utils/RemoteObjectStatus';

const getEulaState = (state: State): EulaState => {
  return state.eula;
};

export const getLatestEulaStatus: Selector<State, RemoteObjectStatusInterface<EulaAgreement>> = createSelector(getEulaState, state => {
  return state.latestEula;
});

export const getIsAgreengStatus: Selector<State, RemoteObjectStatusInterface<boolean>> = createSelector(getEulaState, state => {
  return state.agreeingStatus;
});
