import * as React from 'react';

import { AppAside, AppFooter, AppHeader } from '@coreui/react';

import { Route } from 'react-router';
import Container from 'reactstrap/lib/Container';
import { Switch } from 'react-router';
import routes from '../../routing/routes';
import footerRoutes from '../../routing/footerRoutes';
import { State } from 'state';
import Header from './Header';
import Aside from '../_Shared/Aside';
import asideRoutes from '../../routing/asideRoutes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default class DefaultPublicLayout extends React.Component<State & any> {
  loading = (): JSX.Element => <div className="animated fadeIn pt-1 text-center">Loading...</div>;

  render(): JSX.Element {
    return (
      <div className="app">
        <AppHeader fixed={true}>
          <React.Suspense fallback={this.loading()}>
            <Header {...this.props} />
          </React.Suspense>
        </AppHeader>
        <div className="app-body">
          <main className="main" id="content-main-parent-element">
            <Container fluid={true} id="content-main-element">
              <React.Suspense fallback={this.loading()}>
                <Switch>
                  {routes.map((route, idx) => {
                    return route.component ? <Route key={idx} path={route.path} exact={route.exact} name={route.name} component={route.component} /> : null;
                  })}
                </Switch>
              </React.Suspense>
            </Container>
          </main>
          <AppAside fixed>{this.props.globalUiState.isAsideOpened ? <Aside routes={asideRoutes} /> : null}</AppAside>
        </div>
        <AppFooter>
          <Switch>
            {footerRoutes.map((route, idx) => {
              return route.component ? <Route key={idx} path={route.path} exact={route.exact} name={route.name} component={route.component} /> : null;
            })}
          </Switch>
        </AppFooter>
      </div>
    );
  }
}
