import * as React from 'react';
import { telemetryService } from 'infrastructure/tracking/TelemetryService';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

/**
 * Higher-order component function to hook Application Insights tracking
 * in a React component's lifecycle.
 *
 * @param Component the React component to be instrumented
 * @param componentName (optional) component name
 */
export default function trackComponent<P>(Component: React.ComponentType<P>, componentName: string): React.ComponentClass<P> {
  class TrackingComponent extends React.Component<P> {
    hasMounted = false;
    performanceTick = 0;

    componentDidMount(): void {
      this.hasMounted = true;
      const result = performance.now() - this.performanceTick;
      telemetryService.getInstance().trackMetric(
        {
          average: result,
          sampleCount: 1,
          name: `React Component Mount Time (ms)`,
        },
        {
          'Component Name': componentName,
        }
      );
    }

    componentDidUpdate(): void {
      const result = performance.now() - this.performanceTick;
      telemetryService.getInstance().trackMetric(
        {
          average: result,
          sampleCount: 1,
          name: `React Component Render Time (ms)`,
        },
        {
          'Component Name': componentName,
        }
      );
    }

    render(): JSX.Element {
      this.performanceTick = performance.now();
      return <Component {...this.props} />;
    }
  }

  return withAITracking(telemetryService.getReactPlugin(), TrackingComponent, componentName);
}
