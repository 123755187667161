import { Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { toMaxPrecision, toMaxPrecisionPercentFloored } from 'utils/Helpers';
import flowLogo from '../../../../../assets/img/brand/flow.jpg';
import { InteractorsType, ProjectConversionShopperCategoryType, ProjectDetailedConversionResult, ProjectReportResult } from '../../types';
import { InteractorsStackedBarChart } from '../InteractorsStackedBarChart';
import '../styles/StandardReportStyle.css';
import './StandardProjectReportPage.css';
const getProjectChartData = (conversionReport: ProjectDetailedConversionResult[]): object[] => {
  const result = [{}] as object[];
  Object.keys(ProjectConversionShopperCategoryType).forEach(category => {
    result[0][category] = toMaxPrecisionPercentFloored(conversionReport?.find(x => x.projectConversionShopperCategory === ProjectConversionShopperCategoryType[category])?.shopperPercent, 2);
  });
  return result;
};

interface StandardProjectReportPageProps {
  projectReport: ProjectReportResult;
}
const StandardProjectReportPage: React.FC<StandardProjectReportPageProps> = ({ projectReport }: StandardProjectReportPageProps) => {
  const projectOverviewReport = projectReport.projectOverviewReport;
  const projectBricksReport = projectReport.projectBricksReport;
  const projectDetailedConversionReport = projectReport.projectDetailedConversionReport;

  return (
    <div className="page">
      <div id="flowLogo">
        <img alt="flow-logo" className="img-responsive" src={flowLogo} />
      </div>
      <div style={{ width: '100%' }}>
        <h3 className="text-center">
          {projectOverviewReport.projectName}
          <br />
        </h3>
        <h5 className="text-center">
          {projectOverviewReport.storeName}, Phase {projectOverviewReport.phaseNum}
          <br />
          {moment(projectOverviewReport.dateFrom).format('DD/MM/YYYY')} - {moment(projectOverviewReport.dateTo).format('DD/MM/YYYY')}
          <br />
        </h5>
        <h5 className="text-left">
          <b>Visitors:</b> {projectOverviewReport.totalVisitors}
          <br />
          <b>Products: </b>
          {projectOverviewReport.productsCount}
        </h5>
        <img alt="shelf img" className="img-responsive shelfImage" src={projectOverviewReport.shelfImageUrl} />
        <div className="row">
          <table style={{ marginTop: '10px' }} className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-center metricNameCell">Conversion rate(percent)</td>
                <td className="text-center metricNameCell">Turnover per visitor(amount)</td>
                <td className="text-center metricNameCell">Daily turnover(average amount)</td>
                <td className="text-center metricNameCell">Destination buyers(percent)</td>
              </tr>
              <tr>
                <td className="text-center">
                  {projectOverviewReport.conversionRate.toLocaleString('en', {
                    style: 'percent',
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td className="text-center">{toMaxPrecision(projectOverviewReport.turnoverPerVisitor, 2)}</td>
                <td className="text-center">{toMaxPrecision(projectOverviewReport.dailyTurnoverAverage, 2)}</td>
                <td className="text-center">
                  {projectOverviewReport.destinationBuyersPercent.toLocaleString('en', {
                    style: 'percent',
                    maximumFractionDigits: 2,
                  })}
                </td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">Return ratio(percent)</td>
                <td className="text-center metricNameCell">Lost shoppers(percent)</td>
                <td className="text-center metricNameCell">Lost daily turnover(average)</td>
                <td className="text-center metricNameCell">Out of shelf(daily hours)</td>
              </tr>
              <tr>
                <td className="text-center">
                  {projectOverviewReport.returnRatioPercent?.toLocaleString('en', {
                    style: 'percent',
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td className="text-center">
                  {projectOverviewReport.lostShoppersPercent.toLocaleString('en', {
                    style: 'percent',
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td className="text-center">{toMaxPrecision(projectOverviewReport.averageLostDailyTurnover, 2)}</td>
                <td className="text-center">{projectOverviewReport.outOfStockDailyHours ? toMaxPrecision(projectOverviewReport.outOfStockDailyHours, 2) : 'none'}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row">
          <table style={{ marginTop: '10px' }} className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-left metricNameCell">Seconds to buy and leave(buyers)</td>
                <td className="text-left">{toMaxPrecision(projectOverviewReport.secondsToBuyAndLeave, 2)}</td>
                <td className="text-left metricNameCell">Interactions per shopper(average)</td>
                <td className="text-left">{toMaxPrecision(projectOverviewReport.interactionsPerShopper, 2)}</td>
              </tr>
              <tr>
                <td className="text-left metricNameCell">Seconds to first interaction(all shoppers)</td>
                <td className="text-left">{toMaxPrecision(projectOverviewReport.secondsToFirstInteraction, 2)}</td>
                <td className="text-left metricNameCell">Average basket size(units)</td>
                <td className="text-left">{toMaxPrecision(projectOverviewReport.averageBasketSizeUnits, 2)}</td>
              </tr>
              <tr>
                <td className="text-left metricNameCell">Lost shoppers, time in seconds</td>
                <td className="text-left">{toMaxPrecision(projectOverviewReport.lostShopperSeconds, 2)}</td>
                <td className="text-left metricNameCell">Average basket size(turnover)</td>
                <td className="text-left">{toMaxPrecision(projectOverviewReport.averageBasketSizeTurnover, 2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row">
          <table style={{ marginTop: '10px' }} className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-center metricNameCell">Top 5 bricks</td>
                <td className="text-center metricNameCell">Share of space</td>
                <td className="text-center metricNameCell">Share of unit sales</td>
                <td className="text-center metricNameCell">Share of turnover</td>
                <td className="text-center metricNameCell">Share of first pickups</td>
              </tr>
              {projectBricksReport.map(brick => {
                return (
                  <tr key={brick.brickName}>
                    <td className="text-center metricNameCell">{brick.brickName}</td>
                    <td className="text-center">
                      {brick.shareOfSpace.toLocaleString('en', {
                        style: 'percent',
                        maximumFractionDigits: 2,
                      })}
                    </td>
                    <td className="text-center">
                      {brick.shareOfUnitSales.toLocaleString('en', {
                        style: 'percent',
                        maximumFractionDigits: 2,
                      })}
                    </td>
                    <td className="text-center">
                      {brick.shareOfTurnover.toLocaleString('en', {
                        style: 'percent',
                        maximumFractionDigits: 2,
                      })}
                    </td>
                    <td className="text-center">
                      {brick.shareOfFirstPickups.toLocaleString('en', {
                        style: 'percent',
                        maximumFractionDigits: 2,
                      })}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Grid container alignContent="center" alignItems="center" justify={'center'} style={{ marginTop: '10px' }} direction="column">
        <Grid item>
          <Typography variant="overline">Detailed interaction profile</Typography>
        </Grid>
        <Grid item>
          <InteractorsStackedBarChart data={getProjectChartData(projectDetailedConversionReport)} interactorsType={InteractorsType.Project} />
        </Grid>
      </Grid>
      <footer>
        <strong>Copyright &copy; {new Date().getFullYear()} Flow Insights</strong>. Strictly confidential. No distribution allowed. All rights reserved.
      </footer>
    </div>
  );
};

export { StandardProjectReportPage };
