import { Grid } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { toMaxPrecision, toMaxPrecisionPercent } from 'utils/Helpers';
import flowLogo from '../../../../../assets/img/brand/flow.jpg';
import { BrandComparisonReportResult } from '../../types';
import '../styles/StandardReportStyle.css';
interface StandardBrandComparisonReportPageProps {
  brandComparisonReport: BrandComparisonReportResult;
}
const StandardBrandComparisonReportPage: React.FC<StandardBrandComparisonReportPageProps> = ({ brandComparisonReport }: StandardBrandComparisonReportPageProps) => {
  const overviewTitleReport = brandComparisonReport.overviewTitleReport;
  const overviewRatesReport = brandComparisonReport.overviewRatesReport;
  const overviewDailyAveragesReport = brandComparisonReport.overviewDailyAveragesReport;
  const overviewShopperAveragesReport = brandComparisonReport.overviewShopperAveragesReport;
  const internalMetricsReport = brandComparisonReport.internalMetricsReport;
  const demographicsReport = brandComparisonReport.demographicsReport;
  const brand1ExternalBoughtTogetherReport = _.orderBy(brandComparisonReport.brand1ExternalBoughtTogetherReport, 'numberOfShoppers', 'desc');
  const brand1ExternalWinningOverReport = _.orderBy(brandComparisonReport.brand1ExternalWinningOverReport, 'numberOfShoppers', 'desc');
  const brand1ExternalLosingToReport = _.orderBy(brandComparisonReport.brand1ExternalLosingToReport, 'numberOfShoppers', 'desc');
  const brand2ExternalBoughtTogetherReport = _.orderBy(brandComparisonReport.brand2ExternalBoughtTogetherReport, 'numberOfShoppers', 'desc');
  const brand2ExternalWinningOverReport = _.orderBy(brandComparisonReport.brand2ExternalWinningOverReport, 'numberOfShoppers', 'desc');
  const brand2ExternalLosingToReport = _.orderBy(brandComparisonReport.brand2ExternalLosingToReport, 'numberOfShoppers', 'desc');

  return (
    <div className="page">
      <div id="flowLogo">
        <img alt="flow-logo" className="img-responsive" src={flowLogo} />
      </div>
      <div style={{ width: '100%' }}>
        <h3 className="text-center">
          Brand Comparison Report
          <br />
        </h3>
        <h5 className="text-center">
          <p style={{ margin: '0px' }}>
            <b>Shelf A: </b> {overviewTitleReport.projectNameA}, {overviewTitleReport.storeNameA}, Phase {overviewTitleReport.phaseNumA}, Shelf {overviewTitleReport.shelfNumA} (
            <i>
              {moment(overviewTitleReport.dateFromA).format('DD/MM/YYYY')} - {moment(overviewTitleReport.dateToA).format('DD/MM/YYYY')}
            </i>
            )
          </p>
          <p style={{ margin: '0px' }}>
            <b>Shelf B: </b> {overviewTitleReport.projectNameB}, {overviewTitleReport.storeNameB}, Phase {overviewTitleReport.phaseNumB}, Shelf {overviewTitleReport.shelfNumB} (
            <i>
              {moment(overviewTitleReport.dateFromB).format('DD/MM/YYYY')} - {moment(overviewTitleReport.dateToB).format('DD/MM/YYYY')}
            </i>
            )
          </p>
          <br />
        </h5>
        <h5 className="text-left">
          <Grid container direction="row" justify="space-between" spacing={2}>
            <Grid item>
              <p style={{ margin: '0px' }}>
                <b>Shelf A Visitors:</b> {overviewTitleReport.totalVisitorsA}
              </p>
              <p style={{ margin: '0px' }}>
                <b>Shelf B Visitors:</b> {overviewTitleReport.totalVisitorsB}
              </p>
              <br />
            </Grid>
            <Grid item>
              <p style={{ margin: '0px' }}>
                <b>Brand A</b>
              </p>
              <p style={{ margin: '0px' }}>{overviewTitleReport.brandNameA}</p>
              <p style={{ margin: '0px' }}>Interactors: {overviewTitleReport.totalBrandInteractorsA}</p>
            </Grid>
            <Grid item>
              <p style={{ margin: '0px' }}>
                <b>Brand B</b>
              </p>
              <p style={{ margin: '0px' }}>{overviewTitleReport.brandNameB}</p>
              <p style={{ margin: '0px' }}>Interactors: {overviewTitleReport.totalBrandInteractorsB}</p>
            </Grid>
          </Grid>
        </h5>
        <div className="row">
          <table style={{ marginTop: '10px' }} className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-center metricNameCell">Brand Metrics</td>
                <td className="text-center metricNameCell">Conversion rate(percent)</td>
                <td className="text-center metricNameCell">Turnover per visitor(amount)</td>
                <td className="text-center metricNameCell">Daily turnover(average amount)</td>
                <td className="text-center metricNameCell">Destination buyers(percent)</td>
                <td className="text-center metricNameCell">Return ratio(percent)</td>
                <td className="text-center metricNameCell">Brand lost shoppers(percent)</td>
                <td className="text-center metricNameCell">Brand out of shelf(daily hours)</td>
                <td className="text-center metricNameCell">Brand out of stock(percent)</td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">% change</td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewRatesReport.brandConversionRatePercChange, 2)} {overviewRatesReport.brandConversionRateSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewShopperAveragesReport.brandTurnoverPerVisitorPercChange, 2)} {overviewShopperAveragesReport.brandTurnoverPerVisitorSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewDailyAveragesReport.brandDailyTurnoverPercChange, 2)} {overviewDailyAveragesReport.brandDailyTurnoverSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewRatesReport.brandDestinationBuyersPercChange, 2)} {overviewRatesReport.brandDestinationBuyersSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewRatesReport.brandReturnRatioPercChange, 2)} {overviewRatesReport.brandReturnRatioSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewRatesReport.brandLostShoppersPercChange, 2)} {overviewRatesReport.brandLostShoppersSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewDailyAveragesReport.brandOutOfShelfHoursPercChange, 2)} {overviewDailyAveragesReport.brandOutOfShelfHoursSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewDailyAveragesReport.brandOutOfStockPercentPercChange, 2)} {overviewDailyAveragesReport.brandOutOfStockPercentSignificance}
                </td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">Brand A</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.brandConversionRateA, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewShopperAveragesReport.brandTurnoverPerVisitorA, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewDailyAveragesReport.brandDailyTurnoverA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.brandDestinationBuyersA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.brandReturnRatioA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.brandLostShoppersA, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewDailyAveragesReport.brandOutOfShelfHoursA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewDailyAveragesReport.brandOutOfStockPercentA, 2)}</td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">Brand B</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.brandConversionRateB, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewShopperAveragesReport.brandTurnoverPerVisitorB, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewDailyAveragesReport.brandDailyTurnoverB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.brandDestinationBuyersB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.brandReturnRatioB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.brandLostShoppersB, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewDailyAveragesReport.brandOutOfShelfHoursB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewDailyAveragesReport.brandOutOfStockPercentB, 2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row">
          <table style={{ marginTop: '10px' }} className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-center metricNameCell">Brand Metrics</td>
                <td className="text-center metricNameCell">Share of space</td>
                <td className="text-center metricNameCell">Share of unit sales</td>
                <td className="text-center metricNameCell">Share of turnover</td>
                <td className="text-center metricNameCell">Share of first pickups</td>
                <td className="text-center metricNameCell">Discovery time(all shoppers)</td>
                <td className="text-center metricNameCell">Seconds to buy and leave(buyers)</td>
                <td className="text-center metricNameCell">Seconds to touch and reject(non-buyers)</td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">% change</td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewRatesReport.shareOfSpacePercChange, 2)} {overviewRatesReport.shareOfSpaceSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewRatesReport.shareOfUnitSalesPercChange, 2)} {overviewRatesReport.shareOfUnitSalesSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewRatesReport.shareOfTurnoverPercChange, 2)} {overviewRatesReport.shareOfTurnoverSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewRatesReport.shareOfFirstPickupsPercChange, 2)} {overviewRatesReport.shareOfFirstPickupsSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewShopperAveragesReport.discoveryTimePercChange, 2)} {overviewShopperAveragesReport.discoveryTimeSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewShopperAveragesReport.secondsToBuyAndLeavePercChange, 2)} {overviewShopperAveragesReport.secondsToBuyAndLeaveSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewShopperAveragesReport.secondsToRejectPercChange, 2)} {overviewShopperAveragesReport.secondsToRejectSignificance}
                </td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">Brand A</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.shareOfSpaceA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.shareOfUnitSalesA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.shareOfTurnoverA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.shareOfFirstPickupsA, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewShopperAveragesReport.discoveryTimeA, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewShopperAveragesReport.secondsToBuyAndLeaveA, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewShopperAveragesReport.secondsToRejectA, 2)}</td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">Brand B</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.shareOfSpaceB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.shareOfUnitSalesB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.shareOfTurnoverB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewRatesReport.shareOfFirstPickupsB, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewShopperAveragesReport.discoveryTimeB, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewShopperAveragesReport.secondsToBuyAndLeaveB, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewShopperAveragesReport.secondsToRejectB, 2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row">
          <table className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-center metricNameCell">Brand metrics</td>
                <td className="text-center metricNameCell">External substitutors</td>
                <td className="text-center metricNameCell">Internal substitutors</td>
                <td className="text-center metricNameCell">Internal multiple purchasers</td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">% change</td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewShopperAveragesReport?.substitutionsPercChange, 2)} {overviewShopperAveragesReport?.substitutionsSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(internalMetricsReport?.internalSubstitutorsPercChange, 2)} {internalMetricsReport?.internalSubstitutorsSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(internalMetricsReport?.internalBuysMoreThanOneProductsPercChange, 2)} {internalMetricsReport?.internalBuysMoreThanOneProductsSignificance}
                </td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">Brand A</td>
                <td className="text-center">{toMaxPrecision(overviewShopperAveragesReport?.substitutionsA, 2)}</td>
                <td className="text-center">{toMaxPrecision(internalMetricsReport?.internalSubstitutorsA, 2)}</td>
                <td className="text-center">{toMaxPrecision(internalMetricsReport?.internalBuysMoreThanOneProductsA, 2)}</td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">Brand B</td>
                <td className="text-center">{toMaxPrecision(overviewShopperAveragesReport?.substitutionsB, 2)}</td>
                <td className="text-center">{toMaxPrecision(internalMetricsReport?.internalSubstitutorsB, 2)}</td>
                <td className="text-center">{toMaxPrecision(internalMetricsReport?.internalBuysMoreThanOneProductsB, 2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row">
          <table className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-center metricNameCell" colSpan={3}>
                  Brand A - {overviewTitleReport.brandNameA}
                </td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">Mostly bought with</td>
                <td className="text-center metricNameCell">Mostly losing to</td>
                <td className="text-center metricNameCell">Mostly winning over</td>
              </tr>
              <tr>
                <td className="text-center">
                  {brand1ExternalBoughtTogetherReport.length >= 1
                    ? `
                    ${brand1ExternalBoughtTogetherReport[0].comparisonBrand} (
                    ${brand1ExternalBoughtTogetherReport[0].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {brand1ExternalLosingToReport.length >= 1
                    ? `
                    ${brand1ExternalLosingToReport[0].losingToBrand} (
                    ${brand1ExternalLosingToReport[0].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {brand1ExternalWinningOverReport.length >= 1
                    ? `
                    ${brand1ExternalWinningOverReport[0].winningOverBrand} (
                    ${brand1ExternalWinningOverReport[0].numberOfShoppers})`
                    : ''}
                </td>
              </tr>
              <tr>
                <td className="text-center">
                  {brand1ExternalBoughtTogetherReport.length >= 2
                    ? `
                    ${brand1ExternalBoughtTogetherReport[1].comparisonBrand} (
                    ${brand1ExternalBoughtTogetherReport[1].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {brand1ExternalLosingToReport.length >= 2
                    ? `
                    ${brand1ExternalLosingToReport[1].losingToBrand} (
                    ${brand1ExternalLosingToReport[1].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {brand1ExternalWinningOverReport.length >= 2
                    ? `
                    ${brand1ExternalWinningOverReport[1].winningOverBrand} (
                    ${brand1ExternalWinningOverReport[1].numberOfShoppers})`
                    : ''}
                </td>
              </tr>
              <tr>
                <td className="text-center">
                  {brand1ExternalBoughtTogetherReport.length >= 3
                    ? `
                    ${brand1ExternalBoughtTogetherReport[2].comparisonBrand} (
                    ${brand1ExternalBoughtTogetherReport[2].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {brand1ExternalLosingToReport.length >= 3
                    ? `
                    ${brand1ExternalLosingToReport[2].losingToBrand} (
                    ${brand1ExternalLosingToReport[2].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {brand1ExternalWinningOverReport.length >= 3
                    ? `
                    ${brand1ExternalWinningOverReport[2].winningOverBrand} (
                    ${brand1ExternalWinningOverReport[2].numberOfShoppers})`
                    : ''}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row">
          <table className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-center metricNameCell" colSpan={3}>
                  Brand B - {overviewTitleReport.brandNameB}
                </td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">Mostly bought with</td>
                <td className="text-center metricNameCell">Mostly losing to</td>
                <td className="text-center metricNameCell">Mostly winning over</td>
              </tr>
              <tr>
                <td className="text-center">
                  {brand2ExternalBoughtTogetherReport.length >= 1
                    ? `
                    ${brand2ExternalBoughtTogetherReport[0].comparisonBrand} (
                    ${brand2ExternalBoughtTogetherReport[0].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {brand2ExternalLosingToReport.length >= 1
                    ? `
                    ${brand2ExternalLosingToReport[0].losingToBrand} (
                    ${brand2ExternalLosingToReport[0].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {brand2ExternalWinningOverReport.length >= 1
                    ? `
                    ${brand2ExternalWinningOverReport[0].winningOverBrand} (
                    ${brand2ExternalWinningOverReport[0].numberOfShoppers})`
                    : ''}
                </td>
              </tr>
              <tr>
                <td className="text-center">
                  {brand2ExternalBoughtTogetherReport.length >= 2
                    ? `
                    ${brand2ExternalBoughtTogetherReport[1].comparisonBrand} (
                    ${brand2ExternalBoughtTogetherReport[1].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {brand2ExternalLosingToReport.length >= 2
                    ? `
                    ${brand2ExternalLosingToReport[1].losingToBrand} (
                    ${brand2ExternalLosingToReport[1].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {brand2ExternalWinningOverReport.length >= 2
                    ? `
                    ${brand2ExternalWinningOverReport[1].winningOverBrand} (
                    ${brand2ExternalWinningOverReport[1].numberOfShoppers})`
                    : ''}
                </td>
              </tr>
              <tr>
                <td className="text-center">
                  {brand2ExternalBoughtTogetherReport.length >= 3
                    ? `
                    ${brand2ExternalBoughtTogetherReport[2].comparisonBrand} (
                    ${brand2ExternalBoughtTogetherReport[2].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {brand2ExternalLosingToReport.length >= 3
                    ? `
                    ${brand2ExternalLosingToReport[2].losingToBrand} (
                    ${brand2ExternalLosingToReport[2].numberOfShoppers})`
                    : ''}
                </td>
                <td className="text-center">
                  {brand2ExternalWinningOverReport.length >= 3
                    ? `
                    ${brand2ExternalWinningOverReport[2].winningOverBrand} (
                    ${brand2ExternalWinningOverReport[2].numberOfShoppers})`
                    : ''}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row">
          <table style={{ marginTop: '10px' }} className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-center metricNameCell">Demographics</td>
                <td className="text-center metricNameCell" colSpan={2}>
                  Interactors
                </td>
                <td className="text-center metricNameCell" colSpan={2}>
                  Buyers
                </td>
                <td className="text-center metricNameCell" colSpan={2}>
                  Lost shoppers
                </td>
              </tr>

              <tr>
                <td className="text-center metricNameCell" rowSpan={2}>
                  All
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.allInteractorsPercChange, 2)} {demographicsReport.allInteractorsSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.allBuyersPercChange, 2)} {demographicsReport.allBuyersSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.allLostShoppersPercChange, 2)} {demographicsReport.allLostShoppersSignificance}
                </td>
              </tr>
              <tr>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.allInteractorsA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.allInteractorsB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.allBuyersA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.allBuyersB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.allLostShoppersA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.allLostShoppersB, 2)}</td>
              </tr>
              <tr>
                <td className="text-center metricNameCell" rowSpan={2}>
                  Female
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.femaleInteractorsPercChange, 2)} {demographicsReport.femaleInteractorsSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.femaleBuyersPercChange, 2)} {demographicsReport.femaleBuyersSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.femaleLostShoppersPercChange, 2)} {demographicsReport.femaleLostShoppersSignificance}
                </td>
              </tr>
              <tr>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.femaleInteractorsA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.femaleInteractorsB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.femaleBuyersA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.femaleBuyersB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.femaleLostShoppersA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.femaleLostShoppersB, 2)}</td>
              </tr>

              <tr>
                <td className="text-center metricNameCell" rowSpan={2}>
                  Male
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.maleInteractorsPercChange, 2)} {demographicsReport.maleInteractorsSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.maleBuyersPercChange, 2)} {demographicsReport.maleBuyersSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.maleLostShoppersPercChange, 2)} {demographicsReport.maleLostShoppersSignificance}
                </td>
              </tr>
              <tr>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.maleInteractorsA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.maleInteractorsB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.maleBuyersA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.maleBuyersB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.maleLostShoppersA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.maleLostShoppersB, 2)}</td>
              </tr>

              <tr>
                <td className="text-center metricNameCell" rowSpan={2}>
                  WithKids
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.withKidsInteractorsPercChange, 2)} {demographicsReport.withKidsInteractorsSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.withKidsBuyersPercChange, 2)} {demographicsReport.withKidsBuyersSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.withKidsLostShoppersPercChange, 2)} {demographicsReport.withKidsLostShoppersSignificance}
                </td>
              </tr>
              <tr>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.withKidsInteractorsA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.withKidsInteractorsB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.withKidsBuyersA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.withKidsBuyersB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.withKidsLostShoppersA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.withKidsLostShoppersB, 2)}</td>
              </tr>

              <tr>
                <td className="text-center metricNameCell" rowSpan={2}>
                  Group
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.groupInteractorsPercChange, 2)} {demographicsReport.groupInteractorsSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.groupBuyersPercChange, 2)} {demographicsReport.groupBuyersSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(demographicsReport.groupLostShoppersPercChange, 2)} {demographicsReport.groupLostShoppersSignificance}
                </td>
              </tr>
              <tr>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.groupInteractorsA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.groupInteractorsB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.groupBuyersA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.groupBuyersB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.groupLostShoppersA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(demographicsReport.groupLostShoppersB, 2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <footer>
        <strong>Copyright &copy; {new Date().getFullYear()} Flow Insights</strong>. Strictly confidential. No distribution allowed. All rights reserved.
      </footer>
    </div>
  );
};

export { StandardBrandComparisonReportPage };
