import * as ReduxSaga from 'redux-saga';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { toastr } from 'react-redux-toastr';
import apiSaga from '../../../utils/apiSaga';
import {
  FetchInspirationImagesRequestAction,
  DeleteInspirationImagesRequestAction,
  UpdateDescInspirationImageRequestAction,
  UPLOAD_INSPIRATION_IMAGES,
  UploadInspirationImagesRequestCreator,
} from './types';
import { deleteInspirationImage as deleteService, fetchInspirationImages as fetchServices, updateInspirationImageDesc as updateDesService, uploadInspirationImagesServices } from './services';
import * as actions from './actions';
import ErrorDetails from '../../../infrastructure/exception/ErrorDetails';

export function* fetch(action: ReturnType<FetchInspirationImagesRequestAction>): ReduxSaga.SagaIterator {
  try {
    const response = yield call(apiSaga, fetchServices, action.payload);
    yield put(actions.fetchInspirationImagesSuccess(response.data.inspirationImages));
  } catch (e) {
    yield put(actions.fetchInspirationImagesFailed(e.response.data as ErrorDetails));
  }
}

export function* deleteImage(action: ReturnType<DeleteInspirationImagesRequestAction>) {
  try {
    yield call(apiSaga, deleteService, action.payload);
    yield put(actions.deleteInspirationImageSuccess(action.payload));
  } catch (e) {
    const errorDetails: ErrorDetails = e.response.data;
    yield call(toastr.error, 'Error', errorDetails.errorMessage);
    yield call(action.meta.resetLoading);
  }
}

export function* updateDesc(action: ReturnType<UpdateDescInspirationImageRequestAction>) {
  try {
    yield call(apiSaga, updateDesService, action.payload);
    yield put(
      actions.updateDescInspirationImageSuccess({
        description: action.payload.description,
        inspirationImageId: action.payload.inspirationImageId,
      })
    );
    yield call(action.meta.resetUpdating);
    yield call(action.meta.restEditingMode);
  } catch (e) {
    const errorDetails: ErrorDetails = e.response.data;
    yield call(toastr.error, 'Error', errorDetails.errorMessage);
    yield call(action.meta.resetUpdating);
  }
}

export function* uploadInspirationImages(action: ReturnType<UploadInspirationImagesRequestCreator>) {
  const { resetForm, setSubmitting } = action.meta;
  try {
    yield call(apiSaga, uploadInspirationImagesServices, action.payload);
    yield call(toastr.success, 'Success', 'Successfully uploaded images');
    yield call(resetForm);
    yield put(actions.fetchInspirationImagesRequest(action.payload.planogramId));
  } catch (e) {
    const error: ErrorDetails = e.response.data;
    yield call(toastr.error, 'Error', error.errorMessage);
    yield call(setSubmitting, false);
  }
}

export default function* watchInspirationImage(): ReduxSaga.SagaIterator {
  yield takeLatest(getType(actions.fetchInspirationImagesRequest), fetch);
  yield takeEvery(getType(actions.deleteInspirationImageRequest), deleteImage);
  yield takeEvery(getType(actions.updateDescInspirationImageRequest), updateDesc);
  yield takeLatest(UPLOAD_INSPIRATION_IMAGES.REQUEST, uploadInspirationImages);
}
