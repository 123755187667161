import Typography from '@material-ui/core/Typography';
import { format, isValid } from 'date-fns';
import React from 'react';

export const getFormattedUTCDate = (dateString: string): string => {
  const date = new Date(dateString);

  if (!isValid(date)) {
    return '';
  }

  const utcDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
  const pattern = `HH:mm, EEEE do 'of' MMMM yyyy`;
  return format(utcDate, pattern);
};

export interface SelectedTimeProps {
  selectedTime: string;
}

const SelectedTime: React.FC<SelectedTimeProps> = ({ selectedTime }: SelectedTimeProps): JSX.Element => {
  const formattedDate = getFormattedUTCDate(selectedTime);
  // noinspection HtmlDeprecatedAttribute
  return <Typography align="center"> Time(UTC): {formattedDate}</Typography>;
};

export default SelectedTime;
