import { Button, Card, Divider, Fab, Grid, Tooltip, Typography } from '@material-ui/core';
import { Assessment, Bookmark, Bookmarks, Compare, Description, ListAlt, Store, SupervisedUserCircle, TrendingUp, Wc } from '@material-ui/icons';
import { ClientOption, PhaseOption, ProjectOption, ShelfOption, StoreOption } from 'modules/reports/types';
import { ClientSelector } from 'modules/shared/components/DropDownSelectors/ClientSelector';
import { ProjectSelector } from 'modules/shared/components/DropDownSelectors/ProjectSelector';
import { AlertInfoIndicator } from 'modules/shared/components/LoadStatusIndicators/AlertInfoIndicator';
import { AlertWarningIndicator } from 'modules/shared/components/LoadStatusIndicators/AlertWarningIndicator';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import * as helpers from '../helpers';
import * as selectors from '../selectors';
import { CauseEffectReportSelector } from './CauseEffectReportSelector';
import { StandardReportButtons } from './StandardReportButtons';
import { StandardReportsShelfSelector } from './StandardReportsShelfSelector';
export interface StandardReportsComponentProps {
  selectedProjectId1?: number;
  selectedStoreId1?: number;
  selectedShelfNum1?: number;
  selectedPhaseNum1?: number;
}

const StandardReportsComponent: React.FC<StandardReportsComponentProps> = (props: StandardReportsComponentProps) => {
  const [selectedProjectId1, setSelectedProjectId1] = useState(props.selectedProjectId1);
  const [selectedStoreId1, setSelectedStoreId1] = useState(props.selectedStoreId1);
  const [selectedShelfNum1, setSelectedShelfNum1] = useState(props.selectedShelfNum1);
  const [selectedPhaseNum1, setSelectedPhaseNum1] = useState(props.selectedPhaseNum1);
  const [selectedProjectId2, setSelectedProjectId2] = useState<number | undefined>(props.selectedProjectId1);
  const [selectedStoreId2, setSelectedStoreId2] = useState<number | undefined>(props.selectedStoreId1);
  const [selectedShelfNum2, setSelectedShelfNum2] = useState<number | undefined>(selectedShelfNum1);
  const [selectedPhaseNum2, setSelectedPhaseNum2] = useState<number | undefined>(selectedPhaseNum1);
  const [selectedGtin1, setSelectedGtin1] = useState<number | undefined>();
  const [selectedGtin2, setSelectedGtin2] = useState<number | undefined>();
  const [selectedBrandId1, setSelectedBrandId1] = useState<number | undefined>();
  const [selectedBrandId2, setSelectedBrandId2] = useState<number | undefined>();
  const [selectedProductBrandFilterId1, setSelectedProductBrandFilterId1] = useState<number | undefined>();
  const [selectedProductBrandFilterId2, setSelectedProductBrandFilterId2] = useState<number | undefined>();
  const [selectedGpcBrickCode1, setSelectedGpcBrickCode1] = useState<number | undefined>();
  const [selectedGpcBrickCode2, setSelectedGpcBrickCode2] = useState<number | undefined>();
  const [selectedBenchmarkClientOption, setSelectedBenchmarkClientOption] = useState<ClientOption | undefined>();
  const [selectedBenchmarkProjectOption, setSelectedBenchmarkProjectOption] = useState<ProjectOption | undefined>();

  const phaseShelves = useSelector(selectors.getData);
  const clientOptions = helpers.getClientOptions(phaseShelves);
  const projectOptions = helpers.getProjectOptions(phaseShelves);
  const selectedPhaseShelf1 = phaseShelves.find(x => x.projectId === selectedProjectId1 && x.storeId === selectedStoreId1 && x.shelfNum === selectedShelfNum1 && x.phaseNum === selectedPhaseNum1);
  const selectedPhaseShelf2 = phaseShelves.find(x => x.projectId === selectedProjectId2 && x.storeId === selectedStoreId2 && x.shelfNum === selectedShelfNum2 && x.phaseNum === selectedPhaseNum2);
  const handleOnChangeProject1 = (project: ProjectOption): void => {
    setSelectedProjectId1(project.projectId);
    setSelectedStoreId1(undefined);
    setSelectedShelfNum1(undefined);
    setSelectedPhaseNum1(undefined);
  };

  const handleOnChangeStore1 = (store: StoreOption): void => {
    setSelectedStoreId1(store.storeId);
    setSelectedShelfNum1(undefined);
    setSelectedPhaseNum1(undefined);
  };

  const handleOnChangeShelf1 = (shelf: ShelfOption): void => {
    setSelectedShelfNum1(shelf.shelfNum);
    setSelectedPhaseNum1(undefined);
  };
  const handleOnChangePhase1 = (phase: PhaseOption): void => {
    setSelectedPhaseNum1(phase.phaseNum);
  };

  const handleOnChangeProject2 = (project: ProjectOption): void => {
    setSelectedProjectId2(project.projectId);
    setSelectedStoreId2(undefined);
    setSelectedShelfNum2(undefined);
    setSelectedPhaseNum2(undefined);
  };

  const handleOnChangeStore2 = (store: StoreOption): void => {
    setSelectedStoreId2(store.storeId);
    setSelectedShelfNum2(undefined);
    setSelectedPhaseNum2(undefined);
  };

  const handleOnChangeShelf2 = (shelf: ShelfOption): void => {
    setSelectedShelfNum2(shelf.shelfNum);
    setSelectedPhaseNum2(undefined);
  };
  const handleOnChangePhase2 = (phase: PhaseOption): void => {
    setSelectedPhaseNum2(phase.phaseNum);
  };

  const handleOnChangeGtin1 = (gtin: number): void => {
    setSelectedGtin1(gtin);
  };
  const handleOnChangeGtin2 = (gtin: number): void => {
    setSelectedGtin2(gtin);
  };

  const handleOnChangeBrandId1 = (brandId: number): void => {
    setSelectedBrandId1(brandId);
  };
  const handleOnChangeBrandId2 = (brandId: number): void => {
    setSelectedBrandId2(brandId);
  };

  const handleOnChangeProductBrandFilterId1 = (brandId: number): void => {
    setSelectedProductBrandFilterId1(brandId);
  };
  const handleOnChangeProductBrandFilterId2 = (brandId: number): void => {
    setSelectedProductBrandFilterId2(brandId);
  };
  const handleOnChangeGpcBrickCode1 = (gpcBrickCode: number): void => {
    setSelectedGpcBrickCode1(gpcBrickCode);
  };
  const handleOnChangeGpcBrickCode2 = (gpcBrickCode: number): void => {
    setSelectedGpcBrickCode2(gpcBrickCode);
  };
  const handleOnChangeBenchmarkClient = (clientId: number): void => {
    const clientOption = clientOptions.find(x => x.clientId === clientId);
    setSelectedBenchmarkClientOption(clientOption);
  };
  const handleOnChangeBenchmarkProject = (projectId: number): void => {
    const projectOption = projectOptions.find(x => x.projectId === projectId);
    setSelectedBenchmarkProjectOption(projectOption);
  };

  const isShelf1Selected = (): boolean => {
    return selectedProjectId1 !== undefined && selectedStoreId1 !== undefined && selectedPhaseNum1 !== undefined && selectedShelfNum1 !== undefined;
  };

  const isShelf2Selected = (): boolean => {
    return selectedProjectId2 !== undefined && selectedStoreId2 !== undefined && selectedPhaseNum2 !== undefined && selectedShelfNum2 !== undefined;
  };
  const isExistShelfReport1 = (): boolean => {
    return selectedPhaseShelf1 !== undefined;
  };
  const isExistShelfReport2 = (): boolean => {
    return selectedPhaseShelf2 !== undefined;
  };

  const renderReport1Buttons = (): JSX.Element => {
    if (!isExistShelfReport1()) {
      return <AlertWarningIndicator warningText={'No Standard reports found for this shelf.'} />;
    }
    return (
      <>
        {selectedPhaseShelf1 && (
          <StandardReportButtons
            phaseShelf={selectedPhaseShelf1}
            selectedGtin={selectedGtin1}
            selectedBrandId={selectedBrandId1}
            selectedProductBrandFilterId={selectedProductBrandFilterId1}
            selectedGpcBrickCode={selectedGpcBrickCode1}
            handleOnChangeGtin={(gtin: number): void => handleOnChangeGtin1(gtin)}
            handleOnChangeBrand={(brandId: number): void => handleOnChangeBrandId1(brandId)}
            handleOnChangeGpcBrickCode={(gpcBrickCode: number): void => handleOnChangeGpcBrickCode1(gpcBrickCode)}
            handleOnChangeProductBrandFilter={(brandId: number): void => handleOnChangeProductBrandFilterId1(brandId)}
          />
        )}
      </>
    );
  };
  const renderReport2Buttons = (): JSX.Element => {
    if (!isExistShelfReport2()) {
      return <AlertWarningIndicator warningText={'No Standard reports found for this shelf.'} />;
    }
    return (
      <>
        {selectedPhaseShelf2 && (
          <StandardReportButtons
            phaseShelf={selectedPhaseShelf2}
            selectedGtin={selectedGtin2}
            selectedBrandId={selectedBrandId2}
            selectedProductBrandFilterId={selectedProductBrandFilterId2}
            selectedGpcBrickCode={selectedGpcBrickCode2}
            handleOnChangeGtin={(gtin: number): void => handleOnChangeGtin2(gtin)}
            handleOnChangeBrand={(brandId: number): void => handleOnChangeBrandId2(brandId)}
            handleOnChangeGpcBrickCode={(gpcBrickCode: number): void => handleOnChangeGpcBrickCode2(gpcBrickCode)}
            handleOnChangeProductBrandFilter={(brandId: number): void => handleOnChangeProductBrandFilterId2(brandId)}
          />
        )}
      </>
    );
  };

  const handleOnClickShelfComparisonReport = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    evt.preventDefault();
    const shelfPhaseId1 = `${selectedProjectId1}-${selectedStoreId1}-${selectedPhaseNum1}-${selectedShelfNum1}`;
    const shelfPhaseId2 = `${selectedProjectId2}-${selectedStoreId2}-${selectedPhaseNum2}-${selectedShelfNum2}`;
    window.open(`${window.location.origin}/reports/standardReports/compareReports/shelfComparison/${shelfPhaseId1}/${shelfPhaseId2}`, '_blank');
  };
  const handleOnClickProjectComparisonReport = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    evt.preventDefault();
    const shelfPhaseId1 = `${selectedProjectId1}-${selectedStoreId1}-${selectedPhaseNum1}-${selectedShelfNum1}`;
    const shelfPhaseId2 = `${selectedProjectId2}-${selectedStoreId2}-${selectedPhaseNum2}-${selectedShelfNum2}`;
    window.open(`${window.location.origin}/reports/standardReports/compareReports/projects/${shelfPhaseId1}/${shelfPhaseId2}`, '_blank');
  };
  const handleOnClickShopperComparisonReport = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    evt.preventDefault();
    const shelfPhaseId1 = `${selectedProjectId1}-${selectedStoreId1}-${selectedPhaseNum1}-${selectedShelfNum1}`;
    const shelfPhaseId2 = `${selectedProjectId2}-${selectedStoreId2}-${selectedPhaseNum2}-${selectedShelfNum2}`;
    window.open(`${window.location.origin}/reports/standardReports/compareReports/projects/${shelfPhaseId1}/${shelfPhaseId2}/shoppers`, '_blank');
  };
  const handleOnClickProductComparisonReport = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    evt.preventDefault();
    const shelfPhaseId1 = `${selectedProjectId1}-${selectedStoreId1}-${selectedPhaseNum1}-${selectedShelfNum1}`;
    const shelfPhaseId2 = `${selectedProjectId2}-${selectedStoreId2}-${selectedPhaseNum2}-${selectedShelfNum2}`;
    const gtin1 = selectedGtin1;
    const gtin2 = selectedGtin2;
    window.open(`${window.location.origin}/reports/standardReports/compareReports/projects/${shelfPhaseId1}/${shelfPhaseId2}/products/${gtin1}/${gtin2}`, '_blank');
  };
  const handleOnClickProductBenchmarkComparisonReport = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    evt.preventDefault();
    const shelfPhaseId1 = `${selectedProjectId1}-${selectedStoreId1}-${selectedPhaseNum1}-${selectedShelfNum1}`;
    const shelfPhaseId2 = `${selectedProjectId2}-${selectedStoreId2}-${selectedPhaseNum2}-${selectedShelfNum2}`;
    const gtin = selectedGtin1;
    window.open(`${window.location.origin}/reports/standardReports/projects/${shelfPhaseId1}/${shelfPhaseId2}/products/${gtin}/benchmark`, '_blank');
  };
  const handleOnClickBrandComparisonReport = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    evt.preventDefault();
    const shelfPhaseId1 = `${selectedProjectId1}-${selectedStoreId1}-${selectedPhaseNum1}-${selectedShelfNum1}`;
    const shelfPhaseId2 = `${selectedProjectId2}-${selectedStoreId2}-${selectedPhaseNum2}-${selectedShelfNum2}`;
    const brandId1 = selectedBrandId1;
    const brandId2 = selectedBrandId2;
    window.open(`${window.location.origin}/reports/standardReports/compareReports/projects/${shelfPhaseId1}/${shelfPhaseId2}/brands/${brandId1}/${brandId2}`, '_blank');
  };
  const handleOnClickGpcBrickComparisonReport = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    evt.preventDefault();
    const shelfPhaseId1 = `${selectedProjectId1}-${selectedStoreId1}-${selectedPhaseNum1}-${selectedShelfNum1}`;
    const shelfPhaseId2 = `${selectedProjectId2}-${selectedStoreId2}-${selectedPhaseNum2}-${selectedShelfNum2}`;
    const gpcBrickCode1 = selectedGpcBrickCode1;
    const gpcBrickCode2 = selectedGpcBrickCode2;
    window.open(`${window.location.origin}/reports/standardReports/compareReports/projects/${shelfPhaseId1}/${shelfPhaseId2}/gpcBricks/${gpcBrickCode1}/${gpcBrickCode2}`, '_blank');
  };

  const dataDescriptionsGoogleDocUrl = 'https://docs.google.com/spreadsheets/d/1fBGv1GHnBTW9A9b0Au-DznkRXjNyuWeIhTSKL6DtrEc/edit?usp=sharing';
  return (
    <>
      <Fab size="large" style={{ position: 'fixed', top: '7%', right: '5%', zIndex: 999 }} target="_blank" href={dataDescriptionsGoogleDocUrl}>
        <Tooltip title="Data descriptions for all reports">
          <Description fontSize="large" color="primary" />
        </Tooltip>
      </Fab>
      <Card style={{ margin: '8px', padding: '16px' }}>
        <div style={{ margin: '16px' }}>
          <Typography variant="h5" align="center">
            <Assessment fontSize="large" color="primary" />
            &nbsp;Standard Report Generator
          </Typography>
          <Divider variant="inset" />
        </div>
        <Grid container direction="column" justify="flex-start" alignItems="center" spacing={2}>
          <Card style={{ margin: '8px', padding: '16px', backgroundColor: '#E0FFFF', width: '100%' }}>
            <Typography variant="h5" color="textPrimary" align="left">
              <ListAlt fontSize="large" color="primary" />
              &nbsp;Shelf Reports
            </Typography>
            <Grid item container direction="row" justify="space-around" xs={12} spacing={2}>
              <Grid item container direction="column" justify="flex-start" xs={6}>
                <Card style={{ margin: '8px', padding: '16px', backgroundColor: '#e5e4e2', width: '100%' }}>
                  <Typography variant="h6" color="textPrimary" align="left">
                    <ListAlt fontSize="large" color="primary" />
                    &nbsp;Shelf 1
                  </Typography>
                  <Grid item xs={12} style={{ width: '100%' }}>
                    <StandardReportsShelfSelector
                      phaseShelves={phaseShelves}
                      selectedProjectId={selectedProjectId1}
                      selectedStoreId={selectedStoreId1}
                      selectedShelfNum={selectedShelfNum1}
                      selectedPhaseNum={selectedPhaseNum1}
                      onChangeProject={(project: ProjectOption): void => handleOnChangeProject1(project)}
                      onChangeStore={(store: StoreOption): void => handleOnChangeStore1(store)}
                      onChangeShelf={(shelf: ShelfOption): void => handleOnChangeShelf1(shelf)}
                      onChangePhase={(phase: PhaseOption): void => handleOnChangePhase1(phase)}
                    />
                  </Grid>
                  {isShelf1Selected() ? renderReport1Buttons() : <AlertInfoIndicator infoText={'Select a shelf to view reporting options.'} />}
                </Card>
              </Grid>
              <Grid item container direction="column" justify="flex-start" xs={6}>
                <Card style={{ margin: '8px', padding: '16px', backgroundColor: '#e5e4e2', width: '100%' }}>
                  <Typography variant="h6" color="textPrimary" align="left">
                    <ListAlt fontSize="large" color="primary" />
                    &nbsp;Shelf 2
                  </Typography>
                  <Grid item xs={12} style={{ width: '100%' }}>
                    <StandardReportsShelfSelector
                      phaseShelves={phaseShelves}
                      selectedProjectId={selectedProjectId2}
                      selectedStoreId={selectedStoreId2}
                      selectedShelfNum={selectedShelfNum2}
                      selectedPhaseNum={selectedPhaseNum2}
                      onChangeProject={(project: ProjectOption): void => handleOnChangeProject2(project)}
                      onChangeStore={(store: StoreOption): void => handleOnChangeStore2(store)}
                      onChangeShelf={(shelf: ShelfOption): void => handleOnChangeShelf2(shelf)}
                      onChangePhase={(phase: PhaseOption): void => handleOnChangePhase2(phase)}
                    />
                  </Grid>
                  {isShelf2Selected() ? renderReport2Buttons() : <AlertInfoIndicator infoText={'Select a shelf to view reporting options.'} />}
                </Card>
              </Grid>
            </Grid>
          </Card>
          <Card style={{ margin: '8px', padding: '16px', backgroundColor: '#E0FFFF', width: '100%' }}>
            <Typography variant="h5" color="textPrimary" align="left">
              <Compare fontSize="large" color="primary" />
              &nbsp;Comparison Reports
            </Typography>
            <Card style={{ margin: '8px', padding: '16px', backgroundColor: '#e5e4e2', width: '100%' }}>
              {!isShelf1Selected() || !isShelf2Selected() ? <AlertInfoIndicator infoText={'Select Shelf 1 and Shelf 2 above to view comparison reports.'} /> : null}
              {isShelf1Selected() && isShelf2Selected() ? (
                <Card style={{ margin: '8px', padding: '16px', backgroundColor: 'white', width: '100%' }}>
                  <Grid item container direction="column" justify="flex-start" xs={12} spacing={2}>
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        disabled={!isShelf1Selected() || !isShelf2Selected()}
                        className="text-center"
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={(evt: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => handleOnClickShelfComparisonReport(evt)}
                      >
                        <Compare color="inherit" />
                        &nbsp; Shelf Comparison Report
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        disabled={!isShelf1Selected() || !isShelf2Selected()}
                        className="text-center"
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={(evt: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => handleOnClickProjectComparisonReport(evt)}
                      >
                        <Store color="inherit" />
                        &nbsp; Project Comparison Report
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        disabled={!isShelf1Selected() || !isShelf2Selected()}
                        className="text-center"
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={(evt: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => handleOnClickShopperComparisonReport(evt)}
                      >
                        <Wc color="inherit" />
                        &nbsp; Shopper Comparison Report
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        disabled={!isShelf1Selected() || !isShelf2Selected() || !selectedGpcBrickCode1 || !selectedGpcBrickCode2}
                        className="text-center"
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={(evt: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => handleOnClickGpcBrickComparisonReport(evt)}
                      >
                        <Bookmarks color="inherit" />
                        &nbsp; Gpc Brick Comparison Report
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        disabled={!isShelf1Selected() || !isShelf2Selected() || !selectedBrandId1 || !selectedBrandId2}
                        className="text-center"
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={(evt: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => handleOnClickBrandComparisonReport(evt)}
                      >
                        <Bookmarks color="inherit" />
                        &nbsp; Brand Comparison Report
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        disabled={!isShelf1Selected() || !isShelf2Selected() || !selectedGtin1 || !selectedGtin2}
                        className="text-center"
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={(evt: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => handleOnClickProductComparisonReport(evt)}
                      >
                        <Bookmark color="inherit" />
                        &nbsp; Product Comparison Report
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        disabled={isShelf1Selected() && isShelf2Selected() && !selectedGtin1}
                        className="text-center"
                        size="large"
                        variant="contained"
                        color="primary"
                        onClick={(evt: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => handleOnClickProductBenchmarkComparisonReport(evt)}
                      >
                        <Bookmark color="inherit" />
                        &nbsp; Product Benchmark Comparison Report
                      </Button>
                    </Grid>
                  </Grid>
                </Card>
              ) : null}
            </Card>
          </Card>
          <Card style={{ margin: '8px', padding: '16px', backgroundColor: '#E0FFFF', width: '100%' }}>
            <Typography variant="h5" color="textPrimary" align="left">
              <TrendingUp fontSize="large" color="primary" />
              &nbsp;Benchmark Reports
            </Typography>
            <Card style={{ margin: '8px', padding: '16px', backgroundColor: '#e5e4e2', width: '100%' }}>
              <Card style={{ margin: '8px', padding: '16px', backgroundColor: 'white', width: '100%' }}>
                <Grid item container direction="column" justify="flex-start" xs={12} spacing={2}>
                  <Grid container item direction="row" xs={12} justify="flex-start" spacing={2}>
                    <Grid item xs={6} style={{ width: '100%' }}>
                      <ClientSelector
                        htmlId={`StandardBenchmarkReport_ClientSelector`}
                        selectedClient={selectedBenchmarkClientOption}
                        clientOptions={helpers.getClientOptions(phaseShelves)}
                        handleOnSelectClient={(client: ClientOption): void => {
                          handleOnChangeBenchmarkClient(client.clientId);
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} style={{ width: '100%' }}>
                      {
                        <Button
                          size="large"
                          fullWidth
                          variant="contained"
                          color="primary"
                          disabled={selectedBenchmarkClientOption === undefined}
                          onClick={(): void => {
                            window.open(`/reports/standardReports/clientReports/clients/${selectedBenchmarkClientOption?.clientId}/benchmark`, '_blank');
                          }}
                        >
                          <SupervisedUserCircle color="inherit" />
                          &nbsp; Client Benchmark Report
                        </Button>
                      }
                    </Grid>
                  </Grid>
                  <Grid container item direction="row" xs={12} justify="flex-start" spacing={2}>
                    <Grid item xs={6} style={{ width: '100%' }}>
                      <ProjectSelector
                        htmlId={`StandardBenchmarkReport_ProjectSelector`}
                        selectedProject={selectedBenchmarkProjectOption}
                        projectOptions={helpers.getProjectOptions(phaseShelves)}
                        handleOnSelectProject={(project: ProjectOption): void => {
                          handleOnChangeBenchmarkProject(project.projectId);
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} style={{ width: '100%' }}>
                      {
                        <Button
                          size="large"
                          fullWidth
                          variant="contained"
                          color="primary"
                          disabled={selectedBenchmarkProjectOption === undefined}
                          onClick={(): void => {
                            window.open(`/reports/standardReports/projectReports/projects/${selectedBenchmarkProjectOption?.projectId}/benchmark`, '_blank');
                          }}
                        >
                          <Store color="inherit" />
                          &nbsp; Project Benchmark Report
                        </Button>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Card>
          </Card>

          <CauseEffectReportSelector
            selectedProjectId={props.selectedProjectId1}
            selectedStoreId={props.selectedStoreId1}
            selectedShelf={props.selectedShelfNum1}
            selectedPhase={props.selectedPhaseNum1}
          />
        </Grid>
      </Card>
    </>
  );
};

export { StandardReportsComponent };
