import Select from 'react-select';
import React from 'react';
import { ProductOption } from 'modules/reports/types';

export interface ProductSelectorProps {
  htmlId: string;
  productOptions: ProductOption[];
  selectedProduct?: ProductOption;
  handleOnSelectProduct(selectedProduct: ProductOption): void;
}

export const ProductSelector: React.FC<ProductSelectorProps> = ({ htmlId, productOptions, selectedProduct, handleOnSelectProduct }: ProductSelectorProps) => {
  const defaultProductOption: ProductOption = {
    label: 'Select Product',
    gtin: -1,
    brandId: -1,
    brandName: '',
    tradeItemDescription: '',
    value: -1,
  };
  return (
    <Select
      styles={{
        // Fixes the overlapping problem of the component
        menu: provided => ({ ...provided, zIndex: 9999 }),
      }}
      inputId={htmlId}
      placeholder="Select Product"
      options={productOptions}
      value={selectedProduct === undefined ? defaultProductOption : selectedProduct}
      onChange={(product: ProductOption): void => handleOnSelectProduct(product)}
      isMulti={false}
      isDisabled={productOptions.length === 0}
    />
  );
};
