import React from 'react';

import useHighLightedGtin from '../useHighLightedGtin';
import { getNeedsPrimeLocationGtinArr } from '../../../../../../selectors';
import SelectBtn from '../SelectBtn';

const PrimeLocationBtn: React.FC = (): JSX.Element => {
  const props = useHighLightedGtin(getNeedsPrimeLocationGtinArr, 'PrimeLocationBtn');

  return <SelectBtn {...props} />;
};

export default PrimeLocationBtn;
