import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as selectors from './selectors';

import PlanogramComponent from './PlanogramComponent';

const PlanogramComponentWithHook: React.FC = (): JSX.Element => {
  const viewPort = useSelector(selectors.getViewPort);
  const planogram = useSelector(selectors.getPlanogram);
  const initialPlanogram = useSelector(selectors.getInitialPlanogram);
  const imageViewPort = useSelector(selectors.getImageViewPort);
  const ratio = imageViewPort.calculateRatio(viewPort.getWidth(), viewPort.getHeigth());
  const copyingShelfLocations = useSelector(selectors.getCopyingShelfLocations);
  const dispatch = useDispatch();
  const selectedShelfLocations = useSelector(selectors.getSelectedShelfLocations);
  const notSelectedShelfLocations = useSelector(selectors.getNotSelectedShelfLocations);
  let svgPanZoomValue = useSelector(selectors.getSvgPanZoomValue);
  svgPanZoomValue = {
    ...svgPanZoomValue,
    viewerWidth: viewPort.getWidth(),
    viewerHeight: viewPort.getHeigth(),
  };
  return (
    <PlanogramComponent
      ratio={ratio}
      dispatch={dispatch}
      viewPort={viewPort}
      planogram={planogram}
      imageViewPort={imageViewPort}
      initialPlanogram={initialPlanogram}
      notSelectedShelfLocations={notSelectedShelfLocations}
      selectedShelfLocations={selectedShelfLocations}
      copyingShelfLocations={copyingShelfLocations}
      svgPanZoomValue={svgPanZoomValue}
    />
  );
};

export default PlanogramComponentWithHook;
