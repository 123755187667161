import _ from 'lodash';
import { BrandOption, ProductOption, ClientOption, ProjectOption, GpcBrickOption } from '../types';
import { ProductInPhaseShelf, StandardReportPhaseShelf } from './types';

export function getBrandOptions(products: ProductInPhaseShelf[]): BrandOption[] {
  const brandOptions: BrandOption[] = _.chain(products)
    .groupBy(x => x.brandId)
    .map((value, key) => {
      const brandOption: BrandOption = {
        label: value[0].brandName,
        brandId: parseInt(key),
        brandName: value[0].brandName,
        value: parseInt(key),
      };
      return brandOption;
    })
    .value();
  return brandOptions;
}
export function getGpcBrickOptions(products: ProductInPhaseShelf[]): GpcBrickOption[] {
  const gpcBrickOptions: GpcBrickOption[] = _.chain(products)
    .groupBy(x => x.gpcBrickCode)
    .map((value, key) => {
      const gpcBrickOption: GpcBrickOption = {
        label: value[0].gpcBrickDescription,
        gpcBrickCode: parseInt(key),
        gpcBrickDescription: value[0].gpcBrickDescription,
        value: parseInt(key),
      };
      return gpcBrickOption;
    })
    .value();
  return gpcBrickOptions;
}
export function getProductOptions(products: ProductInPhaseShelf[], brandFilterId: number | undefined): ProductOption[] {
  let productOptions: ProductOption[] = _.chain(products)
    .map(product => {
      const productOption: ProductOption = {
        label: product.tradeItemDescription,
        gtin: product.gtin,
        tradeItemDescription: product.tradeItemDescription,
        brandId: product.brandId ? product.brandId : -1,
        brandName: product.brandName,
        value: product.gtin,
      };
      return productOption;
    })
    .value();
  if (brandFilterId) {
    productOptions = productOptions.filter(x => x.brandId === brandFilterId);
  }
  return productOptions;
}
export function getClientOptions(phaseShelves: StandardReportPhaseShelf[]): ClientOption[] {
  const clientOptions: ClientOption[] = _.orderBy(
    _.uniqBy(phaseShelves, 'clientId').map(shelf => {
      const clientOption: ClientOption = {
        label: shelf.clientName,
        value: shelf.clientId,
        clientId: shelf.clientId,
        clientName: shelf.clientName,
      };
      return clientOption;
    }),
    ['clientName']
  );
  return clientOptions;
}
export function getProjectOptions(phaseShelves: StandardReportPhaseShelf[]): ProjectOption[] {
  const projectOptions: ProjectOption[] = _.orderBy(
    _.uniqBy(phaseShelves, 'projectId').map(shelf => {
      const projectOption: ProjectOption = {
        label: shelf.projectName,
        value: shelf.projectId,
        projectId: shelf.projectId,
        projectName: shelf.projectName,
      };
      return projectOption;
    }),
    ['projectName']
  );
  return projectOptions;
}
