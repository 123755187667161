import { RemoteObjectStatusInterface } from 'infrastructure/utils/RemoteObjectStatus';
import { formatValue, generateDataForShelfLocation } from 'modules/planogram/helpers/reportingHelpers';
import { FlowDataReportResult, FlowDataShelfLocationReportResult, FlowDataShelfLocationsPairRankingsReport } from 'modules/planogram/types/FlowDataReportResult';
import { FlowDataReportType } from 'modules/planogram/types/ReportingTypes';
import { getLeftBottomMostPoint, getLeftTopMostPoint, getLineDistance, getRightTopMostPoint } from 'modules/shared/geometry/helpers';
import Rainbow from 'rainbowvis.js';
import { createSelector, Selector } from 'reselect';
import { State } from 'state';
import { MappedShelfLocation } from '../shared/types';
import RealogramPageState from './state';
import { ProductInfo, RealogramConversation, RealogramViewModel } from './types';

const getState = (state: State): RealogramPageState => state.realogramPageState;

export const realogramLoadingState: Selector<State, RemoteObjectStatusInterface<RealogramViewModel>> = createSelector(getState, state => state.realogramLoadStatus);

export const flowDataLoadingState: Selector<State, RemoteObjectStatusInterface<FlowDataReportResult>> = createSelector(getState, state => state.flowDataLoadStatus);

export const getSelectedFlowDataReportType: Selector<State, FlowDataReportType | undefined> = createSelector(getState, state => state.selectedFlowDataType);

export const getRealogram: Selector<State, RealogramViewModel> = createSelector(getState, state => state.realogramLoadStatus.data as RealogramViewModel);

export const getShelfLocations: Selector<State, MappedShelfLocation[]> = createSelector(getState, getRealogram, (state, realogram) => {
  const shelfLocationIds = realogram.shelfLocations.map(sl => {
    return sl.code;
  });
  const data = generateDataForShelfLocation(shelfLocationIds, state.flowDataLoadStatus.data?.shelfLocationsReport ?? [], state.selectedFlowDataType);
  const rainbow = new Rainbow();
  if (data.canFill) {
    rainbow.setSpectrum(...data.spectrum);
    rainbow.setNumberRange(data.min, data.max);
  }

  return realogram.shelfLocations.map(sl => {
    const topLeft = getLeftTopMostPoint(sl.geometry.points);
    const topRight = getRightTopMostPoint(sl.geometry.points);
    const bottomLeft = getLeftBottomMostPoint(sl.geometry.points);
    const width = getLineDistance(topLeft, topRight);
    const height = getLineDistance(topLeft, bottomLeft);
    const isSelected = state.selectedShelfLocation && state.selectedShelfLocation === sl.id;
    const stroke = isSelected ? 'red' : 'blue';
    const strokeOpacity = isSelected ? 3 : 1;
    let fill = 'transparent';
    let text = '';
    let fillOpacity = 0;

    if (data.values[sl.code] && data.canFill) {
      fill = '#' + rainbow.colourAt(data.values[sl.code]);
      text = formatValue(data.values[sl.code], state.selectedFlowDataType as FlowDataReportType);
      fillOpacity = 0.6;
    }

    return {
      width: `${width * 100}%`,
      height: `${height * 100}%`,
      imageUrl: sl.imageUrl,
      id: sl.code,
      x: `${topLeft.x * 100}%`,
      y: `${(1 - topLeft.y) * 100}%`,
      fill,
      text,
      fillOpacity,
      strokeOpacity,
      stroke,
      tooltip: `${sl.tradeItemDescription} (${sl.gtin})`,
    } as MappedShelfLocation;
  });
});

export const getSelectedShelfLocation: Selector<State, string | undefined> = createSelector(getState, state => state.selectedShelfLocation);

export const getSelectedShelfLocationProductInfo: Selector<State, ProductInfo> = createSelector(getState, getSelectedShelfLocation, (state, selectedShelfLocation) => {
  if (!selectedShelfLocation || !state.realogramLoadStatus.data) {
    throw new Error('Shelf location is required to be selected.');
  }

  const sl = state.realogramLoadStatus.data.shelfLocations.find(row => row.id === selectedShelfLocation);
  if (!sl) {
    throw new Error('Failed to find shelf location.');
  }

  return {
    gtin: sl.gtin,
    productName: sl.productName,
    tradeItemDescription: sl.tradeItemDescription,
    brandName: sl.brandName,
    brandOwner: sl.brandName,
    productImageUrl: sl.imageUrl,
    gpcBrickCode: sl.gpcBrickCode,
    gpcBrickDescription: sl.gpcBrickDescription,
    price: sl.price,
    shelfFacings: sl.shelfFacings,
    shelfLocation: sl.code,
  } as ProductInfo;
});

export const getFlowShelfLocationsDataForSelectedShelfLocation: Selector<State, FlowDataShelfLocationReportResult | undefined> = createSelector(
  getState,
  getSelectedShelfLocationProductInfo,
  (state, productInfo) => {
    if (!state.flowDataLoadStatus.data) {
      return undefined;
    }
    const data = state.flowDataLoadStatus.data.shelfLocationsReport.find(row => row.shelfLocation === productInfo.shelfLocation);
    return data;
  }
);

export const getFlowShelfLocationsPairRankingsDataForSelectedShelfLocation: Selector<State, FlowDataShelfLocationsPairRankingsReport[] | undefined> = createSelector(
  getState,
  getSelectedShelfLocationProductInfo,
  (state, productInfo) => {
    if (!state.flowDataLoadStatus.data) {
      return undefined;
    }
    const data = state.flowDataLoadStatus.data.shelfLocationsPairRankingsReport.filter(row => row.shelfLocationTarget === productInfo.shelfLocation);
    return data;
  }
);
export const getRealogramConversationLoadingState: Selector<State, RemoteObjectStatusInterface<RealogramConversation[]>> = createSelector(getState, state => state.realogramConversationsLoadStatus);

export const getRealogramConversationData: Selector<State, RealogramConversation[]> = createSelector(getRealogramConversationLoadingState, conversationState => {
  if (!conversationState.data) {
    return [] as RealogramConversation[];
  }
  return conversationState.data;
});

export const isOpenConversationPopper: Selector<State, boolean> = createSelector(getState, state => state.isOpenConversationPopper);

export const conversationPopperAnchorEl: Selector<State, Element | undefined> = createSelector(getState, state => state.conversationPopperAnchorEl);

export const postMessageLoadState: Selector<
  State,
  RemoteObjectStatusInterface<{
    shelfReferenceId: string;
    message: string;
    messageId: string;
    userId: number;
    username: string;
    timeOfMessage: Date;
  }>
> = createSelector(getState, state => state.postMessageToConversationLoadStatus);

export const updateMessageLoadState: Selector<State, RemoteObjectStatusInterface<{}>> = createSelector(getState, state => state.updateMessageInConversationLoadStatus);

export const deleteMessageLoadState: Selector<State, RemoteObjectStatusInterface<{}>> = createSelector(getState, state => state.deleteMessageInConversationLoadStatus);
