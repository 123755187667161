import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { UserManager } from 'oidc-client';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import config from 'config';
import { State } from 'state';
import * as actions from '../actions';
import * as constants from '../constants';
import UserManagerInstance from '../helpers/user_manager';
import { OidcUser } from '../types/OidcUser';
import UserLoadError from './UserLoadError';
import { isLoading, isIdle, isError } from 'infrastructure/utils/RemoteObjectStatus';

export default function (ComposedComponent: any) {
  interface MyProps extends DispatchProps, State {
    history: any;
  }

  class AuthProvider extends React.Component<MyProps, any> {
    private readonly userManager: UserManager;

    constructor(props: MyProps, state: any) {
      super(props, state);
      this.userManager = UserManagerInstance;
    }

    componentDidUpdate(prevProps: State): void {
      if (this.props.auth.signoutRequested) {
        if (this.userManager) {
          this.userManager.signoutRedirect().catch(() => {
            // if there is some corruption with the user manager, this catch will execute
            // better to remove user completely and start over
            this.userManager.removeUser().then(() => {
              // and now signout cleanly
              this.userManager.signoutRedirect();
            });
          });
        }
      }
      if (!this.props.auth.isAuthenticated && prevProps.auth.isAuthenticated) {
        if (this.userManager) {
          // was authenticated, but not anymore. Maybe because access token got expired
          // or the server decided not to trust the token anymore.
          this.userManager.removeUser().then(() => {
            this.userManager.signinRedirect({
              state: { redirectPath: window.location.pathname + window.location.search },
            });
          });
        }
      }
    }

    componentDidMount(): void {
      if (config.auth.isMock) {
        const user: OidcUser = {
          profile: {
            email: 'superhacker@flowinsights.com',
            // eslint-disable-next-line @typescript-eslint/camelcase
            family_name: 'hacker',
            name: 'superhacker@flowinsights.com',
            role: ['Admin'],
          },
          state: null,
        };
        this.props.onUserLoaded(user);
        return;
      }
      this.userManager.events.addAccessTokenExpired(() => {
        this.props.notAuthenticated();
      });
      if (window.location.pathname === constants.AUTH_CALLBACK_PATH) {
        this.handleAuthCallback();
        return;
      } else if (window.location.pathname === constants.AUTH_LOGOUT_CLEANUP_PATH) {
        this.handleAuthCleanup();
        return;
      } else {
        this.userManager
          .getUser()
          .then((user: OidcUser | null) => {
            if (user) {
              this.props.onUserLoaded(user);
            } else {
              this.userManager
                .signinRedirect({
                  state: { redirectPath: window.location.pathname + window.location.search },
                })
                .catch((): void => {
                  this.props.onUserLoadedError();
                });
            }
          })
          .catch((): void => {
            this.props.onUserLoadedError();
          });
      }
    }

    handleAuthCallback(): void {
      this.userManager
        .signinRedirectCallback()
        .then((user: OidcUser) => {
          const redirectTo = user.state && user.state.redirectPath ? user.state.redirectPath : '/';
          this.props.onUserLoaded(user);
          this.props.history.push(redirectTo);
        })
        .catch(() => {
          this.props.onUserLoadedError();
        });
    }

    handleAuthCleanup(): void {
      if (this.userManager) {
        this.userManager.removeUser();
      }
    }

    render(): JSX.Element {
      if (isLoading(this.props.auth.loadUserStatus) || isLoading(this.props.auth.loadUserPreferencesStatus) || isIdle(this.props.auth.loadUserPreferencesStatus)) {
        return this.renderLoading();
      }
      if (isError(this.props.auth.loadUserStatus) || isError(this.props.auth.loadUserPreferencesStatus)) {
        return <UserLoadError onLogout={this.props.onLogout} />;
      }
      return <ComposedComponent {...this.props} />;
    }

    private renderLoading(): JSX.Element {
      return (
        <Grid direction="column" alignItems="center" container justify="center" style={{ minHeight: '100vh' }}>
          <Grid>
            <CircularProgress /> &nbsp;&nbsp;
            <Typography variant="overline">Loading your account...</Typography>
          </Grid>
        </Grid>
      );
    }
  }

  interface DispatchProps {
    onUserLoading(): void;
    onUserLoaded(user: OidcUser): void;
    onUserLoadedError(): void;
    onLogout(): void;
    toggleAside(): void;
    notAuthenticated(): void;
  }

  function mapStateToProps(state: State): State {
    return state;
  }
  return connect<State, DispatchProps, RouteComponentProps<any>>(mapStateToProps, actions)(AuthProvider);
}
