import React from 'react';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import InspirationImagesList from './InspirationImagesList';
import HTTPFetchRequest from '../../../../entities/inspirationImages/components/HTTPFetchRequest';

const useStyle = makeStyles({
  grid: {
    minWidth: 300,
    minHeight: 100,
  },
});

interface Props {
  planogramId: string;
}

const InspirationDialogContent: React.FC<Props> = ({ planogramId }: Props): JSX.Element => {
  const { grid: gridClass } = useStyle();
  return (
    <HTTPFetchRequest planogramId={planogramId}>
      {({ data, error, isFetching }): JSX.Element => {
        return (
          <>
            {isFetching && (
              <Grid className={gridClass} container direction="row" justify="center" alignItems="center">
                <CircularProgress />
                <Typography>&nbsp; Loading data...</Typography>
              </Grid>
            )}
            {error && (
              <Grid className={gridClass} container direction="row" justify="center" alignItems="center">
                <Typography color="error">{error}</Typography>
              </Grid>
            )}
            {!isFetching && !error && data.length === 0 && (
              <Grid className={gridClass} container direction="row" justify="center" alignItems="center">
                <Typography color="primary">There are no inspiration images at the moment</Typography>
              </Grid>
            )}
            {!isFetching && !error && data.length > 0 && <InspirationImagesList data={data} />}
          </>
        );
      }}
    </HTTPFetchRequest>
  );
};

export default InspirationDialogContent;
