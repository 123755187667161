import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { State } from 'state';
import * as actions from '../actions';
import { OidcUser } from '../types/OidcUser';

export default function(ComposedComponent: any) {
  class PublicAuthProvider extends React.Component<State & DispatchProps & any, any> {
    render(): JSX.Element {
      return <ComposedComponent {...this.props} />;
    }
  }

  interface DispatchProps {
    onUserLoading(): void;

    onUserLoaded(user: OidcUser): void;

    onLogout(): void;
  }

  function mapStateToProps(state: State): State {
    return state;
  }

  return connect<State, DispatchProps, RouteComponentProps<any>>(mapStateToProps, actions)(PublicAuthProvider);
}
