import * as React from 'react';
import AdminBuilderPlanogramViewModel from '../types/AdminBuilderPlanogramViewModel';
import { AdminBrandPermissionTypeViewModel } from '../types/AdminBrandPermissionTypeViewModel';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  Paper,
  Switch,
  Table,
  TableBody,
  TableHead,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { AdminDataInsightTypeViewModel } from '../types/AdminDataInsightTypeViewModel';
import { StyledTableCell, StyledTableRow } from 'scss/MaterialStyles';
import { Build, Close, Remove, RemoveRedEye, UnfoldLess, UnfoldMore, ZoomOutMap } from '@material-ui/icons';
import { classToClass } from 'class-transformer';
import * as _ from 'lodash';
import trackComponent from 'infrastructure/tracking/withTracking';

interface ViewBuilderRulesModalState {
  selectedBuilderPlanogram: AdminBuilderPlanogramViewModel;
  initialSelectedBuilderPlanogram: AdminBuilderPlanogramViewModel;
}

interface ViewBuilderRulesModalProps {
  selectedBuilderPlanogram: AdminBuilderPlanogramViewModel;
  onClose(): void;
}

class ViewBuilderRulesModal extends React.Component<ViewBuilderRulesModalProps, ViewBuilderRulesModalState> {
  constructor(props: ViewBuilderRulesModalProps, state: ViewBuilderRulesModalState) {
    super(props, state);
    this.state = {
      selectedBuilderPlanogram: classToClass(this.props.selectedBuilderPlanogram),
      initialSelectedBuilderPlanogram: classToClass(this.props.selectedBuilderPlanogram),
    };
  }

  isSelectAllCanView(builderPlanogram: AdminBuilderPlanogramViewModel) {
    return builderPlanogram.actionRules.brandRules.every(br => br.actionsAllowed[AdminBrandPermissionTypeViewModel.canView]);
  }

  isSelectAllCanMove(builderPlanogram: AdminBuilderPlanogramViewModel) {
    return builderPlanogram.actionRules.brandRules.every(br => br.actionsAllowed[AdminBrandPermissionTypeViewModel.canMove]);
  }

  isSelectAllCanDelist(builderPlanogram: AdminBuilderPlanogramViewModel) {
    return builderPlanogram.actionRules.brandRules.every(br => br.actionsAllowed[AdminBrandPermissionTypeViewModel.canDelist]);
  }

  isSelectAllCanIncreaseSize(builderPlanogram: AdminBuilderPlanogramViewModel) {
    return builderPlanogram.actionRules.brandRules.every(br => br.actionsAllowed[AdminBrandPermissionTypeViewModel.canIncreaseSize]);
  }

  isSelectAllCanDecreaseSize(builderPlanogram: AdminBuilderPlanogramViewModel) {
    return builderPlanogram.actionRules.brandRules.every(br => br.actionsAllowed[AdminBrandPermissionTypeViewModel.canDecreaseSize]);
  }

  ifSelectionsAreUnchanged(builderPlanogram: AdminBuilderPlanogramViewModel) {
    const isDataInsightsUnchanged = _.isEqual([...builderPlanogram.dataInsights].sort(), [...this.state.initialSelectedBuilderPlanogram.dataInsights].sort());
    const isBrandRulesUnchanged = _.isEqual([...builderPlanogram.actionRules.brandRules].sort(), [...this.state.initialSelectedBuilderPlanogram.actionRules.brandRules].sort());
    const isPlanogramRulesUnchanged = _.isEqual(builderPlanogram.actionRules.canAddNewShelfLocations, this.state.initialSelectedBuilderPlanogram.actionRules.canAddNewShelfLocations);
    return isDataInsightsUnchanged && isBrandRulesUnchanged && isPlanogramRulesUnchanged;
  }

  render() {
    return this.renderBuilderConfigSection(this.state.selectedBuilderPlanogram);
  }

  renderBuilderConfigSection(selectedBuilderPlanogram: AdminBuilderPlanogramViewModel) {
    return (
      <Dialog maxWidth="xl" open={true} onClose={this.props.onClose}>
        <DialogTitle>
          <Typography variant="overline" gutterBottom>
            <Build color="secondary" />
            &nbsp; View Rules for {selectedBuilderPlanogram.email}
          </Typography>
          <Divider light variant="middle" />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xl={6} xs={6}>
              <FormControl>
                <FormLabel>
                  <Typography variant="overline">Provide insights from</Typography>
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={<Switch readOnly={true} checked={selectedBuilderPlanogram.dataInsights.includes(AdminDataInsightTypeViewModel.FLOW)} color="secondary" value="flow" />}
                    label="Flow Data"
                  />
                  <FormControlLabel
                    control={<Switch readOnly={true} checked={selectedBuilderPlanogram.dataInsights.includes(AdminDataInsightTypeViewModel.SALES)} color="secondary" value="sales" />}
                    label="Sales Data"
                  />
                  <FormControlLabel
                    control={<Switch readOnly={true} color="secondary" checked={selectedBuilderPlanogram.dataInsights.includes(AdminDataInsightTypeViewModel.LOYALTY)} value="loyalty" />}
                    label="Loyalty Data"
                  />
                  <FormHelperText>Data selected above will be used to provide product level insights.</FormHelperText>
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>

          <div className="top-buffer">
            <Typography variant="overline">Planogram rules:</Typography>
          </div>
          <FormControlLabel
            control={<Checkbox readOnly={true} checked={selectedBuilderPlanogram.actionRules.canAddNewShelfLocations} value="checkedB" color="secondary" />}
            label="Can add new product"
          />
          <div className="top-buffer">
            <Typography variant="overline">Brand rules:</Typography>
            {this.renderBrandRulesTable(selectedBuilderPlanogram)}
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={this.props.onClose} className="pull-right">
            <Close />
            &nbsp; Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderBrandRulesTable(builderPlanogram: AdminBuilderPlanogramViewModel) {
    return (
      <Paper>
        <Table size="small" className="sticky-header-table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Brand</StyledTableCell>
              <StyledTableCell align="right">
                <Tooltip title="View" interactive>
                  <RemoveRedEye />
                </Tooltip>
                &nbsp; View
              </StyledTableCell>
              <StyledTableCell align="right">
                <Tooltip title="Move" interactive>
                  <ZoomOutMap />
                </Tooltip>
                &nbsp; Move
              </StyledTableCell>
              <StyledTableCell align="right">
                <Tooltip title="De-list" interactive>
                  <Remove />
                </Tooltip>
                &nbsp; De-list
              </StyledTableCell>
              <StyledTableCell align="right">
                <Tooltip title="Increase share of space" interactive>
                  <UnfoldMore />
                </Tooltip>
                &nbsp; Increase share of space
              </StyledTableCell>
              <StyledTableCell align="right">
                {' '}
                <Tooltip title="Decrease share of space" interactive>
                  <UnfoldLess />
                </Tooltip>
                &nbsp; Decrease share of space
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>{this.renderBrandRulesTableRow(builderPlanogram)}</TableBody>
        </Table>
      </Paper>
    );
  }

  renderBrandRulesTableRow(builderPlanogram: AdminBuilderPlanogramViewModel) {
    return builderPlanogram.actionRules.brandRules.map(brandRule => {
      return (
        <StyledTableRow key={brandRule.brandId}>
          <StyledTableCell component="th" scope="row">
            {brandRule.brandName}
          </StyledTableCell>
          <StyledTableCell align="right">
            <Checkbox readOnly={true} checked={brandRule.actionsAllowed[AdminBrandPermissionTypeViewModel.canView]} value="checkedB" color="secondary" />
          </StyledTableCell>
          <StyledTableCell align="right">
            <Checkbox readOnly={true} checked={brandRule.actionsAllowed[AdminBrandPermissionTypeViewModel.canMove]} value="checkedB" color="secondary" />
          </StyledTableCell>
          <StyledTableCell align="right">
            <Checkbox readOnly={true} checked={brandRule.actionsAllowed[AdminBrandPermissionTypeViewModel.canDelist]} value="checkedB" color="secondary" />
          </StyledTableCell>
          <StyledTableCell align="right">
            <Checkbox readOnly={true} checked={brandRule.actionsAllowed[AdminBrandPermissionTypeViewModel.canIncreaseSize]} value="checkedB" color="secondary" />
          </StyledTableCell>
          <StyledTableCell align="right">
            <Checkbox readOnly={true} checked={brandRule.actionsAllowed[AdminBrandPermissionTypeViewModel.canDecreaseSize]} value="checkedB" color="secondary" />
          </StyledTableCell>
        </StyledTableRow>
      );
    });
  }
}

export default trackComponent(ViewBuilderRulesModal, 'ViewBuilderRulesModal');
