import PlanogramModel from '../domain/PlanogramModel';
import ShelfLocation from '../domain/ShelfLocation';
import { classToClass } from 'class-transformer';
import { Guid } from 'guid-typescript';
import { PlanogramUndoableCommand } from './_planogram_commands';
import PlanogramEventBase from '../events/PlanogramEventBase';

export default class DuplicateNextToCommand extends PlanogramUndoableCommand {
  private planogram: PlanogramModel;
  private shelfLocationToDuplicate: ShelfLocation;
  private newShelfLocation?: ShelfLocation;

  constructor(planogram: PlanogramModel, shelfLocationToDuplicate: ShelfLocation) {
    super();
    this.planogram = planogram;
    this.shelfLocationToDuplicate = shelfLocationToDuplicate;
  }

  execute(): PlanogramEventBase {
    const shelfLocation = this.shelfLocationToDuplicate;
    const newShelfLocation = classToClass(shelfLocation);
    newShelfLocation.geometry.calculatePointsPosition();
    newShelfLocation.id = Guid.create().toString();
    const width = newShelfLocation.geometry.currentWidth;
    newShelfLocation.geometry.points.forEach(p => {
      p.x += width;
    });
    this.newShelfLocation = newShelfLocation;
    return this.planogram.addShelfLocations([newShelfLocation]);
  }

  undo(): PlanogramEventBase {
    if (this.newShelfLocation) {
      return this.planogram.deleteShelfLocations([this.newShelfLocation]);
    }
    throw new Error('No shelf location selected');
  }
}
