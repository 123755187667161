/* eslint-disable @typescript-eslint/no-explicit-any */
import ErrorDetails from 'infrastructure/exception/ErrorDetails';
import { ClientOption, ProjectOption } from 'modules/reports/types';
import moment from 'moment';
import { Reducer } from 'react';
import { actions } from 'react-redux-toastr';
import { ActionType } from 'typesafe-actions';
import { LoadState } from '../shared/types';
import { RemoteObjectStatusInterface, setError, setLoading, setSuccess } from './../../infrastructure/utils/RemoteObjectStatus';
import {
  CLOSE_TIMELINES_MENU,
  ON_SELECT_CLIENT_IN_TIMELINE,
  ON_SELECT_PROJECT_IN_TIMELINE,
  OPEN_TIMELINES_MENU,
  TIMELINES_LOAD_FAILURE,
  TIMELINES_LOAD_REQUEST_IN_CURRENT_YEAR,
  TIMELINES_LOAD_REQUEST_IN_DATE_RANGE,
  TIMELINES_LOAD_SUCCESS,
} from './actions';
import { ShelfPhaseDetails, TimelinesData, TimelinesState } from './types';

type Actions = ActionType<typeof actions>;

export const InitialState: TimelinesState = {
  timelinesObject: {
    loadStatus: LoadState.NOT_LOADED,
    data: {} as TimelinesData,
    errorDetails: {} as ErrorDetails,
  } as RemoteObjectStatusInterface<TimelinesData>,
  isTimelinesMenuOpen: false,
  timelinesMenuAnchorEl: undefined,
  selectedShelfPhase: undefined,
  startDateOfTimeline: moment().startOf('year').format('YYYY-MM-DD'),
  endDateOfTimeline: moment().endOf('year').format('YYYY-MM-DD'),
  selectedClientOption: undefined,
};

// TIMELINES OBJECT LOAD HANDLERS
function loadTimelinesRequestInCurrentYearHandler(state: TimelinesState): TimelinesState {
  return {
    ...state,
    timelinesObject: setLoading(),
    isTimelinesMenuOpen: false,
    timelinesMenuAnchorEl: undefined,
  };
}
function loadTimelinesRequestInDateRangeHandler(state: TimelinesState, startDate: string, endDate: string): TimelinesState {
  return {
    ...state,
    timelinesObject: setLoading(),
    isTimelinesMenuOpen: false,
    timelinesMenuAnchorEl: undefined,
    startDateOfTimeline: startDate,
    endDateOfTimeline: endDate,
  };
}

function loadTimelinesSuccessHandler(state: TimelinesState, data: TimelinesData): TimelinesState {
  return { ...state, timelinesObject: setSuccess(data) };
}

function loadTimelinesFailureHandler(state: TimelinesState, error: ErrorDetails): TimelinesState {
  return { ...state, timelinesObject: setError(error) };
}

function openTimelinesMenuPopperHandler(state: TimelinesState, anchorEl: Element, selectedShelfPhase: ShelfPhaseDetails): TimelinesState {
  return {
    ...state,
    isTimelinesMenuOpen: true,
    timelinesMenuAnchorEl: anchorEl,
    selectedShelfPhase,
  };
}

function closeTimelinesMenuPopperHandler(state: TimelinesState): TimelinesState {
  return { ...state, isTimelinesMenuOpen: false, timelinesMenuAnchorEl: undefined, selectedShelfPhase: undefined };
}

function onSelectClientInTimelineHandler(state: TimelinesState, selectedClientOption: ClientOption): TimelinesState {
  const selectedOption = selectedClientOption.value > 0 ? selectedClientOption : undefined;
  // const defaultProjectOption = { value: -1, label: 'ALL', projectId: -1, projectName: 'ALL' } as ProjectOption;
  return { ...state, selectedClientOption: selectedOption, selectedProjectOption: undefined };
}
function onSelectProjectInTimelineHandler(state: TimelinesState, selectedProjectOption: ProjectOption): TimelinesState {
  const selectedOption = selectedProjectOption.value > 0 ? selectedProjectOption : undefined;
  return { ...state, selectedProjectOption: selectedOption };
}
export const timelinesReducer: Reducer<TimelinesState, Actions> = (state: TimelinesState = InitialState, action: any) => {
  switch (action.type) {
    case TIMELINES_LOAD_REQUEST_IN_DATE_RANGE:
      return loadTimelinesRequestInDateRangeHandler(state, action.startDate, action.endDate);
    case TIMELINES_LOAD_REQUEST_IN_CURRENT_YEAR:
      return loadTimelinesRequestInCurrentYearHandler(state);
    case TIMELINES_LOAD_SUCCESS:
      return loadTimelinesSuccessHandler(state, action.data);
    case TIMELINES_LOAD_FAILURE:
      return loadTimelinesFailureHandler(state, action.error);
    case OPEN_TIMELINES_MENU:
      return openTimelinesMenuPopperHandler(state, action.anchorEl, action.selectedShelfPhase);
    case CLOSE_TIMELINES_MENU:
      return closeTimelinesMenuPopperHandler(state);
    case ON_SELECT_CLIENT_IN_TIMELINE:
      return onSelectClientInTimelineHandler(state, action.selectedClientOption);
    case ON_SELECT_PROJECT_IN_TIMELINE:
      return onSelectProjectInTimelineHandler(state, action.selectedProjectOption);
  }
  return state;
};
