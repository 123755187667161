import { Badge, Card, CardContent, CardHeader, Collapse, IconButton, TextField, Typography } from '@material-ui/core';
import { Cached, ExpandLess, ExpandMore } from '@material-ui/icons';
import * as React from 'react';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import trackComponent from 'infrastructure/tracking/withTracking';
import { ProductResizeReason, RecordChangeReasonDto } from 'modules/planogram/types/PlanogramChangeReasons';
import PlanogramModel from 'modules/planogram/domain/PlanogramModel';

interface MyProps {
  currentPlanogram: PlanogramModel;
  onHighlightProduct(priority: boolean, gtin?: number[]): void;
  onRecordChangeReason(reason: RecordChangeReasonDto): void;
}

interface MyState {
  isExpandedProductChangesHeader: boolean;
  reasons: ResizeProductReason[];
}

interface ResizeProductReason {
  gtin: number;
  reason: string;
}

class ResizedProducts extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {
      isExpandedProductChangesHeader: false,
      reasons: [],
    };
  }

  componentDidUpdate() {
    const reasons = this.state.reasons;
    const resizedProducts = this.props.currentPlanogram.planogramChanges.productsResized;
    let needsUpdate = false;
    resizedProducts.forEach(resizedProduct => {
      let existingReason = reasons.find(r => r.gtin === resizedProduct.gtin);
      if (!existingReason) {
        const savedReason = this.props.currentPlanogram.changeReasons.productResizeReasons.find(newP => newP.gtin === resizedProduct.gtin);
        existingReason = {
          gtin: resizedProduct.gtin,
          reason: savedReason ? savedReason.reason : '',
        };
        needsUpdate = true;
        reasons.push(existingReason);
      }
    });
    if (needsUpdate) {
      this.setState({ reasons });
    }
  }

  render() {
    const resizedProducts = this.props.currentPlanogram.planogramChanges.productsResized;
    const rows = resizedProducts.map(d => {
      const changeReason = this.state.reasons.find(r => r.gtin === d.gtin);
      if (!changeReason) {
        return null;
      }
      return (
        <div
          key={d.gtin}
          onMouseEnter={() => {
            this.props.onHighlightProduct(false, [d.gtin]);
          }}
          onMouseLeave={() => {
            this.props.onHighlightProduct(false, undefined);
          }}
        >
          <div>
            <li>
              <Typography color="textPrimary" variant="body2">
                {d.tradeItemDescription}
              </Typography>
            </li>
          </div>
          <div>
            <Typography variant="caption" color="textSecondary">
              Gtin: {d.gtin}
            </Typography>
          </div>
          <div>
            <Typography variant="caption" color="textSecondary">
              Initial size: {d.initialSpace.toFixed(2)} m<sup>2</sup>
            </Typography>
          </div>
          <div>
            <Typography variant="caption" color="textSecondary">
              Current size: {d.currentSpace.toFixed(2)} m<sup>2</sup>
            </Typography>
          </div>
          {!this.props.currentPlanogram.isReadOnly ? this.renderReasonTextfield(changeReason, d.gtin, d.initialSpace, d.currentSpace) : this.renderReadOnlyReason(changeReason.reason)}
        </div>
      );
    });
    return (
      <Row>
        <Col xs="12">
          <Card raised className="card-accent-info border-info bottom-buffer">
            <CardHeader
              style={{ background: 'rgb(102, 178, 255,0.2)' }}
              disableTypography={true}
              action={
                <div>
                  <Badge color="secondary" badgeContent={rows.length}>
                    <Cached color="primary" />
                  </Badge>
                  <IconButton onClick={() => this.setState({ isExpandedProductChangesHeader: !this.state.isExpandedProductChangesHeader })}>
                    {this.state.isExpandedProductChangesHeader ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
                  </IconButton>
                </div>
              }
              title={<Typography variant="button">Product Share Of Space</Typography>}
            />
            <Collapse in={this.state.isExpandedProductChangesHeader}>
              <CardContent style={{ marginLeft: '0px', paddingLeft: '0px' }}>
                {resizedProducts.length === 0 ? (
                  <div className="left-buffer">
                    <Typography color="textSecondary" variant="caption">
                      No changes made.
                    </Typography>
                  </div>
                ) : (
                  <div>
                    <ol>{rows}</ol>
                  </div>
                )}
              </CardContent>
            </Collapse>
          </Card>
        </Col>
      </Row>
    );
  }
  renderReadOnlyReason(reason: string): React.ReactNode {
    return (
      <Typography variant="caption" color="textSecondary">
        Reason for change: <strong>{reason ? reason : 'N/A'}</strong>
      </Typography>
    );
  }

  private renderReasonTextfield(changeReason: ResizeProductReason, gtin: number, initialSize: number, currentSize: number) {
    return (
      <div>
        <TextField
          value={changeReason.reason}
          onChange={evt => {
            const theReason = evt.target.value ? evt.target.value : '';
            changeReason.reason = theReason;
            this.setState({ reasons: this.state.reasons });
          }}
          id="standard-name"
          label="Reason for change"
          margin="none"
          onFocus={() => {
            this.props.onHighlightProduct(true, [gtin]);
          }}
          onBlur={() => {
            this.props.onHighlightProduct(true, undefined);
            const existingReason = this.props.currentPlanogram.changeReasons.newProductReasons.find(r => r.gtin === gtin);
            changeReason.reason = changeReason.reason ? changeReason.reason.trim() : '';
            if (existingReason && existingReason.reason === changeReason.reason) {
              return;
            }
            const reason = new RecordChangeReasonDto(this.props.currentPlanogram.id);
            const resizedProductReason = new ProductResizeReason(gtin, initialSize, currentSize, changeReason.reason);
            reason.productResizeReason = resizedProductReason;
            this.props.onRecordChangeReason(reason);
          }}
        />
      </div>
    );
  }
}

export default trackComponent(ResizedProducts, 'Aside_ResizedProducts');
