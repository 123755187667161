import React from 'react';
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { ZoomIn, ZoomOut, SettingsBackupRestore } from '@material-ui/icons';

const useStyle = makeStyles({
  iconBtn: {
    '&:hover': {
      background: 'none',
    },
    '&:focus': {
      outline: 'none',
    },
    background: 'none',
  },
});

export interface ZoomControlsProps {
  zoomIn: () => void;
  zoomOut: () => void;
  reset: () => void;
}

const ZoomControls: React.FC<ZoomControlsProps> = ({ zoomIn, zoomOut, reset }: ZoomControlsProps): JSX.Element => {
  const muiClass = useStyle();
  return (
    <>
      <Tooltip title="Zoom Out">
        <IconButton disableRipple className={muiClass.iconBtn} size="small" onClick={zoomOut} aria-label="zoomIn">
          <ZoomOut />
        </IconButton>
      </Tooltip>
      <Tooltip title="Reset View">
        <IconButton onClick={reset} className={muiClass.iconBtn}>
          <SettingsBackupRestore />
        </IconButton>
      </Tooltip>
      <Tooltip title="Zoom In">
        <IconButton disableRipple className={muiClass.iconBtn} size="small" onClick={zoomIn} aria-label="zoomIn">
          <ZoomIn />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default ZoomControls;
