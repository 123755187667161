import React from 'react';
import Select from 'react-select';
import { ProjectOption } from '../../../reports/types';

export interface ProjectSelectorProps {
  htmlId: string;
  projectOptions: ProjectOption[];
  selectedProject?: ProjectOption;
  handleOnSelectProject(selectedProject: ProjectOption): void;
}

export const ProjectSelector: React.FC<ProjectSelectorProps> = ({ htmlId, projectOptions, selectedProject, handleOnSelectProject }: ProjectSelectorProps) => {
  const defaultProjectOption: ProjectOption = {
    label: 'Select Project',
    projectId: -1,
    projectName: 'Select Project',
    value: -1,
  };
  return (
    <Select
      styles={{
        // Fixes the overlapping problem of the component
        menu: provided => ({ ...provided, zIndex: 9999 }),
      }}
      inputId={htmlId}
      placeholder="Select Project"
      options={projectOptions}
      value={selectedProject === undefined ? defaultProjectOption : selectedProject}
      onChange={(project: ProjectOption): void => handleOnSelectProject(project)}
      isMulti={false}
      isDisabled={projectOptions.length === 0}
    />
  );
};
