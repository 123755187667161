import { isLoaded, isLoading } from 'infrastructure/utils/RemoteObjectStatus';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadPhaseShelvesRequestAction } from '../actions';
import * as selectors from '../selectors';
interface LoadStatusParams {
  isLoaded: boolean;
  isLoading: boolean;
  loadError: string;
  isDataEmpty: boolean;
}
export interface PhaseShelvesLoaderProps {
  children: (params: LoadStatusParams) => React.ReactNode;
}

const PhaseShelvesLoader: React.FC<PhaseShelvesLoaderProps> = ({ children }: PhaseShelvesLoaderProps) => {
  const dispatch = useDispatch();
  const loadState = useSelector(selectors.getLoadState);
  const isDataEmpty = useSelector(selectors.isDataEmpty);

  useEffect(() => {
    if (isDataEmpty) {
      dispatch(loadPhaseShelvesRequestAction());
    }
  }, [dispatch, isDataEmpty]);
  return (
    <>
      {children({
        isLoaded: isLoaded(loadState),
        isLoading: isLoading(loadState),
        loadError: loadState?.errorDetails?.errorMessage ?? '',
        isDataEmpty,
      })}
    </>
  );
};

export default PhaseShelvesLoader;
