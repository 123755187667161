import React from 'react';
import { Alert } from 'reactstrap';
import { Typography } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
export interface AlertErrorTextIndicatorProps {
  errorText?: string;
  subErrorText?: string;
}

export const AlertErrorTextIndicator: React.FC<AlertErrorTextIndicatorProps> = ({ errorText, subErrorText }: AlertErrorTextIndicatorProps) => {
  return (
    <Alert className="top-buffer" color="danger">
      <Typography variant="caption" color="textSecondary">
        <ErrorOutline color="secondary" fontSize="small" />
        &nbsp;{errorText}
      </Typography>

      {subErrorText && (
        <Typography variant="caption" color="textSecondary">
          &nbsp;
          {subErrorText}
        </Typography>
      )}
    </Alert>
  );
};
