export enum FlowDataReportType {
  ProductPickupCount = 'Product pickup count',
  ProductReturnCount = 'Product return count',
  ProductTouchCount = 'Product touch count',
  ProductInteractionCount = 'Product interaction count',
  ProductFirstPickupsCount = 'Product first pickups count',
  ProductPurchaseCount = 'Product purchase count',
  ProductBuyersCount = 'Product buyers count',
  ProductReturnRatio = 'Product return ratio',
  ProductTurnover = 'Product turnover',
  ProductConversionRate = 'Product conversion rate',
  ProductDestinationBuyersCount = 'Product destination buyers count',
  ProductDestinationBuyersShare = 'Product destination buyers share',
  ProductLostShoppersCount = 'Product lost shoppers count',
  ProductCurrentShareOfSpace = 'Product share of space',
  ProductShareOfUnitSales = 'Product share of unit sales',
  ProductSalesPerformance = 'Product sales performance',
  ProductShareOfTurnover = 'Product share of turnover',
  ProductTurnoverPerformance = 'Product turnover performance',
  ProductOosHoursPerDay = 'Product OOS hours per day',
  ProductLostDailySalesOutOfStock = 'Product lost daily sales out of stock',
  ProductLostDailyTurnoverOutOfStock = 'Product lost daily turnover out of stock',
  ProductDiscoveryTime = 'Product discovery time',
  ProductSecondsToBuyAndLeave = 'Product seconds to buy and leave',
  ProductSecondsToTouchAndReject = 'Product seconds to touch and reject',

  ShelfLocationPickupCount = 'ShelfLocation pickup count',
  ShelfLocationReturnCount = 'ShelfLocation return count',
  ShelfLocationTouchCount = 'ShelfLocation touch count',
  ShelfLocationInteractionCount = 'ShelfLocation interaction count',
  ShelfLocationFirstPickupsCount = 'ShelfLocation first pickups count',
  ShelfLocationPurchaseCount = 'ShelfLocation purchase count',
  ShelfLocationBuyersCount = 'ShelfLocation buyers count',
  ShelfLocationReturnRatio = 'ShelfLocation return ratio',
  ShelfLocationTurnover = 'ShelfLocation turnover',
  ShelfLocationConversionRate = 'ShelfLocation conversion rate',
  ShelfLocationCurrentShareOfSpace = 'ShelfLocation share of space',
  ShelfLocationShareOfUnitSales = 'ShelfLocation share of unit sales',
  ShelfLocationSalesPerformance = 'ShelfLocation sales performance',
  ShelfLocationShareOfTurnover = 'ShelfLocation share of turnover',
  ShelfLocationTurnoverPerformance = 'ShelfLocation turnover performance',
  ShelfLocationOosHoursPerDay = 'ShelfLocation OOS hours per day',
  ShelfLocationDiscoveryTime = 'ShelfLocation discovery time',
  ShelfLocationSecondsToBuyAndLeave = 'ShelfLocation seconds to buy and leave',
  ShelfLocationSecondsToTouchAndReject = 'ShelfLocation seconds to touch and reject',
}
