import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormGroup, LinearProgress, TextField, Tooltip, Typography } from '@material-ui/core';
import { AttachFile, Close, CloudUpload, InsertDriveFile } from '@material-ui/icons';
import trackComponent from 'infrastructure/tracking/withTracking';
import { isLoading, RemoteObjectStatusInterface } from 'infrastructure/utils/RemoteObjectStatus';
import { DataItem } from 'modules/admin/clientDataset/types/ClientDatasetDto';
import React, { Component } from 'react';
import { toastr } from 'react-redux-toastr';
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';
import * as XLSX from 'xlsx';
import CompetitorShelf from '../../types/CompetitorShelf';
import CompetitorShelfDataset from '../../types/CompetitorShelfDataset';
import { SalesDataModel } from 'modules/planogram/types/SalesDataModel';

interface UploadCompetitorShelfDatasetModalState {
  dataset: CompetitorShelfDataset;
  selectedExcelFile: File | null;
}
class UploadCompetitorShelfDatasetModal extends Component<UploadCompetitorShelfDatasetModalProps, UploadCompetitorShelfDatasetModalState> {
  constructor(props: UploadCompetitorShelfDatasetModalProps, state: UploadCompetitorShelfDatasetModalState) {
    super(props, state);

    this.state = {
      dataset: new CompetitorShelfDataset(),
      selectedExcelFile: null,
    };
  }

  generateExcelTemplate(): void {
    // TODO: pre-fill excell gtins from shelf gtins
    const wb = XLSX.utils.book_new();
    wb.SheetNames.push('Sheet 1');
    const opts = {
      header: ['GTIN', 'Value'],
    };

    const data = [
      {
        GTIN: '1',
        Value: '100',
      },
    ];

    const ws = XLSX.utils.json_to_sheet(data, opts);
    wb.Sheets['Sheet 1'] = ws;
    XLSX.writeFile(wb, `${this.props.selectedShelf.storeName} Dataset.xlsx`);
  }

  onSelectFile(event: React.ChangeEvent<HTMLInputElement>): void {
    if (event && event.target && event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      if (file.name.includes('.xls') || file.name.includes('.xlsx') || file.name.includes('.csv')) {
        this.setState({
          selectedExcelFile: file,
        });
      } else {
        toastr.error('File not permitted.', 'Please select CSV, XLS or XLSX files only');
      }
    }
  }

  hasEnteredRequiredInputs(): boolean {
    return this.state.dataset.datasetName !== '' && this.state.selectedExcelFile !== null;
  }

  onUploadDataset(): void {
    if (this.state.selectedExcelFile === null) {
      toastr.error('Please select a file', '');
      return;
    }
    const shelfDataset = this.state.dataset;
    shelfDataset.planogramId = this.props.planogramId;
    shelfDataset.shelfReferenceId = this.props.selectedShelf.referenceId;

    const reader = new FileReader();
    reader.onload = (): void => {
      if (!reader.result) {
        toastr.error("Can't read filed", 'Please try again.');
        return;
      }
      const data = new Uint8Array(reader.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const dataXlsx = XLSX.utils.sheet_to_json(sheet);
      const dataset: SalesDataModel[] = [];
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dataXlsx.forEach((cd: any): void => {
          const keys = Object.keys(cd);
          const dataItem = new DataItem();
          dataItem.gtin = cd[keys[0]];
          dataItem.value = cd[keys[1]];
          if (dataItem.gtin === undefined || dataItem.value === undefined) {
            // if empty value ignore
            return;
          }
          if (typeof dataItem.gtin === 'string' || typeof dataItem.value === 'string') {
            // if string, throw error
            throw new Error('string detected');
          }
          dataset.push(dataItem);
        });
        shelfDataset.items = dataset;
        this.props.uploadDataset(shelfDataset);
      } catch (err) {
        toastr.error('Incorrect data format', 'String data detected, make sure excel file has data in number format');
      }
    };
    reader.readAsArrayBuffer(this.state.selectedExcelFile);
  }

  render(): JSX.Element {
    const loading = isLoading(this.props.uploadCompetitorShelfDatasetState);
    return (
      <Row>
        <Col xs="12">
          <Dialog fullWidth open={true} onClose={this.props.onClose}>
            <DialogTitle>
              <Typography variant="overline" gutterBottom>
                <CloudUpload color="primary" />
                &nbsp; Upload Dataset
              </Typography>
              <Divider light variant="middle" />
            </DialogTitle>

            <DialogContent style={{ paddingBottom: '200px' }}>
              <FormGroup>
                <Tooltip title="Generate sample excep file in expected format">
                  <div>
                    <Button size="small" variant="outlined" color="secondary" onClick={(): void => this.generateExcelTemplate()}>
                      <InsertDriveFile />
                      &nbsp;Generate Excel Template
                    </Button>
                  </div>
                </Tooltip>
              </FormGroup>
              <FormGroup className="top-buffer">
                <TextField
                  autoFocus={true}
                  fullWidth={true}
                  variant="outlined"
                  label="Dataset Name"
                  disabled={loading}
                  required
                  value={this.state.dataset.datasetName}
                  onChange={(evt): void => this.setState({ dataset: { ...this.state.dataset, datasetName: evt.target.value } })}
                />
              </FormGroup>
              <FormGroup className="top-buffer">
                <input accept=".xlsx, .xls, .csv" style={{ display: 'none' }} id="selectExcelFileBtn" type="file" onChange={(e): void => this.onSelectFile(e)} />
                <label htmlFor="selectExcelFileBtn">
                  <Tooltip title="Choose an excel or csv file">
                    <Button fullWidth variant="contained" color="primary" component="span">
                      <AttachFile />
                      &nbsp; Select excel file
                    </Button>
                  </Tooltip>
                </label>
                {this.state.selectedExcelFile ? 'Selected file: ' + this.state.selectedExcelFile.name : 'No file chosen.'}
              </FormGroup>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                disabled={loading || !this.hasEnteredRequiredInputs()}
                className="pull-left"
                onClick={(): void => {
                  this.onUploadDataset();
                }}
              >
                <CloudUpload />
                &nbsp;
                {loading ? 'Uploading...' : 'Upload'}
              </Button>
              <Button variant="contained" color="secondary" onClick={this.props.onClose} className="pull-right">
                <Close />
                &nbsp; Cancel
              </Button>
            </DialogActions>
            {loading ? <LinearProgress color="primary" /> : null}
          </Dialog>
        </Col>
      </Row>
    );
  }
}
interface UploadCompetitorShelfDatasetModalProps {
  planogramId: string;
  selectedShelf: CompetitorShelf;
  uploadCompetitorShelfDatasetState: RemoteObjectStatusInterface<{}>;

  onClose(): void;
  uploadDataset(dataset: CompetitorShelfDataset): void;
}

export default trackComponent(UploadCompetitorShelfDatasetModal, 'UploadCompetitorShelfDatasetModal');
