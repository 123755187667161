import * as React from 'react';
import { RouteProps, useParams } from 'react-router';
import LoadRealogram from './LoadRealogram';
import { Grid, CircularProgress, Typography } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import { Alert } from 'reactstrap';
import RealogramComponent from './RealogramComponent';

const RealogramPage: React.FC<RouteProps> = (): JSX.Element => {
  const { shelfReferenceId } = useParams<{ shelfReferenceId: string }>();
  return (
    <LoadRealogram realogramId={shelfReferenceId}>
      {({ isLoaded, isLoading, isError, errorMessage }): React.ReactNode => {
        return (
          <>
            {isLoading && (
              <Grid style={{ marginTop: '2%' }} container direction="row" justify="center" alignItems="center">
                <CircularProgress /> &nbsp;&nbsp;
                <Typography variant="overline">Loading realogram...</Typography>
              </Grid>
            )}
            {isLoaded && <RealogramComponent />}
            {isError && (
              <div>
                <Alert className="top-buffer" color="danger">
                  <Typography variant="caption" color="textSecondary">
                    <ErrorOutline color="secondary" fontSize="small" />
                    &nbsp;
                    <strong> {errorMessage}</strong>
                  </Typography>
                </Alert>
              </div>
            )}
          </>
        );
      }}
    </LoadRealogram>
  );
};

export default RealogramPage;
