import * as React from 'react';
import { RouteProps } from 'react-router';
import { useSelector } from 'react-redux';
import * as selectors from '../selectors';
import { isIdle, isLoaded, isLoading, isError } from 'infrastructure/utils/RemoteObjectStatus';
import { HorizontalLoadIndicator } from 'modules/shared/components/LoadStatusIndicators/CircularLoadIndicator';
import { AlertErrorTextIndicator } from 'modules/shared/components/LoadStatusIndicators/AlertErrorTextIndicator';

const RealogramPageHeader: React.FC<RouteProps> = (): JSX.Element | null => {
  const loadState = useSelector(selectors.flowDataLoadingState);

  return isIdle(loadState) || isLoaded(loadState) ? null : (
    <>
      {isLoading(loadState) && <HorizontalLoadIndicator loadingText="Loading reports" />}
      {isError(loadState) && <AlertErrorTextIndicator errorText="Failed to load reports" />}
    </>
  );
};

export default RealogramPageHeader;
