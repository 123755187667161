import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Typography } from '@material-ui/core';
import { Build } from '@material-ui/icons';

export interface EditBuilderRulesDialogProps {
  children: React.ReactNode;
  actionsContent: React.ReactNode;
  onClose: () => void;
  email: string;
}

const EditBuilderRulesDialog: React.FC<EditBuilderRulesDialogProps> = ({ children, actionsContent, onClose, email }: EditBuilderRulesDialogProps): JSX.Element => {
  return (
    <Dialog maxWidth="xl" open={true} onClose={onClose}>
      <DialogTitle>
        <Typography variant="overline" gutterBottom>
          <Build color="secondary" />
          &nbsp; Edit Rules for {email}
        </Typography>
        <Divider light variant="middle" />
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>{actionsContent}</DialogActions>
    </Dialog>
  );
};

export default EditBuilderRulesDialog;
