import { plainToClass } from 'class-transformer';
import { all, put, takeLatest } from 'redux-saga/effects';
import ErrorDetails from 'infrastructure/exception/ErrorDetails';
import apiClient from 'utils/apiClient';
import apiSaga from 'utils/apiSaga';
import * as actions from './actions';
import * as constants from './constants';
import { UserPreferencesDto } from './types/UserPreferencesDto';

function loadUserPreferencesRequest() {
  return apiClient.get('/users/userPreferences');
}

export function* loadUserPreferences() {
  try {
    const response = yield apiSaga(loadUserPreferencesRequest);
    const userPreferences = plainToClass(UserPreferencesDto, response.data as UserPreferencesDto);
    yield put(actions.loadUserPreferencesSuccess(userPreferences));
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    console.log('Failed to load user preferences', errorDetails);
    yield put(actions.loadUserPreferencesFailed());
  }
}

export function* userLoaded() {
  yield put(actions.loadUserPreferencesRequest());
}

export default function* root() {
  yield all([takeLatest(constants.LOAD_USER_PREFERENCES_REQUEST, loadUserPreferences), takeLatest(constants.USER_LOADED, userLoaded)]);
}
