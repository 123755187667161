import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Table, TableBody, TableHead, Tooltip, Typography } from '@material-ui/core';
import { ArrowDropDown, ArrowDropUp, Close, Flag, Info, Launch } from '@material-ui/icons';
import { orderBy } from 'lodash';
import * as React from 'react';
import { Alert, Row } from 'reactstrap';
import trackComponent from '../../../../infrastructure/tracking/withTracking';
import { StyledTableCell, StyledTableRow } from '../../../../scss/MaterialStyles';
import { getDateInInputFormat } from '../../../../utils/dateHelper';
import TestInfoDto from '../../../planogram/types/TestInfoDto';

interface TestListModalProps {
  planogramName: string;
  testInfoList: TestInfoDto[];
  goal: string;

  onClose(): void;
}

interface TestListModalState {
  testInfoList: TestInfoDto[];
  sortByProperty: string;
  sortByDescending: boolean;
}

class TestListModal extends React.Component<TestListModalProps, TestListModalState> {
  constructor(props: TestListModalProps, state: TestListModalState) {
    super(props, state);
    this.state = {
      testInfoList: this.props.testInfoList,
      sortByDescending: true,
      sortByProperty: 'createdOn',
    };
  }

  changeOrderBy(propertyName: string): void {
    const descending = this.state.sortByProperty === propertyName ? !this.state.sortByDescending : false;
    this.setState({
      sortByProperty: propertyName,
      sortByDescending: descending,
    });
  }

  getOrderByIcon(propertyName: string): JSX.Element | null {
    if (this.state.sortByProperty !== propertyName) {
      return null;
    }

    if (this.state.sortByDescending) {
      return <ArrowDropDown />;
    } else {
      return <ArrowDropUp />;
    }
  }

  render(): JSX.Element {
    return (
      <Dialog maxWidth="xl" open={true} onClose={this.props.onClose}>
        <DialogTitle>
          <Typography variant="overline" gutterBottom>
            <Flag color="primary" fontSize="large" />
            &nbsp; Shelf Tests for {this.props.planogramName}
          </Typography>
          <Divider light variant="middle" />
        </DialogTitle>
        <DialogContent>{this.state.testInfoList === undefined || this.state.testInfoList.length === 0 ? this.renderEmpty() : this.renderTestListTable()}</DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={this.props.onClose} className="pull-right">
            <Close />
            &nbsp;
            <Typography variant="button">Cancel</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderEmpty(): JSX.Element {
    return (
      <Alert color="warning">
        <Row>
          <Info color="primary" />
          &nbsp;
          <Typography color="textSecondary" variant="body2">
            This planogram has not requested any shelf tests yet.
          </Typography>
        </Row>
      </Alert>
    );
  }

  renderTestListTable(): JSX.Element {
    return (
      <React.Fragment>
        <Table size="small" className="sticky-header-table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell onClick={(): void => this.changeOrderBy('testName')}>
                <Typography variant="subtitle1">
                  Name&nbsp;
                  {this.getOrderByIcon('testName')}
                </Typography>
              </StyledTableCell>
              <StyledTableCell onClick={(): void => this.changeOrderBy('createdBy')}>
                <Typography variant="subtitle1">
                  Created By&nbsp;
                  {this.getOrderByIcon('createdBy')}
                </Typography>
              </StyledTableCell>
              <StyledTableCell onClick={(): void => this.changeOrderBy('operationsInfo.storeName')}>
                <Typography variant="subtitle1">
                  Store&nbsp;
                  {this.getOrderByIcon('operationsInfo.storeName')}
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography variant="subtitle1" onClick={(): void => this.changeOrderBy('operationsInfo.startDate')}>
                  Start Date&nbsp;
                  {this.getOrderByIcon('operationsInfo.startDate')}
                </Typography>
              </StyledTableCell>
              <StyledTableCell onClick={(): void => this.changeOrderBy('operationsInfo.endDate')}>
                <Typography variant="subtitle1">
                  End Date&nbsp;
                  {this.getOrderByIcon('operationsInfo.endDate')}
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography variant="subtitle1">Goal</Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography variant="subtitle1">KPI</Typography>
              </StyledTableCell>
              <StyledTableCell onClick={(): void => this.changeOrderBy('status')}>
                <Typography variant="subtitle1">
                  Status&nbsp;
                  {this.getOrderByIcon('status')}
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography variant="subtitle1">Report</Typography>
              </StyledTableCell>
              <StyledTableCell />
            </StyledTableRow>
          </TableHead>
          <TableBody>{this.renderTableRow()}</TableBody>
        </Table>
      </React.Fragment>
    );
  }

  renderTableRow(): JSX.Element[] {
    const testList = orderBy(this.state.testInfoList, [this.state.sortByProperty], [this.state.sortByDescending ? 'desc' : 'asc']);
    return testList.map(test => {
      return (
        <StyledTableRow key={test.id}>
          <StyledTableCell>{test.testName}</StyledTableCell>
          <StyledTableCell>{test.createdBy}</StyledTableCell>
          <StyledTableCell>{test.operationsInfo.storeName}</StyledTableCell>
          <StyledTableCell>{getDateInInputFormat(test.operationsInfo.startDate)}</StyledTableCell>
          <StyledTableCell>{getDateInInputFormat(test.operationsInfo.endDate)}</StyledTableCell>
          <StyledTableCell>{this.props.goal}</StyledTableCell>
          <StyledTableCell />
          <StyledTableCell />
          <StyledTableCell />
          <StyledTableCell>
            <Tooltip title="Open Shelf Test">
              <IconButton href={window.location.origin + '/planograms/' + test.initialPlanogramId + '/test/' + test.id} target="_blank">
                <Launch fontSize="small" color="secondary" />
              </IconButton>
            </Tooltip>
          </StyledTableCell>
        </StyledTableRow>
      );
    });
  }
}

export default trackComponent(TestListModal, 'PlanogramsListPage_TestListModal');
