import * as React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { BugReport } from '@material-ui/icons';

export default class AppCrashed extends React.Component {
  render(): JSX.Element {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <span className="clearfix">
                <div style={{ justifyContent: 'center', width: '100%', display: 'flex' }}>
                  <BugReport style={{ fontSize: 40 }} color="secondary" />
                  <BugReport style={{ fontSize: 40 }} color="secondary" />
                  <BugReport style={{ fontSize: 40 }} color="secondary" />
                  <BugReport style={{ fontSize: 40 }} color="secondary" />
                </div>
                <h4 className="pt-3">Well, this is embarrassing...</h4>
                <p className="float-left text-danger">
                  {`The app has reached an unrecoverable state. We have noted down the error details and will make sure
                  that this doesn't happen again. `}
                  <br />
                  {`
                  In the meantime, please refresh the page and try again. If this error continues to occur, please{' '}`}
                  <a href="https://www.flowinsights.com/contact/" target="_blank" rel="noopener noreferrer">
                    send us a message.
                  </a>
                </p>
              </span>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
