import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import MUIDialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import CloseIcon from '@material-ui/icons/Close';
import HighlightIcon from '@material-ui/icons/Highlight';

import InspirationDialogContent from './InspirationDialogContent';

interface Props {
  planogramId: string;
}

const Dialog: React.FC<Props> = ({ planogramId }: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = (): void => {
    setIsOpen(false);
  };
  const handleOpen = (): void => {
    setIsOpen(true);
  };
  return (
    <>
      <Button id="inspirationImagesDialogBtn" size="small" variant="outlined" color="primary" onClick={handleOpen}>
        <HighlightIcon />
        &nbsp;Inspiration
      </Button>
      <MUIDialog maxWidth="md" open={isOpen} onClose={handleClose}>
        <DialogTitle>
          <Typography variant="overline" gutterBottom>
            <HighlightIcon color="primary" />
            &nbsp; Inspiration Images
          </Typography>
          <Divider light variant="middle" />
        </DialogTitle>
        <DialogContent>
          <InspirationDialogContent planogramId={planogramId} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose} color="secondary">
            <CloseIcon />
            &nbsp; Close
          </Button>
        </DialogActions>
      </MUIDialog>
    </>
  );
};

export default Dialog;
