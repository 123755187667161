import React from 'react';
import { Bar, BarChart, Legend, ReferenceLine, Tooltip, XAxis, YAxis } from 'recharts';
import { toMaxPrecisionPercentFloored } from 'utils/Helpers';
import { DetailedConversion } from '../types';

const CategoryOrder = [
  'GrabAndGo',
  'ProductDestinationBuyers',
  'WonFromCompetitor(s)',
  'WonFromInternal',
  'MultiProductBuyers',
  'ShelfLostShoppers',
  'LostToInternal',
  'LostToCompetitor(s)',
  'ProductLostShoppers',
];

const CategoryColor = {
  GrabAndGo: '#00F910',
  ProductDestinationBuyers: '#26B22F',
  'WonFromCompetitor(s)': '#60A486',
  WonFromInternal: '#8fbfaa',
  MultiProductBuyers: '#006a44',
  ShelfLostShoppers: '#F3B75D',
  LostToInternal: '#aa8041',
  'LostToCompetitor(s)': '#FD4444',
  ProductLostShoppers: '#CC0202',
};

export function GenerateBarItems(name: string, data: DetailedConversion[]): object {
  const items = { name: name };
  CategoryOrder.forEach(category => {
    items[category] = toMaxPrecisionPercentFloored(data?.find(x => x.category === category)?.percentage, 2) ?? 0;
  });

  return items;
}

export interface InteractorComparisonStackedBarChartProps {
  data: object[];
}

const InteractorComparisonStackedBarChart: React.FC<InteractorComparisonStackedBarChartProps> = ({ data }: InteractorComparisonStackedBarChartProps) => {
  const yLine = CategoryOrder.slice(0, 5)
    .map(c => {
      return parseFloat(data[0][c]);
    })
    .reduce((a, c) => a + c);
  const labelFormatter = (label: string): string => `${label}%`;
  const legendFormatter = (category: string): React.ReactNode => {
    return (
      <span>
        {category} (
        {data.map((bar, i) => {
          return `${bar[category] || 0}%${i === 0 ? ' / ' : ''}`;
        })}
        )
      </span>
    );
  };

  const bars = CategoryOrder.map(category => {
    return <Bar key={category} dataKey={category} stackId={'a'} fill={CategoryColor[category]}></Bar>;
  });

  return (
    <BarChart width={500} height={300} data={data} stackOffset="wiggle">
      <XAxis dataKey="name" hide={true} />
      <YAxis reversed={true} hide={true} />
      <Tooltip cursor={{ fill: 'white' }} formatter={labelFormatter} />
      <ReferenceLine y={yLine} stroke="red" isFront={true} ifOverflow={'extendDomain'} strokeDasharray="4 1" />
      <Legend layout="vertical" verticalAlign="middle" align="right" iconSize={24} formatter={legendFormatter} wrapperStyle={{ right: -20 }} />
      {bars}
    </BarChart>
  );
};

export { InteractorComparisonStackedBarChart };
