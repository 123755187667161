import { Expose } from 'class-transformer';

export class SalesReportModel {
  reportName = '';

  @Expose({ name: 'id' })
  reportId = '';

  isMasterSalesData = false;
  items: SalesDataModel[] = [];
}

export class SalesDataModel {
  gtin = 0;
  value = 0;
}
