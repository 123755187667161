import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Link, Table, TableBody, TableCell, TableHead, Tooltip, Typography } from '@material-ui/core';
import { Build, Close, PhotoFilter, Visibility, Warning } from '@material-ui/icons';
import { formatDistanceToNow } from 'date-fns';
import * as React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Alert, Row } from 'reactstrap';
import trackComponent from 'infrastructure/tracking/withTracking';
import { StyledTableCell, StyledTableRow } from 'scss/MaterialStyles';
import AdminBuilderPlanogramViewModel from '../types/AdminBuilderPlanogramViewModel';
import AdminPlanogramViewModel from '../types/AdminPlanogramViewModel';
import ViewBuilderRulesModal from './ViewBuilderRulesModal';
import EnabledShelfCondition from './EnabledShelfConditions/EnabledShelfCondition';
import ManageInspirationImages from './ManageInspirationImages';
import { ReduxPlanogramPropertyNames } from './ManageInspirationImages/types';
import EnvFeatureFlag from '../../../../containers/EnvFeatureFlag';
import { isError, isLoading, RemoteObjectStatusInterface } from 'infrastructure/utils/RemoteObjectStatus';
import { AlertErrorTextIndicator } from 'modules/shared/components/LoadStatusIndicators/AlertErrorTextIndicator';

interface BuildersListModalProps {
  planogram: AdminPlanogramViewModel;
  builderPlanograms: RemoteObjectStatusInterface<AdminBuilderPlanogramViewModel[]>;
  onClose(): void;
}

interface BuildersListModalState {
  orderByProperty: string;
  orderByDescending: boolean;
  selectedBuilderPlanogram?: AdminBuilderPlanogramViewModel;
}

class BuildersListModal extends React.Component<BuildersListModalProps & DispatchProps, BuildersListModalState> {
  constructor(props: BuildersListModalProps & DispatchProps, state: BuildersListModalState) {
    super(props, state);
    this.state = {
      orderByProperty: 'name',
      orderByDescending: false,
    };
  }

  componentDidMount(): void {
    this.props.loadBuilderPlanogramsRequest(this.props.planogram);
  }

  onShowEditBuilderRulesModal(selectedBuilderPlanogram: AdminBuilderPlanogramViewModel): void {
    this.setState({
      selectedBuilderPlanogram,
    });
  }

  render(): JSX.Element {
    return (
      <Dialog maxWidth="xl" open={true} onClose={this.props.onClose}>
        <DialogTitle>
          <Typography variant="overline" gutterBottom>
            <PhotoFilter fontSize="large" />
            &nbsp; Planogram Builders
          </Typography>
          <Divider light variant="middle" />
        </DialogTitle>
        <DialogContent>
          {isError(this.props.builderPlanograms) ? (
            <AlertErrorTextIndicator errorText="There seems to be an error with loading builders for this planogram. Please try again later." />
          ) : (
            <LoadingOverlay active={isLoading(this.props.builderPlanograms)} spinner={true} message="Loading builders...">
              {this.renderBuilderPlanogramsList()}
            </LoadingOverlay>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={this.props.onClose} className="pull-right">
            <Close />
            &nbsp; Close
          </Button>
        </DialogActions>
        {this.state.selectedBuilderPlanogram ? (
          <ViewBuilderRulesModal
            selectedBuilderPlanogram={this.state.selectedBuilderPlanogram}
            onClose={() => {
              this.setState({ selectedBuilderPlanogram: undefined });
            }}
          />
        ) : null}
      </Dialog>
    );
  }

  renderBuilderPlanogramsList(): JSX.Element {
    if (isLoading(this.props.builderPlanograms)) {
      return <div>Loading...</div>;
    }
    if (this.props.builderPlanograms !== undefined && this.props.builderPlanograms.data?.length !== 0) {
      return (
        <Table size="small" className="sticky-header-table">
          <TableHead>
            <tr>
              <TableCell>Email</TableCell>
              <TableCell align="center">Last Opened</TableCell>
              <TableCell align="center">View</TableCell>
              <TableCell align="center">Edit Rules</TableCell>
              <EnvFeatureFlag featureName="inspirationImages">
                <TableCell align="center">Inspiration Images</TableCell>
              </EnvFeatureFlag>
              <TableCell align="center">Shelf Timelapse</TableCell>
            </tr>
          </TableHead>
          <TableBody>{this.renderBuilderPlanogramRow()}</TableBody>
        </Table>
      );
    } else {
      return (
        <Alert color="warning">
          <Row>
            <Warning />
            &nbsp; There are no Builders for this Planogram.
          </Row>
        </Alert>
      );
    }
  }

  renderBuilderPlanogramRow(): JSX.Element[] | undefined {
    return this.props.builderPlanograms.data?.map(builderPlanogram => {
      return (
        <StyledTableRow key={builderPlanogram.id}>
          <StyledTableCell>{builderPlanogram.email}</StyledTableCell>
          <StyledTableCell align="center">
            {builderPlanogram.lastOpenedByBuilder ? <span>{formatDistanceToNow(builderPlanogram.lastOpenedByBuilder, { addSuffix: true })}</span> : 'Never'}
          </StyledTableCell>
          <StyledTableCell align="center">
            <Link href={'/planograms/' + builderPlanogram.id} target="_blank">
              <Tooltip title="View Builder Planogram">
                <IconButton>
                  <Visibility color="secondary" />
                </IconButton>
              </Tooltip>
            </Link>
          </StyledTableCell>
          <StyledTableCell align="center">
            <Tooltip title="View Builder's Rules">
              <IconButton
                onClick={() => {
                  return this.onShowEditBuilderRulesModal(builderPlanogram);
                }}
              >
                <Build color="secondary" />
              </IconButton>
            </Tooltip>
          </StyledTableCell>
          <EnvFeatureFlag featureName="inspirationImages">
            <StyledTableCell align="center">
              <ManageInspirationImages planogramId={builderPlanogram.id} reduxPlanogramPropsName={ReduxPlanogramPropertyNames.BUILDER_PLANOGRAMS} />
            </StyledTableCell>
          </EnvFeatureFlag>
          <StyledTableCell align="center">
            <EnabledShelfCondition planogramId={builderPlanogram.id} isShelfConditionsEnabled={builderPlanogram.shelfConditions.isShelfConditionsEnabled} />
          </StyledTableCell>
        </StyledTableRow>
      );
    });
  }
}

interface DispatchProps {
  loadBuilderPlanogramsRequest(planogram: AdminPlanogramViewModel): void;
}

export default trackComponent(BuildersListModal, 'BuildersListModal');
