import * as React from 'react';
import ResizeObserver from '@juggle/resize-observer';
import { ResizeObserverEntry } from '@juggle/resize-observer/lib/ResizeObserverEntry';

interface MyProps {
  domElementIdToWatch: string;
  domElementIdHeightToUse: string;
  onResizeDetected(width: number, height: number): void;
}

interface MyState {
  htmlElementToObserve: HTMLElement;
  htmlElementHeightToUse: HTMLElement;
  resizeObservable: ResizeObserver;
}

class ResizeObserverComponent extends React.Component<MyProps, MyState> {
  resizeTaskId?: number = undefined;

  constructor(props: MyProps) {
    super(props);
    this.state = {
      htmlElementToObserve: document.createElement('div'),
      htmlElementHeightToUse: document.createElement('div'),
      resizeObservable: new ResizeObserver(this.handleResize),
    };
  }

  componentDidMount(): void {
    const htmlElementToObserve = document.getElementById(this.props.domElementIdToWatch);
    const htmlElementHeightToUse = document.getElementById(this.props.domElementIdHeightToUse);
    if (!htmlElementToObserve || !htmlElementHeightToUse) {
      return;
    }
    this.setState({ htmlElementToObserve, htmlElementHeightToUse });
    this.state.resizeObservable.observe(htmlElementToObserve);
    window.addEventListener('resize', this.scheduleWindowResize);
  }

  componentWillUnmount(): void {
    this.state.resizeObservable.unobserve(this.state.htmlElementToObserve);
    this.state.resizeObservable.disconnect();
    window.removeEventListener('resize', this.scheduleWindowResize);
    window.clearTimeout(this.resizeTaskId);
  }

  scheduleWindowResize = (): void => {
    const delay = 100;
    if (this.resizeTaskId !== null) {
      clearTimeout(this.resizeTaskId);
    }

    this.resizeTaskId = window.setTimeout(() => {
      this.resizeTaskId = undefined;
      this.handleWindowResize();
    }, delay);
  };

  handleWindowResize = (): void => {
    const width = this.state.htmlElementHeightToUse.clientWidth - this.getLeftRightTotalPadding();
    const height = this.inViewPort();
    this.props.onResizeDetected(width, height);
  };

  handleResize = (target: ResizeObserverEntry[]): void => {
    const theTarget = target[0];
    if (!theTarget) {
      return;
    }
    const width = theTarget.contentRect.width;
    const height = this.inViewPort();
    this.props.onResizeDetected(width, height);
  };

  render(): JSX.Element {
    return <>{this.props.children}</>;
  }

  private inViewPort(): number {
    const htmlElement = this.state.htmlElementHeightToUse;
    const elH = this.getOuterHeight(htmlElement);
    const H = window.innerHeight;
    const r = htmlElement.getBoundingClientRect();
    const t = r.top;
    const b = r.bottom;
    return Math.max(0, t > 0 ? Math.min(elH, H - t) : Math.min(b, H));
  }

  private getLeftRightTotalPadding(): number {
    const computedStyle = window.getComputedStyle(this.state.htmlElementHeightToUse, null);
    const paddingLeft = parseFloat(computedStyle.getPropertyValue('padding-left'));
    const paddingRight = parseFloat(computedStyle.getPropertyValue('padding-right'));
    return paddingLeft + paddingRight;
  }

  private getOuterHeight(el: HTMLElement): number {
    let height = el.offsetHeight;
    const style = window.getComputedStyle(el, null);

    height += parseInt(style.marginTop || '0') + parseInt(style.marginBottom || '0');
    return height;
  }
}

export default ResizeObserverComponent;
