import ErrorDetails from 'infrastructure/exception/ErrorDetails';
import { RealogramConversation } from './types';

// Realogram Action Types
export const REALOGRAM_LOAD_REQUEST = 'REALOGRAM_LOAD_REQUEST';
export type REALOGRAM_LOAD_REQUEST = typeof REALOGRAM_LOAD_REQUEST;

export const REALOGRAM_LOAD_SUCCESS = 'REALOGRAM_LOAD_SUCCESS';
export type REALOGRAM_LOAD_SUCCESS = typeof REALOGRAM_LOAD_SUCCESS;

export const REALOGRAM_LOAD_FAILED = 'REALOGRAM_LOAD_FAILED';
export type REALOGRAM_LOAD_FAILED = typeof REALOGRAM_LOAD_FAILED;

export const REALOGRAM_FLOW_DATA_REQUEST = 'REALOGRAM_FLOW_DATA_REQUEST';
export type REALOGRAM_FLOW_DATA_REQUEST = typeof REALOGRAM_FLOW_DATA_REQUEST;

export const REALOGRAM_FLOW_DATA_SUCCESS = 'REALOGRAM_FLOW_DATA_SUCCESS';
export type REALOGRAM_FLOW_DATA_SUCCESS = typeof REALOGRAM_FLOW_DATA_SUCCESS;

export const REALOGRAM_FLOW_DATA_FAILED = 'REALOGRAM_FLOW_DATA_FAILED';
export type REALOGRAM_FLOW_DATA_FAILED = typeof REALOGRAM_FLOW_DATA_FAILED;

export const REALOGRAM_SELECT_FLOW_REPORT_TYPE = 'REALOGRAM_SELECT_FLOW_REPORT_TYPE';
export type REALOGRAM_SELECT_FLOW_REPORT_TYPE = typeof REALOGRAM_SELECT_FLOW_REPORT_TYPE;

export const REALOGRAM_SHELF_LOCATION_SELECT = 'REALOGRAM_SHELF_LOCATION_SELECT';
export type REALOGRAM_SHELF_LOCATION_SELECT = typeof REALOGRAM_SHELF_LOCATION_SELECT;

// Realogram Conversation Action Types
export const REALOGRAM_CONVERSATION_LOAD_REQUEST = 'REALOGRAM_CONVERSATION_LOAD_REQUEST';
export type REALOGRAM_CONVERSATION_LOAD_REQUEST = typeof REALOGRAM_CONVERSATION_LOAD_REQUEST;

export const REALOGRAM_CONVERSATION_LOAD_SUCCESS = 'REALOGRAM_CONVERSATION_LOAD_SUCCESS';
export type REALOGRAM_CONVERSATION_LOAD_SUCCESS = typeof REALOGRAM_CONVERSATION_LOAD_SUCCESS;

export const REALOGRAM_CONVERSATION_LOAD_FAILURE = 'REALOGRAM_CONVERSATION_LOAD_FAILURE';
export type REALOGRAM_CONVERSATION_LOAD_FAILURE = typeof REALOGRAM_CONVERSATION_LOAD_FAILURE;

export const OPEN_REALOGRAM_CONVERSATION_POPPER = 'OPEN_REALOGRAM_CONVERSATION_POPPER';
export type OPEN_REALOGRAM_CONVERSATION_POPPER = typeof OPEN_REALOGRAM_CONVERSATION_POPPER;

export const CLOSE_REALOGRAM_CONVERSATION_POPPER = 'CLOSE_REALOGRAM_CONVERSATION_POPPER';
export type CLOSE_REALOGRAM_CONVERSATION_POPPER = typeof CLOSE_REALOGRAM_CONVERSATION_POPPER;

export const POST_MESSAGE_TO_CONVERSATION_REQUEST = 'POST_MESSAGE_TO_CONVERSATION_REQUEST';
export type POST_MESSAGE_TO_CONVERSATION_REQUEST = typeof POST_MESSAGE_TO_CONVERSATION_REQUEST;

export const POST_MESSAGE_TO_CONVERSATION_SUCCESS = 'POST_MESSAGE_TO_CONVERSATION_SUCCESS';
export type POST_MESSAGE_TO_CONVERSATION_SUCCESS = typeof POST_MESSAGE_TO_CONVERSATION_SUCCESS;

export const POST_MESSAGE_TO_CONVERSATION_FAILURE = 'POST_MESSAGE_TO_CONVERSATION_FAILURE';
export type POST_MESSAGE_TO_CONVERSATION_FAILURE = typeof POST_MESSAGE_TO_CONVERSATION_FAILURE;

export const UPDATE_MESSAGE_IN_CONVERSATION_REQUEST = 'UPDATE_MESSAGE_IN_CONVERSATION_REQUEST';
export type UPDATE_MESSAGE_IN_CONVERSATION_REQUEST = typeof UPDATE_MESSAGE_IN_CONVERSATION_REQUEST;

export const UPDATE_MESSAGE_IN_CONVERSATION_SUCCESS = 'UPDATE_MESSAGE_IN_CONVERSATION_SUCCESS';
export type UPDATE_MESSAGE_IN_CONVERSATION_SUCCESS = typeof UPDATE_MESSAGE_IN_CONVERSATION_SUCCESS;

export const UPDATE_MESSAGE_IN_CONVERSATION_FAILURE = 'UPDATE_MESSAGE_IN_CONVERSATION_FAILURE';
export type UPDATE_MESSAGE_IN_CONVERSATION_FAILURE = typeof UPDATE_MESSAGE_IN_CONVERSATION_FAILURE;

export const DELETE_MESSAGE_IN_CONVERSATION_REQUEST = 'DELETE_MESSAGE_IN_CONVERSATION_REQUEST';
export type DELETE_MESSAGE_IN_CONVERSATION_REQUEST = typeof DELETE_MESSAGE_IN_CONVERSATION_REQUEST;

export const DELETE_MESSAGE_IN_CONVERSATION_SUCCESS = 'DELETE_MESSAGE_IN_CONVERSATION_SUCCESS';
export type DELETE_MESSAGE_IN_CONVERSATION_SUCCESS = typeof DELETE_MESSAGE_IN_CONVERSATION_SUCCESS;

export const DELETE_MESSAGE_IN_CONVERSATION_FAILURE = 'DELETE_MESSAGE_IN_CONVERSATION_FAILURE';
export type DELETE_MESSAGE_IN_CONVERSATION_FAILURE = typeof DELETE_MESSAGE_IN_CONVERSATION_FAILURE;

export interface FetchRealogramConversationRequestActionType {
  type: typeof REALOGRAM_CONVERSATION_LOAD_REQUEST;
  payload: {
    shelfReferenceId: string;
  };
}

export interface FetchRealogramConversationSuccessActionType {
  type: typeof REALOGRAM_CONVERSATION_LOAD_SUCCESS;
  payload: {
    realogramConversation: RealogramConversation;
  };
}

export interface FetchRealogramConversationFailureActionType {
  type: typeof REALOGRAM_CONVERSATION_LOAD_FAILURE;
  payload: {
    errorDetails: ErrorDetails;
  };
}

export interface OpenRealogramConversationPopperActionType {
  type: typeof OPEN_REALOGRAM_CONVERSATION_POPPER;
  payload: {
    anchorEl: Element;
  };
}

export interface CloseRealogramConversationPopperActionType {
  type: typeof CLOSE_REALOGRAM_CONVERSATION_POPPER;
}

export interface PostMessageToConversationRequestActionType {
  type: typeof POST_MESSAGE_TO_CONVERSATION_REQUEST;
  payload: {
    shelfReferenceId: string;
    message: string;
  };
}

export interface PostMessageToConversationSuccessActionType {
  type: typeof POST_MESSAGE_TO_CONVERSATION_SUCCESS;
  payload: {
    shelfReferenceId: string;
    message: string;
    messageId: string;
    userId: number;
    username: string;
    timeOfMessage: Date;
  };
}
export interface PostMessageToConversationFailureActionType {
  type: typeof POST_MESSAGE_TO_CONVERSATION_FAILURE;
  payload: {
    errorDetails: ErrorDetails;
  };
}

export interface UpdateMessageInConversationRequestActionType {
  type: typeof UPDATE_MESSAGE_IN_CONVERSATION_REQUEST;
  payload: {
    shelfReferenceId: string;
    messageId: string;
    userId: number;
    editedMessage: string;
  };
}

export interface UpdateMessageInConversationSuccessActionType {
  type: typeof UPDATE_MESSAGE_IN_CONVERSATION_SUCCESS;
  payload: {
    shelfReferenceId: string;
    messageId: string;
    editedMessage: string;
  };
}

export interface UpdateMessageInConversationFailureActionType {
  type: typeof UPDATE_MESSAGE_IN_CONVERSATION_FAILURE;
  payload: {
    errorDetails: ErrorDetails;
  };
}

export interface DeleteMessageInConversationRequestActionType {
  type: typeof DELETE_MESSAGE_IN_CONVERSATION_REQUEST;
  payload: {
    shelfReferenceId: string;
    messageId: string;
  };
}

export interface DeleteMessageInConversationSuccessActionType {
  type: typeof DELETE_MESSAGE_IN_CONVERSATION_SUCCESS;
  payload: {
    shelfReferenceId: string;
    messageId: string;
  };
}

export interface DeleteMessageInConversationFailureActionType {
  type: typeof DELETE_MESSAGE_IN_CONVERSATION_FAILURE;
  payload: {
    errorDetails: ErrorDetails;
  };
}
