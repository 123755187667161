import moment from 'moment';
import React from 'react';
import { toMaxPrecisionPercent } from 'utils/Helpers';
import flowLogo from '../../../../../assets/img/brand/flow.jpg';
import { ShopperComparisonReportResult } from '../../types';
import '../styles/StandardReportStyle.css';
interface StandardShopperComparisonReportPageProps {
  shopperComparisonReport: ShopperComparisonReportResult;
}
const StandardShopperComparisonReportPage: React.FC<StandardShopperComparisonReportPageProps> = ({ shopperComparisonReport }: StandardShopperComparisonReportPageProps) => {
  const overviewReport = shopperComparisonReport.overviewReport;
  const demographicsReport = shopperComparisonReport.demographicsReport;
  const shoppingTimeReport = shopperComparisonReport.shoppingTimeReport;
  const shoppingWithReport = shopperComparisonReport.shoppingWithReport;
  const shoppingWithBasket = shoppingWithReport.find(x => x.shoppingWith === 'Shopping with basket');
  const shoppingWithCart = shoppingWithReport.find(x => x.shoppingWith === 'Shopping with cart');
  const shoppingWithHands = shoppingWithReport.find(x => x.shoppingWith === 'Shopping with hands');
  const femaleDemographics = demographicsReport.find(x => x.demographicGroup === 'Female');
  const maleDemographics = demographicsReport.find(x => x.demographicGroup === 'Male');
  const groupDemographics = demographicsReport.find(x => x.demographicGroup === 'Group');
  const withKidsDemographics = demographicsReport.find(x => x.demographicGroup === 'With Kids');
  const weekdayBefore4pm = shoppingTimeReport.find(x => x.shoppingTime === 'Weekday before 4pm');
  const weekdayAfter4pm = shoppingTimeReport.find(x => x.shoppingTime === 'Weekday after 4pm');
  const weekendBefore4pm = shoppingTimeReport.find(x => x.shoppingTime === 'Weekend inc. friday before 4pm');
  const weekendAfter4pm = shoppingTimeReport.find(x => x.shoppingTime === 'Weekend inc. friday after 4pm');
  return (
    <div className="page">
      <div id="flowLogo">
        <img alt="flow-logo" className="img-responsive" src={flowLogo} />
      </div>
      <div style={{ width: '100%' }}>
        <h3 className="text-center">
          Shopper Comparison Report
          <br />
        </h3>
        <h5 className="text-center">
          <p style={{ margin: '0px' }}>
            <b>Shelf 1: </b> {overviewReport.projectNameA}, {overviewReport.storeNameA}, Phase {overviewReport.phaseNumA}, Store {overviewReport.storeIdA} (
            <i>
              {moment(overviewReport.dateFromA).format('DD/MM/YYYY')} - {moment(overviewReport.dateToA).format('DD/MM/YYYY')}
            </i>
            )
          </p>
          <p style={{ margin: '0px' }}>
            <b>Shelf 2: </b> {overviewReport.projectNameB}, {overviewReport.storeNameB}, Phase {overviewReport.phaseNumB}, Store {overviewReport.storeIdB} (
            <i>
              {moment(overviewReport.dateFromB).format('DD/MM/YYYY')} - {moment(overviewReport.dateToB).format('DD/MM/YYYY')}
            </i>
            )
          </p>
          <br />
        </h5>
        <h5 className="text-left">
          <p style={{ margin: '0px' }}>
            <b>Shelf 1 Visitors:</b> {overviewReport.totalVisitorsA}
          </p>
          <p style={{ margin: '0px' }}>
            <b>Shelf 2 Visitors:</b> {overviewReport.totalVisitorsB}
          </p>
          <br />
        </h5>
        <div className="row">
          <table style={{ marginTop: '10px' }} className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-center metricNameCell">Way of shopping</td>
                <td className="text-center metricNameCell" colSpan={2}>
                  Total
                </td>
                <td className="text-center metricNameCell" colSpan={2}>
                  Shopping with basket
                </td>
                <td className="text-center metricNameCell" colSpan={2}>
                  Shopping with cart
                </td>
                <td className="text-center metricNameCell" colSpan={2}>
                  Shopping by hands
                </td>
              </tr>
              <tr>
                <td className="text-center metricNameCell" rowSpan={2}>
                  Visitors(at shelf)
                </td>
                <td className="text-center" colSpan={2}>
                  -
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {shoppingWithBasket ? toMaxPrecisionPercent(shoppingWithBasket.visitorsSharePercChange, 2) : '-'}
                  {shoppingWithBasket ? shoppingWithBasket.visitorsShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {shoppingWithCart ? toMaxPrecisionPercent(shoppingWithCart.visitorsSharePercChange, 2) : '-'}
                  {shoppingWithCart ? shoppingWithCart.visitorsShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {shoppingWithHands ? toMaxPrecisionPercent(shoppingWithHands.visitorsSharePercChange, 2) : '-'}
                  {shoppingWithHands ? shoppingWithHands.visitorsShareSignificance : '-'}
                </td>
              </tr>
              <tr>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.visitorsShareA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.visitorsShareB, 2)}</td>
                <td className="text-center">{shoppingWithBasket ? toMaxPrecisionPercent(shoppingWithBasket.visitorsShareA, 2) : '-'}</td>
                <td className="text-center">{shoppingWithBasket ? toMaxPrecisionPercent(shoppingWithBasket.visitorsShareB, 2) : '-'}</td>
                <td className="text-center">{shoppingWithCart ? toMaxPrecisionPercent(shoppingWithCart.visitorsShareA, 2) : '-'}</td>
                <td className="text-center">{shoppingWithCart ? toMaxPrecisionPercent(shoppingWithCart.visitorsShareB, 2) : '-'}</td>
                <td className="text-center">{shoppingWithHands ? toMaxPrecisionPercent(shoppingWithHands.visitorsShareA, 2) : '-'}</td>
                <td className="text-center">{shoppingWithHands ? toMaxPrecisionPercent(shoppingWithHands.visitorsShareB, 2) : '-'}</td>
              </tr>
              <tr>
                <td className="text-center metricNameCell" rowSpan={2}>
                  Browsers(interest)
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewReport.shownInterestSharePercChange, 2)}
                  {overviewReport.shownInterestShareSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {shoppingWithBasket ? toMaxPrecisionPercent(shoppingWithBasket.shownInterestSharePercChange, 2) : '-'}
                  {shoppingWithBasket ? shoppingWithBasket.shownInterestShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {shoppingWithCart ? toMaxPrecisionPercent(shoppingWithCart.shownInterestSharePercChange, 2) : '-'}
                  {shoppingWithCart ? shoppingWithCart.shownInterestShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {shoppingWithHands ? toMaxPrecisionPercent(shoppingWithHands.shownInterestSharePercChange, 2) : '-'}
                  {shoppingWithHands ? shoppingWithHands.shownInterestShareSignificance : '-'}
                </td>
              </tr>
              <tr>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.shownInterestShareA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.shownInterestShareB, 2)}</td>
                <td className="text-center">{shoppingWithBasket ? toMaxPrecisionPercent(shoppingWithBasket.shownInterestShareA, 2) : '-'}</td>
                <td className="text-center">{shoppingWithBasket ? toMaxPrecisionPercent(shoppingWithBasket.shownInterestShareB, 2) : '-'}</td>
                <td className="text-center">{shoppingWithCart ? toMaxPrecisionPercent(shoppingWithCart.shownInterestShareA, 2) : '-'}</td>
                <td className="text-center">{shoppingWithCart ? toMaxPrecisionPercent(shoppingWithCart.shownInterestShareB, 2) : '-'}</td>
                <td className="text-center">{shoppingWithHands ? toMaxPrecisionPercent(shoppingWithHands.shownInterestShareA, 2) : '-'}</td>
                <td className="text-center">{shoppingWithHands ? toMaxPrecisionPercent(shoppingWithHands.shownInterestShareB, 2) : '-'}</td>
              </tr>
              <tr>
                <td className="text-center metricNameCell" rowSpan={2}>
                  Shoppers(interacted)
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewReport.interactedSharePercChange, 2)}
                  {overviewReport.interactedShareSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {shoppingWithBasket ? toMaxPrecisionPercent(shoppingWithBasket.interactedSharePercChange, 2) : '-'}
                  {shoppingWithBasket ? shoppingWithBasket.interactedShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {shoppingWithCart ? toMaxPrecisionPercent(shoppingWithCart.interactedSharePercChange, 2) : '-'}
                  {shoppingWithCart ? shoppingWithCart.interactedShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {shoppingWithHands ? toMaxPrecisionPercent(shoppingWithHands.interactedSharePercChange, 2) : '-'}
                  {shoppingWithHands ? shoppingWithHands.interactedShareSignificance : '-'}
                </td>
              </tr>
              <tr>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.interactedShareA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.interactedShareB, 2)}</td>
                <td className="text-center">{shoppingWithBasket ? toMaxPrecisionPercent(shoppingWithBasket.interactedShareA, 2) : '-'}</td>
                <td className="text-center">{shoppingWithBasket ? toMaxPrecisionPercent(shoppingWithBasket.interactedShareB, 2) : '-'}</td>
                <td className="text-center">{shoppingWithCart ? toMaxPrecisionPercent(shoppingWithCart.interactedShareA, 2) : '-'}</td>
                <td className="text-center">{shoppingWithCart ? toMaxPrecisionPercent(shoppingWithCart.interactedShareB, 2) : '-'}</td>
                <td className="text-center">{shoppingWithHands ? toMaxPrecisionPercent(shoppingWithHands.interactedShareA, 2) : '-'}</td>
                <td className="text-center">{shoppingWithHands ? toMaxPrecisionPercent(shoppingWithHands.interactedShareB, 2) : '-'}</td>
              </tr>

              <tr>
                <td className="text-center metricNameCell" rowSpan={2}>
                  Buyers(purchase)
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewReport.purchasedSharePercChange, 2)}
                  {overviewReport.purchasedShareSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {shoppingWithBasket ? toMaxPrecisionPercent(shoppingWithBasket.purchasedSharePercChange, 2) : '-'}
                  {shoppingWithBasket ? shoppingWithBasket.purchasedShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {shoppingWithCart ? toMaxPrecisionPercent(shoppingWithCart.purchasedSharePercChange, 2) : '-'}
                  {shoppingWithCart ? shoppingWithCart.purchasedShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {shoppingWithHands ? toMaxPrecisionPercent(shoppingWithHands.purchasedSharePercChange, 2) : '-'}
                  {shoppingWithHands ? shoppingWithHands.purchasedShareSignificance : '-'}
                </td>
              </tr>
              <tr>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.purchasedShareA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.purchasedShareB, 2)}</td>
                <td className="text-center">{shoppingWithBasket ? toMaxPrecisionPercent(shoppingWithBasket.purchasedShareA, 2) : '-'}</td>
                <td className="text-center">{shoppingWithBasket ? toMaxPrecisionPercent(shoppingWithBasket.purchasedShareB, 2) : '-'}</td>
                <td className="text-center">{shoppingWithCart ? toMaxPrecisionPercent(shoppingWithCart.purchasedShareA, 2) : '-'}</td>
                <td className="text-center">{shoppingWithCart ? toMaxPrecisionPercent(shoppingWithCart.purchasedShareB, 2) : '-'}</td>
                <td className="text-center">{shoppingWithHands ? toMaxPrecisionPercent(shoppingWithHands.purchasedShareA, 2) : '-'}</td>
                <td className="text-center">{shoppingWithHands ? toMaxPrecisionPercent(shoppingWithHands.purchasedShareB, 2) : '-'}</td>
              </tr>

              <tr>
                <td className="text-center metricNameCell" rowSpan={2}>
                  Lost Shoppers
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewReport.lostShoppersSharePercChange, 2)}
                  {overviewReport.lostShoppersShareSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {shoppingWithBasket ? toMaxPrecisionPercent(shoppingWithBasket.lostShoppersSharePercChange, 2) : '-'}
                  {shoppingWithBasket ? shoppingWithBasket.lostShoppersShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {shoppingWithCart ? toMaxPrecisionPercent(shoppingWithCart.lostShoppersSharePercChange, 2) : '-'}
                  {shoppingWithCart ? shoppingWithCart.lostShoppersShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {shoppingWithHands ? toMaxPrecisionPercent(shoppingWithHands.lostShoppersSharePercChange, 2) : '-'}
                  {shoppingWithHands ? shoppingWithHands.lostShoppersShareSignificance : '-'}
                </td>
              </tr>
              <tr>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.lostShoppersShareA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.lostShoppersShareB, 2)}</td>
                <td className="text-center">{shoppingWithBasket ? toMaxPrecisionPercent(shoppingWithBasket.lostShoppersShareA, 2) : '-'}</td>
                <td className="text-center">{shoppingWithBasket ? toMaxPrecisionPercent(shoppingWithBasket.lostShoppersShareB, 2) : '-'}</td>
                <td className="text-center">{shoppingWithCart ? toMaxPrecisionPercent(shoppingWithCart.lostShoppersShareA, 2) : '-'}</td>
                <td className="text-center">{shoppingWithCart ? toMaxPrecisionPercent(shoppingWithCart.lostShoppersShareB, 2) : '-'}</td>
                <td className="text-center">{shoppingWithHands ? toMaxPrecisionPercent(shoppingWithHands.lostShoppersShareA, 2) : '-'}</td>
                <td className="text-center">{shoppingWithHands ? toMaxPrecisionPercent(shoppingWithHands.lostShoppersShareB, 2) : '-'}</td>
              </tr>

              <tr>
                <td className="text-center metricNameCell" rowSpan={2}>
                  Assisted browsers
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {overviewReport.assistedBrowsersSharePercChange ? toMaxPrecisionPercent(overviewReport.assistedBrowsersSharePercChange, 2) : '0%'}
                  {overviewReport.assistedBrowsersShareSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {shoppingWithBasket ? toMaxPrecisionPercent(shoppingWithBasket.assistedBrowsersSharePercChange, 2) : '-'}
                  {shoppingWithBasket ? shoppingWithBasket.assistedBrowsersShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {shoppingWithCart ? toMaxPrecisionPercent(shoppingWithCart.assistedBrowsersSharePercChange, 2) : '-'}
                  {shoppingWithCart ? shoppingWithCart.assistedBrowsersShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {shoppingWithHands ? toMaxPrecisionPercent(shoppingWithHands.assistedBrowsersSharePercChange, 2) : '-'}
                  {shoppingWithHands ? shoppingWithHands.assistedBrowsersShareSignificance : '-'}
                </td>
              </tr>
              <tr>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.assistedBrowsersShareA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.assistedBrowsersShareB, 2)}</td>
                <td className="text-center">{shoppingWithBasket ? toMaxPrecisionPercent(shoppingWithBasket.assistedBrowsersShareA, 2) : '-'}</td>
                <td className="text-center">{shoppingWithBasket ? toMaxPrecisionPercent(shoppingWithBasket.assistedBrowsersShareB, 2) : '-'}</td>
                <td className="text-center">{shoppingWithCart ? toMaxPrecisionPercent(shoppingWithCart.assistedBrowsersShareA, 2) : '-'}</td>
                <td className="text-center">{shoppingWithCart ? toMaxPrecisionPercent(shoppingWithCart.assistedBrowsersShareB, 2) : '-'}</td>
                <td className="text-center">{shoppingWithHands ? toMaxPrecisionPercent(shoppingWithHands.assistedBrowsersShareA, 2) : '-'}</td>
                <td className="text-center">{shoppingWithHands ? toMaxPrecisionPercent(shoppingWithHands.assistedBrowsersShareB, 2) : '-'}</td>
              </tr>

              <tr>
                <td className="text-center metricNameCell" rowSpan={2}>
                  Conversion from assistance
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewReport.conversionFromAssistancePercChange, 2)}
                  {overviewReport.conversionFromAssistanceSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {shoppingWithBasket ? toMaxPrecisionPercent(shoppingWithBasket.conversionFromAssistancePercChange, 2) : '-'}
                  {shoppingWithBasket ? shoppingWithBasket.conversionFromAssistanceSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {shoppingWithCart ? toMaxPrecisionPercent(shoppingWithCart.conversionFromAssistancePercChange, 2) : '-'}
                  {shoppingWithCart ? shoppingWithCart.conversionFromAssistanceSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {shoppingWithHands ? toMaxPrecisionPercent(shoppingWithHands.conversionFromAssistancePercChange, 2) : '-'}
                  {shoppingWithHands ? shoppingWithHands.conversionFromAssistanceSignificance : '-'}
                </td>
              </tr>
              <tr>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.conversionFromAssistanceA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.conversionFromAssistanceB, 2)}</td>
                <td className="text-center">{shoppingWithBasket ? toMaxPrecisionPercent(shoppingWithBasket.conversionFromAssistanceA, 2) : '-'}</td>
                <td className="text-center">{shoppingWithBasket ? toMaxPrecisionPercent(shoppingWithBasket.conversionFromAssistanceB, 2) : '-'}</td>
                <td className="text-center">{shoppingWithCart ? toMaxPrecisionPercent(shoppingWithCart.conversionFromAssistanceA, 2) : '-'}</td>
                <td className="text-center">{shoppingWithCart ? toMaxPrecisionPercent(shoppingWithCart.conversionFromAssistanceB, 2) : '-'}</td>
                <td className="text-center">{shoppingWithHands ? toMaxPrecisionPercent(shoppingWithHands.conversionFromAssistanceA, 2) : '-'}</td>
                <td className="text-center">{shoppingWithHands ? toMaxPrecisionPercent(shoppingWithHands.conversionFromAssistanceB, 2) : '-'}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row">
          <table style={{ marginTop: '10px' }} className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-center metricNameCell">Demographics</td>
                <td className="text-center metricNameCell" colSpan={2}>
                  Total
                </td>
                <td className="text-center metricNameCell" colSpan={2}>
                  Female
                </td>
                <td className="text-center metricNameCell" colSpan={2}>
                  Male
                </td>
                <td className="text-center metricNameCell" colSpan={2}>
                  Group
                </td>
                <td className="text-center metricNameCell" colSpan={2}>
                  With kids
                </td>
              </tr>
              <tr>
                <td className="text-center metricNameCell" rowSpan={2}>
                  Visitors(at shelf)
                </td>
                <td className="text-center" colSpan={2}>
                  -
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {femaleDemographics ? toMaxPrecisionPercent(femaleDemographics.visitorsSharePercChange, 2) : '-'}
                  {femaleDemographics ? femaleDemographics.visitorsShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {maleDemographics ? toMaxPrecisionPercent(maleDemographics.visitorsSharePercChange, 2) : '-'}
                  {maleDemographics ? maleDemographics.visitorsShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {groupDemographics ? toMaxPrecisionPercent(groupDemographics.visitorsSharePercChange, 2) : '-'}
                  {groupDemographics ? groupDemographics.visitorsShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {withKidsDemographics ? toMaxPrecisionPercent(withKidsDemographics.visitorsSharePercChange, 2) : '-'}
                  {withKidsDemographics ? withKidsDemographics.visitorsShareSignificance : '-'}
                </td>
              </tr>
              <tr>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.visitorsShareA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.visitorsShareB, 2)}</td>
                <td className="text-center">{femaleDemographics ? toMaxPrecisionPercent(femaleDemographics.visitorsShareA, 2) : '-'}</td>
                <td className="text-center">{femaleDemographics ? toMaxPrecisionPercent(femaleDemographics.visitorsShareB, 2) : '-'}</td>
                <td className="text-center">{maleDemographics ? toMaxPrecisionPercent(maleDemographics.visitorsShareA, 2) : '-'}</td>
                <td className="text-center">{maleDemographics ? toMaxPrecisionPercent(maleDemographics.visitorsShareB, 2) : '-'}</td>
                <td className="text-center">{groupDemographics ? toMaxPrecisionPercent(groupDemographics.visitorsShareA, 2) : '-'}</td>
                <td className="text-center">{groupDemographics ? toMaxPrecisionPercent(groupDemographics.visitorsShareB, 2) : '-'}</td>
                <td className="text-center">{withKidsDemographics ? toMaxPrecisionPercent(withKidsDemographics.visitorsShareA, 2) : '-'}</td>
                <td className="text-center">{withKidsDemographics ? toMaxPrecisionPercent(withKidsDemographics.visitorsShareB, 2) : '-'}</td>
              </tr>

              <tr>
                <td className="text-center metricNameCell" rowSpan={2}>
                  Browsers(interest)
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewReport.shownInterestSharePercChange, 2)}
                  {overviewReport.shownInterestShareSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {femaleDemographics ? toMaxPrecisionPercent(femaleDemographics.shownInterestSharePercChange, 2) : '-'}
                  {femaleDemographics ? femaleDemographics.shownInterestShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {maleDemographics ? toMaxPrecisionPercent(maleDemographics.shownInterestSharePercChange, 2) : '-'}
                  {maleDemographics ? maleDemographics.shownInterestShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {groupDemographics ? toMaxPrecisionPercent(groupDemographics.shownInterestSharePercChange, 2) : '-'}
                  {groupDemographics ? groupDemographics.shownInterestShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {withKidsDemographics ? toMaxPrecisionPercent(withKidsDemographics.shownInterestSharePercChange, 2) : '-'}
                  {withKidsDemographics ? withKidsDemographics.shownInterestShareSignificance : '-'}
                </td>
              </tr>
              <tr>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.shownInterestShareA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.shownInterestShareB, 2)}</td>
                <td className="text-center">{femaleDemographics ? toMaxPrecisionPercent(femaleDemographics.shownInterestShareA, 2) : '-'}</td>
                <td className="text-center">{femaleDemographics ? toMaxPrecisionPercent(femaleDemographics.shownInterestShareB, 2) : '-'}</td>
                <td className="text-center">{maleDemographics ? toMaxPrecisionPercent(maleDemographics.shownInterestShareA, 2) : '-'}</td>
                <td className="text-center">{maleDemographics ? toMaxPrecisionPercent(maleDemographics.shownInterestShareB, 2) : '-'}</td>
                <td className="text-center">{groupDemographics ? toMaxPrecisionPercent(groupDemographics.shownInterestShareA, 2) : '-'}</td>
                <td className="text-center">{groupDemographics ? toMaxPrecisionPercent(groupDemographics.shownInterestShareB, 2) : '-'}</td>
                <td className="text-center">{withKidsDemographics ? toMaxPrecisionPercent(withKidsDemographics.shownInterestShareA, 2) : '-'}</td>
                <td className="text-center">{withKidsDemographics ? toMaxPrecisionPercent(withKidsDemographics.shownInterestShareB, 2) : '-'}</td>
              </tr>

              <tr>
                <td className="text-center metricNameCell" rowSpan={2}>
                  Shoppers(interact)
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewReport.interactedSharePercChange, 2)}
                  {overviewReport.interactedShareSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {femaleDemographics ? toMaxPrecisionPercent(femaleDemographics.interactedSharePercChange, 2) : '-'}
                  {femaleDemographics ? femaleDemographics.interactedShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {maleDemographics ? toMaxPrecisionPercent(maleDemographics.interactedSharePercChange, 2) : '-'}
                  {maleDemographics ? maleDemographics.interactedShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {groupDemographics ? toMaxPrecisionPercent(groupDemographics.interactedSharePercChange, 2) : '-'}
                  {groupDemographics ? groupDemographics.interactedShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {withKidsDemographics ? toMaxPrecisionPercent(withKidsDemographics.interactedSharePercChange, 2) : '-'}
                  {withKidsDemographics ? withKidsDemographics.interactedShareSignificance : '-'}
                </td>
              </tr>
              <tr>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.interactedShareA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.interactedShareB, 2)}</td>
                <td className="text-center">{femaleDemographics ? toMaxPrecisionPercent(femaleDemographics.interactedShareA, 2) : '-'}</td>
                <td className="text-center">{femaleDemographics ? toMaxPrecisionPercent(femaleDemographics.interactedShareB, 2) : '-'}</td>
                <td className="text-center">{maleDemographics ? toMaxPrecisionPercent(maleDemographics.interactedShareA, 2) : '-'}</td>
                <td className="text-center">{maleDemographics ? toMaxPrecisionPercent(maleDemographics.interactedShareB, 2) : '-'}</td>
                <td className="text-center">{groupDemographics ? toMaxPrecisionPercent(groupDemographics.interactedShareA, 2) : '-'}</td>
                <td className="text-center">{groupDemographics ? toMaxPrecisionPercent(groupDemographics.interactedShareB, 2) : '-'}</td>
                <td className="text-center">{withKidsDemographics ? toMaxPrecisionPercent(withKidsDemographics.interactedShareA, 2) : '-'}</td>
                <td className="text-center">{withKidsDemographics ? toMaxPrecisionPercent(withKidsDemographics.interactedShareB, 2) : '-'}</td>
              </tr>

              <tr>
                <td className="text-center metricNameCell" rowSpan={2}>
                  Buyers(purchased)
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewReport.purchasedSharePercChange, 2)}
                  {overviewReport.purchasedShareSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {femaleDemographics ? toMaxPrecisionPercent(femaleDemographics.purchasedSharePercChange, 2) : '-'}
                  {femaleDemographics ? femaleDemographics.purchasedShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {maleDemographics ? toMaxPrecisionPercent(maleDemographics.purchasedSharePercChange, 2) : '-'}
                  {maleDemographics ? maleDemographics.purchasedShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {groupDemographics ? toMaxPrecisionPercent(groupDemographics.purchasedSharePercChange, 2) : '-'}
                  {groupDemographics ? groupDemographics.purchasedShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {withKidsDemographics ? toMaxPrecisionPercent(withKidsDemographics.purchasedSharePercChange, 2) : '-'}
                  {withKidsDemographics ? withKidsDemographics.purchasedShareSignificance : '-'}
                </td>
              </tr>
              <tr>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.purchasedShareA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.purchasedShareB, 2)}</td>
                <td className="text-center">{femaleDemographics ? toMaxPrecisionPercent(femaleDemographics.purchasedShareA, 2) : '-'}</td>
                <td className="text-center">{femaleDemographics ? toMaxPrecisionPercent(femaleDemographics.purchasedShareB, 2) : '-'}</td>
                <td className="text-center">{maleDemographics ? toMaxPrecisionPercent(maleDemographics.purchasedShareA, 2) : '-'}</td>
                <td className="text-center">{maleDemographics ? toMaxPrecisionPercent(maleDemographics.purchasedShareB, 2) : '-'}</td>
                <td className="text-center">{groupDemographics ? toMaxPrecisionPercent(groupDemographics.purchasedShareA, 2) : '-'}</td>
                <td className="text-center">{groupDemographics ? toMaxPrecisionPercent(groupDemographics.purchasedShareB, 2) : '-'}</td>
                <td className="text-center">{withKidsDemographics ? toMaxPrecisionPercent(withKidsDemographics.purchasedShareA, 2) : '-'}</td>
                <td className="text-center">{withKidsDemographics ? toMaxPrecisionPercent(withKidsDemographics.purchasedShareB, 2) : '-'}</td>
              </tr>

              <tr>
                <td className="text-center metricNameCell" rowSpan={2}>
                  Lost shoppers
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewReport.lostShoppersSharePercChange, 2)}
                  {overviewReport.lostShoppersShareSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {femaleDemographics ? toMaxPrecisionPercent(femaleDemographics.lostShoppersSharePercChange, 2) : '-'}
                  {femaleDemographics ? femaleDemographics.lostShoppersShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {maleDemographics ? toMaxPrecisionPercent(maleDemographics.lostShoppersSharePercChange, 2) : '-'}
                  {maleDemographics ? maleDemographics.lostShoppersShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {groupDemographics ? toMaxPrecisionPercent(groupDemographics.lostShoppersSharePercChange, 2) : '-'}
                  {groupDemographics ? groupDemographics.lostShoppersShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {withKidsDemographics ? toMaxPrecisionPercent(withKidsDemographics.lostShoppersSharePercChange, 2) : '-'}
                  {withKidsDemographics ? withKidsDemographics.lostShoppersShareSignificance : '-'}
                </td>
              </tr>
              <tr>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.lostShoppersShareA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.lostShoppersShareB, 2)}</td>
                <td className="text-center">{femaleDemographics ? toMaxPrecisionPercent(femaleDemographics.lostShoppersShareA, 2) : '-'}</td>
                <td className="text-center">{femaleDemographics ? toMaxPrecisionPercent(femaleDemographics.lostShoppersShareB, 2) : '-'}</td>
                <td className="text-center">{maleDemographics ? toMaxPrecisionPercent(maleDemographics.lostShoppersShareA, 2) : '-'}</td>
                <td className="text-center">{maleDemographics ? toMaxPrecisionPercent(maleDemographics.lostShoppersShareB, 2) : '-'}</td>
                <td className="text-center">{groupDemographics ? toMaxPrecisionPercent(groupDemographics.lostShoppersShareA, 2) : '-'}</td>
                <td className="text-center">{groupDemographics ? toMaxPrecisionPercent(groupDemographics.lostShoppersShareB, 2) : '-'}</td>
                <td className="text-center">{withKidsDemographics ? toMaxPrecisionPercent(withKidsDemographics.lostShoppersShareA, 2) : '-'}</td>
                <td className="text-center">{withKidsDemographics ? toMaxPrecisionPercent(withKidsDemographics.lostShoppersShareB, 2) : '-'}</td>
              </tr>

              <tr>
                <td className="text-center metricNameCell" rowSpan={2}>
                  Assisted browsers
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {overviewReport.assistedBrowsersSharePercChange ? toMaxPrecisionPercent(overviewReport.assistedBrowsersSharePercChange, 2) : '0%'}
                  {overviewReport.assistedBrowsersShareSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {femaleDemographics ? toMaxPrecisionPercent(femaleDemographics.assistedBrowsersSharePercChange, 2) : '-'}
                  {femaleDemographics ? femaleDemographics.assistedBrowsersShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {maleDemographics ? toMaxPrecisionPercent(maleDemographics.assistedBrowsersSharePercChange, 2) : '-'}
                  {maleDemographics ? maleDemographics.assistedBrowsersShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {groupDemographics ? toMaxPrecisionPercent(groupDemographics.assistedBrowsersSharePercChange, 2) : '-'}
                  {groupDemographics ? groupDemographics.assistedBrowsersShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {withKidsDemographics ? toMaxPrecisionPercent(withKidsDemographics.assistedBrowsersSharePercChange, 2) : '-'}
                  {withKidsDemographics ? withKidsDemographics.assistedBrowsersShareSignificance : '-'}
                </td>
              </tr>
              <tr>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.assistedBrowsersShareA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.assistedBrowsersShareB, 2)}</td>
                <td className="text-center">{femaleDemographics ? toMaxPrecisionPercent(femaleDemographics.assistedBrowsersShareA, 2) : '-'}</td>
                <td className="text-center">{femaleDemographics ? toMaxPrecisionPercent(femaleDemographics.assistedBrowsersShareB, 2) : '-'}</td>
                <td className="text-center">{maleDemographics ? toMaxPrecisionPercent(maleDemographics.assistedBrowsersShareA, 2) : '-'}</td>
                <td className="text-center">{maleDemographics ? toMaxPrecisionPercent(maleDemographics.assistedBrowsersShareB, 2) : '-'}</td>
                <td className="text-center">{groupDemographics ? toMaxPrecisionPercent(groupDemographics.assistedBrowsersShareA, 2) : '-'}</td>
                <td className="text-center">{groupDemographics ? toMaxPrecisionPercent(groupDemographics.assistedBrowsersShareB, 2) : '-'}</td>
                <td className="text-center">{withKidsDemographics ? toMaxPrecisionPercent(withKidsDemographics.assistedBrowsersShareA, 2) : '-'}</td>
                <td className="text-center">{withKidsDemographics ? toMaxPrecisionPercent(withKidsDemographics.assistedBrowsersShareB, 2) : '-'}</td>
              </tr>

              <tr>
                <td className="text-center metricNameCell" rowSpan={2}>
                  Conversion from assistance
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewReport.conversionFromAssistancePercChange, 2)}
                  {overviewReport.conversionFromAssistanceSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {femaleDemographics ? toMaxPrecisionPercent(femaleDemographics.conversionFromAssistancePercChange, 2) : '-'}
                  {femaleDemographics ? femaleDemographics.conversionFromAssistanceSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {maleDemographics ? toMaxPrecisionPercent(maleDemographics.conversionFromAssistancePercChange, 2) : '-'}
                  {maleDemographics ? maleDemographics.conversionFromAssistanceSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {groupDemographics ? toMaxPrecisionPercent(groupDemographics.conversionFromAssistancePercChange, 2) : '-'}
                  {groupDemographics ? groupDemographics.conversionFromAssistanceSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {withKidsDemographics ? toMaxPrecisionPercent(withKidsDemographics.conversionFromAssistancePercChange, 2) : '-'}
                  {withKidsDemographics ? withKidsDemographics.conversionFromAssistanceSignificance : '-'}
                </td>
              </tr>
              <tr>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.conversionFromAssistanceA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.conversionFromAssistanceB, 2)}</td>
                <td className="text-center">{femaleDemographics ? toMaxPrecisionPercent(femaleDemographics.conversionFromAssistanceA, 2) : '-'}</td>
                <td className="text-center">{femaleDemographics ? toMaxPrecisionPercent(femaleDemographics.conversionFromAssistanceB, 2) : '-'}</td>
                <td className="text-center">{maleDemographics ? toMaxPrecisionPercent(maleDemographics.conversionFromAssistanceA, 2) : '-'}</td>
                <td className="text-center">{maleDemographics ? toMaxPrecisionPercent(maleDemographics.conversionFromAssistanceB, 2) : '-'}</td>
                <td className="text-center">{groupDemographics ? toMaxPrecisionPercent(groupDemographics.conversionFromAssistanceA, 2) : '-'}</td>
                <td className="text-center">{groupDemographics ? toMaxPrecisionPercent(groupDemographics.conversionFromAssistanceB, 2) : '-'}</td>
                <td className="text-center">{withKidsDemographics ? toMaxPrecisionPercent(withKidsDemographics.conversionFromAssistanceA, 2) : '-'}</td>
                <td className="text-center">{withKidsDemographics ? toMaxPrecisionPercent(withKidsDemographics.conversionFromAssistanceB, 2) : '-'}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="row">
          <table style={{ marginTop: '10px' }} className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-center metricNameCell">Shopping time</td>
                <td className="text-center metricNameCell" colSpan={2}>
                  Total
                </td>
                <td className="text-center metricNameCell" colSpan={2}>
                  Weekday before 4pm
                </td>
                <td className="text-center metricNameCell" colSpan={2}>
                  Weekday after 4pm
                </td>
                <td className="text-center metricNameCell" colSpan={2}>
                  Weekend before 4pm(inc. friday)
                </td>
                <td className="text-center metricNameCell" colSpan={2}>
                  Weekend after 4pm(inc. friday)
                </td>
              </tr>
              <tr>
                <td className="text-center metricNameCell" rowSpan={2}>
                  Visitors(at shelf)
                </td>
                <td className="text-center" colSpan={2}>
                  -
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {weekdayBefore4pm ? toMaxPrecisionPercent(weekdayBefore4pm.visitorsSharePercChange, 2) : '-'}
                  {weekdayBefore4pm ? weekdayBefore4pm.visitorsShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {weekdayAfter4pm ? toMaxPrecisionPercent(weekdayAfter4pm.visitorsSharePercChange, 2) : '-'}
                  {weekdayAfter4pm ? weekdayAfter4pm.visitorsShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {weekendBefore4pm ? toMaxPrecisionPercent(weekendBefore4pm.visitorsSharePercChange, 2) : '-'}
                  {weekendBefore4pm ? weekendBefore4pm.visitorsShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {weekendAfter4pm ? toMaxPrecisionPercent(weekendAfter4pm.visitorsSharePercChange, 2) : '-'}
                  {weekendAfter4pm ? weekendAfter4pm.visitorsShareSignificance : '-'}
                </td>
              </tr>
              <tr>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.visitorsShareA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.visitorsShareB, 2)}</td>
                <td className="text-center">{weekdayBefore4pm ? toMaxPrecisionPercent(weekdayBefore4pm.visitorsShareA, 2) : '-'}</td>
                <td className="text-center">{weekdayBefore4pm ? toMaxPrecisionPercent(weekdayBefore4pm.visitorsShareB, 2) : '-'}</td>
                <td className="text-center">{weekdayAfter4pm ? toMaxPrecisionPercent(weekdayAfter4pm.visitorsShareA, 2) : '-'}</td>
                <td className="text-center">{weekdayAfter4pm ? toMaxPrecisionPercent(weekdayAfter4pm.visitorsShareB, 2) : '-'}</td>
                <td className="text-center">{weekendBefore4pm ? toMaxPrecisionPercent(weekendBefore4pm.visitorsShareA, 2) : '-'}</td>
                <td className="text-center">{weekendBefore4pm ? toMaxPrecisionPercent(weekendBefore4pm.visitorsShareB, 2) : '-'}</td>
                <td className="text-center">{weekendAfter4pm ? toMaxPrecisionPercent(weekendAfter4pm.visitorsShareA, 2) : '-'}</td>
                <td className="text-center">{weekendAfter4pm ? toMaxPrecisionPercent(weekendAfter4pm.visitorsShareB, 2) : '-'}</td>
              </tr>

              <tr>
                <td className="text-center metricNameCell" rowSpan={2}>
                  Browsers(interest)
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewReport.shownInterestSharePercChange, 2)}
                  {overviewReport.shownInterestShareSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {weekdayBefore4pm ? toMaxPrecisionPercent(weekdayBefore4pm.shownInterestSharePercChange, 2) : '-'}
                  {weekdayBefore4pm ? weekdayBefore4pm.shownInterestShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {weekdayAfter4pm ? toMaxPrecisionPercent(weekdayAfter4pm.shownInterestSharePercChange, 2) : '-'}
                  {weekdayAfter4pm ? weekdayAfter4pm.shownInterestShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {weekendBefore4pm ? toMaxPrecisionPercent(weekendBefore4pm.shownInterestSharePercChange, 2) : '-'}
                  {weekendBefore4pm ? weekendBefore4pm.shownInterestShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {weekendAfter4pm ? toMaxPrecisionPercent(weekendAfter4pm.shownInterestSharePercChange, 2) : '-'}
                  {weekendAfter4pm ? weekendAfter4pm.shownInterestShareSignificance : '-'}
                </td>
              </tr>
              <tr>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.shownInterestShareA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.shownInterestShareB, 2)}</td>
                <td className="text-center">{weekdayBefore4pm ? toMaxPrecisionPercent(weekdayBefore4pm.shownInterestShareA, 2) : '-'}</td>
                <td className="text-center">{weekdayBefore4pm ? toMaxPrecisionPercent(weekdayBefore4pm.shownInterestShareB, 2) : '-'}</td>
                <td className="text-center">{weekdayAfter4pm ? toMaxPrecisionPercent(weekdayAfter4pm.shownInterestShareA, 2) : '-'}</td>
                <td className="text-center">{weekdayAfter4pm ? toMaxPrecisionPercent(weekdayAfter4pm.shownInterestShareB, 2) : '-'}</td>
                <td className="text-center">{weekendBefore4pm ? toMaxPrecisionPercent(weekendBefore4pm.shownInterestShareA, 2) : '-'}</td>
                <td className="text-center">{weekendBefore4pm ? toMaxPrecisionPercent(weekendBefore4pm.shownInterestShareB, 2) : '-'}</td>
                <td className="text-center">{weekendAfter4pm ? toMaxPrecisionPercent(weekendAfter4pm.shownInterestShareA, 2) : '-'}</td>
                <td className="text-center">{weekendAfter4pm ? toMaxPrecisionPercent(weekendAfter4pm.shownInterestShareB, 2) : '-'}</td>
              </tr>

              <tr>
                <td className="text-center metricNameCell" rowSpan={2}>
                  Shoppers(interact)
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewReport.interactedSharePercChange, 2)}
                  {overviewReport.interactedShareSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {weekdayBefore4pm ? toMaxPrecisionPercent(weekdayBefore4pm.interactedSharePercChange, 2) : '-'}
                  {weekdayBefore4pm ? weekdayBefore4pm.interactedShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {weekdayAfter4pm ? toMaxPrecisionPercent(weekdayAfter4pm.interactedSharePercChange, 2) : '-'}
                  {weekdayAfter4pm ? weekdayAfter4pm.interactedShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {weekendBefore4pm ? toMaxPrecisionPercent(weekendBefore4pm.interactedSharePercChange, 2) : '-'}
                  {weekendBefore4pm ? weekendBefore4pm.interactedShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {weekendAfter4pm ? toMaxPrecisionPercent(weekendAfter4pm.interactedSharePercChange, 2) : '-'}
                  {weekendAfter4pm ? weekendAfter4pm.interactedShareSignificance : '-'}
                </td>
              </tr>
              <tr>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.interactedShareA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.interactedShareB, 2)}</td>
                <td className="text-center">{weekdayBefore4pm ? toMaxPrecisionPercent(weekdayBefore4pm.interactedShareA, 2) : '-'}</td>
                <td className="text-center">{weekdayBefore4pm ? toMaxPrecisionPercent(weekdayBefore4pm.interactedShareB, 2) : '-'}</td>
                <td className="text-center">{weekdayAfter4pm ? toMaxPrecisionPercent(weekdayAfter4pm.interactedShareA, 2) : '-'}</td>
                <td className="text-center">{weekdayAfter4pm ? toMaxPrecisionPercent(weekdayAfter4pm.interactedShareB, 2) : '-'}</td>
                <td className="text-center">{weekendBefore4pm ? toMaxPrecisionPercent(weekendBefore4pm.interactedShareA, 2) : '-'}</td>
                <td className="text-center">{weekendBefore4pm ? toMaxPrecisionPercent(weekendBefore4pm.interactedShareB, 2) : '-'}</td>
                <td className="text-center">{weekendAfter4pm ? toMaxPrecisionPercent(weekendAfter4pm.interactedShareA, 2) : '-'}</td>
                <td className="text-center">{weekendAfter4pm ? toMaxPrecisionPercent(weekendAfter4pm.interactedShareB, 2) : '-'}</td>
              </tr>

              <tr>
                <td className="text-center metricNameCell" rowSpan={2}>
                  Buyers(purchased)
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewReport.purchasedSharePercChange, 2)}
                  {overviewReport.purchasedShareSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {weekdayBefore4pm ? toMaxPrecisionPercent(weekdayBefore4pm.purchasedSharePercChange, 2) : '-'}
                  {weekdayBefore4pm ? weekdayBefore4pm.purchasedShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {weekdayAfter4pm ? toMaxPrecisionPercent(weekdayAfter4pm.purchasedSharePercChange, 2) : '-'}
                  {weekdayAfter4pm ? weekdayAfter4pm.purchasedShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {weekendBefore4pm ? toMaxPrecisionPercent(weekendBefore4pm.purchasedSharePercChange, 2) : '-'}
                  {weekendBefore4pm ? weekendBefore4pm.purchasedShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {weekendAfter4pm ? toMaxPrecisionPercent(weekendAfter4pm.purchasedSharePercChange, 2) : '-'}
                  {weekendAfter4pm ? weekendAfter4pm.purchasedShareSignificance : '-'}
                </td>
              </tr>
              <tr>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.purchasedShareA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.purchasedShareB, 2)}</td>
                <td className="text-center">{weekdayBefore4pm ? toMaxPrecisionPercent(weekdayBefore4pm.purchasedShareA, 2) : '-'}</td>
                <td className="text-center">{weekdayBefore4pm ? toMaxPrecisionPercent(weekdayBefore4pm.purchasedShareB, 2) : '-'}</td>
                <td className="text-center">{weekdayAfter4pm ? toMaxPrecisionPercent(weekdayAfter4pm.purchasedShareA, 2) : '-'}</td>
                <td className="text-center">{weekdayAfter4pm ? toMaxPrecisionPercent(weekdayAfter4pm.purchasedShareB, 2) : '-'}</td>
                <td className="text-center">{weekendBefore4pm ? toMaxPrecisionPercent(weekendBefore4pm.purchasedShareA, 2) : '-'}</td>
                <td className="text-center">{weekendBefore4pm ? toMaxPrecisionPercent(weekendBefore4pm.purchasedShareB, 2) : '-'}</td>
                <td className="text-center">{weekendAfter4pm ? toMaxPrecisionPercent(weekendAfter4pm.purchasedShareA, 2) : '-'}</td>
                <td className="text-center">{weekendAfter4pm ? toMaxPrecisionPercent(weekendAfter4pm.purchasedShareB, 2) : '-'}</td>
              </tr>

              <tr>
                <td className="text-center metricNameCell" rowSpan={2}>
                  Lost shoppers
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewReport.lostShoppersSharePercChange, 2)}
                  {overviewReport.lostShoppersShareSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {weekdayBefore4pm ? toMaxPrecisionPercent(weekdayBefore4pm.lostShoppersSharePercChange, 2) : '-'}
                  {weekdayBefore4pm ? weekdayBefore4pm.lostShoppersShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {weekdayAfter4pm ? toMaxPrecisionPercent(weekdayAfter4pm.lostShoppersSharePercChange, 2) : '-'}
                  {weekdayAfter4pm ? weekdayAfter4pm.lostShoppersShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {weekendBefore4pm ? toMaxPrecisionPercent(weekendBefore4pm.lostShoppersSharePercChange, 2) : '-'}
                  {weekendBefore4pm ? weekendBefore4pm.lostShoppersShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {weekendAfter4pm ? toMaxPrecisionPercent(weekendAfter4pm.lostShoppersSharePercChange, 2) : '-'}
                  {weekendAfter4pm ? weekendAfter4pm.lostShoppersShareSignificance : '-'}
                </td>
              </tr>
              <tr>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.lostShoppersShareA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.lostShoppersShareB, 2)}</td>
                <td className="text-center">{weekdayBefore4pm ? toMaxPrecisionPercent(weekdayBefore4pm.lostShoppersShareA, 2) : '-'}</td>
                <td className="text-center">{weekdayBefore4pm ? toMaxPrecisionPercent(weekdayBefore4pm.lostShoppersShareB, 2) : '-'}</td>
                <td className="text-center">{weekdayAfter4pm ? toMaxPrecisionPercent(weekdayAfter4pm.lostShoppersShareA, 2) : '-'}</td>
                <td className="text-center">{weekdayAfter4pm ? toMaxPrecisionPercent(weekdayAfter4pm.lostShoppersShareB, 2) : '-'}</td>
                <td className="text-center">{weekendBefore4pm ? toMaxPrecisionPercent(weekendBefore4pm.lostShoppersShareA, 2) : '-'}</td>
                <td className="text-center">{weekendBefore4pm ? toMaxPrecisionPercent(weekendBefore4pm.lostShoppersShareB, 2) : '-'}</td>
                <td className="text-center">{weekendAfter4pm ? toMaxPrecisionPercent(weekendAfter4pm.lostShoppersShareA, 2) : '-'}</td>
                <td className="text-center">{weekendAfter4pm ? toMaxPrecisionPercent(weekendAfter4pm.lostShoppersShareB, 2) : '-'}</td>
              </tr>

              <tr>
                <td className="text-center metricNameCell" rowSpan={2}>
                  Assisted browsers
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {overviewReport.assistedBrowsersSharePercChange ? toMaxPrecisionPercent(overviewReport.assistedBrowsersSharePercChange, 2) : '0%'}
                  {overviewReport.assistedBrowsersShareSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {weekdayBefore4pm ? toMaxPrecisionPercent(weekdayBefore4pm.assistedBrowsersSharePercChange, 2) : '-'}
                  {weekdayBefore4pm ? weekdayBefore4pm.assistedBrowsersShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {weekdayAfter4pm ? toMaxPrecisionPercent(weekdayAfter4pm.assistedBrowsersSharePercChange, 2) : '-'}
                  {weekdayAfter4pm ? weekdayAfter4pm.assistedBrowsersShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {weekendBefore4pm ? toMaxPrecisionPercent(weekendBefore4pm.assistedBrowsersSharePercChange, 2) : '-'}
                  {weekendBefore4pm ? weekendBefore4pm.assistedBrowsersShareSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {weekendAfter4pm ? toMaxPrecisionPercent(weekendAfter4pm.assistedBrowsersSharePercChange, 2) : '-'}
                  {weekendAfter4pm ? weekendAfter4pm.assistedBrowsersShareSignificance : '-'}
                </td>
              </tr>
              <tr>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.assistedBrowsersShareA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.assistedBrowsersShareB, 2)}</td>
                <td className="text-center">{weekdayBefore4pm ? toMaxPrecisionPercent(weekdayBefore4pm.assistedBrowsersShareA, 2) : '-'}</td>
                <td className="text-center">{weekdayBefore4pm ? toMaxPrecisionPercent(weekdayBefore4pm.assistedBrowsersShareB, 2) : '-'}</td>
                <td className="text-center">{weekdayAfter4pm ? toMaxPrecisionPercent(weekdayAfter4pm.assistedBrowsersShareA, 2) : '-'}</td>
                <td className="text-center">{weekdayAfter4pm ? toMaxPrecisionPercent(weekdayAfter4pm.assistedBrowsersShareB, 2) : '-'}</td>
                <td className="text-center">{weekendBefore4pm ? toMaxPrecisionPercent(weekendBefore4pm.assistedBrowsersShareA, 2) : '-'}</td>
                <td className="text-center">{weekendBefore4pm ? toMaxPrecisionPercent(weekendBefore4pm.assistedBrowsersShareB, 2) : '-'}</td>
                <td className="text-center">{weekendAfter4pm ? toMaxPrecisionPercent(weekendAfter4pm.assistedBrowsersShareA, 2) : '-'}</td>
                <td className="text-center">{weekendAfter4pm ? toMaxPrecisionPercent(weekendAfter4pm.assistedBrowsersShareB, 2) : '-'}</td>
              </tr>

              <tr>
                <td className="text-center metricNameCell" rowSpan={2}>
                  Conversion from assistance
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(overviewReport.conversionFromAssistancePercChange, 2)}
                  {overviewReport.conversionFromAssistanceSignificance}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {weekdayBefore4pm ? toMaxPrecisionPercent(weekdayBefore4pm.conversionFromAssistancePercChange, 2) : '-'}
                  {weekdayBefore4pm ? weekdayBefore4pm.conversionFromAssistanceSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {weekdayAfter4pm ? toMaxPrecisionPercent(weekdayAfter4pm.conversionFromAssistancePercChange, 2) : '-'}
                  {weekdayAfter4pm ? weekdayAfter4pm.conversionFromAssistanceSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {weekendBefore4pm ? toMaxPrecisionPercent(weekendBefore4pm.conversionFromAssistancePercChange, 2) : '-'}
                  {weekendBefore4pm ? weekendBefore4pm.conversionFromAssistanceSignificance : '-'}
                </td>
                <td className="text-center" colSpan={2} style={{ whiteSpace: 'nowrap' }}>
                  {weekendAfter4pm ? toMaxPrecisionPercent(weekendAfter4pm.conversionFromAssistancePercChange, 2) : '-'}
                  {weekendAfter4pm ? weekendAfter4pm.conversionFromAssistanceSignificance : '-'}
                </td>
              </tr>
              <tr>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.conversionFromAssistanceA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.conversionFromAssistanceB, 2)}</td>
                <td className="text-center">{weekdayBefore4pm ? toMaxPrecisionPercent(weekdayBefore4pm.conversionFromAssistanceA, 2) : '-'}</td>
                <td className="text-center">{weekdayBefore4pm ? toMaxPrecisionPercent(weekdayBefore4pm.conversionFromAssistanceB, 2) : '-'}</td>
                <td className="text-center">{weekdayAfter4pm ? toMaxPrecisionPercent(weekdayAfter4pm.conversionFromAssistanceA, 2) : '-'}</td>
                <td className="text-center">{weekdayAfter4pm ? toMaxPrecisionPercent(weekdayAfter4pm.conversionFromAssistanceB, 2) : '-'}</td>
                <td className="text-center">{weekendBefore4pm ? toMaxPrecisionPercent(weekendBefore4pm.conversionFromAssistanceA, 2) : '-'}</td>
                <td className="text-center">{weekendBefore4pm ? toMaxPrecisionPercent(weekendBefore4pm.conversionFromAssistanceB, 2) : '-'}</td>
                <td className="text-center">{weekendAfter4pm ? toMaxPrecisionPercent(weekendAfter4pm.conversionFromAssistanceA, 2) : '-'}</td>
                <td className="text-center">{weekendAfter4pm ? toMaxPrecisionPercent(weekendAfter4pm.conversionFromAssistanceB, 2) : '-'}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <footer>
        <strong>Copyright &copy; {new Date().getFullYear()} Flow Insights</strong>. Strictly confidential. No distribution allowed. All rights reserved.
      </footer>
    </div>
  );
};

export { StandardShopperComparisonReportPage };
