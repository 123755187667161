import * as React from 'react';
import { DropdownToggle, Nav } from 'reactstrap';
import { AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import logo from '../../assets/img/brand/flow.jpg';
import logoSmall from '../../assets/img/brand/flow-logo.png';
import { State } from 'state';
import { Lock } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import routes from '../../routing/headerRoutes';
import { Switch, Route } from 'react-router';
import { AsideToggler } from '../_Shared/AsideToggler';

class Header extends React.Component<State & any, any> {
  render() {
    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          full={{
            src: logo,
            width: 100,
            height: 50,
            alt: 'Flow Insights Logo',
          }}
          minimized={{
            src: logoSmall,
            width: 30,
            height: 30,
            alt: 'Flow Insights Logo',
          }}
        />
        <Switch>
          {routes.map((route, idx) => {
            return route.component ? <Route key={idx} path={route.path} exact={route.exact} name={route.name} component={route.component} /> : null;
          })}
        </Switch>
        <Nav className="ml-auto" navbar>
          <AppHeaderDropdown direction="down">
            <DropdownToggle nav>
              <Lock fontSize="small" />
              &nbsp;
              <Typography variant="overline">View Only</Typography>
              &nbsp;
            </DropdownToggle>
          </AppHeaderDropdown>
        </Nav>
        <AsideToggler toggle={this.props.toggleAside} />
      </React.Fragment>
    );
  }
}

export default Header;
