import * as React from 'react';
import { ProductPanel } from 'modules/shared/components/ProductPanel';
import { useDispatch, useSelector } from 'react-redux';
import { fixImageSizeRequest, toggleEnableShowProductInfo } from '../actions';
import { getSelectedProductInfo } from '../selectors';

export interface PlanogramFloatingPanelProps {
  canFixImageSize: boolean;
}

export const PlanogramFloatingPanel = ({ canFixImageSize = false }: PlanogramFloatingPanelProps): JSX.Element => {
  const dispatch = useDispatch();
  const data = useSelector(getSelectedProductInfo);

  return (
    <React.Fragment>
      {data && (
        <ProductPanel
          product={data.details}
          imageUrl={data.shelfLocation.imageUrl}
          hasFixedImage={data.shelfLocation.geometry.hasFixedImage}
          fixingImageSize={data.isFixingImageSize}
          shelfLocationData={data.shelfLocationData}
          shelfLocationPairRankingData={data.shelfLocationPairRankingData}
          selectedMetricName={data.selectedMetricsName}
          onFixImageSize={(): void => {
            if (canFixImageSize) {
              dispatch(fixImageSizeRequest(data.planogramId, data.shelfLocation));
            }
          }}
          onClose={(): void => {
            dispatch(toggleEnableShowProductInfo());
          }}
        />
      )}
    </React.Fragment>
  );
};
