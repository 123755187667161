import { isLoaded, isLoading } from 'infrastructure/utils/RemoteObjectStatus';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { loadPotentialBrandReportRequestAction } from '../../actions';
import { getBrandPotentialReportLoadState, getBrandPotentialReportData, isBrandPotentialReportEmpty } from '../../selectors';
import { BrandPotentialReport } from '../../types';

interface LoadStatusParams {
  isLoaded: boolean;
  isLoading: boolean;
  loadError: string;
  isDataEmpty: boolean;
  data: BrandPotentialReport | undefined;
}

export interface BrandPotentialReportLoaderProps {
  children: (params: LoadStatusParams) => React.ReactNode;
}

const BrandPotentialReportLoader: React.FC<BrandPotentialReportLoaderProps> = ({ children }: BrandPotentialReportLoaderProps) => {
  type RequestParamsType = {
    shelfPhaseId: string;
    brandId: string;
  };

  const { shelfPhaseId, brandId } = useParams<RequestParamsType>();
  const dispatch = useDispatch();
  const loadState = useSelector(getBrandPotentialReportLoadState);
  const isDataEmpty = useSelector(isBrandPotentialReportEmpty);
  const data = useSelector(getBrandPotentialReportData);
  useEffect(() => {
    if (isDataEmpty && shelfPhaseId && brandId !== undefined) {
      dispatch(loadPotentialBrandReportRequestAction(shelfPhaseId, parseInt(brandId)));
    }
  }, [dispatch, isDataEmpty, shelfPhaseId, brandId]);
  return (
    <>
      {children({
        isLoaded: isLoaded(loadState),
        isLoading: isLoading(loadState),
        loadError: loadState?.errorDetails?.errorMessage ?? '',
        isDataEmpty,
        data,
      })}
    </>
  );
};

export { BrandPotentialReportLoader };
