import { Type } from 'class-transformer';
import TestInfoDto from '../../../planogram/types/TestInfoDto';
import CompetitorShelf from './CompetitorShelf';
import { ShelfConditionsModel } from '../../../../types/ShelfConditionsModel';
import { InspirationImagesModel } from '../../../../types/InspirationImagesModel';

export default class AdminPlanogramViewModel {
  planogramId = '';
  shelfReferenceId = '';
  masterPlanogramId = '';
  userEmail = '';
  ownerId = 0;
  builderId = 0;
  planogramName = '';
  projectName = '';
  storeName = '';
  phaseNum = 0;
  shelfNum = 0;
  shelfImageUrl = '';
  shelfConditions: ShelfConditionsModel = {
    isShelfConditionsEnabled: false,
  };

  inspirationImages: InspirationImagesModel = {
    isInspirationImagesEnabled: false,
  };

  @Type(() => Date)
  lastOpenedOn?: Date;

  @Type(() => Date)
  lastUpdatedOn?: Date;

  @Type(() => Date)
  createdOn: Date = new Date();

  testInfoList: TestInfoDto[] = [];
  competitorShelves: CompetitorShelf[] = [];
}
