import { RemoteObjectStatusInterface } from 'infrastructure/utils/RemoteObjectStatus';
import { createSelector, Selector } from 'reselect';
import { State } from '../../../state';
import StandardReportsState from './state';
import {
  BrandComparisonReportResult,
  BrandReportResult,
  ClientBenchmarkReportResult,
  GpcBrickComparisonReportResult,
  GpcBrickReportResult,
  ProductComparisonReportResult,
  ProductReportResult,
  ProjectBenchmarkReportResult,
  ProjectComparisonReportResult,
  ProjectReportResult,
  ShelfComparisonReportResult,
  ShopperComparisonReportResult,
  ShopperReportResult,
  StandardReportPhaseShelf,
  BrandPotentialReport,
  ProductBenchmarkReport,
} from './types';
const getState = (state: State): StandardReportsState => state.standardReports;

// shelf selector selectors
export const getLoadState: Selector<State, RemoteObjectStatusInterface<StandardReportPhaseShelf[]>> = createSelector(getState, state => state.projectStorePhaseShelvesObject);

export const getData: Selector<State, StandardReportPhaseShelf[]> = createSelector(getState, state => {
  return state.projectStorePhaseShelvesObject.data as StandardReportPhaseShelf[];
});

export const isDataEmpty: Selector<State, boolean> = createSelector(getData, data => {
  return data === null || data === undefined || data.length === 0;
});

// project report selectors
export const getProjectReportLoadState: Selector<State, RemoteObjectStatusInterface<ProjectReportResult>> = createSelector(getState, state => {
  return state.standardProjectReportStatus;
});
export const getProjectReportData: Selector<State, ProjectReportResult | undefined> = createSelector(getState, state => {
  return state.standardProjectReportStatus.data;
});

export const isProjectReportEmpty: Selector<State, boolean> = createSelector(getProjectReportData, data => {
  return data === null || data === undefined;
});

// shopper report selectors
export const getShopperReportLoadState: Selector<State, RemoteObjectStatusInterface<ShopperReportResult>> = createSelector(getState, state => {
  return state.standardShopperReportStatus;
});
export const getShopperReportData: Selector<State, ShopperReportResult | undefined> = createSelector(getState, state => {
  return state.standardShopperReportStatus.data;
});
export const isShopperReportEmpty: Selector<State, boolean> = createSelector(getShopperReportData, data => {
  return data === null || data === undefined;
});

// brand report selectors
export const getBrandReportLoadState: Selector<State, RemoteObjectStatusInterface<BrandReportResult>> = createSelector(getState, state => {
  return state.standardBrandReportStatus;
});
export const getBrandReportData: Selector<State, BrandReportResult | undefined> = createSelector(getState, state => {
  return state.standardBrandReportStatus.data;
});
export const isBrandReportEmpty: Selector<State, boolean> = createSelector(getBrandReportData, data => {
  return data === null || data === undefined;
});

// product report selectors
export const getProductReportLoadState: Selector<State, RemoteObjectStatusInterface<ProductReportResult>> = createSelector(getState, state => {
  return state.standardProductReportStatus;
});
export const getProductReportData: Selector<State, ProductReportResult | undefined> = createSelector(getState, state => {
  return state.standardProductReportStatus.data;
});
export const isProductReportEmpty: Selector<State, boolean> = createSelector(getProductReportData, data => {
  return data === null || data === undefined;
});

// gpcBrick report selectors
export const getGpcBrickReportLoadState: Selector<State, RemoteObjectStatusInterface<GpcBrickReportResult>> = createSelector(getState, state => {
  return state.standardGpcBrickReportStatus;
});
export const getGpcBrickReportData: Selector<State, GpcBrickReportResult | undefined> = createSelector(getState, state => {
  return state.standardGpcBrickReportStatus.data;
});
export const isGpcBrickReportEmpty: Selector<State, boolean> = createSelector(getGpcBrickReportData, data => {
  return data === null || data === undefined;
});

// shelf changes report selectors
export const getShelfComparisonReportData: Selector<State, ShelfComparisonReportResult | undefined> = createSelector(getState, state => {
  return state.shelfComparisonReportObject.data;
});
export const getShelfComparisonReportLoadState: Selector<State, RemoteObjectStatusInterface<ShelfComparisonReportResult>> = createSelector(getState, state => {
  return state.shelfComparisonReportObject;
});

export const isShelfComparisonReportEmpty: Selector<State, boolean> = createSelector(getShelfComparisonReportData, data => {
  return data === null || data === undefined;
});

// project comparison report selectors
export const getProjectComparisonReportData: Selector<State, ProjectComparisonReportResult | undefined> = createSelector(getState, state => {
  return state.projectComparisonReportObject.data;
});
export const getProjectComparisonReportLoadState: Selector<State, RemoteObjectStatusInterface<ProjectComparisonReportResult>> = createSelector(getState, state => {
  return state.projectComparisonReportObject;
});

export const isProjectComparisonReportEmpty: Selector<State, boolean> = createSelector(getProjectComparisonReportData, data => {
  return data === null || data === undefined;
});

// shopper comparison report selectors
export const getShopperComparisonReportData: Selector<State, ShopperComparisonReportResult | undefined> = createSelector(getState, state => {
  return state.shopperComparisonReportObject.data;
});
export const getShopperComparisonReportLoadState: Selector<State, RemoteObjectStatusInterface<ShopperComparisonReportResult>> = createSelector(getState, state => {
  return state.shopperComparisonReportObject;
});

export const isShopperComparisonReportEmpty: Selector<State, boolean> = createSelector(getShopperComparisonReportData, data => {
  return data === null || data === undefined;
});

// product comparison report selectors
export const getProductComparisonReportData: Selector<State, ProductComparisonReportResult | undefined> = createSelector(getState, state => {
  return state.productComparisonReportObject.data;
});
export const getProductComparisonReportLoadState: Selector<State, RemoteObjectStatusInterface<ProductComparisonReportResult>> = createSelector(getState, state => {
  return state.productComparisonReportObject;
});

export const isProductComparisonReportEmpty: Selector<State, boolean> = createSelector(getProductComparisonReportData, data => {
  return data === null || data === undefined;
});

// brand comparison report selectors
export const getBrandComparisonReportData: Selector<State, BrandComparisonReportResult | undefined> = createSelector(getState, state => {
  return state.brandComparisonReportObject.data;
});
export const getBrandComparisonReportLoadState: Selector<State, RemoteObjectStatusInterface<BrandComparisonReportResult>> = createSelector(getState, state => {
  return state.brandComparisonReportObject;
});

export const isBrandComparisonReportEmpty: Selector<State, boolean> = createSelector(getBrandComparisonReportData, data => {
  return data === null || data === undefined;
});
// gpcBrick comparison report selectors
export const getGpcBrickComparisonReportData: Selector<State, GpcBrickComparisonReportResult | undefined> = createSelector(getState, state => {
  return state.gpcBrickComparisonReportObject.data;
});
export const getGpcBrickComparisonReportLoadState: Selector<State, RemoteObjectStatusInterface<GpcBrickComparisonReportResult>> = createSelector(getState, state => {
  return state.gpcBrickComparisonReportObject;
});

export const isGpcBrickComparisonReportEmpty: Selector<State, boolean> = createSelector(getGpcBrickComparisonReportData, data => {
  return data === null || data === undefined;
});
// project benchmark report selectors
export const getProjectBenchmarkReportLoadState: Selector<State, RemoteObjectStatusInterface<ProjectBenchmarkReportResult>> = createSelector(getState, state => {
  return state.projectBenchmarkReportStatus;
});
export const getProjectBenchmarkReportData: Selector<State, ProjectBenchmarkReportResult | undefined> = createSelector(getState, state => {
  return state.projectBenchmarkReportStatus.data;
});

export const isProjectBenchmarkReportEmpty: Selector<State, boolean> = createSelector(getProjectBenchmarkReportData, data => {
  return data === null || data === undefined;
});

// client benchmark report selectors
export const getClientBenchmarkReportLoadState: Selector<State, RemoteObjectStatusInterface<ClientBenchmarkReportResult>> = createSelector(getState, state => {
  return state.clientBenchmarkReportStatus;
});
export const getClientBenchmarkReportData: Selector<State, ClientBenchmarkReportResult | undefined> = createSelector(getState, state => {
  return state.clientBenchmarkReportStatus.data;
});

export const isClientBenchmarkReportEmpty: Selector<State, boolean> = createSelector(getClientBenchmarkReportData, data => {
  return data === null || data === undefined;
});

// brand potential report selectors
export const getBrandPotentialReportLoadState: Selector<State, RemoteObjectStatusInterface<BrandPotentialReport>> = createSelector(getState, state => {
  return state.potentialBrandReportStatus;
});
export const getBrandPotentialReportData: Selector<State, BrandPotentialReport | undefined> = createSelector(getState, state => {
  return state.potentialBrandReportStatus.data;
});
export const isBrandPotentialReportEmpty: Selector<State, boolean> = createSelector(getBrandPotentialReportData, data => {
  return data === null || data === undefined;
});

// product benchmark report selectors
export const getProductBenchmarkReportLoadState: Selector<State, RemoteObjectStatusInterface<ProductBenchmarkReport>> = createSelector(getState, state => {
  return state.productBenchmarkReportStatus;
});
export const getProductBenchmarkReportData: Selector<State, ProductBenchmarkReport | undefined> = createSelector(getState, state => {
  return state.productBenchmarkReportStatus.data;
});
export const isProductBenchmarkReportEmpty: Selector<State, boolean> = createSelector(getProductBenchmarkReportData, data => {
  return data === null || data === undefined;
});
