export const LAYOUT_TOGGLE_ASIDE = 'LAYOUT_TOGGLE_ASIDE';
export type LAYOUT_TOGGLE_ASIDE = typeof LAYOUT_TOGGLE_ASIDE;

export const LAYOUT_OPEN_ASIDE = 'LAYOUT_OPEN_ASIDE';
export type LAYOUT_OPEN_ASIDE = typeof LAYOUT_OPEN_ASIDE;

export const LAYOUT_CLOSE_ASIDE = 'LAYOUT_CLOSE_ASIDE';
export type LAYOUT_CLOSE_ASIDE = typeof LAYOUT_CLOSE_ASIDE;

export const APP_HAS_CRASHED = 'APP_HAS_CRASHED';
export type APP_HAS_CRASHED = typeof APP_HAS_CRASHED;
