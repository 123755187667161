import React, { useEffect } from 'react';
import EditBuilderRulesForm from './EditBuilderRulesForm';
import { InitialValuesEditBuilderRules } from './types';
import BuilderPlanogramView from '../../../types/BuilderPlanogramView';

export interface EditBuilderRulesProps {
  onClose: () => void;
  // TODO: for refactoring. a selector should provide data
  builderPlanogram: BuilderPlanogramView;
  updateBuilderRulesRequest: (p: BuilderPlanogramView) => void;
  // TODO: for refactoring. a selector should provide data
  isUpdatingBuilderRules: boolean;
  isUpdatingBuilderRulesSuccess: boolean;
  isDialogOpen: boolean;
}

const EditBuilderRules: React.FC<EditBuilderRulesProps> = ({
  onClose,
  // TODO: for refactoring. a selector should provide data
  builderPlanogram,
  updateBuilderRulesRequest,
  // TODO: for refactoring. a selector should provide data
  isUpdatingBuilderRules,
  isUpdatingBuilderRulesSuccess,
  isDialogOpen,
}: EditBuilderRulesProps): JSX.Element => {
  // TODO: for refactoring. Move initalValues to the selectors
  const initialValues = {
    dataInsights: [...builderPlanogram.dataInsights],
    actionRules: {
      canAddNewShelfLocations: builderPlanogram.actionRules.canAddNewShelfLocations,
      canTestWithFlow: builderPlanogram.actionRules.canTestWithFlow,
      canViewProjectInfo: builderPlanogram.actionRules.canViewProjectInfo,
      mustStateChangeReasons: builderPlanogram.actionRules.mustStateChangeReasons,
      canViewShelfReport: builderPlanogram.actionRules.canViewShelfReport,
      brandRules: [...builderPlanogram.actionRules.brandRules],
    },
  } as InitialValuesEditBuilderRules;

  useEffect(() => {
    if (isUpdatingBuilderRulesSuccess && isDialogOpen) {
      onClose();
    }
  }, [onClose, isUpdatingBuilderRulesSuccess, isDialogOpen]);
  return (
    <EditBuilderRulesForm
      isUpdatingBuilderRules={isUpdatingBuilderRules}
      initialValues={initialValues}
      email={builderPlanogram.email}
      handleOnSubmit={(values): void => {
        const updated = {
          ...builderPlanogram,
          ...values,
        };
        updateBuilderRulesRequest((updated as unknown) as BuilderPlanogramView);
      }}
      onClose={onClose}
    />
  );
};

export default EditBuilderRules;
