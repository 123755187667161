import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Typography } from '@material-ui/core';
import { Close, Delete } from '@material-ui/icons';
import trackComponent from 'infrastructure/tracking/withTracking';
import * as React from 'react';
import CompetitorShelfDataset from '../../types/CompetitorShelfDataset';

const DeleteCompetitorShelfDatasetConfirmationModal = (props: DeleteCompetitorShelfDatasetConfirmationModalProps) => (
  <Dialog open={true} onClose={props.onClose}>
    <DialogTitle>
      <Typography variant="overline" gutterBottom>
        <Delete color="primary" fontSize="large" />
        &nbsp; Delete Dataset confirmation
      </Typography>
    </DialogTitle>
    <DialogContent>
      <Typography variant="body1">Are you absolutely sure that you want to delete this dataset?</Typography>
      <Typography color="textSecondary" variant="body2">
        This action is permanent and you will not be able to recover this test planogram.
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" color="primary" disabled={!props.enabled} onClick={() => props.onConfirmed(props.selectedDataset)}>
        <Delete />
        &nbsp;
        {props.isDeleting ? 'Deleting test...' : 'Delete permanently'}
      </Button>
      &nbsp;
      <Button variant="contained" color="secondary" disabled={!props.enabled} onClick={props.onClose}>
        <Close />
        &nbsp;Cancel
      </Button>
    </DialogActions>
    {props.isDeleting ? <LinearProgress color="secondary" /> : null}
  </Dialog>
);

interface DeleteCompetitorShelfDatasetConfirmationModalProps {
  selectedDataset: CompetitorShelfDataset;
  enabled: boolean;
  isDeleting: boolean;

  onClose(): void;
  onConfirmed(dataset: CompetitorShelfDataset): void;
}

export default trackComponent(DeleteCompetitorShelfDatasetConfirmationModal, 'DeleteCompetitorShelfDatasetConfirmationModal');
