import apiClient from 'utils/apiClient';
import { TimeSalesData } from './types';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const uploadSalesDataApiRequest = (shelfReferenceId: string, reportName: string, data: TimeSalesData[]) => {
  return apiClient.post(`/admin/shelves/${shelfReferenceId}/sales-data/upload`, {
    shelfReferenceId,
    reportName,
    data,
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const deleteSalesDataApiRequest = (shelfReferenceId: string, reportId: string) => {
  return apiClient.delete(`/admin/shelves/${shelfReferenceId}/sales-data/${reportId}`);
};

export const getSalesDataApiRequest = (shelfReferenceId: string) => {
  return apiClient.get(`/shelves/${shelfReferenceId}/sales-data`);
};
