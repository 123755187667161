// Load planograms for admin
export const ADMIN_PLANOGRAMS_LOAD_REQUEST = 'ADMIN_PLANOGRAMS_LOAD_REQUEST';
export type ADMIN_PLANOGRAMS_LOAD_REQUEST = typeof ADMIN_PLANOGRAMS_LOAD_REQUEST;

export const ADMIN_PLANOGRAMS_LOAD_SUCCESS = 'ADMIN_PLANOGRAMS_LOAD_SUCCESS';
export type ADMIN_PLANOGRAMS_LOAD_SUCCESS = typeof ADMIN_PLANOGRAMS_LOAD_SUCCESS;

export const ADMIN_PLANOGRAMS_LOAD_ERROR = 'ADMIN_PLANOGRAMS_LOAD_ERROR';
export type ADMIN_PLANOGRAMS_LOAD_ERROR = typeof ADMIN_PLANOGRAMS_LOAD_ERROR;

// Load Builders of planogram for admin
export const ADMIN_LOAD_PLANOGRAM_BUILDERS_REQUEST = 'ADMIN_LOAD_PLANOGRAM_BUILDERS_REQUEST';
export type ADMIN_LOAD_PLANOGRAM_BUILDERS_REQUEST = typeof ADMIN_LOAD_PLANOGRAM_BUILDERS_REQUEST;

export const ADMIN_LOAD_PLANOGRAM_BUILDERS_ERROR = 'ADMIN_LOAD_PLANOGRAM_BUILDERS_ERROR';
export type ADMIN_LOAD_PLANOGRAM_BUILDERS_ERROR = typeof ADMIN_LOAD_PLANOGRAM_BUILDERS_ERROR;

export const ADMIN_LOAD_PLANOGRAM_BUILDERS_SUCCESS = 'ADMIN_LOAD_PLANOGRAM_BUILDERS_SUCCESS';
export type ADMIN_LOAD_PLANOGRAM_BUILDERS_SUCCESS = typeof ADMIN_LOAD_PLANOGRAM_BUILDERS_SUCCESS;

// Load 'Competitor Shelf' type ProjectStorePhaseShelves
export const ADMIN_LOAD_PLANOGRAM_COMPETITOR_SHELVES_REQUEST = 'ADMIN_LOAD_PLANOGRAM_COMPETITOR_SHELVES_REQUEST';
export type ADMIN_LOAD_PLANOGRAM_COMPETITOR_SHELVES_REQUEST = typeof ADMIN_LOAD_PLANOGRAM_COMPETITOR_SHELVES_REQUEST;

export const ADMIN_LOAD_PLANOGRAM_COMPETITOR_SHELVES_SUCCESS = 'ADMIN_LOAD_PLANOGRAM_COMPETITOR_SHELVES_SUCCESS';
export type ADMIN_LOAD_PLANOGRAM_COMPETITOR_SHELVES_SUCCESS = typeof ADMIN_LOAD_PLANOGRAM_COMPETITOR_SHELVES_SUCCESS;

export const ADMIN_LOAD_PLANOGRAM_COMPETITOR_SHELVES_FAILURE = 'ADMIN_LOAD_PLANOGRAM_COMPETITOR_SHELVES_FAILURE';
export type ADMIN_LOAD_PLANOGRAM_COMPETITOR_SHELVES_FAILURE = typeof ADMIN_LOAD_PLANOGRAM_COMPETITOR_SHELVES_FAILURE;

// Load CompetitorShelves datasets for a planogram
export const ADMIN_LOAD_PLANOGRAM_COMPETITOR_SHELVES_DATASETS_REQUEST = 'ADMIN_LOAD_PLANOGRAM_COMPETITOR_SHELVES_DATASETS_REQUEST';
export type ADMIN_LOAD_PLANOGRAM_COMPETITOR_SHELVES_DATASETS_REQUEST = typeof ADMIN_LOAD_PLANOGRAM_COMPETITOR_SHELVES_DATASETS_REQUEST;

export const ADMIN_LOAD_PLANOGRAM_COMPETITOR_SHELVES_DATASETS_SUCCESS = 'ADMIN_LOAD_PLANOGRAM_COMPETITOR_SHELVES_DATASETS_SUCCESS';
export type ADMIN_LOAD_PLANOGRAM_COMPETITOR_SHELVES_DATASETS_SUCCESS = typeof ADMIN_LOAD_PLANOGRAM_COMPETITOR_SHELVES_DATASETS_SUCCESS;

export const ADMIN_LOAD_PLANOGRAM_COMPETITOR_SHELVES_DATASETS_FAILURE = 'ADMIN_LOAD_PLANOGRAM_COMPETITOR_SHELVES_DATASETS_FAILURE';
export type ADMIN_LOAD_PLANOGRAM_COMPETITOR_SHELVES_DATASETS_FAILURE = typeof ADMIN_LOAD_PLANOGRAM_COMPETITOR_SHELVES_DATASETS_FAILURE;

// Update Competitor Shelves for a planogram
export const ADMIN_UPDATE_PLANOGRAM_COMPETITOR_SHELVES_REQUEST = 'ADMIN_UPDATE_PLANOGRAM_COMPETITOR_SHELVES_REQUEST';
export type ADMIN_UPDATE_PLANOGRAM_COMPETITOR_SHELVES_REQUEST = typeof ADMIN_UPDATE_PLANOGRAM_COMPETITOR_SHELVES_REQUEST;

export const ADMIN_UPDATE_PLANOGRAM_COMPETITOR_SHELVES_SUCCESS = 'ADMIN_UPDATE_PLANOGRAM_COMPETITOR_SHELVES_SUCCESS';
export type ADMIN_UPDATE_PLANOGRAM_COMPETITOR_SHELVES_SUCCESS = typeof ADMIN_UPDATE_PLANOGRAM_COMPETITOR_SHELVES_SUCCESS;

export const ADMIN_UPDATE_PLANOGRAM_COMPETITOR_SHELVES_FAILURE = 'ADMIN_UPDATE_PLANOGRAM_COMPETITOR_SHELVES_FAILURE';
export type ADMIN_UPDATE_PLANOGRAM_COMPETITOR_SHELVES_FAILURE = typeof ADMIN_UPDATE_PLANOGRAM_COMPETITOR_SHELVES_FAILURE;

// Upload Dataset for Competitor Shelf
export const ADMIN_UPLOAD_COMPETITOR_SHELF_DATASET_REQUEST = 'ADMIN_UPLOAD_COMPETITOR_SHELF_DATASET_REQUEST';
export type ADMIN_UPLOAD_COMPETITOR_SHELF_DATASET_REQUEST = typeof ADMIN_UPLOAD_COMPETITOR_SHELF_DATASET_REQUEST;

export const ADMIN_UPLOAD_COMPETITOR_SHELF_DATASET_SUCCESS = 'ADMIN_UPLOAD_COMPETITOR_SHELF_DATASET_SUCCESS';
export type ADMIN_UPLOAD_COMPETITOR_SHELF_DATASET_SUCCESS = typeof ADMIN_UPLOAD_COMPETITOR_SHELF_DATASET_SUCCESS;

export const ADMIN_UPLOAD_COMPETITOR_SHELF_DATASET_FAILURE = 'ADMIN_UPLOAD_COMPETITOR_SHELF_DATASET_FAILURE';
export type ADMIN_UPLOAD_COMPETITOR_SHELF_DATASET_FAILURE = typeof ADMIN_UPLOAD_COMPETITOR_SHELF_DATASET_FAILURE;

// Delete Dataset for Competitor Shelf
export const ADMIN_DELETE_COMPETITOR_SHELF_DATASET_REQUEST = 'ADMIN_DELETE_COMPETITOR_SHELF_DATASET_REQUEST';
export type ADMIN_DELETE_COMPETITOR_SHELF_DATASET_REQUEST = typeof ADMIN_DELETE_COMPETITOR_SHELF_DATASET_REQUEST;

export const ADMIN_DELETE_COMPETITOR_SHELF_DATASET_SUCCESS = 'ADMIN_DELETE_COMPETITOR_SHELF_DATASET_SUCCESS';
export type ADMIN_DELETE_COMPETITOR_SHELF_DATASET_SUCCESS = typeof ADMIN_DELETE_COMPETITOR_SHELF_DATASET_SUCCESS;

export const ADMIN_DELETE_COMPETITOR_SHELF_DATASET_FAILURE = 'ADMIN_DELETE_COMPETITOR_SHELF_DATASET_FAILURE';
export type ADMIN_DELETE_COMPETITOR_SHELF_DATASET_FAILURE = typeof ADMIN_DELETE_COMPETITOR_SHELF_DATASET_FAILURE;

export enum ENABLE_SHELF_CONDITION {
  REQUEST = 'admin/planograms/ENABLE_SHELF_CONDITION_REQUEST',
  SUCCESS = 'admin/planograms/ENABLE_SHELF_CONDITION_SUCCESS',
  FAILED = 'admin/planograms/ENABLE_SHELF_CONDITION_FAILED',
}

export enum ENABLE_INSPIRATION_IMAGES {
  REQUEST = 'admin/planograms/ENABLE_INSPIRATION_IMAGES_REQUEST',
  SUCCESS = 'admin/planograms/ENABLE_INSPIRATION_IMAGES_SUCCESS',
  FAILED = 'admin/planograms/ENABLE_INSPIRATION_IMAGES_FAILED',
}
