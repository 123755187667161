import * as React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav } from 'reactstrap';

import { AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import logo from '../../assets/img/brand/flow.jpg';
import logoSmall from '../../assets/img/brand/flow-logo.png';
import { State } from 'state';
import { Typography } from '@material-ui/core';
import { isPlanogramBuilder } from '../../modules/planogram/helpers/permissionsHelper';
import { Info, Person } from '@material-ui/icons';
import routes from '../../routing/headerRoutes';
import { Switch, Route } from 'react-router';
import { AsideToggler } from '../_Shared/AsideToggler';

interface MyProps {
  onLogout(): void;
  toggleAside(): void;
}

class Header extends React.Component<State & MyProps & any, any> {
  constructor(props: State & MyProps) {
    super(props);
    this.onLogout = this.onLogout.bind(this);
  }

  onLogout() {
    this.props.onLogout();
  }

  render(): JSX.Element {
    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand full={{ src: logo, width: 100, height: 50, alt: 'Flow Insights Logo' }} minimized={{ src: logoSmall, width: 30, height: 30, alt: 'Flow Insights Logo' }} />
        <AppSidebarToggler className="d-md-down-none" display="lg" />
        <Switch>
          {routes.map((route, idx) => {
            return route.component ? <Route key={idx} path={route.path} exact={route.exact} name={route.name} component={route.component} /> : null;
          })}
        </Switch>
        <Nav className="ml-auto" navbar>
          <AppHeaderDropdown direction="down">
            <DropdownToggle nav>
              <React.Fragment>
                <Person fontSize="small" color="primary" />
                &nbsp;
                <Typography variant="overline" color="primary">
                  {this.props.auth.loadUserStatus.data?.profile.name}
                </Typography>
                {this.props.planogram.planogram ? this.indicateIfNotBuilder() : null}
              </React.Fragment>
            </DropdownToggle>
            <DropdownMenu right style={{ right: 'auto' }}>
              <DropdownItem
                onClick={() => {
                  this.props.history.push('/test-page1');
                }}
              >
                <i className="fa fa-address-book-o" /> Account
              </DropdownItem>
              <DropdownItem>
                <i className="fa fa-wrench" /> Settings
              </DropdownItem>
              <DropdownItem onClick={this.onLogout}>
                <i className="fa fa-lock" /> Logout
              </DropdownItem>
            </DropdownMenu>
          </AppHeaderDropdown>
        </Nav>
        <AsideToggler toggle={this.props.toggleAside} />
      </React.Fragment>
    );
  }

  indicateIfNotBuilder(): JSX.Element {
    const planogram = this.props.planogram.planogram;
    const userId = Number(this.props.auth.loadUserStatus.data?.profile.sub);

    return (
      <div>
        {!isPlanogramBuilder(planogram.ownerId, planogram.builderId, userId) ? (
          <React.Fragment>
            <Info fontSize="small" color="secondary" />
            &nbsp;
            <Typography variant="caption" color="textSecondary">
              You are viewing the planogram assigned to {planogram.email}
            </Typography>
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

export default Header;
