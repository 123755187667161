import { Button, Card, CardContent, CardHeader, Divider, Fab, Grid, IconButton, LinearProgress, Tooltip, Typography } from '@material-ui/core';
import { CloudUpload, Delete, Description, Info, InsertDriveFile } from '@material-ui/icons';
import * as React from 'react';
import { Alert, Container, FormGroup, Input } from 'reactstrap';
import trackComponent from 'infrastructure/tracking/withTracking';
import * as XLSX from 'xlsx';
import { isMasterPlanogram, isPlanogramBuilder } from '../../helpers/permissionsHelper';
import PlanogramModel from '../../domain/PlanogramModel';
import ProductModel from '../../types/ProductModel';
import { SalesDataModel, SalesReportModel } from '../../types/SalesDataModel';
import { ClientDatasetDto } from './../../../admin/clientDataset/types/ClientDatasetDto';

type MyProps = Props & DispatchProps;

class SalesDataUpload extends React.Component<MyProps, any> {
  excelUploadInput: HTMLInputElement = undefined as any;

  constructor(props: MyProps) {
    super(props);
    this.state = {
      reportName: '',
    };
  }

  generateExcelTemplate() {
    const wb = XLSX.utils.book_new();
    const products = this.props.products;
    wb.SheetNames.push('Sheet 1');
    const opts = {
      header: products.length === 0 ? ['GTIN', 'Value'] : [],
    };

    const data = products.map(p => ({
      GTIN: p.gtin,
      Value: '',
    }));

    const ws = XLSX.utils.json_to_sheet(data, opts);
    wb.Sheets['Sheet 1'] = ws;
    XLSX.writeFile(wb, 'Sales Data.xlsx');
  }

  onUploadExcelFile = (evt: any) => {
    evt.preventDefault();
    const files = (document.getElementById('salesdataexcelfile') as any).files;
    if (!files || files.length === 0) {
      alert('Please select a file');
      return;
    }
    const file = files[0];
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const salesDataXlsx = XLSX.utils.sheet_to_json(sheet);
      const salesData: SalesDataModel[] = [];
      salesDataXlsx.forEach((sd: any) => {
        const keys = Object.keys(sd);
        const saleD = new SalesDataModel();
        saleD.gtin = sd[keys[0]];
        saleD.value = sd[keys[1]];
        if (this.props.products.find(p => p.gtin.toString() === saleD.gtin.toString()) === undefined) {
          return;
        }
        salesData.push(saleD);
      });
      this.props.saveSalesDataRequest(this.state.reportName, salesData);
    };
    reader.readAsArrayBuffer(file);
    return false;
  };

  setExcelInputElement = (element: any) => {
    this.excelUploadInput = element;
  };

  render() {
    const isMaster: boolean = isMasterPlanogram(this.props.planogram.ownerId, this.props.planogram.builderId);
    return (
      <Container fluid style={{ padding: '8px' }}>
        <Card style={{ paddingTop: '8px', paddingBottom: '8px' }}>
          <CardContent style={{ paddingTop: '8px', paddingBottom: '8px' }}>
            <Typography color="primary" variant="subtitle1">
              Upload Sales Report
            </Typography>
            <Divider className="top-buffer bottom-buffer" light={true} variant="fullWidth" />
            <form action="asd" onSubmit={this.onUploadExcelFile}>
              <FormGroup className="top-buffer">
                <Tooltip title="Generate sample excel file with pre-populated product IDs">
                  <div>
                    <Button
                      style={{ fontSize: '10px' }}
                      size="small"
                      variant="outlined"
                      color="secondary"
                      disabled={!isMaster || !isPlanogramBuilder(this.props.planogram.ownerId, this.props.planogram.builderId, this.props.userId)}
                      onClick={() => this.generateExcelTemplate()}
                    >
                      Generate Report Template &nbsp;
                      <InsertDriveFile />
                    </Button>
                  </div>
                </Tooltip>
              </FormGroup>
              <FormGroup className="top-buffer">
                <Input
                  multiple={true}
                  disabled={this.props.savingSalesData || !isMaster}
                  placeholder="Report Name"
                  required
                  value={this.state.reportName}
                  onChange={evt => this.setState({ reportName: evt.target.value })}
                />
              </FormGroup>
              <FormGroup>
                <input id="salesdataexcelfile" ref={this.setExcelInputElement} required disabled={this.props.savingSalesData || !isMaster} type="file" />
              </FormGroup>
              <FormGroup>
                <Fab size="medium" variant="extended" disabled={this.props.savingSalesData || !isMaster} type="submit" color="primary">
                  {this.props.savingSalesData ? 'Uploading...' : 'Upload'}&nbsp;&nbsp;
                  <CloudUpload />
                </Fab>
              </FormGroup>
            </form>
          </CardContent>
          {this.props.savingSalesData ? <LinearProgress color="primary" /> : null}
        </Card>
        <Divider variant="fullWidth" className="top-buffer bottom-buffer" />
        <Card style={{ paddingTop: '8px', paddingBottom: '8px' }} className="top-buffer">
          <CardContent style={{ paddingTop: '8px', paddingBottom: '8px' }}>
            <Typography color="primary" variant="subtitle1">
              Sales Reports
            </Typography>
            <Divider className="top-buffer bottom-buffer" light={true} variant="fullWidth" />
            {this.renderSalesReports(isMaster)}
          </CardContent>
        </Card>
        <Divider variant="fullWidth" className="top-buffer bottom-buffer" />

        <Card style={{ paddingTop: '8px', paddingBottom: '8px' }} className="top-buffer">
          <CardContent style={{ paddingTop: '8px', paddingBottom: '8px' }}>
            <Typography color="primary" variant="subtitle1">
              Client Reports
            </Typography>
            <Divider className="top-buffer bottom-buffer" light={true} variant="fullWidth" />
            {this.renderClientDatasets()}
          </CardContent>
        </Card>
      </Container>
    );
  }

  renderSalesReports(isMaster: boolean) {
    let reports: any;
    if (this.props.salesData !== null && this.props.salesData !== undefined && this.props.salesData.length !== 0) {
      reports = this.props.salesData.map(report => {
        return (
          <Card raised={true} className="top-buffer bottom-buffer" key={report.reportId}>
            <CardHeader
              disableTypography={true}
              title={
                <Typography style={{ wordWrap: 'break-word' }} variant="body2">
                  {report.reportName}
                </Typography>
              }
              action={
                <Grid direction="row" alignItems="center" container justify="center">
                  <Tooltip title="View report data">
                    <IconButton
                      size="medium"
                      className="pull-left"
                      onClick={() => {
                        this.props.onShowData(report);
                      }}
                      color="primary"
                      aria-label="Add"
                    >
                      <Description />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete report data">
                    <div>
                      <IconButton
                        size="medium"
                        className="pull-right"
                        disabled={this.props.deletingSalesReport || !isMaster}
                        onClick={() => {
                          if (window.confirm('Are you sure you want to delete this sales report? This action is irreversible.')) {
                            this.props.onDeleteSalesData(report.reportId);
                          }
                        }}
                        color="secondary"
                        aria-label="Delete"
                      >
                        <Delete />
                      </IconButton>
                    </div>
                  </Tooltip>
                </Grid>
              }
            />
          </Card>
        );
      });
      return <React.Fragment>{reports}</React.Fragment>;
    } else {
      return (
        <Alert className="top-buffer" color="info">
          <Typography variant="caption" color="textSecondary">
            <Info color="primary" fontSize="small" />
            &nbsp;Sales reports have not been uploaded.
          </Typography>
        </Alert>
      );
    }
  }

  renderClientDatasets() {
    let reports: any;
    if (this.props.clientDatasets !== null && this.props.clientDatasets !== undefined && this.props.clientDatasets.length !== 0) {
      reports = this.props.clientDatasets.map((dataset: ClientDatasetDto) => {
        return (
          <Card raised={true} className="top-buffer bottom-buffer" key={dataset.id}>
            <CardHeader
              disableTypography={true}
              title={
                <Typography style={{ wordWrap: 'break-word' }} variant="body2">
                  {dataset.name}
                </Typography>
              }
              action={
                <Grid direction="row" alignItems="center" container justify="center">
                  <Tooltip title="View dataset">
                    <IconButton
                      size="medium"
                      className="pull-left"
                      onClick={() => {
                        this.props.onShowClientDatasetData(dataset);
                      }}
                      color="primary"
                      aria-label="Add"
                    >
                      <Description />
                    </IconButton>
                  </Tooltip>
                </Grid>
              }
            />
          </Card>
        );
      });
      return <React.Fragment>{reports}</React.Fragment>;
    } else {
      return (
        <Alert className="top-buffer" color="info">
          <Typography variant="caption" color="textSecondary">
            <Info color="primary" fontSize="small" />
            &nbsp;Client datasets have not been uploaded.
          </Typography>
        </Alert>
      );
    }
  }
}

interface Props {
  userId: number;
  planogram: PlanogramModel;
  dataInsights: string[];
  salesData: SalesReportModel[];
  products: ProductModel[];
  savingSalesData: boolean;
  deletingSalesReport: boolean;
  clientDatasets: ClientDatasetDto[];
}

interface DispatchProps {
  saveSalesDataRequest(reportName: string, salesData: SalesDataModel[]): void;

  onShowData(report: SalesReportModel): void;
  onShowClientDatasetData(dataset: ClientDatasetDto): void;

  onDeleteSalesData(reportId: string): void;
}

export default trackComponent(SalesDataUpload, 'Aside_SalesDataUpload');
