import { createSelector, Selector } from 'reselect';
import createCachedSelector, { ParametricSelector } from 're-reselect';
import { State } from '../../state';
import PlanogramsState from './state';
import { BrandRule, InitialValuesCreatePlanogramModal } from './types/CreatePlanogram';
import { isLoading, isLoaded } from 'infrastructure/utils/RemoteObjectStatus';
import ShelfViewModel from './types/ShelfViewModel';

const getState = (state: State): PlanogramsState => state.planograms;

export const isLoadingBrands: Selector<State, boolean> = createSelector(getState, (state: PlanogramsState) => {
  return isLoading(state.loadBrandsStatus);
});

export const isLoadedBrands: Selector<State, boolean> = createSelector(getState, (state: PlanogramsState) => {
  return isLoaded(state.loadBrandsStatus);
});

export const getLoadBrandsError: Selector<State, string> = createSelector(getState, state => {
  return state.loadBrandsStatus.errorDetails?.errorMessage ?? '';
});

export const isLoadedShelves: Selector<State, boolean> = createSelector(getState, state => {
  return isLoaded(state.loadShelvesStatus);
});

export const getLoadShelvesError: Selector<State, string> = createSelector(getState, state => {
  return state.loadShelvesStatus.errorDetails?.errorMessage ?? '';
});

export const getInitialValuesCreatePlanogram: ParametricSelector<State, string, InitialValuesCreatePlanogramModal> = createCachedSelector(
  getState,
  (state: PlanogramsState, shelfReferenceId: string) => shelfReferenceId,
  (state: PlanogramsState, shelfReferenceId: string) => {
    const shelf = state.loadShelvesStatus.data?.find(item => item.shelfReferenceId === shelfReferenceId) as ShelfViewModel;
    const brands = state.loadBrandsStatus.data ?? [];
    return {
      projectName: shelf.projectName,
      storeName: (shelf.storeName as unknown) as string,
      phaseNum: shelf.phaseNum,
      shelfNum: shelf.shelfNum,
      planogramName: '',
      goal: '',
      goalKpis: '',
      userGroups: [
        {
          emails: [],
          dataInsights: [],
          actionRules: {
            canAddNewShelfLocations: false,
            canTestWithFlow: false,
            canViewProjectInfo: true,
            mustStateChangeReasons: false,
            canViewShelfReport: false,
            brandRules: brands.map(brand => {
              const brandRule: BrandRule = {
                brandId: brand.brandId,
                brandName: brand.brandName,
                actionsAllowed: {
                  canView: false,
                  canMove: false,
                  canDelist: false,
                  canIncreaseSize: false,
                  canDecreaseSize: false,
                },
              };
              return brandRule;
            }),
          },
        },
      ],
    };
  }
)((state, shelfReferenceId) => shelfReferenceId);

export const isCreatingPlanogramFromShelf: Selector<State, boolean> = createSelector(getState, state => {
  return isLoading(state.loadCreatePlanogramFromShelfStatus);
});
