export default class PlanogramEventBase {
  readonly occurredOn: Date = new Date();
  readonly eventUrl: string = '';
  readonly eventHttpMethod: string = '';
  readonly eventName: string = '';
  readonly blockUi: boolean = false;

  constructor(eventUrl: string, eventName: string, eventHttpMethod = 'post', blockUi = false) {
    this.occurredOn = new Date();
    this.eventUrl = eventUrl;
    this.eventHttpMethod = eventHttpMethod;
    this.eventName = eventName;
    this.blockUi = blockUi;
  }
}
