import { OperationsInfoDto } from './OperationsInfoDto';

export default class CreateTestPlanogramRequestDto {
  testName = '';
  initialPlanogramId = '';
  shelfSvgHtml = '';
  operationsInfo: OperationsInfoDto = new OperationsInfoDto();
}

export class CreateTestPlanogramResponseDto {
  createdTestId = '';
  createdOn: Date = new Date();
}
