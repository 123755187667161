interface ApplicationConfiguration {
  apiRootUri: string;
  clientRootUri: string;
  auth: {
    isMock: boolean;
    authority: string;
  };
  tracking: {
    telemetryKey: string;
    telemetryEnabled: boolean;
  };
  taskBasedApiConfig: {
    timeout: number;
    retries: number;
    delay: number;
  };
  appStage: string;
  featureFlags: {
    shelfConditions: boolean;
    inspirationImages: boolean;
  };
}

const configuration: ApplicationConfiguration = {
  apiRootUri: process.env.REACT_APP_API_ROOT_URI as string,
  clientRootUri: process.env.REACT_APP_CLIENT_ROOT_URI as string,

  auth: {
    authority: process.env.REACT_APP_OIDC_AUTHORITY as string,
    // missing authority will create a mock user
    isMock: process.env.REACT_APP_OIDC_AUTHORITY ? false : true,
  },
  tracking: {
    telemetryKey: process.env.REACT_APP_TELEMETRY_KEY as string,
    telemetryEnabled: process.env.REACT_APP_TELEMETRY_ENABLED === 'true',
  },
  taskBasedApiConfig: {
    delay: process.env.REACT_APP_TASK_BASED_DELAY ? parseInt(process.env.REACT_APP_TASK_BASED_DELAY) : 0,
    retries: process.env.REACT_APP_TASK_BASED_RETRIES ? parseInt(process.env.REACT_APP_TASK_BASED_RETRIES) : 0,
    timeout: process.env.REACT_APP_TASK_BASED_TIMEOUTS ? parseInt(process.env.REACT_APP_TASK_BASED_TIMEOUTS) : 0,
  },
  appStage: process.env.REACT_APP_STAGE_VALUE as string,
  featureFlags: {
    shelfConditions: process.env.REACT_APP_FEATURE_FLAG_SHELF_CONDITIONS === 'true' ? true : false,
    inspirationImages: process.env.REACT_APP_FEATURE_FLAG_INSPIRATION_IMAGES === 'true' ? true : false,
  },
};

export default configuration;
