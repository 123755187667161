/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { plainToClass } from 'class-transformer';
import { push } from 'connected-react-router';
import CommandManager from 'infrastructure/commands/CommandManager';
import CompetitorShelfDataset from 'modules/admin/planograms/types/CompetitorShelfDataset';
import { toastr } from 'react-redux-toastr';
import { all, put, takeLatest, select, call } from 'redux-saga/effects';
import apiClient from 'utils/apiClient';
import apiSaga, { apiSagaWithRetry, SagaRetryOptions } from 'utils/apiSaga';
import configuration from '../../config';
import ErrorDetails from '../../infrastructure/exception/ErrorDetails';
import { ClientDatasetDto } from './../admin/clientDataset/types/ClientDatasetDto';
import * as actions from './actions';
import ChangeShelfLocationImageUrlCommand from './commands/ChangeShelfLocationImageUrlCommand';
import * as constants from './constants/constants';
import PlanogramEventBase from './events/PlanogramEventBase';
import CreateTestPlanogramRequestDto, { CreateTestPlanogramResponseDto } from './types/CreateTestPlanogramRequestDto';
import { NewProductModel } from './types/NewProductModel';
import { PlanogramChangeReasons, RecordChangeReasonDto } from './types/PlanogramChangeReasons';
import PlanogramModel from './domain/PlanogramModel';
import ProductModel from './types/ProductModel';
import { SalesDataModel, SalesReportModel } from './types/SalesDataModel';
import ShelfLocation from './domain/ShelfLocation';
import TestInfoDto from './types/TestInfoDto';
import Rectangle from './types/Rectangle';
import { State } from 'state';
import { FlowDataReportResult } from './types/FlowDataReportResult';
import { LoadTestPlanogramResult } from './types/LoadTestPlanogramResult';
import { ActionWithId, OnImageLoadingAction, OnNewProductImageUploadRequestAction } from './types/ActionTypes';

function getPlanogram(id: string) {
  return apiClient.get('/planograms/' + id);
}

function getFlowData(id: string) {
  return apiClient.get(`/planograms/${id}/flowdata`);
}

function getPublicPlanogram(id: string, sharingToken: string) {
  return apiClient.get('/planograms/' + id + '/public/' + sharingToken);
}

function getTestPlanogram(id: string, testPlanogramId: string): Promise<AxiosResponse<LoadTestPlanogramResult>> {
  return apiClient.get('/planograms/' + id + '/test/' + testPlanogramId);
}

function getTestsInfoRequest(id: string) {
  return apiClient.get('/planograms/' + id + '/test');
}

function getCompetitorPlanogramRequest(id: string, shelfReferenceId: string) {
  return apiClient.get('/planograms/' + id + '/competitorShelves/' + shelfReferenceId);
}

function postEvent(event: PlanogramEventBase) {
  if (event.eventHttpMethod === 'delete') {
    return apiClient({
      url: event.eventUrl,
      data: event,
      method: 'delete',
    });
  } else {
    const config = {
      timeout: configuration.taskBasedApiConfig.timeout,
    } as AxiosRequestConfig;
    return apiClient.post(event.eventUrl, event, config);
  }
}

function changePlanogramNameRequest(id: string, name: string) {
  return apiClient.post(`/planograms/${id}/change-name`, { newName: name, planogramId: id });
}

function deletePlanogramRequest(id: string) {
  return apiClient({
    method: 'delete',
    url: `/planograms/${id}`,
    data: {
      planogramId: id,
    },
  });
}

function deleteTestRequest(planogramId: string, testId: string) {
  return apiClient({
    method: 'delete',
    url: `/planograms/${planogramId}/test/${testId}`,
    data: {
      planogramId,
      testId,
    },
  });
}

function saveSalesDataRequest(id: string, reportName: string, salesData: SalesDataModel[]) {
  return apiClient.post(`/planograms/${id}/sales-data`, {
    planogramId: id,
    reportName,
    items: salesData,
  });
}

function deleteSalesDataRequest(id: string, reportId: string) {
  return apiClient({
    url: `/planograms/${id}/sales-data`,
    method: 'delete',
    data: {
      planogramId: id,
      reportId,
    },
  });
}

function uploadShelfLocationImageRequest(id: string, imageBase64: string) {
  return apiClient.post(`/planograms/${id}/images`, {
    planogramId: id,
    imageBase64,
  });
}

function fixImageSizeRequest(id: string, shelfLocationId: string, occurredOn: Date, width: number, height: number) {
  return apiClient.post(`/planograms/${id}/shelf-locations/${shelfLocationId}/fix-image-size`, {
    planogramId: id,
    shelfLocationId,
    occurredOn,
    width,
    height,
    eventName: 'ShelfLocationImageSizeFixed', // TODO: replace this hotfix with command that generates this eventName
  });
}

function setShelfLocationGtinRequest(id: string, shelfLocationId: string, occurredOn: Date, gtin: number, newProduct?: NewProductModel) {
  return apiClient.post(`/planograms/${id}/shelf-locations/${shelfLocationId}/set-gtin`, {
    planogramId: id,
    shelfLocationId,
    occurredOn,
    gtin,
    newProduct,
  });
}

function updateCommentsRequest(id: string, comments: string) {
  return apiClient.post(`/planograms/${id}/comments`, {
    planogramId: id,
    comments,
  });
}

function togglePublicSharingRequest(id: string, enabled: boolean) {
  return apiClient.post(`/planograms/${id}/public/enable`, {
    planogramId: id,
    enabled,
  });
}

function resetPlanogramRequest(id: string) {
  return apiClient({
    method: 'post',
    url: `/planograms/${id}/reset`,
    data: {
      planogramId: id,
    },
  });
}

function createTestPlanogramRequest(createTestPlanogramDto: CreateTestPlanogramRequestDto) {
  const planogramId = createTestPlanogramDto.initialPlanogramId;
  return apiClient.post(`/planograms/${planogramId}/test`, createTestPlanogramDto);
}

function updateTestInfoRequest(testInfo: TestInfoDto) {
  return apiClient.put(`/planograms/${testInfo.initialPlanogramId}/test/${testInfo.id}`, testInfo);
}

function recordChangeReasonRequest(reason: RecordChangeReasonDto) {
  return apiClient.post(`/planograms/${reason.planogramId}/change-reasons`, reason, {
    timeout: configuration.taskBasedApiConfig.timeout,
  });
}

function updateHasFinishedProductTourRequest() {
  return apiClient.put(`/users/userPreferences/productTour`);
}

function uploadNewProductImageRequest(planogramId: string, base64Data: string, crop: Rectangle) {
  return apiClient.post(`/planograms/${planogramId}/images`, {
    imageBase64: base64Data,
    crop,
  });
}

export function* loadPlanogram(action: any) {
  try {
    const response = yield apiSaga(getPlanogram, action.id);

    const initialPlanogram = plainToClass(PlanogramModel, response.data.initialPlanogram as PlanogramModel);
    const currentPlanogram = plainToClass(PlanogramModel, response.data.currentPlanogram as PlanogramModel);
    const products = response.data.products as ProductModel[];
    const salesData = response.data.salesData ? plainToClass(SalesReportModel, response.data.salesData as SalesReportModel[]) : [];
    const clientDatasets = response.data.clientDatasets ? plainToClass(ClientDatasetDto, response.data.clientDatasets as ClientDatasetDto[]) : [];
    const changeReasons = response.data.planogramChangeReasons ? plainToClass(PlanogramChangeReasons, response.data.planogramChangeReasons as PlanogramChangeReasons) : new PlanogramChangeReasons();
    initialPlanogram.changeReasons = changeReasons;
    currentPlanogram.changeReasons = changeReasons;

    yield put(actions.planogramLoaded(initialPlanogram, currentPlanogram, products, salesData, clientDatasets));
    yield put(actions.onImageLoading(initialPlanogram.imageUrl));
    yield put(actions.loadFlowData(currentPlanogram.id));
  } catch (error) {
    console.log('Failed to load planogram', error.response.data);
    yield put(actions.errorLoadingPlanogram(error.response.data));
  }
}

export function* loadFlowData(action: ActionWithId) {
  try {
    const response = yield apiSaga(getFlowData, action.id);
    yield put(actions.onFlowDataLoaded(response.data));
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    yield call(toastr.error, 'Failed to load Flow data report.', errorDetails.errorMessage);
    yield put(actions.onFlowDataLoadFailed(errorDetails));
  }
}

function loadImageInPromise(imageUrl: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', () => {
      reject(new Error(`Failed to load image ${imageUrl}`));
    });
    image.src = imageUrl;
  });
}

export function* loadPlanogramImage(action: OnImageLoadingAction) {
  try {
    const image: HTMLImageElement = yield loadImageInPromise(action.imageUrl);
    yield put(actions.onImageLoaded(image.width, image.height));
  } catch (error) {
    console.log('Failed to load planogram image', error);
    yield put(actions.onImageLoadingError());
  }
}

export function* loadPublicPlanogram(action: any) {
  try {
    const response = yield apiSaga(getPublicPlanogram, action.id, action.sharingToken);

    const initialPlanogram = plainToClass(PlanogramModel, response.data.initialPlanogram as PlanogramModel);
    const currentPlanogram = plainToClass(PlanogramModel, response.data.currentPlanogram as PlanogramModel);
    const products = response.data.products as ProductModel[];
    const flowDataReport = response.data.flowData as FlowDataReportResult;
    const changeReasons = response.data.planogramChangeReasons ? plainToClass(PlanogramChangeReasons, response.data.planogramChangeReasons as PlanogramChangeReasons) : new PlanogramChangeReasons();
    initialPlanogram.changeReasons = changeReasons;
    currentPlanogram.changeReasons = changeReasons;
    yield put(actions.publicPlanogramLoaded(initialPlanogram, currentPlanogram, products, flowDataReport));
    yield put(actions.onImageLoading(initialPlanogram.imageUrl));
  } catch (error) {
    console.log('Failed to load planogram', error.response.data);
    yield put(actions.errorLoadingPublicPlanogram(error.response.data));
  }
}
export function* loadTestPlanogram(action: any) {
  try {
    const response: AxiosResponse<LoadTestPlanogramResult> = yield apiSaga(getTestPlanogram, action.id, action.testPlanogramId);

    const initialPlanogram = plainToClass(PlanogramModel, response.data.initialPlanogram);
    const products = response.data.products;
    const testPlanogram = plainToClass(PlanogramModel, response.data.planogram);
    const testInfo = plainToClass(TestInfoDto, response.data.testInfo);
    const flowDataReport = response.data.flowData;
    const changeReasons = response.data.planogramChangeReasons ? plainToClass(PlanogramChangeReasons, response.data.planogramChangeReasons) : new PlanogramChangeReasons();
    initialPlanogram.changeReasons = changeReasons;
    testPlanogram.changeReasons = changeReasons;
    yield put(actions.testPlanogramLoaded(initialPlanogram, testPlanogram, testInfo, products, flowDataReport));
    yield put(actions.onImageLoading(initialPlanogram.imageUrl));
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    console.log('Failed to load test planogram', errorDetails);
    yield put(actions.errorLoadingTestPlanogram(errorDetails));
  }
}

export function* loadCompetitorPlanogram(action: any) {
  try {
    const response = yield apiSaga(getCompetitorPlanogramRequest, action.id, action.shelfReferenceId);

    const initialPlanogram = plainToClass(PlanogramModel, response.data.initialPlanogram as PlanogramModel);
    const products = response.data.products as ProductModel[];
    const competitorPlanogram = plainToClass(PlanogramModel, response.data.competitorPlanogram as PlanogramModel);
    const competitorShelfDataset = plainToClass(CompetitorShelfDataset, response.data.competitorShelfDataset as CompetitorShelfDataset);
    const flowDataReport = response.data.flowData as FlowDataReportResult;
    yield put(actions.loadCompetitorPlanogramSuccess(initialPlanogram, competitorPlanogram, products, flowDataReport, competitorShelfDataset));
    yield put(actions.onImageLoading(initialPlanogram.imageUrl));
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    console.log('Failed to load competitor shelf', errorDetails);
    yield put(actions.loadCompetitorPlanogramFailure(errorDetails));
  }
}

export function* onPlanogramEvent(action: any) {
  try {
    const event = action.event as PlanogramEventBase;
    const retryOptions = {
      delayMilliseconds: configuration.taskBasedApiConfig.delay,
      retries: configuration.taskBasedApiConfig.retries,
    } as SagaRetryOptions;
    if (event.blockUi) {
      yield put(actions.savingChangesRequest(true));
    }
    yield apiSagaWithRetry(postEvent, retryOptions, event);
    yield put(actions.savingChangesRequest(false));
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    console.log('Failed to save command', errorDetails);
    toastr.error('Failed to save changes!', 'Your latest changes have not been saved because of an error. Please refresh the page before continuing to work!\n' + errorDetails.errorMessage);
    yield put(actions.savingChangesRequest(false));
    yield put(actions.setIsInUnstableState());
  }
}

export function* changeName(action: any) {
  try {
    yield apiSaga(changePlanogramNameRequest, action.planogramId, action.newName);
    toastr.success('Name changed successfully.', '');
    yield put(actions.changePlanogramNameSuccess(action.newName));
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    console.log('Failed to change planogram name.', errorDetails);
    yield call(toastr.error, 'Failed to change planogram name.', errorDetails.errorMessage);
    yield put(actions.changePlanogramNameError(errorDetails));
  }
}

export function* deletePlanogram(action: any) {
  try {
    yield apiSaga(deletePlanogramRequest, action.planogramId);
    toastr.success('Successfully deleted planogram', '');
    yield put(actions.deletePlanogramSuccess());
    yield put(push(`/planograms`));
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    console.log('Error deleting planogram.', errorDetails);
    toastr.error('Error deleting planogram.', errorDetails.errorMessage);
    yield put(actions.deletePlanogramFailed(errorDetails));
  }
}

export function* deleteTest(action: any) {
  try {
    const planogramId = action.planogramId;
    const testIdToDelete = action.testIdToDelete;
    yield apiSaga(deleteTestRequest, planogramId, testIdToDelete);
    toastr.success('Successfully deleted test', '');
    yield put(actions.deleteTestSuccess(testIdToDelete));
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    console.log('Error deleting test.', errorDetails);
    toastr.error('Error deleting test.', errorDetails.errorMessage);
    yield put(actions.deleteTestFailure(errorDetails));
  }
}

export function* saveSalesData(action: any) {
  try {
    const salesData = action.salesData as SalesDataModel[];
    const response = yield apiSaga(saveSalesDataRequest, action.planogramId, action.reportName, salesData);
    yield put(actions.saveSalesDataSuccess(response.data.reportId, action.reportName, salesData));
    toastr.success('Successfully uploaded sales data.', '');
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    console.log('Failed to save sales data.', errorDetails);
    toastr.error('Failed to save data.', errorDetails.errorMessage);
    yield put(actions.saveSalesDataFailed(errorDetails));
  }
}

export function* deleteSalesData(action: any) {
  try {
    const reportId = action.reportId;
    const planogramId = action.planogramId;
    yield apiSaga(deleteSalesDataRequest, planogramId, reportId);
    yield put(actions.deleteSalesDataSuccess(planogramId, reportId));
    yield call(toastr.success, 'Successfully removed sales data.', '');
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    console.log('Failed to delete sales data.', errorDetails);
    yield call(toastr.error, 'Failed to delete data.', errorDetails.errorMessage);
    yield put(actions.deleteSalesDataFailed(errorDetails));
  }
}

export function* uploadShelfLocationImage(action: any) {
  try {
    const planogram = action.planogram as PlanogramModel;
    const response = yield apiSaga(uploadShelfLocationImageRequest, planogram.id, action.file);
    yield put(actions.uploadShelfLocationImageSuccess());
    yield call(toastr.success, 'Successfully uploaded shelf location image.', '');
    const shelfLocation = action.shelfLocation as ShelfLocation;
    const commandManager = action.commandManager as CommandManager<PlanogramEventBase>;
    const command = new ChangeShelfLocationImageUrlCommand(action.planogram, shelfLocation, response.data.imageUrl, response.data.width, response.data.height);
    commandManager.executeCommand(command);
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    console.log('Failed to upload shelf location image.', errorDetails);
    yield call(toastr.error, 'Failed to upload shelf location image.', errorDetails.errorMessage);
    yield put(actions.uploadShelfLocationImageFailed(errorDetails));
  }
}

export function* fixImageSize(action: any) {
  const planogramId = action.planogramId as string;
  const shelfLocation = action.shelfLocation as ShelfLocation;
  try {
    yield apiSaga(fixImageSizeRequest, planogramId, shelfLocation.id, action.occurredOn, action.width, action.height);
    yield call(toastr.success, 'Successfully fixed image size', '');
    yield put(actions.fixImageSizeSuccess(planogramId, shelfLocation));
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    console.log('Failed to fix image size', errorDetails);
    yield call(toastr.error, 'Failed to fix image size', errorDetails.errorMessage);
    const theAction = actions.fixImageSizeError(planogramId, shelfLocation, errorDetails);
    yield put(theAction);
  }
}

export function* setShelfLocationGtin(action: any) {
  try {
    yield apiSaga(setShelfLocationGtinRequest, action.planogramId, action.shelfLocation.id, action.occurredOn, action.gtin, action.newProduct);
    toastr.success('Successfully set GTIN', '');
    yield put(actions.setShelfLocationGtinSuccess(action.shelfLocation, action.gtin, action.newProduct));
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    console.log('Failed to set shelf location gtin', errorDetails);
    toastr.error('Failed to set shelf location gtin', errorDetails.errorMessage);
    yield put(actions.setShelfLocationGtinError(errorDetails));
  }
}

export function* updateComments(action: any) {
  try {
    yield apiSaga(updateCommentsRequest, action.planogramId, action.comments);
    yield call(toastr.success, 'Successfully updated comments', '');
    yield put(actions.updateCommentsSuccess(action.planogramId, action.comments));
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    console.log('Failed to update comments', errorDetails);
    yield call(toastr.error, 'Failed to update comments', errorDetails.errorMessage);
    yield put(actions.updateCommentsError(action.planogramId, action.comments, errorDetails));
  }
}

export function* togglePublicSharing(action: any) {
  try {
    const response = yield apiSaga(togglePublicSharingRequest, action.planogramId, action.enabled);
    // TODO: Indicate toastr message with "Successfully turned ON or OFF"
    toastr.success('Successfully updated public sharing', '');
    yield put(actions.publicSharingToggleSuccess(action.planogramId, action.enabled, response.data.newToken));
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    console.log('Failed to toggle public sharing', errorDetails);
    toastr.error('Failed to toggle public sharing', errorDetails.errorMessage);
    yield put(actions.publicSharingToggleError(action.planogramId));
  }
}

export function* createTestPlanogram(action: any) {
  try {
    const createTestPlanogramRequestDto = action.createTestPlanogramDto as CreateTestPlanogramRequestDto;
    const response = yield apiSaga(createTestPlanogramRequest, createTestPlanogramRequestDto);
    const createTestResponseDto = plainToClass(CreateTestPlanogramResponseDto, response.data as CreateTestPlanogramResponseDto);
    yield put(actions.createTestPlanogramSuccess(createTestResponseDto, createTestPlanogramRequestDto));
    yield call(toastr.success, `Submitted a new Shelf Test Proposal '${createTestPlanogramRequestDto.testName}'.`, '');
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    console.log('Failed to create test.', errorDetails);
    yield call(toastr.error, 'Failed to create test.', errorDetails.errorMessage);
    yield put(actions.createTestPlanogramFailure(errorDetails));
  }
}

export function* updateTestInfo(action: any) {
  try {
    const testInfo = action.testInfo as TestInfoDto;
    yield apiSaga(updateTestInfoRequest, testInfo);
    yield put(actions.updateTestInfoSuccess(testInfo));
    yield call(toastr.success, `Successfully edited '${testInfo.testName} '.`, '');
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    console.log('Failed to edit test.', errorDetails);
    yield call(toastr.error, 'Failed to edit test.', errorDetails.errorMessage);
    yield put(actions.updateTestInfoFailure(errorDetails));
  }
}

export function* loadTestsInfoListForPlanogram(action: any) {
  try {
    const planogramId = action.planogramId;
    const response = yield apiSaga(getTestsInfoRequest, planogramId);
    const testsInfoList = plainToClass(TestInfoDto, response.data.testsInfoList as TestInfoDto[]);
    yield put(actions.loadTestsInfoSuccess(testsInfoList));
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    console.log('Failed to load shelf tests.', errorDetails);
    yield call(toastr.error, 'Failed to load shelf tests.', errorDetails.errorMessage);
    yield put(actions.loadTestsInfoFailure(errorDetails));
  }
}

export function* recordChangeReason(action: any) {
  try {
    const reason = action.reason as RecordChangeReasonDto;
    const config = {
      delayMilliseconds: configuration.taskBasedApiConfig.delay,
      retries: configuration.taskBasedApiConfig.retries,
    } as SagaRetryOptions;
    yield apiSagaWithRetry(recordChangeReasonRequest, config, reason);
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    console.log('Failed to save change reason.', errorDetails);
    toastr.error('Failed to save change reason. Please try again later.', errorDetails.errorMessage);
    yield put(actions.setIsInUnstableState());
  }
}

export function* resetPlanogram(action: any) {
  try {
    yield apiSaga(resetPlanogramRequest, action.planogramId);
    toastr.success('Successfully reset planogram', '');
    yield put(actions.resetPlanogramSuccess());
    window.location.reload();
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    console.log('Error resetting planogram.', errorDetails);
    toastr.error('Error resetting planogram.', errorDetails.errorMessage);
    yield put(actions.resetPlanogramFailure(errorDetails));
  }
}

export function* updateHasFinishedProductTour() {
  try {
    yield apiSaga(updateHasFinishedProductTourRequest);
    yield put(actions.updateHasFinishedProductTourSuccess());
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    console.log('Error Updating User Preferences Product Tour with hasFinishedProductTour', errorDetails);
  }
}

export function* uploadNewProductImage(action: OnNewProductImageUploadRequestAction) {
  try {
    const response = yield apiSaga(uploadNewProductImageRequest, action.planogramId, action.base64Data, action.crop);
    const imageUrl = response.data.imageUrl;
    yield put(actions.onNewProductImageUploadSuccess(imageUrl));
  } catch (error) {
    const errorDetails: ErrorDetails = error.response.data;
    toastr.error('Failed to upload image for new product', errorDetails.errorMessage);
    yield put(actions.onNewProductImageUploadError(errorDetails));
  }
}

export function* onCommandExecution() {
  const event = yield select((state: State) => state.planogram.eventToSave);
  if (event) {
    yield put(actions.onPlanogramEventRequest(event));
  }
}

export default function* root() {
  yield all([
    takeLatest(constants.PLANOGRAM_LOADING, loadPlanogram),
    takeLatest(constants.FLOW_DATA_LOADING, loadFlowData),
    takeLatest(constants.PUBLIC_PLANOGRAM_LOADING, loadPublicPlanogram),
    takeLatest(constants.TEST_PLANOGRAM_LOADING, loadTestPlanogram),
    takeLatest(constants.ON_PLANOGRAM_EVENT_REQUEST, onPlanogramEvent),
    takeLatest(constants.CHANGE_PLANOGRAM_NAME, changeName),
    takeLatest(constants.DELETE_PLANOGRAM_REQUEST, deletePlanogram),
    takeLatest(constants.SAVE_SALES_DATA_REQUEST, saveSalesData),
    takeLatest(constants.UPLOAD_SHELFLOCATION_IMAGE_REQUEST, uploadShelfLocationImage),
    takeLatest(constants.FIX_IMAGE_SIZE_REQUEST, fixImageSize),
    takeLatest(constants.SET_SHELFLOCATION_GTIN_REQUEST, setShelfLocationGtin),
    takeLatest(constants.DELETE_SALES_DATA_REQUEST, deleteSalesData),
    takeLatest(constants.UPDATE_PLANOGRAM_COMMENTS_REQUEST, updateComments),
    takeLatest(constants.PLANOGRAM_PUBLIC_SHARE_TOGGLE_REQUEST, togglePublicSharing),
    takeLatest(constants.CREATE_TEST_PLANOGRAM_REQUEST, createTestPlanogram),
    takeLatest(constants.LOAD_TESTS_INFO_REQUEST, loadTestsInfoListForPlanogram),
    takeLatest(constants.UPDATE_TEST_INFO_REQUEST, updateTestInfo),
    takeLatest(constants.DELETE_TEST_REQUEST, deleteTest),
    takeLatest(constants.RECORD_CHANGE_REASON_REQUEST, recordChangeReason),
    takeLatest(constants.RESET_PLANOGRAM_REQUEST, resetPlanogram),
    takeLatest(constants.HAS_FINISHED_PRODUCT_TOUR_REQUEST, updateHasFinishedProductTour),
    takeLatest(constants.LOAD_COMPETITOR_PLANOGRAM_REQUEST, loadCompetitorPlanogram),
    takeLatest(constants.ON_NEW_PRODUCT_IMAGE_UPLOAD_REQUEST, uploadNewProductImage),
    takeLatest(constants.PLANOGRAM_IMAGE_LOADING, loadPlanogramImage),
    takeLatest(constants.ON_COMMAND_EXECUTION_REQUEST, onCommandExecution),
    takeLatest(constants.ON_PLANOGRAM_ACTION_UNDO_REQUEST, onCommandExecution),
  ]);
}
