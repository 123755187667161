import { Grid } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { toMaxPrecision, toMaxPrecisionPercent } from 'utils/Helpers';
import flowLogo from '../../../../../assets/img/brand/flow.jpg';
import { ProjectComparisonReportResult } from '../../types';
import '../styles/StandardReportStyle.css';
interface StandardProjectComparisonReportPageProps {
  projectComparisonReport: ProjectComparisonReportResult;
}
const StandardProjectComparisonReportPage: React.FC<StandardProjectComparisonReportPageProps> = ({ projectComparisonReport }: StandardProjectComparisonReportPageProps) => {
  const projectComparisonOverviewReport = projectComparisonReport.projectComparisonOverviewReport;
  const projectComparisonBrickMetricsReport = projectComparisonReport.projectComparisonBrickMetricsReport;
  return (
    <div className="page">
      <div id="flowLogo">
        <img alt="flow-logo" className="img-responsive" src={flowLogo} />
      </div>
      <div style={{ width: '100%' }}>
        <h3 className="text-center">
          Project Comparison Report
          <br />
        </h3>
        <h5 className="text-center">
          <p style={{ margin: '0px' }}>
            <b>Shelf A: </b> {projectComparisonOverviewReport.projectNameA}, {projectComparisonOverviewReport.storeNameA}, Phase {projectComparisonOverviewReport.phaseNumA}, Shelf{' '}
            {projectComparisonOverviewReport.shelfNumA} (
            <i>
              {moment(projectComparisonOverviewReport.dateFromA).format('DD/MM/YYYY')} - {moment(projectComparisonOverviewReport.dateToA).format('DD/MM/YYYY')}
            </i>
            )
          </p>
          <p style={{ margin: '0px' }}>
            <b>Shelf B: </b> {projectComparisonOverviewReport.projectNameB}, {projectComparisonOverviewReport.storeNameB}, Phase {projectComparisonOverviewReport.phaseNumB}, Shelf{' '}
            {projectComparisonOverviewReport.shelfNumB} (
            <i>
              {moment(projectComparisonOverviewReport.dateFromB).format('DD/MM/YYYY')} - {moment(projectComparisonOverviewReport.dateToB).format('DD/MM/YYYY')}
            </i>
            )
          </p>
          <br />
        </h5>
        <h5 className="text-left">
          <Grid container direction="row" spacing={2} justify="space-between">
            <Grid item>
              <p style={{ margin: '0px' }}>
                <b>Shelf A Visitors:</b> {projectComparisonOverviewReport.totalVisitorsA}
              </p>
              <p style={{ margin: '0px' }}>
                <b>Shelf B Visitors:</b> {projectComparisonOverviewReport.totalVisitorsB}
              </p>
            </Grid>
            <Grid item>
              <p style={{ margin: '0px' }}>
                <b>Shelf A Products: </b> {projectComparisonOverviewReport.productsCountA}
              </p>
              <p style={{ margin: '0px' }}>
                <b>Shelf B Products: </b> {projectComparisonOverviewReport.productsCountB}
              </p>
            </Grid>
          </Grid>
        </h5>
        <div className="row">
          <table style={{ marginTop: '10px' }} className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-center metricNameCell">Project Metrics</td>
                <td className="text-center metricNameCell">Conversion rate(percent)</td>
                <td className="text-center metricNameCell">Turnover per visitor(amount)</td>
                <td className="text-center metricNameCell">Daily turnover(average amount)</td>
                <td className="text-center metricNameCell">Destination buyers(percent)</td>
                <td className="text-center metricNameCell">Return ratio(percent)</td>
                <td className="text-center metricNameCell">Lost shoppers(percent)</td>
                <td className="text-center metricNameCell">Lost daily turnover(average)</td>
                <td className="text-center metricNameCell">Out of shelf(daily hours)</td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">% change</td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(projectComparisonOverviewReport.conversionRatePercChange, 2)} {projectComparisonOverviewReport.conversionRateSignificance}{' '}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(projectComparisonOverviewReport.turnoverPerVisitorPercChange, 2)} {projectComparisonOverviewReport.turnoverPerVisitorSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(projectComparisonOverviewReport.dailyTurnoverAveragePercChange, 2)} {projectComparisonOverviewReport.dailyTurnoverAverageSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(projectComparisonOverviewReport.destinationBuyersPercentPercChange, 2)} {projectComparisonOverviewReport.destinationBuyersPercentSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(projectComparisonOverviewReport.returnRatioPercentPercChange, 2)} {projectComparisonOverviewReport.returnRatioPercentSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(projectComparisonOverviewReport.lostShoppersPercentPercChange, 2)} {projectComparisonOverviewReport.lostShoppersPercentSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(projectComparisonOverviewReport.averageLostDailyTurnoverPercChange, 2)} {projectComparisonOverviewReport.averageLostDailyTurnoverSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(projectComparisonOverviewReport.outOfStockDailyHoursPercChange, 2)} {projectComparisonOverviewReport.outOfStockDailyHoursSignificance}
                </td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">Shelf A</td>
                <td className="text-center">{toMaxPrecisionPercent(projectComparisonOverviewReport.conversionRateA, 2)}</td>
                <td className="text-center">{toMaxPrecision(projectComparisonOverviewReport.turnoverPerVisitorA, 2)}</td>
                <td className="text-center">{toMaxPrecision(projectComparisonOverviewReport.dailyTurnoverAverageA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(projectComparisonOverviewReport.destinationBuyersPercentA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(projectComparisonOverviewReport.returnRatioPercentA, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(projectComparisonOverviewReport.lostShoppersPercentA, 2)}</td>
                <td className="text-center">{toMaxPrecision(projectComparisonOverviewReport.averageLostDailyTurnoverA, 2)}</td>
                <td className="text-center">{toMaxPrecision(projectComparisonOverviewReport.outOfStockDailyHoursA, 2)}</td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">Shelf B</td>
                <td className="text-center">{toMaxPrecisionPercent(projectComparisonOverviewReport.conversionRateB, 2)}</td>
                <td className="text-center">{toMaxPrecision(projectComparisonOverviewReport.turnoverPerVisitorB, 2)}</td>
                <td className="text-center">{toMaxPrecision(projectComparisonOverviewReport.dailyTurnoverAverageB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(projectComparisonOverviewReport.destinationBuyersPercentB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(projectComparisonOverviewReport.returnRatioPercentB, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(projectComparisonOverviewReport.lostShoppersPercentB, 2)}</td>
                <td className="text-center">{toMaxPrecision(projectComparisonOverviewReport.averageLostDailyTurnoverB, 2)}</td>
                <td className="text-center">{toMaxPrecision(projectComparisonOverviewReport.outOfStockDailyHoursB, 2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row">
          <table style={{ marginTop: '10px' }} className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-center metricNameCell">Project Metrics</td>
                <td className="text-center metricNameCell">Seconds to buy and leave(buyers)</td>
                <td className="text-center metricNameCell">Seconds to first interaction(all shoppers)</td>
                <td className="text-center metricNameCell">Lost shoppers time (seconds)</td>
                <td className="text-center metricNameCell">Interactions per shopper(average)</td>
                <td className="text-center metricNameCell">Average basket size(units)</td>
                <td className="text-center metricNameCell">Average basket size(turnover)</td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">% change</td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(projectComparisonOverviewReport.secondsToBuyAndLeavePercChange, 2)} {projectComparisonOverviewReport.secondsToBuyAndLeaveSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(projectComparisonOverviewReport.secondsToFirstInteractionPercChange, 2)} {projectComparisonOverviewReport.secondsToFirstInteractionSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(projectComparisonOverviewReport.lostShopperSecondsPercChange, 2)} {projectComparisonOverviewReport.lostShopperSecondsSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(projectComparisonOverviewReport.interactionsPerShopperPercChange, 2)} {projectComparisonOverviewReport.interactionsPerShopperSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(projectComparisonOverviewReport.averageBasketSizeUnitsPercChange, 2)} {projectComparisonOverviewReport.averageBasketSizeUnitsSignificance}
                </td>
                <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
                  {toMaxPrecisionPercent(projectComparisonOverviewReport.averageBasketSizeTurnoverPercChange, 2)} {projectComparisonOverviewReport.averageBasketSizeTurnoverSignificance}
                </td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">Shelf A</td>
                <td className="text-center">{toMaxPrecision(projectComparisonOverviewReport.secondsToBuyAndLeaveA, 2)}</td>
                <td className="text-center">{toMaxPrecision(projectComparisonOverviewReport.secondsToFirstInteractionA, 2)}</td>
                <td className="text-center">{toMaxPrecision(projectComparisonOverviewReport.lostShopperSecondsA, 2)}</td>
                <td className="text-center">{toMaxPrecision(projectComparisonOverviewReport.interactionsPerShopperA, 2)}</td>
                <td className="text-center">{toMaxPrecision(projectComparisonOverviewReport.averageBasketSizeUnitsA, 2)}</td>
                <td className="text-center">{toMaxPrecision(projectComparisonOverviewReport.averageBasketSizeTurnoverA, 2)}</td>
              </tr>
              <tr>
                <td className="text-center metricNameCell">Shelf B</td>
                <td className="text-center">{toMaxPrecision(projectComparisonOverviewReport.secondsToBuyAndLeaveB, 2)}</td>
                <td className="text-center">{toMaxPrecision(projectComparisonOverviewReport.secondsToFirstInteractionB, 2)}</td>
                <td className="text-center">{toMaxPrecision(projectComparisonOverviewReport.lostShopperSecondsB, 2)}</td>
                <td className="text-center">{toMaxPrecision(projectComparisonOverviewReport.interactionsPerShopperB, 2)}</td>
                <td className="text-center">{toMaxPrecision(projectComparisonOverviewReport.averageBasketSizeUnitsB, 2)}</td>
                <td className="text-center">{toMaxPrecision(projectComparisonOverviewReport.averageBasketSizeTurnoverB, 2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row">
          <table style={{ marginTop: '10px' }} className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-center metricNameCell">Top Bricks</td>
                <td className="text-center metricNameCell" colSpan={2}>
                  Share of space
                </td>
                <td className="text-center metricNameCell" colSpan={2}>
                  Share of unit sales
                </td>
                <td className="text-center metricNameCell" colSpan={2}>
                  Share of turnover
                </td>
                <td className="text-center metricNameCell" colSpan={2}>
                  Share of first pickups
                </td>
              </tr>
              {projectComparisonBrickMetricsReport.map(brick => {
                return (
                  <>
                    <tr key={brick.brickName}>
                      <td className="text-center metricNameCell" rowSpan={2}>
                        {brick.brickName}
                      </td>
                      <td className="text-center" colSpan={2}>
                        {toMaxPrecisionPercent(brick.shareOfSpaceChange, 2)}
                      </td>
                      <td className="text-center" colSpan={2}>
                        {toMaxPrecisionPercent(brick.shareOfUnitSalesPercChange, 2)}
                      </td>
                      <td className="text-center" colSpan={2}>
                        {toMaxPrecisionPercent(brick.shareOfTurnoverPercChange, 2)}
                      </td>
                      <td className="text-center" colSpan={2}>
                        {toMaxPrecisionPercent(brick.shareOfFirstPickupsPercChange, 2)}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">{toMaxPrecisionPercent(brick.shareOfSpaceA, 2)}</td>
                      <td className="text-center">{toMaxPrecisionPercent(brick.shareOfSpaceB, 2)}</td>
                      <td className="text-center">{toMaxPrecisionPercent(brick.shareOfUnitSalesA, 2)}</td>
                      <td className="text-center">{toMaxPrecisionPercent(brick.shareOfUnitSalesB, 2)}</td>
                      <td className="text-center">{toMaxPrecisionPercent(brick.shareOfTurnoverA, 2)}</td>
                      <td className="text-center">{toMaxPrecisionPercent(brick.shareOfTurnoverB, 2)}</td>
                      <td className="text-center">{toMaxPrecisionPercent(brick.shareOfFirstPickupsA, 2)}</td>
                      <td className="text-center">{toMaxPrecisionPercent(brick.shareOfFirstPickupsB, 2)}</td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <footer>
        <strong>Copyright &copy; {new Date().getFullYear()} Flow Insights</strong>. Strictly confidential. No distribution allowed. All rights reserved.
      </footer>
    </div>
  );
};

export { StandardProjectComparisonReportPage };
