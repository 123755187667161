import { Badge, Button, Card, CardContent, CardHeader, Collapse, IconButton, TextField, Typography } from '@material-ui/core';
import { Delete, ExpandLess, ExpandMore } from '@material-ui/icons';
import * as React from 'react';
import trackComponent from 'infrastructure/tracking/withTracking';
import { ProductDelistedReason, RecordChangeReasonDto } from 'modules/planogram/types/PlanogramChangeReasons';
import PlanogramModel from 'modules/planogram/domain/PlanogramModel';

interface MyProps {
  initialPlanogram: PlanogramModel;
  currentPlanogram: PlanogramModel;
  undoDelistedProduct(gtin: number): void;
  onRecordChangeReason(reason: RecordChangeReasonDto): void;
  onHighlightProduct(priority: boolean, gtin?: number[]): void;
}

interface MyState {
  isExpandedDelistHeader: boolean;
  delistReasons: DelistReason[];
}

interface DelistReason {
  gtin: number;
  reason: string;
}

class DelistedProducts extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {
      isExpandedDelistHeader: false,
      delistReasons: [],
    };
  }

  componentDidUpdate() {
    const reasons = this.state.delistReasons;
    const delisted = this.props.currentPlanogram.planogramChanges.productsDelisted;
    let needsUpdate = false;
    delisted.forEach(delistedProduct => {
      let existingReason = reasons.find(r => r.gtin === delistedProduct.gtin);
      if (!existingReason) {
        const savedReason = this.props.currentPlanogram.changeReasons.productDelistedReasons.find(dpp => dpp.gtin === delistedProduct.gtin);
        existingReason = {
          gtin: delistedProduct.gtin,
          reason: savedReason ? savedReason.reason : '',
        };
        needsUpdate = true;
        reasons.push(existingReason);
      }
    });
    if (needsUpdate) {
      this.setState({ delistReasons: reasons });
    }
  }

  render() {
    const delisted = this.props.currentPlanogram.planogramChanges.productsDelisted;

    const rows = delisted.map(d => {
      const canDelist = this.props.initialPlanogram.shelfLocations.find(sl => sl.gtin === d.gtin);
      const changeReason = this.state.delistReasons.find(r => r.gtin === d.gtin);
      if (!changeReason) {
        return null;
      }
      return (
        <div
          key={d.gtin}
          onMouseEnter={() => {
            this.props.onHighlightProduct(false, [d.gtin]);
          }}
          onMouseLeave={() => {
            this.props.onHighlightProduct(false, undefined);
          }}
        >
          <div>
            <li>
              <Typography color="textPrimary" variant="body2">
                {d.tradeItemDescription}
              </Typography>
            </li>
          </div>
          {!this.props.currentPlanogram.isReadOnly ? (
            <div>
              {!canDelist ? (
                <Typography variant="caption" color="error">
                  Cannot undo delist.
                </Typography>
              ) : null}
              {canDelist ? (
                <Button tabIndex={-1} size="small" variant="contained" color="primary" onClick={() => this.props.undoDelistedProduct(d.gtin)}>
                  Undo
                </Button>
              ) : null}
            </div>
          ) : null}
          <div>
            <Typography variant="caption" color="textSecondary">
              Gtin: {d.gtin}
            </Typography>
          </div>
          {!this.props.currentPlanogram.isReadOnly ? this.renderReasonTextfield(changeReason, d.gtin, 0) : this.renderReadonlyReason(changeReason.reason)}
        </div>
      );
    });
    return (
      <div>
        <Card
          raised
          onMouseLeave={() => {
            this.props.onHighlightProduct(false, undefined);
          }}
          className="card-accent-danger border-danger bottom-buffer"
        >
          <CardHeader
            style={{ background: 'rgb(204, 0, 0,.2)' }}
            disableTypography={true}
            action={
              <div>
                <Badge color="secondary" badgeContent={rows.length}>
                  <Delete color="primary" />
                </Badge>
                <IconButton onClick={() => this.setState({ isExpandedDelistHeader: !this.state.isExpandedDelistHeader })}>
                  {this.state.isExpandedDelistHeader ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
                </IconButton>
              </div>
            }
            title={<Typography variant="button">Delisted Products</Typography>}
          />
          <Collapse in={this.state.isExpandedDelistHeader} timeout="auto">
            <CardContent style={{ marginLeft: '0px', paddingLeft: '0px' }}>
              {delisted.length === 0 ? (
                <div className="left-buffer">
                  <Typography color="textSecondary" variant="caption">
                    No de-listed products.
                  </Typography>
                </div>
              ) : (
                <div>
                  <ol className="pull-left">{rows}</ol>
                </div>
              )}
            </CardContent>
          </Collapse>
        </Card>
      </div>
    );
  }
  renderReadonlyReason(reason: string): React.ReactNode {
    return (
      <Typography variant="caption" color="textSecondary">
        Reason for change: <strong>{reason ? reason : 'N/A'}</strong>
      </Typography>
    );
  }

  private renderReasonTextfield(changeReason: DelistReason, gtin: number, initialSize: number) {
    return (
      <div>
        <TextField
          value={changeReason.reason}
          onChange={evt => {
            const theReason = evt.target.value ? evt.target.value : '';
            changeReason.reason = theReason;
            this.setState({ delistReasons: this.state.delistReasons });
          }}
          id="standard-name"
          label="Reason for change"
          margin="none"
          onFocus={() => {
            this.props.onHighlightProduct(true, [gtin]);
          }}
          onBlur={() => {
            this.props.onHighlightProduct(true, undefined);
            const existingReason = this.props.currentPlanogram.changeReasons.productDelistedReasons.find(r => r.gtin === gtin);
            changeReason.reason = changeReason.reason ? changeReason.reason.trim() : '';
            if (existingReason && existingReason.reason === changeReason.reason) {
              return;
            }
            const reason = new RecordChangeReasonDto(this.props.currentPlanogram.id);
            const productDelistedReason = new ProductDelistedReason(gtin, initialSize, changeReason.reason);
            reason.productDelistedReason = productDelistedReason;
            this.props.onRecordChangeReason(reason);
          }}
        />
      </div>
    );
  }
}

export default trackComponent(DelistedProducts, 'Aside_DelistedProducts');
