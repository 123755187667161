import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Typography } from '@material-ui/core';
import { Close, Delete } from '@material-ui/icons';
import trackComponent from 'infrastructure/tracking/withTracking';
import * as React from 'react';
import { getDateInInputFormat } from '../../../../utils/dateHelper';
import TestInfoDto from '../../types/TestInfoDto';

const DeleteTestConfirmationModal = (props: DeleteTestConfirmationModalProps) => (
  <Dialog open={true} onClose={props.onClose}>
    <DialogTitle>
      <Typography variant="overline" gutterBottom>
        <Delete color="primary" fontSize="large" />
        &nbsp; Delete test confirmation
      </Typography>
    </DialogTitle>
    <DialogContent>
      <Typography variant="body1">Are you absolutely sure that you want to delete the test</Typography>
      <Typography variant="button">
        <strong>{props.testInfo.testName}</strong>?
      </Typography>
      <Typography variant="body1">This test was targeted for store</Typography>
      <Typography variant="button">
        <strong>{props.testInfo.operationsInfo.storeName}</strong>
      </Typography>

      <Typography variant="body1">
        between dates {getDateInInputFormat(props.testInfo.operationsInfo.startDate)} to {getDateInInputFormat(props.testInfo.operationsInfo.endDate)}.
      </Typography>
      <br />
      <Typography color="textSecondary" variant="body2">
        This action is permanent and you will not be able to recover this test planogram.
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button variant="contained" color="primary" disabled={!props.enabled} onClick={() => props.onConfirmed(props.testInfo.initialPlanogramId, props.testInfo.id)}>
        <Delete />
        &nbsp;
        {props.isDeleting ? 'Deleting test...' : 'Delete permanently'}
      </Button>
      &nbsp;
      <Button variant="contained" color="secondary" disabled={!props.enabled} onClick={props.onClose}>
        <Close />
        &nbsp;Cancel
      </Button>
    </DialogActions>
    {props.isDeleting ? <LinearProgress color="secondary" /> : null}
  </Dialog>
);

interface DeleteTestConfirmationModalProps {
  testInfo: TestInfoDto;

  enabled: boolean;

  isDeleting: boolean;

  onClose(): void;

  onConfirmed(planogramId: string, testIdToDelete: string): void;
}

export default trackComponent(DeleteTestConfirmationModal, 'Planogram_DeleteTestConfirmationModal');
