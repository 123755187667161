import React from 'react';
import Select from 'react-select';
import { StoreOption } from '../../../reports/types';

export interface StoreSelectorProps {
  htmlId: string;
  storeOptions: StoreOption[];
  selectedStore?: StoreOption;
  handleOnSelectStore(selectedStore: StoreOption): void;
}

export const StoreSelector: React.FC<StoreSelectorProps> = ({ htmlId, storeOptions, selectedStore, handleOnSelectStore }: StoreSelectorProps) => {
  const defaultStoreOption: StoreOption = {
    label: 'Select Store',
    projectId: -1,
    storeId: -1,
    storeName: 'Select Store',
    value: -1,
  };
  return (
    <Select
      styles={{
        // Fixes the overlapping problem of the component
        menu: provided => ({ ...provided, zIndex: 9999 }),
      }}
      inputId={htmlId}
      placeholder="Select Store"
      options={storeOptions}
      value={selectedStore === undefined ? defaultStoreOption : selectedStore}
      onChange={(store: StoreOption): void => handleOnSelectStore(store)}
      isMulti={false}
      isDisabled={storeOptions.length === 0}
    />
  );
};
