import GeometryPoint from '../../shared/geometry/GeometryPoint';
import PointPosition from './PointPosition';

export default class ShelfLocationGeometryPoint implements GeometryPoint {
  x = 0;
  y = 0;
  isTransformed = false;
  // these are needed when point or the polygon is being dragged
  xOffset: number;
  yOffset: number;
  isBeingDragged: boolean;
  position: PointPosition = PointPosition.BottomLeft;

  constructor(x?: number, y?: number) {
    this.xOffset = 0;
    this.yOffset = 0;
    this.isBeingDragged = false;
    this.x = x ? x : 0;
    this.y = y ? y : 0;
  }

  getCurrentPoint(): ShelfLocationGeometryPoint {
    const point = new ShelfLocationGeometryPoint();
    point.x = this.x + this.xOffset;
    point.y = this.y + this.yOffset;
    point.isTransformed = this.isTransformed;
    point.isBeingDragged = false;
    point.position = this.position;
    return point;
  }
}
