import Command from 'infrastructure/commands/Command';
import PlanogramEventBase from '../events/PlanogramEventBase';
import UndoableCommand from 'infrastructure/commands/UndoableCommand';
import StackedCommandManager from 'infrastructure/commands/StackedCommandManager';

export abstract class PlanogramCommand extends Command<PlanogramEventBase> {}

export abstract class PlanogramUndoableCommand extends UndoableCommand<PlanogramEventBase> {}

export class PlanogramCommandManager extends StackedCommandManager<PlanogramEventBase> {}
