import ErrorDetails from 'infrastructure/exception/ErrorDetails';
import {
  AGGREGATED_REPORTS_LOAD_AVAILABLE_REPORTS_FAILURE,
  AGGREGATED_REPORTS_LOAD_AVAILABLE_REPORTS_REQUEST,
  AGGREGATED_REPORTS_LOAD_AVAILABLE_REPORTS_SUCCESS,
  AGGREGATED_REPORTS_LOAD_PRODUCT_REPORT_REQUEST,
  AGGREGATED_REPORTS_LOAD_PRODUCT_REPORT_SUCCESS,
  AGGREGATED_REPORTS_LOAD_PRODUCT_REPORT_FAILURE,
  LoadAggregatedProductReportFailureActionType,
  LoadAggregatedProductReportRequestActionType,
  LoadAggregatedProductReportSuccessActionType,
  LoadAvailableAggregatedReportsFailureActionType,
  LoadAvailableAggregatedReportsRequestActionType,
  LoadAvailableAggregatedReportsSuccessActionType,
  AGGREGATED_REPORTS_LOAD_BRAND_BRICK_REPORT_FAILURE,
  AGGREGATED_REPORTS_LOAD_BRAND_BRICK_REPORT_REQUEST,
  AGGREGATED_REPORTS_LOAD_BRAND_BRICK_REPORT_SUCCESS,
  AGGREGATED_REPORTS_LOAD_BRAND_REPORT_FAILURE,
  AGGREGATED_REPORTS_LOAD_BRAND_REPORT_REQUEST,
  AGGREGATED_REPORTS_LOAD_BRAND_REPORT_SUCCESS,
  AGGREGATED_REPORTS_LOAD_BRICK_REPORT_FAILURE,
  AGGREGATED_REPORTS_LOAD_BRICK_REPORT_REQUEST,
  AGGREGATED_REPORTS_LOAD_BRICK_REPORT_SUCCESS,
  LoadAggregatedBrandBrickReportFailureActionType,
  LoadAggregatedBrandBrickReportRequestActionType,
  LoadAggregatedBrandBrickReportSuccessActionType,
  LoadAggregatedBrandReportFailureActionType,
  LoadAggregatedBrandReportRequestActionType,
  LoadAggregatedBrandReportSuccessActionType,
  LoadAggregatedBrickReportFailureActionType,
  LoadAggregatedBrickReportRequestActionType,
  LoadAggregatedBrickReportSuccessActionType,
} from './actionTypes';
import { AggregatedMetrics, AggregatedOnePagerReport, AvailableAggregatedReports } from './types';

export function loadAvailableAggregatedReportsRequestAction(): LoadAvailableAggregatedReportsRequestActionType {
  return {
    type: AGGREGATED_REPORTS_LOAD_AVAILABLE_REPORTS_REQUEST,
  };
}

export function loadAvailableAggregatedReportsSuccessAction(data: AvailableAggregatedReports): LoadAvailableAggregatedReportsSuccessActionType {
  return {
    type: AGGREGATED_REPORTS_LOAD_AVAILABLE_REPORTS_SUCCESS,
    data,
  };
}

export function loadAvailableAggregatedReportsFailureAction(error: ErrorDetails): LoadAvailableAggregatedReportsFailureActionType {
  return {
    type: AGGREGATED_REPORTS_LOAD_AVAILABLE_REPORTS_FAILURE,
    error,
  };
}

export function loadAggregatedProductReportRequestAction(gtin: number, metrics: AggregatedMetrics[]): LoadAggregatedProductReportRequestActionType {
  return {
    type: AGGREGATED_REPORTS_LOAD_PRODUCT_REPORT_REQUEST,
    gtin,
    metrics,
  };
}

export function loadAggregatedProductReportSuccessAction(data: AggregatedOnePagerReport): LoadAggregatedProductReportSuccessActionType {
  return {
    type: AGGREGATED_REPORTS_LOAD_PRODUCT_REPORT_SUCCESS,
    data,
  };
}

export function loadAggregatedProductReportFailureAction(error: ErrorDetails): LoadAggregatedProductReportFailureActionType {
  return {
    type: AGGREGATED_REPORTS_LOAD_PRODUCT_REPORT_FAILURE,
    error,
  };
}

export function loadAggregatedBrandReportRequestAction(brandId: number, metrics: AggregatedMetrics[]): LoadAggregatedBrandReportRequestActionType {
  return {
    type: AGGREGATED_REPORTS_LOAD_BRAND_REPORT_REQUEST,
    brandId,
    metrics,
  };
}

export function loadAggregatedBrandReportSuccessAction(data: AggregatedOnePagerReport): LoadAggregatedBrandReportSuccessActionType {
  return {
    type: AGGREGATED_REPORTS_LOAD_BRAND_REPORT_SUCCESS,
    data,
  };
}

export function loadAggregatedBrandReportFailureAction(error: ErrorDetails): LoadAggregatedBrandReportFailureActionType {
  return {
    type: AGGREGATED_REPORTS_LOAD_BRAND_REPORT_FAILURE,
    error,
  };
}

export function loadAggregatedBrickReportRequestAction(gpcBrickCode: number, metrics: AggregatedMetrics[]): LoadAggregatedBrickReportRequestActionType {
  return {
    type: AGGREGATED_REPORTS_LOAD_BRICK_REPORT_REQUEST,
    gpcBrickCode,
    metrics,
  };
}

export function loadAggregatedBrickReportSuccessAction(data: AggregatedOnePagerReport): LoadAggregatedBrickReportSuccessActionType {
  return {
    type: AGGREGATED_REPORTS_LOAD_BRICK_REPORT_SUCCESS,
    data,
  };
}

export function loadAggregatedBrickReportFailureAction(error: ErrorDetails): LoadAggregatedBrickReportFailureActionType {
  return {
    type: AGGREGATED_REPORTS_LOAD_BRICK_REPORT_FAILURE,
    error,
  };
}

export function loadAggregatedBrandBrickReportRequestAction(brandId: number, gpcBrickCode: number, metrics: AggregatedMetrics[]): LoadAggregatedBrandBrickReportRequestActionType {
  return {
    type: AGGREGATED_REPORTS_LOAD_BRAND_BRICK_REPORT_REQUEST,
    brandId,
    gpcBrickCode,
    metrics,
  };
}

export function loadAggregatedBrandBrickReportSuccessAction(data: AggregatedOnePagerReport): LoadAggregatedBrandBrickReportSuccessActionType {
  return {
    type: AGGREGATED_REPORTS_LOAD_BRAND_BRICK_REPORT_SUCCESS,
    data,
  };
}

export function loadAggregatedBrandBrickReportFailureAction(error: ErrorDetails): LoadAggregatedBrandBrickReportFailureActionType {
  return {
    type: AGGREGATED_REPORTS_LOAD_BRAND_BRICK_REPORT_FAILURE,
    error,
  };
}
