import { isLoaded, isLoading } from 'infrastructure/utils/RemoteObjectStatus';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { loadStandardBrandReportRequestAction } from '../../actions';
import * as selectors from '../../selectors';
import { BrandReportResult } from '../../types';
interface LoadStatusParams {
  isLoaded: boolean;
  isLoading: boolean;
  loadError: string;
  isDataEmpty: boolean;
  data: BrandReportResult | undefined;
}
export interface StandardBrandReportLoaderProps {
  children: (params: LoadStatusParams) => React.ReactNode;
}

const StandardBrandReportLoader: React.FC<StandardBrandReportLoaderProps> = ({ children }: StandardBrandReportLoaderProps) => {
  type RequestParamsType = {
    shelfPhaseId: string;
    brandId: string;
  };

  const { shelfPhaseId, brandId } = useParams<RequestParamsType>();
  const dispatch = useDispatch();
  const loadState = useSelector(selectors.getBrandReportLoadState);
  const isDataEmpty = useSelector(selectors.isBrandReportEmpty);
  const data = useSelector(selectors.getBrandReportData);
  useEffect(() => {
    if (isDataEmpty && shelfPhaseId && brandId !== undefined) {
      dispatch(loadStandardBrandReportRequestAction(shelfPhaseId, parseInt(brandId)));
    }
  }, [dispatch, isDataEmpty, shelfPhaseId, brandId]);
  return (
    <>
      {children({
        isLoaded: isLoaded(loadState),
        isLoading: isLoading(loadState),
        loadError: loadState?.errorDetails?.errorMessage ?? '',
        isDataEmpty,
        data,
      })}
    </>
  );
};

export { StandardBrandReportLoader };
