import { first, orderBy, take } from 'lodash';
import GeometryPoint from '../../shared/geometry/GeometryPoint';
import ViewPort from '../types/ViewPort';
import ShelfLocationGeometryPoint from '../domain/ShelfLocationGeometryPoint';
import { getLineDistance } from 'modules/shared/geometry/helpers';

export function getLeftTopMostPoint(geometry: ShelfLocationGeometryPoint[]): ShelfLocationGeometryPoint {
  let ordered = orderBy(geometry, ['x'], ['asc']);
  ordered = take(ordered, 2);
  ordered = orderBy(ordered, ['y'], ['asc']);
  return first(ordered) as ShelfLocationGeometryPoint;
}

export function getLeftBottomMostPoint(geometry: ShelfLocationGeometryPoint[]): ShelfLocationGeometryPoint {
  let ordered = take(orderBy(geometry, ['x'], ['asc']), 2);
  ordered = orderBy(ordered, ['y'], ['desc']);
  return first(ordered) as ShelfLocationGeometryPoint;
}

export function getRightTopMostPoint(geometry: ShelfLocationGeometryPoint[]): ShelfLocationGeometryPoint {
  let ordered = take(orderBy(geometry, ['x'], ['desc']), 2);
  ordered = orderBy(ordered, ['y'], ['asc']);
  return first(ordered) as ShelfLocationGeometryPoint;
}

export function getRightBottomMostPoint(geometry: ShelfLocationGeometryPoint[]): ShelfLocationGeometryPoint {
  let ordered = take(orderBy(geometry, ['x'], ['desc']), 2);
  ordered = orderBy(ordered, ['y'], ['desc']);
  return first(ordered) as ShelfLocationGeometryPoint;
}

function putToSameHeight(c1: GeometryPoint, c2: GeometryPoint, bottom: boolean): void {
  if (c1.y < c2.y) {
    if (bottom) {
      c2.y = c1.y;
    } else {
      c1.y = c2.y;
    }
  }
  if (c1.y > c2.y) {
    if (bottom) {
      c2.y = c1.y;
    } else {
      c1.y = c2.y;
    }
  }
}

function putToSameWidth(c1: GeometryPoint, c2: GeometryPoint, left: boolean): void {
  if (c1.x < c2.x) {
    if (left) {
      c1.x = c2.x;
    } else {
      c2.x = c1.x;
    }
  }
  if (c1.x > c2.x) {
    if (left) {
      c2.x = c1.x;
    } else {
      c1.x = c2.x;
    }
  }
}

export function fixPolygonToSquare(geometry: ShelfLocationGeometryPoint[]): void {
  const leftTop = getLeftTopMostPoint(geometry);
  const leftBottom = getLeftBottomMostPoint(geometry);
  const rightTop = getRightTopMostPoint(geometry);
  const rightBottom = getRightBottomMostPoint(geometry);

  putToSameHeight(leftBottom, rightBottom, true);
  putToSameHeight(leftTop, rightTop, false);
  putToSameWidth(leftBottom, leftTop, true);
  putToSameWidth(rightBottom, rightTop, false);
}

export function calculateRectangleArea(points: ShelfLocationGeometryPoint[]): number {
  const topLeft = getLeftTopMostPoint(points);
  const topRight = getRightTopMostPoint(points);
  const bottomLeft = getLeftBottomMostPoint(points);
  const width = getLineDistance(topLeft, topRight);
  const height = getLineDistance(topLeft, bottomLeft);
  return width * height;
}

export function pixelToCm(shelfWidthCm: number, shelfImageUrlWidth: number, pixels: number): number {
  return (shelfWidthCm * pixels) / (shelfImageUrlWidth * 1.0);
}

export function getCenterPoint(points: GeometryPoint[]): GeometryPoint {
  let minX = points[0].x;
  let maxX = points[0].x;
  let minY = points[0].y;
  let maxY = points[0].y;

  for (let i = 1; i < points.length; i++) {
    const point = points[i];
    if (point.x < minX) {
      minX = point.x;
    }
    if (point.x > maxX) {
      maxX = point.x;
    }
    if (point.y < minY) {
      minY = point.y;
    }
    if (point.y > maxY) {
      maxY = point.y;
    }
  }
  const center = {
    x: minX + (maxX - minX) / 2,
    y: minY + (maxY - minY) / 2,
  } as GeometryPoint;
  return center;
}

export function convertHeightToRelative(height: number, viewPort: ViewPort): number {
  return 1 - height / viewPort.getHeigth();
}

export function convertHeightToAbsolute(height: number, viewPort: ViewPort): number {
  return (1 - height) * viewPort.getHeigth();
}

export function convertWidthToRelative(width: number, viewPort: ViewPort): number {
  return width / viewPort.getWidth();
}

export function convertWidthToAbsolute(width: number, viewPort: ViewPort): number {
  return width * viewPort.getWidth();
}

export function convertPointToAbsolute(point: GeometryPoint, viewPort: ViewPort): GeometryPoint {
  return {
    x: convertWidthToAbsolute(point.x, viewPort),
    y: convertHeightToAbsolute(point.y, viewPort),
  };
}

export function convertPointToRelative(point: GeometryPoint, viewPort: ViewPort): GeometryPoint {
  return {
    x: convertWidthToRelative(point.x, viewPort),
    y: convertHeightToRelative(point.y, viewPort),
  };
}
