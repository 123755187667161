import { ActionType, getType, Reducer } from 'typesafe-actions';

import { InspirationImagesState, InspirationImageItem, DeleteInspirationImagesSuccessAction, UpdateDescInspirationImageSuccessAction } from './types';
import * as actions from './actions';

import ErrorDetails from '../../../infrastructure/exception/ErrorDetails';
import { setError, setIdle, setLoading, setSuccess } from 'infrastructure/utils/RemoteObjectStatus';

type Actions = ActionType<typeof actions>;

const removeInspirationImage = (data: InspirationImageItem[], action: ReturnType<DeleteInspirationImagesSuccessAction>): InspirationImageItem[] =>
  data.filter(item => item.id !== action.payload.inspirationImageId);

const updateDescription = (data: InspirationImageItem[], action: ReturnType<UpdateDescInspirationImageSuccessAction>): InspirationImageItem[] => {
  return data.map(item => {
    if (item.id === action.payload.inspirationImageId) {
      return {
        ...item,
        ...{
          description: action.payload.description,
        },
      };
    }
    return item;
  });
};

const reducer: Reducer<InspirationImagesState, Actions> = (state = setIdle<InspirationImageItem[]>(), action) => {
  switch (action.type) {
    case getType(actions.fetchInspirationImagesRequest):
      return setLoading('Loading inspiration images...');
    case getType(actions.fetchInspirationImagesSuccess):
      return setSuccess(action.payload as InspirationImageItem[]);
    case getType(actions.fetchInspirationImagesFailed):
      return setError(action.payload as ErrorDetails);

    case getType(actions.deleteInspirationImageSuccess):
      return {
        ...state,
        ...{
          data: removeInspirationImage(state.data as InspirationImageItem[], action as ReturnType<DeleteInspirationImagesSuccessAction>),
        },
      };

    case getType(actions.updateDescInspirationImageSuccess):
      return {
        ...state,
        ...{
          data: updateDescription(state.data as InspirationImageItem[], action as ReturnType<UpdateDescInspirationImageSuccessAction>),
        },
      };
    default:
      return state;
  }
};

export default reducer;
