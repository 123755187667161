import trackComponent from 'infrastructure/tracking/withTracking';
import ResizeObserverComponent from 'modules/shared/components/ResizeObserverComponent';
import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { State } from 'state';
import * as actions from '../actions';
import ShelfLocation from '../domain/ShelfLocation';
import PlanogramState from '../state';
import { NewProductModel } from '../types/NewProductModel';
import PlanogramComponent from './PlanogramComponent/PlanogramComponentWithHook';
import { isError, isLoading } from 'infrastructure/utils/RemoteObjectStatus';
import { AlertErrorTextIndicator } from 'modules/shared/components/LoadStatusIndicators/AlertErrorTextIndicator';
import { PlanogramFloatingPanel } from './PlanogramFloatingPanel';

class CompetitorPlanogramPage extends Component<PlanogramState & DispatchProps> {
  componentWillUnmount(): void {
    document.onkeydown = null;
    this.props.planogramPageReset();
  }

  componentDidMount(): void {
    this.props.loadCompetitorPlanogramRequest(this.props.id, this.props.shelfReferenceId);
  }
  render(): JSX.Element {
    return (
      <div>
        {isError(this.props.loadPlanogramStatus) ? (
          <AlertErrorTextIndicator errorText={this.props.loadPlanogramStatus.errorDetails?.errorMessage} />
        ) : (
          <LoadingOverlay
            active={isLoading(this.props.loadImageStatus) || isLoading(this.props.loadPlanogramStatus)}
            spinner={true}
            text={isLoading(this.props.loadPlanogramStatus) ? 'Loading planogram...' : isLoading(this.props.loadImageStatus) ? 'Downloading Shelf image...' : ''}
          >
            <ResizeObserverComponent
              domElementIdToWatch="content-main-element"
              domElementIdHeightToUse="content-main-parent-element"
              onResizeDetected={(width: number, height: number): void => {
                this.props.updatePlanogramViewPort(width, height);
              }}
            />
            {this.props.planogram && <PlanogramComponent />}
            {this.props.enableShowProductInfo && <PlanogramFloatingPanel canFixImageSize={false} />}
          </LoadingOverlay>
        )}
      </div>
    );
  }
}

interface DispatchProps {
  loadCompetitorPlanogramRequest(id: string, shelfReferenceId: string): void;
  planogramPageReset(): void;
  updatePlanogramViewPort(width: number, height: number): void;
  toggleEnableShowProductInfo(enabled?: boolean): void;
  setShelfLocationGtinRequest(planogramId: string, shelfLocation: ShelfLocation, gtin: number, newProduct?: NewProductModel): void;
}

interface MatchParams {
  id: string;
  shelfReferenceId: string;
}

function mapStateToProps(state: State, ownProps: RouteComponentProps<MatchParams>): PlanogramState {
  return {
    ...state.planogram,
    id: ownProps.match.params.id,
    shelfReferenceId: ownProps.match.params.shelfReferenceId,
    userId: Number(state.auth.loadUserStatus.data?.profile.sub ?? 0),
  };
}

const componentToExport = connect<PlanogramState, DispatchProps, RouteComponentProps<MatchParams>>(mapStateToProps, actions)(CompetitorPlanogramPage);

export default trackComponent(componentToExport, 'CompetitorPlanogramPage');
