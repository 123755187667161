import { classToClass, Type } from 'class-transformer';
import { Guid } from 'guid-typescript';
import { LiteEventInterface, LiteEvent } from 'infrastructure/utils/LiteEvent';
import { remove } from 'lodash';
import CompetitorShelf from 'modules/admin/planograms/types/CompetitorShelf';
import { BrandPermissionType } from 'modules/planograms/types/BrandPermissionType';
import { telemetryService } from '../../../infrastructure/tracking/TelemetryService';
import { ActionRules } from '../../planograms/types/ActionRules';
import AggregatedEvents from '../events/AggregatedEvents';
import PlanogramEventBase from '../events/PlanogramEventBase';
import ShelfLocationImageChangedEvent from '../events/ShelfLocationImageChangedEvent';
import ShelfLocationsDeletedEvent from '../events/ShelfLocationsDeletedEvent';
import ShelfLocationsInsertedEvent from '../events/ShelfLocationsInsertedEvent';
import ShelfLocationsMovedEvent from '../events/ShelfLocationsMovedEvent';
import { fixPolygonToSquare } from '../helpers/geometry';
import GeometryPoint from '../../shared/geometry/GeometryPoint';
import { PlanogramChangeReasons } from '../types/PlanogramChangeReasons';
import { PlanogramChanges } from '../types/PlanogramChanges';
import ProductModel from '../types/ProductModel';
import ShelfLocation from './ShelfLocation';
import ViewPort from '../types/ViewPort';
import { ShelfConditionsModel } from '../../../types/ShelfConditionsModel';
import calculatePointDragOffsets from './calculatePointDragOffsets';
import { InspirationImagesModel } from '../../../types/InspirationImagesModel';
import PointPosition from './PointPosition';
import ShelfLocationGeometryPoint from './ShelfLocationGeometryPoint';
import { getLineDistance } from 'modules/shared/geometry/helpers';

class PlanogramSharingModel {
  publicLinkEnabled = true;
  publicToken = '';
}

export class BrandSpace {
  brandId = 0;
  currentSpace = 0;
  initialSize = 0;
  // Validation flags
  canIncreaseViolation = false;
  canDecreaseViolation = false;

  resetViolation(): void {
    this.canIncreaseViolation = false;
    this.canDecreaseViolation = false;
  }

  isValid(): boolean {
    const isInvalid = this.canDecreaseViolation || this.canIncreaseViolation;

    return !isInvalid;
  }
}

export default class PlanogramModel {
  id = '';
  shelfReferenceId = '';
  name = '';
  createdOn: Date = new Date();
  ownerId = 0;
  builderId = 0;
  email = '';
  goal = '';
  imageUrl = '';
  shelfWidthCm = 0;
  shelfHeightCm = 0;
  shelfDepthCm = 0;
  shelfImageUrlHeight = 0;
  shelfImageUrlWidth = 0;
  storeName = '';
  dataInsights: string[] = [];
  actionRules: ActionRules = {} as ActionRules;
  @Type(() => ShelfLocation)
  shelfLocations: ShelfLocation[] = [];
  @Type(() => PlanogramSharingModel)
  sharingOptions: PlanogramSharingModel = new PlanogramSharingModel();
  comments = '';
  isReadOnly = false;
  shadowShelfLocations: ShelfLocation[] = [];
  totalArea = 0;
  initialArea = 0;
  competitorShelves: CompetitorShelf[] = [];

  /**
   * Flag indicating whether this planogram is valid. This flag is set as a result
   * of calling the 'calculateIsValid' method.
   */
  isValid = true;

  brandSpaces: BrandSpace[] = [];
  initialBrandSpaces: BrandSpace[] = [];

  @Type(() => PlanogramChangeReasons)
  changeReasons: PlanogramChangeReasons = new PlanogramChangeReasons();
  planogramChanges: PlanogramChanges = new PlanogramChanges();
  initialShelfLocations: ShelfLocation[] = [];
  initialShelfWidthCm = 0;
  initialShelfImageUrlWidth = 0;
  invalidReasons = '';

  shelfConditions: ShelfConditionsModel = { isShelfConditionsEnabled: false };
  inspirationImages: InspirationImagesModel = {
    isInspirationImagesEnabled: false,
  };

  // When this field is true, planogram doesn't calculate postmutation work and doesn't emit events.
  private isInTransaction = false;

  // When in transaction, all events are saved in an array and published at once.
  private transactionEvents: PlanogramEventBase[] = [];

  private imageViewPort: ViewPort = new ViewPort(0, 0);

  // TODO: Create an actual repository shared between components.
  private productRepository: ProductModel[] = [];

  /**
   * Events
   */
  private readonly domainErrorEvent = new LiteEvent<string>();
  private readonly planogramChangedEvent = new LiteEvent<PlanogramEventBase>();

  get OnDomainError(): LiteEventInterface<string> {
    return this.domainErrorEvent.expose();
  }

  get OnPlanogramChangedEvent(): LiteEventInterface<PlanogramEventBase> {
    return this.planogramChangedEvent.expose();
  }

  /**
   * End of Events
   */

  initialize(
    products: ProductModel[],
    initialShelfLocations: ShelfLocation[],
    initialShelfWidthCm: number,
    initialShelfImageUrlWidth: number,
    initialBrandSpaces: BrandSpace[] = [],
    initialArea = 0
  ): void {
    this.productRepository = products;
    this.initialBrandSpaces = initialBrandSpaces;
    this.initialArea = initialArea;
    this.initialShelfLocations = initialShelfLocations;
    this.initialShelfWidthCm = initialShelfWidthCm;
    this.initialShelfImageUrlWidth = initialShelfImageUrlWidth;
    this.shelfLocations.forEach(sl => {
      const g = sl.geometry;
      if (g.points[0].isTransformed) {
        return;
      }
      const imageViewPort = this.imageViewPort;
      if (g.originalWidth > 0) {
        g.currentHeight = (1 - g.currentHeight) * imageViewPort.getHeigth();
        g.currentWidth = g.currentWidth * imageViewPort.getWidth();
        g.heightBottomChanged = (1 - g.heightBottomChanged) * imageViewPort.getHeigth();
        g.heightTopChanged = (1 - g.heightTopChanged) * imageViewPort.getHeigth();
        g.originalHeight = (1 - g.originalHeight) * imageViewPort.getHeigth();
        g.originalWidth = g.originalWidth * imageViewPort.getWidth();
        g.widthLeftChanged = g.widthLeftChanged * imageViewPort.getWidth();
        g.widthRightChanged = g.widthRightChanged * imageViewPort.getWidth();
      }
      sl.geometry.points.forEach(point => {
        if (point.isTransformed) {
          return;
        }
        point.x = imageViewPort.getWidth() * point.x;
        point.y = (1 - point.y) * imageViewPort.getHeigth();

        point.xOffset = 0;
        point.yOffset = 0;
        point.isTransformed = true;
      });
      fixPolygonToSquare(sl.geometry.points);

      sl.geometry.calculatePointsPosition();

      const topLeft = sl.geometry.getPointByPosition(PointPosition.TopLeft);
      const topRight = sl.geometry.getPointByPosition(PointPosition.TopRight);
      const bottomLeft = sl.geometry.getPointByPosition(PointPosition.BottomLeft);

      const width = getLineDistance(topLeft, topRight);
      const height = getLineDistance(topLeft, bottomLeft);

      if (!sl.geometry.originalWidth || sl.geometry.originalWidth <= 0) {
        sl.geometry.originalWidth = width;
        sl.geometry.currentWidth = width;
      }
      if (!sl.geometry.originalHeight || sl.geometry.originalHeight <= 0) {
        sl.geometry.originalHeight = height;
        sl.geometry.currentHeight = height;
      }
    });

    this.shadowShelfLocations = classToClass(this.shelfLocations);
    this.shadowShelfLocations.forEach(x => {
      x.id = Guid.create().toString();
    });
    this.postMutationWork();
  }

  startTransaction(): void {
    this.isInTransaction = true;
    this.transactionEvents = [];
  }

  commitTransaction(): PlanogramEventBase {
    this.isInTransaction = false;
    const aggregatedEvents = new AggregatedEvents(this.id, this.transactionEvents);
    this.postMutationWork(aggregatedEvents);
    this.transactionEvents = [];
    return aggregatedEvents;
  }

  cancelTransaction(): void {
    this.isInTransaction = false;
    this.transactionEvents = [];
  }

  setImageViewPort(imageViewPort: ViewPort): void {
    this.imageViewPort = imageViewPort;
  }

  // domain functions
  deleteShelfLocations(shelfLocationsToDelete: ShelfLocation[]): PlanogramEventBase {
    let canExecute = true;
    if (!this.actionRules.isDisabled) {
      shelfLocationsToDelete.forEach(sl => {
        if (!sl.actionsAllowed[BrandPermissionType.canDelist] && this.shelfLocations.filter(s => s.gtin === sl.gtin).length === 1) {
          this.domainErrorEvent.trigger(`Brand rules don't allow deleting of this shelf location.`);
          canExecute = false;
        }
      });
    }

    canExecute = canExecute || this.actionRules.isDisabled;
    if (!canExecute) {
      throw new Error("Can't execute deleting of shelf locations");
    }
    remove(this.shelfLocations, sl => {
      return shelfLocationsToDelete.indexOf(sl) > -1;
    });
    const evt = new ShelfLocationsDeletedEvent(
      this.id,
      shelfLocationsToDelete.map(sl => sl.id)
    );
    this.postMutationWork(evt);
    return evt;
  }

  addShelfLocations(shelfLocationsToAdd: ShelfLocation[]): PlanogramEventBase {
    shelfLocationsToAdd.forEach(sl => {
      sl.lastChangedOn = new Date();
      sl.geometry.applyMovingState();
      sl.setDefaultActionRules();
      this.shelfLocations.push(sl);
    });
    const event = new ShelfLocationsInsertedEvent(this.id, shelfLocationsToAdd, this.imageViewPort);
    this.postMutationWork(event);
    return event;
  }

  transientMove(shelfLocations: ShelfLocation[], geometryOffset: GeometryPoint): PlanogramEventBase | undefined {
    shelfLocations.forEach(shelfLocation => {
      if (!this.actionRules.isDisabled && !shelfLocation.canMove()) {
        return;
      }
      shelfLocation.geometry.points.forEach(p => {
        p.xOffset = geometryOffset.x;
        p.yOffset = geometryOffset.y;
      });
    });
    this.postMutationWork();
    return undefined;
  }

  transientDrag(shelfLocation: ShelfLocation, draggingPoint: ShelfLocationGeometryPoint, geometryOffset: GeometryPoint): PlanogramEventBase | undefined {
    if (!this.actionRules.isDisabled && !shelfLocation.canMove()) {
      throw new Error("Can't move selected shelf locations");
    }
    const newOffset = calculatePointDragOffsets(shelfLocation, draggingPoint, geometryOffset);

    draggingPoint.xOffset = newOffset.x;
    draggingPoint.yOffset = newOffset.y;
    shelfLocation.geometry.points.forEach(point => {
      if (point === draggingPoint) {
        return;
      }
      // we need to apply the same offset to neighbour points -
      // to keep the rectangular shape.
      if (point.x === draggingPoint.x) {
        point.xOffset = draggingPoint.xOffset;
      }
      if (point.y === draggingPoint.y) {
        point.yOffset = draggingPoint.yOffset;
      }
    });
    shelfLocation.geometry.calculateDraggingPoint(draggingPoint);
    this.postMutationWork();
    return undefined;
  }

  changeShelfLocationImageUrl(sl: ShelfLocation, imageUrl: string, imageWidth: number, imageHeight: number): PlanogramEventBase {
    sl.imageUrl = imageUrl;
    sl.geometry.originalHeight = imageHeight;
    sl.geometry.originalWidth = imageWidth;
    sl.geometry.hasFixedImage = true;
    const evt = new ShelfLocationImageChangedEvent(this.id, sl.id, imageUrl);
    this.postMutationWork(evt);
    return evt;
  }

  applyTransientStateToShelfLocations(shelfLocations: ShelfLocation[]): PlanogramEventBase {
    if (shelfLocations.filter(sl => !sl.canMove()).length > 0) {
      if (!this.actionRules.isDisabled) {
        throw new Error("Can't move selected shelf locations");
      }
    }
    shelfLocations.forEach(sl => {
      sl.geometry.applyMovingState();
      sl.lastChangedOn = new Date();
    });
    const event = new ShelfLocationsMovedEvent(this.id, shelfLocations, this.imageViewPort);
    this.postMutationWork(event);
    return event;
  }

  copyDataForGeometry(targetShelfLocations: ShelfLocation[], dataShelfLocations: ShelfLocation[]): PlanogramEventBase {
    targetShelfLocations.forEach(sl => {
      const dsl = dataShelfLocations.find(x => x.id === sl.id);
      if (!dsl) {
        throw new Error('Shelf locations not found');
      }
      sl.geometry.currentHeight = dsl.geometry.currentHeight;
      sl.geometry.currentWidth = dsl.geometry.currentWidth;
      sl.geometry.widthLeftChanged = dsl.geometry.widthLeftChanged;
      sl.geometry.widthRightChanged = dsl.geometry.widthRightChanged;
      sl.geometry.heightBottomChanged = dsl.geometry.heightBottomChanged;
      sl.geometry.heightTopChanged = dsl.geometry.heightTopChanged;

      for (let i = 0; i < sl.geometry.points.length; i++) {
        const targetPoint = sl.geometry.points[i];
        if (dsl.geometry.points.length <= i) {
          break;
        }
        const dataPoint = dsl.geometry.points[i];
        targetPoint.x = dataPoint.x;
        targetPoint.y = dataPoint.y;
        targetPoint.xOffset = dataPoint.xOffset;
        targetPoint.yOffset = dataPoint.yOffset;
      }
    });
    const event = new ShelfLocationsMovedEvent(this.id, targetShelfLocations, this.imageViewPort);
    this.postMutationWork(event);
    return event;
  }

  calculatePlanogramChanges(): void {
    this.planogramChanges = new PlanogramChanges();
    this.planogramChanges.computeChanges(
      this.productRepository,
      this.initialShelfLocations,
      this.shelfLocations,
      this.initialShelfWidthCm,
      this.shelfWidthCm,
      this.initialShelfImageUrlWidth,
      this.shelfImageUrlWidth,
      this.brandSpaces,
      this.initialBrandSpaces
    );
  }

  calculateIsValid(): boolean {
    this.isValid = true;
    this.invalidReasons = '';
    const pixelTolerance = -5;
    this.verifyShelfLocations(pixelTolerance);
    const isValidShelfLocations = !this.shelfLocations.find(sl => !sl.validStateDescriptor.isValid());
    const isValidChangeReasons = this.verifyChangeReasons();
    if (this.shelfLocations.some(sl => sl.validStateDescriptor.isOutsideShelf)) {
      this.invalidReasons = this.invalidReasons + '\n\nProducts are outside shelf.';
    }
    if (this.shelfLocations.some(sl => sl.validStateDescriptor.isOverlapping)) {
      this.invalidReasons = this.invalidReasons + '\n\nProducts are overlapping.';
    }
    if (this.shelfLocations.some(sl => sl.validStateDescriptor.canIncreaseBrandRuleViolation)) {
      this.invalidReasons = this.invalidReasons + '\n\nTotal Brand Space increased to more than permitted limit.';
    }
    if (this.shelfLocations.some(sl => sl.validStateDescriptor.canDecreaseBrandRuleViolation)) {
      this.invalidReasons = this.invalidReasons + '\n\nTotal Brand Space decreased to less than permitted limit.';
    }

    this.isValid = isValidShelfLocations && isValidChangeReasons;
    return this.isValid;
  }
  private verifyShelfLocations(pixelTolerance: number): void {
    this.shelfLocations.forEach(sl => {
      sl.validStateDescriptor.reset();
      const invalidPoints = sl.geometry.points.filter(point => {
        const currentPoint = point.getCurrentPoint();
        const isXInvalid = currentPoint.x < pixelTolerance || currentPoint.x + pixelTolerance > this.imageViewPort.getWidth();
        const isYInvalid = currentPoint.y < pixelTolerance || currentPoint.y + pixelTolerance > this.imageViewPort.getHeigth();
        return isXInvalid || isYInvalid;
      });
      if (invalidPoints.length > 0) {
        sl.validStateDescriptor.isOutsideShelf = true;
      }
      for (let slToTestIndex = 0; slToTestIndex < this.shelfLocations.length; slToTestIndex++) {
        const slToTest = this.shelfLocations[slToTestIndex];
        if (slToTest === sl) {
          continue;
        }
        const l1 = slToTest.geometry.topLeftPoint.getCurrentPoint();
        const r1 = slToTest.geometry.bottomRightPoint.getCurrentPoint();
        const l2 = sl.geometry.topLeftPoint.getCurrentPoint();
        const r2 = sl.geometry.bottomRightPoint.getCurrentPoint();
        // If one rectangle is on left side of other
        if (l1.x > r2.x + pixelTolerance || l2.x > r1.x + pixelTolerance) {
          continue;
        }
        // If one rectangle is above other
        if (l1.y > r2.y + pixelTolerance || l2.y > r1.y + pixelTolerance) {
          continue;
        }
        sl.validStateDescriptor.isOverlapping = true;
      }
      const product = this.productRepository.find(p => p.gtin === sl.gtin);
      if (!product) {
        return;
      }
      const currentBrandSpace = this.brandSpaces.find(b => b.brandId === product.brandId);
      if (currentBrandSpace && !currentBrandSpace.isValid()) {
        sl.validStateDescriptor.canDecreaseBrandRuleViolation = currentBrandSpace.canDecreaseViolation;
        sl.validStateDescriptor.canIncreaseBrandRuleViolation = currentBrandSpace.canIncreaseViolation;
      }
    });
  }

  private postMutationWork(event?: PlanogramEventBase): void {
    if (this.isInTransaction) {
      if (event) {
        this.transactionEvents.push(event);
      }
      return;
    }
    // TODO: Can we avoid some of this to be run on every render and move them to event ON_PLANOGRAM_CHANGED_EVENT
    const perfTrack = performance.now();
    this.calculateShelfLocations();
    this.calculateBrandSize();
    this.calculatePlanogramChanges();
    this.calculateIsValid();
    this.planogramChangedEvent.trigger(event);
    const perfResult = performance.now() - perfTrack;
    if (perfResult > 20 && telemetryService.getInstance()) {
      telemetryService.getInstance().trackMetric(
        {
          name: 'Planogram Post Mutation Work (ms)',
          average: perfResult,
          sampleCount: 1,
        },
        {
          'Planogram Id': this.id,
        }
      );
    }
  }

  private calculateShelfLocations(): void {
    this.shelfLocations.forEach(sl => {
      sl.geometry.calculateArea(this.shelfWidthCm / this.shelfImageUrlWidth);
    });
  }

  private verifyChangeReasons(): boolean {
    // if user must have change reasons enforced, invalidate planogram if reasons arent entered
    if (!this.actionRules.isDisabled && this.actionRules.mustStateChangeReasons) {
      const changeReasons = this.changeReasons;
      const changes = this.planogramChanges;

      let reasonExists = true;
      for (let i = 0; i < changes.newProducts.length && reasonExists; i++) {
        const newProduct = changes.newProducts[i];
        const changeReason = changeReasons.newProductReasons.find(r => r.gtin === newProduct.gtin);
        reasonExists = changeReason !== undefined && changeReason.reason !== '';
        if (!reasonExists) {
          this.invalidReasons = this.invalidReasons + `\n\nChange reason missing for new product ${newProduct.tradeItemDescription}.`;
        }
      }
      for (let i = 0; i < changes.productsDelisted.length && reasonExists; i++) {
        const delistedProduct = changes.productsDelisted[i];
        const changeReason = changeReasons.productDelistedReasons.find(r => r.gtin === delistedProduct.gtin);
        reasonExists = changeReason !== undefined && changeReason.reason !== '';
        if (!reasonExists) {
          this.invalidReasons = this.invalidReasons + `\n\nChange reason missing for delisted product ${delistedProduct.tradeItemDescription}.`;
        }
      }
      for (let i = 0; i < changes.brandsResized.length && reasonExists; i++) {
        const brandResized = changes.brandsResized[i];
        const changeReason = changeReasons.brandResizeReasons.find(r => r.brandId === brandResized.brandId);
        reasonExists = changeReason !== undefined && changeReason.reason !== '';
        if (!reasonExists) {
          this.invalidReasons = this.invalidReasons + `\n\nChange reason missing for resized brand ${brandResized.brandName}.`;
        }
      }
      for (let i = 0; i < changes.productsResized.length && reasonExists; i++) {
        const productResized = changes.productsResized[i];
        const changeReason = changeReasons.productResizeReasons.find(r => r.gtin === productResized.gtin);
        reasonExists = changeReason !== undefined && changeReason.reason !== '';
        if (!reasonExists) {
          this.invalidReasons = this.invalidReasons + `\n\nChange reason missing for resized product ${productResized.tradeItemDescription}.`;
        }
      }
      return reasonExists;
    } else {
      return true;
    }
  }

  private calculateBrandSize(): void {
    const products = this.productRepository;
    this.brandSpaces = [];
    let totalArea = 0;
    this.shelfLocations.forEach(sl => {
      const product = products.find(p => p.gtin === sl.gtin);
      const area = sl.geometry.areaSquaredMeters;
      totalArea += area;
      if (!product) {
        return;
      }
      let brand = this.brandSpaces.find(bs => bs.brandId === product.brandId);
      if (!brand) {
        brand = new BrandSpace();
        brand.brandId = product.brandId;
        this.brandSpaces.push(brand);
      }
      brand.currentSpace += area;
    });

    this.brandSpaces.forEach(bs => {
      bs.resetViolation();
      const initialBrandSpace = this.initialBrandSpaces.find(b => b.brandId === bs.brandId);
      const currentSize = parseFloat(bs.currentSpace.toFixed(2));
      let initialSize = initialBrandSpace ? initialBrandSpace.currentSpace : 0;
      initialSize = parseFloat(initialSize.toFixed(2));
      const brandRule = this.actionRules.brandRules.find(b => b.brandId === bs.brandId);
      if (brandRule) {
        if (!brandRule.actionsAllowed[BrandPermissionType.canIncreaseSize]) {
          if (currentSize > initialSize) {
            bs.canIncreaseViolation = true;
          }
        }

        if (!brandRule.actionsAllowed[BrandPermissionType.canDecreaseSize]) {
          if (currentSize < initialSize) {
            bs.canDecreaseViolation = true;
          }
        }
      }
    });
    this.totalArea = totalArea;
  }
}
