import * as ReduxSaga from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import ErrorDetails from '../../infrastructure/exception/ErrorDetails';
import apiSaga from '../../utils/apiSaga';
import { loadTimelinesFailureAction, loadTimelinesSuccessAction, TIMELINES_LOAD_REQUEST_IN_CURRENT_YEAR, TIMELINES_LOAD_REQUEST_IN_DATE_RANGE } from './actions';
import { getCalendarEndDate, getCalendarStartDate } from './selectors';
import { getTimelinesApiRequest } from './services';

export function* fetchTimelinesDataInDateRange(action: any): ReduxSaga.SagaIterator {
  try {
    const response = yield call(apiSaga, getTimelinesApiRequest, action.startDate, action.endDate, action.viewAs);
    yield put(loadTimelinesSuccessAction(response.data));
  } catch (e) {
    const error = e.response.data as ErrorDetails;
    yield put(loadTimelinesFailureAction(error));
  }
}

export function* fetchTimelinesDataInCurrentYear(action: any): ReduxSaga.SagaIterator {
  try {
    const startDate = yield select(getCalendarStartDate);
    const endDate = yield select(getCalendarEndDate);
    const response = yield call(apiSaga, getTimelinesApiRequest, startDate, endDate, action.viewAs);
    yield put(loadTimelinesSuccessAction(response.data));
  } catch (e) {
    const error = e.response.data as ErrorDetails;
    yield put(loadTimelinesFailureAction(error));
  }
}

export default function* watchTimelinesSaga(): ReduxSaga.SagaIterator {
  yield takeLatest(TIMELINES_LOAD_REQUEST_IN_DATE_RANGE, fetchTimelinesDataInDateRange);
  yield takeLatest(TIMELINES_LOAD_REQUEST_IN_CURRENT_YEAR, fetchTimelinesDataInCurrentYear);
}
