import { Typography } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import * as React from 'react';
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import trackComponent from 'infrastructure/tracking/withTracking';
import { SalesReportModel } from '../../types/SalesDataModel';

interface SalesDataModalProps {
  salesData: SalesReportModel;
  onClose(): void;
}

function renderSalesDataRows(salesData: SalesReportModel): JSX.Element | JSX.Element[] {
  if (salesData.items.length === 0) {
    return (
      <Alert className="top-buffer" color="info">
        <Typography variant="caption" color="textSecondary">
          <InfoOutlined color="primary" fontSize="small" />
          &nbsp; Data does not contain products in this shelf.
        </Typography>
      </Alert>
    );
  }
  return salesData.items.map(row => {
    return (
      <tr key={row.gtin}>
        <td>{row.gtin}</td>
        <td>{row.value}</td>
      </tr>
    );
  });
}

const SalesDataModal = (props: SalesDataModalProps): JSX.Element => (
  <Modal keyboard={true} isOpen={true} backdrop={true} toggle={props.onClose}>
    <ModalHeader>Sales Data</ModalHeader>
    <ModalBody>
      <Table responsive>
        <thead>
          <tr>
            <th>GTIN</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>{renderSalesDataRows(props.salesData)}</tbody>
      </Table>
    </ModalBody>
    <ModalFooter>
      <Button onClick={props.onClose} color="secondary">
        Close
      </Button>
    </ModalFooter>
  </Modal>
);

export default trackComponent(SalesDataModal, 'PlanogramPage_SalesDataModal');
