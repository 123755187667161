import { getCenterPoint, pixelToCm } from '../helpers/geometry';
import { BrandSpace } from '../domain/PlanogramModel';
import ProductModel from './ProductModel';
import ShelfLocation from '../domain/ShelfLocation';

export class BrandResized {
  brandId = 0;
  initialSpace = 0;
  currentSpace = 0;
  brandName = '';
  brandOwner = '';

  constructor(brandId: number, brandName: string, brandOwner: string, initialSpace: number, currentSpace: number) {
    this.brandId = brandId;
    this.brandName = brandName;
    this.brandOwner = brandOwner;
    this.initialSpace = initialSpace;
    this.currentSpace = currentSpace;
  }
}
export class ProductMoved {
  id = '';
  gtin = 0;
  tradeItemDescription = '';
  movedHorizontal = 0;
  movedVertical = 0;
  constructor(id: string, gtin: number, tradeItemDescription: string, movedHorizontal: number, movedVertical: number) {
    this.id = id;
    this.gtin = gtin;
    this.tradeItemDescription = tradeItemDescription;
    this.movedHorizontal = movedHorizontal;
    this.movedVertical = movedVertical;
  }
}
export class ProductResized {
  gtin = 0;
  tradeItemDescription = '';
  initialSpace = 0;
  currentSpace = 0;
  constructor(gtin: number, tradeItemDescription: string, initialSpace: number, currentSpace: number) {
    this.gtin = gtin;
    this.tradeItemDescription = tradeItemDescription;
    this.initialSpace = initialSpace;
    this.currentSpace = currentSpace;
  }
}
export class NewProduct {
  gtin = 0;
  tradeItemDescription = '';
  constructor(gtin: number, tradeItemDescription: string) {
    this.gtin = gtin;
    this.tradeItemDescription = tradeItemDescription;
  }
}
export class ProductDelisted {
  gtin = 0;
  tradeItemDescription = '';
  constructor(gtin: number, tradeItemDescription: string) {
    this.gtin = gtin;
    this.tradeItemDescription = tradeItemDescription;
  }
}

/* All planogram changes are calculated and stored in this object
 The changes are calculated using 'InitialPlanogram', 'CurrentPlanogram', 'Products' and shelf dimensions.
 Changes calculated are:
    1 - Brands Resized
    2 - Products Delisted
    3 - Products Moved
    4 - ProductsResized
*/
export class PlanogramChanges {
  brandsResized: BrandResized[] = [];
  productsDelisted: ProductDelisted[] = [];
  newProducts: NewProduct[] = [];
  productsMoved: ProductMoved[] = [];
  productsResized: ProductResized[] = [];

  computeChanges(
    products: ProductModel[],
    initialShelfLocations: ShelfLocation[],
    currentShelfLocations: ShelfLocation[],
    initialShelfWidthCm: number,
    shelfWidthCm: number,
    initialShelfImageUrlWidth: number,
    shelfImageUrlWidth: number,
    currentBrandSpaces: BrandSpace[],
    initialBrandSpaces: BrandSpace[]
  ): void {
    const initialGtins = initialShelfLocations.map(s => s.gtin);
    const currentGtins = currentShelfLocations.map(s => s.gtin);

    products.forEach(product => {
      this.computeDelistedProducts(currentGtins, product);
      this.computeNewProducts(initialGtins, product);
      this.computeResizedProducts(initialShelfLocations, product, currentShelfLocations, initialGtins, currentGtins);
    });

    // Calculating Moved products
    initialShelfLocations.forEach(initialSl => {
      const currentSl = currentShelfLocations.find(sl => sl.id === initialSl.id || (initialSl.code.length > 0 && sl.code === initialSl.code));
      if (!currentSl) {
        return;
      }
      this.computeMovement(currentSl, initialSl, shelfWidthCm, shelfImageUrlWidth, initialShelfWidthCm, initialShelfImageUrlWidth, products);
    });

    this.computeBrandsResized(initialBrandSpaces, currentBrandSpaces, products);
  }

  private computeBrandsResized(initialBrandSpaces: BrandSpace[], currentBrandSpaces: BrandSpace[], products: ProductModel[]): void {
    currentBrandSpaces.forEach(brandSpace => {
      const brand = products.find(p => p.brandId === brandSpace.brandId);
      if (!brand) {
        return;
      }
      const initialBrandSize = initialBrandSpaces.find(b => b.brandId === brandSpace.brandId);
      brandSpace.initialSize = initialBrandSize ? initialBrandSize.currentSpace : 0;
      if (brandSpace.initialSize.toFixed(2) !== brandSpace.currentSpace.toFixed(2)) {
        const brandResized = new BrandResized(brand.brandId, brand.brandName, brand.brandOwner, brandSpace.initialSize, brandSpace.currentSpace);
        this.brandsResized.push(brandResized);
      }
    });
  }

  private computeMovement(
    currentSl: ShelfLocation,
    initialSl: ShelfLocation,
    shelfWidthCm: number,
    shelfImageUrlWidth: number,
    initialShelfWidthCm: number,
    initialShelfImageUrlWidth: number,
    products: ProductModel[]
  ): void {
    const currentCenterPoint = getCenterPoint(currentSl.geometry.points);
    const initialCenterPoint = getCenterPoint(initialSl.geometry.points);
    const currentCmFromLeft = pixelToCm(shelfWidthCm, shelfImageUrlWidth, currentCenterPoint.x);
    const currentCmFromBottom = pixelToCm(shelfWidthCm, shelfImageUrlWidth, currentCenterPoint.y);
    const initialCmFromLeft = pixelToCm(initialShelfWidthCm, initialShelfImageUrlWidth, initialCenterPoint.x);
    const initialCmFromBottom = pixelToCm(initialShelfWidthCm, initialShelfImageUrlWidth, initialCenterPoint.y);
    const movedHorizontal = initialCmFromLeft - currentCmFromLeft;
    const movedVertical = initialCmFromBottom - currentCmFromBottom;
    const product = products.find(p => p.gtin === initialSl.gtin);
    if (movedHorizontal !== 0 || movedVertical !== 0) {
      const productMoved = new ProductMoved(initialSl.id, initialSl.gtin, product ? product.tradeItemDescription : 'N/A', movedHorizontal, movedVertical);
      this.productsMoved.push(productMoved);
    }
  }

  private computeResizedProducts(initialShelfLocations: ShelfLocation[], product: ProductModel, currentShelfLocations: ShelfLocation[], initialGtins: number[], currentGtins: number[]): void {
    if (!initialGtins.includes(product.gtin) || !currentGtins.includes(product.gtin)) {
      // if new or deleted
      return;
    }
    const initialSpace = initialShelfLocations
      .filter(sl => sl.gtin === product.gtin)
      .reduce((sum: number, sl: ShelfLocation) => {
        return sum + sl.geometry.areaSquaredMeters;
      }, 0);
    const currentSpace = currentShelfLocations
      .filter(sl => sl.gtin === product.gtin)
      .reduce((sum: number, sl: ShelfLocation) => {
        return sum + sl.geometry.areaSquaredMeters;
      }, 0);
    if (initialSpace.toFixed(2) !== currentSpace.toFixed(2)) {
      const resizedProduct = new ProductResized(product.gtin, product.tradeItemDescription, initialSpace, currentSpace);
      this.productsResized.push(resizedProduct);
    }
  }

  private computeNewProducts(initialGtins: number[], product: ProductModel): void {
    if (!initialGtins.includes(product.gtin)) {
      const newProduct = new NewProduct(product.gtin, product.tradeItemDescription);
      this.newProducts.push(newProduct);
    }
  }

  private computeDelistedProducts(currentGtins: number[], product: ProductModel): void {
    if (!currentGtins.includes(product.gtin)) {
      const deletedProduct = new ProductDelisted(product.gtin, product.tradeItemDescription);
      this.productsDelisted.push(deletedProduct);
    }
  }
}
