import React from 'react';
import { Card, CardContent, CardHeader, Divider } from '@material-ui/core';

export interface CardPlanogramDetailsProps {
  children: React.ReactNode;
}

const CardPlanogramDetails: React.FC<CardPlanogramDetailsProps> = ({ children }: CardPlanogramDetailsProps): JSX.Element => {
  return (
    <Card raised={true} className="top-buffer-sm">
      <CardHeader title="PLANOGRAM DETAILS" titleTypographyProps={{ color: 'primary' }} />
      <Divider light variant="middle" />
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default CardPlanogramDetails;
