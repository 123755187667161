import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InspirationImagesSwitch from './InspirationImagesSwitch';

import { enableInspirationImagesRequest } from '../../../actions';
import { getIsInspirationImagesEnabled, getIsInspirationImagesEnabledBuilderPlangrams, isEnablingInspirationImage } from '../../../selectors';
import { State } from '../../../../../../state';
import { ReduxPlanogramPropertyNames } from '../types';

interface Props {
  planogramId: string;
  reduxPlanogramPropsName: ReduxPlanogramPropertyNames;
}
const EnableDisable: React.FC<Props> = ({ planogramId, reduxPlanogramPropsName }: Props): JSX.Element => {
  const isEnabling = useSelector(isEnablingInspirationImage);
  const selector = reduxPlanogramPropsName === ReduxPlanogramPropertyNames.PLANOGRAMS ? getIsInspirationImagesEnabled : getIsInspirationImagesEnabledBuilderPlangrams;
  const checked = useSelector((state: State) => selector(state, planogramId));

  const dispatch = useDispatch();
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(
      enableInspirationImagesRequest({
        planogramId,
        isInspirationImagesEnabled: e.target.checked,
      })
    );
  };
  return <InspirationImagesSwitch onChange={handleOnChange} disabled={isEnabling} checked={checked} />;
};

export default EnableDisable;
