import { combineReducers } from 'redux';
import inspirationImages from './inspirationImages/reducer';
import { InspirationImagesState } from './inspirationImages/types';

export interface EntitiesState {
  inspirationImages: InspirationImagesState;
}

export default combineReducers({
  inspirationImages,
});
