import { Card, CardContent, InputAdornment, TextField, Tooltip } from '@material-ui/core';
import { FormatQuote, InsertComment } from '@material-ui/icons';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Container, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { State } from '../../../../state';
import * as actions from '../../actions';
import PlanogramState from '../../state';
import { RecordChangeReasonDto } from '../../types/PlanogramChangeReasons';
import PlanogramModel from '../../domain/PlanogramModel';
import ProductModel from '../../types/ProductModel';
import { FlowDataReportType } from '../../types/ReportingTypes';
import { SalesDataModel, SalesReportModel } from '../../types/SalesDataModel';
import PreviewChanges from './PreviewChanges/PreviewChanges';
import PublicSettings from './PublicSettings';
import { isLoaded } from 'infrastructure/utils/RemoteObjectStatus';

class PublicPlanogramAside extends React.Component<PlanogramState & DispatchProps, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      activeTab: 1,
    };
  }

  toggle(tab: number): void {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render(): JSX.Element {
    if (!isLoaded(this.props.loadPlanogramStatus) || this.props.planogram === undefined) {
      return <></>;
    }
    const planogram = this.props.planogram as PlanogramModel;
    return (
      <React.Fragment>
        <Nav tabs>
          <NavItem>
            <Tooltip title="Planogram Info and Actions">
              <NavLink
                className={classNames({ active: this.state.activeTab === 1 })}
                onClick={(): void => {
                  this.toggle(1);
                }}
              >
                <i className="fa fa-pencil-square-o" />
              </NavLink>
            </Tooltip>
          </NavItem>
          <NavItem>
            <Tooltip title="Preview Changes">
              <NavLink
                className={classNames({ active: this.state.activeTab === 2 })}
                onClick={(): void => {
                  this.toggle(2);
                }}
              >
                <i className="fa fa-eye" />
              </NavLink>
            </Tooltip>
          </NavItem>
          <NavItem>
            <Tooltip title="Settings and Data Controls">
              <NavLink
                className={classNames({ active: this.state.activeTab === 3 })}
                onClick={(): void => {
                  this.toggle(3);
                }}
              >
                <i className="fa fa-sliders" />
              </NavLink>
            </Tooltip>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId={1}>
            <Container style={{ padding: '8px' }}>
              <Card>
                <CardContent style={{ paddingBottom: '8px' }}>
                  <TextField
                    id="name"
                    label="Planogram name"
                    placeholder="Planogram name"
                    disabled={true}
                    value={this.props.planogram?.name}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FormatQuote color="primary" />
                        </InputAdornment>
                      ),
                      readOnly: false,
                    }}
                  />
                </CardContent>
              </Card>
              <Card className="top-buffer-sm">
                <CardContent style={{ paddingBottom: '8px' }}>
                  <TextField
                    id="comments"
                    label="Comments"
                    placeholder="You can type in comments here."
                    disabled={true}
                    value={this.props.planogram?.comments}
                    fullWidth
                    multiline={true}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <InsertComment color="primary" />
                        </InputAdornment>
                      ),
                      readOnly: false,
                    }}
                  />
                </CardContent>
              </Card>
            </Container>
          </TabPane>
          <TabPane tabId={2} className="p-3">
            <PreviewChanges
              currentPlanogram={planogram}
              initialPlanogram={this.props.initialPlanogram as PlanogramModel}
              products={this.props.products as ProductModel[]}
              undoDelistedProduct={(): void => {
                return;
              }}
              onHighlightBrand={this.props.onHighlightBrand}
              onHighlightProduct={this.props.onHighlightProduct}
              onRecordChangeReason={this.props.onRecordChangeReason}
            />
          </TabPane>
          <TabPane tabId={3} className="p-3">
            <PublicSettings
              enableShowProductInfo={this.props.enableShowProductInfo}
              toggleEnableShowProductInfo={this.props.toggleEnableShowProductInfo}
              onShowSharing={(): void => this.setState({ showSharingModal: true })}
            />
          </TabPane>
        </TabContent>
      </React.Fragment>
    );
  }
}

interface DispatchProps {
  changePlanogramName(planogramId: string, newName: string): void;

  deletePlanogramRequest(planogramId: string): void;

  toggleEnableShowProductInfo(enabled?: boolean): void;

  toggleEnableSalesDataReport(enabled?: boolean): void;

  toggleEnableFlowDataReport(enabled?: boolean): void;

  saveSalesDataRequest(planogramId: string, reportName: string, salesData: SalesDataModel[]): void;

  setFlowReportTypeRequest(reportType?: FlowDataReportType): void;

  selectSalesReportRequest(salesReport?: SalesReportModel): void;

  deleteSalesDataRequest(planogramId: string, reportId: string): void;

  updateCommentsRequest(planogramId: string, comments: string): void;

  onHighlightBrand(priority: boolean, brandId?: number): void;

  onHighlightProduct(priority: boolean, gtin?: number[]): void;

  onRecordChangeReason(reason: RecordChangeReasonDto): void;
}

function mapStateToProps(state: State, ownProps: any): PlanogramState {
  return {
    ...state.planogram,
    id: ownProps.match.params.id,
  };
}

export default connect<PlanogramState, DispatchProps, RouteComponentProps<any>>(mapStateToProps, actions)(PublicPlanogramAside);
