import createReducer from 'utils/createReducer';
import AdminUsersState from './state';
import * as constants from './constants';

import { setError, setIdle, setLoading, setSuccess } from 'infrastructure/utils/RemoteObjectStatus';
import UserForAdminViewModel from './types/UserForAdminViewModel';
import UserPlanogramForAdminViewModel from './types/UserPlanogramForAdminViewModel';
import { OnErrorRequestAction } from 'modules/shared/actions';

const initialState = {
  loadAdminUsersStatus: setIdle<UserForAdminViewModel[]>(),
  loadAdminUserPlanagramsStatus: setIdle<UserPlanogramForAdminViewModel[]>(),
} as AdminUsersState;

function loadUsersForAdminRequestHandler(state: AdminUsersState): AdminUsersState {
  return {
    ...state,
    loadAdminUsersStatus: setLoading('Loading users...'),
  };
}

function loadUsersForAdminErrorHandler(state: AdminUsersState, action: OnErrorRequestAction): AdminUsersState {
  return {
    ...state,
    loadAdminUsersStatus: setError(action.errorDetails),
  };
}

function loadUsersForAdminSuccessHandler(state: AdminUsersState, action: any): AdminUsersState {
  return {
    ...state,
    loadAdminUsersStatus: setSuccess(action.users),
  };
}

function loadUserPlanogramsForAdminRequestHandler(state: AdminUsersState): AdminUsersState {
  return {
    ...state,
    loadAdminUserPlanagramsStatus: setLoading('Loading planograms...'),
  };
}

function loadUserPlanogramsForAdminErrorHandler(state: AdminUsersState, action: OnErrorRequestAction): AdminUsersState {
  return {
    ...state,
    loadAdminUserPlanagramsStatus: setError(action.errorDetails),
  };
}

function loadUserPlanogramsForAdminSuccessHandler(state: AdminUsersState, action: any): AdminUsersState {
  return {
    ...state,
    loadAdminUserPlanagramsStatus: setSuccess(action.planograms),
  };
}

const handlers = {};

handlers[constants.ADMIN_USERS_LOAD_ERROR] = loadUsersForAdminErrorHandler;
handlers[constants.ADMIN_USERS_LOAD_REQUEST] = loadUsersForAdminRequestHandler;
handlers[constants.ADMIN_USERS_LOAD_SUCCESS] = loadUsersForAdminSuccessHandler;

handlers[constants.ADMIN_USER_PLANOGRAMS_LOAD_ERROR] = loadUserPlanogramsForAdminErrorHandler;
handlers[constants.ADMIN_USER_PLANOGRAMS_LOAD_REQUEST] = loadUserPlanogramsForAdminRequestHandler;
handlers[constants.ADMIN_USER_PLANOGRAMS_LOAD_SUCCESS] = loadUserPlanogramsForAdminSuccessHandler;

const adminUsersReducer = createReducer(initialState, handlers);

export default adminUsersReducer;
