import { isLoaded, isLoading } from 'infrastructure/utils/RemoteObjectStatus';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { loadStandardBrandComparisonReportRequestAction } from '../../actions';
import * as selectors from '../../selectors';
import { BrandComparisonReportResult } from '../../types';
interface LoadStatusParams {
  isLoaded: boolean;
  isLoading: boolean;
  loadError: string;
  isDataEmpty: boolean;
  data: BrandComparisonReportResult | undefined;
}
export interface StandardBrandComparisonReportLoaderProps {
  children: (params: LoadStatusParams) => React.ReactNode;
}

const StandardBrandComparisonReportLoader: React.FC<StandardBrandComparisonReportLoaderProps> = ({ children }: StandardBrandComparisonReportLoaderProps) => {
  const { shelfPhaseId1, shelfPhaseId2, brandId1, brandId2 } = useParams();
  const dispatch = useDispatch();
  const loadState = useSelector(selectors.getBrandComparisonReportLoadState);
  const isDataEmpty = useSelector(selectors.isBrandComparisonReportEmpty);
  const data = useSelector(selectors.getBrandComparisonReportData);
  useEffect(() => {
    if (isDataEmpty && shelfPhaseId1 && shelfPhaseId2 && brandId1 && brandId2) {
      dispatch(loadStandardBrandComparisonReportRequestAction(shelfPhaseId1, shelfPhaseId2, parseInt(brandId1), parseInt(brandId2)));
    }
  }, [dispatch, isDataEmpty, shelfPhaseId1, shelfPhaseId2, brandId1, brandId2]);
  return (
    <>
      {children({
        isLoaded: isLoaded(loadState),
        isLoading: isLoading(loadState),
        loadError: loadState?.errorDetails?.errorMessage ?? '',
        isDataEmpty,
        data,
      })}
    </>
  );
};

export { StandardBrandComparisonReportLoader };
