import { ActionRules } from './ActionRules';
import ShelfLocation from '../../planogram/domain/ShelfLocation';
import { Type } from 'class-transformer';
import TestInfoDto from '../../planogram/types/TestInfoDto';

export default class BuilderPlanogramView {
  id = '';
  name = '';
  email = '';
  ownerId = 0;
  builderId = 0;
  createdOn: Date = new Date();

  @Type(() => Date)
  lastOpenedByBuilder?: Date;

  @Type(() => Date)
  lastEditedByBuilder?: Date;

  imageUrl = '';
  shelfLocations: ShelfLocation[] = [];
  dataInsights: string[] = [];
  actionRules: ActionRules = {} as ActionRules;

  goal = '';
  testInfoList: TestInfoDto[] = [];
}
