import { closeRealogramConversationPopperAction } from 'modules/realogram/actions';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as selectors from '../../selectors';
import { ConversationLoader } from './ConversationLoader';
import ConversationPopper from './ConversationPopper';
import { isLoading as isLoadingFc } from 'infrastructure/utils/RemoteObjectStatus';
export interface ConversationPopperIndexProps {
  shelfReferenceId: string;
  isOpen: boolean;
  conversationAnchorEl?: Element;
  handleOnSubmitMessage(message: string): void;
  handleOnSubmitEdit(messageId: string, userId: number, editedMessage: string): void;
  handleOnSubmitDelete(messageId: string): void;
}

// Loads and renders the ConversationPopper for a realogram using its shelfReferenceId
export const ConversationPopperIndex: React.FC<ConversationPopperIndexProps> = ({
  shelfReferenceId,
  isOpen,
  conversationAnchorEl,
  handleOnSubmitMessage,
  handleOnSubmitEdit,
  handleOnSubmitDelete,
}: ConversationPopperIndexProps) => {
  const dispatch = useDispatch();
  const postMessageLoadState = useSelector(selectors.postMessageLoadState);
  const updateMessageLoadState = useSelector(selectors.updateMessageLoadState);
  const deleteMessageLoadState = useSelector(selectors.deleteMessageLoadState);
  const handleOnClosePopper = (): void => {
    dispatch(closeRealogramConversationPopperAction());
  };

  return (
    <ConversationLoader shelfReferenceId={shelfReferenceId}>
      {({ isLoading, isLoaded, data, errorMessage, isError }): React.ReactNode => {
        return (
          <>
            <ConversationPopper
              isPopperOpen={isOpen}
              isConversationLoading={isLoading}
              isConversationLoaded={isLoaded}
              isConversationLoadError={isError}
              isSubmittingMessage={isLoadingFc(postMessageLoadState)}
              isEditingMessage={isLoadingFc(updateMessageLoadState)}
              isDeletingMessage={isLoadingFc(deleteMessageLoadState)}
              conversationLoadErrorMessage={errorMessage}
              conversationAnchorEl={conversationAnchorEl}
              conversationMessages={data?.conversationMessages || []}
              handleOnSubmitMessage={handleOnSubmitMessage}
              handleOnSubmitEdit={(messageId: string, userId: number, editedMessage: string): void => handleOnSubmitEdit(messageId, userId, editedMessage)}
              handleOnSubmitDelete={(messageId: string): void => handleOnSubmitDelete(messageId)}
              handleOnClosePopper={handleOnClosePopper}
            />
          </>
        );
      }}
    </ConversationLoader>
  );
};
