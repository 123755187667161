import React from 'react';
import useHighLightedGtin from '../useHighLightedGtin';
import { getCanHaveAnyShelfLocationGtinArr } from '../../../../../../selectors';
import SelectBtn from '../SelectBtn';

const ShelfLocationBtn: React.FC = (): JSX.Element => {
  const props = useHighLightedGtin(getCanHaveAnyShelfLocationGtinArr, 'ShelfLocationBtn');

  return <SelectBtn {...props} />;
};

export default ShelfLocationBtn;
