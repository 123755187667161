import * as actionTypes from './actionsTypes';
import { EulaAgreement } from './types';
import ErrorDetails from 'infrastructure/exception/ErrorDetails';

export function loadEula(): actionTypes.LoadEulaRequestAction {
  return {
    type: actionTypes.EULA_LOAD_REQUEST,
  };
}

export function loadingEulaSuccess(payload: EulaAgreement): actionTypes.LoadEulaSuccessAction {
  return {
    type: actionTypes.EULA_LOAD_SUCCESS,
    payload,
  };
}

export function loadingEulaError(error: ErrorDetails): actionTypes.LoadEulaErrorAction {
  return {
    type: actionTypes.EULA_LOAD_ERROR,
    error,
  };
}

export function acceptEula(version: number): actionTypes.AcceptEulaRequestAction {
  return {
    type: actionTypes.EULA_ACCEPT_REQUEST,
    version,
  };
}

export function acceptEulaSuccess(): actionTypes.AcceptEulaSuccessAction {
  return {
    type: actionTypes.EULA_ACCEPT_SUCCESS,
  };
}

export function acceptEulaError(error: ErrorDetails): actionTypes.AcceptEulaErrorAction {
  return {
    type: actionTypes.EULA_ACCEPT_ERROR,
    error,
  };
}
