import React from 'react';

import config from '../config';

interface EnvFeatureFlagProps {
  children: React.ReactNode;
  featureName: string;
}

const EnvFeatureFlag: React.FC<EnvFeatureFlagProps> = ({ children, featureName }: EnvFeatureFlagProps): JSX.Element | null => {
  return config.featureFlags[featureName] ? <>{children}</> : null;
};

export default EnvFeatureFlag;
