import React from 'react';
import { toMaxPrecision, toMaxPrecisionPercent } from 'utils/Helpers';
import flowLogo from '../../../../../assets/img/brand/flow.jpg';
import { ClientBenchmarkReportResult } from '../../types';
import '../styles/StandardReportStyle.css';
interface ClientBenchmarkReportPageProps {
  clientBenchmarkReport: ClientBenchmarkReportResult;
}
const ClientBenchmarkReportPage: React.FC<ClientBenchmarkReportPageProps> = ({ clientBenchmarkReport }: ClientBenchmarkReportPageProps) => {
  const overviewReport = clientBenchmarkReport.overviewReport;
  const brickMetricsReport = clientBenchmarkReport.brickMetrics;
  return (
    <div className="page">
      <div id="flowLogo">
        <img alt="flow-logo" className="img-responsive" src={flowLogo} />
      </div>
      <div style={{ width: '100%' }}>
        <h3 className="text-center">
          {overviewReport.clientName}
          <br />
        </h3>
        <h5 className="text-center">
          Phases: {overviewReport.shelfPhaseCount}
          <br />
        </h5>
        <h5 className="text-left">
          <b>Visitors:</b> {overviewReport.totalVisitors}
          <br />
          <b>Products: </b>
          {overviewReport.productsCount}
        </h5>
        <div className="row">
          <table style={{ marginTop: '10px' }} className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-center metricNameCell">Conversion rate(percent)</td>
                <td className="text-center metricNameCell">Turnover per visitor(amount)</td>
                <td className="text-center metricNameCell">Daily turnover(average amount)</td>
                <td className="text-center metricNameCell">Destination buyers(percent)</td>
                <td className="text-center metricNameCell">Return ratio(percent)</td>
                <td className="text-center metricNameCell">Lost shoppers (percent)</td>
                <td className="text-center metricNameCell">Lost daily turnover (average)</td>
                <td className="text-center metricNameCell">Out of shelf (daily hours)</td>
              </tr>
              <tr>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.conversionRate, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewReport.turnoverPerVisitor, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewReport.dailyTurnoverAverage, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.destinationBuyersPercent, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.returnRatioPercent, 2)}</td>
                <td className="text-center">{toMaxPrecisionPercent(overviewReport.lostShoppersPercent, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewReport.averageLostDailyTurnover, 2)}</td>
                <td className="text-center">{toMaxPrecision(overviewReport.outOfStockDailyHours, 2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row">
          <table style={{ marginTop: '10px' }} className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-left metricNameCell">Seconds to buy and leave (buyers)</td>
                <td className="text-left">{toMaxPrecision(overviewReport.secondsToBuyAndLeave, 2)}</td>
                <td className="text-left metricNameCell">Interactions per shopper (average)</td>
                <td className="text-left">{toMaxPrecision(overviewReport.interactionsPerShopper, 2)}</td>
              </tr>
              <tr>
                <td className="text-left metricNameCell">Seconds to first interaction (all shoppers)</td>
                <td className="text-left">{toMaxPrecision(overviewReport.secondsToFirstInteraction, 2)}</td>
                <td className="text-left metricNameCell">Average basket size (units)</td>
                <td className="text-left">{toMaxPrecision(overviewReport.averageBasketSizeUnits, 2)}</td>
              </tr>
              <tr>
                <td className="text-left metricNameCell">Lost shoppers, time in seconds</td>
                <td className="text-left">{toMaxPrecision(overviewReport.lostShopperSeconds, 2)}</td>
                <td className="text-left metricNameCell">Average basket size (turnover)</td>
                <td className="text-left">{toMaxPrecision(overviewReport.averageBasketSizeTurnover, 2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="row">
          <table style={{ marginTop: '10px' }} className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td className="text-center metricNameCell">Top 5 bricks</td>
                <td className="text-center metricNameCell">Share of space</td>
                <td className="text-center metricNameCell">Share of unit sales</td>
                <td className="text-center metricNameCell">Share of turnover</td>
                <td className="text-center metricNameCell">Share of first pickups</td>
              </tr>
              {brickMetricsReport.map(brick => {
                return (
                  <tr key={brick.brickName}>
                    <td className="text-center metricNameCell">{brick.brickName}</td>
                    <td className="text-center">{toMaxPrecisionPercent(brick.shareOfSpace, 2)}</td>
                    <td className="text-center">{toMaxPrecisionPercent(brick.shareOfUnitSales, 2)}</td>
                    <td className="text-center">{toMaxPrecisionPercent(brick.shareOfTurnover, 2)}</td>
                    <td className="text-center">{toMaxPrecisionPercent(brick.shareOfFirstPickups, 2)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <footer>
        <strong>Copyright &copy; {new Date().getFullYear()} Flow Insights</strong>. Strictly confidential. No distribution allowed. All rights reserved.
      </footer>
    </div>
  );
};

export { ClientBenchmarkReportPage };
