import * as ReduxSaga from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import apiSaga from '../../../utils/apiSaga';
import { fetchFailed, fetchRequest, fetchSuccess } from './actions';
import { fetchShelfConditionFromPlanogram, fetchShelfConditionFromRealogram } from './services';
import { FetchRequestAction, ShelfConditionsResult, ShelfConditionsTarget } from './types';

export function* fetch(action: ReturnType<FetchRequestAction>): ReduxSaga.SagaIterator {
  try {
    const apiRequest =
      action.payload.targetType === ShelfConditionsTarget.planogram
        ? fetchShelfConditionFromPlanogram
        : action.payload.targetType === ShelfConditionsTarget.realogram
        ? fetchShelfConditionFromRealogram
        : null;
    const { data }: { data: ShelfConditionsResult } = yield call(apiSaga, apiRequest, action.payload.targetId);
    yield put(fetchSuccess(data));
  } catch (e) {
    yield put(fetchFailed(e.response.data));
  }
}

function* watchShelfConditionImages(): ReduxSaga.SagaIterator {
  yield takeLatest(getType(fetchRequest), fetch);
}

export default watchShelfConditionImages;
