import { IconButton, Table, TableBody, TableHead, Tooltip, Typography } from '@material-ui/core';
import { CloudDownload, CloudUpload, Delete, Description, InfoOutlined } from '@material-ui/icons';
import trackComponent from 'infrastructure/tracking/withTracking';
import { isError, isLoaded, isLoading, RemoteObjectStatusInterface } from 'infrastructure/utils/RemoteObjectStatus';
import { AlertErrorTextIndicator } from 'modules/shared/components/LoadStatusIndicators/AlertErrorTextIndicator';
import { CircularLoadIndicator } from 'modules/shared/components/LoadStatusIndicators/CircularLoadIndicator';
import React, { Component } from 'react';
import Alert from 'reactstrap/lib/Alert';
import { StyledTableCell, StyledTableRow } from 'scss/MaterialStyles';
import { getDateInInputFormat } from 'utils/dateHelper';
import * as XLSX from 'xlsx';
import CompetitorShelf from '../../types/CompetitorShelf';
import CompetitorShelfDataset from '../../types/CompetitorShelfDataset';
import DeleteCompetitorShelfDatasetConfirmationModal from './DeleteCompetitorShelfDatasetConfirmationModal';
import PreviewCompetitorDatasetModal from './PreviewCompetitorDatasetModal';
import UploadCompetitorShelfDatasetModal from './UploadCompetitorShelfDatasetModal';

interface CompetitorShelvesDatasetsTabState {
  showUploadDatasetModal: boolean;
  selectedShelf: CompetitorShelf | undefined;
  selectedDataset: CompetitorShelfDataset | undefined;
  showDeleteDatasetConfirmationModal: boolean;
  showPreviewCompetitorDatasetModal: boolean;
}
class CompetitorShelvesDatasetsTab extends Component<CompetitorShelvesDatasetsTabProps, CompetitorShelvesDatasetsTabState> {
  constructor(props: CompetitorShelvesDatasetsTabProps, state: CompetitorShelvesDatasetsTabState) {
    super(props, state);

    this.state = {
      showUploadDatasetModal: false,
      showDeleteDatasetConfirmationModal: false,
      selectedShelf: undefined,
      selectedDataset: undefined,
      showPreviewCompetitorDatasetModal: false,
    };
  }

  componentDidUpdate(prevProps: CompetitorShelvesDatasetsTabProps): void {
    if (prevProps.uploadCompetitorShelfDatasetState.loadStatus !== this.props.uploadCompetitorShelfDatasetState.loadStatus && isLoaded(this.props.uploadCompetitorShelfDatasetState)) {
      this.setState({ showUploadDatasetModal: false });
    }
    if (prevProps.deleteCompetitorShelfDatasetState.loadStatus !== this.props.deleteCompetitorShelfDatasetState.loadStatus && isLoaded(this.props.deleteCompetitorShelfDatasetState)) {
      this.setState({ showDeleteDatasetConfirmationModal: false });
    }
  }

  downloadDataAsExcelFile(dataset: CompetitorShelfDataset): void {
    const wb = XLSX.utils.book_new();
    wb.SheetNames.push('Sheet 1');
    const opts = {
      header: dataset.items.length === 0 ? ['GTIN', 'Value'] : [],
    };

    const data = dataset.items.map(i => ({
      GTIN: i.gtin,
      Value: i.value,
    }));

    const ws = XLSX.utils.json_to_sheet(data, opts);
    wb.Sheets['Sheet 1'] = ws;
    XLSX.writeFile(wb, `${dataset.datasetName}.xlsx`);
  }
  render(): JSX.Element {
    return (
      <div className="buffer">
        {isError(this.props.competitorShelvesDatasetsStatus) ? (
          <AlertErrorTextIndicator errorText={this.props.competitorShelvesDatasetsStatus.errorDetails?.errorMessage ?? ''} />
        ) : isLoading(this.props.competitorShelvesDatasetsStatus) ? (
          <CircularLoadIndicator loadingText={`Loading Datasets for 'Competitor/Similar' Shelves...`} />
        ) : (
          this.renderCompetitorShelvesTable()
        )}
        {this.state.showUploadDatasetModal && this.state.selectedShelf ? (
          <UploadCompetitorShelfDatasetModal
            planogramId={this.props.planogramId}
            selectedShelf={this.state.selectedShelf}
            onClose={(): void => {
              this.setState({ showUploadDatasetModal: false });
            }}
            uploadCompetitorShelfDatasetState={this.props.uploadCompetitorShelfDatasetState}
            uploadDataset={(dataset: CompetitorShelfDataset): void => {
              this.props.uploadDataset(dataset);
            }}
          />
        ) : null}

        {this.state.showDeleteDatasetConfirmationModal && this.state.selectedDataset ? (
          <DeleteCompetitorShelfDatasetConfirmationModal
            selectedDataset={this.state.selectedDataset}
            enabled={!isLoading(this.props.deleteCompetitorShelfDatasetState)}
            isDeleting={isLoading(this.props.deleteCompetitorShelfDatasetState)}
            onClose={(): void => this.setState({ showDeleteDatasetConfirmationModal: false })}
            onConfirmed={(dataset: CompetitorShelfDataset): void => {
              this.props.deleteCompetitorShelfDataset(dataset);
            }}
          />
        ) : null}
        {this.state.showPreviewCompetitorDatasetModal && this.state.selectedDataset ? (
          <PreviewCompetitorDatasetModal shelfDataset={this.state.selectedDataset} onClose={(): void => this.setState({ showPreviewCompetitorDatasetModal: false })} />
        ) : null}
      </div>
    );
  }

  renderCompetitorShelvesTable(): React.ReactNode {
    if (this.props.competitorShelves.length === 0) {
      return (
        <Alert className="top-buffer" color="info">
          <Typography variant="caption" color="textSecondary">
            <InfoOutlined color="primary" fontSize="small" />
            {`&nbsp; No 'Competitor/Similar' Shelves are linked to this planogram yet.`}
          </Typography>
        </Alert>
      );
    }
    return (
      <Table size="small" className="sticky-header-table">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell>Id</StyledTableCell>
            <StyledTableCell>Project Info</StyledTableCell>
            <StyledTableCell>Client Info</StyledTableCell>
            <StyledTableCell>Period</StyledTableCell>
            <StyledTableCell>Shelf Image</StyledTableCell>
            <StyledTableCell />
            <StyledTableCell />
            <StyledTableCell />
            <StyledTableCell />
          </StyledTableRow>
        </TableHead>
        <TableBody>{this.renderCompetitorShelvesTableRows()}</TableBody>
      </Table>
    );
  }

  renderCompetitorShelvesTableRows(): JSX.Element[] {
    return this.props.competitorShelves.map(shelf => {
      const shelvesDatasets = this.props.competitorShelvesDatasetsStatus.data;
      const shelfDataset = shelvesDatasets?.find(s => s.shelfReferenceId === shelf.referenceId);

      return (
        <StyledTableRow key={shelf.referenceId}>
          <StyledTableCell>
            <p>
              Reference GUID: <strong>{shelf.referenceId}</strong>
            </p>
            <p>
              Project ID: <strong>{shelf.projectId}</strong>
            </p>
            <p>
              Store ID: <strong>{shelf.storeId}</strong>
            </p>
            <p>
              Phase: <strong>{shelf.phaseNum}</strong>
            </p>
            <p>
              Shelf: <strong>{shelf.shelfNum}</strong>
            </p>
          </StyledTableCell>
          <StyledTableCell>
            <p>
              Project Name: <strong>{shelf.projectName}</strong>
            </p>
            <p>
              Store Name: <strong>{shelf.storeName}</strong>
            </p>
          </StyledTableCell>
          <StyledTableCell>
            <p>
              Client ID: <strong>{shelf.clientId}</strong>
            </p>
            <p>
              Client Name: <strong>{shelf.clientName}</strong>
            </p>
          </StyledTableCell>
          <StyledTableCell>
            <p>
              Start Date: <strong>{getDateInInputFormat(shelf.startdate)}</strong>
            </p>
            <p>
              End Date <strong>{getDateInInputFormat(shelf.endDate)}</strong>
            </p>
          </StyledTableCell>
          <StyledTableCell>
            <a href={shelf.shelfImageUrl} target="_blank" rel="noopener noreferrer">
              <img style={{ maxWidth: 200, maxHeight: 200 }} src={shelf.shelfImageUrl} alt="" />
            </a>
          </StyledTableCell>
          <StyledTableCell>
            {shelfDataset ? (
              <Tooltip title="View Dataset">
                <IconButton
                  size="medium"
                  className="pull-left"
                  color="primary"
                  onClick={(): void => {
                    this.setState({ showPreviewCompetitorDatasetModal: true, selectedDataset: shelfDataset });
                  }}
                >
                  <Description />
                </IconButton>
              </Tooltip>
            ) : null}
          </StyledTableCell>
          <StyledTableCell>
            {shelfDataset ? (
              <Tooltip title="Download Dataset">
                <IconButton
                  size="medium"
                  className="pull-left"
                  color="primary"
                  onClick={(): void => {
                    this.downloadDataAsExcelFile(shelfDataset);
                  }}
                >
                  <CloudDownload />
                </IconButton>
              </Tooltip>
            ) : null}
          </StyledTableCell>
          <StyledTableCell>
            {shelfDataset ? null : (
              <Tooltip title="Upload Data for this shelf">
                <IconButton onClick={(): void => this.setState({ showUploadDatasetModal: true, selectedShelf: shelf })}>
                  <CloudUpload color="primary" />
                </IconButton>
              </Tooltip>
            )}
          </StyledTableCell>
          <StyledTableCell>
            {shelfDataset ? (
              <Tooltip title="Delete Dataset">
                <IconButton size="medium" className="pull-left" color="secondary" onClick={(): void => this.setState({ showDeleteDatasetConfirmationModal: true, selectedDataset: shelfDataset })}>
                  <Delete />
                </IconButton>
              </Tooltip>
            ) : null}
          </StyledTableCell>
        </StyledTableRow>
      );
    });
  }
}

interface CompetitorShelvesDatasetsTabProps {
  planogramId: string;
  planogramName: string;
  competitorShelves: CompetitorShelf[];
  uploadCompetitorShelfDatasetState: RemoteObjectStatusInterface<{}>;
  competitorShelvesDatasetsStatus: RemoteObjectStatusInterface<CompetitorShelfDataset[]>;
  deleteCompetitorShelfDatasetState: RemoteObjectStatusInterface<{}>;
  uploadDataset(dataset: CompetitorShelfDataset): void;

  deleteCompetitorShelfDataset(dataset: CompetitorShelfDataset): void;
}
export default trackComponent(CompetitorShelvesDatasetsTab, 'CompetitorShelvesDatasetsTab');
