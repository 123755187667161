import createReducer from 'utils/createReducer';
import * as constants from './constants';
import AdminPlanogramsState from './state';
import CompetitorShelf from './types/CompetitorShelf';
import CompetitorShelfDataset from './types/CompetitorShelfDataset';
import * as actions from './actions';
import { setError, setIdle, setLoading, setSuccess, setData } from 'infrastructure/utils/RemoteObjectStatus';
import { OnErrorRequestAction } from 'modules/shared/actions';
import AdminPlanogramViewModel from './types/AdminPlanogramViewModel';
import AdminBuilderPlanogramViewModel from './types/AdminBuilderPlanogramViewModel';
import { Action } from 'types/Action';

const initialState = {
  // Planograms
  loadAdminPlanogramsStatus: setIdle<AdminPlanogramViewModel[]>([]),
  loadAdminBuildPlanogramsStatus: setIdle<AdminBuilderPlanogramViewModel[]>([]),

  // Competitor Shelves
  competitorShelvesState: setIdle<CompetitorShelf[]>([] as CompetitorShelf[]),
  competitorShelvesDatasetsStatus: setIdle<CompetitorShelfDataset[]>([] as CompetitorShelfDataset[]),
  updateCompetitorShelvesState: setIdle(),
  uploadCompetitorShelfDatasetState: setIdle(),
  deleteCompetitorShelfDatasetState: setIdle(),

  loadEnableInspirationImagesState: setIdle(),
} as AdminPlanogramsState;

// Load planograms for admin
function loadAdminPlanogramsRequestHandler(state: AdminPlanogramsState): AdminPlanogramsState {
  return {
    ...state,
    loadAdminPlanogramsStatus: setLoading('Loading planograms...'),
  };
}

function loadAdminPlanogramsErrorHandler(state: AdminPlanogramsState, action: OnErrorRequestAction): AdminPlanogramsState {
  return {
    ...state,
    loadAdminPlanogramsStatus: setError(action.errorDetails),
  };
}

function loadAdminPlanogramsSuccessHandler(state: AdminPlanogramsState, action: Action): AdminPlanogramsState {
  return {
    ...state,
    loadAdminPlanogramsStatus: setSuccess(action.planograms),
  };
}

// Load Builders of planogram for admin
function loadAdminPlanogramBuildersRequestHandler(state: AdminPlanogramsState): AdminPlanogramsState {
  return {
    ...state,
    loadAdminBuildPlanogramsStatus: setLoading('Loading planogram builders...'),
  };
}

function loadAdminPlanogramBuildersErrorHandler(state: AdminPlanogramsState, action: OnErrorRequestAction): AdminPlanogramsState {
  return {
    ...state,
    loadAdminBuildPlanogramsStatus: setError(action.errorDetails),
  };
}

function loadAdminPlanogramBuildersSuccessHandler(state: AdminPlanogramsState, action: Action): AdminPlanogramsState {
  return {
    ...state,
    loadAdminBuildPlanogramsStatus: setSuccess(action.builders),
  };
}

// Load 'Competitor Shelf' type ProjectStorePhaseShelves
function loadAdminCompetitorShelvesRequest(state: AdminPlanogramsState): AdminPlanogramsState {
  return {
    ...state,
    competitorShelvesState: setLoading('Loading competitor shelves...'),
  };
}

function loadAdminCompetitorShelvesSuccess(state: AdminPlanogramsState, action: Action): AdminPlanogramsState {
  return {
    ...state,
    competitorShelvesState: setSuccess(action.competitorShelves as CompetitorShelf[]),
  };
}

function loadAdminCompetitorShelvesFailure(state: AdminPlanogramsState, action: OnErrorRequestAction): AdminPlanogramsState {
  return {
    ...state,
    competitorShelvesState: setError(action.errorDetails),
  };
}

// Load CompetitorShelves datasets for a planogram
function loadAdminCompetitorShelvesDatasetsRequestHandler(state: AdminPlanogramsState): AdminPlanogramsState {
  return {
    ...state,
    competitorShelvesDatasetsStatus: setLoading('Loading competitor shelf data...'),
  };
}

function loadAdminCompetitorShelvesDatasetsSuccessHandler(state: AdminPlanogramsState, action: Action): AdminPlanogramsState {
  return {
    ...state,
    competitorShelvesDatasetsStatus: setSuccess(action.competitorShelvesDatasets as CompetitorShelfDataset[]),
  };
}

function loadAdminCompetitorShelvesDatasetsFailureHandler(state: AdminPlanogramsState, action: OnErrorRequestAction): AdminPlanogramsState {
  return {
    ...state,
    competitorShelvesDatasetsStatus: setError(action.errorDetails),
  };
}

// Update CompetitorShelves for a planogram
function updateAdminCompetitorShelvesRequestHandler(state: AdminPlanogramsState): AdminPlanogramsState {
  return {
    ...state,
    updateCompetitorShelvesState: setLoading('Updating competitor shelves...'),
  };
}

function updateAdminCompetitorShelvesSuccessHandler(state: AdminPlanogramsState, action: Action): AdminPlanogramsState {
  const competitorShelves = action.competitorShelves as CompetitorShelf[];
  const planogramId = action.planogramId as string;
  const planograms = state.loadAdminPlanogramsStatus.data?.map(p => {
    // Updating planogram's competitorShelves
    if (p.planogramId === planogramId) {
      p.competitorShelves = competitorShelves;
    }
    return p;
  });
  return {
    ...state,
    updateCompetitorShelvesState: setSuccess({}),
    loadAdminPlanogramsStatus: setData(state.loadAdminPlanogramsStatus, planograms ?? []),
  };
}

function updateAdminCompetitorShelvesFailureHandler(state: AdminPlanogramsState, action: OnErrorRequestAction): AdminPlanogramsState {
  return {
    ...state,
    updateCompetitorShelvesState: setError(action.errorDetails),
  };
}

// Upload dataset for a planogram's similar shelf.
function uploadCompetitorShelfDatasetRequestHandler(state: AdminPlanogramsState): AdminPlanogramsState {
  return {
    ...state,
    uploadCompetitorShelfDatasetState: setLoading('Uploading data...'),
  };
}

function uploadCompetitorShelfDatasetSuccessHandler(state: AdminPlanogramsState, action: Action): AdminPlanogramsState {
  const uploadedDataset = action.planogramCompetitorShelfDataset as CompetitorShelfDataset;
  let datasets = state.competitorShelvesDatasetsStatus.data as CompetitorShelfDataset[];
  // filter out existing datasets belonging to planogramId and shelfRefrenceId
  datasets = datasets.filter(d => d.planogramId !== uploadedDataset.planogramId && d.shelfReferenceId !== uploadedDataset.shelfReferenceId);
  // update datasets with newly uploaded dataset
  datasets.push(uploadedDataset);

  return {
    ...state,
    uploadCompetitorShelfDatasetState: setSuccess({}),
    competitorShelvesDatasetsStatus: setData(state.competitorShelvesDatasetsStatus, datasets),
  };
}

function uploadCompetitorShelfDatasetFailureHandler(state: AdminPlanogramsState, action: OnErrorRequestAction): AdminPlanogramsState {
  return {
    ...state,
    uploadCompetitorShelfDatasetState: setError(action.errorDetails),
  };
}

// Delete dataset for a planogram's similar shelf.
function deleteCompetitorShelfDatasetRequestHandler(state: AdminPlanogramsState): AdminPlanogramsState {
  return {
    ...state,
    deleteCompetitorShelfDatasetState: setLoading('Deleting shelf dataset...'),
  };
}

function deleteCompetitorShelfDatasetSuccessHandler(state: AdminPlanogramsState, action: Action): AdminPlanogramsState {
  const deletedDataset = action.dataset as CompetitorShelfDataset;
  let datasets = state.competitorShelvesDatasetsStatus.data as CompetitorShelfDataset[];
  // filter out existing datasets belonging to planogramId and shelfRefrenceId
  datasets = datasets.filter(d => d.planogramId !== deletedDataset.planogramId && d.shelfReferenceId !== deletedDataset.shelfReferenceId);

  return {
    ...state,
    deleteCompetitorShelfDatasetState: setSuccess({}),
    competitorShelvesDatasetsStatus: setData(state.competitorShelvesDatasetsStatus, datasets),
  };
}

function deleteCompetitorShelfDatasetFailureHandler(state: AdminPlanogramsState, action: OnErrorRequestAction): AdminPlanogramsState {
  return {
    ...state,
    deleteCompetitorShelfDatasetState: setError(action.errorDetails),
  };
}

export function enableShelfConditionRequest(state: AdminPlanogramsState, action: ReturnType<actions.EnableShelfConditionRequestActionCreator>): AdminPlanogramsState {
  const planograms = state.loadAdminPlanogramsStatus.data?.map(planogram => {
    if (planogram.planogramId === action.payload.planogramId) {
      planogram.shelfConditions = {
        ...planogram.shelfConditions,
        ...{
          isShelfConditionsEnabled: action.payload.isShelfConditionsEnabled,
        },
      };
      return planogram;
    }
    return planogram;
  });

  const builderPlanograms = state.loadAdminBuildPlanogramsStatus.data?.map(builder => {
    if (builder.id === action.payload.planogramId) {
      builder.shelfConditions = {
        ...builder.shelfConditions,
        ...{
          isShelfConditionsEnabled: action.payload.isShelfConditionsEnabled,
        },
      };
    }
    return builder;
  });

  return {
    ...state,
    loadAdminPlanogramsStatus: setData(state.loadAdminPlanogramsStatus, planograms ?? []),
    loadAdminBuildPlanogramsStatus: setData(state.loadAdminBuildPlanogramsStatus, builderPlanograms ?? []),
  };
}

export function enableShelfConditionSuccess(state: AdminPlanogramsState): AdminPlanogramsState {
  return state;
}

export function enableShelfConditionFailed(state: AdminPlanogramsState, action: ReturnType<actions.EnableShelfConditionFailedActionCreator>): AdminPlanogramsState {
  const planograms = state.loadAdminPlanogramsStatus.data?.map(planogram => {
    if (planogram.planogramId === action.payload.planogramId) {
      planogram.shelfConditions = {
        ...planogram.shelfConditions,
        ...{
          isShelfConditionsEnabled: action.payload.previousState,
        },
      };
      return planogram;
    }
    return planogram;
  });
  const builderPlanograms = state.loadAdminBuildPlanogramsStatus.data?.map(builder => {
    if (builder.id === action.payload.planogramId) {
      builder.shelfConditions = {
        ...builder.shelfConditions,
        ...{
          isShelfConditionsEnabled: action.payload.previousState,
        },
      };
    }
    return builder;
  });
  return {
    ...state,
    loadAdminPlanogramsStatus: setData(state.loadAdminPlanogramsStatus, planograms ?? []),
    loadAdminBuildPlanogramsStatus: setData(state.loadAdminBuildPlanogramsStatus, builderPlanograms ?? []),
  };
}

export function enableInspirationImagesRequest(state: AdminPlanogramsState): AdminPlanogramsState {
  return {
    ...state,
    loadEnableInspirationImagesState: setLoading('Loading inspiration image...'),
  };
}

export function enableInspirationImagesSuccess(state: AdminPlanogramsState, action: ReturnType<actions.EnableInspirationImagesSuccessCreator>): AdminPlanogramsState {
  const planograms = state.loadAdminPlanogramsStatus.data?.map(p => {
    if (p.planogramId === action.payload.planogramId) {
      return {
        ...p,
        inspirationImages: {
          ...p.inspirationImages,
          isInspirationImagesEnabled: action.payload.isInspirationImagesEnabled,
        },
      };
    }
    return p;
  });

  const builderPlanograms = state.loadAdminBuildPlanogramsStatus.data?.map(p => {
    if (p.id === action.payload.planogramId) {
      return {
        ...p,
        inspirationImages: {
          ...p.inspirationImages,
          isInspirationImagesEnabled: action.payload.isInspirationImagesEnabled,
        },
      };
    }
    return p;
  });

  return {
    ...state,
    loadEnableInspirationImagesState: setSuccess({}),
    loadAdminPlanogramsStatus: setData(state.loadAdminPlanogramsStatus, planograms ?? []),

    loadAdminBuildPlanogramsStatus: setData(state.loadAdminBuildPlanogramsStatus, builderPlanograms ?? []),
  };
}
export function enableInspirationImagesFailed(state: AdminPlanogramsState, action: OnErrorRequestAction): AdminPlanogramsState {
  return {
    ...state,
    loadEnableInspirationImagesState: setError(action.errorDetails),
  };
}
const handlers = {};

handlers[constants.ADMIN_PLANOGRAMS_LOAD_ERROR] = loadAdminPlanogramsErrorHandler;
handlers[constants.ADMIN_PLANOGRAMS_LOAD_REQUEST] = loadAdminPlanogramsRequestHandler;
handlers[constants.ADMIN_PLANOGRAMS_LOAD_SUCCESS] = loadAdminPlanogramsSuccessHandler;

handlers[constants.ADMIN_LOAD_PLANOGRAM_BUILDERS_ERROR] = loadAdminPlanogramBuildersErrorHandler;
handlers[constants.ADMIN_LOAD_PLANOGRAM_BUILDERS_REQUEST] = loadAdminPlanogramBuildersRequestHandler;
handlers[constants.ADMIN_LOAD_PLANOGRAM_BUILDERS_SUCCESS] = loadAdminPlanogramBuildersSuccessHandler;

handlers[constants.ADMIN_LOAD_PLANOGRAM_COMPETITOR_SHELVES_REQUEST] = loadAdminCompetitorShelvesRequest;
handlers[constants.ADMIN_LOAD_PLANOGRAM_COMPETITOR_SHELVES_SUCCESS] = loadAdminCompetitorShelvesSuccess;
handlers[constants.ADMIN_LOAD_PLANOGRAM_COMPETITOR_SHELVES_FAILURE] = loadAdminCompetitorShelvesFailure;

handlers[constants.ADMIN_LOAD_PLANOGRAM_COMPETITOR_SHELVES_DATASETS_REQUEST] = loadAdminCompetitorShelvesDatasetsRequestHandler;
handlers[constants.ADMIN_LOAD_PLANOGRAM_COMPETITOR_SHELVES_DATASETS_SUCCESS] = loadAdminCompetitorShelvesDatasetsSuccessHandler;
handlers[constants.ADMIN_LOAD_PLANOGRAM_COMPETITOR_SHELVES_DATASETS_FAILURE] = loadAdminCompetitorShelvesDatasetsFailureHandler;

handlers[constants.ADMIN_UPDATE_PLANOGRAM_COMPETITOR_SHELVES_REQUEST] = updateAdminCompetitorShelvesRequestHandler;
handlers[constants.ADMIN_UPDATE_PLANOGRAM_COMPETITOR_SHELVES_SUCCESS] = updateAdminCompetitorShelvesSuccessHandler;
handlers[constants.ADMIN_UPDATE_PLANOGRAM_COMPETITOR_SHELVES_FAILURE] = updateAdminCompetitorShelvesFailureHandler;

handlers[constants.ADMIN_UPLOAD_COMPETITOR_SHELF_DATASET_REQUEST] = uploadCompetitorShelfDatasetRequestHandler;
handlers[constants.ADMIN_UPLOAD_COMPETITOR_SHELF_DATASET_SUCCESS] = uploadCompetitorShelfDatasetSuccessHandler;
handlers[constants.ADMIN_UPLOAD_COMPETITOR_SHELF_DATASET_FAILURE] = uploadCompetitorShelfDatasetFailureHandler;

handlers[constants.ADMIN_DELETE_COMPETITOR_SHELF_DATASET_REQUEST] = deleteCompetitorShelfDatasetRequestHandler;
handlers[constants.ADMIN_DELETE_COMPETITOR_SHELF_DATASET_SUCCESS] = deleteCompetitorShelfDatasetSuccessHandler;
handlers[constants.ADMIN_DELETE_COMPETITOR_SHELF_DATASET_FAILURE] = deleteCompetitorShelfDatasetFailureHandler;

handlers[constants.ENABLE_SHELF_CONDITION.REQUEST] = enableShelfConditionRequest;
handlers[constants.ENABLE_SHELF_CONDITION.SUCCESS] = enableShelfConditionSuccess;
handlers[constants.ENABLE_SHELF_CONDITION.FAILED] = enableShelfConditionFailed;

handlers[constants.ENABLE_INSPIRATION_IMAGES.REQUEST] = enableInspirationImagesRequest;
handlers[constants.ENABLE_INSPIRATION_IMAGES.SUCCESS] = enableInspirationImagesSuccess;
handlers[constants.ENABLE_INSPIRATION_IMAGES.FAILED] = enableInspirationImagesFailed;

const adminPlanogramsReducer = createReducer(initialState, handlers);
export default adminPlanogramsReducer;
