import { Button, Typography } from '@material-ui/core';
import { BarChart, ErrorOutline, Share, Style, Videocam } from '@material-ui/icons';
import trackComponent from 'infrastructure/tracking/withTracking';
import { isLoaded, isLoading } from 'infrastructure/utils/RemoteObjectStatus';
import * as React from 'react';
import { connect } from 'react-redux';
import { BasicToastrOptions, toastr } from 'react-redux-toastr';
import { RouteComponentProps } from 'react-router';
import { State } from 'state';
import EnvFeatureFlag from '../../../../containers/EnvFeatureFlag';
import * as actions from '../../actions';
import PlanogramModel from '../../domain/PlanogramModel';
import { isAllowedToTestWithFlow } from '../../helpers/permissionsHelper';
import PlanogramState from '../../state';
import GetCompetitorModal from '../modals/GetCompetitorModal';
import InspirationImages from '../modals/InspirationImages';
import ShareModal from '../modals/ShareModal';

type MyProps = PlanogramState & DispatchProps;

class PlanogramPageFooter extends React.Component<MyProps> {
  launchTest = (): void => {
    const planogram = this.props.planogram as PlanogramModel;
    if (!planogram.isValid) {
      toastr.warning('Your planogram is in invalid state', 'You have to fix shelf locations that are overlapping or are outside the boundaries of the shelf.', {
        timeOut: 3000,
      } as BasicToastrOptions);
      return;
    }
    this.props.openTestWithFlowModal();
  };

  render(): JSX.Element | null {
    const planogram = this.props.planogram as PlanogramModel;
    if (!isLoaded(this.props.loadPlanogramStatus)) {
      return null;
    }
    if (planogram && planogram.isReadOnly) {
      return null;
    }

    return (
      <div className="no-print">
        <Button
          id="testWithFlowBtn"
          size="small"
          variant="outlined"
          color="primary"
          className="pull-left"
          disabled={!isAllowedToTestWithFlow(planogram.actionRules, planogram.ownerId, planogram.builderId, this.props.userId)}
          onClick={this.launchTest}
        >
          <BarChart />
          &nbsp; Test with FLOW
        </Button>
        &nbsp;&nbsp;
        <Button id="sharePlanogramBtn" size="small" variant="outlined" color="primary" onClick={this.props.shareModalToggle}>
          <Share />
          &nbsp; Share planogram
        </Button>
        &nbsp;&nbsp;
        {planogram.competitorShelves.length !== 0 ? (
          <Button id="drawCompetitorBtn" size="small" variant="outlined" color="primary" disabled={planogram.competitorShelves.length === 0} onClick={this.props.toggleShowCompetitorModal}>
            <Style />
            &nbsp; Competitors
          </Button>
        ) : null}
        <EnvFeatureFlag featureName="inspirationImages">
          &nbsp;&nbsp;
          <InspirationImages planogramId={this.props.id} />
        </EnvFeatureFlag>
        &nbsp;&nbsp;
        <EnvFeatureFlag featureName="shelfConditions">
          <Button href={`/shelves/${planogram.shelfReferenceId}/timelapse`} size="small" variant="outlined" color="primary" target="_blank">
            <Videocam />
            &nbsp;Shelf Timelapse
          </Button>
        </EnvFeatureFlag>
        {this.renderInvalidPlanogram(planogram)}
        {this.renderPlanogramGoal()}
        {this.props.showSharingModal ? (
          <ShareModal
            planogram={planogram}
            onClose={this.props.shareModalToggle}
            isTogglingPublicShare={isLoading(this.props.togglePublicShareOptionsStatus)}
            onTogglePublicShare={(): void => {
              this.props.publicSharingToggleRequest(planogram.id, !planogram.sharingOptions.publicLinkEnabled);
            }}
          />
        ) : null}
        {this.props.showGetCompetitorModal ? <GetCompetitorModal planogramId={planogram.id} competitorShelves={planogram.competitorShelves} onClose={this.props.toggleShowCompetitorModal} /> : null}
      </div>
    );
  }
  renderPlanogramGoal(): JSX.Element | null {
    if (!this.props.planogram) {
      return null;
    }
    return (
      <Typography id="planogramGoaltxt" style={{ margin: '8px' }} className="col-xs-4 col-xs-offset-2" variant="overline" color="textPrimary">
        {this.props.planogram.goal}
      </Typography>
    );
  }

  renderInvalidPlanogram(planogram: PlanogramModel): JSX.Element | null {
    if (planogram.isValid) {
      return null;
    }
    return (
      <Button
        variant={this.props.paintInvalidShelfLocations ? 'contained' : 'outlined'}
        color="secondary"
        size="small"
        onClick={(): void => {
          if (!this.props.paintInvalidShelfLocations) {
            toastr.warning('Invalid Reaason(s)', planogram.invalidReasons);
          }
          this.props.toggleShowInvalidShelfLocations();
        }}
      >
        <ErrorOutline /> &nbsp; INVALID!
      </Button>
    );
  }
}

function mapStateToProps(state: State, ownProps: RouteComponentProps<MatchParams>): PlanogramState {
  return {
    ...state.planogram,
    id: ownProps.match.params.id,
    userId: Number(state.auth.loadUserStatus.data?.profile.sub ?? 0),
  };
}

interface DispatchProps {
  shareModalToggle(): void;

  openTestWithFlowModal(): void;

  publicSharingToggleRequest(planogramId: string, enabled: boolean): void;

  toggleShowInvalidShelfLocations(): void;
  toggleShowCompetitorModal(): void;
}

interface MatchParams {
  id: string;
}

const componentToExport = connect<PlanogramState, DispatchProps, RouteComponentProps<MatchParams>>(mapStateToProps, actions)(PlanogramPageFooter);

export default trackComponent(componentToExport, 'PlanogramPageFooter');
