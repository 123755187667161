import * as React from 'react';
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { CloudOffSharp, Refresh } from '@material-ui/icons';
import trackComponent from 'infrastructure/tracking/withTracking';

interface MyState {
  countdownSeconds: number;
  refreshing: boolean;
}

class UnstableStateModal extends React.Component<{}, MyState> {
  intervalHandler: number | undefined;

  constructor(props: {}) {
    super(props);
    this.state = {
      countdownSeconds: 60,
      refreshing: false,
    };
    this.onTick = this.onTick.bind(this);
  }

  onTick(): void {
    let currentCountdown = this.state.countdownSeconds;
    if (currentCountdown === 1) {
      clearInterval(this.intervalHandler);
      this.setState({ refreshing: true });
      window.location.reload();
    }
    currentCountdown--;
    this.setState({ countdownSeconds: currentCountdown });
  }

  componentDidMount(): void {
    this.intervalHandler = (setInterval(this.onTick, 1000) as unknown) as number;
  }

  render(): JSX.Element {
    return (
      <Dialog open={true}>
        <DialogTitle>
          <Typography variant="overline" gutterBottom>
            <CloudOffSharp color="secondary" fontSize="large" />
            &nbsp; Your changes might not have been saved
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Due to network error, your recent changes to the planogram might not have been saved. Please refresh this page to continue from last saved state of the planogram.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            disabled={this.state.refreshing}
            onClick={(): void => {
              clearInterval(this.intervalHandler);
              this.setState({ refreshing: true });
              window.location.reload();
            }}
          >
            <Refresh />
            &nbsp;
            {this.state.refreshing ? <span>Refreshing...</span> : <span>Refresh now ({this.state.countdownSeconds})</span>}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default trackComponent(UnstableStateModal, 'PlanogramPage_UnstableStateModal');
