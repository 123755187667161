export interface StandardReportPhaseShelf {
  shelfPhaseId: string;
  projectId: number;
  storeId: number;
  phaseNum: number;
  shelfNum: number;
  clientId: number;
  clientName: string;
  projectName: string;
  storeName: string;
  shelfImageUrl: string;
  products: ProductInPhaseShelf[];
}
export interface ProductInPhaseShelf {
  gtin: number;
  tradeItemDescription: string;
  brandId?: number;
  brandName: string;
  gpcBrickCode?: number;
  gpcBrickDescription: string;
  shelfPhaseId: string;
}
export enum InteractorsType {
  Project,
  Brand,
  Product,
  GpcBrick,
}
// Shopper category types
export enum BrandConversionShopperCategoryType {
  GrabAndGo = 'GrabAndGo',
  BrandsProductDestinationBuyers = 'BrandsProductDestinationBuyers',
  BrandDestinationBuyers = 'BrandDestinationBuyers',
  'WonFromCompetitorBrand(s)' = 'WonFromCompetitorBrand(s)',
  MultiBrandBuyers = 'MultiBrandBuyers',
  'LostToCompetitorBrand(s)' = 'LostToCompetitorBrand(s)',
  ShelfLostShoppers = 'ShelfLostShoppers',
  BrandLostShoppers = 'BrandLostShoppers',
  BrandsProductLostShoppers = 'BrandsProductLostShoppers',
}
export enum ProjectConversionShopperCategoryType {
  GrabAndGo = 'GrabAndGo',
  ProductDestinationBuyers = 'ProductDestinationBuyers',
  BrandDestinationBuyers = 'BrandDestinationBuyers',
  MultiBrandInteractors = 'MultiBrandInteractors',
  ShelfLostShoppers = 'ShelfLostShoppers',
  BrandLostShoppers = 'BrandLostShoppers',
  ProductLostShoppers = 'ProductLostShoppers',
}
export enum ProductConversionShopperCategoryType {
  GrabAndGo = 'GrabAndGo',
  ProductDestinationBuyers = 'ProductDestinationBuyers',
  WonFromInternal = 'WonFromInternal',
  'WonFromCompetitor(s)' = 'WonFromCompetitor(s)',
  MultiProductBuyers = 'MultiProductBuyers',
  ShelfLostShoppers = 'ShelfLostShoppers',
  LostToInternal = 'LostToInternal',
  'LostToCompetitor(s)' = 'LostToCompetitor(s)',
  ProductLostShoppers = 'ProductLostShoppers',
}

export enum GpcBrickConversionShopperCategoryType {
  GrabAndGo = 'GrabAndGo',
  ProductDestinationBuyers = 'ProductDestinationBuyers',
  BrickDestinationBuyers = 'BrickDestinationBuyers',
  'WonFromOtherBrick(s)' = 'WonFromOtherBrick(s)',
  MultiBrickBuyers = 'MultiBrickBuyers',
  'LostToOtherBrick(s)' = 'LostToOtherBrick(s)',
  ShelfLostShoppers = 'ShelfLostShoppers',
  BrickLostShoppers = 'BrickLostShoppers',
  ProductLostShoppers = 'ProductLostShoppers',
}

// Reports

// Project Report
export interface ProjectReportResult {
  projectOverviewReport: ProjectOverviewReportResult;
  projectBricksReport: ProjectBrickReportResult[];
  projectDetailedConversionReport: ProjectDetailedConversionResult[];
}

interface ProjectOverviewReportResult {
  shelfPhaseId: string;
  projectName: string;
  storeName: string;
  phaseNum: number;
  dateFrom: string;
  dateTo: string;
  totalVisitors?: number;
  productsCount?: number;
  conversionRate: number;
  turnoverPerVisitor: number;
  dailyTurnoverAverage: number;
  destinationBuyersPercent: number;
  returnRatioPercent?: number;
  lostShoppersPercent: number;
  averageLostDailyTurnover: number;
  outOfStockDailyHours?: number;
  secondsToBuyAndLeave: number;
  secondsToFirstInteraction: number;
  lostShopperSeconds: number;
  interactionsPerShopper: number;
  averageBasketSizeUnits: number;
  averageBasketSizeTurnover: number;
  shelfImageUrl: string;
}
interface ProjectBrickReportResult {
  shelfPhaseId: string;
  brickName: string;
  shareOfSpace: number;
  shareOfUnitSales: number;
  shareOfTurnover: number;
  shareOfFirstPickups: number;
}
export interface ProjectDetailedConversionResult {
  shelfPhaseId: string;
  projectConversionShopperCategory: ProjectConversionShopperCategoryType;
  shopperPercent: number;
}

// Shopper Report
export interface ShopperReportResult {
  shoppersOverviewReport: ShopperOverviewReportResult;
  shoppersDemographicsReport: ShopperDemographicsReportResult[];
  shoppersShoppingWithReport: ShopperShoppingWithReportResult[];
  shoppersWeekdaysReport: ShopperWeekdaysReportResult[];
  shoppingTimeReport: ShoppingTimeReportResult[];
}

interface ShopperOverviewReportResult {
  shelfPhaseId: string;
  projectName: string;
  storeName: string;
  phaseNum: number;
  dateFrom: Date;
  dateTo: Date;
  totalVisitors?: number;
  visitorsShare: number;
  shownInterestShare?: number;
  interactedShare?: number;
  purchasedShare?: number;
  lostShoppersShare?: number;
  assistedBrowsersShare?: number;
  conversionFromAssistance?: number;
}
interface ShopperDemographicsReportResult {
  shelfPhaseId: string;
  demographicGroup: string;
  visitorsShare?: number;
  shownInterestShare?: number;
  interactedShare?: number;
  purchasedShare?: number;
  lostShoppersShare?: number;
  assistedBrowsersShare?: number;
  conversionFromAssistance?: number;
}
interface ShopperShoppingWithReportResult {
  shelfPhaseId: string;
  shoppingWith: string;
  visitorsShare: number;
  shownInterestShare: number;
  interactedShare: number;
  purchasedShare: number;
  lostShoppersShare: number;
  assistedBrowsersShare: number;
  conversionFromAssistance: number;
}
interface ShopperWeekdaysReportResult {
  shelfPhaseId: string;
  shoppingWeekDay: string;
  visitorsShare: number;
  shownInterestShare: number;
  interactedShare: number;
  purchasedShare: number;
  lostShoppersShare: number;
  assistedBrowsersShare: number;
  conversionFromAssistance: number;
}
interface ShoppingTimeReportResult {
  shelfPhaseId: string;
  shoppingTime: string;
  visitorsShare: number;
  shownInterestShare: number;
  interactedShare: number;
  purchasedShare: number;
  lostShoppersShare: number;
  assistedBrowsersShare: number;
  conversionFromAssistance: number;
}
// Brand Report
export interface BrandReportResult {
  brandOverviewReport: BrandOverviewReportResult;
  brandDemographicsReport: BrandDemographicReportResult[];
  brandsExternalBoughtTogetherReport: BrandExternalBoughtTogetherReportResult[];
  brandsExternalWinningOverReport: BrandExternalWinningOverReportResult[];
  brandsExternalLosingToReport: BrandExternalLosingToReportResult[];
  brandInternalBuysMoreThanOneProductReport?: BrandInternalBuysMoreThanOneProductReportResult;
  brandInternalSubstitutorsReport: BrandInternalSubstitutorsResult;
  brandDetailedConversionReport: BrandDetailedConversionResult[];
  brandProductsUrlReport: BrandProductUrlReportResult[];
}
interface BrandOverviewReportResult {
  brandId: number;
  brandName: string;
  shelfPhaseId: string;
  projectName: string;
  storeName: string;
  phaseNum: number;
  dateFrom: string;
  dateTo: string;
  totalVisitors: number;
  totalBrandInteractors: number;
  brandConversionRate: number;
  brandTurnoverPerVisitor: number;
  brandDailyTurnover: number;
  brandDestinationBuyers: number;
  brandReturnRatio: number;
  brandLostShoppers: number;
  brandOutOfShelfHours?: number;
  brandOutOfStockPercent?: number;
  shareOfSpace: number;
  shareOfUnitSales: number;
  shareOfTurnover: number;
  shareOfFirstPickups: number;
  discoveryTime: number;
  secondsToBuyAndLeave: number;
  secondsToReject: number;
  substitutions: number;
}
interface BrandDemographicReportResult {
  shelfPhaseId: string;
  brandId: number;
  brandName: string;
  demographicGroup: string;
  interactorsPercent: number;
  buyersPercent: number;
  lostShoppersPercent: number;
}
interface BrandExternalBoughtTogetherReportResult {
  shelfPhaseId: string;
  targetBrandId: number;
  targetBrand: string;
  comparisonBrand: string;
  numberOfShoppers: number;
}
interface BrandExternalWinningOverReportResult {
  shelfPhaseId: string;
  targetBrandId: number;
  targetBrand: string;
  winningOverBrand: string;
  numberOfShoppers: number;
}
interface BrandExternalLosingToReportResult {
  shelfPhaseId: string;
  targetBrandId: number;
  targetBrand: string;
  losingToBrand: string;
  numberOfShoppers: number;
}
interface BrandInternalBuysMoreThanOneProductReportResult {
  shelfPhaseId: string;
  brandId: number;
  brandName: string;
  numberOfShoppers: number;
}
interface BrandInternalSubstitutorsResult {
  shelfPhaseId: string;
  brandId: number;
  brandName: string;
  numberOfShoppers: number;
}

export interface BrandDetailedConversionResult {
  shelfPhaseId: string;
  brandId: number;
  brandConversionShopperCategory: BrandConversionShopperCategoryType;
  shopperPercent: number;
}
interface BrandProductUrlReportResult {
  shelfPhaseId: string;
  brandId: number;
  gtin: number;
  productImageUrl: string;
  tradeItemDescription: string;
}
// Product Report
export interface ProductReportResult {
  productOverviewReport: ProductOverviewReportResult;
  productDemographicsReport: ProductDemographicReportResult[];
  productsBoughtTogetherReport: ProductBoughtTogetherReportResult[];
  productsWinningOverReport: ProductWinningOverReportResult[];
  productsLosingToReport: ProductLosingToReportResult[];
  productDetailedConversionReport: ProductDetailedConversionResult[];
  productShelfLocationReport: ProductShelfLocationReportResult;
}
interface ProductOverviewReportResult {
  gtin: number;
  tradeItemDescription: string;
  shelfPhaseId: string;
  projectName: string;
  storeName: string;
  phaseNum: number;
  dateFrom: string;
  dateTo: string;
  totalVisitors: number;
  totalShoppersInteractedSKU: number;
  productImageURL: string;
  productConversionRate: number;
  productTurnoverPerVisitor: number;
  averageProductDailyTurnover: number;
  productDestinationBuyersShare: number;
  productReturnRatio: number;
  productLostShoppersPercent: number;
  averageProductOosHours?: number;
  productOosTimeShare?: number;
  shareOfSpace: number;
  shareOfUnitSales: number;
  shareOfTurnover: number;
  shareOfFirstPickups: number;
  discoveryTime: number;
  secondsToBuyAndLeave: number;
  secondsToTouchAndReject: number;
  substitutionShopperCount: number;
}
interface ProductDemographicReportResult {
  shelfPhaseId: string;
  gtin: number;
  demographicGroup: string;
  interactorsPercent: number;
  buyersPercent: number;
  lostShoppersPercent: number;
}
interface ProductBoughtTogetherReportResult {
  shelfPhaseId: string;
  targetGTIN: number;
  targetTradeItemDescription: string;
  comparisonTradeItemDescription: string;
  numberOfShoppers: number;
}
interface ProductWinningOverReportResult {
  shelfPhaseId: string;
  targetGTIN: number;
  targetTradeItemDescription: string;
  comparisonTradeItemDescription: string;
  numberOfShoppers: number;
}
interface ProductLosingToReportResult {
  shelfPhaseId: string;
  targetGTIN: number;
  targetTradeItemDescription: string;
  comparisonTradeItemDescription: string;
  numberOfShoppers: number;
}
export interface ProductDetailedConversionResult {
  shelfPhaseId: string;
  gtin: number;
  productConversionShopperCategory: ProductConversionShopperCategoryType;
  shopperPercent: number;
}
interface ProductShelfLocationReportResult {
  shelfPhaseId: string;
  gtin: number;
  verticalLocation?: number;
  maxVerticalLocation?: number;
  locationsCount?: number;
}

// Gpc Brick Report
export interface GpcBrickReportResult {
  gpcBrickOverviewReport: GpcBrickOverviewReportResult;
  gpcBrickDemographicsReport: GpcBrickDemographicReportResult[];
  gpcBricksExternalBoughtTogetherReport: GpcBrickExternalBoughtTogetherReportResult[];
  gpcBricksExternalWinningOverReport: GpcBrickExternalWinningOverReportResult[];
  gpcBricksExternalLosingToReport: GpcBrickExternalLosingToReportResult[];
  gpcBrickInternalBuysMoreThanOneProductReport?: GpcBrickInternalBuysMoreThanOneProductReportResult;
  gpcBrickInternalSubstitutorsReport: GpcBrickInternalSubstitutorsResult;
  gpcBrickDetailedConversionReport: GpcBrickDetailedConversionResult[];
  gpcBrickProductsUrlReport: GpcBrickProductUrlReportResult[];
}
interface GpcBrickOverviewReportResult {
  gpcBrickCode: number;
  gpcBrickDescription: string;
  shelfPhaseId: string;
  projectName: string;
  storeName: string;
  phaseNum: number;
  dateFrom: string;
  dateTo: string;
  totalVisitors: number;
  totalGpcInteractors: number;
  gpcConversionRate: number;
  gpcTurnoverPerVisitor: number;
  gpcDailyTurnover: number;
  gpcDestinationBuyers: number;
  gpcReturnRatio: number;
  gpcLostShoppers: number;
  gpcOutOfShelfHours?: number;
  gpcOutOfStockPercent?: number;
  shareOfSpace: number;
  shareOfUnitSales: number;
  shareOfTurnover: number;
  shareOfFirstPickups: number;
  discoveryTime: number;
  secondsToBuyAndLeave: number;
  secondsToReject: number;
  substitutions: number;
}
interface GpcBrickDemographicReportResult {
  shelfPhaseId: string;
  gpcBrickCode: number;
  gpcBrickDescription: string;
  demographicGroup: string;
  interactorsPercent: number;
  buyersPercent: number;
  lostShoppersPercent: number;
}
interface GpcBrickExternalBoughtTogetherReportResult {
  shelfPhaseId: string;
  targetGpcBrickCode: number;
  targetGpc: string;
  comparisonGpc: string;
  numberOfShoppers: number;
}
interface GpcBrickExternalWinningOverReportResult {
  shelfPhaseId: string;
  targetGpcBrickCode: number;
  targetGpc: string;
  winningOverGpc: string;
  numberOfShoppers: number;
}
interface GpcBrickExternalLosingToReportResult {
  shelfPhaseId: string;
  targetGpcBrickCode: number;
  targetGpc: string;
  losingToGpc: string;
  numberOfShoppers: number;
}
interface GpcBrickInternalBuysMoreThanOneProductReportResult {
  shelfPhaseId: string;
  gpcBrickCode: number;
  gpcBrickDescription: string;
  numberOfShoppers: number;
}
interface GpcBrickInternalSubstitutorsResult {
  shelfPhaseId: string;
  gpcBrickCode: number;
  gpcBrickDescription: string;
  numberOfShoppers: number;
}

export interface GpcBrickDetailedConversionResult {
  shelfPhaseId: string;
  gpcBrickCode: number;
  gpcConversionShopperCategory: GpcBrickConversionShopperCategoryType;
  shopperPercent: number;
}
interface GpcBrickProductUrlReportResult {
  shelfPhaseId: string;
  gpcBrickCode: number;
  gpcBrickDescription: string;
  gtin: number;
  productImageUrl: string;
  tradeItemDescription: string;
}
// Shelf Comparison Report
export interface ShelfComparisonReportResult {
  shelfChanges: ShelfChangesResult;
  brandChanges: BrandChangeResult[];
  productChanges: ProductChangeResult[];
}

interface ShelfChangesResult {
  projectNameA: string;
  projectNameB: string;
  storeNameA: string;
  storeNameB: string;
  storeIdA: number;
  storeIdB: number;
  shelfNumA: number;
  shelfNumB: number;
  phaseA: number;
  phaseB: number;
  dateFromA: string;
  dateToA: string;
  visitorsCountA: number;
  dateFromB: string;
  dateToB: string;
  visitorsCountB: number;
  widthA: number;
  heightA: number;
  depthA: number;
  shelfLocationsA: number;
  openingDaysA: number;
  openingHoursA: number;
  dailyOpeningHoursA: number;
  shelfPhaseIdB: string;
  widthB: number;
  heightB: number;
  depthB: number;
  shelfLocationsB: number;
  openingDaysB: number;
  openingHoursB: number;
  dailyOpeningHoursB: number;
  gtinCountA: number;
  gtinCountB: number;
  newGTINCount: number;
  removedGTINCount: number;
  relocatedGTINCount: number;
  brandCountA: number;
  brandCountB: number;
  newBrandCount: number;
  removedBrandCount: number;
  relocatedBrandCount: number;
  gpcBrickCountA: number;
  gpcBrickCountB: number;
  newGpcBrickCount: number;
  removedGpcBrickCount: number;
  relocatedGpcBrickCount: number;
  averagePriceA: number;
  minPriceA: number;
  maxPriceA: number;
  pricePointsA: number;
  averagePriceB: number;
  minPriceB: number;
  maxPriceB: number;
  pricePointsB: number;
}
interface BrandChangeResult {
  brandName: string;
  productsA: number;
  productsB: number;
  productsOut: number;
  productsIn: number;
  productsRelocated: number;
  priceA: number;
  priceB: number;
  spaceA: number;
  spaceB: number;
  locationsA: number;
  locationsB: number;
  locationMinA: number;
  locationMinB: number;
  locationHeightA: number;
  locationHeightB: number;
  locationMaxA: number;
  locationMaxB: number;
}
interface ProductChangeResult {
  gtin: number;
  brandName: string;
  tradeItemDescription: string;
  outInRelocated: string;
  priceA: number;
  priceB: number;
  spaceA: number;
  spaceB: number;
  locationsA: number;
  locationsB: number;
  locationMinA: number;
  locationMinB: number;
  locationHeightA: number;
  locationHeightB: number;
  locationMaxA: number;
  locationMaxB: number;
}

// Project Comparison Report
export interface ProjectComparisonReportResult {
  projectComparisonOverviewReport: ProjectComparisonOverviewReport;
  projectComparisonBrickMetricsReport: ProjectComparisonBrickMetricReport[];
}
interface ProjectComparisonOverviewReport {
  shelfPhaseIdA: string;
  projectNameA: string;
  storeNameA: string;
  phaseNumA: number;
  shelfNumA: number;
  shelfNumB: number;
  dateFromA: string;
  dateToA: string;
  totalVisitorsA: number;
  productsCountA: number;
  shelfImageUrlA: string;
  projectNameB: string;
  storeNameB: string;
  phaseNumB: number;
  dateFromB: string;
  dateToB: string;
  totalVisitorsB: number;
  productsCountB: number;
  shelfImageUrlB: string;
  conversionRatePercChange?: number;
  conversionRateSignificance: string;
  conversionRateA: number;
  conversionRateB: number;
  turnoverPerVisitorPercChange?: number;
  turnoverPerVisitorSignificance: string;
  turnoverPerVisitorA: number;
  turnoverPerVisitorB: number;
  dailyTurnoverAveragePercChange?: number;
  dailyTurnoverAverageSignificance: string;
  dailyTurnoverAverageA: number;
  dailyTurnoverAverageB: number;
  destinationBuyersPercentPercChange?: number;
  destinationBuyersPercentSignificance: string;
  destinationBuyersPercentA: number;
  destinationBuyersPercentB: number;
  returnRatioPercentPercChange?: number;
  returnRatioPercentSignificance: string;
  returnRatioPercentA: number;
  returnRatioPercentB: number;
  lostShoppersPercentPercChange?: number;
  lostShoppersPercentSignificance: string;
  lostShoppersPercentA: number;
  lostShoppersPercentB: number;
  averageLostDailyTurnoverPercChange?: number;
  averageLostDailyTurnoverSignificance: string;
  averageLostDailyTurnoverA: number;
  averageLostDailyTurnoverB: number;
  outOfStockDailyHoursPercChange?: number;
  outOfStockDailyHoursSignificance: string;
  outOfStockDailyHoursA: number;
  outOfStockDailyHoursB: number;
  secondsToBuyAndLeavePercChange?: number;
  secondsToBuyAndLeaveSignificance: string;
  secondsToBuyAndLeaveA: number;
  secondsToBuyAndLeaveB: number;
  secondsToFirstInteractionPercChange?: number;
  secondsToFirstInteractionSignificance: string;
  secondsToFirstInteractionA: number;
  secondsToFirstInteractionB: number;
  lostShopperSecondsPercChange?: number;
  lostShopperSecondsSignificance: string;
  lostShopperSecondsA: number;
  lostShopperSecondsB: number;
  interactionsPerShopperPercChange?: number;
  interactionsPerShopperSignificance: string;
  interactionsPerShopperA: number;
  interactionsPerShopperB: number;
  averageBasketSizeUnitsPercChange?: number;
  averageBasketSizeUnitsSignificance: string;
  averageBasketSizeUnitsA: number;
  averageBasketSizeUnitsB: number;
  averageBasketSizeTurnoverPercChange?: number;
  averageBasketSizeTurnoverSignificance: string;
  averageBasketSizeTurnoverA: number;
  averageBasketSizeTurnoverB: number;
}

interface ProjectComparisonBrickMetricReport {
  shelfPhaseIdA: string;
  shelfPhaseIdB: string;
  brickName: string;
  shareOfSpaceChange?: number;
  shareOfSpaceA: number;
  shareOfSpaceB: number;
  shareOfUnitSalesPercChange?: number;
  shareOfUnitSalesA: number;
  shareOfUnitSalesB: number;
  shareOfTurnoverPercChange?: number;
  shareOfTurnoverA: number;
  shareOfTurnoverB: number;
  shareOfFirstPickupsPercChange?: number;
  shareOfFirstPickupsA: number;
  shareOfFirstPickupsB: number;
}

// Shopper Comparison Report
export interface ShopperComparisonReportResult {
  overviewReport: ShopperComparisonOverviewReportResult;
  demographicsReport: ShopperComparisonDemographicReportResult[];
  shoppingTimeReport: ShopperComparisonShoppingTimeReportResult[];
  shoppingWithReport: ShopperComparisonShoppingWithReportResult[];
}
interface ShopperComparisonOverviewReportResult {
  shelfPhaseIdA: string;
  projectNameA: string;
  storeIdA: number;
  storeNameA: string;
  phaseNumA: number;
  shelfNumA: number;
  dateFromA: string;
  dateToA: string;
  totalVisitorsA: number;
  shelfPhaseIdB: string;
  projectNameB: string;
  storeIdB: number;
  storeNameB: string;
  phaseNumB: number;
  shelfNumB: number;
  dateFromB: string;
  dateToB: string;
  totalVisitorsB: number;
  visitorsShareA: number;
  visitorsShareB: number;
  shownInterestShareA: number;
  shownInterestShareB: number;
  shownInterestSharePercChange?: number;
  shownInterestShareSignificance: string;
  interactedShareA: number;
  interactedShareB: number;
  interactedSharePercChange?: number;
  interactedShareSignificance: string;
  purchasedShareA: number;
  purchasedShareB: number;
  purchasedSharePercChange?: number;
  purchasedShareSignificance: string;
  lostShoppersShareA: number;
  lostShoppersShareB: number;
  lostShoppersSharePercChange?: number;
  lostShoppersShareSignificance: string;
  assistedBrowsersShareA: number;
  assistedBrowsersShareB: number;
  assistedBrowsersSharePercChange?: number;
  assistedBrowsersShareSignificance: string;
  conversionFromAssistanceA: number;
  conversionFromAssistanceB: number;
  conversionFromAssistancePercChange?: number;
  conversionFromAssistanceSignificance: string;
}
interface ShopperComparisonDemographicReportResult {
  shelfPhaseIdA: string;
  shelfPhaseIdB: string;
  demographicGroup: string;
  visitorsShareA: number;
  visitorsShareB: number;
  visitorsSharePercChange?: number;
  visitorsShareSignificance: string;
  shownInterestShareA: number;
  shownInterestShareB: number;
  shownInterestSharePercChange?: number;
  shownInterestShareSignificance: string;
  interactedShareA: number;
  interactedShareB: number;
  interactedSharePercChange?: number;
  interactedShareSignificance: string;
  purchasedShareA: number;
  purchasedShareB: number;
  purchasedSharePercChange?: number;
  purchasedShareSignificance: string;
  lostShoppersShareA: number;
  lostShoppersShareB: number;
  lostShoppersSharePercChange?: number;
  lostShoppersShareSignificance: string;
  assistedBrowsersShareA: number;
  assistedBrowsersShareB: number;
  assistedBrowsersSharePercChange?: number;
  assistedBrowsersShareSignificance: string;
  conversionFromAssistanceA: number;
  conversionFromAssistanceB: number;
  conversionFromAssistancePercChange?: number;
  conversionFromAssistanceSignificance: string;
}
interface ShopperComparisonShoppingTimeReportResult {
  shelfPhaseIdA: string;
  shelfPhaseIdB: string;
  shoppingTime: string;
  visitorsShareA: number;
  visitorsShareB: number;
  visitorsSharePercChange?: number;
  visitorsShareSignificance: string;
  shownInterestShareA: number;
  shownInterestShareB: number;
  shownInterestSharePercChange?: number;
  shownInterestShareSignificance: string;
  interactedShareA: number;
  interactedShareB: number;
  interactedSharePercChange?: number;
  interactedShareSignificance: string;
  purchasedShareA: number;
  purchasedShareB: number;
  purchasedSharePercChange?: number;
  purchasedShareSignificance: string;
  lostShoppersShareA: number;
  lostShoppersShareB: number;
  lostShoppersSharePercChange?: number;
  lostShoppersShareSignificance: string;
  assistedBrowsersShareA: number;
  assistedBrowsersShareB: number;
  assistedBrowsersSharePercChange?: number;
  assistedBrowsersShareSignificance: string;
  conversionFromAssistanceA: number;
  conversionFromAssistanceB: number;
  conversionFromAssistancePercChange?: number;
  conversionFromAssistanceSignificance: string;
}
interface ShopperComparisonShoppingWithReportResult {
  shelfPhaseIdA: string;
  shelfPhaseIdB: string;
  shoppingWith: string;
  visitorsShareA: number;
  visitorsShareB: number;
  visitorsSharePercChange?: number;
  visitorsShareSignificance: string;
  shownInterestShareA: number;
  shownInterestShareB: number;
  shownInterestSharePercChange?: number;
  shownInterestShareSignificance: string;
  interactedShareA: number;
  interactedShareB: number;
  interactedSharePercChange?: number;
  interactedShareSignificance: string;
  purchasedShareA: number;
  purchasedShareB: number;
  purchasedSharePercChange?: number;
  purchasedShareSignificance: string;
  lostShoppersShareA: number;
  lostShoppersShareB: number;
  lostShoppersSharePercChange?: number;
  lostShoppersShareSignificance: string;
  assistedBrowsersShareA: number;
  assistedBrowsersShareB: number;
  assistedBrowsersSharePercChange?: number;
  assistedBrowsersShareSignificance: string;
  conversionFromAssistanceA: number;
  conversionFromAssistanceB: number;
  conversionFromAssistancePercChange?: number;
  conversionFromAssistanceSignificance: string;
}
// Product Comaprison Report
export interface ProductComparisonReportResult {
  overviewTitleReport: ProductComparisonOverviewTitleReportResult;
  overviewRatesReport: ProductComparisonOverviewRatesReportResult;
  overviewAveragesReport: ProductComparisonOverviewAveragesReportResult;
  demographicsReport: ProductComparisonDemographicsReportResult;
  product1BoughtTogetherReport: ProductBoughtTogetherReportResult[];
  product2BoughtTogetherReport: ProductBoughtTogetherReportResult[];
  product1WinningOverReport: ProductWinningOverReportResult[];
  product2WinningOverReport: ProductWinningOverReportResult[];
  product1LosingToReport: ProductLosingToReportResult[];
  product2LosingToReport: ProductLosingToReportResult[];
}
interface ProductComparisonOverviewTitleReportResult {
  shelfPhaseIdA: string;
  projectNameA: string;
  storeNameA: string;
  storeIdA: number;
  phaseNumA: number;
  shelfNumA: number;
  dateFromA: string;
  dateToA: string;
  totalVisitorsA: number;
  gtinA: number;
  tradeItemDescriptionA: string;
  totalShoppersInteractedSKUA: number;
  productImageUrlA: string;
  shelfPhaseIdB: string;
  projectNameB: string;
  storeNameB: string;
  storeIdB: number;
  phaseNumB: number;
  shelfNumB: number;
  dateFromB: string;
  dateToB: string;
  totalVisitorsB: number;
  gtinB: number;
  tradeItemDescriptionB: string;
  totalShoppersInteractedSKUB: number;
  productImageUrlB: string;
}
interface ProductComparisonOverviewRatesReportResult {
  productConversionRateA: number;
  productConversionRateB: number;
  productConversionRatePercChange?: number;
  productConversionRateSignificance: string;
  productDestinationBuyersShareA: number;
  productDestinationBuyersShareB: number;
  productDestinationBuyersSharePercChange?: number;
  productDestinationBuyersShareSignificance: string;
  productReturnRatioA?: number;
  productReturnRatioB?: number;
  productReturnRatioPercChange?: number;
  productReturnRatioSignificance: string;
  productLostShoppersPercentA: number;
  productLostShoppersPercentB: number;
  productLostShoppersPercentPercChange?: number;
  productLostShoppersPercentSignificance: string;
  shareOfSpaceA: number;
  shareOfSpaceB: number;
  shareOfSpacePercChange?: number;
  shareOfSpaceSignificance: string;
  shareOfUnitSalesA: number;
  shareOfUnitSalesB: number;
  shareOfUnitSalesPercChange?: number;
  shareOfUnitSalesSignificance: string;
  shareOfTurnoverA: number;
  shareOfTurnoverB: number;
  shareOfTurnoverPercChange?: number;
  shareOfTurnoverSignificance: string;
  shareOfFirstPickupsA: number;
  shareOfFirstPickupsB: number;
  shareOfFirstPickupsPercChange?: number;
  shareOfFirstPickupsSignificance: string;
  substitutionShopperCountA: number;
  substitutionShopperCountB: number;
  substitutionShopperCountPercChange?: number;
  substitutionShopperCountSignificance: string;
}
interface ProductComparisonOverviewAveragesReportResult {
  productTurnoverPerVisitorA: number;
  productTurnoverPerVisitorB: number;
  productTurnoverPerVisitorPercChange?: number;
  productTurnoverPerVisitorSignificance: string;
  discoveryTimeA: number;
  discoveryTimeB: number;
  discoveryTimePercChange?: number;
  discoveryTimeSignificance: string;
  secondsToBuyAndLeaveA: number;
  secondsToBuyAndLeaveB: number;
  secondsToBuyAndLeavePercChange?: number;
  secondsToBuyAndLeaveSignificance: string;
  secondsToTouchAndRejectA: number;
  secondsToTouchAndRejectB: number;
  secondsToTouchAndRejectPercChange?: number;
  secondsToTouchAndRejectSignificance: string;
  averageProductDailyTurnoverA: number;
  averageProductDailyTurnoverB: number;
  averageProductDailyTurnoverPercChange?: number;
  averageProductDailyTurnoverSignificance: string;
  averageProductOosHoursA: number;
  averageProductOosHoursB: number;
  averageProductOosHoursPercChange?: number;
  averageProductOosHoursSignificance: string;
  productOosTimeShareA: number;
  productOosTimeShareB: number;
  productOosTimeSharePercChange?: number;
  productOosTimeShareSignificance: string;
}
interface ProductComparisonDemographicsReportResult {
  allInteractorsA: number;
  allInteractorsB: number;
  allInteractorsPercChange?: number;
  allInteractorsSignificance: string;
  allBuyersA: number;
  allBuyersB: number;
  allBuyersPercChange?: number;
  allBuyersSignificance: string;
  allLostShoppersA: number;
  allLostShoppersB: number;
  allLostShoppersPercChange?: number;
  allLostShoppersSignificance: string;
  maleInteractorsA: number;
  maleInteractorsB: number;
  maleInteractorsPercChange?: number;
  maleInteractorsSignificance: string;
  maleBuyersA: number;
  maleBuyersB: number;
  maleBuyersPercChange?: number;
  maleBuyersSignificance: string;
  maleLostShoppersA: number;
  maleLostShoppersB: number;
  maleLostShoppersPercChange?: number;
  maleLostShoppersSignificance: string;
  femaleInteractorsA: number;
  femaleInteractorsB: number;
  femaleInteractorsPercChange?: number;
  femaleInteractorsSignificance: string;
  femaleBuyersA: number;
  femaleBuyersB: number;
  femaleBuyersPercChange?: number;
  femaleBuyersSignificance: string;
  femaleLostShoppersA: number;
  femaleLostShoppersB: number;
  femaleLostShoppersPercChange?: number;
  femaleLostShoppersSignificance: string;
  groupInteractorsA: number;
  groupInteractorsB: number;
  groupInteractorsPercChange?: number;
  groupInteractorsSignificance: string;
  groupBuyersA: number;
  groupBuyersB: number;
  groupBuyersPercChange?: number;
  groupBuyersSignificance: string;
  groupLostShoppersA: number;
  groupLostShoppersB: number;
  groupLostShoppersPercChange?: number;
  groupLostShoppersSignificance: string;
  withKidsInteractorsA: number;
  withKidsInteractorsB: number;
  withKidsInteractorsPercChange?: number;
  withKidsInteractorsSignificance: string;
  withKidsBuyersA: number;
  withKidsBuyersB: number;
  withKidsBuyersPercChange?: number;
  withKidsBuyersSignificance: string;
  withKidsLostShoppersA: number;
  withKidsLostShoppersB: number;
  withKidsLostShoppersPercChange?: number;
  withKidsLostShoppersSignificance: string;
}
// Brand Comparison Report
export interface BrandComparisonReportResult {
  overviewTitleReport: BrandComparisonOverviewTitleReportResult;
  overviewRatesReport: BrandComparisonOverviewRatesReportResult;
  overviewDailyAveragesReport: BrandComparisonOverviewDailyAveragesReportResult;
  overviewShopperAveragesReport: BrandComparisonOverviewShopperAveragesReportResult;
  internalMetricsReport: BrandComparisonInternalMetricsReportResult;
  demographicsReport: BrandComparisonDemographicsReportResult;
  brand1ExternalBoughtTogetherReport: BrandExternalBoughtTogetherReportResult[];
  brand1ExternalWinningOverReport: BrandExternalWinningOverReportResult[];
  brand1ExternalLosingToReport: BrandExternalLosingToReportResult[];
  brand2ExternalBoughtTogetherReport: BrandExternalBoughtTogetherReportResult[];
  brand2ExternalWinningOverReport: BrandExternalWinningOverReportResult[];
  brand2ExternalLosingToReport: BrandExternalLosingToReportResult[];
}
interface BrandComparisonOverviewTitleReportResult {
  shelfPhaseIdA: string;
  projectNameA: string;
  storeNameA: string;
  storeIdA: number;
  phaseNumA: number;
  shelfNumA: number;
  dateFromA: string;
  dateToA: string;
  totalVisitorsA: number;
  brandIdA: number;
  brandNameA: string;
  totalBrandInteractorsA: number;
  productImageUrlA: string;
  shelfPhaseIdB: string;
  projectNameB: string;
  storeNameB: string;
  storeIdB: number;
  phaseNumB: number;
  shelfNumB: number;
  dateFromB: string;
  dateToB: string;
  totalVisitorsB: number;
  brandIdB: number;
  brandNameB: string;
  totalBrandInteractorsB: number;
  productImageUrlB: string;
}
interface BrandComparisonOverviewRatesReportResult {
  brandConversionRateA: number;
  brandConversionRateB: number;
  brandConversionRatePercChange?: number;
  brandConversionRateSignificance: string;
  brandDestinationBuyersA: number;
  brandDestinationBuyersB: number;
  brandDestinationBuyersPercChange?: number;
  brandDestinationBuyersSignificance: string;
  brandReturnRatioA: number;
  brandReturnRatioB: number;
  brandReturnRatioPercChange?: number;
  brandReturnRatioSignificance: string;
  brandLostShoppersA: number;
  brandLostShoppersB: number;
  brandLostShoppersPercChange?: number;
  brandLostShoppersSignificance: string;
  shareOfSpaceA: number;
  shareOfSpaceB: number;
  shareOfSpacePercChange?: number;
  shareOfSpaceSignificance: string;
  shareOfUnitSalesA: number;
  shareOfUnitSalesB: number;
  shareOfUnitSalesPercChange?: number;
  shareOfUnitSalesSignificance: string;
  shareOfTurnoverA: number;
  shareOfTurnoverB: number;
  shareOfTurnoverPercChange?: number;
  shareOfTurnoverSignificance: string;
  shareOfFirstPickupsA: number;
  shareOfFirstPickupsB: number;
  shareOfFirstPickupsPercChange?: number;
  shareOfFirstPickupsSignificance: string;
}
interface BrandComparisonOverviewDailyAveragesReportResult {
  brandDailyTurnoverA: number;
  brandDailyTurnoverB: number;
  brandDailyTurnoverPercChange?: number;
  brandDailyTurnoverSignificance: string;
  brandOutOfShelfHoursA: number;
  brandOutOfShelfHoursB: number;
  brandOutOfShelfHoursPercChange?: number;
  brandOutOfShelfHoursSignificance: string;
  brandOutOfStockPercentA: number;
  brandOutOfStockPercentB: number;
  brandOutOfStockPercentPercChange?: number;
  brandOutOfStockPercentSignificance: string;
}

interface BrandComparisonOverviewShopperAveragesReportResult {
  discoveryTimeA: number;
  discoveryTimeB: number;
  discoveryTimePercChange?: number;
  discoveryTimeSignificance: string;
  secondsToBuyAndLeaveA: number;
  secondsToBuyAndLeaveB: number;
  secondsToBuyAndLeavePercChange?: number;
  secondsToBuyAndLeaveSignificance: string;
  secondsToRejectA: number;
  secondsToRejectB: number;
  secondsToRejectPercChange?: number;
  secondsToRejectSignificance: string;
  substitutionsA: number;
  substitutionsB: number;
  substitutionsPercChange?: number;
  substitutionsSignificance: string;
  brandTurnoverPerVisitorA: number;
  brandTurnoverPerVisitorB: number;
  brandTurnoverPerVisitorPercChange?: number;
  brandTurnoverPerVisitorSignificance: string;
}
interface BrandComparisonInternalMetricsReportResult {
  internalSubstitutorsA: number;
  internalSubstitutorsB: number;
  internalSubstitutorsPercChange?: number;
  internalSubstitutorsSignificance: string;
  internalBuysMoreThanOneProductsA: number;
  internalBuysMoreThanOneProductsB: number;
  internalBuysMoreThanOneProductsPercChange?: number;
  internalBuysMoreThanOneProductsSignificance: string;
}
interface BrandComparisonDemographicsReportResult {
  allInteractorsA: number;
  allInteractorsB: number;
  allInteractorsPercChange?: number;
  allInteractorsSignificance: string;
  allBuyersA: number;
  allBuyersB: number;
  allBuyersPercChange?: number;
  allBuyersSignificance: string;
  allLostShoppersA: number;
  allLostShoppersB: number;
  allLostShoppersPercChange?: number;
  allLostShoppersSignificance: string;
  maleInteractorsA: number;
  maleInteractorsB: number;
  maleInteractorsPercChange?: number;
  maleInteractorsSignificance: string;
  maleBuyersA: number;
  maleBuyersB: number;
  maleBuyersPercChange?: number;
  maleBuyersSignificance: string;
  maleLostShoppersA: number;
  maleLostShoppersB: number;
  maleLostShoppersPercChange?: number;
  maleLostShoppersSignificance: string;
  femaleInteractorsA: number;
  femaleInteractorsB: number;
  femaleInteractorsPercChange?: number;
  femaleInteractorsSignificance: string;
  femaleBuyersA: number;
  femaleBuyersB: number;
  femaleBuyersPercChange?: number;
  femaleBuyersSignificance: string;
  femaleLostShoppersA: number;
  femaleLostShoppersB: number;
  femaleLostShoppersPercChange?: number;
  femaleLostShoppersSignificance: string;
  groupInteractorsA: number;
  groupInteractorsB: number;
  groupInteractorsPercChange?: number;
  groupInteractorsSignificance: string;
  groupBuyersA: number;
  groupBuyersB: number;
  groupBuyersPercChange?: number;
  groupBuyersSignificance: string;
  groupLostShoppersA: number;
  groupLostShoppersB: number;
  groupLostShoppersPercChange?: number;
  groupLostShoppersSignificance: string;
  withKidsInteractorsA: number;
  withKidsInteractorsB: number;
  withKidsInteractorsPercChange?: number;
  withKidsInteractorsSignificance: string;
  withKidsBuyersA: number;
  withKidsBuyersB: number;
  withKidsBuyersPercChange?: number;
  withKidsBuyersSignificance: string;
  withKidsLostShoppersA: number;
  withKidsLostShoppersB: number;
  withKidsLostShoppersPercChange?: number;
  withKidsLostShoppersSignificance: string;
}

// GpcBrick Comparison Report
export interface GpcBrickComparisonReportResult {
  overviewTitleReport: GpcBrickComparisonOverviewTitleReportResult;
  overviewRatesReport: GpcBrickComparisonOverviewRatesReportResult;
  overviewDailyAveragesReport: GpcBrickComparisonOverviewDailyAveragesReportResult;
  overviewShopperAveragesReport: GpcBrickComparisonOverviewShopperAveragesReportResult;
  internalMetricsReport: GpcBrickComparisonInternalMetricsReportResult;
  demographicsReport: GpcBrickComparisonDemographicsReportResult;
  gpcBrick1ExternalBoughtTogetherReport: GpcBrickExternalBoughtTogetherReportResult[];
  gpcBrick1ExternalWinningOverReport: GpcBrickExternalWinningOverReportResult[];
  gpcBrick1ExternalLosingToReport: GpcBrickExternalLosingToReportResult[];
  gpcBrick2ExternalBoughtTogetherReport: GpcBrickExternalBoughtTogetherReportResult[];
  gpcBrick2ExternalWinningOverReport: GpcBrickExternalWinningOverReportResult[];
  gpcBrick2ExternalLosingToReport: GpcBrickExternalLosingToReportResult[];
}
interface GpcBrickComparisonOverviewTitleReportResult {
  shelfPhaseIdA: string;
  projectNameA: string;
  storeNameA: string;
  storeIdA: number;
  phaseNumA: number;
  shelfNumA: number;
  dateFromA: string;
  dateToA: string;
  totalVisitorsA: number;
  gpcBrickCodeA: number;
  gpcBrickDescriptionA: string;
  totalGpcInteractorsA: number;
  productImageUrlA: string;
  shelfPhaseIdB: string;
  projectNameB: string;
  storeNameB: string;
  storeIdB: number;
  phaseNumB: number;
  shelfNumB: number;
  dateFromB: string;
  dateToB: string;
  totalVisitorsB: number;
  gpcBrickCodeB: number;
  gpcBrickDescriptionB: string;
  totalGpcInteractorsB: number;
  productImageUrlB: string;
}
interface GpcBrickComparisonOverviewRatesReportResult {
  gpcConversionRateA: number;
  gpcConversionRateB: number;
  gpcConversionRatePercChange?: number;
  gpcConversionRateSignificance: string;
  gpcDestinationBuyersA: number;
  gpcDestinationBuyersB: number;
  gpcDestinationBuyersPercChange?: number;
  gpcDestinationBuyersSignificance: string;
  gpcReturnRatioA: number;
  gpcReturnRatioB: number;
  gpcReturnRatioPercChange?: number;
  gpcReturnRatioSignificance: string;
  gpcLostShoppersA: number;
  gpcLostShoppersB: number;
  gpcLostShoppersPercChange?: number;
  gpcLostShoppersSignificance: string;
  shareOfSpaceA: number;
  shareOfSpaceB: number;
  shareOfSpacePercChange?: number;
  shareOfSpaceSignificance: string;
  shareOfUnitSalesA: number;
  shareOfUnitSalesB: number;
  shareOfUnitSalesPercChange?: number;
  shareOfUnitSalesSignificance: string;
  shareOfTurnoverA: number;
  shareOfTurnoverB: number;
  shareOfTurnoverPercChange?: number;
  shareOfTurnoverSignificance: string;
  shareOfFirstPickupsA: number;
  shareOfFirstPickupsB: number;
  shareOfFirstPickupsPercChange?: number;
  shareOfFirstPickupsSignificance: string;
}
interface GpcBrickComparisonOverviewDailyAveragesReportResult {
  gpcDailyTurnoverA: number;
  gpcDailyTurnoverB: number;
  gpcDailyTurnoverPercChange?: number;
  gpcDailyTurnoverSignificance: string;
  gpcOutOfShelfHoursA: number;
  gpcOutOfShelfHoursB: number;
  gpcOutOfShelfHoursPercChange?: number;
  gpcOutOfShelfHoursSignificance: string;
  gpcOutOfStockPercentA: number;
  gpcOutOfStockPercentB: number;
  gpcOutOfStockPercentPercChange?: number;
  gpcOutOfStockPercentSignificance: string;
}

interface GpcBrickComparisonOverviewShopperAveragesReportResult {
  discoveryTimeA: number;
  discoveryTimeB: number;
  discoveryTimePercChange?: number;
  discoveryTimeSignificance: string;
  secondsToBuyAndLeaveA: number;
  secondsToBuyAndLeaveB: number;
  secondsToBuyAndLeavePercChange?: number;
  secondsToBuyAndLeaveSignificance: string;
  secondsToRejectA: number;
  secondsToRejectB: number;
  secondsToRejectPercChange?: number;
  secondsToRejectSignificance: string;
  substitutionsA: number;
  substitutionsB: number;
  substitutionsPercChange?: number;
  substitutionsSignificance: string;
  gpcTurnoverPerVisitorA: number;
  gpcTurnoverPerVisitorB: number;
  gpcTurnoverPerVisitorPercChange?: number;
  gpcTurnoverPerVisitorSignificance: string;
}
interface GpcBrickComparisonInternalMetricsReportResult {
  internalSubstitutorsA: number;
  internalSubstitutorsB: number;
  internalSubstitutorsPercChange?: number;
  internalSubstitutorsSignificance: string;
  internalBuysMoreThanOneProductsA: number;
  internalBuysMoreThanOneProductsB: number;
  internalBuysMoreThanOneProductsPercChange?: number;
  internalBuysMoreThanOneProductsSignificance: string;
}
interface GpcBrickComparisonDemographicsReportResult {
  allInteractorsA: number;
  allInteractorsB: number;
  allInteractorsPercChange?: number;
  allInteractorsSignificance: string;
  allBuyersA: number;
  allBuyersB: number;
  allBuyersPercChange?: number;
  allBuyersSignificance: string;
  allLostShoppersA: number;
  allLostShoppersB: number;
  allLostShoppersPercChange?: number;
  allLostShoppersSignificance: string;
  maleInteractorsA: number;
  maleInteractorsB: number;
  maleInteractorsPercChange?: number;
  maleInteractorsSignificance: string;
  maleBuyersA: number;
  maleBuyersB: number;
  maleBuyersPercChange?: number;
  maleBuyersSignificance: string;
  maleLostShoppersA: number;
  maleLostShoppersB: number;
  maleLostShoppersPercChange?: number;
  maleLostShoppersSignificance: string;
  femaleInteractorsA: number;
  femaleInteractorsB: number;
  femaleInteractorsPercChange?: number;
  femaleInteractorsSignificance: string;
  femaleBuyersA: number;
  femaleBuyersB: number;
  femaleBuyersPercChange?: number;
  femaleBuyersSignificance: string;
  femaleLostShoppersA: number;
  femaleLostShoppersB: number;
  femaleLostShoppersPercChange?: number;
  femaleLostShoppersSignificance: string;
  groupInteractorsA: number;
  groupInteractorsB: number;
  groupInteractorsPercChange?: number;
  groupInteractorsSignificance: string;
  groupBuyersA: number;
  groupBuyersB: number;
  groupBuyersPercChange?: number;
  groupBuyersSignificance: string;
  groupLostShoppersA: number;
  groupLostShoppersB: number;
  groupLostShoppersPercChange?: number;
  groupLostShoppersSignificance: string;
  withKidsInteractorsA: number;
  withKidsInteractorsB: number;
  withKidsInteractorsPercChange?: number;
  withKidsInteractorsSignificance: string;
  withKidsBuyersA: number;
  withKidsBuyersB: number;
  withKidsBuyersPercChange?: number;
  withKidsBuyersSignificance: string;
  withKidsLostShoppersA: number;
  withKidsLostShoppersB: number;
  withKidsLostShoppersPercChange?: number;
  withKidsLostShoppersSignificance: string;
}

export interface ProjectBenchmarkReportResult {
  overviewReport: ProjectBenchmarkOverviewReportResult;
  brickMetrics: ProjectBenchmarkBrickMetricReportResult[];
}
interface ProjectBenchmarkOverviewReportResult {
  projectId: number;
  projectName: string;
  storeName: string;
  shelfPhaseCount: number;
  totalVisitors: number;
  productsCount: number;
  conversionRate: number;
  turnoverPerVisitor: number;
  dailyTurnoverAverage: number;
  destinationBuyersPercent: number;
  returnRatioPercent: number;
  lostShoppersPercent: number;
  averageLostDailyTurnover: number;
  outOfStockDailyHours: number;
  secondsToBuyAndLeave: number;
  secondsToFirstInteraction: number;
  lostShopperSeconds: number;
  interactionsPerShopper: number;
  averageBasketSizeUnits: number;
  averageBasketSizeTurnover: number;
}
interface ProjectBenchmarkBrickMetricReportResult {
  brickName: string;
  shelfPhaseCount: number;
  shareOfSpace: number;
  shareOfUnitSales: number;
  shareOfTurnover: number;
  shareOfFirstPickups: number;
}

export interface ClientBenchmarkReportResult {
  overviewReport: ClientBenchmarkOverviewReportResult;
  brickMetrics: ClientBenchmarkBrickMetricReportResult[];
}
interface ClientBenchmarkOverviewReportResult {
  clientId: number;
  clientName: string;
  shelfPhaseCount: number;
  totalVisitors: number;
  productsCount: number;
  conversionRate: number;
  turnoverPerVisitor: number;
  dailyTurnoverAverage: number;
  destinationBuyersPercent: number;
  returnRatioPercent: number;
  lostShoppersPercent: number;
  averageLostDailyTurnover: number;
  outOfStockDailyHours: number;
  secondsToBuyAndLeave: number;
  secondsToFirstInteraction: number;
  lostShopperSeconds: number;
  interactionsPerShopper: number;
  averageBasketSizeUnits: number;
  averageBasketSizeTurnover: number;
}
interface ClientBenchmarkBrickMetricReportResult {
  brickName: string;
  shelfPhaseCount: number;
  shareOfSpace: number;
  shareOfUnitSales: number;
  shareOfTurnover: number;
  shareOfFirstPickups: number;
}

interface BrandPotentialOverview {
  brandId: number;
  brandName: string;
  shelfPhaseId: string;
  projectName: string;
  storeName: string;
  phaseNum: number;
  shelfNum: number;
  dateFrom: string;
  dateTo: string;
  totalVisitors: number;
  totalBrandInteractors: number;
  brandDailyTurnover: number;
}

export interface BrandPotentialBricks {
  shelfPhaseID: string;
  brandId: number;
  brandNumberOfProducts: number;
  gpcBrickCode: number;
  gpcBrickDescription: string;
  gpcNumberOfProducts: number;
  gpcPerformace: number;
  brandPerformance: number;
  bricksOnShelf: number;
  increaseProducts: number;
  decreaseProducts: number;
  bricksToConsider: number;
}
interface BrandPotentialDelist {
  shelfPhaseId: string;
  gtin: number;
  tradeItemDescription: string;
  brandId: number;
  gpcBrickCode: number;
  shareOfSpace: number;
  numberOfFacings: number;
  incrementalDailyTurnover: number;
  delistScore: number;
}
interface BrandPotentialLostInteractedBrand {
  shelfPhaseId: string;
  brandId: number;
  brandName: string;
  lostShoppersCount: number;
  lostDailyTurnover: number;
  demographicGroup: string;
  demographicGroupCount: number;
  age: number;
  ageCount: number;
  shoppingTime: string;
  shoppingTimeCount: number;
  shoppingWith: string;
  shoppingWithCount: number;
}
interface BrandPotentialLostInteractedShelf {
  ShelfPhaseId: string;
  lostShoppersCount: number;
  lostDailyTurnover: number;
  demographicGroup: string;
  demographicGroupCount: number;
  age: number;
  ageCount: number;
  shoppingTime: string;
  shoppingTimeCount: number;
  shoppingWith: string;
  shoppingWithCount: number;
}
interface BrandPotentialLostInteractedProduct {
  shelfPhaseId: string;
  gtin: number;
  brandId: number;
  tradeItemDescription: string;
  lostShoppersCount: number;
  lostDailyTurnover: number;
  demographicGroup: string;
  demographicGroupCount: number;
  age: number;
  ageCount: number;
  shoppingTime: string;
  shoppingTimeCount: number;
  shoppingWith: string;
  shoppingWithCount: number;
}
interface BrandPotentialLostOosBrand {
  shelfPhaseId: string;
  brandId: number;
  hoursOutOfStockPerDay: number;
  lostDailySalesOutOfStock: number;
  lostDailyTurnoverOutOfStock: number;
}
interface BrandPotentialLostOosShelf {
  shelfPhaseId: string;
  hoursOutOfStockPerDay: number;
  lostDailySalesOutOfStock: number;
  lostDailyTurnoverOutOfStock: number;
}
interface BrandPotentialLostOosProduct {
  shelfPhaseId: string;
  gtin: number;
  tradeItemDescription: string;
  brandId: number;
  hoursOutOfStockPerDay: number;
  lostDailySalesOutOfStock: number;
  lostDailyTurnoverOutOfStock: number;
}
export interface BrandPotentialSpaceSuggestion {
  gtin: number;
  tradeItemDescription: string;
  shareOfSpace: number;
  facings: number;
  change: number;
  incrementalDailyTurnover: number;
}

export interface BrandPotentialLocationSuggestion {
  gtin: number;
  tradeItemDescription: string;
  currentPosition: number;
  suggestedPosition: number;
  incrementalDailyTurnover: number;
}

export interface BrandPotentialReport {
  overview: BrandPotentialOverview;
  bricks: BrandPotentialBricks[];
  delists: BrandPotentialDelist[];
  lostInteractedBrand: BrandPotentialLostInteractedBrand;
  lostInteractedShelf: BrandPotentialLostInteractedShelf;
  lostInteractedProducts: BrandPotentialLostInteractedProduct[];
  lostOosBrand: BrandPotentialLostOosBrand;
  lostOosShelf: BrandPotentialLostOosShelf;
  lostOosProducts: BrandPotentialLostOosProduct[];
  spaceSuggestionIncrease: BrandPotentialSpaceSuggestion[];
  spaceSuggestionDecrease: BrandPotentialSpaceSuggestion[];
  locationSuggestions: BrandPotentialLocationSuggestion[];
}

export interface ProductBenchmarkReport {
  overviewTitle: ProductBenchmarkOverviewTitle;
  overviewRates: ProductBenchmarkOverviewRates;
  overviewAverage: ProductBenchmarkOverviewAverage;
  benchmarkInteractors: DetailedConversion[];
  productInteractors: DetailedConversion[];
  demographics: ProductBenchmarkDemographics;
}

export interface ProductBenchmarkOverviewTitle {
  comparisonShelfPhaseId: string;
  projectNameA: string;
  storeIdA: number;
  storeNameA: string;
  phaseNumA: number;
  shelfNumA: number;
  dateFromA: string;
  dateToA: string;
  totalVisitors: number;
  comparisonProductsCount: number;
  averageShoppersInteractedSKUA: number;
  averagePriceQuantileA: number;
  targetShelfPhaseId: string;
  projectNameB: string;
  storeIdB: number;
  storeNameB: string;
  phaseNumB: number;
  shelfNumB: number;
  dateFromB: string;
  dateToB: string;
  totalVisitorsB: number;
  targetGtin: number;
  tradeItemDescriptionB: string;
  totalShoppersInteractedSKUB: number;
  average: number;
  priceQuantileB: number;
  productImageUrlB: string;
}

export interface ProductBenchmarkOverviewRates {
  targetShelfPhaseId: string;
  targetGTIN: number;
  targetTID: string;
  comparisonShelfPhaseId: string;
  productConversionRateA: number;
  productConversionRateB: number;
  productConversionRatePercChange: number;
  productConversionRateSignificance: string;
  productDestinationBuyersShareA: number;
  productDestinationBuyersShareB: number;
  productDestinationBuyersSharePercChange: number;
  productDestinationBuyersShareSignificance: string;
  productReturnRatioA: number;
  productReturnRatioB: number;
  productReturnRatioPercChange: number;
  productReturnRatioSignificance: string;
  productLostShoppersPercentA: number;
  productLostShoppersPercentB: number;
  productLostShoppersPercentPercChange: number;
  productLostShoppersPercentSignificance: string;
  shareOfSpaceA: number;
  shareOfSpaceB: number;
  shareOfSpacePercChange: number;
  shareOfSpaceSignificance: string;
  shareOfUnitSalesA: number;
  shareOfUnitSalesB: number;
  shareOfUnitSalesPercChange: number;
  shareOfUnitSalesSignificance: string;
  shareOfTurnoverA: number;
  shareOfTurnoverB: number;
  shareOfTurnoverPercChange: number;
  shareOfTurnoverSignificance: string;
  shareOfFirstPickupsA: number;
  shareOfFirstPickupsB: number;
  shareOfFirstPickupsPercChange: number;
  shareOfFirstPickupsSignificance: string;
  substitutionShopperCountA: number;
  substitutionShopperCountB: number;
  substitutionShopperCountPercChange: number;
  substitutionShopperCountSignificance: string;
}

export interface ProductBenchmarkOverviewAverage {
  comparisonShelfPhaseId: string;
  targetShelfPhaseId: string;
  targetGTIN: number;
  productTurnoverPerVisitorA: number;
  productTurnoverPerVisitorB: number;
  productTurnoverPerVisitorSignificance: string;
  productTurnoverPerVisitorPercChange: number;
  discoveryTimeA: number;
  discoveryTimeB: number;
  discoveryTimeSignificance: string;
  discoveryTimePercChange: number;
  secondsToBuyAndLeaveA: number;
  secondsToBuyAndLeaveB: number;
  secondsToBuyAndLeaveSignificance: string;
  secondsToBuyAndLeavePercChange: number;
  secondsToTouchAndRejectA: number;
  secondsToTouchAndRejectB: number;
  secondsToTouchAndRejectSignificance: string;
  secondsToTouchAndRejectPercChange: number;
  averageProductDailyTurnoverA: number;
  averageProductDailyTurnoverB: number;
  averageProductDailyTurnoverSignificance: string;
  averageProductDailyTurnoverPercChange: number;
  averageProductOosHoursA: number;
  averageProductOosHoursB: number;
  averageProductOosHoursSignificance: string;
  averageProductOosHoursPercChange: number;
  productOosTimeShareA: number;
  productOosTimeShareB: number;
  productOosTimeShareSignificance: string;
  productOosTimeSharePercChange: number;
}

export interface DetailedConversion {
  category: string;
  percentage: number;
}

export interface ProductBenchmarkDemographics {
  comparisonShelfPhaseId: string;
  targetShelfPhaseId: string;
  targetGtin: number;
  allInteractorsA: number;
  allInteractorsB: number;
  allInteractorsPercChange: number;
  allInteractorsSignificance: string;
  allBuyersA: number;
  allBuyersB: number;
  allBuyersPercChange: number;
  allBuyersSignificance: string;
  allLostShoppersA: number;
  allLostShoppersB: number;
  allLostShoppersPercChange: number;
  allLostShoppersSignificance: string;
  maleInteractorsA: number;
  maleInteractorsB: number;
  maleInteractorsPercChange: number;
  maleInteractorsSignificance: string;
  maleBuyersA: number;
  maleBuyersB: number;
  maleBuyersPercChange: number;
  maleBuyersSignificance: string;
  maleLostShoppersA: number;
  maleLostShoppersB: number;
  maleLostShoppersPercChange: number;
  maleLostShoppersSignificance: string;
  femaleInteractorsA: number;
  femaleInteractorsB: number;
  femaleInteractorsPercChange: number;
  femaleInteractorsSignificance: string;
  femaleBuyersA: number;
  femaleBuyersB: number;
  femaleBuyersPercChange: number;
  femaleBuyersSignificance: string;
  femaleLostShoppersA: number;
  femaleLostShoppersB: number;
  femaleLostShoppersPercChange: number;
  femaleLostShoppersSignificance: string;
  groupInteractorsA: number;
  groupInteractorsB: number;
  groupInteractorsPercChange: number;
  groupInteractorsSignificance: string;
  groupBuyersA: number;
  groupBuyersB: number;
  groupBuyersPercChange: number;
  groupBuyersSignificance: string;
  groupLostShoppersA: number;
  groupLostShoppersB: number;
  groupLostShoppersPercChange: number;
  groupLostShoppersSignificance: string;
  withKidsInteractorsA: number;
  withKidsInteractorsB: number;
  withKidsInteractorsPercChange: number;
  withKidsInteractorsSignificance: string;
  withKidsBuyersA: number;
  withKidsBuyersB: number;
  withKidsBuyersPercChange: number;
  withKidsBuyersSignificance: string;
  withKidsLostShoppersA: number;
  withKidsLostShoppersB: number;
  withKidsLostShoppersPercChange: number;
  withKidsLostShoppersSignificance: string;
}
