import { isLoaded, isLoading } from 'infrastructure/utils/RemoteObjectStatus';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { loadStandardProjectComparisonReportRequestAction } from '../../actions';
import * as selectors from '../../selectors';
import { ProjectComparisonReportResult } from '../../types';
interface LoadStatusParams {
  isLoaded: boolean;
  isLoading: boolean;
  loadError: string;
  isDataEmpty: boolean;
  data: ProjectComparisonReportResult | undefined;
}
export interface StandardProjectComparisonReportLoaderProps {
  children: (params: LoadStatusParams) => React.ReactNode;
}

const StandardProjectComparisonReportLoader: React.FC<StandardProjectComparisonReportLoaderProps> = ({ children }: StandardProjectComparisonReportLoaderProps) => {
  const { shelfPhaseId1, shelfPhaseId2 } = useParams();
  const dispatch = useDispatch();
  const loadState = useSelector(selectors.getProjectComparisonReportLoadState);
  const isDataEmpty = useSelector(selectors.isProjectComparisonReportEmpty);
  const data = useSelector(selectors.getProjectComparisonReportData);
  useEffect(() => {
    if (isDataEmpty && shelfPhaseId1 && shelfPhaseId2) {
      dispatch(loadStandardProjectComparisonReportRequestAction(shelfPhaseId1, shelfPhaseId2));
    }
  }, [dispatch, isDataEmpty, shelfPhaseId1, shelfPhaseId2]);
  return (
    <>
      {children({
        isLoaded: isLoaded(loadState),
        isLoading: isLoading(loadState),
        loadError: loadState?.errorDetails?.errorMessage ?? '',
        isDataEmpty,
        data,
      })}
    </>
  );
};

export { StandardProjectComparisonReportLoader };
