import React from 'react';
import { FieldArray, Formik } from 'formik';
import { Button, Grid, LinearProgress, Typography } from '@material-ui/core';
import { Close, Update } from '@material-ui/icons';

import PlanogramRules from '../SharedPlanogramFormGroups/PlanogramRules';
import Insights from '../SharedPlanogramFormGroups/Insights';
import BrandRulesTable from '../SharedPlanogramFormGroups/BrandRules/BrandRulesTable';
import BrandRulesTableRow from '../SharedPlanogramFormGroups/BrandRules/BrandRulesTableRow';

import EditBuilderRulesDialog from './EditBuilderRulesDialog';
import { InitialValuesEditBuilderRules } from './types';

export interface EditBuilderRulesFormProps {
  onClose: () => void;
  email: string;
  initialValues: InitialValuesEditBuilderRules;
  handleOnSubmit: (values: InitialValuesEditBuilderRules) => void;
  isUpdatingBuilderRules: boolean;
}

const EditBuilderRulesForm: React.FC<EditBuilderRulesFormProps> = ({ onClose, email, initialValues, handleOnSubmit, isUpdatingBuilderRules }: EditBuilderRulesFormProps): JSX.Element => {
  return (
    <Formik
      onSubmit={(values): void => {
        handleOnSubmit(values);
      }}
      initialValues={initialValues}
    >
      {({ handleSubmit, values, setFieldValue, dirty }): JSX.Element => {
        return (
          <EditBuilderRulesDialog
            onClose={onClose}
            email={email}
            actionsContent={
              <>
                <Button
                  data-testid="edit-builder-rules-dialog-submitBtn"
                  variant="contained"
                  color="primary"
                  disabled={!dirty || isUpdatingBuilderRules}
                  className="pull-left"
                  onClick={(): void => {
                    handleSubmit();
                  }}
                >
                  <Update />
                  &nbsp;
                  {isUpdatingBuilderRules ? 'Updating...' : 'Update'}
                </Button>
                <Button data-testid="edit-builder-rules-dialog-closeBtn" variant="contained" color="secondary" onClick={onClose} className="pull-right">
                  <Close />
                  &nbsp; Cancel
                </Button>
              </>
            }
          >
            {isUpdatingBuilderRules && <LinearProgress color="primary" />}
            <Grid container spacing={4}>
              <Grid item xl={6} xs={6}>
                <Insights name="dataInsights" />
              </Grid>
            </Grid>
            <PlanogramRules namePrefix={`actionRules`} />
            <div className="top-buffer">
              <Typography variant="overline">Brand rules:</Typography>
              <FieldArray name={`actionRules.brandRules`} validateOnChange={false}>
                {(): JSX.Element => {
                  return (
                    <BrandRulesTable
                      setAllFieldValues={(actionRuleName, checked): void => {
                        values.actionRules.brandRules.forEach((value, i) => {
                          setFieldValue(`actionRules.brandRules.${i}.actionsAllowed.${actionRuleName}` as any, checked);
                        });
                      }}
                    >
                      {values.actionRules.brandRules.map(
                        (brandRule, brandRuleIndex): JSX.Element => {
                          return <BrandRulesTableRow key={brandRule.brandId} brandName={brandRule.brandName} namePrefix={`actionRules.brandRules.${brandRuleIndex}.actionsAllowed`} />;
                        }
                      )}
                    </BrandRulesTable>
                  );
                }}
              </FieldArray>
            </div>
          </EditBuilderRulesDialog>
        );
      }}
    </Formik>
  );
};

export default EditBuilderRulesForm;
