import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getIsLoading } from '../state/selectors';
import { getAvailableSalesReports, getSelectedReport } from './SalesDataManagement/selectors';
import { Grid, Typography, Switch, Card, CardContent, Divider } from '@material-ui/core';
import { selectSalesReport } from '../state/actions';

const ReportSelector: React.FC = (): JSX.Element | null => {
  const isLoadingTimelapseData = useSelector(getIsLoading);
  const salesReports = useSelector(getAvailableSalesReports);
  const selectedReport = useSelector(getSelectedReport);

  const dispatch = useDispatch();
  if (isLoadingTimelapseData) {
    return null;
  }
  if (salesReports.length === 0) {
    return <h6>No sales report found.</h6>;
  }
  return (
    <>
      <Card raised className="top-buffer-sm">
        <CardContent style={{ padding: '8px' }}>
          <Grid direction="row" container justify="space-between" alignItems="center">
            <Typography color="secondary" variant="overline">
              Select Report
            </Typography>
          </Grid>
          <Divider className="top-buffer bottom-buffer" light={true} variant="fullWidth" />
          {salesReports.map(r => {
            const amISelected = r.id === selectedReport;
            return (
              <Grid key={r.id} style={{ padding: '0px' }} direction="row" container>
                <Grid item xs={8}>
                  <Typography style={{ padding: '0px', fontSize: '11px' }} variant="caption">
                    {r.reportName}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Switch
                    color="secondary"
                    size="small"
                    checked={amISelected}
                    onChange={(): void => {
                      const toDispatch = amISelected ? undefined : r.id;
                      dispatch(selectSalesReport(toDispatch));
                    }}
                  />
                </Grid>
              </Grid>
            );
          })}
        </CardContent>
      </Card>
    </>
  );
};

export default ReportSelector;
