export const USER_LOADING = 'USER_LOADING';
export type USER_LOADING = typeof USER_LOADING;

export const USER_LOADED = 'USER_LOADED';
export type USER_LOADED = typeof USER_LOADED;

export const USER_LOADED_ERROR = 'USER_LOADED_ERROR';
export type USER_LOADED_ERROR = typeof USER_LOADED_ERROR;

export const REQUEST_SIGNOUT = 'REQUEST_SIGNOUT';
export type REQUEST_SIGNOUT = typeof REQUEST_SIGNOUT;

export const NOT_AUTHENTICATED = 'NOT_AUTHENTICATED';
export type NOT_AUTHENTICATED = typeof NOT_AUTHENTICATED;

export const AUTH_CALLBACK_PATH = '/_auth/callback';
export const AUTH_LOGOUT_PATH = '/_auth/post-logout';
export const AUTH_LOGOUT_CLEANUP_PATH = '/_auth/logout-cleanup';

export const LOAD_USER_PREFERENCES_REQUEST = 'LOAD_USER_PREFERENCES_REQUEST';
export type LOAD_USER_PREFERENCES_REQUEST = typeof LOAD_USER_PREFERENCES_REQUEST;

export const LOAD_USER_PREFERENCES_SUCCESS = 'LOAD_USER_PREFERENCES_SUCCESS';
export type LOAD_USER_PREFERENCES_SUCCESS = typeof LOAD_USER_PREFERENCES_SUCCESS;

export const LOAD_USER_PREFERENCES_FAILED = 'LOAD_USER_PREFERENCES_FAILED';
export type LOAD_USER_PREFERENCES_FAILED = typeof LOAD_USER_PREFERENCES_FAILED;

export const HAS_FINISHED_PRODUCT_TOUR_SUCCESS = 'HAS_FINISHED_PRODUCT_TOUR_SUCCESS';
export type HAS_FINISHED_PRODUCT_TOUR_SUCCESS = typeof HAS_FINISHED_PRODUCT_TOUR_SUCCESS;
